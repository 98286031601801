import React from 'react'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { Typography, Icon } from '../../atomic-design-components'

const NameWithLogo = ({
  iconName,
  iconSize,
  name,
  typographyVariant,
}) => {
  return (
    <>
      {name && (
        <Icon
          name={iconName}
          size={iconSize || 20}
        />
      )}
      <Typography
        component="div"
        variant={typographyVariant || 'button1'}
        margin='0 0 0 6px'
        className="name"
        as={ReactTexty}
        textTransform='capitalize'
      >
        {name || '-'}
      </Typography>

    </>
  )
}

export default withTheme(NameWithLogo)
