import styled from 'styled-components'

export const StyledBulkMessaging = styled.div``

export const StyledResultsBlock = styled.div`
  .typography {
    margin-bottom: 10px;
  }

  .panelHeader {
    margin-bottom: 15px;
    .typography {
      margin: 0;
    }
  }
`
