import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import MenuItem from './MenuItem'
import { StyledMenuItems } from './styled'

const MenuGroup = ({
  entitiesArr,
  children,
  isExtended,
  isMobile,
  textColor,
  onClick,
  padding,
  isRoleSensitive = false,
  role,
}) => {
  const { pathname } = useLocation()
  const params = useParams()

  const isActiveKey = (key) =>
    pathname && pathname !== '/'
      ? pathname
          .replace(/[^a-zA-Z ]/g, '')
          .replace(params.action, '')
          .includes(key.toLowerCase())
      : key === 'dashboard'

  return (
    <StyledMenuItems onClick={onClick} textColor={textColor} padding={padding}>
      {entitiesArr &&
        entitiesArr.map((entity) => {
          const { key, route, iconName, sortObject, rolesWithAccess } = entity

          if (!isRoleSensitive || rolesWithAccess.includes(role))
            return (
              <MenuItem
                iconName={iconName}
                key={key}
                text={key}
                route={route}
                isActive={isActiveKey(key)}
                isExtended={isExtended}
                isMobile={isMobile}
                sortObject={sortObject}
              />
            )
        })}
      {children}
    </StyledMenuItems>
  )
}

export default MenuGroup
