import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Cookies from 'universal-cookie'

import BulkMessaging from './containers/BulkMessaging/BulkMessaging'
import BulkMessagingEdit from './containers/BulkMessaging/panels/BulkMessagingEdit'
import Chats from './containers/Chats'
import ChatActionPanel from './containers/Chats/panels/ChatActionPanel'
import Contacts from './containers/Contacts'
import ContactEdit from './containers/Contacts/panels/ContactEdit'
import Customers from './containers/Customers'
import CustomersRightPanel from './containers/Customers/panels/CustomersRightPanel'
import Dashboard from './containers/Dashboard'
import MainWrapper from './containers/Layout/MainWrapper'
import Members from './containers/Members'
import MembersRightPanel from './containers/Members/panels/MembersRightPanel'
import MyCompany from './containers/MyCompany'
import ChatWidgetCustomisation from './containers/MyCompanyChatWidgets/ChatWidgetCustomisation'
import ChatWidgets from './containers/MyCompanyChatWidgets/ChatWidgets'
import ChatWidgetsAutoMessages from './containers/MyCompanyChatWidgets/panels/ChatWidgetsAutoMessages'
import ChatWidgetsCustomisationPanel from './containers/MyCompanyChatWidgets/panels/ChatWidgetsCustomisationPanel'
import ChatWidgetsUpsert from './containers/MyCompanyChatWidgets/panels/ChatWidgetsUpsert'
import MyCompanyDelete from './containers/MyCompanyDelete'
import Departments from './containers/MyCompanyDepartments'
import DepartmentUpsert from './containers/MyCompanyDepartments/panels/DepartmentUpsert'
import MyCompanyGeneral from './containers/MyCompanyGeneral'
import MyCompanyHours from './containers/MyCompanyHours'
import MyCompanyNotifications from './containers/MyCompanyNotifications/MyCompanyNotifications'
import Payments from './containers/MyCompanyPayments/Payments'
import PaymentsDetails from './containers/MyCompanyPayments/panels/PaymentsDetails'
import MyCompanySubscription from './containers/MyCompanySubscription'
import NewPassword from './containers/NewPassword'
import NotFound from './containers/NotFound'
import PasswordRecovery from './containers/PasswordRecovery'
import PasswordRecoverySent from './containers/PasswordRecoverySent'
import PrivacyPolicy from './containers/PrivacyPolicy'
import RequestSent from './containers/RequestSent'
import Settings from './containers/Settings'
import SettingsDeleteAccount from './containers/SettingsDeleteAccount'
import SettingsInvoices from './containers/SettingsInvoices'
import InvoiceDetails from './containers/SettingsInvoices/InvoiceDetails'
import SettingsNotifications from './containers/SettingsNotifications'
import SettingsProfile from './containers/SettingsProfile'
import SignIn from './containers/SignIn'
import SignUp from './containers/SignUp'
import SubscriptionPlans from './containers/SubscriptionPlans/SubscriptionPlans'
import SubscriptionPlansEdit from './containers/SubscriptionPlans/panels/SubscriptionPlansEdit'
import Templates from './containers/Templates'
import TermsAndConditions from './containers/TermsAndConditions/TermsAndConditions'
import { useFacebookSdkInit } from './hooks/useFacebookSdkInit'
import { ScreenContext, useScreenContext } from './hooks/useScreenContext'
import PrivateRoute from './wrappers/PrivateRoute'

function App({ theme }) {
  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const screenContextValues = useScreenContext(theme)

  const { width, xl } = screenContextValues
  const isDesktop = width > xl

  useFacebookSdkInit()

  useEffect(() => {
    const lngCookies = cookies.get('lng')
    if (lngCookies !== undefined) {
      i18n.changeLanguage(lngCookies)
    }
  }, [i18n])

  console.log('Version:', '3.4.5')

  return (
    <ScreenContext.Provider value={screenContextValues}>
      <BrowserRouter>
        <Routes>
          <Route path="/sign-in" element={<SignIn screenContext={screenContextValues} />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
          <Route path="/password-request-sent" element={<PasswordRecoverySent />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/confirm-email" element={<RequestSent />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainWrapper />
              </PrivateRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="/templates" element={<Templates />}>
              <Route path=":templateId" element={null} />
            </Route>

            <Route path="/members" element={<Members />}>
              <Route path=":rightPanelId" element={<MembersRightPanel />} />
            </Route>

            <Route path="/contacts" element={<Contacts />}>
              <Route path=":rightPanelId" element={<ContactEdit />} />
            </Route>

            <Route path="/bulk-messaging" element={<BulkMessaging />}>
              <Route path=":rightPanelId" element={<BulkMessagingEdit />} />
            </Route>

            <Route path="/customers" element={<Customers />}>
              <Route path=":rightPanelId" element={<CustomersRightPanel />}>
                <Route path=":action" element={null} />
              </Route>
            </Route>
            <Route path="/subscription-plans" element={<SubscriptionPlans />}>
              <Route path=":rightPanelId" element={<SubscriptionPlansEdit />} />
            </Route>
            <Route path="/payments" element={<Payments />}>
              <Route path=":rightPanelId" element={<PaymentsDetails />}></Route>
            </Route>

            <Route path="/chats" element={<Chats />}>
              <Route path=":chatId" element={null} />
              <Route path=":chatId/:action" element={<ChatActionPanel />} />
              <Route path=":chatId/:action/:rightPanelId" element={<ContactEdit />} />
            </Route>

            <Route path="/my-company" element={<MyCompany />}>
              <Route path="general" element={<MyCompanyGeneral />} />
              <Route path="hours-of-work" element={<MyCompanyHours />} />
              <Route path="notifications" element={<MyCompanyNotifications />} />
              <Route path="subscription" element={<MyCompanySubscription />} />
              <Route path="delete-company" element={<MyCompanyDelete />} />
            </Route>
            {/*my-company with right panel*/}
            <Route path="/my-company/departments" element={<Departments />}>
              <Route path=":rightPanelId" element={<DepartmentUpsert />} />
            </Route>
            <Route path="/my-company/chat-widgets" element={<ChatWidgets />}>
              <Route path=":rightPanelId" element={<ChatWidgetsUpsert />} />
              <Route path="auto-messages/:rightPanelId" element={<ChatWidgetsAutoMessages />} />
            </Route>
            <Route path="/my-company/chat-customization" element={<ChatWidgetCustomisation />}>
              <Route path=":rightPanelId" element={<ChatWidgetsCustomisationPanel />} />
            </Route>

            <Route path="/my-company/payments" element={<Payments />}>
              <Route path=":rightPanelId" element={<PaymentsDetails />} />
            </Route>

            <Route path="/settings" element={<Settings />}>
              <Route index path="my-profile" element={<SettingsProfile />} />
              <Route path="notifications" element={<SettingsNotifications />} />
              <Route path="delete-account" element={<SettingsDeleteAccount />} />
            </Route>
            {/*settings with right panel*/}
            <Route path="/settings/invoices" element={<SettingsInvoices />}>
              <Route path=":rightPanelId" element={<InvoiceDetails />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
          {/*<Route path="/loader.js" onEnter={() => window.location.reload()} />*/}
          {/*<Route*/}
          {/*  path="/chat-widget-container"*/}
          {/*  element={*/}
          {/*    <ChatProvider>*/}
          {/*      <LiveChatContainer />*/}
          {/*    </ChatProvider>*/}
          {/*  }*/}
          {/*/>*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ScreenContext.Provider>
  )
}

export default App
