import React from 'react'

export const logoSymbol = ({ width, height, fill, theme }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M10.0002 18.9143C14.9235 18.9143 18.9145 14.9233 18.9145 9.99998C18.9145 5.07676 14.9235 1.08569 10.0002 1.08569C5.077 1.08569 1.08594 5.07676 1.08594 9.99998C1.08594 14.9233 5.077 18.9143 10.0002 18.9143Z"
      stroke={fill || theme.color.general.black}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.43504 11.9024C4.10472 11.4247 3.91765 10.835 3.86914 10.1679H5.07134C5.13828 10.4687 5.2599 10.7513 5.45044 10.9935C5.68899 11.2967 6.02515 11.5231 6.46342 11.6451C7.2741 11.8707 7.90573 11.6397 8.4167 11.2002C8.90656 10.7788 9.29423 10.1583 9.64957 9.58946L9.65622 9.57882L9.91539 9.14544C10.3663 8.43014 10.8785 7.72166 11.5149 7.25882C12.1386 6.80523 12.8833 6.58544 13.8334 6.84988L13.8671 6.85925H13.8675C15.1834 7.2388 15.9592 8.29501 16.1218 9.6559H14.9069C14.7063 8.98071 14.2273 8.46957 13.5315 8.27605C12.8493 8.08638 12.3105 8.30235 11.8795 8.68276C11.4699 9.04421 11.1426 9.567 10.8518 10.0314L10.8406 10.0493L10.8403 10.0497L10.8134 10.0932C10.7913 10.129 10.7701 10.1632 10.7481 10.1979L10.503 10.5756L10.4654 10.6336L10.4653 10.6337C10.0901 11.2136 9.58745 11.9865 8.88419 12.5351C8.19407 13.0734 7.31572 13.3924 6.16153 13.0713C5.36856 12.8507 4.80577 12.4385 4.43504 11.9024Z"
      fill={fill || theme.color.general.black}
      strokeWidth="0"
    />
  </svg>
)
