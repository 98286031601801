import React from 'react'

export const forwardToDepartment = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill={fill || color.status.warning} />
    <path
      d="M6.85603 7.14394C7.56611 7.14394 8.14174 6.5683 8.14174 5.85822C8.14174 5.14814 7.56611 4.57251 6.85603 4.57251C6.14595 4.57251 5.57031 5.14814 5.57031 5.85822C5.57031 6.5683 6.14595 7.14394 6.85603 7.14394Z"
      stroke="white"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.42801 11.4297H4.28516V10.8582C4.28516 10.1762 4.55607 9.52219 5.03831 9.03996C5.52055 8.55772 6.1746 8.2868 6.85658 8.2868C7.53857 8.2868 8.19262 8.55772 8.67486 9.03996C9.1571 9.52219 9.42801 10.1762 9.42801 10.8582V11.4297Z"
      stroke="white"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14258 4.57251C9.48357 4.57251 9.8106 4.70797 10.0517 4.94909C10.2928 5.1902 10.4283 5.51723 10.4283 5.85822C10.4283 6.19922 10.2928 6.52624 10.0517 6.76736C9.8106 7.00848 9.48357 7.14394 9.14258 7.14394"
      stroke="white"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0566 8.39539C10.5434 8.58056 10.9625 8.90916 11.2584 9.33772C11.5543 9.76628 11.7131 10.2746 11.7138 10.7954V11.4297H10.8566"
      stroke="white"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
