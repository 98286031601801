import React from 'react'

export const planet = ({ width, height, fill }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M8.0001 15.4286C12.1028 15.4286 15.4287 12.1027 15.4287 8C15.4287 3.89731 12.1028 0.571428 8.0001 0.571428C3.89742 0.571428 0.571533 3.89731 0.571533 8C0.571533 12.1027 3.89742 15.4286 8.0001 15.4286Z"
      stroke={fill || 'black'}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.571533 8H15.4287" stroke={fill || 'black'}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8574 8C10.717 10.7166 9.71641 13.3181 8.00021 15.4286C6.284 13.3181 5.28341 10.7166 5.14307 8C5.28341 5.28344 6.284 2.68189 8.00021 0.571428C9.71641 2.68189 10.717 5.28344 10.8574 8V8Z"
      stroke={fill || 'black'}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
