import React from 'react'

export const chats = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M3.4 14.8011L1.12012 15.5211L2.22781 12.198V2.22879C2.22781 1.93501 2.34451 1.65326 2.55225 1.44553C2.75998 1.2378 3.04172 1.12109 3.3355 1.12109H14.4124C14.7062 1.12109 14.9879 1.2378 15.1957 1.44553C15.4034 1.65326 15.5201 1.93501 15.5201 2.22879V5.62571"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0399 18.068L18.8799 18.8803L17.9199 16.4434V9.13262C17.9199 8.91718 17.8187 8.71057 17.6387 8.55823C17.4587 8.40589 17.2145 8.32031 16.9599 8.32031H7.35988C7.10527 8.32031 6.86109 8.40589 6.68106 8.55823C6.50102 8.71057 6.39988 8.91718 6.39988 9.13262V17.2557C6.39988 17.4711 6.50102 17.6777 6.68106 17.8301C6.86109 17.9824 7.10527 18.068 7.35988 18.068H15.0399Z"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
