import clsx from 'clsx'
import { pick } from 'dot-object'
import { PropTypes as T } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import { withTheme } from 'styled-components'

import Typography from '../../atoms/Typography'
import { StyledErrorLabel, StyledInputWrapper, StyledLabel, StyledWrapper } from '../Input/styled'
import ClearIndicator from './components/ClearIndicator'
import Control from './components/Control'
import DropdownIndicator from './components/DropdownIndicator'
import MultiValue from './components/MultiValue'
import Option from './components/Option'
import { StyledSelect } from './styled'

const Select = ({
  autosize,
  children,
  className,
  createLabelText,
  createOptionPosition,
  customGetOptionLabel,
  error,
  fullWidth,
  getOptionBeforeTextComponent,
  iconLeftProps,
  iconName,
  iconText,
  inputValue,
  isClearable,
  isCreatable,
  isDisabled,
  isHidden,
  isMulti,
  isSearchable = true,
  isOptionDisabled, //hidden
  label,
  labelType,
  labelKey,
  labelWidth,
  lng,
  loadOptions,
  MultiValue: MultiValueCustom,
  noOptionsMessage,
  onBlur,
  onChange,
  onCreateOption,
  onInputChange,
  onMenuClose,
  onOptionClick,
  OptionCustom,
  optionIconColor,
  optionIconKey,
  options,
  placeholder,
  primaryFocusColor,
  readOnly,
  required,
  t = () => '',
  theme,
  value,
  valueKey,
  variant,
  width,
  withBorder,
  withoutValidation,
  isTranslatedLabel = true,
  ...otherProps
}) => {
  const isFocusedRef = useRef(false)
  const [customStyles, setCustomStyles] = useState({})
  const [menuFocused, setMenuFocused] = useState(false)

  const onCloseMenu = () => {
    setMenuFocused(false)
    if (onMenuClose) onMenuClose()
  }
  const onOpenMenu = () => setMenuFocused(true)

  useEffect(() => {
    const styles = () =>
      autosize && {
        container: (base, state) => {
          isFocusedRef.current = state.isFocused
          return {
            ...base,
            display: 'inline-block',
          }
        },
        placeholder: (base, state) => {
          return {
            ...base,
            ...(isFocusedRef.current && state.value
              ? {}
              : {
                  position: 'static',
                  top: 'auto',
                  transform: 'none',
                }),
          }
        },
        input: (base, state) => {
          return {
            ...base,
            ...(isFocusedRef.current && state.value
              ? {}
              : {
                  position: 'absolute',
                  top: '2px',
                  // transform: 'translateY(-40%)',
                }),
          }
        },
        singleValue: (base, state) => {
          return {
            ...base,
            maxWidth: 'none',
            ...(isFocusedRef.current && state.value
              ? {}
              : {
                  position: 'static',
                  top: 'auto',
                  transform: 'none',
                }),
          }
        },
      }
    setCustomStyles(styles())
  }, [])

  const handleChange = (option) => {
    // TODO: makes sense to define default value for single and multi-valued select
    if (isMulti && option === null) {
      option = []
    }

    if (onChange) {
      onChange(option)
    }
  }

  const handleCreate = (inputValue) => {
    // TODO: When we'll have another case of creatable options add necessary values to config (=> this object)
    const newOption = inputValue //, __isNew__: true
    if (onCreateOption) {
      onCreateOption({ [valueKey]: newOption, [labelKey]: newOption })
    }
  }

  return (
    <StyledInputWrapper className={clsx('inputContainer', isHidden && 'isHidden')}>
      {label && labelType === 'top' && (
        <StyledLabel
          as={Typography}
          variant="caption1"
          className={clsx('label', 'top', required && 'required')}
        >
          {t ? t(label) || label : label}
        </StyledLabel>
      )}
      <StyledWrapper
        className={clsx(
          'selectWrapper',
          isDisabled && 'disabled',
          !!error && 'hasError',
          className,
          withoutValidation && 'withoutValidation',
          menuFocused && 'focused'
        )}
        fullWidth={fullWidth}
        width={width}
      >
        {label && labelType === 'border' && (
          <StyledLabel className={clsx('label', 'border', required && 'required')}>
            {(t ? t(label) || label : label)?.toLowerCase()}
          </StyledLabel>
        )}
        <StyledSelect
          as={isCreatable ? CreatableSelect : loadOptions ? AsyncSelect : ReactSelect}
          inputValue={inputValue}
          onInputChange={onInputChange}
          primaryFocusColor={primaryFocusColor}
          loadOptions={loadOptions}
          // autoFocus
          variant={variant}
          // defaultInputValue
          isSearchable={isSearchable}
          // defaultValue={defaultValue}
          styles={customStyles}
          theme={theme}
          onBlur={onBlur}
          onMenuClose={onCloseMenu}
          onMenuOpen={onOpenMenu}
          iconLeftProps={{
            ...iconLeftProps,
            fill: error ? theme.color.status.error : theme.color.general.light,
          }}
          iconText={iconText}
          className={clsx(
            'select',
            labelType === 'top' && 'labelTop',
            !!error && 'hasError',
            autosize && 'autosize'
          )}
          createOptionPosition={createOptionPosition}
          formatCreateLabel={(inputValue) => {
            return `${createLabelText} ${inputValue}`
          }}
          getNewOptionData={(inputValue, optionLabel) => {
            // TODO: When we'll have another case of creatable options add necessary values to config (=> this object)
            return { [valueKey]: inputValue, [labelKey]: optionLabel }
          }}
          noOptionsMessage={() => (isCreatable ? null : noOptionsMessage)}
          onCreateOption={handleCreate}
          // filterOption={
          //   isCreatable
          //     ? (candidate, input) => {
          //         return candidate.data[labelKey].includes(slugify(input))
          //       }
          //     : undefined
          // }
          value={value}
          onChange={handleChange}
          options={options?.filter((option) => !!option[valueKey])}
          classNamePrefix="react-select"
          closeMenuOnSelect={!isMulti}
          isClearable={isClearable}
          isMulti={isMulti}
          isDisabled={isDisabled || readOnly}
          isOptionDisabled={isOptionDisabled ? isOptionDisabled(otherProps.optionsData) : undefined}
          components={{
            Control,
            DropdownIndicator,
            ClearIndicator,
            Option: OptionCustom || Option,
            MultiValue: MultiValueCustom || MultiValue,
          }}
          placeholder={placeholder || ''}
          withBorder={withBorder}
          getOptionLabel={
            customGetOptionLabel
              ? (option) => customGetOptionLabel(option, t, lng)
              : labelKey &&
                ((option) => {
                  const label = pick(labelKey, option)
                  return (isTranslatedLabel && t(label)) || label
                })
          }
          getOptionValue={valueKey && ((option) => option[valueKey])}
          {...otherProps}
        />

        {children}
      </StyledWrapper>
      {!!error && <StyledErrorLabel className="error">{error}</StyledErrorLabel>}
    </StyledInputWrapper>
  )
  // <StyledFlexRow className={clsx(className, labelType === 'top' && 'labelTop', 'wrapper')}>
  //   <StyledLabel variant={variant} text={label} labelWidth={labelWidth} className="label" />
  // </StyledFlexRow>
}

export default withTheme(Select)

Select.propTypes = {
  createLabelText: T.string,
  createOptionPosition: T.oneOf(['first', 'last']),
  isDisabled: T.bool,
  fullWidth: T.bool,
  iconName: T.string,
  label: T.string,
  labelKey: T.string,
  labelType: T.oneOf(['top', 'border']),
  labelWidth: T.string,
  name: T.string,
  onChange: T.func,
  placeholder: T.string,
  primaryFocusColor: T.bool,
  valueKey: T.string,
  variant: T.oneOf(['primary', 'secondary']),
  withBorder: T.bool,
}

Select.defaultProps = {
  createLabelText: 'Create',
  createOptionPosition: 'last',
  iconName: 'chevronDownFilled',
  labelKey: 'label',
  labelType: 'border',
  // labelWidth: '150px',
  value: { id: '', label: '' },
  valueKey: 'id',
}
