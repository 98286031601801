import styled from 'styled-components'
import Switch from 'react-switch'

export const StyledSwitch = styled(Switch)`
  border-radius: 2em !important;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};

  .react-switch-handle {
    border: ${({ borderColor }) => `1px solid ${borderColor} !important`};
  }

  &.disabled {
    cursor: not-allowed;
  }
`

export const StyledSwitchLabel = styled.label`
  margin: ${({ margin }) => margin};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  cursor: auto;
  .typography {
    cursor: default;
  }
  &.revert {
    .switch {
      order: 1;
    }
  }
`
