import React from 'react'
import ReactTexty from 'react-texty'

import { StyledAverageComponent } from './styled'
import { FlexRow, Icon, Typography } from '../../../atomic-design-components'
import { formatTime } from '../../../utils/formatMeasureUnits'

const ICON_NAME = {
  response_time: 'chats',
  chat_duration: 'durationClock',
  averageReaction: 'clockReaction',
  rating: 'starSign',
}

const AverageComponent = ({ color, t, data = {}, className }) => {
  const isRating = data.type === 'rating'
  const value = isRating ? data.avg.toFixed(2) : formatTime({ seconds: data.avg, t })

  return (
    <StyledAverageComponent color={color} className={className}>
      <FlexRow>
        <Icon
          name={ICON_NAME[data.type]}
          size={18}
          fill={isRating ? color.general.white : color.primary.main}
          stroke={isRating ? color.primary.main : ''}
          margin="0 8px 0 0"
        />
        <Typography variant="caption2" text={isRating ? t('averageRating') : t(data.type)} as={ReactTexty} />
      </FlexRow>
      <FlexRow gap="10px" as={ReactTexty}>
        <Typography variant="h3" text={value} as={ReactTexty} />

        <FlexRow flexDirection="column" alignItems="start">
          <Typography variant="body3" text={`${t('count')}: ${data.count}`} color={color.general.dark} />
          {!isRating && (
            <>
              <Typography
                variant="body3"
                text={`Min: ${formatTime({ seconds: data.min, t })}`}
                color={color.status.success}
                as={ReactTexty}
              />
              <Typography
                variant="body3"
                text={`Max: ${formatTime({ seconds: data.max, t })}`}
                color={color.status.error}
                as={ReactTexty}
              />
            </>
          )}
        </FlexRow>
      </FlexRow>
    </StyledAverageComponent>
  )
}

export default AverageComponent
