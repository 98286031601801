import React from 'react'
import { withTheme } from 'styled-components'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { StyledTag } from './styled'
import Icon from '../Icon'

const Tag = ({
  backgroundColor,
  className,
  color,
  children,
  fontSize,
  hexOpacity,
  lineHeight,
  fontWeight,
  iconLeftProps,
  iconRightProps,
  isOutlined,
  outlineColor,
  onCrossClick,
  removeProps,
  selected,
  text,
  withBackgroundOpacity,
  withCrossIcon,
  ...rest
}) => (
  <StyledTag
    className={clsx(
      className,
      'tag',
      selected && 'selected',
      isOutlined && 'isOutlined',
      withBackgroundOpacity && 'withBackgroundOpacity'
    )}
    backgroundColor={backgroundColor}
    color={color}
    hexOpacity={hexOpacity}
    outlineColor={outlineColor}
    fontSize={fontSize}
    lineHeight={lineHeight}
    fontWeight={fontWeight}
    {...rest}
  >
    <Icon height={8} width={8} fill={color} {...iconLeftProps} />
    {children || text}
    <Icon height={8} width={8} fill={color} {...iconRightProps} />
    {withCrossIcon && (
      <Icon
        name="cross"
        height={8}
        width={8}
        fill={color}
        className="crossIcon"
        onClick={onCrossClick}
        {...removeProps}
      />
    )}
  </StyledTag>
)

export default withTheme(Tag)

Tag.propTypes = {
  backgroundColor: T.string,
  borderRadius: T.string,
  color: T.string,
  children: T.node,
  fontSize: T.number,
  hexOpacity: T.string,
  lineHeight: T.number,
  fontWeight: T.number,
  iconLeftProps: T.object,
  iconRightProps: T.object,
  isOutlined: T.bool,
  outlineColor: T.string,
  onCrossClick: T.func,
  removeProps: T.object,
  selected: T.bool,
  text: T.string,
  withBackgroundOpacity: T.bool,
  withCrossIcon: T.bool,
}

Tag.defaultProps = {
  removeProps: {},
}
