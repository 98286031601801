import { useEffect, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { parse } from 'query-string'

import { DEFAULT_TABLE_SORTING } from '../../../config/defaultValues'

export const sortUrlStringToObj = (sortString, type) => {
  if (!sortString) {
    return DEFAULT_TABLE_SORTING[type] || {}
  }

  const allSortValuesSplit = sortString.split(',')

  return allSortValuesSplit?.reduce((acc, sort) => {
    const sortSplit = sort.split('-')

    return { ...acc, [sortSplit?.[0]]: sortSplit?.[1] }
  }, {})
}

export const sortObjectToUrlString = (sortObject) => {
  return (
    sortObject &&
    Object.entries(sortObject)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}-${value}`)
      .join(',')
  )
}

export const useSorting = (type) => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParsed = parse(location.search)

  const [sortState, setSortState] = useState(() => {
    return sortUrlStringToObj(searchParsed?.sort, type)
  })

  const navigateToSortUrl = (state) => {
    const allSortsUrlParams = sortObjectToUrlString(state)

    if (allSortsUrlParams) {
      navigate({
        search: createSearchParams({ ...searchParsed, sort: allSortsUrlParams })
          .toString()
          .replace(/%2C/g, ','),
      })
    } else {
      const newSearchObject = { ...searchParsed }
      if (searchParsed.sort) {
        delete newSearchObject.sort
      }

      navigate({
        search: createSearchParams(newSearchObject).toString().replace(/%2C/g, ','),
      })
    }
  }

  const onColumnSort = ({ column, key, order }) => {
    if (!column?.sortable) {
      return false
    }
    const newSortObject = {}

    if (sortState.status) {
      newSortObject.status = sortState.status
    }

    setSortState({ ...newSortObject, [key]: order })

    navigateToSortUrl({ ...newSortObject, [key]: order })
  }

  return { sortState, onColumnSort }
}
