import React from 'react'

export const clockLong = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none">
    <path
      d="M9.00053 17.1715C13.5135 17.1715 17.172 13.513 17.172 9.00004C17.172 4.48709 13.5135 0.828613 9.00053 0.828613C4.48757 0.828613 0.829102 4.48709 0.829102 9.00004C0.829102 13.513 4.48757 17.1715 9.00053 17.1715Z"
      stroke={fill || color.general.light}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00098 0.828613V9.00004L14.7838 14.7829"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
