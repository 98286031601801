import {
  CANCEL_SUBSCRIPTION_SUCCESS,
  GET_BILLING_PLANS,
  GET_BILLING_PLANS_ERROR,
  GET_BILLING_PLANS_SUCCESS,
  GET_COMPANY_DATA,
  GET_COMPANY_DATA_ERROR,
  GET_COMPANY_DATA_SUCCESS,
  UPDATE_COMPANY_DATA,
  UPDATE_COMPANY_DATA_ERROR,
  UPDATE_COMPANY_DATA_SUCCESS,
} from '../constants'

const defaultState = {
  inProgress: false,
  error: null,
  general: {
    alias: '',
    description: '',
    email: '',
    industry: [],
    phone: { phone: '' },
    website_url: '',
  },
  billingPlans: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_COMPANY_DATA:
    case UPDATE_COMPANY_DATA:
    case GET_BILLING_PLANS:
      return {
        ...state,
        inProgress: true,
        error: null,
      }
    case GET_COMPANY_DATA_SUCCESS: {
      return {
        ...state,
        general: {
          ...action.item,
          id: action.item.id,
          alias: action.item.alias || action.item.company_name,
          description: action.item.description,
          email: action.item.email,
          industry: action.item.industry,
          phone: action.item.phone?.phone,
          timezone_offset: action.item.timezone_offset,
          timezone_region: action.item.timezone_region,
          website_url: action.item.website_url,
          work_hours: action.item.work_hours,
        },
        inProgress: false,
      }
    }
    case UPDATE_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        general: { ...state.general, ...action.item, alias: action.item.alias || action.item.company_name },
        inProgress: false,
      }
    case GET_BILLING_PLANS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        billingPlans: action.plans,
      }

    case GET_COMPANY_DATA_ERROR:
    case UPDATE_COMPANY_DATA_ERROR:
    case GET_BILLING_PLANS_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error || true,
      }
    default:
      return state
  }
}
