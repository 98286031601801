import React from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { Button, FlexRow, Icon, Typography } from '../../../atomic-design-components'
import { cancelSubscription } from '../../../redux-saga/actions'
import { getPlanPrice } from '../../../utils/dataTransformations'
import { transformDate } from '../../../utils/dates'
import { StyledCardContent, StyledCurrPlanCard } from './slyled'
import { getAvailableTranslation } from '../../../utils/getAvailableTranslation'

const SubscriptionCurrentPlan = ({ subscriptionData, t, theme, isMobile, lng }) => {
  const dispatch = useDispatch()
  const {
    created_at,
    plan_id,
    payment_type,
    plan_prices,
    plan_titles,
    subscription_id,
    subscription_start_at,
    subscription_end_at,
    wfp_subscription_end_at,
  } = subscriptionData

  const isActive =
    !!subscription_id && (!wfp_subscription_end_at || dayjs().isBefore(wfp_subscription_end_at, 'day'))
  const isFreePlan = plan_id === 1

  const planTitle = getAvailableTranslation(plan_titles, lng)
  const planPrice = getPlanPrice(plan_prices, payment_type, 'UAH', plan_id)

  const subscriptionStartedAt = subscription_start_at || (isFreePlan && (subscription_start_at || created_at))
  const nextPayment = payment_type !== 'manual' && subscription_end_at
  const subscriptionExpiresAt = payment_type === 'manual' ? subscription_end_at : wfp_subscription_end_at

  const isSubscriptionCanceled = dayjs(subscription_end_at).isSame(wfp_subscription_end_at, 'day')
  const isCancelSubscriptionShown =
    !isFreePlan && payment_type !== 'manual' && isActive && !isSubscriptionCanceled

  const onCancelSubscription = () => {
    dispatch(cancelSubscription())
  }

  return (
    <StyledCardContent>
      <FlexRow className="cardTableItemRow" justifyContent="space-between">
        <Typography variant={isMobile ? 'h4' : 'h3'} text={planTitle} />
        <Typography variant={isMobile ? 'h4' : 'h3'} text={planPrice} />
      </FlexRow>

      <StyledCurrPlanCard>
        <FlexRow className="planRow">
          <Typography variant="caption1" text={t('status')} className="optionName" />
          <FlexRow gap="5px">
            <Icon
              name={isActive ? 'statusActive' : 'plusCircle'}
              size={18}
              stroke={isActive ? theme.color.general.white : ''}
              fill={isActive ? theme.color.status.success : theme.color.general.white}
              opacity={isActive ? '1' : ''}
              fillColor={!isActive ? theme.color.status.error : ''}
              className={!isActive ? 'disabledIcon' : ''}
            />
            <Typography variant="body3" text={t(isActive ? 'active' : 'inactive')} />
          </FlexRow>
        </FlexRow>
        {subscriptionStartedAt && (
          <FlexRow className="planRow">
            <Typography variant="caption1" text={t('activated')} className="optionName" />
            <Typography variant="body3" text={transformDate(subscriptionStartedAt)} />
          </FlexRow>
        )}
        {!isSubscriptionCanceled && nextPayment && (
          <FlexRow className="planRow">
            <Typography variant="caption1" text={t('nextPayment')} className="optionName" />
            <Typography variant="body3" text={transformDate(nextPayment)} />
          </FlexRow>
        )}
        {subscriptionExpiresAt && (
          <FlexRow className="planRow">
            <Typography variant="caption1" text={t('expires')} className="optionName" />
            <Typography variant="body3" text={transformDate(subscriptionExpiresAt)} />
          </FlexRow>
        )}
        {isCancelSubscriptionShown && (
          <Button
            text={t('cancelSubscription')}
            margin="15px 0 0 0"
            variant="general"
            fullWidth
            onClick={onCancelSubscription}
          />
        )}
      </StyledCurrPlanCard>
    </StyledCardContent>
  )
}

export default SubscriptionCurrentPlan
