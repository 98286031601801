import styled from 'styled-components'
import { getCssFromTheme } from '../../../utils/css'

export const StyledExpansionPanel = styled.div`
  //-webkit-tap-highlight-color: transparent;

  &.opened {
    margin-bottom: ${({ marginBottomWhenOpened }) => marginBottomWhenOpened};
    > div:not(.panelHeader):not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .body1 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'caption1')};
  }
`

export const StyledHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.opened {
    margin-bottom: 20px;

    .empty & {
      margin-bottom: 0;
    }

    & .headerChevronIcon {
      transform: rotate(180deg);
    }
  }
  &.disabled {
    cursor: default;
  }
`

export const StyledDetailsWrapper = styled.div`
  //display: flex;
  //align-items: flex-end;
  //margin-top: 16px;
  //flex-shrink: 0;
`
