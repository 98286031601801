import { call, put, select, takeLatest } from 'redux-saga/effects'

import { buildRequest, fetchData, showError } from '../../api'
import { getCurrentSubscription, getTableData, popupAlertShow, setContactNameToChat } from '../actions'
import {
  DELETE_ENTITY,
  GET_CURRENT_USER_SUCCESS,
  GET_INVITATION_LINK,
  GET_INVITATION_LINK_ERROR,
  GET_INVITATION_LINK_SUCCESS,
  GET_TABLE_ITEM_DETAILS,
  GET_TABLE_ITEM_DETAILS_ERROR,
  GET_TABLE_ITEM_DETAILS_SUCCESS,
  INVITATIONS_RESEND,
  INVITATIONS_RESEND_SUCCESS,
  INVITATIONS_SEND,
  INVITATIONS_SEND_SUCCESS,
  SIDEBAR_UPSERT,
  SIDEBAR_UPSERT_ERROR,
  SIDEBAR_UPSERT_SUCCESS,
  UPDATE_CURRENT_PLAN,
  UPDATE_CURRENT_PLAN_ERROR,
} from '../constants'
import {
  selectCurrentSubscription,
  selectMainData,
  selectSidebarDetails,
  selectUserDetails,
} from '../selectors'
import { doUpdateTableData } from './data'
import { doGetDepartmentMembersAvatars, doGetFileUrl } from './fileUpload'
import { MY_COMPANY } from '../../config'

function* doGetTableItemDetails({ payload: { type, id } }) {
  try {
    const request = yield buildRequest({ apiMethod: 'GET', type, id })
    const response = yield fetchData(request)

    yield showError(response)

    let itemToSet

    if (type === 'members' || type === 'customers') {
      itemToSet = yield doGetFileUrl(
        response,
        type === 'members' ? 'profile_picture' : 'owner_profile_picture',
        response[type === 'members' ? 'profile_picture' : 'owner_profile_picture']
      )
    } else {
      itemToSet = response
    }

    yield put({
      type: GET_TABLE_ITEM_DETAILS_SUCCESS,
      item: itemToSet,
    })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_TABLE_ITEM_DETAILS_ERROR })
  }
}

/**
 * Writes the whole object to the API with postprocessing and reducer update
 * @param { object } { data, type }
 */
function* doUpsertSidebar({ payload }) {
  const { apiUrlParam, id, type, requestBody, navigate, location, successContentKey } = payload
  // const { fileUpload } = yield select(mapStateToProps)
  let imagesFormDataObject
  const isDeleteOperation = requestBody.state === 'deleted' // TODO: change api delete request

  try {
    // if (!isObjectEmpty(fileUpload.files)) {
    //   imagesFormDataObject = yield getUploadedImagesData({ type, objData, id })
    // }
    const request = yield buildRequest({
      apiMethod: id ? 'PATCH' : 'POST',
      type,
      id,
      requestBody,
      apiUrlParam,
    })

    const response = yield fetchData(request)

    yield showError(response)

    let itemToSet = response?.value || response

    let newItem =
      type === 'members'
        ? itemToSet.profile_picture.includes('http')
          ? itemToSet
          : yield call(doGetFileUrl, itemToSet, 'profile_picture', itemToSet.profile_picture)
        : {}

    if (itemToSet) {
      yield put({ type: SIDEBAR_UPSERT_SUCCESS, item: { ...itemToSet, ...newItem } })

      if (itemToSet.status) {
        const planData = yield select(selectCurrentSubscription)

        if (planData && (Object.keys(planData?.counts).includes(type) || type === 'members')) {
          yield put(getCurrentSubscription())
        }
      }

      if (type === 'departments') {
        const departments = yield doGetDepartmentMembersAvatars({ departments: [itemToSet] })

        yield doUpdateTableData(type, departments?.[0] || itemToSet, isDeleteOperation, !id)
      } else if (type === 'members') {
        yield doUpdateTableData(type, { ...itemToSet, ...newItem }, isDeleteOperation, !id)

        if (location?.pathname?.includes('my-profile')) {
          const currentUser = yield select(selectUserDetails)

          yield put({
            type: GET_CURRENT_USER_SUCCESS,
            payload: {
              ...currentUser,
              ...newItem,
              id: currentUser.id,
              email_verified: currentUser.email_verified,
              member_id: newItem.id || currentUser.member_id,
              member_full_name: newItem.full_name || currentUser.member_full_name,
              member_status: newItem.status || currentUser.member_status,
              member_profile_picture: newItem.profile_picture,
            },
          })
        }
      } else {
        yield doUpdateTableData(
          type,
          itemToSet,
          isDeleteOperation,
          !id && !(type === 'customers' && requestBody.customer_id)
        )
      }

      if (requestBody.tags) {
        yield put(getTableData({ type: 'tags', isSystemData: true }))
      }
    }

    if (
      location?.pathname.includes('chats') &&
      type === 'contacts' &&
      typeof requestBody.full_name !== 'undefined'
    ) {
      yield put(
        setContactNameToChat({
          chat_id: itemToSet.chat_id,
          contact_name: itemToSet.full_name,
        })
      )
    }

    if (isDeleteOperation) {
      navigate({ pathname: '..', search: location?.search })
    }

    const DATA_TYPE_TO_URL_TYPE = {
      widgets: 'chat-widgets',
      bulkMessaging: 'bulk-messaging',
      subscriptionPlans: 'subscription-plans',
    }

    const parentType = MY_COMPANY.some((object) => object.key === type) ? 'my-company' : ''
    const urlType = DATA_TYPE_TO_URL_TYPE[type] || type

    if (!id && navigate && itemToSet.id) {
      navigate(`/${parentType ? `${parentType}/` : ''}${urlType}/${itemToSet.id}`)
    }

    yield put(
      popupAlertShow({
        contentKey: successContentKey || 'dataSuccessfullySaved',
        type: 'success',
        withCloseButton: true,
        iconName: 'statusActive',
      })
    )
  } catch (error) {
    console.log(error)
    yield put({ type: SIDEBAR_UPSERT_ERROR, error })
  }
}

function* doDeleteEntity({ payload }) {
  const { id, type, navigate, location, successContentKey, navigateTo } = payload

  try {
    const request = yield buildRequest({
      apiMethod: 'DELETE',
      type,
      id,
    })

    const response = yield fetchData(request)

    yield showError(response)
    const itemToSet = { id }

    yield doUpdateTableData(type, itemToSet, true, !id)

    const planData = yield select(selectCurrentSubscription)

    if (planData && (Object.keys(planData?.counts).includes(type) || type === 'members')) {
      yield put(getCurrentSubscription())
    }

    navigate({ pathname: navigateTo || '..', search: location.search })

    yield put(
      popupAlertShow({
        contentKey: successContentKey || 'dataSuccessfullySaved',
        type: 'success',
        withCloseButton: true,
        iconName: 'statusActive',
      })
    )
  } catch (error) {
    console.log(error)
    yield put({ type: SIDEBAR_UPSERT_ERROR, error })
  }
}

function* doSendInvitations({ requestBody }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'invite',
      requestBody,
    })

    const response = yield fetchData(request)

    yield showError(response)

    yield put({
      type: INVITATIONS_SEND_SUCCESS,
    })

    yield put(
      popupAlertShow({
        contentKey: 'invitationSent',
        type: 'success',
        withCloseButton: true,
        iconName: 'statusActive',
      })
    )

    const itemToSet = response?.value || response

    //////////only for testing!!!!!!!
    const getLink = yield buildRequest({
      apiMethod: 'GET',
      type: 'inviteLink',
      apiUrlParam: itemToSet.id,
    })

    const link = yield fetchData(getLink)
    console.log('inviteLink :>> ', link.url)
    ////////

    yield doUpdateTableData('members', itemToSet, false, true)
  } catch (error) {
    console.error(error)
    yield put({ type: SIDEBAR_UPSERT_ERROR, error })
  }
}

function* doResendInvitations({ id }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'inviteResend',
      apiUrlParam: id,
    })

    const response = yield fetchData(request)

    yield showError(response)

    yield put({
      type: INVITATIONS_RESEND_SUCCESS,
    })

    yield put(
      popupAlertShow({
        contentKey: 'invitationSent',
        type: 'success',
        withCloseButton: true,
        iconName: 'statusActive',
      })
    )

    //////////only for testing!!!!!!!
    const getLink = yield buildRequest({
      apiMethod: 'GET',
      type: 'inviteLink',
      apiUrlParam: id,
    })

    const link = yield fetchData(getLink)
    console.log('inviteLink :>> ', link.url)
    //////////
  } catch (error) {
    console.error(error)
    yield put({ type: SIDEBAR_UPSERT_ERROR, error })
  }
}

function* doGetInvitationLink({ id }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'inviteLink',
      apiUrlParam: id,
    })

    const response = yield fetchData(request)

    yield showError(response)

    yield put({
      type: GET_INVITATION_LINK_SUCCESS,
    })

    const textToCopy = response.url.replace('http://localhost:3000', window.location.origin)
    setTimeout(() => {
      try {
        navigator.clipboard.writeText(textToCopy)
        console.log('Copy to clipboard successfully.')
      } catch (err) {
        console.error('Copy to clipboard unsuccessfully.', err)
      }
    })

    yield put(
      popupAlertShow({
        contentKey: 'invitationLinkCopied',
        type: 'success',
        withCloseButton: true,
        iconName: 'copy',
      })
    )
  } catch (error) {
    console.error(error)
    yield put({ type: GET_INVITATION_LINK_ERROR, error })
  }
}

function* doUpdateCurrentPlan({
  payload: { customer_id, plan_id, expires_at, start_at, billing_period, status, payment_type },
  navigate,
  location,
}) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'updateCurrentPlan',
      requestBody: {
        customer_id,
        plan_id,
        expire_at: expires_at,
        billing_period,
        start_at,
        status,
        payment_type,
      },
    })

    const response = yield fetchData(request)

    yield showError(response)

    const companies = yield select(selectMainData('customers'))
    const company = companies.find((company) => company.id === customer_id)

    const plans = yield select(selectMainData('subscriptionPlansByCustomer'))
    const newPlan = plans.find((plan) => plan.id === plan_id)

    const newData = {
      subscription_expire_at: response.expire_at,
      payment_type: response.type,
      plan_id: response.plan_id,
      limits: response.limits,
      plan_titles: response.plan_titles || newPlan?.titles,
      plan_prices: response.plan_prices || newPlan?.prices, // TODO: delete newPlan after api fix
    }

    if (company) {
      yield doUpdateTableData('customers', {
        ...company,
        ...newData,
      })
    }
    // yield put({ type: UPDATE_CURRENT_PLAN_SUCCESS, planData: response })

    const sidebarDetails = yield select(selectSidebarDetails)
    yield put({
      type: SIDEBAR_UPSERT_SUCCESS,
      item: { ...sidebarDetails, ...newData },
    })

    yield put(
      popupAlertShow({
        contentKey: 'dataSuccessfullySaved',
        type: 'success',
        withCloseButton: true,
        iconName: 'statusActive',
      })
    )
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_CURRENT_PLAN_ERROR })
  }
}

export default function* () {
  return [
    yield takeLatest(GET_TABLE_ITEM_DETAILS, doGetTableItemDetails),
    yield takeLatest(SIDEBAR_UPSERT, doUpsertSidebar),
    yield takeLatest(INVITATIONS_SEND, doSendInvitations),
    yield takeLatest(GET_INVITATION_LINK, doGetInvitationLink),
    yield takeLatest(INVITATIONS_RESEND, doResendInvitations),
    yield takeLatest(DELETE_ENTITY, doDeleteEntity),
    yield takeLatest(UPDATE_CURRENT_PLAN, doUpdateCurrentPlan),
  ]
}
