import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'
import ReactTexty from 'react-texty'

import { Label, Switch, Tag, Typography, Userpic } from '../../atomic-design-components'
import { useGeneralWebsocket } from '../../containers/Chats/useChatsWebsockets'
import { useMappedState } from '../../hooks/useReactRedux'
import { toggleIsUserOnline } from '../../redux-saga/actions'
import { selectIsUserOnline, selectUserDetails } from '../../redux-saga/selectors'
import { StyledMenuItem } from './styled'

const UserBlockWithWebsocket = ({ isExtended, isMobile, t }) => {
  const dispatch = useDispatch()
  const isUserOnline = useMappedState(selectIsUserOnline)
  const user = useMappedState(selectUserDetails)

  useGeneralWebsocket(isUserOnline)

  const onToggleOnline = () => {
    dispatch(toggleIsUserOnline(!isUserOnline))
  }

  return (
    <StyledMenuItem className="userState">
      <Userpic
        onlineState={isUserOnline ? 'online' : 'offline'}
        height={36}
        width={36}
        src={user.member_profile_picture}
      />

      <div className={clsx('userInfo', 'condensedItem', isExtended && 'extendedItem')}>
        <Typography as={isExtended && ReactTexty} variant="caption1" text={user.member_full_name} />
        <Label as={isExtended && ReactTexty} size="big" text={user.email} className="userEmail" />
        <Tag text={t(`all:${user.role}`)} />
      </div>

      <Switch
        className={clsx('condensedItem', isExtended && 'extendedItem')}
        checked={isUserOnline}
        onChange={onToggleOnline}
        handleDiameter={isMobile ? 26 : undefined}
        height={isMobile ? 32 : undefined}
        width={isMobile ? 50 : undefined}
        id="onlineStatus"
      />
    </StyledMenuItem>
  )
}

export default UserBlockWithWebsocket
