import React from 'react'
import { PropTypes as T } from 'prop-types'

import Pagination from '../../Pagination'
import { StyledNavbar } from './styled'
import { Button, Typography } from '../../../index'
import { useTranslation } from 'react-i18next'
import theme from '../../../../theme'
import { useMappedState } from '../../../../hooks/useReactRedux'
import { selectDataType } from '../../../../redux-saga/selectors'
import TableSearch from './TableSearch'

const TableNavbar = ({
  buttonProps,
  className,
  itemsTotalCount,
  rowsCount,
  tableMode,
  withSearch,
  tipsComponent,
}) => {
  const { t } = useTranslation('all')

  const iconLeftProps = { name: 'searchSign', fill: theme.color.general.dark }
  const type = useMappedState(selectDataType)

  return (
    <StyledNavbar className={className}>
      {buttonProps && <Button {...buttonProps} />}
      {withSearch && (
        <TableSearch
          type={type}
          iconLeftProps={iconLeftProps}
          placeholder={t('search')}
          placeholderColor={theme.color.general.light}
          className="searchInput"
          withoutValidation
        />
      )}
      {tipsComponent}
      <Pagination type={type} itemsCount={itemsTotalCount} rowsCount={rowsCount} />
    </StyledNavbar>
  )
}

export default TableNavbar

TableNavbar.propTypes = { buttonProps: T.object, rowsCount: T.number, withSearch: T.bool }

TableNavbar.defaultProps = {
  withSearch: true,
}
