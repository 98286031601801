import React from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { parse } from 'query-string'
import { Button, LoaderCircular, Typography } from '../../../atomic-design-components'
import UploadImages from '../../../components/UploadImages'

const TextareaActionButtons = ({
  chatId,
  inProgress,
  isReadOnly,
  onEmojiClick,
  onUploadClick,
  onSend,
  theme,
  t,
}) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParsed = parse(search)

  const onTemplatesClick = () => {
    navigate({ pathname: `${chatId}/templates`, search: createSearchParams(searchParsed).toString() })
  }

  return (
    <>
      <Button
        variant="general"
        iconLeftProps={{
          name: 'smile',
          className: 'withoutHover',
        }}
        className="textareaActionButton smileButton"
        onClick={onEmojiClick}
      />

      <Button
        variant="general"
        iconLeftProps={{
          name: 'attachSign',
          fill: theme.color.general.black,
          className: 'withoutHover',
        }}
        className="textareaActionButton attachButton"
      >
        <UploadImages
          isSimpleButton
          chatID={chatId}
          maxFiles={10}
          maxSize={20971520}
          onHide={onUploadClick}
        />
      </Button>

      {!isReadOnly && (
        <Button
          variant="general"
          iconLeftProps={{
            name: 'templates',
            className: 'withoutHover',
            fill: theme.color.general.black,
            width: 16,
            height: 16,
          }}
          className="textareaActionButton copyButton"
          onClick={onTemplatesClick}
        />
      )}

      {inProgress ? (
        <LoaderCircular color="#fff" />
      ) : (
        <Button
          variant="general"
          iconLeftProps={{
            name: 'mailSend',
            className: 'withoutHover',
            fill: isReadOnly ? theme.color.general.black : theme.color.general.white,
          }}
          className="textareaActionButton sendButton"
          onClick={onSend}
        />
      )}

      <Typography variant="caption3" className="prompt" color={theme.color.general.dark}>
        <b>SHIFT + ENTER</b> {t('toAddNewLine')}
      </Typography>
    </>
  )
}

export default TextareaActionButtons
