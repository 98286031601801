import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import ReactHelmet from '../../wrappers/ReactHelmet'

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import Form from '../../components/Form'
import { FORM_CONFIGS } from '../../config/forms'
import { Button, Typography, Link } from '../../atomic-design-components/'
import backOrange from '../../assets/images/backOrange.jpg'
import { userRequestResetPassword } from '../../redux-saga/actions'
import { useNavigate } from 'react-router-dom'

const initialValues = { email: '', password: '' }

const PasswordRecovery = ({ theme }) => {
  const { t } = useTranslation('auth')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onFormSubmit = (data) => {
    dispatch(userRequestResetPassword(data.email, navigate))
  }

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper type="passwordRecovery" theme={theme} imageUrl={backOrange}>
        <Typography variant="body1" text={t('passRecoverText')} margin="10px 0 30px" />
        <Form
          type="passwordRecovery"
          initialValues={initialValues}
          // serverError={error !== false}
          onSubmit={onFormSubmit}
          validationRules={{
            email: ['required', 'email'],
          }}
          fields={[FORM_CONFIGS.passwordRecovery.fields[0]]}
        >
          <Button fullWidth variant="primary" type="submit" iconName="passwordReset">
            {t('recoverPassword')}
          </Button>
        </Form>
        <div>
          <Typography variant="body2" color={theme.color.general.black} margin="20px 0 0 0">
            {t('backTo')}
          </Typography>
          <Typography variant="body2" fontWeight={500} color={theme.color.primary.main}>
            <Link to="/sign-in" className="link" themeColor="primary.main">
              &nbsp;{t('toSignIn')}
            </Link>
          </Typography>
        </div>
      </IntroScreenWrapper></>
  )
}

export default withTheme(PasswordRecovery)
