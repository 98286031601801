import React from 'react'
import { components } from 'react-select'

import Tag from '../../../atoms/Tag/Tag'

const MultiValue = (props) => {
  const {
    theme: { color },
  } = props.selectProps

  return (
    <components.MultiValueContainer {...props}>
      <Tag
        outlineColor={color.general.lighter}
        isOutlined
        color={color.general.darker}
        removeProps={props.removeProps}
        withCrossIcon
      >
        <components.MultiValueLabel {...props} />
      </Tag>
    </components.MultiValueContainer>
  )
}

export default MultiValue
