import React from 'react'

export const invitePeople = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_9628_73958)">
      <path
        d="M5.71401 5.71439C7.13417 5.71439 8.28544 4.56312 8.28544 3.14296C8.28544 1.7228 7.13417 0.571533 5.71401 0.571533C4.29385 0.571533 3.14258 1.7228 3.14258 3.14296C3.14258 4.56312 4.29385 5.71439 5.71401 5.71439Z"
        stroke={fill || color.general.white}
        strokeWidth="1.14"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14272 14.2859H0.571289V12.5716C0.580387 11.7006 0.81012 10.846 1.23903 10.0877C1.66794 9.32951 2.28203 8.69235 3.02395 8.23579C3.76586 7.77923 4.61139 7.51815 5.48156 7.47696C6.35172 7.43576 7.21813 7.61579 7.99986 8.00022"
        stroke={fill || color.general.white}
        strokeWidth="1.14"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.57153V15.4287"
        stroke={fill || color.general.white}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57129 12H15.4284"
        stroke={fill || color.general.white}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9628_73958">
        <rect width="16" height="16" fill={fill || color.general.white} />
      </clipPath>
    </defs>
  </svg>
)
