import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactTexty from 'react-texty'

import { Button, FlexRow, Icon, Tag, Typography } from '../../../atomic-design-components'
import { CURRENCY } from '../../../constants'
import { useWayforpay } from '../../../hooks/useWayforpay'
import { getPlanPrice } from '../../../utils/dataTransformations'
import { getAvailableTranslation } from '../../../utils/getAvailableTranslation'
import { StyledCardContent, StyledFlexRow, StyledOptionContainer } from './slyled'

const currency = 'UAH'

const SubscriptionPlanCard = ({
  companyId,
  customerEmail,
  customerFullName,
  customerPhone,
  plan,
  billedAnnually,
  isActive,
  isMobile,
  t,
  theme,
  trial_available,
}) => {
  const isDevEnv = process.env.NODE_ENV === 'development' || window.location.hostname.includes('skibble-dev')
  // const isDevEnv = false
  const { i18n } = useTranslation('all')

  const name = getAvailableTranslation(plan?.titles, i18n.language) // TODO: better name for function
  const isFreePlan = plan?.id === 1

  const getCurrPlanTag = () => {
    return (
      <Tag
        backgroundColor={theme.color.primary.main}
        color={theme.color.general.white}
        text={t('currentPlan')}
      />
    )
  }

  const { pay } = useWayforpay({
    billedAnnually,
    clientEmail: customerEmail,
    clientFirstName: customerFullName,
    clientLastName: customerFullName,
    clientPhone: customerPhone,
    companyId,
    language: i18n.language === 'uk' ? 'ua' : i18n.language,
    planId: plan.id,
    plan_name: name,
    regularAmount:
      process.env.NODE_ENV === 'development' || window.location.hostname.includes('skibble-dev')
        ? 10
        : billedAnnually
        ? plan?.prices?.price_annually?.[currency]
        : plan?.prices?.price_monthly?.[currency],
    trial_available,
    isDevEnv,
  })

  return (
    <StyledCardContent className={clsx(isActive ? 'active' : '')}>
      <FlexRow className="cardTableItemRow" justifyContent="space-between">
        <Typography variant={isMobile ? 'h4' : 'h3'} text={t(name)} as={ReactTexty} />
        {isActive && getCurrPlanTag()}
        {!isFreePlan && billedAnnually && (
          <Typography
            variant="caption1"
            text={`${CURRENCY[currency]} ${plan?.prices?.price_monthly?.[currency] * 12}`}
            margin="0 10px 0 auto"
            className="linedText"
            color={theme.color.general.light}
          />
        )}
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          text={getPlanPrice(plan.prices, billedAnnually ? 'annually' : 'monthly', currency, plan?.id)}
        />
      </FlexRow>

      <StyledOptionContainer>
        <FlexRow
          alignItems="start"
          flexWrap="wrap"
          flexDirection="column"
          gap="10px"
          className="optionColumn"
        >
          {Object.keys(plan.features).map((option, i) => {
            const isOptionActive = plan.features[option]
            const getText = () => {
              if (typeof plan.features[option] === 'number') {
                if (option === 'templates') {
                  if (plan.features[option] === -1) {
                    return t('unlimitTemplates')
                  } else {
                    return `${t('templatesC')}: ${plan.features[option]}`
                  }
                } else {
                  return `${t(option)}: ${plan.features[option]}`
                }
              } else {
                return t(option)
              }
            }

            return (
              <StyledFlexRow gap="10px" key={i} className={clsx(option, 'flexRow')}>
                <Icon
                  name={isOptionActive ? 'statusActive' : 'plusCircle'}
                  size={18}
                  stroke={isOptionActive ? theme.color.general.white : ''}
                  fill={isOptionActive ? theme.color.status.success : theme.color.general.white}
                  opacity={isOptionActive ? '1' : ''}
                  fillColor={!isOptionActive ? theme.color.status.error : ''}
                  className={!isOptionActive ? 'disabledIcon' : ''}
                />
                <Typography variant="body3" text={getText()} as={ReactTexty} />
              </StyledFlexRow>
            )
          })}
        </FlexRow>
      </StyledOptionContainer>

      {!isFreePlan && !isActive && (
        <Button
          text={t(trial_available ? 'trialPeriod' : 'switchToPlan')}
          fullWidth
          onClick={pay}
          margin="10px 0 0"
        />
      )}
    </StyledCardContent>
  )
}

export default SubscriptionPlanCard
