import React, { forwardRef } from 'react'

import ChatActionDivider from './ChatActionDivider'
import MessageItem from './MessageItem'

const ChatAction = forwardRef(({ data, searchValue, areaWidth, t, theme }, ref) => {
  if (['contact_message', 'operator_message', 'system_message', 'bulk_message'].includes(data.type)) {
    return <MessageItem action={data} searchValue={searchValue} ref={ref} t={t} theme={theme} />
  }

  return <ChatActionDivider action={data} ref={ref} t={t} theme={theme} areaWidth={areaWidth} />
})

export default ChatAction
