import React from 'react'
import { withTheme } from 'styled-components'

import { StyledContainer } from './styled'
import { Icon } from '../../../atomic-design-components'
import { WIDGET_BUTTON_ICONS } from '../../../constants'

const ChooseIconType = (props) => {
  const { name, value } = props

  return (
    <StyledContainer borderColor={props.theme.color.general.lighter}>
      {WIDGET_BUTTON_ICONS.map((icon) => (
        <Icon
          key={icon}
          id={icon}
          name={icon}
          size={36}
          fill={props.theme.color.general.black}
          wrapperHeight={56}
          wrapperWidth={56}
          borderRadius="5px"
          wrapperColor={value === icon ? `${props.theme.color.secondary.lightest}80` : ''}
          onClick={() => {
            props.updateInput({ target: { name, value: icon } })
          }}
        />
      ))}
    </StyledContainer>
  )
}

export default withTheme(ChooseIconType)
