import React from 'react'

export const emptyDepartments = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
    <g clipPath="url(#clip0_10869_199951)">
      <circle cx="100.004" cy="100" r="100" fill={color.general.lightest} />
      <path
        d="M133.657 111.923H101.926V98.8459C101.926 97.692 100.964 96.9229 100.003 96.9229C99.0412 96.9229 98.0797 97.8844 98.0797 98.8459V111.923H66.3488C65.1949 111.923 64.4258 112.884 64.4258 113.846V125.577C64.4258 126.731 65.3873 127.5 66.3488 127.5C67.5027 127.5 68.2719 126.538 68.2719 125.577V115.769H98.0795V125.577C98.0795 126.731 99.041 127.5 100.003 127.5C100.964 127.5 101.926 126.538 101.926 125.577V115.769H131.541V125.577C131.541 126.731 132.503 127.5 133.464 127.5C134.618 127.5 135.387 126.538 135.387 125.577V113.846C135.58 112.884 134.618 111.923 133.657 111.923Z"
        fill="#121212"
        fillOpacity="0.15"
      />
      <path
        d="M100.427 98.8461C113.915 98.8461 124.85 87.9115 124.85 74.423C124.85 60.9346 113.915 50 100.427 50C86.9385 50 76.0039 60.9346 76.0039 74.423C76.0039 87.9115 86.9385 98.8461 100.427 98.8461Z"
        fill={color.general.lighter}
      />
      <circle cx="100" cy="74" r="17" fill={color.general.white} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.93 74.4199C119.93 79.6483 117.872 84.3961 114.522 87.8978C110.984 91.5958 106.006 93.9038 100.488 93.9199C100.468 93.9199 100.449 93.9199 100.43 93.9199C100.41 93.9199 100.391 93.9199 100.371 93.9199C94.8535 93.9038 89.8748 91.5958 86.3371 87.8978C82.9874 84.3961 80.9297 79.6483 80.9297 74.4199C80.9297 63.6504 89.6601 54.9199 100.43 54.9199C111.199 54.9199 119.93 63.6504 119.93 74.4199ZM112.249 84.1699C109.439 80.7671 105.188 78.5984 100.43 78.5984C95.6719 78.5984 91.4205 80.7671 88.6103 84.1699C91.4205 87.5727 95.6719 89.7414 100.43 89.7414C105.188 89.7414 109.439 87.5727 112.249 84.1699ZM100.43 75.8127C104.276 75.8127 107.394 72.6947 107.394 68.8484C107.394 65.0021 104.276 61.8841 100.43 61.8841C96.5838 61.8841 93.4657 65.0021 93.4657 68.8484C93.4657 72.6947 96.5838 75.8127 100.43 75.8127Z"
        fill={color.general.light}
      />
      <path
        d="M66.3498 150C73.041 150 78.4652 144.576 78.4652 137.885C78.4652 131.194 73.041 125.77 66.3498 125.77C59.6586 125.77 54.2344 131.194 54.2344 137.885C54.2344 144.576 59.6586 150 66.3498 150Z"
        fill={color.general.lighter}
      />
      <circle cx="66" cy="138" r="9" fill={color.general.white} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3496 137.89C76.3496 140.571 75.2943 143.006 73.5766 144.801C71.7623 146.698 69.2092 147.881 66.3795 147.89C66.3695 147.89 66.3596 147.89 66.3496 147.89C66.3397 147.89 66.3297 147.89 66.3198 147.89C63.49 147.881 60.9368 146.698 59.1227 144.801C57.4048 143.006 56.3496 140.571 56.3496 137.89C56.3496 132.367 60.8268 127.89 66.3496 127.89C71.8725 127.89 76.3496 132.367 76.3496 137.89ZM72.4108 142.89C70.9698 141.145 68.7895 140.032 66.3496 140.032C63.9097 140.032 61.7295 141.145 60.2884 142.89C61.7295 144.635 63.9097 145.747 66.3496 145.747C68.7895 145.747 70.9698 144.635 72.4108 142.89ZM66.3498 138.604C68.3222 138.604 69.9212 137.005 69.9212 135.032C69.9212 133.06 68.3222 131.461 66.3498 131.461C64.3773 131.461 62.7784 133.06 62.7784 135.032C62.7784 137.005 64.3773 138.604 66.3498 138.604Z"
        fill={color.general.light}
      />
      <path
        d="M100.004 150C106.695 150 112.12 144.576 112.12 137.885C112.12 131.194 106.695 125.77 100.004 125.77C93.3129 125.77 87.8887 131.194 87.8887 137.885C87.8887 144.576 93.3129 150 100.004 150Z"
        fill={color.general.lighter}
      />
      <circle cx="100" cy="138" r="9" fill={color.general.white} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.004 137.89C110.004 140.571 108.949 143.006 107.231 144.801C105.417 146.698 102.863 147.881 100.034 147.89C100.024 147.89 100.014 147.89 100.004 147.89C99.9939 147.89 99.984 147.89 99.9741 147.89C97.1443 147.881 94.5911 146.698 92.7769 144.801C91.0591 143.006 90.0039 140.571 90.0039 137.89C90.0039 132.367 94.4811 127.89 100.004 127.89C105.527 127.89 110.004 132.367 110.004 137.89ZM106.065 142.89C104.624 141.145 102.444 140.032 100.004 140.032C97.564 140.032 95.3838 141.145 93.9427 142.89C95.3838 144.635 97.564 145.747 100.004 145.747C102.444 145.747 104.624 144.635 106.065 142.89ZM100.004 138.604C101.977 138.604 103.576 137.005 103.576 135.032C103.576 133.06 101.977 131.461 100.004 131.461C98.0316 131.461 96.4327 133.06 96.4327 135.032C96.4327 137.005 98.0316 138.604 100.004 138.604Z"
        fill={color.general.light}
      />
      <path
        d="M133.658 150C140.35 150 145.774 144.576 145.774 137.885C145.774 131.194 140.35 125.77 133.658 125.77C126.967 125.77 121.543 131.194 121.543 137.885C121.543 144.576 126.967 150 133.658 150Z"
        fill={color.general.lighter}
      />
      <circle cx="133" cy="138" r="9" fill={color.general.white} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.664 137.89C143.664 140.571 142.609 143.006 140.891 144.801C139.077 146.698 136.524 147.881 133.694 147.89C133.684 147.89 133.674 147.89 133.664 147.89C133.654 147.89 133.644 147.89 133.634 147.89C130.804 147.881 128.251 146.698 126.437 144.801C124.719 143.006 123.664 140.571 123.664 137.89C123.664 132.367 128.141 127.89 133.664 127.89C139.187 127.89 143.664 132.367 143.664 137.89ZM139.725 142.89C138.284 141.145 136.104 140.032 133.664 140.032C131.224 140.032 129.044 141.145 127.603 142.89C129.044 144.635 131.224 145.747 133.664 145.747C136.104 145.747 138.284 144.635 139.725 142.89ZM133.664 138.604C135.637 138.604 137.236 137.005 137.236 135.032C137.236 133.06 135.637 131.461 133.664 131.461C131.692 131.461 130.093 133.06 130.093 135.032C130.093 137.005 131.692 138.604 133.664 138.604Z"
        fill={color.general.light}
      />
    </g>
    <defs>
      <clipPath id="clip0_10869_199951">
        <rect width="200" height="200" fill={color.general.white} />
      </clipPath>
    </defs>
  </svg>
)
