import styled from 'styled-components'

export const StyledAverageComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 88px;
  padding: 10px 15px;
  border: 1px solid ${({ color }) => color.general.lighter};
  border-radius: 5px;
  max-width: 320px;
  width: 100%;

  &.rating {
    order: 0;
  }
  &.chat_duration {
    order: 1;
  }
  &.response_time {
    order: 2;
  }
`

export const StyledRatingComponent = styled.div`
  max-width: 320px;
`

export const StyledRatingLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 8px;

  .starsContainer {
    display: flex;
    gap: 4px;
    width: 100px;
  }
`

export const StyledChannelsAnalytic = styled.div`
  .headerRow {
    margin-bottom: 10px;
  }
  .channelAnalytic:nth-child(even) .valueRow {
    background-color: ${({ color }) => color.primary.main};
  }
  .channelAnalytic:last-child {
    margin-bottom: 10px;
  }
`

export const StyledAnalyticsLine = styled.div`
  width: ${({ width }) => width || '100%'};
  height: 4px;
  border-radius: 3px;
  background-color: ${({ color }) => color.secondary.main}1a;
  position: relative;

  .valueRow {
    width: ${({ value }) => value}%;
    height: 4px;
    border-radius: 3px;
    background-color: ${({ color }) => color.secondary.main};
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const StyledAnalyticsCircle = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;

  .legend {
    .nameText {
      margin-right: auto;
    }
  }
  .circleContainer {
    width: 100px;
    height: 100px;
    position: relative;
    margin: 5px auto;
    margin-bottom: auto;

    svg {
      filter: drop-shadow(0px 0px 5px ${({ color }) => `${color.general.dark}90`});
    }
  }
  &.tags {
    order: 6;
    max-height: 290px !important;
    height: fit-content !important;
    .legend {
      margin: 20px 0;
      > div {
        padding: 11px 0;
      }
    }
  }
  &.device {
    order: 1;
  }
  &.device_os {
    order: 2;
  }
  &.browser {
    order: 3;
  }
  &.browser_lang {
    order: 4;
  }
  &.timezone {
    order: 5;
  }
`
