import React from 'react'

export const calendar2 = ({ width, height, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M15.2854 4.49484H4.80326C4.1222 4.49484 3.57007 5.04698 3.57007 5.72804V7.57783H16.5186V5.72804C16.5186 5.04698 15.9665 4.49484 15.2854 4.49484Z"
      fill={color.secondary.lighter || '#99CCF1'}
    />
    <path d="M6.15978 5.41974V3.56995V5.41974Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M13.9289 5.41974V3.56995V5.41974Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M11.3392 5.41974V3.56995V5.41974Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M8.74949 5.41974V3.56995V5.41974Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M11.0309 9.65885H11.8016H11.0309Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M8.28704 9.65885H9.05779H8.28704Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M13.7748 9.65885H14.5455H13.7748Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M5.54318 11.7399H6.31393H5.54318Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M8.28704 11.7399H9.05779H8.28704Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M11.0309 11.7399H11.8016H11.0309Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M5.54318 13.8209H6.31393H5.54318Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path d="M8.28704 13.8209H9.05779H8.28704Z" fill={color.secondary.lighter || '#99CCF1'} />
    <path
      d="M18.4301 15.0387C18.4301 16.9116 16.9117 18.4299 15.0388 18.4299C13.1658 18.4299 11.6475 16.9116 11.6475 15.0387C11.6475 13.1657 13.1658 11.6474 15.0388 11.6474C16.9117 11.6474 18.4301 13.1657 18.4301 15.0387Z"
      fill={color.secondary.lighter || '#99CCF1'}
    />
    <path d="M15.0388 13.4972V15.0387H16.5803" fill={color.secondary.lighter || '#99CCF1'} />
    <path
      d="M16.5186 11.9865V7.57783H3.57007V14.977C3.57007 15.4878 3.98418 15.9019 4.49496 15.9019H11.7585"
      fill={color.secondary.lighter || '#99CCF1'}
    />
    <path
      d="M3.57007 7.57783V5.72804C3.57007 5.04698 4.1222 4.49484 4.80326 4.49484H15.2854C15.9665 4.49484 16.5186 5.04698 16.5186 5.72804V7.57783M3.57007 7.57783H16.5186M3.57007 7.57783V14.977C3.57007 15.4878 3.98418 15.9019 4.49496 15.9019H11.7585M16.5186 7.57783V11.9865M6.15978 5.41974V3.56995M13.9289 5.41974V3.56995M11.3392 5.41974V3.56995M8.74949 5.41974V3.56995M11.0309 9.65885H11.8016M8.28704 9.65885H9.05779M13.7748 9.65885H14.5455M5.54318 11.7399H6.31393M8.28704 11.7399H9.05779M11.0309 11.7399H11.8016M5.54318 13.8209H6.31393M8.28704 13.8209H9.05779M15.0388 13.4972V15.0387H16.5803M18.4301 15.0387C18.4301 16.9116 16.9117 18.4299 15.0388 18.4299C13.1658 18.4299 11.6475 16.9116 11.6475 15.0387C11.6475 13.1657 13.1658 11.6474 15.0388 11.6474C16.9117 11.6474 18.4301 13.1657 18.4301 15.0387Z"
      stroke={color.secondary.darker || '#003FBC'}
      strokeWidth="1.14"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
