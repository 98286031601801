import React from 'react'

import { Button } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectCurrentChatId, selectUserName } from '../../../redux-saga/selectors'
import { useSendEventWebsocket } from '../useChatsWebsockets'

const AcceptButton = ({ t, theme }) => {
  const currentUserName = useMappedState(selectUserName)
  const chatId = useMappedState(selectCurrentChatId)

  const onAcceptSuccess = (eventType, callback) => {
    if (eventType === 'operator_joined') {
      callback()
    }
  }

  const onAccept = (callback) => {
    // console.log('accept')
    sendEventToServer({
      chat_id: +chatId,
      type: 'operator_joined',
      operator_name: currentUserName,
    })
    callback()
  }

  const { sendEventToServer, onSendEvent } = useSendEventWebsocket(onAccept, onAcceptSuccess)

  if (!chatId) {
    return null
  }

  const iconLeftProps = {
    name: 'statusActive',
    fill: 'none',
    stroke: theme.color.general.white,
  }

  return (
    <Button
      variant="primary"
      iconLeftProps={iconLeftProps}
      text={t('acceptChat')}
      onClick={onSendEvent('operator_joined')}
      margin="0 20px 0 0"
    />
  )
}

export default AcceptButton
