import styled from 'styled-components'
import { getCssFromTheme } from '../../../utils/css'

export const StyledLabel = styled.div`
  ${({ theme, size }) => getCssFromTheme(theme, 'label', size)};
  text-transform: ${({ $isUppercase }) => $isUppercase && 'uppercase'};
  display: inline-flex;
  color: ${({ color, theme }) => color || theme.color.general.black};
  margin: ${({ margin }) => margin};
`
