import React from 'react'

export const deleteAccount = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 200} height={height || 200} viewBox="0 0 200 200" fill="none">
    <circle cx="100" cy="100" r="100" fill="#F9CEC2" />
    <g filter="url(#filter0_d_12830_21914)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.577 50C123.202 51.6068 123.004 53.2807 123.004 55C123.004 67.1313 132.873 77 145.004 77C146.723 77 148.397 76.8018 150.004 76.427V140C150.004 145.523 145.527 150 140.004 150H60.0039C54.4811 150 50.0039 145.523 50.0039 140V60C50.0039 54.4772 54.4811 50 60.0039 50H123.577Z"
        fill="#E83C0C"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.003 70.6047C103.453 70.6047 106.825 71.6277 109.694 73.5442C112.562 75.4608 114.797 78.1848 116.118 81.3719C117.438 84.559 117.783 88.0659 117.11 91.4493C116.437 94.8327 114.776 97.9406 112.337 100.38C109.897 102.819 106.789 104.48 103.406 105.153C100.023 105.826 96.5157 105.481 93.3286 104.161C90.1415 102.841 87.4175 100.605 85.501 97.7368C83.5844 94.8685 82.5615 91.4963 82.5615 88.0466C82.5664 83.4222 84.4056 78.9887 87.6755 75.7188C90.9454 72.4489 95.379 70.6097 100.003 70.6047ZM100.003 98.5117C105.783 98.5117 110.468 93.8264 110.468 88.0466C110.468 82.2669 105.783 77.5815 100.003 77.5815C94.2236 77.5815 89.5382 82.2669 89.5382 88.0466C89.5382 93.8264 94.2236 98.5117 100.003 98.5117Z"
      fill="white"
    />
    <path
      d="M123.775 129.065C123.634 129.084 123.491 129.093 123.347 129.093C122.496 129.092 121.675 128.779 121.038 128.215C120.401 127.65 119.991 126.873 119.887 126.028C119.504 122.929 118.002 120.077 115.664 118.008C113.325 115.938 110.312 114.794 107.189 114.791H92.8173C89.6949 114.794 86.6813 115.938 84.343 118.008C82.0047 120.077 80.5028 122.929 80.1196 126.028C80.0067 126.946 79.5337 127.782 78.8045 128.352C78.0752 128.921 77.1496 129.178 76.2312 129.065C75.3129 128.952 74.4769 128.479 73.9073 127.75C73.3377 127.021 73.0812 126.095 73.194 125.177C73.787 120.388 76.1083 115.981 79.7219 112.784C83.3354 109.587 87.9923 107.819 92.8173 107.814H107.189C112.014 107.819 116.671 109.587 120.285 112.784C123.898 115.981 126.22 120.388 126.813 125.177C126.869 125.632 126.834 126.093 126.712 126.534C126.59 126.976 126.382 127.389 126.099 127.75C125.817 128.111 125.467 128.413 125.068 128.639C124.669 128.864 124.23 129.009 123.775 129.065Z"
      fill="white"
    />
    <path
      d="M145.004 37C135.078 37 127.004 45.0744 127.004 55C127.004 64.9256 135.078 73 145.004 73C154.93 73 163.004 64.9256 163.004 55C163.004 45.0744 154.93 37 145.004 37Z"
      fill="#E83C0C"
    />
    <path
      d="M151.634 59.5075C152.221 60.0941 152.221 61.042 151.634 61.6286C151.342 61.9212 150.958 62.0681 150.574 62.0681C150.19 62.0681 149.806 61.9212 149.513 61.6286L145.006 57.1209L140.498 61.6286C140.206 61.9212 139.822 62.0681 139.438 62.0681C139.054 62.0681 138.67 61.9212 138.377 61.6286C137.791 61.042 137.791 60.0941 138.377 59.5075L142.885 55L138.377 50.4926C137.791 49.906 137.791 48.9581 138.377 48.3714C138.964 47.785 139.912 47.785 140.498 48.3714L145.006 52.8791L149.513 48.3714C150.1 47.785 151.048 47.785 151.634 48.3714C152.221 48.9581 152.221 49.906 151.634 50.4926L147.127 55L151.634 59.5075Z"
      fill="#FAFAFA"
    />
    <defs>
      <filter
        id="filter0_d_12830_21914"
        x="35.0039"
        y="49"
        width="130"
        height="130"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.909804 0 0 0 0 0.235294 0 0 0 0 0.0470588 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12830_21914" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12830_21914" result="shape" />
      </filter>
    </defs>
  </svg>
)
