import React from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { StyledColor } from './styled'

const Color = ({ color, dataTooltip, disabled, selected, size, withBorder, ...rest }) => {
  if (!color) {
    return null
  }

  return (
    <StyledColor
      className={clsx('color', selected && 'selected', disabled && 'disabled')}
      color={typeof color === 'string' ? color : color?.key}
      data-tooltip={dataTooltip}
      size={size}
      withBorder={withBorder}
      {...rest}
    />
  )
}

export default Color

Color.propTypes = {
  color: T.string,
  dataTooltip: T.string,
  disabled: T.bool,
  selected: T.bool,
  size: T.number,
  withBorder: T.bool,
}
