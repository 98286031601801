import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { DEFAULT_LANGUAGE, resources } from './locales'

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    resources,
    load: 'languageOnly',
    languages: Object.keys(resources),
    fallbackLng: DEFAULT_LANGUAGE,
    lng: DEFAULT_LANGUAGE,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
