import styled from 'styled-components'

export const StyledSwitchableCardWithFields = styled.div`
  border: 1px solid ${({ theme }) => theme.color.general.lighter};
  border-radius: 3px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .inputContainer {
    margin: 0;
  }
`
