import React from 'react'

export const equalizer = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M1.08594 2.45692L1.08594 17.5426C1.08594 18.3001 1.69995 18.9141 2.45737 18.9141H17.5431C18.3005 18.9141 18.9145 18.3001 18.9145 17.5426V2.45692C18.9145 1.6995 18.3005 1.08549 17.5431 1.08549H2.45737C1.69995 1.08549 1.08594 1.6995 1.08594 2.45692Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.57129 15.486V8.62891"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.57179 8.62796C7.70792 8.62796 8.62893 7.70694 8.62893 6.57082C8.62893 5.43469 7.70792 4.51367 6.57179 4.51367C5.43566 4.51367 4.51465 5.43469 4.51465 6.57082C4.51465 7.70694 5.43566 8.62796 6.57179 8.62796Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4287 4.51367V8.62796"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4287 12.7422V15.485"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4292 12.7432C14.5653 12.7432 15.4864 11.8222 15.4864 10.686C15.4864 9.54992 14.5653 8.62891 13.4292 8.62891C12.2931 8.62891 11.3721 9.54992 11.3721 10.686C11.3721 11.8222 12.2931 12.7432 13.4292 12.7432Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
