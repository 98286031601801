import styled from 'styled-components'

export const StyledCardContent = styled.div`
  .caption1 {
    margin-left: 10px;
  }
  .squezzyBlock {
    position: relative;
    min-width: 124px;
  }
  .button1 {
    display: flex;
  }
  .bottom.tooltip {
    right: auto;
    left: 0;
    white-space: pre-wrap;
    .tooltip-arrow {
      right: auto !important;
      left: 9% !important;
    }
  }
`

export const StyledPanel = styled.div`
  .codePanel {
    border: 1px solid ${({ theme }) => theme.color.general.light};
    margin: 10px 0;
    .content {
      font-size: 12px;
      display: block;

      [data-texty] {
        white-space: normal;
      }
    }
  }
`
export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  .icon {
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
  }
`

export const StyledFieldsCustomisation = styled.div`
  .row {
    display: grid;
    grid-template-columns: 150px repeat(2, 1fr);
    grid-template-rows: 1fr;
    margin: 8px 0;
    align-items: center;
    &.header {
      border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    }
  }
  .col2,
  .col3 {
    display: flex;
    justify-content: flex-end;
  }
  .rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .inputContainer {
    padding-top: 10px;
    width: 100%;
    .label:not(.error) {
      color: ${({ theme }) => theme.color.general.dark};
    }

    .inputWrapper {
      &:not(.hasError):not(.selectWrapper):not(.searchInput):focus-within:not(.hasError) {
        border: 1px solid ${({ theme }) => theme.color.general.light};
        outline: none;

        .label {
          color: ${({ theme }) => theme.color.general.dark} !important;
        }

        .icon svg {
          path {
            stroke: ${({ theme }) => theme.color.general.dark};
          }
        }
      }
    }
  }
`
