import React from 'react'

import WidgetStartHeader from './components/WidgetStartHeader'
import Channels from './components/Channels'
import WidgetFooter from './components/WidgetFooter'
import { StyledScreenWrapper } from './styled'
import clsx from 'clsx'

const ChooseChannels = ({
  className,
  channels,
  color,
  hideWidget,
  is_hide_branding,
  getT,
  goOneScreenForward,
}) => {
  const isOnlyLiveChatActive =
    channels &&
    !Object.keys(channels).some((channel) => channel !== 'live_chat' && channels[channel].status === 'active')

  return (
    <StyledScreenWrapper className={clsx(className, isOnlyLiveChatActive && 'isOnlyLiveChatActive')}>
      <WidgetStartHeader
        color={color}
        hideWidget={hideWidget}
        goOneScreenForward={goOneScreenForward}
        getT={getT}
        subtextKey="quickWayToContact"
        currentScreen="chooseChannels"
      />

      <Channels channels={channels} color={color} getT={getT} goOneScreenForward={goOneScreenForward} />
      {!is_hide_branding && <WidgetFooter getT={getT} />}
    </StyledScreenWrapper>
  )
}

export default ChooseChannels
