import React from 'react'
import { PropTypes as T } from 'prop-types'
import { parse } from 'query-string'
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StyledPaginationWrapper } from './styled'
import { Typography, Icon, Button } from '../../../atomic-design-components'
import theme from '../../../theme'
import { useWidth } from '../../../hooks/useWidth'

const Pagination = ({ rowsCount, itemsCount }) => {
  if (!itemsCount || !rowsCount || itemsCount < rowsCount + 1) {
    return null
  }

  const { t } = useTranslation('all')
  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.lg

  const { search } = useLocation()
  const navigate = useNavigate()
  const searchParsed = parse(search)

  const currentPage = parseInt(searchParsed.page) || 1

  const totalPageCount = Math.ceil(itemsCount / rowsCount)
  const indexOfLastRecord = currentPage * rowsCount > itemsCount ? itemsCount : currentPage * rowsCount
  const indexOfFirstRecord =
    indexOfLastRecord === itemsCount
      ? (totalPageCount - 1) * rowsCount + 1
      : indexOfLastRecord - rowsCount + 1

  const text = isMobile
    ? `${indexOfFirstRecord} - ${indexOfLastRecord}`
    : `${indexOfFirstRecord} - ${indexOfLastRecord} ${t('of')} ${itemsCount}`

  const isDisabledPrev = currentPage === 1
  const isDisabledNext = currentPage === totalPageCount

  const onPageChange = (page) => {
    if (page === 1) {
      if (searchParsed.page) {
        delete searchParsed.page
      }
      navigate({
        search: createSearchParams(searchParsed).toString(),
      })
    } else {
      searchParsed.page = page
      navigate({
        search: createSearchParams(searchParsed).toString(),
      })
    }
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  return (
    <StyledPaginationWrapper>
      <Typography variant="input" text={text} className="paginationText" />
      <div className="buttonWrapper">
        <Button
          variant="general"
          disabled={isDisabledPrev}
          className="previousPageButton"
          onClick={onPrevious}
        >
          <Icon name="paginationArrow" fill={!isDisabledPrev ? theme.color.general.black : ''} />
        </Button>
        <Button variant="general" disabled={isDisabledNext} className="nextPageButton" onClick={onNext}>
          <Icon name="paginationArrow" fill={!isDisabledNext ? theme.color.general.black : ''} />
        </Button>
      </div>
    </StyledPaginationWrapper>
  )
}

export default Pagination

Pagination.propTypes = {
  rowsCount: T.number,
  itemsCount: T.number,
}

Pagination.defaultProps = {
  rowsCount: 100,
}
