import {
  POPUP_ALERT_HIDE,
  POPUP_ALERT_HIDE_ALL,
  POPUP_ALERT_SHOW,
  SET_NOTIFICATIONS,
  SIGN_OUT,
  TOGGLE_DRAWER_EXTENDED,
  TOGGLE_NAV_MENU_EXTENDED,
} from '../constants'

const defaultState = {
  isNavMenuExtended: false,
  isDrawerExtended: false,
  popupAlerts: [],
  notifications: [],
}

const buildPopupAlerts = (alerts, payload) => {
  let updatedPopupAlerts = alerts
  const {
    id,
    type,
    contentKey,
    contentParams,
    content,
    iconName,
    timeout,
    stacked,
    withCloseButton,
    className,
    // responsiveFlow,
  } = payload

  if (stacked) {
    const similarItemIndex = alerts.findIndex(
      (alert) => type === alert.type && (content === alert.content || contentKey === alert.contentKey)
    )

    if (similarItemIndex !== -1) {
      updatedPopupAlerts.splice(similarItemIndex, 1)
    }
  }

  const newAlert = {
    timeout,
    id,
    type,
    content,
    contentKey,
    contentParams,
    withCloseButton,
    iconName,
    className,
  }

  if (updatedPopupAlerts.length > 0 && timeout > 0 && withCloseButton) {
    const warningAlerts = updatedPopupAlerts.filter((alert) => alert.timeout === 0 && !alert.withCloseButton)
    const otherAlerts = updatedPopupAlerts.filter((alert) => alert.timeout !== 0 && alert.withCloseButton)

    return [...warningAlerts, newAlert, ...otherAlerts]
  }

  return [newAlert, ...updatedPopupAlerts]
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_NAV_MENU_EXTENDED: {
      const isNavMenuExtended =
        typeof action.extendedValue === 'undefined' ? !state.isNavMenuExtended : action.extendedValue
      localStorage.setItem('menuState', JSON.stringify({ isExtended: isNavMenuExtended }))

      return {
        ...state,
        isNavMenuExtended,
      }
    }
    case TOGGLE_DRAWER_EXTENDED: {
      const isDrawerExtended =
        typeof action.extendedValue === 'undefined' ? !state.isDrawerExtended : action.extendedValue
      localStorage.setItem('rightDrawerState', JSON.stringify({ isExtended: isDrawerExtended }))

      return {
        ...state,
        isDrawerExtended,
      }
    }
    case POPUP_ALERT_SHOW:
      return { ...state, popupAlerts: buildPopupAlerts(state.popupAlerts, action.payload) }
    case POPUP_ALERT_HIDE:
      return {
        ...state,
        popupAlerts: state.popupAlerts.filter((alert) => alert.id !== action.id),
      }
    case POPUP_ALERT_HIDE_ALL:
      return {
        ...state,
        popupAlerts: state.popupAlerts.filter((alert) => alert.timeout === 0),
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [{ ...action.notification, isNew: true }, ...state.notifications],
      }
    // case MARK_NOTIFICATION_AS_READ:
    //   return {
    //     ...state,
    //     notifications: state.notifications.map(notification => {
    //       if (notification.entity.id === action.id) {
    //         return { ...notification, isNew: false }
    //       } else {
    //         return notification
    //       }
    //     }),
    //   }
    // case CLEAR_NOTIFICATIONS:
    //   return { ...state, notifications: [] }
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
