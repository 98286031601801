import React from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { StyledLabel } from './styled'

const Label = ({ as, children, className, color, icon, margin, onClick, isUppercase, text, size }) => {
  return (
    <StyledLabel
      as={as}
      color={color}
      size={size}
      margin={margin}
      onClick={onClick}
      className={clsx(className, 'label')}
      $isUppercase={isUppercase}
    >
      {icon}
      {children || text}
    </StyledLabel>
  )
}

export default Label

Label.propTypes = {
  children: T.node,
  className: T.string,
  color: T.string,
  margin: T.string,
  onClick: T.func,
  text: T.string,
  isUppercase: T.bool,
  size: T.oneOf(['small', 'medium', 'big']),
}

Label.defaultProps = {
  size: 'big',
}
