import React from 'react'
import clsx from 'clsx'

import { ExpansionPanel, Typography, Badge } from '../../../atomic-design-components'
import TemplatesListItem from './TemplatesListItem'
import { StyledContainer } from './styled'
import { StyledHeader } from './styled'

const TemplatesList = ({ type, templateData, t, variant, className }) => {
  const total_items = templateData?.length || '0'

  const panelHeader = (
    <StyledHeader>
      <Typography text={t(type)} variant="button1" />
      <Badge returnBadgeOnly badgeContent={total_items} className="itemsCountBadge" />
    </StyledHeader>
  )

  const getTemplatesList = () => {
    return templateData?.map((template) => {
      return <TemplatesListItem data={template} key={template.id} type={type} t={t} variant={variant} />
    })
  }

  return (
    <StyledContainer className={clsx(type, className)}>
      <ExpansionPanel
        header={panelHeader}
        withChevronInHeader={!!templateData}
        initialOpened
        panelName={`${type}TemplatesLeftPanel`}
      >
        {getTemplatesList()}
      </ExpansionPanel>
    </StyledContainer>
  )
}

export default TemplatesList
