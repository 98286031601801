import clsx from 'clsx'
import parse from 'html-react-parser'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { Badge, FlexRow, Icon, Typography } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectIsContactOnline, selectNewChatNotifications } from '../../../redux-saga/selectors'
import { useTransformDateFromNow } from '../../../utils/dates'
import { StyledItemContent } from './styled'

const ChatsListItem = ({ data: chatData, type, t, theme }) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { chatId, action } = useParams()

  const newChatHistoryNotifications = useMappedState(selectNewChatNotifications)
  const hasNewMessages = newChatHistoryNotifications?.[chatData.id]?.length > 0

  const isClosedChat = type === 'closed'

  const updateDate = useTransformDateFromNow(chatData.last_message_at)

  const onChatClick = () => {
    if (action && action === 'templates' && !isClosedChat) {
      navigate({ pathname: `${chatData.id}/templates`, search })
    } else {
      navigate({ pathname: `${chatData.id}`, search })
    }
  }

  const contactName = chatData?.contact_name || `${t('guest')} #${chatData?.contact_id}`

  const isContactOnline =
    chatData.channel !== 'live_chat' || useMappedState(selectIsContactOnline(chatData?.contact_id))

  const getLastMessage = () => {
    if (chatData?.last_message?.text) {
      return <Typography className="userMessage" variant="input" text={parse(chatData?.last_message?.text)} />
    }
    if (chatData?.last_message?.files) {
      return (
        <div style={{ display: 'flex', gap: '5px' }}>
          <Icon
            name="attachSign"
            size={14}
            margin="0 !important"
            fill={theme.color.general.dark}
            className="attachIcon"
          />
          <Typography
            className="userMessage"
            variant="input"
            text={t('attachment')}
            color={theme.color.general.dark}
          />
        </div>
      )
    }
  }

  return (
    <StyledItemContent
      className={clsx(`${type}Chat`, +chatId === +chatData.id && 'active')}
      onClick={onChatClick}
    >
      {!isClosedChat && hasNewMessages && <Badge isMini className="newMessagesBadge" />}
      <FlexRow justifyContent="space-between" flexWrap="wrap">
        <FlexRow>
          <Typography variant="caption1" text={contactName} as={ReactTexty} />
          <Icon name={`${chatData?.channel}Icon`} width={18} height={18} />
        </FlexRow>
        <Typography variant="caption3" text={updateDate} />
      </FlexRow>
      {!isClosedChat && (
        <FlexRow justifyContent="space-between">
          {getLastMessage()}
          {!isContactOnline && (
            <Icon
              name="offline"
              width={8}
              height={8}
              className="offline"
              fill={theme.color.general.white}
              wrapperColor={theme.color.general.light}
              borderRadius="50%"
              wrapperHeight={16}
              wrapperWidth={16}
            />
          )}
          {isContactOnline && chatData.isForwarded && (
            <>
              {chatData.chat_data?.to_operator_id && <Icon name="forward" />}
              {chatData.chat_data?.to_department_id && <Icon name="forwardToDepartment" />}
            </>
          )}
        </FlexRow>
      )}
    </StyledItemContent>
  )
}

export default withTheme(ChatsListItem)
