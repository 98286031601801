import clsx from 'clsx'
import { PropTypes as T } from 'prop-types'
import React from 'react'

import Typography from '../Typography'
import { StyledTooltip } from './styled'

const Tooltip = ({
  children,
  className,
  disableTooltip,
  left,
  right,
  text,
  textVariant,
  color,
  textColor,
  tipPosition,
  arrowPosition,
  whiteSpace,
  width,
  ...rest
}) => {
  if (disableTooltip) {
    return children
  }

  const [show, setShow] = React.useState(false)

  return (
    <StyledTooltip
      color={color}
      textColor={textColor}
      className={className}
      left={left}
      right={right}
      whiteSpace={whiteSpace}
      width={width}
    >
      <div className={clsx(tipPosition, 'tooltip')} style={show ? { visibility: 'visible' } : {}}>
        <Typography text={text} variant={textVariant || 'button2'} className="tooltipText" />
        <span className={clsx(arrowPosition, 'tooltip-arrow')} />
      </div>
      <div {...rest} onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </div>
    </StyledTooltip>
  )
}

export default Tooltip

Tooltip.propTypes = {
  children: T.node,
  className: T.string,
  color: T.string,
  left: T.string,
  right: T.string,
  textColor: T.string,
  text: T.string,
  tipPosition: T.oneOf(['top', 'bottom']),
  arrowPosition: T.oneOf(['left', 'center', 'right']),
  whiteSpace: T.string,
}

Tooltip.defaultProps = {
  tipPosition: 'bottom',
  arrowPosition: 'center',
}
