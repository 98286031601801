import React from 'react'

export const plus = ({ width, height, fill, fillColor, stroke, theme: { color } }) => (
  <svg width={width || 22} height={height || 22} viewBox="0 0 22 22" fill={fillColor || 'none'}>
    <path
      d="M11 1.40234V20.7166"
      stroke={stroke || color.general.black}
      strokeWidth="1.14"
      strokeLinecap="round"
    />
    <path
      d="M1.34277 11H20.6571"
      stroke={stroke || color.general.black}
      strokeWidth="1.14"
      strokeLinejoin="round"
    />
  </svg>
)
