import React from 'react'

export const typeTXT = ({ width, height, fill, theme: { color } }) => {
  return (
    <svg width={width || 30} height={height || 36} viewBox="0 0 30 36" fill="none">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H18L30 12V33C30 34.6569 28.6569 36 27 36H3C1.34315 36 0 34.6569 0 33V3Z"
        fill="#9D9D9D"
      />
      <path d="M30 12H21C19.3431 12 18 10.6569 18 9V0L30 12Z" fill="#616161" />
      <path
        d="M6.09109 23.5955V22.4545H11.4668V23.5955H9.46289V29H8.09499V23.5955H6.09109ZM13.6497 22.4545L14.9696 24.6854H15.0208L16.3471 22.4545H17.91L15.9125 25.7273L17.9547 29H16.3631L15.0208 26.766H14.9696L13.6273 29H12.0421L14.0907 25.7273L12.0804 22.4545H13.6497ZM18.5364 23.5955V22.4545H23.9121V23.5955H21.9082V29H20.5403V23.5955H18.5364Z"
        fill="white"
      />
    </svg>
  )
}
