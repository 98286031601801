import React from 'react'
import { useDispatch } from 'react-redux'

import Form from '../../../components/Form'
import { sendInvitations } from '../../../redux-saga/actions'
import theme from '../../../theme'

const MembersInvite = () => {
  const dispatch = useDispatch()

  const formConfig = {
    validationRules: {
      email: ['required', 'email'],
    },
    fields: [
      {
        key: 'email',
        label: 'email',
        iconLeftProps: { name: 'doggySign', fill: theme.color.general.light },
      },
    ],
  }

  const onFormSubmit = (valuesChanged) => {
    dispatch(sendInvitations({ email: valuesChanged.email }))
  }

  const iconProps = { name: 'mailSend', margin: '0 6px 0', fill: theme.color.general.white }

  return (
    <Form
      // initialValues={formDataInitial}
      expansionPanelProps={{
        disabled: true,
        withChevronInHeader: false,
      }}
      formConfig={formConfig}
      validationRules={formConfig.validationRules}
      onSubmit={onFormSubmit}
      isSubmitButtonAlwaysShown
      withActions
      withCancelButton={false}
      saveBtnTextKey="sendInvitation"
      saveBtnIconProps={iconProps}
    />
  )
}

export default MembersInvite
