import styled from 'styled-components'

export const StyledScreenWrapper = styled.div`
  margin-bottom: 20px;

  .widgetFooter {
    margin-bottom: -20px;
  }

  .selectWrapper {
    width: 100%;
    pointer-events: none;
  }

  .inputContainer {
    pointer-events: none;
  }

  .checkbox {
    margin: 0 20px;
    align-items: flex-start;
    pointer-events: none;

    .icon {
      margin-top: 4px;
    }

    .typography {
      span {
        cursor: default;
      }
      a {
        color: ${({ theme, color }) => color || theme.color.primary.main};
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .inputContainer {
    margin-right: 20px;
    margin-left: 20px;

    input,
    .inputWrapper,
    .selectWrapper,
    .react-select__control {
      border-radius: 10px;
    }
  }

  .textarea {
    padding-top: 0;
    .inputContainer {
      margin-right: 0;
      margin-left: 0;
    }
    pointer-events: none;
    .inputWrapper,
    textarea {
      border-radius: 10px;
    }
    .textareaActionButton {
      border-radius: 5px;
      padding: 12px;
      .icon {
        margin: 0;
      }
    }
    .sendButton {
      background-color: ${({ theme }) => theme.color.general.white};
      border-color: ${({ theme }) => theme.color.general.lighter};
      &:hover:not(.disabled) {
        background-color: ${({ theme }) => theme.color.general.lighter} !important;
      }
    }
  }
`

export const StyledWidgetStartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 37px;
  background-color: ${({ color, theme }) => color || theme.color.primary.main};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .preChatForm &,
  .isOnlyLiveChatActive & {
    padding: 20px;
  }

  .preChatForm & {
    margin-bottom: 20px;
  }

  .closeIcon,
  .arrowLeft {
    border-radius: 10px;
    padding: 5px;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.color.general.white}40;
  }

  //.closeIcon {
  //  margin-left: 10px;
  //}

  .divToCenterTitle {
    width: 32px;
    height: 32px;
    margin-right: auto;
  }
`

export const StyledWidgetTitle = styled.div`
  //display: flex;
  color: ${({ theme }) => theme.color.general.white};

  .typography {
    display: block;

    &.subtext {
      opacity: 0.8;
    }
  }
`

export const StyledChannelsContainer = styled.div`
  padding: 0 20px;
  margin-top: -22px;

  .isOnlyLiveChatActive & {
    margin-top: 0;
  }

  button {
    border-radius: 10px;
    border: transparent;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
    justify-content: flex-start;

    &:hover {
      &:not(.disabled) {
        opacity: 1;
      }
    }
  }

  & ~ button.live_chat {
    width: calc(100% - 40px);
    margin: 10px 20px;
    border-radius: 10px;
  }
`
