import React from 'react'
import { withTheme } from 'styled-components'

import { FlexRow, Icon, Typography, Link } from '../../../../../atomic-design-components'

const WidgetFooter = ({ className, getT, theme }) => {
  return (
    <FlexRow className="widgetFooter" justifyContent="center" padding="10px 20px 10px">
      <Typography variant="body2" text={getT('poweredBy')} margin="0 6px 0 0" />
      <Link isOuterLink href="https://www.skibble.chat/" noStyles>
        <Icon name="skibbleTextLogo" fill="#FF5F00" height={10} width={84} />
      </Link>
    </FlexRow>
  )
}

export default withTheme(WidgetFooter)
