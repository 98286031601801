import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useMappedState } from '../hooks/useReactRedux'
import PopupAlert from '../atomic-design-components/molecules/PopupAlert'
import { popupAlertHide } from '../redux-saga/actions'
import { selectPopupAlerts } from '../redux-saga/selectors'

const StyledPopupAlerts = styled.div`
  position: absolute;
  top: 20px;
  z-index: 100;
  width: fit-content;
  transform: translate(50%, 0);
  right: 50%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: 10px;
    width: 80%;
  }
`

const PopupAlertsComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('popupAlerts')
  const popupAlerts = useMappedState(selectPopupAlerts)

  return (
    <StyledPopupAlerts>
      {popupAlerts.map((alert) => (
        <PopupAlert
          {...alert}
          key={alert.id}
          onClose={(id) => dispatch(popupAlertHide(id))}
          content={alert.content || t(alert.contentKey, alert.contentParams)}
          index={popupAlerts.indexOf(alert)}
        />
      ))}
    </StyledPopupAlerts>
  )
}

export default PopupAlertsComponent
