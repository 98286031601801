import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'
// import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { useTranslation } from 'react-i18next'

import DropZone from '../DropZone'
import { FILE_TYPES } from '../../constants'
import { Typography, Button, Image } from '../../atomic-design-components'
import { StyledProfilePicture } from '../../containers/SettingsProfile/styled'
import { isObjectEmpty } from '../../utils/isObjectEmpty'
import { getUploadedData, upsertSidebarItem } from '../../redux-saga/actions'

// const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
//   return centerCrop(
//     makeAspectCrop(
//       {
//         unit: '%',
//         width: 90,
//       },
//       aspect,
//       mediaWidth,
//       mediaHeight
//     ),
//     mediaWidth,
//     mediaHeight
//   )
// }

const ProfilePicture = ({ props, theme, memberID }) => {
  const { t } = useTranslation('all')
  const dispatch = useDispatch()
  const location = useLocation()

  const [avatar, setAvatar] = useState(props.value)

  useEffect(() => {
    setAvatar(props.value)
  }, [props.value])
  // const [crop, setCrop] = useState()
  // const [completedCrop, setCompletedCrop] = useState()
  // const [scale, setScale] = useState(1)
  // const [rotate, setRotate] = useState(0)
  // const [aspect, setAspect] = useState(1 / 1)
  // const imgRef = useRef(null)

  // function onImageLoad(e) {
  //   if (aspect) {
  //     const { width, height } = e.currentTarget
  //     setCrop(centerAspectCrop(width, height, aspect))
  //   }
  // }

  const fnSet = (newFile) => {
    if (!isObjectEmpty(newFile)) {
      dispatch(getUploadedData(newFile, '', memberID, location))
    }
  }

  const onRemoveAvatar = () => {
    dispatch(
      upsertSidebarItem({
        id: memberID,
        type: 'members',
        requestBody: { profile_picture: null },
        location,
      })
    )
  }
  return (
    <StyledProfilePicture>
      {/* <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => setCompletedCrop(c)}
        aspect={aspect}
      > */}
      <Image
        // ref={imgRef}
        alt="Crop me"
        src={avatar}
        // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
        // onLoad={onImageLoad}
        height={56}
        radius="50%"
        width={56}
      />
      {/* </ReactCrop> */}
      <Typography variant="body3" text={t('profileImageTip')} color={theme.color.general.dark} />
      <Button
        variant="general"
        withoutBorder
        hoverType="opacity"
        padding="10px"
        iconLeftProps={{
          name: 'trashCan',
          size: 16,
          fill: theme.color.general.black,
          className: 'withoutHover',
        }}
        className="removeButton"
        onClick={onRemoveAvatar}
      />
      <Button
        variant="primary"
        iconLeftProps={{
          name: 'upload',
          fill: theme.color.general.white,
          className: 'withoutHover',
        }}
        className="attachButton"
      >
        <Typography variant="button1" text={t('upload')} />
        <DropZone
          fileType={{ ...FILE_TYPES.images }}
          setFiles={fnSet}
          type={props.name}
          // currentFiles={draftFiles[chatID]}
          isSimpleButton={true}
          maxFiles={1}
          maxSize={20971520}
          className="profileDrop"
        />
      </Button>
    </StyledProfilePicture>
  )
}

export default withTheme(ProfilePicture)
