import React from 'react'

import { StyledChannelsContainer } from '../styled'
import { Button } from '../../../../../atomic-design-components'

const CHANNEL_NAMES = {
  viber: 'Viber',
  telegram: 'Telegram',
  facebook: 'Facebook Messenger',
  instagram: 'Instagram',
}

const Channels = ({ channels, color, getT, goOneScreenForward }) => {
  if (!channels) {
    return null
  }

  return (
    <>
      <StyledChannelsContainer>
        {Object.keys(channels).map((channelKey) => {
          const channel = channels[channelKey]
          if (channel.status !== 'active' || channelKey === 'live_chat') {
            return null
          }

          return (
            <Button
              fullWidth
              key={channelKey}
              variant="general"
              className={channelKey}
              iconLeftProps={{ name: `${channelKey}Icon`, fill: '' }}
            >
              {getT('writeIn')} {CHANNEL_NAMES[channelKey]}
            </Button>
          )
        })}
      </StyledChannelsContainer>
      <Button
        fullWidth
        iconLeftProps={{ name: 'chats' }}
        className="live_chat"
        backgroundColor={color}
        borderColor={color}
      >
        {getT('sendUsMessage')}
      </Button>
    </>
  )
}

export default Channels
