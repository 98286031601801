import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledResultsBlock } from '../styled'
import { FlexRow, Link, Typography } from '../../../atomic-design-components'

const ResultsBlock = ({ value }) => {
  const { t } = useTranslation('all')
  const errors = value?.failure || []
  return (
    <StyledResultsBlock>
      <Typography text={t('bulkSuccess', { messagesCount: value?.success?.length || 0 })} variant="button1" />
      {!!errors.length && (
        <>
          <Typography text={t('bulkErrors', { messagesCount: errors.length })} variant="button1" />
          <FlexRow alignItems="start" flexWrap="wrap" gap="7px" flexDirection="column">
            {errors.map((errorInfo, i) => (
              <Link
                key={i}
                text={errorInfo?.name || `${t('guest')} #${errorInfo?.id || 1}`}
                to={`/chats/${errorInfo?.chat_id}`}
                iconName={`${errorInfo?.channel}Icon`}
                iconPosition="left"
                iconProps={{ size: 16 }}
              />
            ))}
          </FlexRow>
        </>
      )}
    </StyledResultsBlock>
  )
}

export default ResultsBlock
