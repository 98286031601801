import { useEffect } from 'react'

const useAutosizeTextArea = (textAreaRef, value, maxHeight, initialHeight = 0) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = initialHeight + 'px'
      const scrollHeight = textAreaRef.scrollHeight

      if (scrollHeight <= maxHeight) {
        textAreaRef.style.height = scrollHeight + 'px'
      } else {
        textAreaRef.style.height = maxHeight + 'px'
      }
    }
  }, [textAreaRef, value])
}

export default useAutosizeTextArea
