import React, { useState, useEffect, useContext } from 'react'
import ReactHelmet from '../../wrappers/ReactHelmet'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'
import dayjs from 'dayjs'

import { StyledDashboard, StyledStatisticBlock } from './styled'
import GridLayout from '../Layout/GridLayout'
import TableTitleBlock from '../../components/TableBlock/components/TableTitleBlock'
import { DatePicker, FlexRow, Select } from '../../atomic-design-components'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectMainData, selectUserRole, selectUserMemberId } from '../../redux-saga/selectors'
import { getTableData } from '../../redux-saga/actions'
import AverageComponent from './components/AverageComponent'
import ChannelsAnalytic from './components/ChannelsAnalytic'
import AnalyticsCircle from './components/AnalyticsCircle'
import RatingComponent from './components/RatingComponent'
import { ScreenContext } from '../../hooks/useScreenContext'
import { getDays } from '../../utils/datePickerRangeOption'

const Dashboard = ({ theme }) => {
  const dispatch = useDispatch()

  const { width, lg } = useContext(ScreenContext)
  const isMobile = width && width < lg

  const {
    i18n: { language: currentLng },
  } = useTranslation()
  const { t } = useTranslation('all')

  const members = useMappedState(selectMainData('members')) || []
  const currMemberId = useMappedState(selectUserMemberId)
  const statistics = useMappedState(selectMainData('statistics')) || {}
  const statisticMetric = useMappedState(selectMainData('statisticMetric')) || {}

  const statisticMetricOptions = [
    'browser',
    'browser_lang',
    'channel',
    'device',
    'tags',
    'timezone',
    'rating',
    'device_os',
  ]
  const statisticOption = ['rating', 'chat_duration', 'response_time']

  useEffect(() => {
    dispatch(getTableData({ type: 'members', status: 'active', isSystemData: true }))
  }, [])

  const role = useMappedState(selectUserRole)

  const [dateRange, setDateRange] = useState(getDays(0))
  const [startDate, endDate] = dateRange

  const [selectMember, setSelectMember] = React.useState(role === 'operator' ? { id: currMemberId } : '')

  const onSelectMember = (member) => {
    setSelectMember(member)
    onGetNewStat(member)
  }

  const onSelectDate = (update) => {
    const start = update[0]
      ? dayjs(update[0]).set('hour', 0).set('minute', 0).set('second', 0).millisecond(0).$d
      : null
    const end = update[1]
      ? dayjs(update[1]).set('hour', 23).set('minute', 59).set('second', 59).millisecond(0).$d
      : null
    setDateRange([start, end])
  }

  useEffect(() => {
    if (startDate && endDate) {
      onGetNewStat(selectMember)
    }
  }, [endDate])

  const onGetNewStat = (member) => {
    statisticOption.forEach((option) =>
      dispatch(
        getTableData({
          type: 'statistics',
          apiUrlParam: option,
          id: member?.id || '',
          queryParams: [
            { key: 'date_from', value: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS') },
            { key: 'date_to', value: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS') },
          ],
        })
      )
    )
    statisticMetricOptions.forEach((option) =>
      dispatch(
        getTableData({
          type: 'statisticMetric',
          apiUrlParam: option,
          id: member?.id || '',
          queryParams: [
            { key: 'date_from', value: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS') },
            { key: 'date_to', value: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS') },
          ],
        })
      )
    )
  }

  const getDataOptionsComponents = () => {
    return (
      <>
        {role !== 'operator' && (
          <Select
            options={members}
            value={selectMember}
            onChange={(member) => onSelectMember(member)}
            isMulti={false}
            isCreatable={false}
            isClearable={true}
            valueKey="id"
            labelKey="full_name"
            variant="primary"
            withoutValidation
            width="200px"
            iconLeftProps={{ name: 'members', fill: theme.color.general.dark }}
            placeholder={t('allMembers')}
          />
        )}

        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          onChange={(update) => onSelectDate(update)}
          currLang={currentLng}
          hasSelect
          theme={theme}
          t={t}
          startPeriod="today"
          dateRange={dateRange}
        />
      </>
    )
  }

  return (
    <>
      <ReactHelmet title="dashboardTitle" />
      <GridLayout>
        <StyledDashboard>
          <TableTitleBlock type="dashboard" withBadge={false}>
            {!isMobile && getDataOptionsComponents()}
          </TableTitleBlock>

          {isMobile && (
            <FlexRow
              justifyContent={width <= '460' ? 'center' : 'space-between'}
              flexDirection={width <= '460' ? 'column' : 'row'}
              gap="10px"
              padding="10px 20px"
              className="headerBlock"
            >
              {getDataOptionsComponents()}
            </FlexRow>
          )}

          <StyledStatisticBlock color={theme.color} className="averageBlock">
            <FlexRow className="flexRow" flexWrap="wrap">
              {Object.keys(statistics)?.map((average) => (
                <AverageComponent
                  color={theme.color}
                  t={t}
                  data={statistics?.[average]}
                  key={average}
                  className={average}
                />
              ))}
            </FlexRow>

            {statisticMetric?.rating && (
              <RatingComponent
                color={theme.color}
                t={t}
                data={statisticMetric.rating}
                total={statistics?.rating?.avg || 0}
              />
            )}

            {statisticMetric?.channel && (
              <ChannelsAnalytic color={theme.color} t={t} data={statisticMetric.channel} />
            )}

            {role !== 'operator' &&
              Object.keys(statisticMetric)?.map(
                (metric) =>
                  metric !== 'channel' &&
                  metric !== 'rating' &&
                  statisticMetric[metric]?.length > 0 && (
                    <AnalyticsCircle
                      key={metric}
                      color={theme.color}
                      t={t}
                      header={t(`mostPopular${metric}`)}
                      iconName="clockLong"
                      data={statisticMetric[metric]}
                      isTagsAnalytics={metric === 'tags'}
                      className={metric}
                    />
                  )
              )}
          </StyledStatisticBlock>
        </StyledDashboard>
      </GridLayout>
    </>
  )
}

export default withTheme(Dashboard)
