import { combineReducers } from 'redux'

import common from './reducers/common'
import data from './reducers/data'
import user from './reducers/user'
import company from './reducers/company'
import sidebar from './reducers/sidebar'
import chats from './reducers/chats'
import fileUpload from './reducers/fileUpload'
import forms from './reducers/forms'

export default combineReducers({ common, data, user, company, sidebar, chats, fileUpload, forms })
