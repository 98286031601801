import styled from 'styled-components'
import { pick } from 'dot-object'

import { getCssFromTheme } from '../../../utils/css'
import { convertHexToRGBA } from '../../../utils/convertHexToRgba'

export const StyledLink = styled.span`
  display: inline-flex;

  .icon {
    margin: 0 4px 0 0;
  }

  a {
    ${({ theme }) => getCssFromTheme(theme, 'link')};
    color: ${({ theme, themeColor, inverted }) =>
      inverted ? theme.color.general.white : pick(themeColor, theme.color)};
    cursor: pointer;
    font-weight: ${({ fontWeight }) => fontWeight};
    text-transform: ${({ $textTransform }) => $textTransform};
  }

  &:hover:not(.disabled) a {
    opacity: 0.7;
  }

  &.withBorder:not(.noStyles) {
    a {
      border-bottom: ${({ theme, themeColor }) =>
        `1px solid ${convertHexToRGBA(pick(themeColor, theme.color), 0.7)}`};
    }
    &:not(.disabled) a:hover {
      border-bottom-color: ${({ theme, themeColor }) => pick(themeColor, theme.color)};
    }
  }

  &.disabled a {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.noStyles,
  a {
    text-decoration: none;
  }
`
