import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import { Button, FlexRow, Icon, Typography } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { upsertSidebarItem } from '../../../redux-saga/actions'
import { selectCurrentSubscription } from '../../../redux-saga/selectors'

const StyledChatWidgetActions = styled(FlexRow)`
  > div:not(:last-child) {
    margin-right: 10px;
  }

  .disabled.customization {
    .right.tooltip-arrow {
      right: 9px;
    }
  }
  button {
    padding: 4px;

    &.disabled {
      border: 1px solid ${({ theme }) => theme.color.general.light};
      background-color: ${({ theme }) => theme.color.general.lighter};

      .typography {
        color: ${({ theme }) => theme.color.general.light};
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .button1 {
    margin-left: 6px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
    justify-content: space-between;
  }
`

const ChatWidgetActions = ({ rowData, t, theme: { color }, container }) => {
  const BUTTON_CONFIG = {
    inactive: {
      light: `${color.status.success}40`,
      dark: color.status.success,
      text: 'activate',
    },
    active: {
      light: color.secondary.lighter,
      dark: color.secondary.darker,
      text: 'deactivate',
    },
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const planData = useMappedState(selectCurrentSubscription)
  const { limits } = planData || {}
  // console.log(planData)
  const isCustomizationAllowedByBilling = limits?.is_customization // subscription_status === 'active' &&

  const activateWidget = () => {
    const status = rowData.status === 'active' ? 'inactive' : 'active'

    dispatch(
      upsertSidebarItem({
        id: rowData?.id,
        type: 'widgets',
        requestBody: {
          ...rowData,
          status: status,
        },
        navigate,
      })
    )
  }

  const isActionButtonsExtended = container ? container?.props?.isActionButtonsExtended : true
  const currentStatus = rowData?.status === 'active' ? 'active' : 'inactive'

  const customizationTooltipText = isCustomizationAllowedByBilling
    ? t('customization')
    : isActionButtonsExtended
    ? t('upgradeYourPlanToUse')
    : t('customization') + ': ' + t('upgradeYourPlanToUse').toLowerCase()

  return (
    <StyledChatWidgetActions>
      <Button
        variant="general"
        onClick={(e) => {
          e.stopPropagation()
          if (isCustomizationAllowedByBilling) {
            navigate(`../my-company/chat-customization/${rowData.id}`)
          }
        }}
        backgroundColor={color.secondary.lighter}
        borderColor={color.secondary.darker}
        fullWidth={isActionButtonsExtended}
        tooltipProps={{
          text: customizationTooltipText,
          tipPosition: 'bottom',
          arrowPosition: 'right',
          right: '0',
          disableTooltip: isActionButtonsExtended && isCustomizationAllowedByBilling,
          className: isCustomizationAllowedByBilling ? '' : 'disabled customization',
        }}
        className={isCustomizationAllowedByBilling ? '' : 'disabled'}
      >
        <Icon size={14} name="palette" fill={color.secondary.darker} />

        {isActionButtonsExtended && (
          <Typography text={t('customization')} variant="button1" color={color.secondary.darker} />
        )}
      </Button>

      {/* <Button
        variant="general"
        onClick={(e) => {
          e.stopPropagation()
          activateWidget()
        }}
        backgroundColor={BUTTON_CONFIG[currentStatus].light}
        borderColor={BUTTON_CONFIG[currentStatus].dark}
        fullWidth={isActionButtonsExtended}
        tooltipProps={{
          text: t(BUTTON_CONFIG[currentStatus].text),
          tipPosition: 'bottom',
          arrowPosition: 'right',
          right: '0',
          disableTooltip: isActionButtonsExtended,
        }}
      >
        <Icon size={14} name="off" fill={BUTTON_CONFIG[currentStatus].dark} />
        {isActionButtonsExtended && (
          <Typography
            text={t(BUTTON_CONFIG[currentStatus].text)}
            variant="button1"
            color={BUTTON_CONFIG[currentStatus].dark}
          />
        )}
      </Button> */}
      <Button
        variant="general"
        onClick={(e) => {
          e.stopPropagation()
          navigate(`../my-company/chat-widgets/auto-messages/${rowData.id}`)
        }}
        backgroundColor={color.secondary.lighter}
        borderColor={color.secondary.darker}
        fullWidth={isActionButtonsExtended}
        tooltipProps={{
          text: t('auto-messages'),
          tipPosition: 'bottom',
          arrowPosition: 'right',
          right: '0',
          disableTooltip: isActionButtonsExtended,
        }}
      >
        <Icon size={14} name="autoMessages" fill={color.secondary.darker} />
        {isActionButtonsExtended && (
          <Typography text={t('auto-messages')} variant="button1" color={color.secondary.darker} />
        )}
      </Button>
    </StyledChatWidgetActions>
  )
}

export default withTheme(ChatWidgetActions)
