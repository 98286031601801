import styled from 'styled-components'

export const StyledDropdown = styled.div`
  position: relative;

  &.tabsDropdown .icon {
    border: ${({ theme }) => `1px solid ${theme.color.general.lighter}`};
  }
`

export const StyledMenu = styled.div`
  display: none;
  position: absolute;
  //left: 0;
  right: 0;
  top: 100%;
  background-color: ${({ theme }) => theme.color.general.white};
  filter: drop-shadow(0px 14px 15px rgba(0, 0, 0, 0.15));
  z-index: 4;
  border-radius: 3px;
  &.right {
    left: 0;
    right: auto;
  }
  &.left {
    right: 0;
    top: 100%;
  }
  > * {
    background-color: ${({ theme }) => theme.color.general.white};
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  &.opened {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
    > * {
      width: 100%;
      &:hover {
        background-color: ${({ theme }) => theme.color.general.lightest};
        border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
        border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
      }
    }
  }

  & > * {
    padding: 13px 16px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: ${({ theme }) => theme.color.general.lightest};
      .icon {
        background-color: ${({ theme }) => theme.color.general.lightest};
      }
    }

    .icon {
      margin-right: 10px;
    }
  }
`
