import {
  ALL_STATUSES_ICONS,
  BULK_MESSAGE_STATUSES_ICONS,
  CURRENCY,
  INDUSTRIES,
  MESSENGERS,
  MESSENGERS_INFO,
  ROLES,
  STATUSES_ICONS,
  WIDGET_COLORS,
} from '../constants'
import { LANGUAGES_FULL_NAME } from '../locales'
import { transformStringSelectValue } from '../utils/select'
// import PhoneInputGroupWithSelect from '../containers/Members/panels/PhoneInputGroupWithSelect'
import { FlexRow, Icon, Typography } from '../atomic-design-components'
import DateTimeInputs from '../atomic-design-components/organisms/DateTimeInputs/DateTimeInputs'
import ChooseIconType from '../containers/MyCompanyChatWidgets/components/ChooseIconType'
import FieldsCustomisation from '../containers/MyCompanyChatWidgets/components/FieldsCustomisation'
import InputWithSwitch from '../containers/MyCompanyChatWidgets/components/InputWithSwitch'
import IsHideBrandingSwitch from '../containers/MyCompanyChatWidgets/components/IsHideBrandingSwitch'
import SetupPanel from '../containers/MyCompanyChatWidgets/components/SetupPanel'
import SwitchableCards from '../containers/MyCompanyChatWidgets/components/SwitchableCards'
import theme from '../theme'
import { getAvailableTranslation } from '../utils/getAvailableTranslation'
import ResultsBlock from '../containers/BulkMessaging/components/ResultsBlock'

export const PHONE = {
  key: 'phone.phone',
  label: 'phone',
  iconLeftProps: { name: 'phone' },
}

const STATUS = {
  key: 'status',
  label: 'status',
  options: Object.keys(STATUSES_ICONS).map((status) => ({
    id: status,
    label: status,
    iconName: STATUSES_ICONS[status],
    className: 'statusIcon',
  })),
  component: 'dropdown',
  isSearchable: false,
}

export const FULL_NAME = {
  key: 'full_name',
  label: 'fullName',
  iconLeftProps: { name: 'contactCard' },
}

export const EMAIL = {
  label: 'email',
  key: 'email',
  iconLeftProps: { name: 'doggySign' },
}

const getMessengerItem = (key, value) =>
  value === MESSENGERS_INFO[key].presetPart
    ? ''
    : value.includes(MESSENGERS_INFO[key].presetPart)
    ? value
    : `${MESSENGERS_INFO[key].presetPart}${value}`

const TELEGRAM = {
  key: 'telegram',
  label: 'telegram',
  iconLeftProps: { name: 'telegramIcon', className: 'leftIcon withoutHover' },
  placeholder: '@username',
  onInputValueChange: (value, setFormValues) => {
    setFormValues((prev) => {
      return { ...prev, telegram: getMessengerItem('telegram', value) }
    })
  },
}

const VIBER = {
  key: 'viber',
  label: 'viber',
  iconLeftProps: { name: 'viberIcon', className: 'leftIcon withoutHover' },
  onInputValueChange: (value, setFormValues) => {
    setFormValues((prev) => {
      return { ...prev, viber: getMessengerItem('viber', value) }
    })
  },
}

const INSTAGRAM = {
  key: 'instagram',
  label: 'instagram',
  iconLeftProps: { name: 'instagramIcon', className: 'leftIcon withoutHover' },
  placeholder: '@username',
  onInputValueChange: (value, setFormValues) => {
    setFormValues((prev) => {
      return { ...prev, instagram: getMessengerItem('instagram', value) }
    })
  },
}

const FACEBOOK = {
  key: 'facebook',
  label: 'facebook',
  iconLeftProps: { name: 'facebookIcon', className: 'leftIcon withoutHover' },
  placeholder: '@username',
  onInputValueChange: (value, setFormValues) => {
    setFormValues((prev) => {
      return { ...prev, facebook: getMessengerItem('facebook', value) }
    })
  },
}

export const LANGUAGE = {
  key: 'lang',
  label: 'language',
  component: 'dropdown',
  isMulti: false,
  iconLeftProps: { name: 'dashboard', fill: theme.color.general.light },
  options: Object.keys(LANGUAGES_FULL_NAME).map((lng) => ({
    id: lng,
    label: LANGUAGES_FULL_NAME[lng],
  })),
}

export const LANGUAGES = {
  key: 'languages',
  label: 'languages',
  component: 'dropdown',
  isMulti: true,
  iconLeftProps: { name: 'planet', fill: theme.color.general.light },
  options: Object.keys(LANGUAGES_FULL_NAME).map((lng) => ({
    id: lng,
    label: LANGUAGES_FULL_NAME[lng],
  })),
}

export const FORM_CONFIGS = {
  signUp: {
    fields: [
      {
        label: 'fullName',
        key: 'full_name',
        placeholder: 'firstLastName',
        variant: 'secondary',
        labelType: 'border',
        iconLeftProps: { name: 'contactCard' },
        required: true,
      },
      {
        label: 'companyName',
        key: 'company_name',
        placeholder: 'yourCompanyName',
        variant: 'secondary',
        labelType: 'border',
        iconLeftProps: { name: 'briefcase' },
        required: true,
      },
      {
        key: 'company_phone',
        label: 'companyPhone',
        iconLeftProps: { name: 'phone' },
        required: true,
        placeholder: '+380',
      },
      {
        ...EMAIL,
        placeholder: 'you@yourcompany.com',
        required: true,
      },
      {
        label: 'password',
        key: 'password',
        placeholder: 'enterYourPassword',
        variant: 'secondary',
        type: 'password',
        labelType: 'border',
        iconLeftProps: { name: 'passwordLocked' },
        className: 'password',
        required: true,
      },
    ],
  },
  signIn: {
    fields: [
      {
        ...EMAIL,
        placeholder: 'you@yourcompany.com',
      },
      {
        key: 'password',
        label: 'password',
        variant: 'secondary',
        type: 'password',
        placeholder: 'enterYourPassword',
        labelType: 'border',
        iconLeftProps: { name: 'passwordLocked' },
        className: 'password',
      },
    ],
  },
  passwordRecovery: {
    fields: [
      {
        ...EMAIL,
        placeholder: 'you@yourcompany.com',
      },
    ],
  },
  newPassword: {
    fields: [
      {
        key: 'password',
        label: 'newPassword',
        variant: 'secondary',
        type: 'password',
        placeholder: 'enterYourPassword',
        labelType: 'border',
        iconLeftProps: { name: 'passwordLocked' },
        className: 'password',
      },
    ],
  },
  members: {
    validationRules: {
      'phone.phone': ['phone'],
    },
    fields: {
      noTitle: [
        {
          ...STATUS,
          getDisabled: (formValues, initialValues) => initialValues.status === 'pending',
          isOptionDisabled: () => (option) => option.id === 'pending',
        },
        {
          key: 'role',
          label: 'role',
          component: 'dropdown',
          getDisabled: (formValues, initialValues, optionsData) =>
            initialValues.role === 'system_administrator' ||
            optionsData.role === 'customer_administrator' ||
            optionsData.currentUserMemberId === initialValues.id,
          isOptionDisabled: (optionsData) => (option) => {
            return (
              option.id === 'system_administrator' ||
              (optionsData.role !== 'system_administrator' && option.id === 'customer_owner')
            )
          },
          options: Object.keys(ROLES).map((role) => transformStringSelectValue(role)),
          iconLeftProps: { name: 'user', fill: theme.color.general.light },
          isSearchable: false,
        },
        {
          key: 'departments',
          label: 'departments',
          component: 'dropdown',
          isMulti: true,
          labelKey: 'name',
          optionsKeys: ['departments'],
          iconLeftProps: { name: 'multiUsers', fill: theme.color.general.light },
        },
      ],
      memberInfo: [
        // { key: 'username', label: 'username', iconLeftProps: { name: 'contactCard' } },
        FULL_NAME,
        { ...EMAIL, disabled: true },
        { ...PHONE, label: 'phone' },
        LANGUAGES,
      ],
      // memberMessengers: [TELEGRAM, VIBER, INSTAGRAM, FACEBOOK],
    },
  },
  contacts: {
    validationRules: {
      'phone.phone': ['phone'],
      email: ['email'],
    },
    fields: {
      guestInfo: [
        {
          key: 'tags',
          label: 'tags',
          iconLeftProps: { name: 'tag', fill: theme.color.general.light },
          isMulti: true,
          component: 'dropdown',
          optionsKeys: ['tags'],
          getOptions: (optionsData) => optionsData.tags?.map((tag) => ({ id: tag?.name, name: tag?.name })),
          // getOptionsFromFormValues: (formValues) => {
          //   return (
          //     (Array.isArray(formValues?.tags) &&
          //       formValues.tags.map((tag) => transformStringSelectValue(tag))) ||
          //     []
          //   )
          // },
          isCreatable: true,
          createLabelText: 'addTag',
          isClearable: true,
          labelKey: 'name',
          isTranslatedLabel: false,
        },
        {
          ...FULL_NAME,
          label: 'name',
          iconLeftProps: { name: 'contactCard' },
        },
        LANGUAGES,
        { key: 'comment', label: 'comment', multiline: true },
      ],
      contactInfo: [EMAIL, PHONE, TELEGRAM, VIBER, INSTAGRAM, FACEBOOK],

      cameFrom: [],
    },
  },
  bulkMessaging: {
    validationRules: {
      name: ['required'],
      message: ['required'],
      tags: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['tags', 'channels'],
        },
      ],
      channels: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['tags', 'channels'],
        },
      ],
    },
    fields: {
      general: [
        {
          ...STATUS,
          options: Object.keys(BULK_MESSAGE_STATUSES_ICONS).map((status) => ({
            id: status,
            label: status,
            iconName: BULK_MESSAGE_STATUSES_ICONS[status],
            className: 'statusIcon',
          })),
          getDisabled: () => true,
        },
        {
          label: 'name',
          key: 'name',
          variant: 'secondary',
          labelType: 'border',
          required: true,
        },
      ],
      clientFilters: [
        {
          key: 'tags',
          label: 'tags',
          iconLeftProps: { name: 'tag', fill: theme.color.general.light },
          isMulti: true,
          component: 'dropdown',
          optionsKeys: ['tags'],
          getOptions: (optionsData) => optionsData.tags?.map((tag) => ({ id: tag?.name, name: tag?.name })),
          isClearable: true,
          labelKey: 'name',
          isTranslatedLabel: false,
          validationTranslationKeys: ['tags', 'messengers'],
        },
        {
          key: 'channels',
          label: 'messengers',
          iconLeftProps: { name: 'chats', fill: theme.color.general.light },
          isMulti: true,
          component: 'dropdown',
          options: MESSENGERS,
          customGetOptionLabel: (option, t, lng) => {
            return (
              <FlexRow gap="10px">
                <Icon name={`${option.label}Icon`} size={18} />
                <Typography variant="input" text={option.label} />
              </FlexRow>
            )
          },
          isClearable: true,
          validationTranslationKeys: ['tags', 'messengers'],
        },
      ],
      message: [
        {
          key: 'message',
          label: 'message',
          component: 'messageTextarea',
          placeholder: 'bulkMessagePlaceholder',
          required: true,
          initialHeight: 100,
        },
        {
          label: 'withSchedule',
          key: 'is_scheduled',
          component: 'checkbox',
        },
        {
          key: 'scheduled_at',
          component: (props) => <DateTimeInputs {...props} onChange={props.updateSelect} />,
          getDisabled: (props) => !props.is_scheduled,
        },
      ],
      results: [
        {
          key: 'result',
          component: (props) => <ResultsBlock {...props} />,
          getIsHidden: (formValues) => !['complete', 'complete_with_error'].includes(formValues.status),
        },
      ],
    },
  },
  customers: {
    accountStatus: {
      validationRules: {},
      fields: [
        {
          ...STATUS,
          isDisabled: true,
          options: Object.keys(ALL_STATUSES_ICONS).map((status) => ({
            id: status,
            label: status,
            iconName: ALL_STATUSES_ICONS[status],
            className: 'statusIcon',
          })),
        },
      ],
    },
    currentPlan: {
      validationRules: {},
      fields: {
        currentPlan: [
          {
            key: 'plan',
            label: 'plan',
            component: 'dropdown',
            iconLeftProps: { name: 'planet', fill: theme.color.general.light },
            optionsKeys: ['plans'],
            customGetOptionLabel: (option, t, lng) => {
              return getAvailableTranslation(option?.titles, lng)
            },
            onSelectValueChange: (value, setFormValues) => {
              // Free plan
              if (value?.id === 1) {
                setFormValues({ subscription_expire_at: null })
              }
            },
            isSearchable: false,
          },
          {
            key: 'subscription_expire_at',
            component: 'date',
            label: 'planEnds',
            minDate: new Date(),
            getDisabled: (formValues) => {
              return formValues.plan?.id === 1 // Free plan
            },
          },
        ],
      },
    },
    information: {
      validationRules: {
        email: ['email'],
        'phone.phone': ['phone'],
      },
      fields: {
        information: [
          {
            key: 'website_url',
            label: 'website',
            iconLeftProps: { name: 'linkSign' },
          },
          { ...PHONE, label: 'companyPhone' },
          EMAIL,
          { key: 'comment', label: 'comment', multiline: true },
        ],
      },
    },
  },
  customersInvoice: {
    validationRules: {
      email: ['required', 'email'],
      full_name: ['required'],
      EDRPOU: ['required', 'onlyDigits'],
      TIN: ['required', 'onlyDigits'],
      IBAN: ['required'],
      amount: ['required', 'price'],
    },
    fields: {
      customerInfo: [
        {
          key: 'company_name',
          label: 'customerCompany',
          iconLeftProps: { name: 'contactCard' },
          isDisabled: true,
        },
        { ...EMAIL, label: 'companyEmail' },
        { ...FULL_NAME, iconLeftProps: { name: 'briefcase' } },
        {
          key: 'EDRPOU',
          label: 'EDRPOU',
          isUppercase: true,
          iconLeftProps: { name: 'briefcase' },
        },
        {
          key: 'TIN',
          label: 'TIN',
          isUppercase: true,
          iconLeftProps: { name: 'briefcase' },
        },
        {
          key: 'IBAN',
          label: 'IBAN',
          isUppercase: true,
          iconLeftProps: { name: 'briefcase' },
        },
        {
          key: 'purposeOfPayment',
          label: 'purposeOfPayment',
          multiline: true,
        },
        {
          key: 'amount',
          label: 'amount',
          iconLeftProps: { name: 'dollarSign' },
          onInputValueChange: (value, setFormValues) => {
            const presetPart = CURRENCY.UAH
            const formattedValue =
              value === presetPart
                ? `${value} `
                : value.includes(presetPart)
                ? value
                : `${presetPart} ${value}`
            setFormValues((prev) => {
              return { ...prev, amount: formattedValue }
            })
          },
        },
      ],
    },
  },
  departments: {
    validationRules: {
      name: ['required'],
    },
    fields: [
      {
        ...STATUS,
        options: Object.keys(STATUSES_ICONS)
          .filter((option) => option !== 'pending')
          .map((status) => ({
            id: status,
            label: status,
            iconName: ALL_STATUSES_ICONS[status],
            className: 'statusIcon',
          })),
        getIsHidden: (formValues) => {
          return !formValues?.id
        },
      },
      {
        key: 'name',
        label: 'departmentName',
        iconLeftProps: { name: 'tag' },
      },
      {
        key: 'members',
        label: 'addMembers',
        component: 'dropdown',
        isMulti: true,
        labelKey: 'full_name',
        optionsKeys: ['members'],
        iconLeftProps: { name: 'invitePeople', fill: theme.color.general.light },
      },
    ],
  },
  widgets: {
    validationRules: {
      name: ['required'],
    },
    fields: {
      general: [
        {
          ...STATUS,
          options: Object.keys(STATUSES_ICONS)
            .filter((option) => option !== 'pending')
            .map((status) => ({
              id: status,
              label: status,
              iconName: ALL_STATUSES_ICONS[status],
              className: 'statusIcon',
            })),
          getIsHidden: (formValues) => !formValues?.id,
        },
        {
          key: 'name',
          label: 'name',
          iconLeftProps: { name: 'widgets' },
        },
      ],
      channelConfig: [
        {
          key: 'channels',
          component: (props) => <SwitchableCards formProps={props} theme={theme} />,
        },
      ],
      setup: [
        {
          key: 'license_id',
          component: ({ value }) => <SetupPanel data={value} theme={theme} />,
        },
      ],
    },
  },
  widgetsNew: {
    validationRules: {
      name: ['required'],
    },
    fields: [
      {
        key: 'name',
        label: 'name',
        placeholder: 'enterChatWidgetName',
        iconLeftProps: { name: 'widgets' },
      },
    ],
  },
  companyGeneral: {
    validationRules: {
      alias: ['required'],
      email: ['email'],
      'phone.phone': ['phone'],
    },
    fields: {
      companyInformation: [
        {
          label: 'companyName',
          key: 'alias',
          placeholder: 'yourCompanyName',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'briefcase' },
          required: true,
        },
        {
          label: 'companyWebsite',
          key: 'website_url',
          placeholder: 'www.',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'monitor' },
        },
        { ...EMAIL, label: 'companyEmail', placeholder: 'email@example.com' },
        { ...PHONE, label: 'companyPhone', placeholder: '+380' },
        {
          key: 'industry',
          label: 'industry',
          component: 'dropdown',
          iconLeftProps: { name: 'settings', fill: theme.color.general.dark },
          options: INDUSTRIES.map((industry) => transformStringSelectValue(industry)),
        },
        {
          key: 'description',
          label: 'description',
          placeholder: 'companyInfoDescription',
          variant: 'secondary',
          labelType: 'border',
          multiline: true,
          rows: 4,
        },
      ],
    },
  },
  customization: {
    validationRules: {
      privacy_policy: ['privacy_policy_required_custom', 'privacy_policy_website_custom'],
    },
    fields: {
      widgetAppearance: {
        widgetColor: [
          {
            label: 'color',
            key: 'color',
            labelType: 'border',
            component: 'dropdown',
            options: Object.keys(WIDGET_COLORS).map((color) => {
              return {
                id: WIDGET_COLORS[color],
                label: color,
                color: WIDGET_COLORS[color],
                className: 'widgetColorIcon',
              }
            }),
          },
        ],
        options: [
          {
            key: 'is_hide_branding',
            component: (props) => <IsHideBrandingSwitch {...props} />,
            label: 'hideBranding',
            textVariant: 'button1',
            id: 'is_hide_branding',
            revert: true,
            justifyContent: 'space-between',
            labelTopHidden: true,
          },
        ],
      },
      widgetButton: {
        iconType: [
          {
            key: 'button_style',
            component: (props) => <ChooseIconType {...props} />,
          },
        ],
      },
      forms: [
        {
          key: 'is_form_displayed',
          component: 'switch',
          label: 'preChat',
          textVariant: 'button1',
          revert: true,
          justifyContent: 'space-between',
        },
        {
          key: 'form_fields',
          component: (props) => <FieldsCustomisation {...props} />,
          getOptionsFromFormValues: (formValues) => {
            return { is_form_displayed: formValues.is_form_displayed }
          },
        },
        // { key: 'departments', component: (props) => <InputWithSwitch {...props} /> },
        {
          key: 'privacy_policy',
          component: (props) => <InputWithSwitch {...props} />,
          getOptionsFromFormValues: (formValues) => {
            return { is_form_displayed: formValues.is_form_displayed }
          },
        },
      ],
    },
  },
  subscriptionPlans: {
    withTabs: ['titles', 'prices.price_monthly', 'prices.price_annually'],
    // withTabs: {
    //   titles:'titles.ti'
    // },
    validationRules: {
      status: ['required'],
      customers: ['required'],
      'titles.uk': ['required'],
      'prices.price_monthly.UAH': ['required', 'onlyDigits'],
      'prices.price_annually.UAH': ['required', 'onlyDigits'],
      'features.widgets': ['onlyDigits', 'required'],
      'features.users': ['onlyDigits', 'required'],
      'features.templates': ['onlyDigits', 'required'],
    },
    fields: {
      general: [
        {
          ...STATUS,
          options: Object.keys(STATUSES_ICONS)
            .filter((option) => option !== 'pending')
            .map((status) => ({
              id: status,
              label: status,
              iconName: ALL_STATUSES_ICONS[status],
              className: 'statusIcon',
            })),
          // getIsHidden: (formValues) => {
          //   return !formValues?.id
          // },
          required: true,
        },
        {
          key: 'customers',
          label: 'addCustomer',
          component: 'dropdown',
          isMulti: true,
          labelKey: 'alias',
          optionsKeys: ['customers'],
          iconLeftProps: { name: 'invitePeople', fill: theme.color.general.light },
        },
      ],
      translations: [
        {
          label: 'title',
          key: 'titles',
          variant: 'secondary',
          labelType: 'border',
          required: true,
        },
      ],
      planCharacteristics: [
        {
          label: 'widgets',
          key: 'features.widgets',
          placeholder: '0',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'chats' },
          required: true,
        },
        {
          label: 'users',
          key: 'features.users',
          placeholder: '0',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'users' },
          required: true,
        },
        {
          label: 'templates',
          key: 'features.templates',
          placeholder: '0',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'templates' },
          required: true,
        },
        {
          label: 'is_branding',
          key: 'features.is_branding',
          component: 'checkbox',
        },
        {
          label: 'is_customization',
          key: 'features.is_customization',
          component: 'checkbox',
        },
        // {
        //   label: 'is_support',
        //   key: 'features.is_support',
        //   component: 'checkbox',
        // },
      ],
      prices: [
        {
          label: 'priceMonthly',
          key: 'prices.price_monthly',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'dollarSign' },
          required: true,
          placeholder: '0',
        },
        {
          label: 'priceAnnually',
          key: 'prices.price_annually',
          variant: 'secondary',
          labelType: 'border',
          iconLeftProps: { name: 'dollarSign' },
          required: true,
          placeholder: '0',
        },
      ],
    },
  },
}
