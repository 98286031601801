import clsx from 'clsx'
import React from 'react'
import { withTheme } from 'styled-components'

import { Checkbox, FlexRow, Icon, Select, Switch, Typography } from '../../../atomic-design-components'
import { StyledErrorLabel } from '../../../atomic-design-components/molecules/Input/styled'
import TimeInputWithMask from '../../../atomic-design-components/molecules/TimeInputWithMask/TimeInputWithMask'
import { StyledSwitchableCardWithFields } from '../styled'

const SwitchableCardWithFields = ({
  blockKey,
  cardKey,
  fields,
  updatedValues,
  setUpdatedValues,
  isCardDisabled,
  theme,
  t,
  optionsData,
  errors,
  setErrors,
}) => {
  const isSwitchActive = updatedValues[blockKey]?.status === 'active'

  const onSwitchClick = (isSwitchedOn) => {
    setUpdatedValues({
      ...updatedValues,
      [blockKey]: { ...updatedValues[blockKey], status: isSwitchedOn ? 'active' : 'inactive' },
    })
  }

  const onBlur = (e) => {
    const { value } = e.target
    const isValid = new RegExp('^([0-9][0-9]):[0-5][0-9]$').test(value)
    if (isValid) {
      delete errors[blockKey]?.delay
      setErrors(errors)
    } else {
      setErrors((prev) => ({ ...prev, [blockKey]: { delay: 'enterAllChars' } }))
    }
  }
  const onInputChange = (e) => {
    const { name, value } = e.target
    setUpdatedValues({ ...updatedValues, [blockKey]: { ...updatedValues[blockKey], [name]: value } })
    delete errors[blockKey]?.delay
    setErrors(errors)
  }

  const onCheckboxClick = (isCheckboxOn, name) => {
    setUpdatedValues({ ...updatedValues, [blockKey]: { ...updatedValues[blockKey], [name]: isCheckboxOn } })
    delete errors.checkbox?.[blockKey]
    setErrors(errors)
  }

  const onCreateOption = (newValue) => {
    setUpdatedValues({
      ...updatedValues,
      [blockKey]: { ...updatedValues[blockKey], emails: [...updatedValues[blockKey].emails, newValue] },
    })
  }
  const onSelectUpdate = (options) => {
    setUpdatedValues({
      ...updatedValues,
      [blockKey]: { ...updatedValues[blockKey], emails: [...options] },
    })
    delete errors.checkbox?.[blockKey]
    setErrors(errors)
  }

  return (
    <StyledSwitchableCardWithFields>
      <div className="notificationItem">
        <FlexRow justifyContent="space-between" className="notificationSwitch">
          <Typography variant="h4" text={t(blockKey)} color={theme.color.general.darker} />
          <Switch id={cardKey} checked={isSwitchActive} onChange={onSwitchClick} />
        </FlexRow>
        <Typography variant="body2" text={t(`${cardKey}_tip`)} color={theme.color.general.dark} />
      </div>
      <FlexRow
        flexDirection="column"
        alignItems="start"
        className={clsx('fieldsContainer', isCardDisabled && 'isCardDisabled')}
      >
        <FlexRow flexWrap="wrap" gap="20px" className={clsx(cardKey, 'fields')}>
          {fields.map((field, i) => {
            if (field.component === 'checkbox') {
              return (
                <Checkbox
                  key={i}
                  {...field}
                  name={field.key}
                  value={field.value}
                  checked={updatedValues[blockKey][field.key]}
                  variant={field.variant || 'primary'}
                  label={t(field.label)}
                  handleChange={(isCheckboxOn) => onCheckboxClick(isCheckboxOn, field.key)}
                  labelType={field.labelType || 'body2'}
                  className="formCheckbox"
                />
              )
            }
            if (field.component === 'dropdown') {
              return (
                <Select
                  key={i}
                  {...field}
                  options={optionsData}
                  onChange={onSelectUpdate}
                  onCreateOption={onCreateOption}
                  t={t}
                  isHidden={field.getIsHidden(updatedValues[blockKey])}
                  value={updatedValues[blockKey][field.key]}
                  fullWidth
                />
              )
            }
            return (
              <TimeInputWithMask
                key={i}
                {...field}
                value={updatedValues[blockKey][field.key]}
                name={field.key}
                label={t(field.label)}
                placeholder={field.placeholder}
                error={errors[blockKey]?.[field.key] && t(`validation:${errors[blockKey][field.key]}`)}
                mask="MM:Ss"
                formatChars={{
                  M: '[0-9]',
                  S: '[0-5]',
                  s: '[0-9]',
                }}
                onChange={onInputChange}
                onBlur={onBlur}
              />
            )
          })}
          {errors.checkbox?.[blockKey] && (
            <StyledErrorLabel icon={<Icon name="inputError" size={13} />} className="error">
              {t(`validation:${errors.checkbox[blockKey]}`)}
            </StyledErrorLabel>
          )}
        </FlexRow>
      </FlexRow>
    </StyledSwitchableCardWithFields>
  )
}

export default withTheme(SwitchableCardWithFields)
