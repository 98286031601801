import { pick } from 'dot-object'
import React, { useEffect, useState } from 'react'

import { FlexRow, Icon, Tabs, Typography } from '../../atomic-design-components'
import { FORM_CONFIGS } from '../../config/forms'
import { SUPPORTED_CURRENCIES } from '../../constants'
import { SUPPORTED_LNGS } from '../../locales'
import FormFields from './FormFields'

const defaultTabs = {
  translations: 'uk',
  prices: 'UAH',
}

const FormBlockTabs = ({ blockKey, formFieldsProps = {}, fields, type }) => {
  const { isReadOnly } = formFieldsProps
  const [tabs, setTabs] = useState([])

  const getTabOptions = () => {
    const tabsKeys = FORM_CONFIGS[type]?.withTabs.filter((key) => fields.some((field) => field.key === key))

    if (!tabsKeys) return

    let tabs = [defaultTabs[blockKey]]
    tabsKeys.forEach((key) => {
      const tabValueObj = pick(key, formFieldsProps.formValues) || {}

      Object.keys(tabValueObj).forEach((lng) => {
        if (!tabs.includes(lng) && tabValueObj[lng]) {
          tabs = [...tabs, lng]
        }
      })
    })

    return tabs
  }

  useEffect(() => {
    const tabs = getTabOptions()

    if (tabs) {
      setTabs(tabs)
    }
  }, [formFieldsProps.initialValues?.id])

  const getTabScreen = (tabKey) => {
    return <FormFields fields={fields} tabKey={tabKey} {...formFieldsProps} />
  }

  const onDropItemClick = (id) => {
    setTabs([...tabs, id])
  }

  const getTabTitle = (title) => (
    <FlexRow id={title} gap="6px">
      {blockKey === 'translations' && <Icon width={16} height={12} name={`flag${title.toUpperCase()}`} />}
      <Typography text={title} textTransform="uppercase" />
    </FlexRow>
  )

  const getDropdownItems = () => {
    if (blockKey === 'prices') {
      return SUPPORTED_CURRENCIES.filter((currency) => !tabs.includes(currency)).map((currency) => ({
        id: currency,
        label: currency.toUpperCase(),
      }))
    } else {
      return SUPPORTED_LNGS.filter((lng) => !tabs.includes(lng)).map((lng) => ({
        id: lng,
        label: lng.toUpperCase(),
      }))
    }
  }

  return (
    <Tabs
      isDisabled={isReadOnly}
      withAddAction
      tabsTitles={tabs.map((tab) => getTabTitle(tab))}
      tabsContents={tabs.map((tab) => getTabScreen(tab))}
      dropdownItems={getDropdownItems()}
      onDropItemClick={onDropItemClick}
      typographyType="link"
    />
  )
}

export default FormBlockTabs
