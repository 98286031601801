import dot from 'dot-object'
import React from 'react'

import { FORM_CONFIGS } from '../../config/forms'
import { isObjectEmpty } from '../../utils/isObjectEmpty'
import FormField from './FormField'

const FormFields = ({
  additionalFormValues,
  fields,
  flexDirection = 'column',
  formErrors,
  formSubmit,
  formValues,
  initialValues,
  isReadOnly,
  isSubmitOnBlur,
  labelKey,
  labelType,
  optionsData,
  sidebarFormId,
  submitByEnterPressed,
  tabKey,
  type,
  updateCheckbox,
  updateInput,
  updateSelect,
  validationRules,
}) => {
  if (!Array.isArray(fields)) {
    return null
  }

  return (
    fields &&
    fields.map((field) => {
      const withTabs = FORM_CONFIGS[type]?.withTabs || []
      const isTabs = withTabs.includes(field.key)
      const name = isTabs ? `${field.key}.${tabKey}` : field.key

      return (
        <FormField
          formFlexDirection={flexDirection}
          field={field}
          formError={!isObjectEmpty(formErrors) && (formErrors[name] || dot.pick(name, formErrors))}
          formErrors={field.validationRules && !isObjectEmpty(formErrors) && formErrors}
          formSubmit={formSubmit}
          formValue={formValues && (formValues[name] || dot.pick(name, formValues))}
          initialValue={initialValues && dot.pick(name, initialValues)}
          isDisabled={
            (field.isReadOnly !== false && isReadOnly) ||
            (field.getDisabled && field.getDisabled(formValues, initialValues, optionsData))
          }
          isHidden={field.getIsHidden && field.getIsHidden(formValues, initialValues)}
          isSubmitOnBlur={isSubmitOnBlur}
          key={field.label || field.key}
          labelKey={labelKey}
          labelType={labelType}
          name={name}
          optionsData={optionsData}
          optionsFromFormValues={
            field.getOptionsFromFormValues &&
            // optionsData &&
            field.getOptionsFromFormValues(formValues, optionsData, initialValues, additionalFormValues)
          }
          sidebarFormId={sidebarFormId}
          submitByEnterPressed={submitByEnterPressed}
          updateCheckbox={updateCheckbox}
          updateInput={updateInput}
          updateSelect={updateSelect}
          validationRules={validationRules}
          isTabs={isTabs}
        />
      )
    })
  )
}

export default FormFields
