import { useRef } from 'react'
import md5 from 'blueimp-md5'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'

import { API_URL_START, GET_API_URLS } from '../api'
import { transformDateToUtcDayWithZeroedTime } from '../utils/dates'

const STATUS = {
  Approved: 'approved',
  Declined: 'declined',
  Expired: 'expired',
  InProcessing: 'pending',
  Pending: 'pending',
  RefundInProcessing: 'pending',
}

export const useWayforpay = ({
  billedAnnually,
  clientFirstName,
  clientLastName,
  clientEmail,
  clientPhone,
  companyId,
  language = 'ua',
  planId,
  plan_name = 'plan_business',
  regularAmount = '10',
  trial_available,
  isDevEnv,
}) => {
  if (typeof window === 'undefined' || !window.Wayforpay) {
    return
  }
  // const isDevEnv = false
  // const trial_available = false
  const wayforpayRef = useRef(new window.Wayforpay())

  const dateNextAfterFreeTrial = dayjs().add(
    trial_available && !isDevEnv ? 14 : 1,
    trial_available || isDevEnv ? 'day' : billedAnnually ? 'year' : 'month'
  )
  const pay = () => {
    const regularMode = isDevEnv ? 'daily' : billedAnnually ? 'yearly' : 'monthly'
    const orderReference = `${companyId}:${planId}:${regularMode}:${nanoid()}`
    const dateNow = dayjs().unix()

    const firstPaymentPrice = trial_available ? 1 : regularAmount
    const domain = window.location.origin
    const hash = md5(
      `${
        process.env.REACT_APP_WFP_MERCHANT_ACCOUNT || 'freelance_user_624b4177f0fd4'
      };${domain};${orderReference};${dateNow};${firstPaymentPrice};UAH;${plan_name};1;${firstPaymentPrice}`,
      process.env.REACT_APP_WFP_SECRET_KEY || 'c709e79f425bbf3045747c2a63e4c759b2fbd356'
    )
    // console.log('pay func', hash, orderReference)
    wayforpayRef.current.run(
      {
        apiVersion: 2,
        merchantAccount: process.env.REACT_APP_WFP_MERCHANT_ACCOUNT || 'freelance_user_624b4177f0fd4',
        merchantDomainName: domain,
        authorizationType: 'SimpleSignature',
        merchantSignature: hash,
        orderReference,
        orderDate: dateNow,
        productCount: 1,
        productPrice: firstPaymentPrice,
        amount: firstPaymentPrice,
        currency: 'UAH',
        productName: plan_name,
        // clientFirstName,
        clientLastName,
        clientEmail,
        clientPhone,
        language: language.toUpperCase() || 'AUTO', // AUTO(browser lng)|UA|RU|EN|DE|IT|RO|ES\PL\SK|FR|LV
        straightWidget: true,
        paymentSystems: 'card;applePay;privat24;masterPass;visaCheckout',
        regularOn: '1',
        regularAmount,
        regularMode,
        dateNext: dateNextAfterFreeTrial.format('DD.MM.YYYY'),
        // dateEnd,
        regularCount: isDevEnv ? 3 : billedAnnually ? 2 : 24,
        serviceUrl:
          process.env.REACT_APP_WAYFORPAY_SERVICE_URL ||
          API_URL_START + GET_API_URLS('manager').wayforpayServiceUrl,
      },
      function (response) {
        console.log(response, 'approved sent')
        // on approved
      },
      function (response) {
        console.log('declined sent')
        // on declined
      },
      function (response) {
        console.log('pending or in processing')
        // on pending or in processing
      }
    )
  }

  return { pay }
}

// {
//     "merchantAccount": "freelance_user_624b4177f0fd4",
//     "merchantSignature": "4dce8d03f627882f578d7dec286c417b",
//     "orderReference": "DH1650467091",
//     "amount": 10,
//     "currency": "UAH",
//     "authCode": "431361",
//     "email": "some@mail.com",
//     "phone": "380631234567",
//     "createdDate": 1676478057,
//     "processingDate": 1676478161,
//     "cardPan": "53****1719",
//     "cardType": "MasterCard",
//     "issuerBankCountry": "Ukraine",
//     "issuerBankName": "MONObank",
//     "transactionStatus": "Pending",
//     "reason": "Transaction is pending",
//     "reasonCode": 1134,
//     "fee": 0,
//     "paymentSystem": "card",
//     "clientStartTime": "1676478056665"
// }

// {
//     "merchantAccount": "freelance_user_624b4177f0fd4",
//     "merchantSignature": "4384807e83469115cb452d1498ed0a8b",
//     "orderReference": "B6cY9kqrKtl-QAgWUTFNI",
//     "amount": 10,
//     "currency": "UAH",
//     "authCode": "341367",
//     "email": "some@mail.com",
//     "phone": "380631234567",
//     "createdDate": 1676485748,
//     "processingDate": 1676485793,
//     "cardPan": "53****1719",
//     "cardType": "MasterCard",
//     "issuerBankCountry": "Ukraine",
//     "issuerBankName": "MONObank",
//     "transactionStatus": "Approved",
//     "reason": "Ok",
//     "reasonCode": 1100,
//     "fee": 0,
//     "paymentSystem": "card",
//     "clientStartTime": "1676485747958"
// }
