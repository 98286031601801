import React from 'react'

import { StyledLoaderCircular } from './styled'

const LoaderCircular = ({ color, left, size, top }) => {
  return (
    <StyledLoaderCircular color={color} top={top} left={left} size={size} className="loader">
      <div className="loaderBg" />
      <div className="spinnerHolder animate1">
        <div className="loaderSpinner1" />
      </div>
      <div className="spinnerHolder animate2">
        <div className="loaderSpinner2" />
      </div>
    </StyledLoaderCircular>
  )
}

export default LoaderCircular
