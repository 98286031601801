import React from 'react'

export const typeQuestionWidget = ({ width, height, fill, theme: { color } }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    width={width || 56}
    height={height || 56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <defs>
      <style>
        {`.cls-1 {
            fill: ${color.general.white};
          }
    
          .cls-2 {
            fill: ${fill || color.primary.main};
          }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className="cls-2"
        d="m1.06,11.77C1.8,6.19,6.19,1.8,11.77,1.06,22.54-.35,33.46-.35,44.23,1.06c5.58.73,9.98,5.13,10.71,10.71,1.42,10.77,1.42,21.68,0,32.45-.73,5.58-5.13,9.98-10.71,10.71-10.77,1.42-21.68,1.42-32.45,0-5.58-.73-9.98-5.13-10.71-10.71C-.35,33.46-.35,22.54,1.06,11.77Z"
      />
      <g>
        <path
          className="cls-1"
          d="m20,45.36c-.15,0-.29-.03-.43-.1-.35-.17-.57-.52-.57-.9v-4.71h-3.57c-.88,0-1.7-.34-2.32-.96-.62-.62-.96-1.45-.96-2.32v-19.43c0-.88.34-1.7.96-2.32s1.45-.96,2.32-.96h25.14c.88,0,1.7.34,2.32.96.62.62.96,1.45.96,2.32v19.43c0,.86-.35,1.71-.96,2.32-.61.61-1.46.96-2.32.96h-13.09l-6.86,5.49c-.18.15-.4.22-.63.22Zm-4.57-29.71c-.34,0-.67.13-.91.38s-.38.57-.38.91v19.43c0,.34.14.67.38.91.24.24.57.38.91.38h4.57c.55,0,1,.45,1,1v3.63l5.51-4.41c.18-.14.4-.22.63-.22h13.44c.34,0,.67-.14.91-.38s.38-.57.38-.91v-19.43c0-.34-.13-.67-.38-.91-.24-.24-.57-.38-.91-.38H15.43Z"
        />
        <path
          className="cls-1"
          d="m28,29.36c-.55,0-1-.45-1-1v-1.14c0-.55.45-1,1-1,.48,0,.95-.14,1.35-.41.4-.27.71-.65.89-1.09.18-.44.23-.93.14-1.4-.09-.47-.32-.9-.66-1.24-.34-.34-.77-.57-1.24-.66-.47-.09-.96-.05-1.4.14-.44.18-.82.49-1.09.9-.27.4-.41.87-.41,1.35,0,.55-.45,1-1,1s-1-.45-1-1c0-.88.26-1.73.75-2.46.49-.73,1.18-1.3,1.99-1.63.81-.34,1.69-.42,2.56-.25.86.17,1.65.59,2.27,1.21s1.04,1.41,1.21,2.27c.17.86.08,1.75-.25,2.56-.34.81-.9,1.5-1.63,1.99-.45.3-.94.51-1.46.63v.26c0,.55-.45,1-1,1Z"
        />
        <path
          className="cls-1"
          d="m28,31.79c-.34,0-.67.1-.95.29-.28.19-.5.46-.63.77-.13.31-.16.66-.1.99.07.33.23.64.47.88.24.24.55.4.88.47.33.07.68.03.99-.1.31-.13.58-.35.77-.63.19-.28.29-.61.29-.95,0-.45-.19-.89-.51-1.21-.32-.32-.75-.5-1.21-.51h0Z"
        />
      </g>
    </g>
  </svg>
)
