import { oneOf } from 'prop-types'
import React, { useState } from 'react'

import clsx from 'clsx'
import useClickOutside from '../../../hooks/useClickOutside'
import FlexRow from '../../atoms/FlexRow'
import HeaderMenuIcon from '../../molecules/HeaderMenuIcon/HeaderMenuIcon'
import { StyledDropdown, StyledMenu } from './styled'

const DropdownMenu = ({
  children,
  buttonProps,
  MenuButton: MenuButtonProp,
  openDirection,
  className,
  dropdownItems,
  onItemClick,
}) => {
  const dropdownRef = React.useRef(null)
  const [menuOpened, setMenuOpened] = useState(false)

  useClickOutside(dropdownRef, () => setMenuOpened(false))

  const MenuButton = MenuButtonProp || HeaderMenuIcon

  const onBtnClick = () => {
    setMenuOpened((prev) => !prev)
  }

  const onClick = (id) => () => {
    if (onItemClick) onItemClick(id)
  }

  return (
    <StyledDropdown className={className} ref={dropdownRef}>
      <MenuButton onClick={onBtnClick} opened={menuOpened} {...buttonProps} />
      <StyledMenu
        className={clsx(menuOpened && 'opened', openDirection)}
        onClick={() => setMenuOpened(false)}
      >
        {dropdownItems?.length &&
          dropdownItems.map((option, index) => {
            return (
              <FlexRow onClick={onClick(option.id || index)} key={index}>
                {option.label || option}
              </FlexRow>
            )
          })}
        {children}
      </StyledMenu>
    </StyledDropdown>
  )
}

export default DropdownMenu

DropdownMenu.propTypes = {
  openDirection: oneOf(['left', 'right']),
}

DropdownMenu.defaultProps = {
  openDirection: 'left',
}
