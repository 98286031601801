import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { StyledMenuItem } from './styled'
import { Icon, Link, Typography, Badge } from '../../atomic-design-components'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectNewChatNotifications } from '../../redux-saga/selectors'
import { sortObjectToUrlString } from '../TableBlock/hooks/useSorting'

const MenuItem = ({
  iconName,
  iconFill,
  text,
  route,
  isActive,
  className,
  to,
  onClick,
  isExtended,
  isMobile,
  activeBGColor,
  sortObject,
}) => {
  const { t } = useTranslation('menu')
  const newChatNotifications = useMappedState(selectNewChatNotifications)

  const newChatNotificationsCount = newChatNotifications
    ? Object.values(newChatNotifications).filter((messagesArray) => !!messagesArray?.length).length
    : 0

  const routeWithSortString = sortObject
    ? `${route || to}?sort=${sortObjectToUrlString(sortObject)}`
    : route || to

  return (
    <Link className={clsx(className, 'menuItem')} to={routeWithSortString} onClick={onClick}>
      <StyledMenuItem
        className={clsx(isExtended ? 'extendedMenu' : 'condensedMenu', isActive && 'active')}
        activeBGColor={activeBGColor}
      >
        <Icon
          name={iconName}
          width={20}
          height={20}
          className={clsx(iconName, 'menuItemIcon')}
          fill={iconFill}
        />
        <Typography
          variant={isMobile ? 'button3' : 'button1'}
          text={t(text)}
          className={clsx(isExtended ? 'extendedItem' : 'condensedItem')}
        />

        {(route || to) === '/chats' && (
          <Badge
            invisible={newChatNotificationsCount === 0}
            badgeContent={isExtended && newChatNotificationsCount}
            variant={isExtended ? 'general' : 'primary'}
            className="menuItemBadge"
            isMini={!isExtended}
          />
        )}
      </StyledMenuItem>
    </Link>
  )
}

export default MenuItem
