import styled from 'styled-components'

export const StyledCardContent = styled.div`
  .caption1 {
    margin-left: 10px;
  }
  .statusIcon {
    margin-left: 12px;
  }
`
