import React from 'react'

import { useWidth } from '../../../hooks/useWidth'
import LanguageSelect from '../../LanguageSelect/LanguageSelect'

import { StyledHeader } from './styled'
import Logo from '../../Logo'

const HeaderIntroScreen = ({ theme }) => {
  const screenWidth = useWidth()
  const isMobile = screenWidth && screenWidth < theme.breakpoints.sm
  const isNearTablet = screenWidth && screenWidth < '1140'
  const isNearMobile = screenWidth && screenWidth < '640'
  const isTablet = screenWidth && screenWidth < theme.breakpoints.lg
  const width = !isMobile ? '204px' : ''

  return (
    <StyledHeader className="introHeader">
      <Logo theme={theme} variant={isMobile ? 'medium' : 'large'} to="../sign-in" />
      {(!isNearTablet || isTablet) && (
        <LanguageSelect isIntroScreensSelect theme={theme} width={width} isFullSize={!isNearMobile} />
      )}
    </StyledHeader>
  )
}

export default HeaderIntroScreen
