import styled from 'styled-components'

export const StyledPaginationWrapper = styled.div`
  width: fit-content;
  display: flex;

  .paginationText {
    padding: 13px 10px;
    background-color: ${({ theme }) => theme.color.general.lightest};
    border-radius: 3px;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      padding: 12px 18px;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  .buttonWrapper {
    display: flex;
    margin-left: 10px;
  }

  button {
    padding: 10px;

    &.disabled {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.general.lighter};
    }
    &.previousPageButton {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &.nextPageButton {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      position: relative;
      left: -1px;

      .icon {
        transform: rotate(180deg);
      }
    }
  }
`
