import React from 'react'

export const upload = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 17} height={height || 17} viewBox="0 0 17 17" fill="none">
    <path
      d="M12.5004 6.4707H13.6433C13.7948 6.4707 13.9402 6.53091 14.0473 6.63807C14.1545 6.74523 14.2147 6.89058 14.2147 7.04213V15.6136C14.2147 15.7651 14.1545 15.9105 14.0473 16.0176C13.9402 16.1248 13.7948 16.185 13.6433 16.185H3.35756C3.20601 16.185 3.06066 16.1248 2.9535 16.0176C2.84634 15.9105 2.78613 15.7651 2.78613 15.6136V7.04213C2.78613 6.89058 2.84634 6.74523 2.9535 6.63807C3.06066 6.53091 3.20601 6.4707 3.35756 6.4707H4.50042"
      stroke={fill || color.general.dark}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 9.32813V1.32812"
      stroke={fill || color.general.dark}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.21484 3.61384L8.50056 1.32812L10.7863 3.61384"
      stroke={fill || color.general.dark}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
