import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Form from '../../../components/Form'
import { FORM_CONFIGS } from '../../../config/forms'
import { upsertSidebarItem } from '../../../redux-saga/actions'
import { DEFAULT_WIDGET_DATA } from '../../../config/defaultValues'
import { nanoid } from 'nanoid'

const ChatWidgetsNew = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onFormSubmit = (valuesChanged) => {
    const license_id = nanoid()

    const updatedData = {
      ...DEFAULT_WIDGET_DATA,
      name: valuesChanged.name,
      license_id,
      channels: {
        ...DEFAULT_WIDGET_DATA.channels,
        live_chat: { ...DEFAULT_WIDGET_DATA.channels.live_chat, license_id },
      },
    }

    dispatch(
      upsertSidebarItem({
        type: 'widgets',
        requestBody: updatedData,
        navigate,
      })
    )
  }

  const iconProps = { name: 'plusCircle', margin: '0 6px 0' }

  return (
    <Form
      formConfig={FORM_CONFIGS.widgetsNew}
      validationRules={FORM_CONFIGS.widgetsNew.validationRules}
      onSubmit={onFormSubmit}
      saveBtnIconProps={iconProps}
      saveBtnTextKey="create"
      withActions
      isSubmitButtonAlwaysShown
    />
  )
}

export default ChatWidgetsNew
