import React from 'react'

export const typeQuestion = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 36} height={height || 36} viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_13439_135088)">
      <path
        d="M31.8296 1.6582H4.17249C3.50566 1.6582 2.86614 1.9231 2.39462 2.39462C1.9231 2.86614 1.6582 3.50566 1.6582 4.17249V25.5439C1.6582 26.2107 1.9231 26.8503 2.39462 27.3218C2.86614 27.7933 3.50566 28.0582 4.17249 28.0582H9.20106V34.3439L17.0456 28.0582H31.8296C32.4965 28.0582 33.136 27.7933 33.6075 27.3218C34.079 26.8503 34.3439 26.2107 34.3439 25.5439V4.17249C34.3439 3.50566 34.079 2.86614 33.6075 2.39462C33.136 1.9231 32.4965 1.6582 31.8296 1.6582Z"
        stroke={fill || color.general.black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2305 11.7148C14.2305 10.9689 14.4517 10.2397 14.8661 9.6195C15.2805 8.99929 15.8695 8.51589 16.5586 8.23044C17.2478 7.94499 18.0061 7.87031 18.7377 8.01583C19.4693 8.16135 20.1413 8.52054 20.6687 9.04799C21.1961 9.57543 21.5553 10.2474 21.7009 10.979C21.8464 11.7106 21.7717 12.4689 21.4862 13.1581C21.2008 13.8472 20.7174 14.4362 20.0972 14.8506C19.477 15.265 18.7478 15.4862 18.0019 15.4862V16.7434"
        stroke={fill || color.general.black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5089 21.6649C17.6527 21.5688 17.8215 21.5169 17.9944 21.5156C18.2295 21.5204 18.4537 21.6159 18.62 21.7822C18.7864 21.9486 18.8819 22.1728 18.8866 22.4079C18.8854 22.5808 18.8335 22.7496 18.7374 22.8934C18.6401 23.0391 18.5017 23.1526 18.3399 23.2196C18.1781 23.2867 18 23.3042 17.8282 23.27C17.6563 23.2359 17.4985 23.1515 17.3747 23.0276C17.2508 22.9038 17.1664 22.7459 17.1323 22.5741C17.0981 22.4023 17.1156 22.2242 17.1827 22.0624C17.2497 21.9005 17.3632 21.7622 17.5089 21.6649Z"
        stroke={fill || color.general.black}
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_13439_135088">
        <rect width="35.2" height="35.2" fill="white" transform="translate(0.400391 0.400391)" />
      </clipPath>
    </defs>
  </svg>
)
