import { components } from 'react-select'
import React from 'react'

import Icon from '../../../atoms/Icon/Icon'
import Typography from '../../../atoms/Typography'

const Control = ({ children, ...props }) => {
  const { iconLeftProps, iconText, isDisabled, error, theme } = props.selectProps
  const iconName = props?.selectProps?.value?.iconName
  const color = props?.selectProps?.value?.color

  return (
    <components.Control {...props}>
      {(!!iconLeftProps || color) && (
        <>
          <Icon
            width={16}
            height={16}
            className="leftIcon"
            color={color}
            fill={
              isDisabled
                ? theme.color.general.light
                : error
                  ? theme.color.status.error
                  : theme.color.general.white
            }
            {...iconLeftProps}
          />
          {iconText && <Typography variant="input" text={iconText} className="iconText" />}
        </>
      )}
      {!!iconName && <Icon name={iconName} className="statusIcon" margin=" 0 0 0 15px" />}
      {children}
    </components.Control>
  )
}

export default Control
