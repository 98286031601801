import { isObjectEmpty } from './isObjectEmpty'
import { DEFAULT_LANGUAGE } from '../locales'

export const getAvailableTranslation = (textObject, currLng = '') => {
  if (isObjectEmpty(textObject)) {
    return ''
  }

  let value = textObject[currLng] || textObject[DEFAULT_LANGUAGE]

  if (!value) {
    value = Object.values(textObject).find((item) => item)
  }

  return value
}
