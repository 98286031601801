import clsx from 'clsx'
import { findAll } from 'highlight-words-core'
import parse from 'html-react-parser'
import React, { forwardRef } from 'react'
import ReactTexty from 'react-texty'

import { FlexRow, Icon, TextPanel, Typography, Userpic } from '../../../atomic-design-components'
import { FILE_TYPES } from '../../../constants'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectCurrentChatInfo } from '../../../redux-saga/selectors'
import { transformDateWithDay } from '../../../utils/dates'
import FilesList from './FilesList'
import { StyledChatMessage } from './styled'

const getFileTypeFromName = (name) => {
  return name?.split('.')?.pop()?.toLowerCase()
}

const getFiles = (files, types) => {
  if (!files || !Array.isArray(files)) {
    return
  }

  return files?.filter((file) => {
    return file.type
      ? Object.keys(types).includes(file.type)
      : Object.values(types)
          .flat()
          .includes(`.${getFileTypeFromName(file.file_name)}`)
  })
}

const MessageItem = forwardRef(
  (
    {
      action: {
        at,
        id,
        chat_id,
        operator_id,
        operator_name,
        operator_pic,
        data: { text, files },
        type,
      },
      searchValue,
      t,
      theme,
    },
    ref
  ) => {
    const chatInfo = useMappedState(selectCurrentChatInfo)
    const { channel, contact_name, contact_id } = chatInfo

    const isSystemMessage = type === 'system_message'
    const isBulkMessage = type === 'bulk_message'

    const isMessageFromOperator = type === 'operator_message'
    const messageHeader = isSystemMessage
      ? t('system')
      : isBulkMessage
      ? t('bulkMessage')
      : isMessageFromOperator
      ? operator_name
      : contact_name || `${t('guest')} #${contact_id}`

    const messageFiles = getFiles(files, FILE_TYPES.files)
    const messageImages = getFiles(files, FILE_TYPES.images)

    const getTitle = () => {
      return (
        <>
          <Typography text={messageHeader} as={ReactTexty} variant="caption1" className="longText" />
          <Icon
            name={isSystemMessage || isBulkMessage ? 'infoCircle' : `${channel}Icon`}
            margin="0 5px"
            width={14}
            height={14}
            fill={isSystemMessage || isBulkMessage ? theme.color.status.new : ''}
            stroke={isSystemMessage || isBulkMessage ? theme.color.general.white : ''}
          />
          <Typography
            variant="body2"
            text={transformDateWithDay(at)}
            color={theme.color.general.dark}
            as={ReactTexty}
          />
        </>
      )
    }

    const urlify = (text) => {
      const currText = Array.isArray(text) ? text[0] : text
      const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
      return currText?.replace(urlRegex, function (url, b, c) {
        const url2 = c == 'www.' ? 'http://' + url : url
        return '<a href="' + url2 + '" target="_blank" class="noStyles link">' + url + '</a>'
      })
    }

    const textToHighlight = urlify(text)
    const chunks = findAll({
      caseSensitive: false,
      searchWords: [searchValue],
      textToHighlight,
      autoEscape: true,
    })

    const formattedHTML = chunks
      .map((chunk) => {
        const { end, highlight, start } = chunk
        const tagsComponents = textToHighlight?.match(/<[^>]+>/g)
        let tagsIndex
        const highText = textToHighlight?.substr(start, end - start)
        if (tagsComponents?.length > 0) {
          tagsIndex = tagsComponents.map((tag) => {
            const startIndex = textToHighlight?.indexOf(tag)
            const endIndex = startIndex + tag.length
            return start >= startIndex && endIndex >= end
          })
        }

        const hasNoTag = tagsIndex ? !tagsIndex.includes(true) : true
        if (highlight && hasNoTag) {
          return `<mark className="highlightedText">${highText}</mark>`
        } else {
          return highText
        }
      })
      .join('')

    return (
      <StyledChatMessage
        ref={ref}
        className={clsx(
          isMessageFromOperator && 'operatorMessage',
          isSystemMessage && 'systemMessage',
          isBulkMessage && 'bulkMessage'
        )}
      >
        <FlexRow alignItems="start">
          {isMessageFromOperator && operator_pic && <Userpic height={36} width={36} src={operator_pic} />}
          <TextPanel
            title={getTitle()}
            content={text && parse(formattedHTML)}
            className="chatMessage"
            padding="0"
          />
        </FlexRow>

        {!!messageImages?.length && <FilesList files={messageImages} type="images" />}
        {!!messageFiles?.length && <FilesList files={messageFiles} type="files" />}
      </StyledChatMessage>
    )
  }
)

export default MessageItem
