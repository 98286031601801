import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { Button } from '../../../atomic-design-components'
import Form from '../../../components/Form'
import NameWithAvatar from '../../../components/NameWithAvatar'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import {
  deleteEntity,
  getInvitationLink,
  resendInvitations,
  upsertSidebarItem,
  userRequestResetPassword,
} from '../../../redux-saga/actions'
import {
  selectMainData,
  selectSidebarDetails,
  selectSidebarDetailsStatus,
  selectSidebarMetaData,
  selectUserMemberId,
  selectUserRole,
} from '../../../redux-saga/selectors'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'
import { StyledFlexRow, StyledNameRow } from './styled'

const FormBlockWrapper = withTheme(({ children, blockKey, id, formValues, theme }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('all')
  const { inProgress: sidebarInProgress } = useMappedState(selectSidebarMetaData)
  const initialMemberStatus = useMappedState(selectSidebarDetailsStatus)

  const isStatusPending = initialMemberStatus === 'pending'

  const onCopyLinkClick = (e) => {
    e.preventDefault()
    dispatch(getInvitationLink(id))
  }

  const onDeleteConfirm = () => {
    dispatch(deleteEntity({ id, type: 'members', navigate, location }))
  }

  const onResendInvite = (e) => {
    e.preventDefault()
    dispatch(resendInvitations(id))
  }

  const onResetPassword = (e) => {
    e.preventDefault()
    dispatch(userRequestResetPassword(formValues.email))
  }

  const getButtons = () => {
    if (isStatusPending) {
      const iconProps = { fill: theme.color.general.black, margin: '0', size: 20 }

      return (
        <StyledFlexRow>
          <Button
            onClick={onCopyLinkClick}
            margin="0 5px 0 0"
            iconName="linkSign"
            padding="11px"
            variant="general"
            iconLeftProps={iconProps}
          />
          <Button
            onClick={onResendInvite}
            disabled={sidebarInProgress}
            margin="0 5px 0 0"
            padding="12px 5px"
            iconName="arrowSync"
            variant="general"
            iconLeftProps={{ fill: theme.color.general.black, size: 16 }}
          >
            <ReactTexty>{t('resendInvitation')}</ReactTexty>
          </Button>
          <Button
            padding="11px"
            iconName="trashCan"
            variant="general"
            iconLeftProps={iconProps}
            disabled={sidebarInProgress}
            confirmButtonProps={{
              fullWidth: true,
              iconLeftProps: {
                size: 16,
                margin: '0 5px 0 0',
                fill: theme.color.general.white,
                name: 'inputError',
              },
              backgroundColor: theme.color.status.error,
              color: theme.color.general.white,
              classNameConfirm: 'pendingMemberDelete',
              onDeleteConfirm: onDeleteConfirm,
              variant: 'primary',
            }}
          />
        </StyledFlexRow>
      )
    } else {
      return (
        <>
          <Button
            iconLeftProps={{ name: 'trashCan', size: 15 }}
            text={t('deleteMemberPermanently')}
            fullWidth
            backgroundColor={theme.color.status.error}
            color={theme.color.general.white}
            confirmButtonProps={{
              onDeleteConfirm,
              iconLeftProps: {
                fill: theme.color.status.error,
                name: 'inputError',
              },
            }}
          />
          <Button
            color={theme.color.status.error}
            iconLeftProps={{ size: 16, name: 'passwordReset' }}
            fullWidth
            backgroundColor={theme.color.general.white}
            text={t('resetPassword')}
            hoverType="opacity"
            margin="8px 0 -12px 0"
            withoutBorder
            confirmButtonProps={{
              fullWidth: true,
              iconLeftProps: {
                fill: theme.color.general.white,
                name: 'inputError',
              },
              backgroundColor: theme.color.status.error,
              color: theme.color.general.white,
              onDeleteConfirm: onResetPassword,
              variant: 'primary',
            }}
          />
        </>
      )
    }
  }

  if (blockKey === 'noTitle') {
    return (
      <>
        {children}
        {getButtons()}
      </>
    )
  }

  return children
})

const MemberEdit = () => {
  const dispatch = useDispatch()
  const formDataInitial = useMappedState(selectSidebarDetails)
  const departments = useMappedState(selectMainData('departments')) || []
  const role = useMappedState(selectUserRole)
  const userMemberId = useMappedState(selectUserMemberId)

  const onFormSubmit = (valuesChanged) => {
    const newValues = { ...valuesChanged }

    if (!isObjectEmpty(valuesChanged)) {
      if ('departments' in valuesChanged) {
        newValues.department_ids = newValues.departments || []
        delete newValues.departments
      }

      dispatch(
        upsertSidebarItem({
          id: formDataInitial.id,
          type: 'members',
          requestBody: transformDataToBeSent(newValues),
        })
      )
    }
  }
  const iconProps = { name: 'floppy', margin: '0 6px 0' }

  return (
    <>
      <StyledNameRow padding="20px">
        <NameWithAvatar
          avatarSrc={formDataInitial?.profile_picture}
          avatarSize={60}
          name={formDataInitial?.full_name}
          typographyVariant="h3"
        />
      </StyledNameRow>
      <Form
        initialValues={formDataInitial}
        formConfig={FORM_CONFIGS.members}
        isSubmitOnBlur
        validationRules={FORM_CONFIGS.members.validationRules}
        onSubmit={onFormSubmit}
        buttonsAreSticky
        withActions
        FormBlockWrapper={FormBlockWrapper}
        saveBtnIconProps={iconProps}
        optionsData={{ departments, role, currentUserMemberId: userMemberId }}
      />
    </>
  )
}

export default MemberEdit
