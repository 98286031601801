import React from 'react'

export const dollarSign = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M8 5.14281V3.42853"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.28589 9.71425C6.28589 10.5714 7.0516 10.8571 8.00017 10.8571C8.94875 10.8571 9.71446 10.8571 9.71446 9.71425C9.71446 7.99997 6.28589 7.99997 6.28589 6.28568C6.28589 5.14282 7.0516 5.14282 8.00017 5.14282C8.94875 5.14282 9.71446 5.57711 9.71446 6.28568"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00024 10.8571V12.5714"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0001 15.4286C12.1028 15.4286 15.4287 12.1027 15.4287 7.99998C15.4287 3.8973 12.1028 0.571411 8.0001 0.571411C3.89742 0.571411 0.571533 3.8973 0.571533 7.99998C0.571533 12.1027 3.89742 15.4286 8.0001 15.4286Z"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
