import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../../constants'

export const StyledDrawer = styled.div`
  display: block;
  position: ${({ absolutePositioned }) => (absolutePositioned ? 'absolute' : 'relative')};
  z-index: 4;
  height: 100vh;
  width: ${({ width }) => width};
  background-color: ${({ theme: { color } }) => color.general.white};
  transition: transform 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  //overflow: hidden;
  -webkit-overflow-scrolling: touch;

  .titleRow {
    position: sticky;
    top: 0;
    background-color: ${({ theme: { color } }) => color.general.white};
    z-index: 3;
  }

  &.static {
    transition: none;
  }

  &.opened {
    overflow-y: auto;
    //& > div {
    //  overflow-x: hidden;
    //}
  }

  &.left {
    transform: translateX(-100%);

    &.opened {
      transform: translateX(0);
    }
  }

  &.right {
    transform: translateX(100%);

    &.opened {
      transform: translateX(0);
    }
  }

  &.displayed {
    display: block;
    //will-change: transform;
  }

  .closeIcon {
    padding: ${({ closeIconPadding }) => closeIconPadding};
    cursor: pointer;
  }

  @media only screen and (max-width: ${({ width }) => width}) {
    width: 100vw;

    &.right {
      //right: -100vw;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    &.right.opened {
      top: ${HEADER_HEIGHT}px;
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: 900px) {
    &.right.opened {
      position: absolute;
      right: 0;
      top: 0;
      height: calc(100vh - ${HEADER_HEIGHT});
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    height: calc(100vh - ${HEADER_HEIGHT}px);
  }
`
