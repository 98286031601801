import React from 'react'

export const statusActiveWithError = ({ width, height, theme: { color } }) => (
  <svg width={width || 17} height={height || 17} viewBox="0 0 17 17" fill="none">
    <path
      d="M8.0001 15.4286C12.1028 15.4286 15.4287 12.1027 15.4287 7.99998C15.4287 3.8973 12.1028 0.571411 8.0001 0.571411C3.89742 0.571411 0.571533 3.8973 0.571533 7.99998C0.571533 12.1027 3.89742 15.4286 8.0001 15.4286Z"
      fill={color.status.success || '#12B357'}
      fillOpacity="0.25"
      stroke={color.status.success || '#12B357'}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.15723 8.62563L6.50008 10.4999C6.56301 10.5517 6.63668 10.5889 6.71576 10.6086C6.79484 10.6284 6.87732 10.6303 6.95723 10.6142C7.03789 10.5991 7.11435 10.5667 7.18141 10.5194C7.24846 10.4721 7.30453 10.4109 7.3458 10.3399L11.0144 4.0542"
      stroke={color.status.success || '#12B357'}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0701 15.5699C14.0031 15.5699 15.5701 14.0029 15.5701 12.0699C15.5701 10.1369 14.0031 8.56995 12.0701 8.56995C10.1371 8.56995 8.57007 10.1369 8.57007 12.0699C8.57007 14.0029 10.1371 15.5699 12.0701 15.5699Z"
      fill={color.status.error || '#E83C0C'}
      stroke={color.status.error || '#E83C0C'}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0702 14.07V13.9546M12.0701 10V12.57"
      stroke="white"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
