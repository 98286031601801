import React, { useContext, useEffect } from 'react'
import ReactHelmet from '../../wrappers/ReactHelmet'

import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import GridLayout from '../Layout/GridLayout'
import MyCompanyLeftPanel from './panels/MyCompanyLeftPanel'
import theme from '../../theme'
import { ScreenContext } from '../../hooks/useScreenContext'
import { Button } from '../../atomic-design-components'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectUserRole } from '../../redux-saga/selectors'

const MyCompany = ({ children, RightPanelComponent, className }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { width, md, lg } = useContext(ScreenContext)

  const role = useMappedState(selectUserRole)

  const isMobile = width && width < lg

  useEffect(() => {
    if (width > md && (pathname === '/my-company' || pathname === '/my-company/')) {
      navigate('/my-company/general', { replace: true })
    }
  }, [pathname])

  const onArrowClick = () => {
    navigate({ pathname: '../my-company' })
  }

  if (role === 'system_administrator' && pathname.includes('/my-company/payments')) {
    return <Navigate replace to="/404" />
  }

  return (
    <>
      <ReactHelmet title="companyTitle" />

      <GridLayout
        RightPanelComponent={RightPanelComponent}
        RightPanelMinWidth="330px"
        LeftPanelComponent={
          role === 'system_administrator' && pathname.startsWith('/payments') ? undefined : (
            <MyCompanyLeftPanel isExtended={true} isMobile={isMobile} theme={theme} />
          )
        }
        type="myCompany"
        isLeftPanelShown={true}
        className={className}
      >
        {width < md && role !== 'system_administrator' && (
          <Button
            variant="general"
            iconLeftProps={{
              name: 'paginationArrow',
              fill: theme.color.general.black,
              width: 20,
              height: 20,
              className: 'withoutHover',
            }}
            className="navbarButton leftMenuButton"
            onClick={onArrowClick}
            margin="20px 30px 0"
          />
        )}
        {children || <Outlet />}
      </GridLayout>
    </>
  )
}

export default MyCompany
