import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from '@redux-devtools/extension'

import createRootReducer from './redux-saga/rootReducer'
import rootSaga from './redux-saga/rootSaga'

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]

  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(createRootReducer, preloadedState, composedEnhancers)

  sagaMiddleware.run(rootSaga)

  return store
}
