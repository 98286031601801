import React from 'react'
import { withTheme } from 'styled-components'

import { Tag, Typography } from '../../../atomic-design-components'
import { useNavigate } from 'react-router-dom'

const ChatsTableCell = ({ chatId, messagesCount, theme: { color } }) => {
  const navigate = useNavigate()

  const onChatsClick = (e) => {
    e.stopPropagation()
    navigate(chatId ? `../chats/${chatId}` : '../chats')
  }

  return (
    <Tag
      onClick={onChatsClick}
      color={color.secondary.dark}
      backgroundColor={color.secondary.main}
      hexOpacity="1A"
      withBackgroundOpacity
      iconLeftProps={{
        margin: '0 5px 0 0',
        width: 15,
        height: 15,
        name: 'chats',
        fill: color.secondary.dark,
      }}
    >
      <Typography variant="button1">{messagesCount}</Typography>
    </Tag>
  )
}

export default withTheme(ChatsTableCell)
