import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, FlexRow, Typography, HeaderMenuIcon } from '../../../atomic-design-components'
import { ScreenContext } from '../../../hooks/useScreenContext'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectTableTotalItems } from '../../../redux-saga/selectors'

const TableTitleBlock = ({ type, withMenuIcon, menuIconProps, withBadge, children }) => {
  const { t } = useTranslation('menu')
  const { width, md } = useContext(ScreenContext)

  const total_items = useMappedState(selectTableTotalItems)

  return (
    <FlexRow className="titleRow" justifyContent={width <= md ? 'space-between' : 'start'}>
      <Typography variant="h3">{t(type)}</Typography>
      {withBadge && <Badge returnBadgeOnly badgeContent={total_items || 0} className="itemsCountBadge" />}
      {withMenuIcon && <HeaderMenuIcon className="menuIcon" {...menuIconProps} />}
      {children}
    </FlexRow>
  )
}

export default TableTitleBlock

TableTitleBlock.defaultProps = {
  withBadge: true,
}
