import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactTexty from 'react-texty'
import { useDispatch } from 'react-redux'

import { Typography, Button, TextPanel } from '../../../atomic-design-components'
import { StyledPanel } from './styled'
import { popupAlertShow } from '../../../redux-saga/actions'

const SetupPanel = ({ data, theme }) => {
  const { t } = useTranslation('all')

  const dispatch = useDispatch()
  const codeText = `<meta name="viewport" content="width=device-width, initial-scale=1" /><script async src="${window.location.origin}/loader.js?license=${data}"></script>`

  const handleClick = (event) => {
    event.stopPropagation()
    navigator.clipboard.writeText(codeText)
    dispatch(
      popupAlertShow({
        contentKey: 'codeCopied',
        type: 'success',
        withCloseButton: true,
        iconName: 'copy',
      })
    )
  }

  return (
    <StyledPanel>
      <Typography variant="body2" color={theme.color.general.dark} text={t('setupCodeText')} />
      <TextPanel
        content={
          <ReactTexty>
            <code>{codeText}</code>
          </ReactTexty>
        }
        className="codePanel"
      />
      <Button
        variant="primary"
        text={t('copyCode')}
        fullWidth
        iconLeftProps={{ name: 'copy' }}
        onClick={handleClick}
      />
    </StyledPanel>
  )
}

export default SetupPanel
