import React from 'react'

export const facebookLetter = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none">
    <path
      d="M16.5 8.04868C16.5 3.60243 12.9194 0 8.5 0C4.08065 0 0.5 3.60243 0.5 8.04868C0.5 12.0659 3.42548 15.3957 7.25 16V10.3753H5.21774V8.04868H7.25V6.27538C7.25 4.25834 8.44355 3.14418 10.2716 3.14418C11.1471 3.14418 12.0626 3.30126 12.0626 3.30126V5.28097H11.0535C10.06 5.28097 9.75 5.9015 9.75 6.53793V8.04868H11.9687L11.6139 10.3753H9.75V16C13.5745 15.3957 16.5 12.0659 16.5 8.04868Z"
      fill={fill || color.general.white}
    />
  </svg>
)
