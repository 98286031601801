import React, { useState, useEffect } from 'react'

import { StyledRatingComponent, StyledRatingLine } from './styled'
import { FlexRow, Icon, Typography } from '../../../atomic-design-components'
import AnalyticsLine from './AnalyticsLine'
import { getPercent } from '../../../utils/dataTransformations'

const RatingComponent = ({ color, t, data, total }) => {
  const ratingValue = new Array(5).fill(1).map((el, i) => {
    const ratePoint = 5 - i
    const rateQuant = total === 0 ? total : data.find((el) => +el.value === ratePoint)?.quantity || 0
    return { [ratePoint]: rateQuant }
  })

  let ratingTotalValue = 0
  let ratingTotalCount = 0
  data.map((el) => {
    ratingTotalValue += +el.value * el.quantity
    ratingTotalCount += el.quantity
  })

  const ratingData = {
    rating: ratingValue,
    total: total === 0 ? total : ratingTotalValue / ratingTotalCount,
  }

  return (
    <StyledRatingComponent color={color}>
      <FlexRow margin="0 0 10px 0">
        <Icon name="starSign" size={18} stroke={color.primary.main} margin="0 8px 0 0" />
        <Typography variant="caption2" text={t('rating')} />
      </FlexRow>

      {ratingData.rating.map((rate, i) => {
        const counter = Object.keys(rate)[0]
        const percent = getPercent(rate[counter], data)

        return (
          <StyledRatingLine key={i}>
            <div className="starsContainer">
              {Array(+counter)
                .fill(1)
                .map((count, i) => {
                  if (i < counter)
                    return (
                      <Icon name="starSign" fill={color.primary.lightest} width={16} height={16} key={i} />
                    )
                })}
            </div>
            <AnalyticsLine color={color} value={percent} width="120px" />
            <Typography variant="caption3" text={`${percent}%`} />
          </StyledRatingLine>
        )
      })}

      <FlexRow justifyContent="space-between" padding="10px 0 0" margin="auto 0 0 0">
        <Typography variant="caption2" text={t('averageRating')} />
        <Typography variant="caption2" text={ratingData.total.toFixed(2)} />
      </FlexRow>
    </StyledRatingComponent>
  )
}

export default RatingComponent
