import React from 'react'
import { useParams } from 'react-router-dom'

import CustomerEdit from './CustomerEdit'
import CustomersInvoice from './CustomersInvoice'

const CustomersRightPanel = () => {
  const params = useParams()
  const { action } = params

  if (action === 'generate-invoice') {
    return <CustomersInvoice />
  }

  return <CustomerEdit />
}

export default CustomersRightPanel
