import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist'

import Form from '../../components/Form'
import { FORM_CONFIGS } from '../../config/forms'
import { Button } from '../../atomic-design-components'
import { userSignUp } from '../../redux-saga/actions'

const FormCreate = ({ initialValues }) => {
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(undefined)
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const { t } = useTranslation('auth')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { type, iconRightProps } = useIsPasswordShown()

  const onFormSubmit = (credentials) => {
    dispatch(
      userSignUp(
        { ...credentials, company_phone: `+${credentials.company_phone.replace(/\D/g, '')}` },
        navigate
      )
    )
  }

  return (
    <Form
      className="form"
      type="signUp"
      withCustomValidationRule
      initialValues={initialValues}
      // serverError={error !== false}
      onSubmit={onFormSubmit}
      validationRules={{
        full_name: ['required'],
        company_name: ['required'],
        company_phone: ['required', 'phone'],
        email: ['required', 'email'],
        password: ['required', { type: 'password', isPasswordValid }],
      }}
      fields={[
        FORM_CONFIGS.signUp.fields[0],
        FORM_CONFIGS.signUp.fields[1],
        FORM_CONFIGS.signUp.fields[2],
        FORM_CONFIGS.signUp.fields[3],
        {
          ...FORM_CONFIGS.signUp.fields[4],
          type,
          iconRightProps,
          onInputValueChange: (value) => setPassword(value),
        },
      ]}
    >
      <CustomPasswordChecklist
        password={password}
        setIsPasswordValid={setIsPasswordValid}
        className={isSubmitPressed && !isPasswordValid ? 'passError' : ''}
      />
      <Button
        fullWidth
        variant="primary"
        type="submit"
        iconName="peopleSign"
        onClick={() => setIsSubmitPressed(true)}
      >
        {t('signUp')}
      </Button>
    </Form>
  )
}

export default withTheme(FormCreate)
