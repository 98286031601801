import React from 'react'
import { PropTypes as T } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../atomic-design-components'
import { StyledTable } from './styled'

const StripedRowsBlock = ({ data }) => {
  const { t } = useTranslation('all')

  return (
    <StyledTable>
      {Object.entries(data).map((row) => {
        if (!row[1]) {
          return null
        }

        return (
          <tbody key={row[0]}>
            <tr>
              <td className="nameCell">
                <Typography variant="caption1">{t(row[0])}</Typography>
              </td>
              <td className="valueCell">
                <Typography variant="body2">{row[1]}</Typography>
              </td>
            </tr>
          </tbody>
        )
      })}
    </StyledTable>
  )
}

export default StripedRowsBlock
