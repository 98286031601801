import styled from 'styled-components'

export const StyledContent = styled.div`
  height: ${({ topHeight, maxHeight }) => (maxHeight ? `${maxHeight}px` : `calc(100vh - ${topHeight}px)`)};
  padding: 20px;
  position: relative;

  .emailForCopy {
    cursor: pointer;
  }
`
