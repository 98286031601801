import { createGlobalStyle } from 'styled-components'
import { getCssFromTheme } from './utils/css'

const GlobalStyle = createGlobalStyle`
  // ReactTexty styles
  [data-texty] {
    display:block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  [data-texty-tooltip] {
    background-color: ${({ theme }) => theme.color.general.black};
    color: ${({ theme }) => theme.color.general.white};
    max-width: 60vw;
    padding: 10px 12px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    
    span {
      color: ${({ theme }) => theme.color.general.white};
    }
  }

  [data-texty-arrow] {
    border-color: ${({ theme }) => theme.color.general.black} transparent transparent;
  }

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    z-index: 50000;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    //color: white !important;
    border-radius: 10px;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: ${({ theme }) => theme.color.general.lighter};
    //margin-bottom: -60px;
    //margin-right: 60px;

    .table & {
      margin-bottom: 0;
      //margin-left: 150px;
    }
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
  }
  
  body {
    margin: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${({ theme }) => theme.font.family.primary};
  }
  
  button {
    color: inherit; 
    border: none; 
    padding: 0; 
    cursor: pointer;
  }
  
  button, input, optgroup, select, textarea {
    font-family: inherit;
  }
  
  figure {
    margin: 0;
  }
  
  input::-moz-focus-inner {
    border: 0; 
    padding: 0; 
    margin: 0;
  }
  
  ul, ol, dd {
    margin: 0;
    padding-left: 16px; 
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0; 
    //font-size: inherit; 
    //font-weight: inherit;
  }

  h1, .ql-snow .ql-editor h1 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h1')};
  }
  h2, .ql-snow .ql-editor h2 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h2')};
  }
  h3, .ql-snow .ql-editor h3 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h3')};
  }
  h4, .ql-snow .ql-editor h4 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h4')};
  }
  
  p {
    margin: 0;
  }
  
  fieldset {
    border-width: 0; 
    padding: 0; 
    margin: 0;
  }
  
  :focus {
    outline: 0;
  }
  
  *, :after, :before {
    box-sizing: border-box;
  }

  //scrollbar
  ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 4px ${({ theme }) => theme.color.general.lightest}; 
//   border-radius: 5px;
// }
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: ${({ theme }) => theme.color.general.lighter};
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${({ theme }) => theme.color.general.light};
}

.link.noStyles {
  text-decoration: none;
  color: ${({ theme }) => theme.color.secondary.main};
}
`

export default GlobalStyle
