import React from 'react'
import ReactTexty from 'react-texty'

import { FlexRow, Typography } from '../../../atomic-design-components'
import { StyledCardContent } from './styled'

// you should use name of column keys from config to get data here (not dataKeys): data.key

const MembersTableCard = ({ cellProps, editMode, index, onClick, data, columns, t, theme }) => {
  const isInactive = data?.status?.props?.dataTooltip === 'Inactive'

  return (
    <StyledCardContent className={isInactive ? 'inactive' : ''}>
      <FlexRow className="cardTableItemRow">
        <FlexRow className="nameRow">{data.fullName}</FlexRow>
        <FlexRow>
          {data.rating}
          {data.status}
        </FlexRow>
      </FlexRow>
      <FlexRow className="cardTableItemRow">
        {data.email}
        <Typography as={ReactTexty} variant="caption1" text={data.role} />
      </FlexRow>
      <FlexRow className="cardTableItemRow">{data.departments}</FlexRow>
    </StyledCardContent>
  )
}

export default MembersTableCard
