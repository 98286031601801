import {
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_UP_SUCCESS,
  CONFIRM_EMAIL_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  INITIALIZE_APP,
  SIGN_IN_ERROR,
  GET_CURRENT_USER_ERROR,
  GET_INVITE_DETAILS_SUCCESS,
  TOGGLE_IS_USER_ONLINE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_USER_AVATAR,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
} from '../constants'

const defaultState = {
  token: null,
  details: {
    email: '',
    role: '',
    plan_slug: '',
  },
  subscriptionDetails: {},
  inviteDetails: {
    email: '',
    company_name: '',
  },
  inProgress: false,
  resetPassInProgress: false,
  error: false,
  isAuthenticated: null,
  isAppInitialized: false,
  isUserOnline: true,
  email_verified: undefined,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case INITIALIZE_APP:
      return {
        ...state,
        inProgress: true,
        isAppInitialized: true,
      }
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.token,
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        token: action.token,
        details: { ...state.details, email: action.email },
        email_verified: false,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassInProgress: true,
        error: false,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassInProgress: false,
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassInProgress: false,
        error: true,
      }
    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        email_verified: true,
      }
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        details: action.payload,
        isAuthenticated: true,
        inProgress: false,
      }
    case GET_CURRENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionDetails: action.payload,
      }
    case SET_USER_AVATAR:
      return { ...state, details: { ...state.details, member_profile_picture: action.avatar } }
    case GET_INVITE_DETAILS_SUCCESS:
      return { ...state, inviteDetails: action.payload }
    case TOGGLE_IS_USER_ONLINE:
      return { ...state, isUserOnline: action.payload.isOnline }
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          wfp_subscription_end_at: state.subscriptionDetails.subscription_expire_at,
        },
        inProgress: false,
      }
    case SIGN_IN_ERROR:
    case GET_CURRENT_USER_ERROR:
      return { ...state, inProgress: false, error: true, isAppInitialized: true }
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
