import styled from 'styled-components'

export const StyledPopupAlert = styled.div`
  width: fit-content;
  padding: 12px 16px 13px 16px;
  background-color: ${({ splitThemeColor, theme: { color } }) =>
    `${color[splitThemeColor[0]][splitThemeColor[1]]}d9`};
  box-shadow: ${({ splitThemeColor, theme: { color } }) =>
    `0px 14px 15px ${color[splitThemeColor[0]][splitThemeColor[1]]}40`};
  color: ${({ theme }) => theme.color.general.white};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 454px;
  //min-width: 30vw;
  transition: all 0.2s;
  white-space: pre-line;
  margin: 0 auto;
  &:not(.warning) {
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &.top {
    margin-bottom: 15px;
  }

  &.bottom {
    margin-top: 15px;
  }

  .icon {
    cursor: pointer;
  }
  .leftIcon {
    margin-right: 12px;
    cursor: default;
  }
  .rightIcon {
    margin-left: 12px;
  }
  &.hide {
    animation: scale-out-center 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

    //& ~ div {
    //  animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    //}
  }

  //@keyframes slide-top {
  //  0% {
  //    transform: translateY(0);
  //  }
  //  100% {
  //    transform: translateY(-100%);
  //  }
  //}
  //
  //@keyframes slide-down {
  //  0% {
  //    transform: translateY(-100%);
  //  }
  //  100% {
  //    transform: translateY(0);
  //  }
  //}

  @keyframes scale-out-center {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`
