import React from 'react'

import { FlexRow, Typography } from '../../../atomic-design-components'
import { StyledCardContent } from './styled'

// you should use name of column keys from config to get data here (not dataKeys): data.key

const PaymentsTableCard = ({ cellProps, editMode, index, onClick, data, columns, t, theme }) => {
  return (
    <StyledCardContent>
      <FlexRow className="cardTableItemRow">
        <FlexRow gap="10px">
          {data.status}
          <Typography variant="button1" text={data.plan} />
        </FlexRow>
        <Typography variant="button1" text={data.created} />
      </FlexRow>
      <FlexRow className="cardTableItemRow">
        {data.paymentMethod}
        <Typography variant="h3" text={data.pricing} />
      </FlexRow>
    </StyledCardContent>
  )
}

export default PaymentsTableCard
