import styled from 'styled-components'

export const StyledUploadImages = styled.div`
  .actions {
    display: flex;
    justify-content: space-evenly;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 10px 0;
    width: 100%;
  }
  &.hidden {
    width: calc(100% - 10px);
    position: absolute;
    bottom: 40px;
    left: 5px;
    opacity: 0;
    div,
    div * {
      cursor: auto;
    }
  }
  &.active {
    opacity: 1;
    bottom: 2px;
    div {
      height: ${({ dropHeight }) => `calc(90px + ${dropHeight}px)`};
    }
  }
`

export const StyledUploadedList = styled.div`
  position: absolute;
  left: 35px;
  bottom: 80px;
  width: 80%;
  .uploadItemsList {
    width: 100%;
    display: flex;
    gap: 8px 15px;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`

export const StyledUploadedItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  max-width: 190px;
  .size {
    white-space: nowrap;
  }
`
