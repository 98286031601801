import styled from 'styled-components'
import { getCssFromTheme } from '../../utils/css'

export const StyledGeneral = styled.div`
  max-width: 660px;
  margin: 10px auto;

  .panelHeader {
    .typography {
      ${({ theme }) => getCssFromTheme(theme, 'typography', 'h3')};
    }
    margin-bottom: 10px;
  }

  .explanation {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 20px;
  }
`
