import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import UploadImages from '../../../components/UploadImages'
import UploadedItemsList from '../../../components/UploadImages/UploadedItemsList'
import useAutosizeTextArea from '../../../hooks/useAutosizeTextArea'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectFilesUploaded } from '../../../redux-saga/selectors'
import Button from '../Button/Button'
import { StyledLabel } from '../Input/styled'
import { StyledInput, StyledInputContainer } from './styled'

const MessageTextarea = ({
  theme,
  name,
  label,
  labelType = 'border',
  placeholder,
  disabled,
  required,
  value,
  error,
  onChange,
  initialHeight,
}) => {
  const { t } = useTranslation('all')

  const files = useMappedState(selectFilesUploaded)
  const [extraPadding, setExtraPadding] = useState(0)
  const [uploadClicked, setUploadClicked] = useState(false)

  const textAreaRef = useRef(null)
  useAutosizeTextArea(textAreaRef.current, value, 500, initialHeight)

  const filesHeight = useRef(null)

  useEffect(() => {
    if (filesHeight.current) {
      setExtraPadding(filesHeight.current.clientHeight)
    }
  }, [filesHeight, extraPadding, uploadClicked, files])

  const onUploadClick = () => setUploadClicked((prev) => !prev)

  return (
    <StyledInputContainer>
      {label && labelType === 'border' && (
        <StyledLabel className={clsx(required && 'required', 'label', 'border')}>
          {label?.toLowerCase()}
        </StyledLabel>
      )}
      <StyledInput
        className={clsx('inputWrapper', disabled && 'disabled', !!error && 'hasError')}
        error={error}
        multiline={true}
        fullWidth
        required={required}
        onChange={onChange}
        value={value}
        inputRef={textAreaRef}
        extraPadding={extraPadding}
        placeholder={t(placeholder)}
        disabled={disabled}
        active
        name={name}
        insideButtons={
          <Button
            disabled={disabled}
            variant="general"
            iconLeftProps={{
              name: 'attachSign',
              fill: theme.color.general.black,
              className: 'withoutHover',
            }}
            className="attachButton"
          >
            <UploadImages
              isSimpleButton
              maxFiles={10}
              maxSize={20971520}
              onHide={onUploadClick}
              chatID="bulkMessage"
            />
          </Button>
        }
      >
        <UploadImages
          maxFiles={10}
          maxSize={20971520}
          onHide={onUploadClick}
          noClick={true}
          hiddenZone
          dropHeight={extraPadding}
          active={true}
          chatID="bulkMessage"
        />
      </StyledInput>
      <UploadedItemsList
        chatID="bulkMessage"
        isSimpleButton
        uploadRef={filesHeight}
        onHide={onUploadClick}
        className={clsx(!!error && 'hasError')}
        stateKey="filesUploaded"
      />
    </StyledInputContainer>
  )
}

export default withTheme(MessageTextarea)
