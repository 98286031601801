import React from 'react'

import Icon from '../../../atoms/Icon'
import theme from '../../../../theme'

const SortIndicator = ({ className, sortOrder }) => {
  return (
    <>
      {sortOrder === 'asc' && (
        <Icon name="chevronUp" fill={theme.color.general.light} className={className} />
      )}
      {sortOrder === 'desc' && (
        <Icon name="chevronDown" fill={theme.color.general.light} className={className} />
      )}
    </>
  )
}

export default SortIndicator
