import styled from 'styled-components'

export const StyledTable = styled.table`
  border-spacing: 0;
  display: grid;
  margin-bottom: 20px;
  tr {
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
  }
  tbody {
    border-radius: 3px;
  }
  tbody:nth-child(odd) {
    background-color: ${({ theme: { color } }) => color.general.lightest};
  }
  .nameCell {
    padding: 0;
    width: 35%;
  }
  .valueCell {
    padding: 0 10px;
    width: 65%;
  }
`
