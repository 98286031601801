import React from 'react'

import { Input } from '../../../../../atomic-design-components'

const CONFIG = {
  name: { key: 'name', label: 'name', iconLeftProps: { name: 'contactCard' } },
  phone: { key: 'phone', label: 'phone', iconLeftProps: { name: 'phone' } },
  email: { label: 'email', key: 'email', iconLeftProps: { name: 'doggySign' } },
  // departments: {
  //   key: 'departments',
  //   label: 'departments',
  //   labelKey: 'name',
  //   iconLeftProps: { name: 'multiUsers', fill: theme.color.general.light },
  // },
}

const PreChatFormFields = ({ departments, fields = {}, t }) => {
  const fieldsVisible = Object.keys(fields).filter((key) => fields[key].is_visible)

  return (
    <>
      {fieldsVisible.map((fieldName) => {
        return <Input required={fields[fieldName].is_required} key={fieldName} {...CONFIG[fieldName]} label={t(CONFIG[fieldName].label)} />
      })}
      {/*{departments?.is_selected && <Select {...CONFIG.departments} />}*/}
    </>
  )
}

export default PreChatFormFields
