// import pL from 'js-regex-pl' // any letter from any language
import dot from 'dot-object'

import { isPossiblePhoneNumber } from 'react-phone-number-input'
import isEmail from 'sane-email-validation'

const checkUrl = (value) => {
  const res = value.match(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  )
  return res !== null
}

export const validators = {
  privacy_policy_required_custom: (value) => {
    return !value.is_required || value.document_url?.replace('https://', '')
  },
  privacy_policy_website_custom: (value) => {
    const url = value.document_url

    return !value.is_required || (url && checkUrl(url))
  },
  requiredPrice: (value) => {
    if (+value === 0 || !value || (Array.isArray(value) && !value.length)) {
      return false
    }

    if (typeof value.trim === 'function') {
      return value.trim()
    }

    return value
  },
  required: (value) => {
    if (value === 0) {
      return true
    }

    if (!value || (Array.isArray(value) && !value.length)) {
      return false
    }

    if (typeof value.trim === 'function') {
      return value.trim()
    }

    return value
  },
  requiredIfFieldsEmpty: (value, ruleParams, data) => {
    return (
      // value ||
      ruleParams.fields.some((field) => {
        if (data?.values) {
          const value = dot.pick(field, data.values)
          const hasValue = Array.isArray(value) ? value.length : value
          return hasValue
        }
      })
    )
  },
  requiredIfFieldsNotEmpty: (value, ruleParams) => {
    return (
      value || ruleParams.fields.every((field) => ruleParams?.values && !dot.pick(field, ruleParams.values))
    )
  },
  requiredStringWithPresetPart: (value, ruleParams) => {
    if (
      !value ||
      value === ruleParams.presetPart ||
      value === ruleParams.presetPart.slice(0, ruleParams.presetPart.length - 1)
    ) {
      return false
    }

    return value
  },
  passEqualTo: (value, ruleParams) => {
    if (value || ruleParams.value) {
      return value === ruleParams.value
    }
    return true
  },
  // date: value => !value || moment(value, 'DD.MM.YYYY', true).isValid() || moment(value, 'DD/MM/YYYY', true).isValid(),
  // dateISO: value => !value || moment(value, 'YYYY-MM-DD', true).isValid(),
  minLength: (value, ruleParams) => !value || value.length >= ruleParams.value,
  maxLength: (value, ruleParams) => !value || value.length <= ruleParams.value,
  maxNumber: (value, ruleParams) => !value || value <= ruleParams.value,
  minNumber: (value, ruleParams) => !value || value >= ruleParams.value,
  // equalTo: (value, ruleParams, data) => !value || data[ruleParams.field] === value,
  regex: (value, ruleParams) => !value || new RegExp(ruleParams.value, 'i').test(value),
  // ^[a-zA-Z\u0400-\u04FF\s'\-.]+$ - old name regex
  // name: (value) => new RegExp(`^[ ${pL}'\`,\.-]*[${pL}'\`]$`).test(value),
  specialCharacters: (value) => new RegExp('^[a-zA-Z0-9]*$').test(value),
  onlyDigits: (value) => !value || new RegExp('^(0|[1-9][0-9]{0,30})$').test(value),
  positiveFloat: (value) => !value || new RegExp(/^(?:[1-9]\d*|0)(?:(\.|,)\d+)?$/).test(value),
  price: (value) => new RegExp('^₴ [0-9]+.[0-9][0-9]$').test(value),
  email: (value) => {
    return !value || isEmail(value)
  },
  password: (value, ruleParams) => !value || ruleParams.isPasswordValid,
  phone: (value) => {
    return !value || isPossiblePhoneNumber(value)
  },
  website: (value) => {
    !value || checkUrl(value)
  },
}

const checkField = (value, rules, onError, data) => {
  for (let ruleIndex = 0; ruleIndex < rules?.length; ruleIndex += 1) {
    const ruleItem = rules[ruleIndex]
    const ruleName = ruleItem.type || ruleItem
    const ruleParams = ruleItem.type ? ruleItem : {}
    const customRuleName = ruleItem.customRuleName

    if (!validators[ruleName]) {
      throw new Error(`FormValidationRules: Invalid validator: ${ruleName}`)
    }
    if (!validators[ruleName](value, ruleParams, data)) {
      onError(customRuleName || ruleName)
      break
    } else if (data?.ruleWithSuccess && data?.ruleWithSuccess?.type === ruleName) {
      onError('success')
      break
    }
  }
}

// per field validation
export const validateOneField = (fieldName, rules) => (value, data) => {
  const error = {}
  checkField(
    value,
    rules[fieldName],
    (ruleName) => {
      error[fieldName] = ruleName
    },
    data
  )

  return error
}

const validate = (allRules) => (values, data) => {
  const errors = {}
  Object.keys(allRules).forEach((field) => {
    if (!allRules[field]) {
      return
    }

    const rules = allRules[field].filter((rule) => !rule.withSuccess)
    checkField(
      dot.pick(field, values),
      rules,
      (ruleName) => {
        errors[field] = ruleName
      },
      data
    )
  })

  return errors
}

export default validate
