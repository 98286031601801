import styled from 'styled-components'

export const StyledPayments = styled.div`
  padding-left: 20px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  gap: 10px;
  &.datePickerContainer {
    padding: 10px 20px 0;
  }
  .react-datepicker__input-container input {
    width: 212px;
    border: 1px solid ${({ theme }) => theme.color.general.light};
    border-radius: 3px;
  }
  .isClearable .react-datepicker__input-container input {
    width: 220px;
  }
  .inputContainer {
    margin: 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    justify-content: center;
    &.datePickerContainer {
      padding: 20px 20px 0;
    }
    &.filterActions {
      margin-top: 20px;
      flex-wrap: wrap;
    }
  }
`
export const StyledDetails = styled.div`
  padding: 20px;
  .nameCell {
    width: 45%;
  }
  .valueCell {
    width: 55%;
    .body2 {
      display: block;
    }
  }
`
