import React from 'react'
import { PropTypes as T } from 'prop-types'

import Icon from '../Icon'
import { StyledLoader } from './styled'
import clsx from 'clsx'

const Loader = ({ left, size, top, withOverlay }) => {
  return (
    <StyledLoader
      top={top}
      left={left}
      withOverlay={withOverlay}
      size={size}
      className={clsx('loader', withOverlay && 'withOverlay')}
    >
      <Icon name="logo" size={size ? +size?.replace('px', '') : undefined} />
    </StyledLoader>
  )
}

export default Loader

Loader.propTypes = {
  left: T.string,
  top: T.string,
  size: T.string,
  withOverlay: T.bool,
}
