import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { withTheme } from 'styled-components'
import { PropTypes as T } from 'prop-types'

import { Icon, Typography } from '../../atomic-design-components'

import { StyledDropButton, StyledDropZone } from './styled'
import { popupAlertShow } from '../../redux-saga/actions'

const DropZone = (props) => {
  const {
    className,
    currentFiles,
    fileType,
    isSimpleButton,
    maxFiles,
    maxSize,
    setFiles,
    theme: { color },
    type,
    noClick,
    setIsDropZoneActive,
  } = props
  const { t } = useTranslation('all')
  const dispatch = useDispatch()

  const getNewFiles = (newFiles) => {
    setFiles(
      newFiles.map((file) =>
        Object.assign(file.file, {
          preview: URL.createObjectURL(file.file),
        })
      )
    )

    dispatch(
      popupAlertShow({
        contentKey: 'only10Files',
        type: 'error',
        timeout: 10000,
        withCloseButton: true,
        iconName: 'statusDeleted',
      })
    )
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({
      accept: fileType,
      noClick: noClick,
      onDropRejected: (fileRejections) => {
        if (!acceptedFiles.length && fileRejections.length > maxFiles) {
          const newFiles = fileRejections.slice(0, maxFiles)
          getNewFiles(newFiles)
        }

        const maxNewFiles = maxFiles - acceptedFiles.length
        if (acceptedFiles.length && fileRejections.length > maxNewFiles) {
          const newFiles = fileRejections.slice(0, maxNewFiles)
          getNewFiles(newFiles)
        }

        fileRejections.map((file) => {
          if (file.errors[0].code === 'file-too-large') {
            dispatch(
              popupAlertShow({
                contentKey: 'file-too-large',
                contentParams: { fileName: file.file.name },
                type: 'error',
                timeout: 10000,
                withCloseButton: true,
                iconName: 'statusDeleted',
              })
            )
          }
        })
      },
      onDrop: (acceptedFiles) => {
        if (!acceptedFiles?.length) {
          return
        }

        const maxNewFiles = maxFiles - (currentFiles?.length || 0)
        if (acceptedFiles.length > maxNewFiles) {
          acceptedFiles.splice(maxNewFiles)

          dispatch(
            popupAlertShow({
              contentKey: 'only10Files',
              type: 'error',
              timeout: 10000,
              withCloseButton: true,
              iconName: 'statusDeleted',
            })
          )
        }

        const newFiles = currentFiles
          ? acceptedFiles.reduce((prev, curr) => {
            if (currentFiles.findIndex((currFile) => currFile.name === curr.name) === -1) {
              return [...prev, curr]
            } else {
              return prev
            }
          }, [])
          : acceptedFiles

        setFiles(
          newFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      },
      maxFiles,
      maxSize,
    })

  useEffect(() => {
    if (setIsDropZoneActive) {
      setIsDropZoneActive(isDragActive)
    }
  }, [isDragActive])

  if (isSimpleButton) {
    return (
      <StyledDropButton {...getRootProps({ isDragActive, isDragAccept, isDragReject })} maxSize={maxSize} className={className}>
        <input {...getInputProps()} id={type} />
      </StyledDropButton>
    )
  }

  return (
    <StyledDropZone {...getRootProps({ isDragActive, isDragAccept, isDragReject })} maxSize={maxSize} className={className}>
      <input {...getInputProps()} id={type} />
      <Icon name="upload" />
      <Typography variant="input" textAlign="center" color={color.general.light}>
        {t('uploadAndImage')}
      </Typography>
    </StyledDropZone>
  )
}

export default withTheme(DropZone)

DropZone.propTypes = { noClick: T.bool }
DropZone.defaultTypes = { noClick: false }
