import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'

import { useMappedState } from '../../../hooks/useReactRedux'
import {
  selectCurrentChatInfo,
  selectMainData,
  selectUserMemberId,
  selectUserName,
} from '../../../redux-saga/selectors'
import { getTableData, setEventToChatHistory, setEventJustSent } from '../../../redux-saga/actions'
import NameWithAvatar from '../../../components/NameWithAvatar'
import { ExpansionPanel, Typography, Checkbox, Button } from '../../../atomic-design-components'
import { StyledPanel } from './styled'
import theme from '../../../theme'
import { useSendEventWebsocket } from '../useChatsWebsockets'

const ChatForward = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['all', 'menu'])

  const members = useMappedState(selectMainData('members')) || []
  const departments = useMappedState(selectMainData('departments')) || []
  const currentMemberId = useMappedState(selectUserMemberId)
  const currentUserName = useMappedState(selectUserName)
  const chatInfo = useMappedState(selectCurrentChatInfo)

  const { chatId, action } = useParams()

  const iconLeftProps = { name: 'mailSend' }

  const isForwardForbidden = chatInfo.status !== 'open'

  useEffect(() => {
    dispatch(
      getTableData({
        type: 'members',
        status: 'active',
        queryParams: [{ key: 'id__ne', value: currentMemberId }],
        isSystemData: true,
      })
    )
    dispatch(
      getTableData({
        type: 'departments',
        status: 'active',
        isSystemData: true,
      })
    )
  }, [])

  const [radioChecked, setRadioChecked] = useState('')
  const [checkedType, setCheckedType] = useState('')

  const constructForwardData = () => {
    if (!radioChecked) return

    const mainData = {
      chat_id: +chatId,
      type: checkedType,
      data: { from_operator_id: currentMemberId, from_operator_name: currentUserName },
    }

    if (checkedType === 'forwarded_operator') {
      const name = members.find((item) => item.id === +radioChecked).full_name

      mainData.data.to_operator_id = +radioChecked
      mainData.data.to_operator_name = name
    } else if (checkedType === 'forwarded_department') {
      const name = departments.find((item) => item.id === +radioChecked).name

      mainData.data.to_department_id = +radioChecked
      mainData.data.to_department_name = name
    }

    return mainData
  }

  const onSubmit = (callback) => {
    sendEventToServer(constructForwardData())
    callback()
  }

  const onSubmitSuccess = (eventType, callback) => {
    if (eventType === 'forwarded_operator' || eventType === 'forwarded_department') {
      setRadioChecked('')
      setCheckedType('')
      dispatch(setEventToChatHistory(eventType, constructForwardData()))
      callback()
      dispatch(setEventJustSent(eventType))
    }
  }

  const { sendEventToServer, onSendEvent } = useSendEventWebsocket(onSubmit, onSubmitSuccess)

  if (action !== 'forward') {
    return null
  }

  const onChange = (type) => (e) => {
    setRadioChecked(e.target.value)
    setCheckedType(type)
  }

  return (
    <StyledPanel className={isForwardForbidden ? 'disabled' : undefined}>
      {members?.length > 0 && (
        <ExpansionPanel header={<Typography text={t('menu:members')} variant="h4" />} initialOpened={true}>
          <div onChange={onChange('forwarded_operator')}>
            {members.map((member) => {
              const checked = +radioChecked === member.id && checkedType === 'forwarded_operator'
              const departments = member.departments.map((dep) => dep.name).join(', ')
              const isOptionForbidden = member.id === chatInfo.chat_data?.to_operator_id

              return (
                <Checkbox
                  key={member.id}
                  type="radio"
                  checked={checked}
                  value={`${member.id}`}
                  name="forwardTo"
                  className={clsx(checked && 'checked', 'memberRadio')}
                  checkboxColor={theme.color.status.new}
                  fill={theme.color.general.white}
                  disabled={isForwardForbidden || isOptionForbidden}
                >
                  <NameWithAvatar
                    avatarSrc={member.profile_picture}
                    avatarSize={26}
                    name={member.full_name}
                    typographyVariant="button1"
                    iconWidth={10}
                    iconHeight={10}
                    underText={departments}
                    className="checkboxLabel"
                  />
                </Checkbox>
              )
            })}
          </div>
        </ExpansionPanel>
      )}

      {departments?.length > 0 && (
        <ExpansionPanel header={<Typography text={t('departments')} variant="h4" />} initialOpened={true}>
          <div onChange={onChange('forwarded_department')}>
            {departments.map((department) => {
              const checked = +radioChecked === department.id && checkedType === 'forwarded_department'
              const isOptionForbidden = department.id === chatInfo.chat_data?.to_department_id

              return (
                <Checkbox
                  key={department.id}
                  type="radio"
                  checked={checked}
                  value={`${department.id}`}
                  label={department.name}
                  labelType="button1"
                  name="forwardTo"
                  className={clsx(checked && 'checked')}
                  checkboxColor={theme.color.status.new}
                  fill={theme.color.general.white}
                  disabled={isForwardForbidden || isOptionForbidden}
                />
              )
            })}
          </div>
        </ExpansionPanel>
      )}

      <Button
        disabled={isForwardForbidden || !radioChecked}
        fullWidth
        iconLeftProps={iconLeftProps}
        onClick={onSendEvent(checkedType)}
        text={t('forward')}
      />
    </StyledPanel>
  )
}

export default ChatForward
