import { useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useUnmount = (cb) => {
  useEffect(() => {
    return cb
  }, [])
}

export const useMappedState = (selectorObj) => useSelector(selectorObj, shallowEqual)
