export default function transformTime(value, fromSeconds) {
  if (!value && value === undefined) return
  let transformedTime
  if (fromSeconds) {
    if (isNaN(value)) return value
    transformedTime = new Date(value * 1000).toISOString().slice(14, 19)
  } else {
    const [minutes, seconds] = value.split(':')
    transformedTime = Number(minutes) * 60 + Number(seconds)
  }
  return transformedTime
}
