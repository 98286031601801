import { parse } from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { FlexRow, Icon, Typography } from '../../../atomic-design-components'
import EmptyScreen from '../../../components/EmptyScreen'
import { useMappedState } from '../../../hooks/useReactRedux'
import { getChatDetails } from '../../../redux-saga/actions'
import {
  selectCurrentChatChannel,
  selectCurrentChatHistory,
  selectCurrentChatId,
  selectEventJustSent,
  selectFoundMessagesIds,
  selectIsContactOnline,
} from '../../../redux-saga/selectors'
import ChatArea from './ChatArea'
import ChatNavbar from './ChatNavbar'

const ChatAreaWithSearch = ({
  currentChatStatus,
  eventJustReceived,
  setEventJustReceived,
  searchButtonClicked,
  t,
  theme,
}) => {
  const dispatch = useDispatch()

  const foundMessagesIds = useMappedState(selectFoundMessagesIds)
  const { chatId: chatIdUrlParam } = useParams()
  const chatId = useMappedState(selectCurrentChatId)

  const isContactOnline = useMappedState(selectIsContactOnline())
  const eventJustSent = useMappedState(selectEventJustSent)
  const currentChatChannel = useMappedState(selectCurrentChatChannel)

  const gridRef = useRef(false)

  const { search } = useLocation()
  const searchParsed = parse(search)
  const leftPanelSearchValue = searchParsed.searchValue ?? ''

  const [searchValue, setSearchValue] = useState('')
  const [searchValueCount, setSearchValueCount] = useState(null)

  const { chatHistoryCount, inProgressHistory } = useMappedState(selectCurrentChatHistory)

  const showOfflineText =
    currentChatChannel === 'live_chat' && chatId && currentChatStatus !== 'closed' && !isContactOnline

  useEffect(() => {
    if (eventJustSent) {
      setSearchValueCount(0)
    }
  }, [eventJustSent])

  useEffect(() => {
    if (chatIdUrlParam) {
      dispatch(getChatDetails({ chatId: chatIdUrlParam }))
    }
    setSearchValue(leftPanelSearchValue || '')
    setSearchValueCount(leftPanelSearchValue ? 0 : null)
  }, [chatIdUrlParam])

  if (!chatId) {
    return <EmptyScreen type="chatsSelected" icon="noChats" />
  }

  if (!chatHistoryCount || inProgressHistory) {
    return null
  }

  return (
    <>
      <ChatNavbar
        currentChatStatus={currentChatStatus}
        searchButtonClicked={searchButtonClicked}
        foundMessagesIds={foundMessagesIds}
        searchValueCount={searchValueCount}
        setSearchValueCount={setSearchValueCount}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        t={t}
        theme={theme}
      />
      <ChatArea
        gridRef={gridRef}
        eventJustReceived={eventJustReceived}
        setEventJustReceived={setEventJustReceived}
        foundMessagesIds={foundMessagesIds}
        searchValueCount={searchValueCount}
        setSearchValueCount={setSearchValueCount}
        searchValue={searchValue}
        t={t}
        theme={theme}
      />
      {showOfflineText && (
        <FlexRow justifyContent="center" className="offlineText">
          <Icon margin="0 3px 0 0" height={12} width={14} name="offline" />
          <Typography color={theme.color.general.dark} margin="10px 0" variant="input" textAlign="center">
            {t('guestOfflineText')}
          </Typography>
        </FlexRow>
      )}
    </>
  )
}

export default ChatAreaWithSearch
