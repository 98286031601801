import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../../constants'

export const StyledItemContent = styled.div`
  padding: 10px 15px;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  .typography {
    max-width: 100px;
  }

  &.newChat {
    background-color: ${({ theme }) => theme.color.secondary.lightest};
    color: ${({ theme }) => theme.color.secondary.darker};
  }
  &.openChat {
    background-color: ${({ theme }) => theme.color.general.lightest};
  }
  &.closedChat {
    border: 1px solid ${({ theme }) => theme.color.general.lighter};
    .icon circle {
      fill: ${({ theme }) => theme.color.general.light};
    }
  }
  &.active {
    background-color: ${({ theme }) => theme.color.status.new};

    .typography {
      color: ${({ theme }) => theme.color.general.white};
    }

    .icon {
      box-shadow: 0 0 5px white;
      border-radius: 50%;

      &.attachIcon {
        box-shadow: none;
        path {
          stroke: ${({ theme }) => theme.color.general.white};
        }
      }
      &.offline {
        background-color: ${({ theme }) => theme.color.general.white};
        box-shadow: none;
        path {
          fill: ${({ theme }) => theme.color.status.new};
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .icon {
    margin-left: 5px;
  }

  .userMessage {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .newMessagesBadge {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 12px;
    height: 12px;
    background-color: white;
    transform-origin: 100% 0%;
    transform: scale(1) translate(50%, -50%);
    border-radius: 50%;
    span {
      min-width: 10px;
      height: 10px;
      padding: 0;
      transform-origin: inherit;
      transform: none;
    }
  }
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  .itemsCountBadge {
    margin-left: 5px;
  }
`

export const StyledPanel = styled.div`
  padding: 20px;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;

  &.disabled {
    .panelHeader {
      opacity: 0.5;
    }
  }

  .expansionPanel:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
  }
  .expansionPanel:not(:first-child) {
    padding-top: 20px;
  }

  .radio {
    padding: 5px 10px;
    border-radius: 3px;
    margin-bottom: 2px;
    gap: 6px;

    input {
      width: 220px;
      height: 30px;
    }

    .typography,
    .label {
      margin: 0;
    }

    .userpic {
      padding: 4px 0;
    }

    &:not(.memberRadio) {
      padding: 10px;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .radio:last-child {
    margin-bottom: 20px;
  }

  .memberRadio:nth-child(odd) {
    background-color: ${({ theme }) => theme.color.general.lightest};
  }

  .radio.checked {
    background-color: ${({ theme }) => theme.color.status.new} !important;
    color: ${({ theme }) => theme.color.general.white};

    .label {
      color: ${({ theme }) => theme.color.general.white};
    }
  }

  .panelHeader {
    margin-bottom: 10px;
  }

  .checkboxLabel {
    margin-left: 6px;
    overflow: hidden;
    height: 34px;
  }
`

export const StyledClosedChatsWrapper = styled.div`
  height: ${({ itemCount }) => `calc(${itemCount} * 65px)`};
  max-height: calc(100vh - 80px);
  // height: calc(100vh - 80px);

  .chatAreaBottomLine {
    height: 1px;
    width: 100%;
  }
`
