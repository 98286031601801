import React, { useCallback } from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'
import { withTheme } from 'styled-components'
import { pick } from 'dot-object'
import { Link as RouterLink, NavLink } from 'react-router-dom'

import { StyledLink } from './styled'
import Icon from '../Icon'

const Link = ({
  className,
  children,
  disabled,
  href,
  inverted,
  iconName,
  iconPosition,
  iconProps = {},
  isNavLink,
  isOuterLink,
  noStyles,
  onClick,
  rel,
  target,
  text,
  textTransform,
  theme,
  themeColor,
  to,
  withBorder,
  ...otherProps
}) => {
  const getIcon = useCallback(
    () => (
      <Icon
        name={iconName}
        className="icon"
        fill={inverted ? theme.color.general.white : pick(themeColor, theme.color)}
        {...iconProps}
      />
    ),
    [inverted, iconName, iconProps, theme, themeColor]
  )

  return (
    <StyledLink
      themeColor={themeColor}
      inverted={inverted}
      $textTransform={textTransform}
      className={clsx(
        className,
        disabled && 'disabled',
        noStyles && 'noStyles',
        withBorder && 'withBorder',
        'link'
      )}
      onClick={disabled ? undefined : onClick}
      {...otherProps}
    >
      {iconPosition === 'left' && getIcon()}

      {isOuterLink ? (
        <a href={href} target={target || '_blank'} rel={rel || 'noreferrer noopener'}>
          {children || text}
        </a>
      ) : (
        <RouterLink as={isNavLink && NavLink} target={target} rel={rel} to={to}>
          {children || text}
        </RouterLink>
      )}
      {iconPosition === 'right' && getIcon()}
    </StyledLink>
  )
}

export default withTheme(Link)

Link.propTypes = {
  children: T.node,
  className: T.string,
  disabled: T.bool,
  href: T.string,
  inverted: T.bool,
  iconName: T.string,
  iconPosition: T.string,
  isNavLink: T.bool,
  isOuterLink: T.bool,
  noStyles: T.bool,
  onClick: T.func,
  rel: T.string,
  target: T.string,
  text: T.oneOfType([T.string, T.object]),
  theme: T.object,
  themeColor: T.oneOf([
    'primary.main',
    'secondary.main',
    'general.black',
    'general.light',
    'general.dark',
    'general.darker',
    'general.white',
    'status.new',
    'status.error',
  ]),
  to: T.string,
  withBorder: T.bool,
}

Link.defaultProps = {
  themeColor: 'secondary.main',
}
