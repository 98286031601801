import React, { useEffect } from 'react'

import TableSearch from '../../../atomic-design-components/organisms/Table/components/TableSearch'
import { StyledContainer } from '../components/styled'
import theme from '../../../theme'
import { useTranslation } from 'react-i18next'
import { getTableData } from '../../../redux-saga/actions'
import { useDispatch } from 'react-redux'
import { SEARCH_VALUE_MIN_LENGTH } from '../../../constants'

const TemplatesInChatsSearch = ({ ...otherProps }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')

  const [searchValue, setSearchValue] = React.useState('')
  const isSearchActive = searchValue.length >= SEARCH_VALUE_MIN_LENGTH

  useEffect(() => {
    if (isSearchActive) {
      dispatch(
        getTableData({
          type: 'templates',
          status: 'active',
          search: `?searchValue=${searchValue}`,
          searchFields: 'name|body',
        })
      )
    }

    if (!searchValue || searchValue.length < SEARCH_VALUE_MIN_LENGTH) {
      dispatch(getTableData({ type: 'templates', status: 'active' }))
    }
  }, [searchValue])

  const iconLeftProps = { name: 'searchSign', fill: theme.color.general.dark }
  const iconRightProps = { name: 'cross', size: 16 }

  return (
    <StyledContainer className="searchContainer">
      <TableSearch
        iconLeftProps={iconLeftProps}
        iconRightProps={iconRightProps}
        placeholder={t('search')}
        placeholderColor={theme.color.general.light}
        className="searchInput"
        withoutBorder
        withoutValidation
        searchValueState={searchValue}
        setSearchValue={setSearchValue}
      />
    </StyledContainer>
  )
}

export default TemplatesInChatsSearch
