import React from 'react'

export const checkboxUnchecked = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
    <g filter="url(#filter0_i_6934_34493)">
      <rect width={width || 16} height={height || 16} rx="3" fill={fill || color.general.lightest} />
      <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" stroke={color.general.light} />
    </g>
    <defs>
      <filter id="filter0_i_6934_34493" x="0" y="0" width="16" height="17" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6934_34493" />
      </filter>
    </defs>
  </svg>
)
