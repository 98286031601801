import React from 'react'

export const eyeSignCross = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
    <path
      d="M14.0458 6.1709C14.4801 6.55947 14.8458 6.93661 15.1201 7.23376C15.3096 7.44376 15.4146 7.71659 15.4146 7.99947C15.4146 8.28235 15.3096 8.55518 15.1201 8.76518C13.9201 10.0566 11.1887 12.5709 8.00011 12.5709H7.54297"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.42275 11.5763C3.0989 10.8349 1.90272 9.88564 0.879894 8.76488C0.690362 8.55488 0.585449 8.28205 0.585449 7.99916C0.585449 7.71628 0.690362 7.44345 0.879894 7.23345C2.07989 5.94202 4.81132 3.42773 7.99989 3.42773C9.25678 3.454 10.4869 3.79592 11.577 4.42202"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2858 1.71387L1.71436 14.2853"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.38864 9.61101C5.9594 9.18432 5.71691 8.60481 5.71436 7.99958C5.71436 7.39337 5.95517 6.81199 6.38383 6.38334C6.81248 5.95468 7.39386 5.71387 8.00007 5.71387C8.6053 5.71642 9.18481 5.95891 9.6115 6.38815"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.98829 9.14258C9.78485 9.49035 9.49309 9.77817 9.14258 9.97686"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
