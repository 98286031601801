import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { PropTypes as T } from 'prop-types'
import { withTheme } from 'styled-components'

import { StyledSwitch, StyledSwitchLabel } from './styled'
import Typography from '../../atoms/Typography'

const Switch = ({
  initialChecked,
  checked = false,
  disabled,
  height,
  gap,
  id,
  width,
  handleDiameter,
  justifyContent,
  label,
  labelColor,
  leftLabel,
  margin,
  name,
  onChange,
  readOnly,
  theme,
  className,
  textVariant,
  revert,
  ...props
}) => {
  const [stateChecked, setStateChecked] = useState(checked || false)

  const onClick = () => {
    setStateChecked((prev) => !prev)
    onChange && onChange(!stateChecked, { target: { name } })
  }

  useEffect(() => {
    setStateChecked(checked)
  }, [checked])

  return (
    <StyledSwitchLabel
      htmlFor={id || 'switch'}
      margin={margin}
      className={clsx(className, disabled && 'disabled', revert && 'revert')}
      justifyContent={justifyContent}
      gap={gap}
    >
      {leftLabel && <Typography type="body2" text={leftLabel} variant={textVariant} color={labelColor} />}
      <StyledSwitch
        name={name}
        checked={stateChecked}
        onChange={onClick}
        disabled={disabled}
        readOnly={readOnly}
        uncheckedIcon={false}
        checkedIcon={false}
        offColor={theme.color.general.lighter}
        onColor={theme.color.secondary.main}
        offHandleColor={theme.color.general.white}
        onHandleColor={theme.color.general.white}
        $borderColor={stateChecked ? theme.color.secondary.dark : `${theme.color.general.darkest}40`}
        handleDiameter={handleDiameter || 20}
        height={height || 24}
        width={width || 40}
        className="switch"
        id={`${id} switch`}
        {...props}
      />
      {label && <Typography type="body2" text={label} variant={textVariant} color={labelColor} />}
    </StyledSwitchLabel>
  )
}

export default withTheme(Switch)

Switch.propTypes = {
  checked: T.bool,
  disabled: T.bool,
  label: T.string,
  margin: T.string,
  onChange: T.func,
  readOnly: T.bool,
  theme: T.object,
}

Switch.defaultProps = {
  checked: false,
}
