import React from 'react'

import { Outlet } from 'react-router-dom'
import Settings from '../Settings'

const SettingsInvoices = () => {
  return <Settings RightPanelComponent={<Outlet />}>SettingsInvoices</Settings>
}

export default SettingsInvoices
