import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'

import { getChatsList } from '../../../redux-saga/actions'
import ChatsListItem from './ChatsListItem'
import { VirtualizedList } from '../../../atomic-design-components'
import { StyledClosedChatsWrapper } from './styled'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectChatsClosedInProgress } from '../../../redux-saga/selectors'

const ChatsListClosed = ({ chatsData, closedCount, t }) => {
  const dispatch = useDispatch()
  const gridRef = useRef(null)
  const containerRef = useRef(null)

  const { search } = useLocation()
  const searchParsed = parse(search)
  const searchValue = searchParsed.searchValue ?? ''

  const inProgress = useMappedState(selectChatsClosedInProgress)

  const itemCount = chatsData?.length || 0
  const limit = 5

  return (
    <StyledClosedChatsWrapper ref={containerRef} itemCount={itemCount}>
      <VirtualizedList
        columnCount={1}
        gridRef={gridRef}
        initialPageNumber={1}
        items={chatsData}
        itemsTotalCount={closedCount}
        hasMore={chatsData?.length && chatsData.length < closedCount}
        inProgress={inProgress}
        limit={limit}
        loadNextPage={() => {
          dispatch(
            getChatsList(
              'closed',
              [
                { key: 'offset', value: itemCount },
                { key: 'limit', value: limit },
              ],
              true,
              searchValue
            )
          )
        }}
        rowHeight={45}
        CustomListItem={ChatsListItem}
        customListItemProps={{ type: 'closed', t }}
        t={t}
      />
    </StyledClosedChatsWrapper>
  )
}

export default ChatsListClosed

// if (isClosedChat && chatsData?.length - 1 === i) {
//     return (
//         <ChatsListItem ref={lastElementRef} chatData={chat} key={chat.contact_id} type={type} t={t} />
//     )
// } else {
