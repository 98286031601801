import React from 'react'

import { StyledWidgetStartHeader, StyledWidgetTitle } from '../styled'
import { Icon, Typography } from '../../../../../atomic-design-components'
import { withTheme } from 'styled-components'

const WidgetStartHeader = ({
  currentScreen,
  color,
  hideWidget,
  goOneScreenBack,
  getT,
  subtextKey,
  theme,
}) => {
  return (
    <StyledWidgetStartHeader color={color}>
      <StyledWidgetTitle>
        {currentScreen !== 'chooseChannels' && (
          <Icon
            name="arrowLeft"
            className="arrowLeft"
            onClick={goOneScreenBack ? () => goOneScreenBack() : undefined}
          />
        )}
        <div>
          <Typography variant="h3">{getT('welcome')}</Typography>
          {subtextKey && (
            <Typography className="subtext" variant="button1">
              {getT(subtextKey)}
            </Typography>
          )}
        </div>
      </StyledWidgetTitle>

      <Icon
        onClick={hideWidget ? () => hideWidget() : undefined}
        name="chevronDownThin"
        fill={theme.color.general.white}
        className="closeIcon"
      />
    </StyledWidgetStartHeader>
  )
}

export default withTheme(WidgetStartHeader)
