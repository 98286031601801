import theme from './theme'
// import { clientTz, findTzByName } from 'timezone-select-js'
import { clientTz, findTzByName } from './timezoneSelect/tz.helper'

export const CURRENCY = { UAH: '₴', USD: '$', EUR: '€' }

export const SEARCH_VALUE_MIN_LENGTH = 2
export const MAX_SEARCH_INPUT_LENGTH = 255

export const MAX_BACKEND_LIMIT = 100

export const NAV_MENU_WIDTH = {
  desktop: { extended: '260px', condensed: '64px' },
  mobile: { extended: '100%', condensed: '100%' },
}

export const NAV_MENU_COLORS = {
  system_administrator: {
    dark: theme.color.secondary.darker,
    light: theme.color.secondary.lightest,
    lighter: theme.color.secondary.lighter,
  },
  customer_administrator: {
    dark: theme.color.tertiary.dark,
    light: theme.color.tertiary.lightest,
    lighter: `${theme.color.status.success}40`,
  },
  customer_owner: {
    dark: theme.color.tertiary.dark,
    light: theme.color.tertiary.lightest,
    lighter: `${theme.color.status.success}40`,
  },
  operator: {
    dark: theme.color.general.darker,
    light: theme.color.general.lighter,
    lighter: `${theme.color.general.dark}26`,
  },
}

export const WIDGET_COLORS = {
  orange: '#FF5F00',
  classic: '#0057B7',
  gold: '#FAB711',
  coral: '#FF6F61',
  cherry: '#BB2649',
  violet: '#5F4B8B',
  grass: '#88B04B',
  turquoise: '#367E7D',
  corporate: '#0F4C81',
}

export const HEADER_HEIGHT = 64

export const STATUSES_ICONS = {
  pending: 'statusPending',
  active: 'statusActive',
  inactive: 'statusDeleted',
}

export const CUSTOMERS_ADDITIONAL_STATUS_ICON = {
  trial: 'statusTrial',
}

export const PAYMENTS_STATUSES_ICONS = {
  pending: 'statusPending',
  inProcessing: 'statusPending',
  approved: 'statusActive',
  declined: 'statusDeleted',
  expired: 'statusDeleted',
}

export const BULK_MESSAGE_STATUSES_ICONS = {
  draft: 'statusPending',
  in_progress: 'statusInProgress',
  scheduled: 'calendar2',
  complete: 'statusActive',
  complete_with_error: 'statusActiveWithError',
}

export const ALL_STATUSES_ICONS = {
  ...STATUSES_ICONS,
  ...CUSTOMERS_ADDITIONAL_STATUS_ICON,
}

export const SEARCH_FIELDS = {
  members: 'full_name|email|role',
  contacts: 'full_name|email|id',
  customers: 'company_name|alias',
  departments: 'name',
  bulkMessaging: 'name',
  templates: 'name|body',
  subscriptionPlans: 'titles',
}

export const MESSENGERS_INFO = {
  telegram: {
    presetPart: 'https://t.me/',
  },
  viber: {
    presetPart: '',
  },
  instagram: {
    presetPart: 'https://www.instagram.com/',
  },
  facebook: {
    presetPart: 'https://m.me/',
  },
  website_url: {
    presetPart: 'https://',
  },
}

export const ROUTES = {
  dashboard: '/dashboard',
  customers: '/customers',
  confirmEmail: '/confirm-email',
  contacts: '/contacts',
  bulkMessaging: '/bulk-messaging',
  departments: '/departments',
  invite: '/invite',
  invoices: '/settings/invoices',
  members: '/members',
  new: '/new',
  newPassword: '/new-password',
  passwordRecovery: '/password-recovery',
  passwordRequestSent: 'password-request-sent',
  privacyPolicy: '/privacy-policy',
  signIn: '/sign-in',
  signUp: '/sign-up',
  settings: '/settings',
  termsAndConditions: '/terms-and-conditions',
  // notFound: '/not-found',
}

export const ROLES = {
  system_administrator: true,
  customer_administrator: true,
  customer_owner: true,
  operator: true,
}

export const ROUTES_FORBIDDEN = {
  operator: ['/my-company', '/members', '/contacts', '/customers', '/payments', '/bulk-messaging'],
  customer_administrator: [
    '/customers',
    '/payments',
    '/my-company/subscription',
    '/my-company/delete-company',
  ],
  customer_owner: ['/customers'],
  system_administrator: [],
}

const CHAT_ACTION_TYPES = [
  'chat_started',
  'chat_closed',
  'forwarded_operator',
  'forwarded_department',
  'operator_joined',
  'operator_rated',
]

export const CHANNELS = ['live_chat', 'telegram', 'viber', 'instagram', 'facebook']
export const MESSENGERS = [
  { id: 'telegram', label: 'telegram' },
  { id: 'viber', label: 'viber' },
  { id: 'instagram', label: 'instagram' },
  { id: 'facebook', label: 'facebook' },
]

export const EVENT_TYPE_TO_CHAT_STATUS = {
  chat_started: 'new',
  chat_closed: 'closed',
  forwarded_operator: '',
  forwarded_department: '',
  operator_joined: 'open',
  operator_rated: 'closed',
  contact_message: '',
  operator_message: '',
  system_message: '',
}
// chat_started => NEW
// chat_closed => CLOSED
// forwarded_operator, forwarded_department => FORWARDED
// operator_joined => OPENED

export const FILE_TYPES = {
  images: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
  files: {
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'image/heic': ['.heic'],
  },
}
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

export const NO_ACTIVE_CHANNELS_WARNING_ID = 'noActiveChannelsWarning'

export const INDUSTRIES = [
  'general',
  'ecommerce',
  'logistics',
  'finance',
  'education',
  'travel',
  'real_estate',
  'construction',
  'it',
  'medical',
]

export const DEFAULT_WORK_HOURS = {
  timezone_offset: findTzByName(clientTz()).offset,
  timezone_region: findTzByName(clientTz()).value,
  work_hours: {
    monday: { is_open: true, start: '00:00', close: '23:59' },
    tuesday: { is_open: true, start: '00:00', close: '23:59' },
    wednesday: { is_open: true, start: '00:00', close: '23:59' },
    thursday: { is_open: true, start: '00:00', close: '23:59' },
    friday: { is_open: true, start: '00:00', close: '23:59' },
    saturday: { is_open: true, start: '00:00', close: '23:59' },
    sunday: { is_open: true, start: '00:00', close: '23:59' },
  },
}

export const DEFAULT_WIDGET_SETTINGS = {
  color: theme.color.primary.main,
  is_hide_branding: false,
  button_style: 'typeSkibble',
  is_form_displayed: false,
  form_fields: {
    name: { is_visible: false, is_required: false },
    email: { is_visible: false, is_required: false },
    phone: { is_visible: false, is_required: false },
  },
  departments: {
    is_selected: false,
    values: [],
  },
  privacy_policy: {
    is_required: false,
    document_url: '',
  },
}

export const WIDGET_BUTTON_ICONS = [
  'typeSkibble',
  'typeBubbles',
  'typeBubble',
  'typeBriefcase',
  'typeQuestion',
]

export const PLAN_OPTIONS = [
  {
    id: 1,
    titles: [
      {
        lang: 'en',
        title: 'Free',
      },
    ],
    status: 'active',
    prices: [{ currency: 'UAH', price_monthly: 0, price_annualy: 0 }],
    features: {
      users: 1,
      widgets: 1,
      templates: 1,
      is_customization: false,
      is_branding: false,
      // is_support: false,
    },
  },
  {
    id: 2,
    titles: [
      {
        lang: 'en',
        title: 'Small Team',
      },
    ],
    status: 'active',
    prices: [{ currency: 'UAH', price_monthly: 300, price_annualy: 3000 }],
    features: {
      users: 3,
      widgets: 1,
      templates: 200,
      is_customization: true,
      is_branding: true,
      // is_support: false,
    },
  },
  {
    id: 3,
    titles: [
      {
        lang: 'en',
        title: 'Business',
      },
    ],
    status: 'active',
    prices: [{ currency: 'UAH', price_monthly: 600, price_annualy: 6000 }],
    features: {
      users: 10,
      widgets: 5,
      templates: 500,
      is_customization: true,
      is_branding: true,
      // is_support: true,
    },
  },
]

export const BOTS_URLS_START = {
  telegram: 'https://t.me/',
  viber: 'viber://pa?chatURI=',
  // whatsapp: 'https://wa.me/',
  instagram: 'https://www.instagram.com/',
  facebook: 'https://m.me/',
}

export const SUPPORTED_CURRENCIES = ['UAH', 'USD', 'EUR']

export const COMPANY_NOTIFICATIONS = {
  no_response: {
    status: 'active',
    delay: 60,
    chat_owner: true,
    administrators: false,
    specified_emails: false,
    emails: [],
  },
  new_chat_working_hours: {
    status: 'inactive',
    delay: 60,
    operators: true,
    administrators: false,
    specified_emails: false,
    emails: [],
  },
  new_chat_non_working_hours: {
    status: 'inactive',
    operators: true,
    administrators: false,
    specified_emails: false,
    emails: [],
  },
}
