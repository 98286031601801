import React from 'react'

export const inputError = ({ width, height, fill, theme: { color } }) => {
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99998 0.571568C12.1027 0.571568 15.4286 3.89745 15.4286 8.00014C15.4286 12.1028 12.1027 15.4287 7.99998 15.4287C3.8973 15.4287 0.571411 12.1028 0.571411 8.00014C0.571411 3.89745 3.8973 0.571568 7.99998 0.571568Z"
        stroke={fill || color.status.error}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8V4"
        stroke={fill || color.status.error}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00002 10.6872C8.31561 10.6872 8.57145 10.9431 8.57145 11.2586C8.57145 11.5742 8.31561 11.8301 8.00002 11.8301C7.68443 11.8301 7.42859 11.5742 7.42859 11.2586C7.42859 10.9431 7.68443 10.6872 8.00002 10.6872Z"
        stroke={fill || color.status.error}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
