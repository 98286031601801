import React from 'react'

export const logo = ({ width, height, fill, theme: { color } }) => {
  return (
    <svg width={width || 26} height={height || 26} viewBox="0 0 26 26" fill="none">
      <path
        d="M0 4.17857C0 1.87081 1.87081 0 4.17857 0H21.8214C24.1292 0 26 1.87081 26 4.17857V21.8214C26 24.1292 24.1292 26 21.8214 26H4.17857C1.87081 26 0 24.1292 0 21.8214V4.17857Z"
        fill={fill || color.primary.main}
      />
      <g clipPath="url(#clip0_8583_3161)">
        <path
          d="M13.0001 22.8428C18.4362 22.8428 22.8429 18.4361 22.8429 13C22.8429 7.5639 18.4362 3.1571 13.0001 3.1571C7.56403 3.1571 3.15723 7.5639 3.15723 13C3.15723 18.4361 7.56403 22.8428 13.0001 22.8428Z"
          fill={color.general.white}
        />
        <path
          className="rotate-center"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0003 9.5175C18.5865 9.95835 19.4662 11.2789 19.5618 12.9098H17.8249C17.6791 12.2001 17.2279 11.6743 16.5491 11.4855C15.358 11.1544 14.6959 12.211 14.0831 13.1896C14.0506 13.242 14.0191 13.2934 13.9866 13.3448L13.7344 13.7332L13.6959 13.7925C12.9324 14.9727 11.5472 17.1127 8.9926 16.402C7.25865 15.9197 6.46951 14.5764 6.43799 12.9098H8.16503C8.26059 13.6245 8.64384 14.2116 9.44284 14.434C10.9098 14.8423 11.6792 13.6205 12.4221 12.4314L12.6881 11.9866C13.6181 10.5109 14.8792 8.9274 16.9993 9.5175H17.0003Z"
          fill={fill || color.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_8583_3161">
          <rect width="19.6857" height="19.6857" fill="white" transform="translate(3.15723 3.1571)" />
        </clipPath>
      </defs>
    </svg>
  )
}
