import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useMappedState } from '../../hooks/useReactRedux'
import ReactHelmet from '../../wrappers/ReactHelmet'

import { Icon, Typography } from '../../atomic-design-components'
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import backBlue from '../../assets/images/backBlue.jpg'
import { selectWholeUserState } from '../../redux-saga/selectors'

const RequestSent = ({ theme }) => {
  const { t } = useTranslation('auth')

  const user = useMappedState(selectWholeUserState)

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper type="requestSent" theme={theme} imageUrl={backBlue} text={t('confirmYourEmail')}>
        <Icon name="requestSent" margin="30px 0 0" />
        <div className="inscriptionWrapper">
          <Typography
            variant="body2"
            color={theme.color.general.black}
            margin="20px 0 0 0"
            className="inline"
            text={t('requestText')}
          />
          <Typography variant="body2" fontWeight={500} color={theme.color.primary.main} className="inline">
            &nbsp;{user?.details?.email || 'you@yourcompany.com'}
          </Typography>
          <Typography
            variant="body2"
            color={theme.color.general.black}
            margin="20px 0 0 0"
            className="inline"
            text={t('requestText1')}
          />
        </div>
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(RequestSent)
