import React from 'react'
import ReactTexty from 'react-texty'

import { FlexRow, Typography } from '../../../atomic-design-components'
import { StyledCardContent } from './styled'
// you should use "key" keys from columns config to get data here (not dataKeys): data[key]

const CustomersTableCard = ({ cellProps, index, onClick, data, columns, t, theme }) => {
  const periodEnd = data?.planEnds?.props?.cellData

  return (
    <StyledCardContent>
      <FlexRow className="cardTableItemRow statusRow">
        <FlexRow>
          <Typography as={ReactTexty} variant="caption1" text={data.companyName} />
        </FlexRow>
        <FlexRow justifyContent="end">
          <Typography as={ReactTexty} variant="caption1" text={data.plan} />
          {data.status}
        </FlexRow>
      </FlexRow>
      <FlexRow className="cardTableItemRow planRow">
        <Typography
          as={ReactTexty}
          variant="caption1"
          text={`${data.usersIncluded} ${t('usersIncluded')?.toLowerCase()}`}
        />
        <Typography variant="h3" text={data.pricing} />
      </FlexRow>
      <FlexRow className="cardTableItemRow actionRow">
        {data.action}
        <Typography
          variant="button1"
          text={`${t('exp')} ${periodEnd || ''}`}
          color={theme.color.status.success}
        />
      </FlexRow>
    </StyledCardContent>
  )
}

export default CustomersTableCard
