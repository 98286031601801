import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import clsx from 'clsx'
import parse from 'html-react-parser'

import { FlexRow, Tooltip, Typography, Icon } from '../../../atomic-design-components'
import { StyledItemContent } from './styled'
import theme from '../../../theme'
import { setChatMessage } from '../../../redux-saga/actions'
import { useDispatch } from 'react-redux'

const TemplatesListItem = ({ data: templateData, t, variant }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { templateId, chatId } = useParams()

  const isDisabled = templateData.status !== 'active'

  const onTemplateClick = () => {
    if (variant === 'chats') {
      dispatch(setChatMessage(templateData.body))
      document.getElementById(`chatTextarea-${chatId}`)?.focus()
    }

    if (variant === 'templates') {
      navigate({ pathname: `${templateData.id}`, search })
    }
  }

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  const templateName = templateData.name === 'Personal template' ? t('personalTemplate') : templateData.name

  return (
    <StyledItemContent
      className={clsx('template', +templateId === +templateData.id && 'active')}
      onClick={onTemplateClick}
    >
      <FlexRow justifyContent="space-between">
        <Typography variant="caption1" text={templateName} color={theme.color.general.black} />
        {isDisabled && (
          <Tooltip
            text="Template is inactive. Upgrade your plan to use it."
            right="-8px"
            arrowPosition="right"
            whiteSpace="pre-wrap"
            width="200px"
          >
            <Icon name="statusDeleted" padding="10px" />
          </Tooltip>
        )}
      </FlexRow>
      <FlexRow>
        <Typography
          variant="body2"
          text={parse(truncateString(templateData.body, 120))}
          color={theme.color.general.dark}
        />
      </FlexRow>
    </StyledItemContent>
  )
}

export default TemplatesListItem
