import React from 'react'

export const typeBubbles = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 36} height={height || 36} viewBox="0 0 36 36" fill="none">
    <path
      d="M5.90049 26.8007L1.7207 28.1207L3.75147 22.0284V3.75147C3.75147 3.21288 3.96543 2.69634 4.34627 2.3155C4.72711 1.93466 5.24365 1.7207 5.78224 1.7207H26.0899C26.6285 1.7207 27.1451 1.93466 27.5259 2.3155C27.9067 2.69634 28.1207 3.21288 28.1207 3.75147V9.97916"
      stroke={fill || color.general.black}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.2393 32.7907L34.2793 34.2799L32.5193 29.8122V16.4092C32.5193 16.0142 32.3339 15.6354 32.0038 15.3561C31.6737 15.0768 31.2261 14.9199 30.7593 14.9199H13.1593C12.6925 14.9199 12.2449 15.0768 11.9148 15.3561C11.5847 15.6354 11.3993 16.0142 11.3993 16.4092V31.3015C11.3993 31.6964 11.5847 32.0752 11.9148 32.3545C12.2449 32.6338 12.6925 32.7907 13.1593 32.7907H27.2393Z"
      stroke={fill || color.general.black}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
