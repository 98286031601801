import React from 'react'

export const invoices = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M12.0574 1.08569H2.45737C2.09364 1.08569 1.74481 1.23018 1.48762 1.48738C1.23043 1.74457 1.08594 2.0934 1.08594 2.45712V17.5428C1.08594 17.9066 1.23043 18.2554 1.48762 18.5126C1.74481 18.7698 2.09364 18.9143 2.45737 18.9143H17.5431C17.9068 18.9143 18.2556 18.7698 18.5128 18.5126C18.77 18.2554 18.9145 17.9066 18.9145 17.5428V7.94284L12.0574 1.08569Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0566 7.25712V1.08569L18.9138 7.94284H12.7424C12.5605 7.94284 12.3861 7.87059 12.2575 7.742C12.1289 7.6134 12.0566 7.43899 12.0566 7.25712Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.57227 6.5713V4.51416"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.51367 12.057C4.51367 13.0856 5.43253 13.4284 6.57082 13.4284C7.7091 13.4284 8.62796 13.4284 8.62796 12.057C8.62796 9.99986 4.51367 9.99986 4.51367 7.94272C4.51367 6.57129 5.43253 6.57129 6.57082 6.57129C7.7091 6.57129 8.62796 7.09243 8.62796 7.94272"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.57227 13.4285V15.4856"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
