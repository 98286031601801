export const isValidJSON = (string) => {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export function jsonParser(blob) {
  let parsed = JSON.parse(blob)

  if (typeof parsed === 'string') {
    parsed = jsonParser(parsed)
  }

  return parsed
}
