import React, { forwardRef, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'

import quillEmoji from 'quill-emoji'
import { useEffect } from 'react'
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji
Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true
)

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  [
    'bold',
    'italic',
    'underline',
    'strike',
    // 'blockquote', 'link'
  ],
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ indent: '-1' }, { indent: '+1' }],
  // ['emoji'],
  ['clean'],
]

const RichTextEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || '')
  const reactQuillRef = useRef(null)

  useEffect(() => {
    setValue(props.value)
  }, [props])

  const onChange = (content) => {
    setValue(content)

    if (props.onChange) {
      props.onChange({
        html: content,
        markdown: content,
      })
    }
  }

  // const onFocus = (range, source, editor) => {
  //   // reactQuillRef.current.setSelectionRange(value.length, value.length)
  // }
  return (
    <ReactQuill
      id={props.id}
      ref={ref || reactQuillRef}
      theme="snow"
      placeholder={props.placeholder}
      modules={{
        toolbar: {
          container: TOOLBAR_OPTIONS,
          // handlers: {
          //   emoji() { },
          // },
        },
        // 'emoji-toolbar': true,
        // 'emoji-textarea': false,
        // 'emoji-shortname': true,
      }}
      formats={['header', 'bold', 'italic', 'underline', 'strike', 'list', 'emoji']}
      value={value}
      onChange={onChange}
      readOnly={props.readOnly}
      // onFocus={onFocus}
    />
  )
})

export default RichTextEditor
