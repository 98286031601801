import React from 'react'

export const tag = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_10369_49759)">
      <path
        d="M15.1774 10.7315L10.7317 15.1772C10.571 15.3377 10.3532 15.4279 10.126 15.4279C9.89886 15.4279 9.68101 15.3377 9.52029 15.1772L0.697437 6.35434C0.653104 6.31195 0.618808 6.2602 0.597056 6.20285C0.575305 6.14551 0.566649 6.08403 0.571723 6.02291L1.24601 1.64576C1.25151 1.54146 1.29542 1.44288 1.36927 1.36903C1.44313 1.29517 1.54171 1.25127 1.64601 1.24576L6.02315 0.571478C6.08427 0.566405 6.14575 0.575061 6.2031 0.596812C6.26044 0.618564 6.3122 0.65286 6.35458 0.697193L15.1774 9.52005C15.338 9.68076 15.4281 9.89862 15.4281 10.1258C15.4281 10.3529 15.338 10.5708 15.1774 10.7315V10.7315Z"
        stroke={fill || color.general.light}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.69838 5.26859C5.01397 5.26859 5.26981 5.01275 5.26981 4.69716C5.26981 4.38157 5.01397 4.12573 4.69838 4.12573C4.38279 4.12573 4.12695 4.38157 4.12695 4.69716C4.12695 5.01275 4.38279 5.26859 4.69838 5.26859Z"
        stroke={fill || color.general.light}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10369_49759">
        <rect width="16" height="16" fill={color.general.white} />
      </clipPath>
    </defs>
  </svg>
)
