import clsx from 'clsx'
import ru from 'date-fns/locale/ru'
import uk from 'date-fns/locale/uk'
import React, { useState } from 'react'
import Calendar, { CalendarContainer, registerLocale } from 'react-datepicker'
registerLocale('uk', uk)
registerLocale('ru', ru)

import { useEffect } from 'react'
import { rangeDate, rangeOption } from '../../../utils/datePickerRangeOption'
import { Input, Select } from '../../index'
import { StyledDatePicker } from './styled'

const DatePicker = (props) => {
  // const [startDate, setStartDate] = useState(new Date())
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div className="myContainer">
          <Select
            options={props.selectOptions || rangeOption}
            fullWidth
            valueKey="id"
            labelKey="period"
            variant="primary"
            withoutValidation
            value={selectValue}
            onChange={onSelectChange}
            className="datePickerSelect"
            iconLeftProps={{ name: 'calendar', fill: props.theme.color.general.light }}
            t={props.t}
          />
          {children}
        </div>
      </CalendarContainer>
    )
  }

  const isSameRange =
    props?.dateRange?.[0]?.toISOString() === rangeDate[props.startPeriod]?.[0]?.toISOString() &&
    props?.dateRange?.[1]?.toISOString() === rangeDate[props.startPeriod]?.[1]?.toISOString()

  const [selectValue, setSelectValue] = useState(
    props.startPeriod ? { id: props.startPeriod, period: props.startPeriod } : ''
  )
  useEffect(() => {
    setSelectValue(isSameRange ? { id: props.startPeriod, period: props.startPeriod } : '')
  }, [isSameRange])

  const onSelectChange = ({ id, period }) => {
    props.setDateRange(rangeDate[id])
    setSelectValue({ id, period })
  }

  return (
    <StyledDatePicker className={clsx(props.isClearable && 'isClearable', 'datePickerContainer')}>
      <Calendar
        {...props}
        selected={props.value}
        onChange={props.onChange}
        dateFormat="dd/MM/yyyy"
        // onChange={(date) => setStartDate(date)}
        minDate={props.minDate}
        maxDate={props.maxDate}
        showDisabledMonthNavigation
        isClearable={props.isClearable}
        customInput={
          props.withIcon && <Input iconLeftProps={{ name: 'calendar', width: 18, height: 18 }} {...props} />
        }
        locale={props.currLang || 'en'}
        calendarStartDay={1} //to start from Monday
        calendarContainer={props.hasSelect && MyContainer}
        placeholderText={props.placeholder}
        // popperModifiers={[
        //   {
        //     name: 'offset',
        //     options: {
        //       offset: [5, 10],
        //     },
        //   },
        //   {
        //     name: 'preventOverflow',
        //     options: {
        //       rootBoundary: 'viewport',
        //       tether: false,
        //       altAxis: true,
        //     },
        //   },
        // ]}
      />
    </StyledDatePicker>
  )
}

export default DatePicker
