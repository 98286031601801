import clsx from 'clsx'
import { PropTypes as T } from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Icon } from '../../atomic-design-components'
import { ENTITIES } from '../../config/index'
import { useMappedState } from '../../hooks/useReactRedux'
import { ScreenContext } from '../../hooks/useScreenContext'
import { toggleNavMenuExtended, userLogout } from '../../redux-saga/actions'
import { selectIsNavMenuExtended, selectUserRole } from '../../redux-saga/selectors'
import Logo from '../Logo'
import MenuGroup from './MenuGroup'
import MenuItem from './MenuItem'
import UserBlockWithWebsocket from './UserBlockWithWebsocket'
import { StyledLine, StyledNavMenu } from './styled'

const NavMenu = ({ isMobile, theme, colorScheme, isTablet, isRevert, onClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('menu')
  const { pathname } = useLocation()
  const params = useParams()

  const { width, md, xl, height } = useContext(ScreenContext)
  const isDesktop = width > xl

  const role = useMappedState(selectUserRole)
  const isNavMenuExtended = useMappedState(selectIsNavMenuExtended)

  const currMenuState = JSON.parse(localStorage.getItem('menuState'))
  const hasLocalStorageState = currMenuState?.isExtended !== undefined

  useEffect(() => {
    if (isDesktop && !hasLocalStorageState) {
      dispatch(toggleNavMenuExtended(isDesktop))
    } else if (hasLocalStorageState) {
      dispatch(toggleNavMenuExtended(currMenuState.isExtended))
    }
  }, [isDesktop])

  const onToggleIconClick = () => {
    dispatch(toggleNavMenuExtended())
  }

  const isActiveKey = (key) =>
    pathname && pathname !== '/'
      ? pathname
          .replace(/[^a-zA-Z ]/g, '')
          .replace(params.action, '')
          .includes(key.toLowerCase())
      : key === 'dashboard'

  const onLogout = () => {
    dispatch(userLogout())
  }

  return (
    <StyledNavMenu
      className={clsx(
        isNavMenuExtended ? 'extended' : 'condensed',
        isTablet && 'tabletView',
        isRevert && 'revertView'
      )}
      colorScheme={colorScheme}
    >
      {!isMobile && <Icon name="navMenuToggleArrow" className="toggleIcon" onClick={onToggleIconClick} />}
      {!isMobile && (
        <Logo
          theme={theme}
          variant="small"
          isExtended={true}
          hasMiniLogo={!isMobile}
          logoColor={theme.color.general.black}
          to="../chats"
        />
      )}
      <div className="topWrapper">
        <MenuGroup
          entitiesArr={ENTITIES.common}
          isExtended={isNavMenuExtended}
          isMobile={isMobile}
          onClick={onClick}
        />
        <StyledLine />
        <MenuGroup
          entitiesArr={ENTITIES.roleSensitive}
          isExtended={isNavMenuExtended}
          isMobile={isMobile}
          onClick={onClick}
          isRoleSensitive={true}
          role={role}
        />
        {role === 'system_administrator' && (
          <>
            <StyledLine />
            <MenuGroup
              entitiesArr={ENTITIES.systemAdminOnly}
              isExtended={isNavMenuExtended}
              isMobile={isMobile}
              onClick={onClick}
              isRoleSensitive={true}
              role={role}
            />
          </>
        )}
      </div>
      <MenuGroup onClick={onClick}>
        <MenuItem
          iconName="profileEdit"
          text="accountSettings"
          to="/settings"
          isActive={isActiveKey('settings')}
          isExtended={isNavMenuExtended}
          isMobile={isMobile}
        />
        {role !== 'operator' && (
          <MenuItem
            iconName="briefcase"
            text="myCompany"
            to="/my-company"
            isActive={isActiveKey('myCompany')}
            isExtended={isNavMenuExtended}
            isMobile={isMobile}
          />
        )}
      </MenuGroup>
      <StyledLine />

      <UserBlockWithWebsocket isMobile={isMobile} isExtended={isNavMenuExtended} t={t} />

      <StyledLine />
      <MenuItem
        iconName="logout"
        text={t('signOut')}
        className="logoutLink"
        to="/sign-in"
        isActive={false}
        onClick={onLogout}
        isExtended={isNavMenuExtended}
        isMobile={isMobile}
      />
    </StyledNavMenu>
  )
}

export default withTheme(NavMenu)

NavMenu.propTypes = {
  isExtended: T.bool,
}

NavMenu.defaultProps = {}
