import styled from 'styled-components'
import { getCssFromTheme } from '../../../utils/css'

export const StyledDatePicker = styled.div`
  position: relative;

  .react-datepicker {
    color: ${({ theme }) => theme.color.general.darker};
  }

  .react-datepicker__input-container {
    input {
      ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    }
    input:not(.hasLeftIcon) {
      padding: 12px 15px;
      text-align: center;
    }

    .icon.leftIcon {
      bottom: 14px;
    }
  }
  .react-datepicker-popper {
    z-index: 3;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${({ theme }) => theme.color.primary.main};
  }

  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today, .react-datepicker__day--in-selecting-range, .react-datepicker__day--range-start, .react-datepicker__day--in-range) {
    color: ${({ theme }) => theme.color.general.darker};
    background-color: transparent;
  }

  .inputContainer {
    margin: 0;
    .datePickerSelect {
      border-color: transparent;
    }
  }

  .myContainer .react-datepicker__navigation {
    top: 46px;
  }

  &.isClearable {
    .react-datepicker__close-icon {
      background-color: transparent;
      padding: 0 6px;
    }
    .react-datepicker__close-icon::after {
      background-color: transparent;
      color: ${({ theme }) => theme.color.general.darker};
      font-size: 16px;
    }
    input:not(.hasLeftIcon) {
      padding: 12px 23px 12px 15px;
    }
  }

  @media only screen and (max-width: 850px) {
    .react-datepicker__input-container input {
      font-size: 16px;
    }
  }
`
