import { put, select, takeLatest } from 'redux-saga/effects'

import { INITIALIZE_APP, SIGN_IN_SUCCESS } from '../constants'
import { getCurrentUser } from '../actions'
import { verifyToken } from './auth'

function* authInit({ navigate, location, changeLanguage }) {
  try {
    const token = yield verifyToken()

    if (token) {
      yield put({ type: SIGN_IN_SUCCESS, token })
      yield put(getCurrentUser(navigate, location, changeLanguage))

      // if (accessGranted) {
      //   yield websocketsInit()
      // }
    } else {
      navigate('/sign-in')
    }
  } catch (error) {
    console.log(error)
    navigate('/sign-in')
  }
}

export default function* initSaga() {
  return [yield takeLatest(INITIALIZE_APP, authInit)]
}
