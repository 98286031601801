import React, { useEffect, useState } from 'react'

// import ReadOnlyInfo from '../RightPanel/ReadOnlyInfo'
import { Select } from '../../atomic-design-components'
import { getSelectOptions, getSelectValue } from '../../utils/select'

const FormSelect = (props) => {
  const {
    field,
    formSubmit,
    initialValue,
    isDisabled,
    isHidden,
    isSubmitOnBlur,
    label,
    labelKey,
    lng,
    name,
    optionsData,
    optionsFromFormValues,
    t,
    updateSelect,
    value,
    optionsArray,
  } = props

  const [options, setOptions] = useState([])

  const fieldLabelKey = field.labelKey || labelKey

  useEffect(() => {
    const filteredOptions = (
      optionsFromFormValues ||
      optionsArray ||
      getSelectOptions(field, optionsData, lng)
    )?.filter((option) => option.state !== 'deleted')

    setOptions(filteredOptions)
  }, [field, lng, optionsData, optionsArray, optionsFromFormValues])

  const val = getSelectValue(value, options, field) //TODO: make it defaultValue of select
  // if (field.readOnly) {
  //   return <ReadOnlyInfo labelKey={fieldLabelKey || 'label'} label={label} value={val} />
  // }
  const onCreateOption = (newValue) => {
    if (field.isCreatable && !field.getOptionsFromFormValues) {
      const newOptions = field.isMulti ? [...options, ...val, newValue] : [...options, newValue] // TODO: to check (tags)
      setOptions(newOptions)
    }

    updateSelect(name, field.onSelectValueChange)(field.isMulti ? [...val, newValue] : newValue)

    if (isSubmitOnBlur && formSubmit) {
      formSubmit({}, { [name]: field.isMulti ? [...val, newValue] : newValue })
    }
  }

  const onChange = (newValue) => {
    if (field.onChange) {
      field.onChange(newValue)
    } else {
      updateSelect(name, field.onSelectValueChange)(newValue)
    }

    if (isSubmitOnBlur && formSubmit) {
      formSubmit({}, { [name]: newValue })
    }
  }

  return (
    <Select
      {...props}
      {...field}
      value={val}
      onChange={onChange}
      onCreateOption={onCreateOption}
      createLabelText={t(field.createLabelText) || t('create')}
      isDisabled={isDisabled || (field.disabledWhenNotEmpty && initialValue !== '') || !options}
      autosize={field.autosize}
      isMulti={field.isMulti || false}
      isCreatable={field.isCreatable || false}
      isClearable={typeof field.isClearable === 'undefined' && field.isMulti ? true : field.isClearable}
      noOptionsMessage={t('nothingFound')}
      getOptionBeforeTextComponent={field.getOptionBeforeTextComponent}
      customGetOptionLabel={field.customGetOptionLabel}
      labelKey={fieldLabelKey || 'label'}
      valueKey="id"
      options={options}
      t={t}
      lng={lng}
      iconLeftProps={field.iconLeftProps}
    >
      {field.additionToInput}
      {!isHidden && field.addElementToSelect && field.addElementToSelect(updateSelect, t, field.disabled)}
    </Select>
  )
}

export default FormSelect

FormSelect.defaultProps = {
  labelKey: 'label',
}
