import React from 'react'

export const forwarded = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none">
    <g clipPath="url(#clip0_11111_3864)">
      <path
        d="M1.05664 12.0288C3.7291 9.76779 4.38647 8.82882 6.9995 8.82882H8.37093V11.5717L12.9424 6.54311L8.37093 1.97168V4.71454H7.45664C2.88521 4.71454 1.97093 9.28596 1.05664 12.0288Z"
        stroke={fill || color.primary.light}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11111_3864">
        <rect
          width="12.8"
          height="12.8"
          fill={color.general.white}
          transform="translate(0.599609 0.600098)"
        />
      </clipPath>
    </defs>
  </svg>
)
