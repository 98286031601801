import { takeLatest, put, select, call } from 'redux-saga/effects'
import Cookies from 'universal-cookie'

import {
  GET_CURRENT_USER,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  TOKEN_REFRESH,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  GET_INVITE_DETAILS,
  GET_INVITE_DETAILS_SUCCESS,
  SIGN_OUT,
  TOKEN_REFRESH_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_TOKEN,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_CURRENT_SUBSCRIPTION,
  GET_CURRENT_SUBSCRIPTION_ERROR,
} from '../constants'
import { buildRequest, fetchData, showError } from '../../api'
import { popupAlertShow } from '../actions'
import { selectUserSystemParams, selectWholeUserState } from '../selectors'
// import { currentUser } from '../../dummyData'
import { doGetFileUrl } from './fileUpload'

const cookies = new Cookies()

function* doUserSignUp({ payload, navigate }) {
  const isInvite = payload.code
  const lang = cookies.get('lng')

  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: isInvite ? 'inviteSignup' : 'signUp',
      requestBody: {
        ...payload,
        lang,
        timezone_region: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    const response = yield fetchData(request)

    yield showError(response)

    if (isInvite) {
      navigate('/sign-in')
    } else {
      yield put({ type: SIGN_UP_SUCCESS, email: response.email })
      navigate('/confirm-email')
      console.log(`${window.location.origin}/sign-in?code=${response.email_confirmation_code}`)
    }
  } catch (error) {
    console.error(error)
  }
}

function doUserLogout() {
  if (cookies) {
    cookies.remove('tokenValue', { path: '/' })
    cookies.remove('tokenExpires', { path: '/' })
  }
}

function* doGetCurrentCompany() {
  try {
    const request = yield buildRequest({ apiMethod: 'GET', type: 'currentCompany' })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({ type: GET_CURRENT_SUBSCRIPTION_SUCCESS, payload: response })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CURRENT_SUBSCRIPTION_ERROR })
  }
}

function* doGetCurrentUser({ navigate, location, changeLanguage }) {
  try {
    const request = yield buildRequest({ apiMethod: 'GET', type: 'currentUser' })
    const response = yield fetchData(request)
    yield showError(response)

    const responseItem = yield call(
      doGetFileUrl,
      response,
      'member_profile_picture',
      response.member_profile_picture
    )

    yield put({ type: GET_CURRENT_USER_SUCCESS, payload: responseItem })

    // TODO: figure out why this code runs twice on signIn
    // first run doesn't have changeLanguage, meaning it isn't being triggered by
    // dispatch(initializeApp(navigate, location, i18n.changeLanguage)) in PrivateRoute
    if (response.lang && changeLanguage) {
      cookies.set('lng', response.lang, { path: '/' })
      changeLanguage(response.lang)
    }

    if (response?.email_verified) {
      yield doGetCurrentCompany()
    }

    if (response?.email_verified && !location) {
      navigate('/')
      // navigate(location ? `${location.pathname}${location.search}` : '/')
    } else if ('email_verified' in response && !response.email_verified) {
      navigate('/confirm-email')
      console.log(`${window.location.origin}/sign-in?code=${response.email_confirmation_code}`)
    }

    return response?.email_verified
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CURRENT_USER_ERROR })
    const { isAuthenticated, inProgress } = yield select(selectUserSystemParams)

    if (!isAuthenticated && !inProgress) {
      navigate('/sign-in')
    }
  }
}

function* doUserSignIn({ payload, navigate }) {
  try {
    const request = yield buildRequest({ apiMethod: 'POST', type: 'signIn', requestBody: payload })
    const response = yield fetchData(request)

    yield showError(response)

    if (response.access_token) {
      yield put({ type: SIGN_IN_SUCCESS, token: response.access_token })
    }
    if (response.expires_in) {
      cookies.set('tokenExpires', Date.now() + response.expires_in * 1000, { path: '/' })
    }

    if (response.refresh_token) {
      cookies.set('tokenValue', response.refresh_token, { path: '/', httpOnly: false })
    }

    yield doGetCurrentUser({ navigate })
  } catch (error) {
    console.error(error)
  }
}

function* doUserRequestResetPassword({ payload, navigate }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'requestResetPassword',
      requestBody: payload,
    })
    const response = yield fetchData(request)

    yield showError(response)

    if (navigate) {
      navigate('/password-request-sent')
    } else {
      yield put(
        popupAlertShow({
          contentKey: 'passRecoverySentCustomers',
          contentParams: { email: payload.email },
          type: 'success',
          withCloseButton: true,
          iconName: 'mailSend',
        })
      )
    }
    console.log(`${window.location.origin}/new-password?code=${response.code}`)
  } catch (error) {
    console.error(error)
  }
}

function* doUserResetPassword({ payload, isUpdate, navigate }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: isUpdate ? 'updatePassword' : 'resetPassword',
      requestBody: payload,
    })
    const response = yield fetchData(request)

    if (!isUpdate) {
      yield showError(response)
      navigate('/sign-in')
    }

    if (isUpdate) {
      if (response === true) {
        yield put(
          popupAlertShow({
            contentKey: 'dataSuccessfullySaved',
            type: 'success',
            withCloseButton: true,
            iconName: 'statusActive',
          })
        )

        yield put({ type: RESET_PASSWORD_SUCCESS })
      } else {
        yield put(
          popupAlertShow({
            contentKey: 'wrongPass',
            type: 'error',
            timeout: 10000,
            withCloseButton: true,
            iconName: 'statusDeleted',
          })
        )
        yield put({ type: RESET_PASSWORD_ERROR })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* doUserConfirm({ payload }) {
  try {
    const request = yield buildRequest({ apiMethod: 'POST', type: 'emailConfirm', requestBody: payload })
    const response = yield fetchData(request)

    yield showError(response)

    if (response.email_verified) {
      yield put({ type: CONFIRM_EMAIL_SUCCESS, status: response.status })
      yield put(
        popupAlertShow({
          contentKey: 'emailConfirmed',
          type: 'success',
          withCloseButton: true,
          iconName: 'doggySign',
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function* doGetInviteDetails({ payload: { code } }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'inviteDetails',
      requestBody: { code: code },
    })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({
      type: GET_INVITE_DETAILS_SUCCESS,
      payload: {
        email: response.email,
        company_name: response.customer_alias,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export function* verifyToken(tokenArg, exp) {
  const cookies = new Cookies()
  // verify validity of the token
  const { token: access_token, details } = yield select(selectWholeUserState)
  const expFromCookies = cookies.get('tokenExpires', { path: '/' })

  const expirationDate = exp || details?.exp || expFromCookies
  const tokenVerified = tokenArg || access_token

  const isTokenExpired = !tokenVerified || !expirationDate || (expirationDate && Date.now() > +expirationDate)

  if (isTokenExpired) {
    const newAccessToken = yield doRefreshToken()

    return newAccessToken
  }
  return tokenVerified
}

export function* doRefreshToken() {
  try {
    const refreshTokenFromCookies = cookies.get('tokenValue', { path: '/' })

    if (!refreshTokenFromCookies) {
      return
    }

    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'refreshToken',
      requestBody: { refresh_token: refreshTokenFromCookies },
    })

    const response = yield fetchData(request)

    yield showError(response)

    if (response.access_token) {
      yield put({ type: SIGN_IN_SUCCESS, token: response.access_token })
    }
    if (response.expires_in) {
      cookies.set('tokenExpires', Date.now() + response.expires_in * 1000, { path: '/' })
    }

    if (response.refresh_token) {
      cookies.set('tokenValue', response.refresh_token, { path: '/', httpOnly: false })
    }

    return response.access_token
  } catch (error) {
    console.error(error)
    yield put({ type: TOKEN_REFRESH_ERROR })
  }
}

export default function* authSaga() {
  return [
    yield takeLatest(SIGN_IN, doUserSignIn),
    yield takeLatest(SIGN_UP, doUserSignUp),
    yield takeLatest(SIGN_OUT, doUserLogout),
    yield takeLatest(GET_CURRENT_USER, doGetCurrentUser),
    yield takeLatest(GET_CURRENT_SUBSCRIPTION, doGetCurrentCompany),
    yield takeLatest(CONFIRM_EMAIL, doUserConfirm),
    yield takeLatest(REQUEST_RESET_PASSWORD, doUserRequestResetPassword),
    yield takeLatest(RESET_PASSWORD, doUserResetPassword),
    yield takeLatest(GET_INVITE_DETAILS, doGetInviteDetails),
    yield takeLatest(TOKEN_REFRESH, doRefreshToken),
    yield takeLatest(VERIFY_TOKEN, verifyToken),
  ]
}
