import styled from 'styled-components'

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;

  .logoIcon {
    margin: 0px;
  }
  .skibbleTextLogo {
    margin-left: 16px;
  }
  .link a {
    display: flex;
    align-items: center;
  }
`
