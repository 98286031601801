import { PropTypes as T } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Tab, Tabs as TabsComponent } from 'react-tabs-scrollable'
import 'react-tabs-scrollable/dist/rts.css'

import { withTheme } from 'styled-components'
import DropdownMenu from '../../atoms/Dropdown/Dropdown'
import FlexRow from '../../atoms/FlexRow/FlexRow'
import Icon from '../../atoms/Icon/Icon'
import { StyledTabs } from './styled'

const Tabs = (
  { typographyType, withAddAction, tabsTitles, tabsContents, dropdownItems, onDropItemClick, isDisabled },
  props
) => {
  const [activeTab, setActiveTab] = useState(0)

  if (!tabsTitles?.length && !tabsContents?.length && !dropdownItems?.length) {
    return null
  }

  const onTabClick = (e, idx) => {
    setActiveTab(idx)
  }

  useEffect(() => {
    setActiveTab(0)
  }, [tabsTitles?.length])

  return (
    <StyledTabs variant={typographyType || 'caption1'}>
      <FlexRow margin="0 0 20px">
        {!isDisabled && withAddAction && !!dropdownItems?.length && (
          <DropdownMenu
            MenuButton={Icon}
            buttonProps={{
              name: 'plus',
              wrapperWidth: 44,
              wrapperHeight: 44,
              borderRadius: '3px',
            }}
            openDirection="right"
            className="tabsDropdown"
            dropdownItems={dropdownItems}
            onItemClick={onDropItemClick}
          />
        )}
        <TabsComponent
          activeTab={activeTab}
          onTabClick={onTabClick}
          hideNavBtnsOnMobile={false}
          rightNavBtnClassName="right-nav-btn"
          rightBtnIcon={<Icon name="chevronLeft" />}
          leftBtnIcon={<Icon name="chevronLeft" />}
        >
          {tabsTitles.map((item, idx) => (
            <Tab key={idx} id={item.props.id}>
              {item}
            </Tab>
          ))}
        </TabsComponent>
      </FlexRow>
      {tabsContents.map((item, index) => {
        return (
          <div className="animate__animated animate__fadeInLeft" role="tabpanel" key={index} {...props}>
            {activeTab === index && <div className="mx-4">{item}</div>}
          </div>
        )
      })}
    </StyledTabs>
  )
}

export default withTheme(Tabs)

Tabs.propTypes = {
  typographyType: T.string,
  withAddAction: T.bool,
}

Tabs.defaultProps = {
  withAddAction: true,
}
