import styled from 'styled-components'
import { getCssFromTheme } from '../../../utils/css'

export const StyledOptionRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.general.white};
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:hover {
    background-color: ${({ theme }) => theme.color.general.lightest};
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
  }

  .icon {
    &.rightIcon {
      padding-right: 16px;
    }

    &.optionLeftIcon {
      margin-left: 16px;
    }
  }

  .react-select__option {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    background-color: ${({ theme }) => theme.color.general.white};
    color: ${({ theme }) => theme.color.general.black};
    padding: 12px 15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:hover {
      background-color: ${({ theme }) => theme.color.general.lightest};

      ~ .icon {
        background-color: ${({ theme }) => theme.color.general.lightest};
        margin-right: 10px;
      }
    }

    &.react-select__option--is-disabled {
      display: none;
    }
  }

  .icon.isOptionDisabled {
    display: none !important;
  }

  .icon .react-select__option--is-focused {
    background-color: transparent;
  }

  // .react-select__option--is-selected {
  //   background-color: ${({ theme }) => theme.color.general.lightest};
  //   border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
  //   border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
  //   .react-select__option {
  //     background-color: ${({ theme }) => theme.color.general.lightest};
  //   }
  // }
  &.selected {
    background-color: ${({ theme }) => theme.color.general.lightest};
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    .react-select__option {
      background-color: ${({ theme }) => theme.color.general.lightest};
    }
  }
`

export const StyledSelect = styled.div`
  width: inherit;

  .react-select__control {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    border: none;
    min-height: 42px;
    box-shadow: none;

    .leftIcon {
      margin-left: 15px;
    }

    &:hover,
    &:focus {
      border: none;
    }
  }

  &.hasError {
    border-radius: 3px;
    .react-select__control {
      border: none;
    }
  }

  .react-select__control--is-focused.react-select__control--menu-is-open {
    border: none;

    &:hover,
    &:focus {
      border: none;
    }
  }

  .react-select__control--menu-is-open {
    border: none !important;
  }

  .react-select__value-container {
    padding: 7px 0 7px 10px;

    .react-select__single-value {
      margin: 0;
    }

    & > div:last-child {
      margin: 0;
      padding: 0;
      top: initial;
    }

    &.react-select__value-container--is-multi {
      & > div {
        display: flex;
        margin: 4px 2px 4px 0;

        &:last-child {
          line-height: 18px;
        }
      }

      .tag {
        white-space: normal;
      }

      input[readonly] {
        width: 0;
      }
    }
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.color.general.dark};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicators {
    padding-right: 15px;

    .react-select__indicator {
      padding: 0;
      padding-left: 8px;
    }
  }

  .react-select__indicator {
    padding-right: 0;
  }
  .react-select__menu {
    background-color: ${({ theme }) => theme.color.general.white};
    box-shadow: 0 14px 15px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    z-index: 9;
    width: calc(100% + 2px);
    border: none;
    right: -1px;
  }
  &.autosize {
    .react-select__menu {
      width: auto;
    }
  }

  .react-select__menu-list {
    border-radius: 3px;
    padding: 0;
  }

  .react-select__menu-notice--no-options {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    background-color: ${({ theme }) => theme.color.general.white};
    padding: 13px 16px;
    text-align: left;
  }

  @media only screen and (max-width: 850px) {
    .react-select__control {
      font-size: 16px;
    }
  }
`
