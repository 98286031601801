import React, { useEffect, useState } from 'react'
import { PropTypes as T } from 'prop-types'
import { withTheme } from 'styled-components'
import clsx from 'clsx'

import { StyledCheckboxWrapper } from './styled'
import { Icon, Typography } from '../../index'

const Checkbox = ({
  checked,
  className,
  disabled,
  fontWeight,
  handleChange,
  label,
  labelType,
  name,
  readOnly,
  theme: { color },
  type,
  value,
  children,
  checkboxColor,
  fill,
  withLabelChildren,
  ...otherProps
}) => {
  const [stateChecked, setStateChecked] = useState(checked || false)

  const onChange = (e) => {
    handleChange && handleChange(e.target.checked, e)
    setStateChecked(e.target.checked)
  }

  const onLabelClick = () => {
    setStateChecked((prev) => (type === 'radio' ? true : !prev))
    handleChange && handleChange(type === 'radio' ? true : !stateChecked, { target: { name, value } })
  }

  useEffect(() => {
    setStateChecked(checked)
  }, [checked])

  return (
    <StyledCheckboxWrapper className={clsx(className, disabled && 'disabled', type)}>
      <input
        type={type}
        checked={stateChecked}
        disabled={disabled}
        name={name}
        onChange={disabled || readOnly ? undefined : onChange}
        readOnly={readOnly}
        value={value}
        {...otherProps}
      />
      {stateChecked ? (
        <Icon name={`${type}Checked`} fill={fill || color.secondary.main} checkboxColor={checkboxColor} />
      ) : (
        <Icon name={`${type}Unchecked`} />
      )}
      {(withLabelChildren || label) && (
        <Typography
          variant={labelType}
          onClick={disabled || readOnly ? undefined : onLabelClick}
          fontWeight={fontWeight}
        >
          {label || children}
        </Typography>
      )}
      {!withLabelChildren && children}
    </StyledCheckboxWrapper>
  )
}

export default withTheme(Checkbox)

Checkbox.propTypes = {
  checked: T.bool,
  className: T.string,
  disabled: T.bool,
  fontWeight: T.oneOfType([T.number, T.string]),
  handleChange: T.func,
  label: T.string,
  labelType: T.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'caption1',
    'caption2',
    'caption3',
    'body1',
    'body2',
    'body3',
    'button1',
    'input',
  ]),
  name: T.string,
  readOnly: T.bool,
  theme: T.object,
  type: T.oneOf(['checkbox', 'radio']).isRequired,
  value: T.string,
  children: T.node,
  withLabelChildren: T.bool,
}

Checkbox.defaultProps = {
  labelType: 'input',
  type: 'checkbox',
}
