import clsx from 'clsx'
import { PropTypes as T } from 'prop-types'
import React from 'react'
import { withTheme } from 'styled-components'

import { Icon, Typography } from '../../index'
import { StyledTextPanel } from './styled'

const TextPanel = ({
  alignItems,
  backgroundColor,
  bordered,
  borderColor,
  children,
  className,
  color,
  content,
  contentTypographyProps,
  gap,
  fullWidth,
  iconLeftProps,
  iconRightProps,
  inverted,
  justifyContent,
  padding,
  textAlign,
  title,
  titleTypographyProps,
  theme,
}) => {
  const textColor = inverted ? theme.color.general.white : color

  return (
    <StyledTextPanel
      textAlign={textAlign}
      className={clsx(className, 'panel', (borderColor || bordered) && 'bordered', fullWidth && 'fullWidth')}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={textColor}
      padding={padding}
      justifyContent={justifyContent}
      alignItems={alignItems}
      inverted={inverted}
      gap={gap}
    >
      {children || (
        <>
          <Icon height={15} width={15} fill={textColor} {...iconLeftProps} />
          <div className="titleAndContent">
            {title && (
              <Typography
                className="title"
                component="div"
                variant="caption1"
                color={textColor}
                {...titleTypographyProps}
              >
                {title}
              </Typography>
            )}
            {content && (
              <Typography className="content" variant="body2" color={textColor} {...contentTypographyProps}>
                {content}
              </Typography>
            )}
          </div>
          <Icon height={15} width={15} fill={textColor} {...iconRightProps} />
        </>
      )}
    </StyledTextPanel>
  )
}

export default withTheme(TextPanel)

TextPanel.propTypes = {
  alignItems: T.oneOf(['start', 'end', 'center', 'stretch']),
  backgroundColor: T.string,
  children: T.node,
  className: T.string,
  content: T.oneOfType([T.string, T.element, T.node]),
  justifyContent: T.oneOf([
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
  ]),
  padding: T.string,
  textAlign: T.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  title: T.oneOfType([T.string, T.element]),
}

TextPanel.defaultProps = {
  alignItems: 'center',
  justifyContent: 'start',
  textAlign: 'left',
}
