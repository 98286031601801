import React from 'react'

export const live_chatIcon = ({ width, height, fill }) => (
  <svg width={width || 18} height={height || 18} fill="none" viewBox="0 0 18 18">
    <circle cx="9" cy="9" r="9" fill={fill || "#003FBC"} />
    <path
      d="M13.2427 4.66138H4.75733C4.30636 4.66138 3.9375 5.03024 3.9375 5.4812V11.2201C3.9375 11.6711 4.30636 12.04 4.75733 12.04H9.06145L11.603 14.4666V12.0398H13.2427C13.6936 12.0398 14.0625 11.6709 14.0625 11.22V5.4812C14.0625 5.03024 13.6936 4.66138 13.2427 4.66138Z"
      fill="white"
    />
  </svg>
)
