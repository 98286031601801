import React, { useCallback, useEffect, useState } from 'react'
import { parse } from 'query-string'
import { useDispatch } from 'react-redux'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import { usePrevious } from '../../../../hooks/useReactRedux'
import { Button, Icon, Input } from '../../../index'
import { MAX_SEARCH_INPUT_LENGTH } from '../../../../constants'

const SEARCH_QUERY_MIN_LENGTH = 2

const TableSearch = ({
  isDropdownSearch,
  activeFilters,
  iconRightProps,
  searchValueState,
  setSearchValue,
  facets,
  type,
  ...otherProps
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const hasCustomSearchState = typeof searchValueState !== 'undefined' && setSearchValue

  const { search } = location
  const searchParsed = parse(search)
  const searchValue = hasCustomSearchState ? searchValueState : searchParsed.searchValue ?? ''
  const prevSearchValue = usePrevious(searchValue)

  const [searchOpened, setSearchOpened] = useState(false)

  const [inputValue, setInputValue] = useState(searchValue)
  const prevInputValue = usePrevious(inputValue)

  const prevType = usePrevious(type)

  useEffect(() => {
    if (type !== prevType && searchValue) {
      if (hasCustomSearchState) {
        setSearchValue('')
      } else {
        searchParsed.searchValue = ''
      }
    }
  }, [type, prevType])

  const onInputChange = useCallback(
    (e) => {
      const { value } = e.target

      setInputValue(value)

      if (value === searchValue || (value.length > 0 && value.length < SEARCH_QUERY_MIN_LENGTH)) {
        // ignore search
        return
      }

      if (hasCustomSearchState) {
        setSearchValue(value?.length >= SEARCH_QUERY_MIN_LENGTH ? value : '')
      } else if (value.length >= SEARCH_QUERY_MIN_LENGTH) {
        searchParsed.searchValue = value
      } else if (value.length === 0) {
        delete searchParsed.searchValue
        if (prevInputValue?.length > inputValue.length) {
          setInputValue('')
        }
      } else {
        console.log('Unmapped search corner case')
      }

      navigate({ search: createSearchParams(searchParsed).toString() })
    },
    [activeFilters, dispatch, facets, searchParsed, prevSearchValue, type]
  )

  const onSearchCleanClick = () => {
    setInputValue('')

    if (hasCustomSearchState) {
      setSearchValue('')
    } else {
      delete searchParsed.searchValue
      navigate({ search: createSearchParams(searchParsed).toString() })
    }
  }

  const withSearchCleanCross = iconRightProps?.name === 'cross'
  const searchCleanIconProps = withSearchCleanCross &&
    inputValue && {
      ...iconRightProps,
      onClick: onSearchCleanClick,
    }

  const getInput = () => (
    <Input
      maxLength={MAX_SEARCH_INPUT_LENGTH}
      value={inputValue}
      // withDebounce
      // primaryFocusColor
      iconRightProps={searchCleanIconProps || (!withSearchCleanCross && iconRightProps) || undefined}
      onChange={onInputChange}
      {...otherProps}
    />
  )

  if (!isDropdownSearch) {
    return getInput()
  }

  return (
    <>
      <Button variant="bordered" onClick={() => setSearchOpened((prev) => !prev)} className="navBar search">
        <Icon name="search" width={17} />
      </Button>
      {searchOpened && getInput()}
    </>
  )
}

export default TableSearch
