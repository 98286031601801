import React from 'react'

export const download = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M11.9999 5.42853H13.1428C13.2943 5.42853 13.4397 5.48873 13.5468 5.5959C13.654 5.70306 13.7142 5.8484 13.7142 5.99996V14.5714C13.7142 14.7229 13.654 14.8683 13.5468 14.9754C13.4397 15.0826 13.2943 15.1428 13.1428 15.1428H2.85707C2.70552 15.1428 2.56018 15.0826 2.45301 14.9754C2.34585 14.8683 2.28564 14.7229 2.28564 14.5714V5.99996C2.28564 5.8484 2.34585 5.70306 2.45301 5.5959C2.56018 5.48873 2.70552 5.42853 2.85707 5.42853H3.99993"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 0.857117V9.99997"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.71411 7.71423L7.99983 9.99995L10.2855 7.71423"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
