import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import MyCompany from '../MyCompany'
import { useTranslation } from 'react-i18next'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectMainData } from '../../redux-saga/selectors'
import TableBlock from '../../components/TableBlock'
import { getTableData } from '../../redux-saga/actions'
import { useDispatch } from 'react-redux'
import DepartmentsTableCard from './components/DepartmentsTableCard'
import { NAV_MENU_COLORS } from '../../constants'
import theme from '../../theme'
import { useWidth } from '../../hooks/useWidth'

const Departments = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')
  const params = useParams()
  const navigate = useNavigate()
  const urlId = params?.rightPanelId

  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.md

  const data = useMappedState(selectMainData('departments')) || []

  useEffect(() => {
    dispatch(getTableData({ type: 'members', isSystemData: true, status: 'active' }))
  }, [])

  const [isNavMenuExtended, setIsNavMenuExtended] = useState(false)

  const onIconClick = () => {
    setIsNavMenuExtended((prev) => !prev)
    navigate('../my-company')
  }
  // RightPanelComponent={urlId && <Outlet />}
  return (
    <MyCompany RightPanelComponent={<Outlet />} className="withBackIcon">
      <TableBlock
        tableData={data}
        navbarProps={{
          buttonProps: {
            iconLeftProps: { name: 'users' },
            text: t('addDepartment'),
            variant: 'primary',
            hoverType: 'opacity',
            onClick: () => {
              navigate('/my-company/departments/new')
            },
          },
        }}
        activeRowId={urlId}
        type="departments"
        iconEmpty="emptyDepartments"
        TableCardContent={DepartmentsTableCard}
        tableCardHeight={50}
        withMenuIcon={isMobile}
        menuIconProps={{
          opened: isNavMenuExtended,
          onClick: onIconClick,
          fill: theme.color.general.light,
          iconName: 'paginationArrow',
        }}
      // rightPanelIsOpened={rightPanelIsOpened}
      // leftPanelIsOpened={leftPanelIsOpened}
      // setLeftPanelOpened={setLeftPanelOpened}
      // user={user}
      />
    </MyCompany>
  )
}

export default Departments
