import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import ReactHelmet from '../../wrappers/ReactHelmet'

import GridLayout from '../Layout/GridLayout'
import TemplatesLeftPanel from './panels/TemplatesLeftPanel'
import EmptyScreen from '../../components/EmptyScreen'
import TemplateArea from './components/TemplateArea'
import { getTableData, upsertSidebarItem } from '../../redux-saga/actions'
import { selectUserMemberId, selectMainData } from '../../redux-saga/selectors'
import { useMappedState } from '../../hooks/useReactRedux'
import { parse } from 'query-string'
import { SEARCH_VALUE_MIN_LENGTH } from '../../constants'
import { Button } from '../../atomic-design-components'
import { ScreenContext } from '../../hooks/useScreenContext'

const Templates = ({ theme }) => {
  const { t } = useTranslation('all')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { width, md } = useContext(ScreenContext)

  const operatorID = useMappedState(selectUserMemberId)

  const templates = useMappedState(selectMainData('templates')) || []
  const { templateId } = useParams()

  const { search } = useLocation()
  const searchParsed = parse(search)
  const searchValue = searchParsed.searchValue || ''
  const isSearchActive = searchValue.length >= SEARCH_VALUE_MIN_LENGTH

  useEffect(() => {
    if (isSearchActive) {
      dispatch(getTableData({ type: 'templates', search }))
    }

    if (!searchValue || searchValue.length < SEARCH_VALUE_MIN_LENGTH) {
      dispatch(getTableData({ type: 'templates' }))
    }
  }, [searchValue])

  const getItemsList = () => {
    const general = templates?.length > 0 ? templates.filter((item) => item.operator_id === null) : []
    const personal = templates?.length > 0 ? templates.filter((item) => item.operator_id === operatorID) : []
    return [{ general }, { personal }]
  }

  const hasTemplates = () => getItemsList()?.filter((el) => Object.values(el)[0].length > 0)

  const [isNewTemplate, setIsNewTemplate] = useState(false)

  const onAddTemplate = () => {
    dispatch(
      upsertSidebarItem({
        type: 'templates',
        requestBody: { name: 'Personal template', body: '', operator_id: operatorID },
        location,
        navigate,
      })
    )

    setIsNewTemplate(true)
  }

  const onArrowClick = () => {
    navigate({ pathname: '.', search })
  }

  return (
    <>
      <ReactHelmet title="templatesTitle" />

      <GridLayout
        LeftPanelComponent={<TemplatesLeftPanel templates={getItemsList()} onAddTemplate={onAddTemplate} />}
        isLeftPanelShown={true}
      >
        {templateId && width < md && (
          <Button
            variant="general"
            iconLeftProps={{
              name: 'paginationArrow',
              fill: theme.color.general.black,
              width: 20,
              height: 20,
              className: 'withoutHover',
            }}
            className="navbarButton leftMenuButton"
            onClick={onArrowClick}
            margin="20px 30px 0"
          />
        )}
        {hasTemplates().length > 0 ? (
          <TemplateArea
            templatesList={templates}
            operatorID={operatorID}
            t={t}
            theme={theme}
            dispatch={dispatch}
            navigate={navigate}
            isNewTemplate={isNewTemplate}
            setIsNewTemplate={setIsNewTemplate}
          />
        ) : (
          <EmptyScreen type={isSearchActive ? 'templateSelected' : 'templates'} icon="templatesEmpty" />
        )}
      </GridLayout>
    </>
  )
}

export default withTheme(Templates)
