import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import ReactHelmet from '../../wrappers/ReactHelmet'

import { useDispatch } from 'react-redux'
import TableBlock from '../../components/TableBlock'
import { useMappedState } from '../../hooks/useReactRedux'
import { getTableData } from '../../redux-saga/actions'
import { selectMainData } from '../../redux-saga/selectors'
import GridLayout from '../Layout/GridLayout'
import SubscriptionPlansTableCard from './components/SubscriptionPlansTableCard'

const SubscriptionPlans = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    t,
    i18n: { language: currentLng },
  } = useTranslation('all')
  const params = useParams()
  const urlId = params?.rightPanelId

  const data = useMappedState(selectMainData('subscriptionPlans')) || []

  useEffect(() => {
    dispatch(getTableData({ type: 'customers', isSystemData: true }))
  }, [])

  return (
    <>
      <ReactHelmet title="subscriptionPlansTitle" />

      <GridLayout RightPanelComponent={urlId && <Outlet />}>
        <TableBlock
          tableData={data}
          TableCardContent={SubscriptionPlansTableCard}
          // tableCardHeight={190}
          activeRowId={urlId}
          type="subscriptionPlans"
          // rightPanelIsOpened={rightPanelIsOpened}
          // leftPanelIsOpened={leftPanelIsOpened}
          // setLeftPanelOpened={setLeftPanelOpened}
          // user={user}
          navbarProps={{
            buttonProps: {
              onClick: () => {
                navigate({ pathname: 'new' })
              },
              text: t('addFirstItem', { itemType: t('plan') }),
              iconName: 'plusCircle',
              padding: '13px 15px',
            },
          }}
          tableCardHeight={166}
        />
      </GridLayout>
    </>
  )
}

export default SubscriptionPlans
