import {
  CANCEL_SUBSCRIPTION,
  CONFIRM_EMAIL,
  DELETE_COMPANY,
  DELETE_ENTITY,
  FILE_UPLOAD,
  FILE_URL,
  FIND_ALL_MESSAGES,
  GENERATE_INVOICE,
  GET_BILLING_PLANS,
  GET_CHATS_LIST,
  GET_CHATS_LIST_NOT_CLOSED,
  GET_CHAT_DETAILS,
  GET_CHAT_DETAILS_ON_SCROLL,
  GET_COMPANY_DATA,
  GET_CURRENT_SUBSCRIPTION,
  GET_CURRENT_USER,
  GET_INVITATION_LINK,
  GET_INVITE_DETAILS,
  GET_OPEN_DATA,
  GET_TABLE_DATA,
  GET_TABLE_ITEM_DETAILS,
  INITIALIZE_APP,
  INPUT_FILES_ADD,
  INPUT_FILES_CLEAR,
  INPUT_FILE_REMOVE,
  INVITATIONS_RESEND,
  INVITATIONS_SEND,
  POPUP_ALERT_HIDE,
  POPUP_ALERT_HIDE_ALL,
  POPUP_ALERT_SHOW,
  REQUEST_RESET_PASSWORD,
  RESET_FORM_VALUES,
  RESET_FOUND_MESSAGES,
  RESET_NEW_CHAT_HISTORY_NOTIFICATIONS,
  RESET_PASSWORD,
  SEARCH_CHAT_MESSAGE,
  SET_CHAT_MESSAGE,
  SET_CONTACT_NAME_TO_CHAT,
  SET_CONTACT_ONLINE_STATUS,
  SET_DATA_TYPE,
  SET_EVENT_TO_CHAT_HISTORY,
  SET_FORM_VALUES,
  SET_IS_EVENT_JUST_SENT,
  SET_NEW_CHAT_HISTORY_NOTIFICATION,
  SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL,
  SIDEBAR_ITEM_RESET,
  SIDEBAR_ITEM_SET,
  SIDEBAR_UPSERT,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  TOGGLE_DRAWER_EXTENDED,
  TOGGLE_IS_USER_ONLINE,
  TOGGLE_NAV_MENU_EXTENDED,
  UPDATE_COMPANY_DATA,
  UPDATE_CURRENT_PLAN,
  VERIFY_TOKEN,
} from './constants'

// COMMON
const popupAlertShow = ({
  id = Date.now().toString(),
  type,
  contentKey,
  contentParams,
  content,
  iconName,
  timeout = 4000,
  stacked,
  withCloseButton,
  className,
}) => ({
  type: POPUP_ALERT_SHOW,
  payload: {
    id,
    type,
    contentKey,
    contentParams,
    content,
    iconName,
    timeout,
    stacked,
    withCloseButton,
    className,
  },
})
const popupAlertHide = (id) => ({ type: POPUP_ALERT_HIDE, id })
const popupAlertHideAll = () => ({ type: POPUP_ALERT_HIDE_ALL })
const initializeApp = (navigate, location, changeLanguage) => ({
  type: INITIALIZE_APP,
  navigate,
  location,
  changeLanguage,
})
const verifyToken = () => ({ type: VERIFY_TOKEN })
const toggleNavMenuExtended = (extendedValue) => ({ type: TOGGLE_NAV_MENU_EXTENDED, extendedValue })
const toggleDrawerExtended = (extendedValue) => ({ type: TOGGLE_DRAWER_EXTENDED, extendedValue })

// DATA
export const setDataType = (type) => ({ type: SET_DATA_TYPE, payload: { type } })
export const getTableData = ({
  type,
  search,
  searchFields,
  sortString,
  queryParams,
  pageLimit,
  pageOffset,
  isSystemData,
  status,
  noReduxSet,
  apiUrlParam,
  id,
}) => ({
  type: GET_TABLE_DATA,
  payload: {
    type,
    search,
    searchFields,
    sortString,
    queryParams,
    pageLimit,
    pageOffset,
    isSystemData,
    status,
    noReduxSet,
    apiUrlParam,
    id,
  },
})
export const getOpenData = ({ type, queryParams }) => ({
  type: GET_OPEN_DATA,
  payload: { type, queryParams },
})

// SIDEBAR
const getTableItemDetails = (id, type) => ({
  type: GET_TABLE_ITEM_DETAILS,
  payload: { type, id },
})
const upsertSidebarItem = (data) => ({ type: SIDEBAR_UPSERT, payload: data })
const sidebarItemReset = () => ({ type: SIDEBAR_ITEM_RESET })
const sidebarItemSet = (item) => ({ type: SIDEBAR_ITEM_SET, item })
const sidebarGenerateInvoice = (bankDetails) => ({ type: GENERATE_INVOICE, bankDetails })
const sendInvitations = (requestBody) => ({ type: INVITATIONS_SEND, requestBody })
const resendInvitations = (id) => ({ type: INVITATIONS_RESEND, id })
const getInvitationLink = (id) => ({ type: GET_INVITATION_LINK, id })
const deleteEntity = (data) => ({ type: DELETE_ENTITY, payload: data })

// COMPANY, BILLING
const getCompanyDataById = (id) => ({ type: GET_COMPANY_DATA, id })
const updateCompanyDataById = (id, values) => ({ type: UPDATE_COMPANY_DATA, id, values })
const getBillingPlans = () => ({ type: GET_BILLING_PLANS })
const updateCurrentPlan = ({
  customer_id,
  plan_id,
  expires_at,
  start_at,
  billing_period,
  payment_type,
  status,
  navigate,
  location,
}) => ({
  type: UPDATE_CURRENT_PLAN,
  payload: { customer_id, plan_id, expires_at, start_at, billing_period, status, payment_type },
  navigate,
  location,
})
const cancelSubscription = () => ({ type: CANCEL_SUBSCRIPTION })
const deleteCompany = (customerId, navigate) => ({ type: DELETE_COMPANY, customerId, navigate })

// USER AND AUTH
const getCurrentUser = (navigate, location, changeLanguage) => ({
  type: GET_CURRENT_USER,
  navigate,
  location,
  changeLanguage,
})
const getCurrentSubscription = () => ({ type: GET_CURRENT_SUBSCRIPTION })
const userSignUp = (credentials, navigate) => {
  const { company_name, company_phone, email, full_name, password, code } = credentials

  return {
    type: SIGN_UP,
    payload: { email, password, full_name, company_name, company_phone, code },
    navigate,
  }
}
const userSignIn = (credentials, navigate) => ({
  type: SIGN_IN,
  payload: credentials,
  navigate,
})
const userLogout = () => ({ type: SIGN_OUT })

const getInviteDetails = (code) => ({ type: GET_INVITE_DETAILS, payload: { code } })

const userConfirm = (userCode) => {
  return {
    type: CONFIRM_EMAIL,
    payload: { code: userCode },
  }
}

const userRequestResetPassword = (email, navigate) => ({
  type: REQUEST_RESET_PASSWORD,
  payload: { email },
  navigate,
})

const userResetPassword = (isUpdate, credentials, navigate) => ({
  type: RESET_PASSWORD,
  isUpdate,
  payload: credentials,
  navigate,
})
const toggleIsUserOnline = (isOnline) => ({ type: TOGGLE_IS_USER_ONLINE, payload: { isOnline } })

//CHATS
const getChatsList = (status, queryParams, isInfiniteScroll, searchValue) => ({
  type: GET_CHATS_LIST,
  payload: { status, queryParams, isInfiniteScroll, searchValue },
})
const getChatsListNotClosed = (searchValue) => ({
  type: GET_CHATS_LIST_NOT_CLOSED,
  searchValue,
})
const setContactNameToChat = ({ contact_name, chat_id }) => ({
  type: SET_CONTACT_NAME_TO_CHAT,
  payload: { contact_name, chat_id },
})
const setEventToChatHistory = (type, data, isReceivedEvent) => ({
  type: SET_EVENT_TO_CHAT_HISTORY,
  payload: { data: data.at ? data : { ...data, at: new Date() }, type, isReceivedEvent },
})
const getChatDetails = ({ chatId }) => ({
  type: GET_CHAT_DETAILS,
  payload: { chatId },
})
const getChatDetailsOnScroll = ({ thresholdMessageId, isScrollUp, searchValue, before }) => ({
  type: GET_CHAT_DETAILS_ON_SCROLL,
  payload: { thresholdMessageId, isScrollUp, searchValue, before },
})
const findAllMessages = (chatId, searchValue) => ({
  type: FIND_ALL_MESSAGES,
  payload: { chatId, searchValue },
})
const searchChatMessage = (chatId, messageId) => ({
  type: SEARCH_CHAT_MESSAGE,
  payload: { chatId, messageId },
})
const resetFoundMessages = () => ({ type: RESET_FOUND_MESSAGES })
const setNewChatHistoryNotificationsInitial = (dataFromLocalStorage) => ({
  type: SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL,
  payload: { dataFromLocalStorage },
})
const setNewChatHistoryNotification = (event) => ({
  type: SET_NEW_CHAT_HISTORY_NOTIFICATION,
  payload: { event },
})
const resetNewChatHistoryNotifications = (chatId) => ({
  type: RESET_NEW_CHAT_HISTORY_NOTIFICATIONS,
  payload: { chatId },
})
const setContactOnlineStatus = (isOnline, contactId) => ({
  type: SET_CONTACT_ONLINE_STATUS,
  payload: { isOnline, contactId },
})
const setChatMessage = (message) => ({ type: SET_CHAT_MESSAGE, payload: { message } })
const setEventJustSent = (eventJustSent) => ({
  type: SET_IS_EVENT_JUST_SENT,
  payload: { eventJustSent },
})

// FILES
const getUploadedData = (newFiles, chatID, memberID, location) => ({
  type: FILE_UPLOAD,
  newFiles,
  chatID,
  memberID,
  location,
})
const getFileUrl = (file, keyName, fileCode) => ({ type: FILE_URL, file, keyName, fileCode })

// LOCAL FILES ACTIONS
const inputFilesAdd = (files, chatID, stateKey = 'filesChosen') => ({
  type: INPUT_FILES_ADD,
  files,
  chatID: chatID,
  stateKey,
})
const inputFileRemove = (id, chatID, stateKey = 'filesChosen') => ({
  type: INPUT_FILE_REMOVE,
  filePreviewId: id,
  chatID: chatID,
  stateKey,
})
const inputFilesClear = () => ({ type: INPUT_FILES_CLEAR })

// FORM
const setFormValues = (values, formName) => ({ type: SET_FORM_VALUES, payload: { values, formName } })
const resetFormValues = (formName) => ({ type: RESET_FORM_VALUES, payload: { formName } })

export {
  cancelSubscription,
  deleteCompany,
  deleteEntity,
  findAllMessages,
  getBillingPlans,
  getChatDetails,
  getChatDetailsOnScroll,
  getChatsList,
  getChatsListNotClosed,
  getCompanyDataById,
  getCurrentSubscription,
  getCurrentUser,
  getFileUrl,
  getInvitationLink,
  getInviteDetails,
  getTableItemDetails,
  getUploadedData,
  initializeApp,
  inputFileRemove,
  inputFilesAdd,
  inputFilesClear,
  popupAlertHide,
  popupAlertHideAll,
  popupAlertShow,
  resendInvitations,
  resetFormValues,
  resetFoundMessages,
  resetNewChatHistoryNotifications,
  searchChatMessage,
  sendInvitations,
  setChatMessage,
  setContactNameToChat,
  setContactOnlineStatus,
  setEventJustSent,
  setEventToChatHistory,
  setFormValues,
  setNewChatHistoryNotification,
  setNewChatHistoryNotificationsInitial,
  sidebarGenerateInvoice,
  sidebarItemReset,
  sidebarItemSet,
  toggleDrawerExtended,
  toggleIsUserOnline,
  toggleNavMenuExtended,
  updateCompanyDataById,
  updateCurrentPlan,
  upsertSidebarItem,
  userConfirm,
  userLogout,
  userRequestResetPassword,
  userResetPassword,
  userSignIn,
  userSignUp,
  verifyToken,
}
