import { pick } from 'dot-object'
import React from 'react'
import { withTheme } from 'styled-components'
import { StyledTableCard } from './styled'

const getValue = (columns, column, data, cellProps) => {
  const value = pick(column?.dataKey || '', data)
  const res = column?.cellRenderer
    ? column.cellRenderer({ cellData: value, column, rowData: data, ...cellProps })
    : value

  return res
}

const CardTableItem = ({
  CardContent,
  cellProps,
  editMode,
  index,
  isClicked,
  onClick,
  style,
  data,
  columns,
  margin,
  rowHeight,
  t,
  theme,
  type,
}) => {
  const getDataForRender = () => {
    return columns?.reduce((acc, column) => {
      return { ...acc, [column.key]: getValue(columns, column, data, cellProps) }
    }, {})
  }

  return (
    <StyledTableCard
      borderColor={theme.color.general.lighter}
      margin={margin}
      rowHeight={rowHeight}
      style={style}
      className={isClicked ? 'clicked' : ''}
    >
      <CardContent
        cellProps={cellProps}
        editMode={editMode}
        index={index}
        onClick={onClick}
        data={getDataForRender()}
        initialData={data}
        columns={columns}
        t={t}
        theme={theme}
        type={type}
      />
    </StyledTableCard>
  )
}

export default withTheme(CardTableItem)
