import getTableColumns from '../config/columns'
import { SEARCH_FIELDS } from '../constants'
import { parse } from 'query-string'

export const getApiSortString = (sortQuery, type, lng) => {
  const sortArrOfAllSortParams = sortQuery.split(',') // ['name_asc', 'id_desc']

  return `sort=${sortArrOfAllSortParams.map((pair) => {
    const urlSortKey = pair.split('-')[0]
    const apiSortKey = getTableColumns(type, lng).find((col) => col.key === urlSortKey)?.dataKey

    return pair.replace(urlSortKey, apiSortKey)
  })}`
}

export const getSearchValueApiQuery = (searchValue, type, searchFields) => {
  const tableSearchField = searchFields || SEARCH_FIELDS[type]

  return [`searchValue=${searchValue}`, `searchFields=${tableSearchField || 'full_name'}`]
}

export const mapUrlToParams = (type, search, searchFields, sortString) => {
  let apiQueryPairs
  let apiQuery = []
  // let searchValue
  // let sortValue
  const searchParsed = parse(search)

  // const firstFilterUrlKey = searchParsed && Object.keys(searchParsed)[0]
  // const firstFilterConfigObj = FILTERS_CONFIG[firstFilterUrlKey]

  // search
  if (searchParsed?.searchValue) {
    // searchValue = searchParsed.searchValue
    apiQueryPairs = getSearchValueApiQuery(searchParsed.searchValue, type, searchFields)
  } else {
    apiQueryPairs = []
  }

  // sorting
  if (sortString || searchParsed?.sort) {
    const apiSortString = getApiSortString(sortString || searchParsed.sort, type)
    // sortValue = value
    apiQueryPairs.push(apiSortString)
  }

  if (apiQueryPairs.length > 0) {
    apiQuery = apiQueryPairs.map((pair) => {
      const pairSplit = pair.split('=')
      return { key: pairSplit[0], value: pairSplit[1] }
    })
  }

  return { apiQuery: apiQuery || [] }
}
