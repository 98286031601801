import React from 'react'
import { withTheme } from 'styled-components'
import ReactTexty from 'react-texty'

import { Typography } from '../../../atomic-design-components'
import { transformDate } from '../../../utils/dates'

const PlanPeriodEndDate = ({ cellData, rowData, theme: { color } }) => {
  const subscription_expire_at = rowData.plan_id === 1 ? '∞' : transformDate(cellData)
  const isPlanExpired = +rowData.planDueMilliseconds < Date.now()

  return (
    <Typography
      color={isPlanExpired && subscription_expire_at !== '∞' ? color.status.error : color.status.success}
      variant="body2"
      as={ReactTexty}
    >
      {subscription_expire_at}
    </Typography>
  )
}

export default withTheme(PlanPeriodEndDate)
