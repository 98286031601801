export const isObject = (value) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export const getObjectFromArray = (array) => {
  const obj = array.reduce((acc, curr) => {
    return { ...acc, ...curr }
  }, {})
  return obj
}
