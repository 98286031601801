import React from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes as T } from 'prop-types'

import { useWidth } from '../../hooks/useWidth'
import Logo from '../Logo'

import { StyledTextWrapper, StyledLine, StyledFooterText } from './styled'
import { Typography } from '../../atomic-design-components'
import LanguageSelect from '../LanguageSelect/'

const TextPagesWrapper = ({ children, text, type, theme }) => {
  const { t } = useTranslation('auth')

  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.lg

  return (
    <StyledTextWrapper type={type} className={`${type}Wrapper`}>
      <div className="headerWrapper">
        <Logo theme={theme} variant="medium" isExtended={!isMobile} isTextPage to="../sign-in" />
        <LanguageSelect isIntroScreensSelect theme={theme} isFullSize={false} />
      </div>
      <StyledLine />
      <Typography
        variant={(isMobile && 'h2') || 'h1'}
        className="text"
        textAlign="left"
        text={t(text || type)}
        margin="0"
      />
      <StyledLine />
      {children}
      <StyledFooterText>
        <Typography variant="body2" className="footerText">
          Skibble {new Date().getFullYear()} © {t('footerText')}
        </Typography>
      </StyledFooterText>
    </StyledTextWrapper>
  )
}

export default TextPagesWrapper

TextPagesWrapper.propTypes = {
  children: T.node,
  text: T.string,
  type: T.string,
  theme: T.object,
  imageUrl: T.string,
}
