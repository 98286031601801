import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { buildRequest, fetchData, showError } from '../../api'
import { MAX_BACKEND_LIMIT } from '../../constants'
import { convertArrayToObject } from '../../utils/dataTransformations'
import { mapUrlToParams } from '../../utils/mapUrlSearchParams'
import {
  GET_OPEN_DATA,
  GET_OPEN_DATA_SUCCESS,
  GET_TABLE_DATA,
  GET_TABLE_DATA_ERROR,
  GET_TABLE_DATA_SUCCESS,
} from '../constants'
import { selectMainData, selectTableTotalItems } from '../selectors'
import { doGetDepartmentMembersAvatars, doGetFileUrl } from './fileUpload'

export function* doGetTableData({
  payload: {
    type,
    isSystemData,
    queryParams = [],
    search,
    searchFields,
    sortString,
    pageLimit,
    pageOffset,
    status,
    noReduxSet,
    apiUrlParam,
    id,
  },
}) {
  try {
    let searchApiQuery
    if ((search && !isSystemData) || sortString) {
      const { apiQuery } = mapUrlToParams(
        type,
        search?.charAt(0) === '?' ? search?.substr(1) : search,
        searchFields,
        sortString
      )
      searchApiQuery = apiQuery
    }

    // pagination
    if ((pageLimit && !isSystemData) || type === 'templates') {
      queryParams.push({ key: 'limit', value: type === 'templates' ? MAX_BACKEND_LIMIT : pageLimit })
    }
    if (pageOffset && !isSystemData) {
      queryParams.push({ key: 'offset', value: pageOffset })
    }

    if (isSystemData) {
      queryParams.push({ key: 'limit', value: MAX_BACKEND_LIMIT })
    }

    if (status) {
      queryParams.push({ key: 'status', value: status })
    }

    const query = searchApiQuery?.length ? queryParams.concat(searchApiQuery) : queryParams

    const request = yield buildRequest({
      apiMethod: 'GET',
      type,
      queryParams: query,
      apiUrlParam,
      id,
    })

    const response = yield fetchData(request)

    yield showError(response)

    let items

    if (type === 'members') {
      items = yield all(
        response.items.map((item) => call(doGetFileUrl, item, 'profile_picture', item.profile_picture))
      )
    } else if (!isSystemData && type === 'departments') {
      items = yield doGetDepartmentMembersAvatars({ departments: response.items })
    } else {
      items = response.items || response
    }

    if (!noReduxSet) {
      yield put({
        type: GET_TABLE_DATA_SUCCESS,
        payload: {
          type,
          isSystemData,
          data: items,
          total: isSystemData ? undefined : response.total,
          apiUrlParam,
        },
      })
    }

    return response.items ? response.items : response
  } catch (error) {
    console.error(error)
    yield put({ type: GET_TABLE_DATA_ERROR })
  }
}

export function* doUpdateTableData(type, updatedItem, isDelete, isCreate) {
  try {
    const currentItems = yield select(selectMainData(type))
    const total = yield select(selectTableTotalItems)

    const isUpdatedItemOnCurrentPage = currentItems?.some((item) => item.id === +updatedItem?.id)

    if (isUpdatedItemOnCurrentPage) {
      yield put({
        type: GET_TABLE_DATA_SUCCESS,
        payload: {
          type,
          total: isDelete ? total - 1 : total,
          data: isDelete
            ? currentItems.filter((item) => item.id !== +updatedItem.id)
            : currentItems.map((item) => (item.id === +updatedItem.id ? updatedItem : item)),
        },
      })
    }

    if (isCreate) {
      yield put({
        type: GET_TABLE_DATA_SUCCESS,
        payload: {
          type,
          total: total + 1,
          data: [updatedItem, ...currentItems],
        },
      })
    }
  } catch (error) {
    console.error(error)
  }
}

function* doGetOpenData({ payload: { type, queryParams } }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'GET',
      type,
      queryParams,
      isOpenData: true,
    })

    const response = yield fetchData(request)

    yield showError(response)

    const transformedData = Array.isArray(response) ? convertArrayToObject(response) : response

    yield put({ type: GET_OPEN_DATA_SUCCESS, payload: { type, data: transformedData } })
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  return [
    yield takeEvery(GET_TABLE_DATA, doGetTableData),
    yield takeEvery(GET_OPEN_DATA, doGetOpenData),
    // yield takeEvery(UPDATE_TABLE_DATA, doUpdateTableData),
  ]
}
