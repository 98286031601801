import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'

import { Button } from '../../../atomic-design-components'
import { useMappedState, usePrevious } from '../../../hooks/useReactRedux'
import { useTimeout } from '../../../hooks/useTimeout'
import { getChatDetails, getChatDetailsOnScroll, setEventJustSent } from '../../../redux-saga/actions'
import {
  selectCurrentChatHistory,
  selectIsDrawerExtended,
  selectEventJustSent,
  selectIsNavMenuExtended,
} from '../../../redux-saga/selectors'
import ChatAction from './ChatAction'
import { StyledChatArea } from './styled'

const ChatArea = ({
  foundMessagesIds,
  gridRef,
  eventJustReceived,
  setEventJustReceived,
  searchValueCount,
  setSearchValueCount,
  searchValue,
  t,
  theme,
}) => {
  const dispatch = useDispatch()
  const { chatId } = useParams()
  // const gridRef = useRef(false)
  const followOutput = useRef(true)
  const isFirstToBottomScrollDone = useRef(false)
  const scrollOnOpenTimeoutIdRef = useRef(null)
  const ref = useRef(null)

  const eventJustSent = useMappedState(selectEventJustSent)

  const {
    chatHistory,
    chatHistoryCount,
    inProgressHistoryOnScroll,
    inProgressSearchMessage,
    isHistoryFirstLoadWithPictures,
    lastMessageId,
    allMessagesLoadedUp,
    allMessagesLoadedDown,
    limit,
  } = useMappedState(selectCurrentChatHistory)

  const prevSearchValueCount = usePrevious(searchValueCount)
  const prevChatHistoryCount = usePrevious(chatHistoryCount)

  // const lastItemId = useRef(chatHistory[chatHistory.length - 1]?.id)

  const foundMessageIdSelected = foundMessagesIds?.length && foundMessagesIds[searchValueCount - 1]

  const [atBottom, setAtBottom] = useState(false)
  const [firstItemIndex, setFirstItemIndex] = useState(chatHistoryCount - chatHistory.length)
  const [width, setWidth] = useState(0)

  const isMenuOpened = useMappedState(selectIsNavMenuExtended)
  const isPanelOpened = useMappedState(selectIsDrawerExtended)

  const scrollToTheLastItem = (behavior) => {
    if (!chatHistory.some((item) => item.id === lastMessageId)) {
      dispatch(getChatDetails({ chatId }))
    }

    // console.log('to last')
    if (gridRef?.current?.scrollToIndex) {
      gridRef.current.scrollToIndex({
        index: chatHistory?.length - 1,
        behavior: behavior || 'auto',
        align: 'end',
      })
    }
  }

  useEffect(() => {
    let timeoutId
    if (!isFirstToBottomScrollDone.current) {
      timeoutId = setTimeout(() => {
        scrollToTheLastItem('auto')
        isFirstToBottomScrollDone.current = true
        // console.log('timeoutId')
      }, 50)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const prependItems = useCallback(
    (index) => {
      if (!firstItemIndex || allMessagesLoadedUp || inProgressSearchMessage || inProgressHistoryOnScroll) {
        return
      }

      followOutput.current = false

      setFirstItemIndex(() => (firstItemIndex > limit ? firstItemIndex - limit : firstItemIndex))
      dispatch(getChatDetailsOnScroll({ isScrollUp: true }))
      // console.log('startReached')
      return false
    },
    [firstItemIndex, allMessagesLoadedUp, inProgressSearchMessage, inProgressHistoryOnScroll]
  )

  const loadMore = () => {
    if (allMessagesLoadedDown) {
      return
    }
    // console.log('load down')
    dispatch(getChatDetailsOnScroll({ isScrollUp: false }))
  }

  const onScrollToBottomClick = () => {
    if (searchValueCount > 0) {
      // dispatch(getChatDetails({ chatId }))
      setSearchValueCount(0)
    } else {
      scrollToTheLastItem()
    }
  }

  useEffect(() => {
    if (foundMessagesIds?.length && followOutput.current) {
      followOutput.current = false
      isFirstToBottomScrollDone.current = true
    }
  }, [foundMessagesIds])

  useEffect(() => {
    let timeoutId

    if (!inProgressSearchMessage) {
      if (prevSearchValueCount && searchValueCount === 0) {
        // dispatch(getChatDetails({ chatId }))
        // console.log('searchValueCount')
        scrollToTheLastItem()
      } else if (foundMessageIdSelected) {
        const indexToScrollTo = chatHistory?.findIndex((item) => item.id === foundMessageIdSelected)
        // console.log('indexToScrollTo')
        if (indexToScrollTo !== -1 && chatHistory[indexToScrollTo]) {
          timeoutId = setTimeout(() => {
            gridRef.current.scrollToIndex({
              index: indexToScrollTo,
              behavior: 'auto',
              // align: 'start',
            })
          }, 100)
        }
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [foundMessageIdSelected, prevSearchValueCount, inProgressSearchMessage])

  useTimeout(
    () => {
      setWidth(ref.current.offsetWidth)
    },
    300,
    [isMenuOpened, isPanelOpened],
    true
  )

  return (
    <StyledChatArea id="chat-area" ref={ref}>
      <Virtuoso
        style={{ height: '100%' }}
        data={chatHistory}
        ref={gridRef}
        // alignToBottom
        firstItemIndex={firstItemIndex}
        startReached={prependItems}
        endReached={loadMore}
        // atTopThreshold={100}
        initialTopMostItemIndex={chatHistory?.length - 1}
        // atBottomThreshold={100}
        atBottomStateChange={(bottom) => {
          setAtBottom(bottom)
          if (bottom) {
            clearTimeout(scrollOnOpenTimeoutIdRef.current)
            scrollOnOpenTimeoutIdRef.current = null
          }
        }}
        atTopStateChange={(top) => {
          if (searchValueCount && top && !allMessagesLoadedUp && firstItemIndex < limit) {
            // console.log('atTopStateChange')
            dispatch(getChatDetailsOnScroll({ isScrollUp: true }))
          }
        }}
        // increaseViewportBy={{ top: 100, bottom: 100 }}
        followOutput={(isAtBottom) => {
          if (eventJustSent && chatHistoryCount === prevChatHistoryCount + 1) {
            document.getElementById(`chatTextarea-${chatId}`)?.focus()
            dispatch(setEventJustSent(''))
            return true
          }

          if (isAtBottom && eventJustReceived) {
            setEventJustReceived('')
            return true
          }

          return false
        }}
        itemContent={(index, historyEvent) => (
          <ChatAction
            key={index}
            data={historyEvent}
            searchValue={searchValue}
            t={t}
            theme={theme}
            areaWidth={width}
          />
        )}
      />
      <Button
        className={clsx(atBottom ? 'hide' : 'displayed', 'scrollButton')}
        variant="secondary"
        hoverType="opacity"
        borderRadius="50%"
        iconRightProps={{ name: 'paginationArrow', width: 16, height: 16, margin: '0' }}
        onClick={onScrollToBottomClick}
        disabled={atBottom}
      />
    </StyledChatArea>
  )
}

export default ChatArea
