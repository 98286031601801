import clsx from 'clsx'
import React from 'react'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { Icon, Loader, Typography } from '../../atomic-design-components'
import { formatSizeUnits } from '../../utils/formatMeasureUnits'
import { StyledUploadedItem } from './styled'

const UploadedItem = ({
  error,
  fileName,
  fileSize,
  fnDelete = () => null,
  isDeleted,
  loading,
  saved,
  src,
  theme,
}) => {
  if (!src) {
    return null
  }

  const transformedSize = formatSizeUnits(fileSize)
  const fileNameOnly = fileName.split('.')[0]
  const fileType = '.' + fileName.split('.')[1]

  return (
    <>
      <StyledUploadedItem className={clsx(error && 'error', saved && 'saved', isDeleted && 'isDeleted')}>
        <Typography as={ReactTexty} variant="caption3" text={fileNameOnly} color={theme.color.general.dark} />
        <Typography as={ReactTexty} variant="caption3" text={fileType} color={theme.color.general.dark} />

        <Typography
          variant="caption3"
          text={transformedSize}
          color={theme.color.general.dark}
          className="size"
        />

        <Icon name="cross" size={12} fill={theme.color.general.dark} onClick={() => fnDelete(src)} />
        {loading && <Loader />}
      </StyledUploadedItem>
    </>
  )
}

export default withTheme(UploadedItem)
