export const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB'
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB'
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB'
  } else if (bytes > 1) {
    bytes = bytes + ' bytes'
  } else if (bytes === 1) {
    bytes = bytes + ' byte'
  } else {
    bytes = '0 bytes'
  }
  return bytes
}

export const formatTime = ({ seconds, t }) => {
  const hrs = ~~(seconds / 3600)
  const mins = ~~((seconds % 3600) / 60)
  const secs = ~~seconds % 60

  let ret = ''

  if (seconds === 0) {
    return `0${t('sec')}`
  }

  if (hrs > 0) {
    ret += '' + hrs + t('h')
  }

  ret += mins ? ' ' + mins + t('min') : ''
  ret += ' ' + secs + t('sec')

  return ret
}
