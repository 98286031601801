import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { sidebarGenerateInvoice } from '../../../redux-saga/actions'

import Form from '../../../components/Form'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectSidebarDetails } from '../../../redux-saga/selectors'
import theme from '../../../theme'

const StyledForm = styled(Form)`
  .singleFormTitle.customerInfo {
    border: none !important;
  }
`

const CustomersInvoice = () => {
  const dispatch = useDispatch()

  const customerDetails = useMappedState(selectSidebarDetails)

  const formDataInitial = {
    company_name: customerDetails?.company_name,
    email: customerDetails?.email,
    full_name: customerDetails?.owner?.full_name,
    EDRPOU: '',
    IBAN: '',
    TIN: '',
    amount: '',
    purposeOfPayment: '',
  }

  const onFormSubmit = (valuesChanged) => {
    // dispatch(sidebarGenerateInvoice({}))
  }

  const iconProps = { name: 'dollarSign', margin: '0 6px 0', fill: theme.color.general.white }

  return (
    <div>
      <StyledForm
        initialValues={formDataInitial}
        formConfig={FORM_CONFIGS.customersInvoice}
        validationRules={FORM_CONFIGS.customersInvoice.validationRules}
        onSubmit={onFormSubmit}
        isSubmitButtonAlwaysShown
        expansionPanelProps={{
          disabled: true,
          withChevronInHeader: false,
        }}
        withActions
        saveBtnIconProps={iconProps}
        saveBtnTextKey="send"
      />
    </div>
  )
}

export default CustomersInvoice
