import styled from 'styled-components'

export const StyledNotifications = styled.div`
  max-width: 660px;
  margin: 10px auto;
  padding: 20px;
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .pageTitle {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  .timezoneInput {
    margin-bottom: 10px;
    font-size: 15px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin: 20px 30px;
  }
`

export const StyledSwitchableCardWithFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .fieldsContainer {
    .fields:not(.new_chat_non_working_hours) {
      > *:first-child,
      > *:last-child {
        width: 100%;
      }
    }
    border: 1px solid ${({ theme }) => theme.color.general.lighter};
    padding: 15px;
    border-radius: 3px;

    &.isCardDisabled {
      display: none;
    }
    .fields {
      .inputContainer {
        width: 100%;
        margin: 0 !important;
        .selectWrapper {
          width: 100%;
        }
      }
      > .error.label {
        padding: 0;
      }
    }
  }
`
