export const DEFAULT_RIGHT_PANEL_DATA = {
  '/members/invite': {
    email: '',
  },
  '/my-company/departments/new': {
    name: '',
    members: [],
  },
}

export const DEFAULT_TABLE_SORTING = {
  contacts: { fullName: 'asc' },
  customers: { status: 'asc', created: 'desc' },
  departments: { status: 'asc', name: 'asc' },
  members: { status: 'asc', role: 'asc', fullName: 'asc' },
  widgets: { status: 'asc', name: 'asc' },
  billingPlans: { status: 'asc', planTitle: 'asc' },
  bulkMessaging: { status: 'asc', name: 'asc' },
  // payments: { status: 'asc', created: 'desc' },
  // invoices: { status: 'asc', created: 'desc' },
}

export const DEFAULT_WIDGET_DATA = {
  name: '',
  status: 'active',
  install_status: 'not_installed',
  license_id: '',
  channels: {
    live_chat: {
      license_id: '',
      status: 'inactive',
    },
    telegram: {
      token: '',
      bot_name: '',
      status: 'inactive',
    },
    viber: {
      token: '',
      bot_name: '',
      status: 'inactive',
    },
    facebook: {
      page_name: '',
      status: 'inactive',
    },
    instagram: {
      page_name: '',
      status: 'inactive',
    },
  },
}

const defaultPlanValue = {
  id: '',
  is_custom: true,
  titles: { uk: '' },
  prices: { price_monthly: { UAH: '' }, price_annually: { UAH: '' } },
  features: { is_branding: true, is_customization: true, is_support: false },
}

export const defaultBulkMessaging = {
  name: '',
  tags: [],
  channels: [],
  is_scheduled: false,
  scheduled_at: new Date(),
  status: 'draft',
  message: '',
  files: [],
}

export const DEFAULT_VALUES_DATA = {
  subscriptionPlans: defaultPlanValue,
  bulkMessaging: defaultBulkMessaging,
}

export const defaultAutoMessagesValue = {
  live_chat_delayed: {
    first_message: { status: 'inactive', delay: null, text: '' },
    second_message: { status: 'inactive', delay: null, text: '' },
    idle_operator: { status: 'inactive', delay: null, text: '' },
    idle_guest: { status: 'inactive', delay: null, text: '' },
  },
  all_channels_instant: {
    first_message: { status: 'inactive', text: '' },
    second_message: { status: 'inactive', text: '' },
    non_working_hours: { status: 'inactive', text: '' },
  },
}
