import React from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { calculateImageHeight } from '../../../utils/imageProcessing'
import { StyledActions, StyledImage, StyledImageAsBackground, StyledImageWithActionsWrapper } from './styled'

const Image = ({
  actionIcons,
  actionIconsType,
  alignItems,
  alt,
  asBackground,
  backgroundSize,
  children,
  className,
  height,
  justifyContent,
  imageRef,
  maxHeight,
  maxWidth,
  radius,
  src,
  type,
  width,
  ...otherProps
}) => {
  return asBackground ? (
    actionIcons ? (
      <StyledImageWithActionsWrapper radius={radius} {...otherProps}>
        <StyledImageAsBackground
          src={src}
          height={height}
          width={width}
          className={clsx(className, 'image')}
          backgroundSize={backgroundSize}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          {...otherProps}
        >
          <StyledActions className={clsx(actionIconsType, 'imageActions')} radius={radius}>
            {actionIcons}
          </StyledActions>
        </StyledImageAsBackground>
      </StyledImageWithActionsWrapper>
    ) : (
      <StyledImageAsBackground
        src={src}
        height={height}
        width={width}
        radius={radius}
        className={clsx(className, 'image')}
        backgroundSize={backgroundSize}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        {...otherProps}
      />
    )
  ) : (
    <StyledImage
      height={calculateImageHeight(width, height, type)}
      width={width}
      radius={radius}
      className={className}
      alignItems={alignItems}
      justifyContent={justifyContent}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      ref={imageRef}
      {...otherProps}
    >
      <img src={src} alt={alt} />
      {children}
    </StyledImage>
  )
}

Image.propTypes = {
  actionIcons: T.bool,
  actionIconsType: T.oneOf(['permanent', 'onHover']),
  alignItems: T.oneOf(['start', 'end', 'center', 'stretch']),
  alt: T.string.isRequired,
  asBackground: T.bool,
  backgroundSize: T.string,
  children: T.node,
  className: T.string,
  height: T.oneOfType([T.number, T.string]),
  justifyContent: T.oneOf([
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
  ]),
  loading: T.oneOf(['lazy']),
  margin: T.string,
  maxHeight: T.oneOf([T.number, T.string]),
  maxWidth: T.oneOf([T.number, T.string]),
  radius: T.oneOfType([T.number, T.string]),
  src: T.string.isRequired,
  type: T.oneOf(['square', 'horizontal', 'vertical', 'custom']),
  width: T.oneOfType([T.number, T.string]),
}

Image.defaultProps = {
  actionIconsType: 'permanent',
  alignItems: 'center',
  alt: '',
  backgroundSize: 'contain',
  justifyContent: 'center',
  radius: '',
  src: '',
}

export default Image
