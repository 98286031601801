// user related
export const selectWholeUserState = (state) => state.user
export const selectUserDetails = (state) => state.user.details
export const selectUserName = (state) => state.user.details.member_full_name
export const selectUserMemberId = (state) => state.user.details.member_id
export const selectUserCompanyId = (state) => state.user.details.customer_id
export const selectUserDepartments = (state) => {
  const userMemberId = selectUserMemberId(state)
  const memberObject = state.members?.[userMemberId]

  return memberObject?.departments?.map((dep) => dep.id)?.filter((dep) => dep)
}
export const selectUserNameWithPic = (state) => ({
  name: state.user.details.member_full_name,
  pic: state.user.details.member_profile_picture,
})
export const selectUserRole = (state) => state.user.details.role
export const selectUserToken = (state) => state.user.token
export const selectUserInviteDetails = (state) => state.user.inviteDetails
export const selectUserSystemParams = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  isAppInitialized: state.user.isAppInitialized,
  inProgress: state.user.inProgress,
})
export const selectIsUserOnline = (state) => state.user.isUserOnline
export const selectResetPassMetaData = (state) => ({
  inProgress: state.user.resetPassInProgress,
  error: state.user.error,
})
export const selectCurrentSubscription = (state) => state.user.subscriptionDetails

// company related
export const selectCompanyData = (state) => state.company.general
export const selectCompanyId = (state) => state.company.general?.id
export const selectCompanyMetaData = (state) => ({
  inProgress: state.company.inProgress,
  error: state.company.error,
})
export const selectBillingPlans = (state) => state.company.billingPlans

// unsorted
export const selectTableTotalItems = (state) => state.data.total_items
export const selectMainDataLoading = (state) => state.data.inProgress
export const selectMainDataInitialized = (state) => state.data.isDataInitialized
export const selectMainData = (type) => (state) => state.data[type]

export const selectDataType = (state) => state.data.type

export const selectSidebarInitial = (state) => state.sidebar.initial
export const selectSidebarDetails = (state) => state.sidebar.details
export const selectSidebarDetailsStatus = (state) => state.sidebar.details?.status
export const selectSidebarMetaData = (state) => ({
  inProgress: state.sidebar.sidebarInProgress,
  error: state.sidebar.error,
})

export const selectOpenData = (type) => (state) => state.data[type]

export const selectPopupAlerts = (state) => state.common.popupAlerts
export const selectNotifications = (state) => state.common.notifications
export const selectIsNavMenuExtended = (state) => state.common.isNavMenuExtended
export const selectIsDrawerExtended = (state) => state.common.isDrawerExtended

export const selectAllChatsLists = (state) => ({
  new: state.chats.new,
  open: state.chats.open,
  closed: state.chats.closed,
})
export const selectChatsList = (status) => (state) => state.chats[status]
export const selectCurrentChatInfo = (state) => state.chats.chatInfo
export const selectCurrentChatChannel = (state) => state.chats.chatInfo?.channel
export const selectCurrentChatContactUid = (state) => state.chats.chatInfo.contact_uid
export const selectCurrentChatStatus = (state) => state.chats?.chatInfo?.status
export const selectCurrentChatId = (state) => state.chats?.chatInfo?.id
export const selectClosedCount = (state) => state.chats.closedCount
export const selectCurrentChatHistory = (state) => ({
  chatHistory: state.chats.chatHistory,
  chatHistoryCount: state.chats.chatHistoryCount,
  inProgressHistory: state.chats.inProgressHistory,
  allMessagesLoadedUp: state.chats.allMessagesLoadedUp,
  allMessagesLoadedDown: state.chats.allMessagesLoadedDown,
  inProgressSearchMessage: state.chats.inProgressSearchMessage,
  limit: state.chats.limit,
  lastMessageId: state.chats.lastMessageId,
  isHistoryFirstLoadWithPictures: state.chats.isHistoryFirstLoadWithPictures,
})
export const selectCurrentChatHistoryCount = (state) => state.chats.chatHistoryCount
export const selectContactsOnline = (state) => state.chats.contactsOnline
export const selectIsContactOnline = (id) => (state) => {
  const contactId = id || state.chats.chatInfo?.contact_id
  return contactId && state.chats.contactsOnline?.[contactId]
}
export const selectChatsClosedInProgress = (state) => state.chats.inProgressClosed
export const selectChatsNotClosedInProgress = (state) => state.chats.inProgressNotClosed
export const selectChatsError = (state) => state.chats.error
export const selectFoundMessagesIds = (state) => state.chats.foundMessagesIds
export const selectLastMessageIdCurrent = (state) =>
  state.chats.chatHistory[state.chats.chatHistory.length - 1]?.id
export const selectLastHistoryMessageId = (state) => state.chats.lastMessageId
export const selectFirstMessageId = (state) => state.chats.chatHistory[0]?.id
export const selectIsNoChatsFound = (state) => {
  const { new: newChats, open: openChats, closed: closedChats } = state.chats
  return !newChats.length && !openChats.length && !closedChats.length
}
export const selectChatMessage = (state) => state.chats.chatMessage
export const selectSetEventToHistoryInProgress = (state) => state.chats.inProgressSetEventToHistory
export const selectEventJustSent = (state) => state.chats.eventJustSent

export const selectFilesUpload = (state) => state.fileUpload
export const selectFilesDraft = (state) => state.fileUpload.filesChosen
export const selectFilesUploaded = (state) => state.fileUpload.filesUploaded
export const selectNewChatNotifications = (state) => state.chats.newChatHistoryNotifications
export const selectFileUrlsLoaded = (state) => state.fileUpload.fileUrlsLoadedByCode

export const selectFormValues = (state) => state.forms.formValues
