import React from 'react'

export const eyeSign = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
    <path
      d="M15.1199 7.23345C15.3094 7.44345 15.4143 7.71628 15.4143 7.99916C15.4143 8.28205 15.3094 8.55488 15.1199 8.76488C13.9199 10.0563 11.1885 12.5706 7.99989 12.5706C4.81132 12.5706 2.07989 10.0563 0.879894 8.76488C0.690362 8.55488 0.585449 8.28205 0.585449 7.99916C0.585449 7.71628 0.690362 7.44345 0.879894 7.23345C2.07989 5.94202 4.81132 3.42773 7.99989 3.42773C11.1885 3.42773 13.9199 5.94202 15.1199 7.23345Z"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99983 10.2843C9.26219 10.2843 10.2855 9.26097 10.2855 7.99861C10.2855 6.73624 9.26219 5.71289 7.99983 5.71289C6.73746 5.71289 5.71411 6.73624 5.71411 7.99861C5.71411 9.26097 6.73746 10.2843 7.99983 10.2843Z"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
