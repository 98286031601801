import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import ReactHelmet from '../../wrappers/ReactHelmet'

import TableBlock from '../../components/TableBlock'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectMainData } from '../../redux-saga/selectors'
import GridLayout from '../Layout/GridLayout'
import BulkMessagingTableCard from './components/BulkMessagingTableCard'

const BulkMessaging = () => {
  const { t } = useTranslation('all')
  const params = useParams()
  const urlId = params?.rightPanelId

  const navigate = useNavigate()
  const data = useMappedState(selectMainData('bulkMessaging')) || []

  return (
    <>
      <ReactHelmet title="bulkMessagingTitle" />

      <GridLayout RightPanelComponent={urlId && <Outlet />} RightPanelMinWidth="300px">
        <TableBlock
          tableData={data}
          navbarProps={{
            buttonProps: {
              onClick: () => {
                navigate({ pathname: 'new' })
              },
              text: t('newBulkMessage'),
              iconName: 'messages',
              padding: '13px 15px',
              iconSize: 20,
            },
          }}
          TableCardContent={BulkMessagingTableCard}
          activeRowId={urlId}
          type="bulkMessaging"
          tableCardHeight={120}
        />
      </GridLayout>
    </>
  )
}

export default BulkMessaging
