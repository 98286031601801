import React from 'react'
import styled from 'styled-components'

import NameWithAvatar from '../../../components/NameWithAvatar'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectSidebarDetails } from '../../../redux-saga/selectors'
import { FlexRow, Typography } from '../../../atomic-design-components'

const StyledOwnerBlock = styled.div`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
  border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
  padding-bottom: 20px;
  margin-top: 20px;
`

const StyledResetPassword = styled(Typography)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${({ theme }) => theme.color.primary.main};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const CustomerOwner = ({ t }) => {
  const formDataInitial = useMappedState(selectSidebarDetails)

  return (
    <StyledOwnerBlock className="owner">
      <Typography margin="20px 20px 10px" variant="h4" text={t('owner')} />
      <FlexRow padding="0 20px">
        <NameWithAvatar
          underText={formDataInitial?.owner_email}
          avatarSrc={formDataInitial?.owner_profile_picture}
          avatarSize={44}
          name={formDataInitial?.owner_full_name}
          typographyVariant="caption1"
        />
      </FlexRow>
    </StyledOwnerBlock>
  )
}

export default CustomerOwner
