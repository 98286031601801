import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import { FlexRow, Icon, Label } from '../../../atomic-design-components'
import { findAllMessages, resetFoundMessages, searchChatMessage } from '../../../redux-saga/actions'
import { StyledSearchInput } from './styled'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectCurrentChatHistoryCount } from '../../../redux-saga/selectors'
import { MAX_SEARCH_INPUT_LENGTH } from '../../../constants'

const SearchInChatMessage = ({
  chatId,
  foundMessagesIds,
  searchButtonClicked,
  searchValueCount,
  setSearchValueCount,
  searchValue,
  setSearchValue,
  ...otherProps
}) => {
  const dispatch = useDispatch()

  const foundMessagesIdsCount = foundMessagesIds?.length || 0

  const chatHistoryCount = useMappedState(selectCurrentChatHistoryCount)

  // const prevSearchValueCount = usePrevious(searchValueCount)

  useEffect(() => {
    if (searchValueCount > 0) {
      dispatch(searchChatMessage(chatId, foundMessagesIds[searchValueCount - 1]))
    }
  }, [searchValueCount])

  const doSearch = (searchValue) => {
    if (!chatHistoryCount) {
      return
    }
    setSearchValue(searchValue)
    setSearchValueCount(0)
    if (searchValue) {
      dispatch(findAllMessages(chatId, searchValue))
    } else {
      dispatch(resetFoundMessages())
    }
  }

  const onInputChange = (e) => {
    const { value } = e.target
    doSearch(value)
  }

  const { search } = useLocation()
  const searchParsed = parse(search)
  const leftPanelSearchValue = searchParsed.searchValue || ''

  useEffect(() => {
    doSearch(leftPanelSearchValue)
  }, [leftPanelSearchValue])

  const onSearchClean = () => {
    onInputChange({ target: { value: '' } })
    // dispatch(getChatDetails({ chatId }))
  }

  const onSearchButtonClick = (direction) => () => {
    searchButtonClicked.current = true
    if (direction === 'up') {
      setSearchValueCount((prev) => prev + 1)
    }
    if (direction === 'down') {
      setSearchValueCount((prev) => prev - 1)
    }
  }

  const onEnterClick = () => {
    if (searchValueCount < foundMessagesIdsCount) {
      setSearchValueCount((prev) => (prev || 0) + 1)
    }
  }

  return (
    <>
      <StyledSearchInput
        // withDebounce
        // primaryFocusColor
        maxLength={MAX_SEARCH_INPUT_LENGTH}
        value={searchValue}
        onChange={onInputChange}
        onEnter={onEnterClick}
        {...otherProps}
      >
        {searchValue && (
          <Label size="medium">
            {searchValueCount}/{foundMessagesIdsCount}
          </Label>
        )}
      </StyledSearchInput>
      {searchValue && (
        <FlexRow className="searchManipulation">
          {/*earlier*/}
          <Icon
            disabled={searchValueCount === foundMessagesIdsCount}
            name="chevronUpThin"
            size={16}
            margin="0 10px 0 0"
            onClick={onSearchButtonClick('up')}
          />
          {/*later*/}
          <Icon
            disabled={!searchValueCount}
            name="chevronDownThin"
            size={16}
            margin="0 10px 0 0"
            onClick={onSearchButtonClick('down')}
          />
          <Icon name="cross" margin="0 10px 0 0" onClick={onSearchClean} />
        </FlexRow>
      )}
    </>
  )
}

export default SearchInChatMessage
