import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'

import { Button, Typography } from '../../atomic-design-components'
import { COMPANY_NOTIFICATIONS } from '../../constants'
import { useMappedState } from '../../hooks/useReactRedux'
import { getCompanyDataById, getTableData, updateCompanyDataById } from '../../redux-saga/actions'
import { selectCompanyData, selectMainData, selectUserCompanyId } from '../../redux-saga/selectors'
import { getObjectFromArray } from '../../utils'
import transformTime from '../../utils/transformTime'
import SwitchableCardWithFields from './components/SwitchableCardWithFields'
import { StyledNotifications } from './styled'

const DELAY = {
  key: 'delay',
  label: 'delayMS',
  iconLeftProps: { name: 'clock' },
  required: true,
  min: 0,
  placeholder: '00:00',
}

const EMAILS = {
  key: 'emails',
  label: 'emails',
  valueKey: 'label',
  component: 'dropdown',
  isMulti: true,
  isCreatable: true,
  isClearable: true,
  getIsHidden: (props) => !props.specified_emails,
}

const formConfig = {
  no_response: {
    tip: 'no response',
    fields: [
      DELAY,
      { component: 'checkbox', key: 'chat_owner', label: 'chat_owner' },
      { component: 'checkbox', key: 'administrators', label: 'administrators' },
      { component: 'checkbox', key: 'specified_emails', label: 'specified_emails' },
      EMAILS,
    ],
  },
  new_chat_working_hours: {
    tip: 'new chat',
    fields: [
      DELAY,
      { component: 'checkbox', key: 'operators', label: 'operators' },
      { component: 'checkbox', key: 'administrators', label: 'administrators' },
      { component: 'checkbox', key: 'specified_emails', label: 'specified_emails' },
      EMAILS,
    ],
  },
  new_chat_non_working_hours: {
    tip: 'new chat',
    fields: [
      { component: 'checkbox', key: 'operators', label: 'operators' },
      { component: 'checkbox', key: 'administrators', label: 'administrators' },
      { component: 'checkbox', key: 'specified_emails', label: 'specified_emails' },
      EMAILS,
    ],
  },
}

const changeValue = (object, blockKey, key, newValue) => {
  object[blockKey][key] = newValue
  return object
}

const transformValues = (values, forSave, delayFromSeconds) => {
  Object.keys(values).forEach((blockKey) => {
    const emails = forSave
      ? values[blockKey].emails.map((el) => el.label)
      : values[blockKey].emails.map((el) => (typeof el === 'object' ? el : { id: el, label: el }))

    changeValue(values, blockKey, 'emails', emails)
    changeValue(values, blockKey, 'delay', transformTime(values[blockKey].delay, delayFromSeconds))
  })
  return values
}

const MyCompanyNotifications = ({ theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')

  const companyData = useMappedState(selectCompanyData)
  const currentUserCompanyId = useMappedState(selectUserCompanyId)
  const members = useMappedState(selectMainData('members')) || []

  useEffect(() => {
    dispatch(getCompanyDataById(currentUserCompanyId))
    dispatch(
      getTableData({
        type: 'members',
        status: 'active',
        isSystemData: true,
      })
    )
  }, [])

  const initialValues = companyData.notifications || COMPANY_NOTIFICATIONS
  const [updatedValues, setUpdatedValues] = useState(transformValues({ ...initialValues }, false, true))
  const [errors, setErrors] = useState({})
  const optionsData = members?.map((member) => {
    return { id: member.id, label: member.email }
  })

  useEffect(() => {
    setUpdatedValues(transformValues({ ...initialValues }, false, true))
  }, [companyData])

  const onCancel = () => {
    setUpdatedValues({ ...initialValues })
    setErrors({})
  }

  const validateValues = () => {
    return Object.keys(updatedValues).map((blockKey) => {
      if (updatedValues[blockKey].status === 'active') {
        if (!updatedValues[blockKey].delay) {
          setErrors((prev) => ({ ...prev, [blockKey]: { delay: 'required' } }))
        }

        const hasCheckedValue = Object.values(updatedValues[blockKey]).find((el) => el === true)
        if (hasCheckedValue) {
          console.log('updatedValues[blockKey]', updatedValues[blockKey])

          return updatedValues[blockKey].specified_emails
            ? updatedValues[blockKey].emails.length > 0
              ? false
              : { [blockKey]: 'emailsError' }
            : false
        } else {
          return { [blockKey]: 'checkboxError' }
        }
      }
      return false
    })
  }

  const onSave = () => {
    const isNotValid = validateValues()

    if (isNotValid.some((el) => el)) {
      const errors = isNotValid.filter((el) => el)
      const error = getObjectFromArray(errors)

      return setErrors((prev) => ({ ...prev, checkbox: { ...prev.checkbox, ...error } }))
    } else {
      const newValues = transformValues({ ...updatedValues }, true, false)
      dispatch(updateCompanyDataById(companyData.id, { notifications: newValues }))
    }
  }

  return (
    <StyledNotifications>
      <Typography variant="h3" text={t('emailNotifications')} className="pageTitle" />
      {Object.keys(formConfig).map((blockKey, i) => {
        return (
          <SwitchableCardWithFields
            key={i}
            fields={formConfig[blockKey].fields}
            cardKey={blockKey}
            tipText={formConfig[blockKey].tip}
            blockKey={blockKey}
            t={t}
            updatedValues={updatedValues}
            optionsData={optionsData}
            isCardDisabled={updatedValues[blockKey]?.status === 'inactive'}
            setUpdatedValues={setUpdatedValues}
            errors={errors}
            setErrors={setErrors}
          />
        )
      })}
      <div className="buttonWrapper">
        <Button
          variant="general"
          text={t('cancel')}
          confirmButtonProps={{
            onDeleteConfirm: onCancel,
          }}
          fullWidth
          maxWidth="200px"
        />
        <Button
          variant="primary"
          text={t('save')}
          iconLeftProps={{ name: 'floppy' }}
          onClick={onSave}
          fullWidth
        />
      </div>
    </StyledNotifications>
  )
}

export default withTheme(MyCompanyNotifications)
