import styled from 'styled-components'
import { getCssFromTheme } from '../../../utils/css'

export const StyledTabs = styled.div`
  .rts___tabs___container {
    border-bottom: ${({ theme }) => `1px solid ${theme.color.general.lighter}`};
    padding: 0;
    width: 100%;
    * {
      ${({ variant, theme }) => getCssFromTheme(theme, 'typography', `${variant}`)};
    }

    .rts___tabs {
      padding: 0 5px;
    }

    .rts___nav___btn {
      border: none;
      padding: 0;
      &:disabled.rts___btn svg path {
        stroke: ${({ theme }) => theme.color.general.light};
      }
      &.left-nav-btn {
        margin-right: 10px;
      }
      &.right-nav-btn {
        margin-left: 10px;
        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        background: none;
        transition: none;
      }
    }
    .rts___tab {
      color: ${({ theme }) => theme.color.general.darker};
      background: none;
      box-shadow: none;
      border: none;
      border-radius: 0;
      padding: 10px 20px;
      margin: 0;
      border-bottom: 2px solid transparent;

      &.rts___tab___selected {
        border-bottom: ${({ theme }) => `2px solid ${theme.color.primary.main}`};
      }
    }
  }
`
