import React from 'react'
import { withTheme } from 'styled-components'

import WidgetStartHeader from '../ChooseChannels/components/WidgetStartHeader'
import WidgetFooter from '../ChooseChannels/components/WidgetFooter'
import PreChatFormFields from './components/PreChatFormFields'
import { StyledScreenWrapper } from '../ChooseChannels/styled'
import { StyledChatsContainer } from '../../../Chats/components/styled'
import Textarea from '../../../Chats/components/Textarea'
import { Checkbox, Typography } from '../../../../atomic-design-components'

const PreChatForm = ({
  className,
  color,
  theme,
  hideWidget,
  getT,
  goOneScreenForward,
  goOneScreenBack,
  is_hide_branding,
  form_fields,
  departments,
  privacy_policy,
}) => {
  const getLink = () => {
    const values = privacy_policy.document_url.match(/(http(s)?:\/\/)?(.*)/)

    if (values[1] === undefined) {
      return `https://${values[3]}`
    }

    return privacy_policy.document_url
  }

  return (
    <StyledScreenWrapper className={className}>
      <WidgetStartHeader
        color={color}
        hideWidget={hideWidget}
        goOneScreenBack={goOneScreenBack}
        getT={getT}
        subtextKey="sendMessageToStart"
        currentScreen="preChatForm"
      />

      <PreChatFormFields fields={form_fields} departments={departments} t={getT} />

      <StyledChatsContainer className="textarea">
        <Textarea isReadOnly theme={theme} t={getT} />
      </StyledChatsContainer>

      {privacy_policy.is_required && (
        <Checkbox readOnly checked={false}>
          {/*<FlexRow>*/}
          <Typography variant="body2">
            <span>{getT('privacyPolicyAgreeText')} </span>
            {!privacy_policy.document_url && <span>{getT('privacyPolicyLinkText')}</span>}
            {privacy_policy.document_url && (
              <a href={getLink()} target="_blank" rel="noreferrer noopener">
                {getT('privacyPolicyLinkText')}
              </a>
            )}
          </Typography>

          {/*</FlexRow>*/}
        </Checkbox>
      )}

      {!is_hide_branding && <WidgetFooter getT={getT} />}
    </StyledScreenWrapper>
  )
}

export default withTheme(PreChatForm)
