import React from 'react'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { Label, Typography, Userpic } from '../../atomic-design-components'

const NameWithAvatar = ({
  avatarSrc,
  avatarSize,
  label,
  name,
  onlineState,
  theme,
  typographyVariant,
  withoutAvatar,
  iconWidth,
  iconHeight,
  className,
  underText
}) => {
  return (
    <>
      {!withoutAvatar && name && (
        <Userpic
          height={avatarSize || 36}
          src={avatarSrc}
          onlineState={onlineState}
          width={avatarSize || 36}
          iconWidth={iconWidth || 14}
          iconHeight={iconHeight || 14}
        />
      )}
      <Typography
        component="div"
        variant={typographyVariant || 'caption1'}
        margin={withoutAvatar ? undefined : '0 0 0 13px'}
        className="name"
        as={ReactTexty}
      >
        {name || '-'}
        {underText && (
          <Label
            color={theme.color.general.dark}
            margin={withoutAvatar ? undefined : '0'}
            text={underText}
            as={ReactTexty}
          />
        )}
      </Typography>
      {label && (
        <Label
          color={theme.color.general.dark}
          margin={withoutAvatar ? undefined : '0 0 0 13px'}
          text={label}
          as={ReactTexty}
        />
      )}
    </>
  )
}

export default withTheme(NameWithAvatar)
