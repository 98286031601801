import styled from 'styled-components'
import Input from '../Input/Input'

export const StyledInput = styled(Input)`
  &.inputWrapper {
    .withInsideButtons {
      padding-bottom: ${({ active, extraPadding }) => active && `calc(60px + ${extraPadding}px) !important`};

      margin-bottom: 0;
    }
    .multiline {
      min-height: 56px;
    }

    &.hasError {
      border-color: ${({ theme }) => theme.color.status.error};
      margin-bottom: 0;
    }

    &:focus-within:not(.searchInput):not(.hasError) {
      outline: none;
      border: 1px solid ${({ theme }) => theme.color.general.light};
    }

    .ql-editor {
      max-height: 95px;
    }
    .attachButton .icon {
      margin: 0;
    }
  }
`

export const StyledInputContainer = styled.div`
  flex-grow: 1;
  position: relative;

  .uploadItemsListContainer {
    bottom: 45px;
    left: 15px;
    &.hasError {
      bottom: 70px;
    }
  }
`
