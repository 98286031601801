import React from 'react'

export const customization = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M1.71415 0.572266H5.14272C5.44582 0.572266 5.73651 0.692673 5.95084 0.907001C6.16517 1.12133 6.28558 1.41202 6.28558 1.71512V12.5723C6.28558 13.33 5.98456 14.0568 5.44874 14.5926C4.91292 15.1284 4.18619 15.4294 3.42843 15.4294V15.4294C3.05323 15.4294 2.6817 15.3555 2.33505 15.2119C1.98841 15.0683 1.67344 14.8579 1.40813 14.5926C0.872309 14.0568 0.571289 13.33 0.571289 12.5723V1.71512C0.571289 1.41202 0.691697 1.12133 0.906024 0.907001C1.12035 0.692673 1.41104 0.572266 1.71415 0.572266V0.572266Z"
      stroke={color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.28546 5.71457L10.2855 1.69171C10.4996 1.47885 10.7892 1.35938 11.0912 1.35938C11.3931 1.35938 11.6828 1.47885 11.8969 1.69171L14.3083 4.11457C14.5212 4.3287 14.6406 4.61835 14.6406 4.92028C14.6406 5.22221 14.5212 5.51187 14.3083 5.726L5.45117 14.5946"
      stroke={color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2859 9.71484H14.2859C14.589 9.71484 14.8796 9.83525 15.094 10.0496C15.3083 10.2639 15.4287 10.5546 15.4287 10.8577V14.2863C15.4287 14.5894 15.3083 14.8801 15.094 15.0944C14.8796 15.3087 14.589 15.4291 14.2859 15.4291H3.42871"
      stroke={color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.571289 5.14453H6.28558"
      stroke={color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.571289 9.71484H6.28558"
      stroke={color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
