import React from 'react'

export const profileEdit = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M7.25759 7.25712C8.96178 7.25712 10.3433 5.8756 10.3433 4.17141C10.3433 2.46721 8.96178 1.08569 7.25759 1.08569C5.5534 1.08569 4.17188 2.46721 4.17188 4.17141C4.17188 5.8756 5.5534 7.25712 7.25759 7.25712Z"
      stroke={fill || color.general.darker}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.20022 17.5428H1.08594V15.4857C1.09685 14.4404 1.37253 13.4149 1.88723 12.505C2.40192 11.5951 3.13883 10.8305 4.02913 10.2826C4.91942 9.73475 5.93406 9.42147 6.97826 9.37203C8.02246 9.3226 9.06215 9.53863 10.0002 9.99995"
      stroke={fill || color.general.darker}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9157 12.0571L12.4563 18.5165L9.53516 18.9143L9.94659 15.9931L16.3923 9.53369L18.9157 12.0571Z"
      stroke={fill || color.general.darker}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
