import React from 'react'

import { StyledChannelsAnalytic } from './styled'
import { FlexRow, Icon, Typography } from '../../../atomic-design-components'
import AnalyticsLine from './AnalyticsLine'
import theme from '../../../theme'
import { CHANNELS } from '../../../constants'
import { getPercent } from '../../../utils/dataTransformations'

const ChannelsAnalytic = ({ color, t, data }) => {
  return (
    <StyledChannelsAnalytic color={color}>
      <FlexRow className="headerRow">
        <Icon name="chats" size={18} fill={color.primary.main} margin="0 8px 0 0" />
        <Typography variant="caption2" text={t('messagingChannels')} />
      </FlexRow>

      {CHANNELS?.map((channel, i) => {
        const channelData = data.find((el) => el.value === channel) || { value: channel, quantity: 0 }
        const percent = getPercent(channelData.quantity, data)

        return (
          <div className="channelAnalytic" key={channel}>
            <FlexRow justifyContent="start" padding="10px 0 0">
              <Icon name={`${channel}Icon`} size={14} margin="0 10px 0 0" />
              <Typography
                variant="caption2"
                text={channelData.value === 'live_chat' ? 'Live Chat' : channelData.value}
                textTransform="capitalize"
                margin="0 auto 0 0"
              />
              <Typography variant="caption3" text={channelData.quantity} margin="0 15px 0" />
              <Typography
                variant="caption3"
                text={`${percent}%`}
                textAlign="right"
                style={{ width: '45px' }}
              />
            </FlexRow>
            <AnalyticsLine color={theme.color} value={percent} />
          </div>
        )
      })}
    </StyledChannelsAnalytic>
  )
}

export default ChannelsAnalytic
