import React from 'react'

export const trashCan = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 22} height={height || 22} viewBox="0 0 22 22" fill="none">
    <path
      d="M2.08594 5.7998H19.9145"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.31445 5.7998H17.6859V19.1712C17.6859 19.5653 17.5293 19.9432 17.2507 20.2218C16.9721 20.5004 16.5942 20.6569 16.2002 20.6569H5.80017C5.40613 20.6569 5.02823 20.5004 4.74961 20.2218C4.47098 19.9432 4.31445 19.5653 4.31445 19.1712V5.7998Z"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.28613 5.79992V5.05706C7.28613 4.07197 7.67746 3.12723 8.37402 2.43066C9.07059 1.7341 10.0153 1.34277 11.0004 1.34277C11.9855 1.34277 12.9303 1.7341 13.6268 2.43066C14.3234 3.12723 14.7147 4.07197 14.7147 5.05706V5.79992"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.77148 8.77148V16.9429"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.229 8.77148V16.9429"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
