import React from 'react'

export const navMenuToggleArrow = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <rect x="2" y="2" width="16" height="16" rx="3" fill={fill || color.primary.main} />
    <path
      d="M13.7147 10H6.28613"
      stroke={color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.28613 8L6.28613 10L8.28613 12"
      stroke={color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="1" y="1" width="18" height="18" rx="4" stroke={color.general.white} strokeWidth="2" />
  </svg>
)
