import merge from 'deepmerge'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { Button, FlexRow, TextPanel } from '../../../atomic-design-components'
import Form from '../../../components/Form'
import { DEFAULT_VALUES_DATA } from '../../../config/defaultValues'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import {
  deleteEntity,
  getTableData,
  inputFilesAdd,
  inputFilesClear,
  upsertSidebarItem,
} from '../../../redux-saga/actions'
import {
  selectFilesUploaded,
  selectMainData,
  selectSidebarDetails,
  selectSidebarMetaData,
} from '../../../redux-saga/selectors'
import { transformDateToUTC, transformUtcDateToISOWithTZ } from '../../../utils/dates'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'

const overwriteMerge = (destinationArray, sourceArray) => sourceArray

const FormBlockWrapper = withTheme(({ children, blockKey, id, formValues, theme, formErrors }) => {
  const { t } = useTranslation('all')

  const contacts = useMappedState(selectMainData('bulkContacts')) || []
  const hasError = Object.keys(formErrors).includes('tags')

  if (blockKey === 'clientFilters') {
    return (
      <>
        {!['complete', 'complete_with_error'].includes(formValues.status) && (
          <TextPanel
            backgroundColor={theme.color.general.lightest}
            content={t('clientCountTip', { count: hasError ? 0 : contacts.length })}
            iconLeftProps={{ name: 'infoCircle' }}
            gap="6px"
          />
        )}
        {children}
      </>
    )
  }

  return children
})

const BulkMessagingEdit = ({ theme }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formDataInitial = useMappedState(selectSidebarDetails)
  const transformedInitialData = formDataInitial
    ? {
        ...formDataInitial,
        scheduled_at: transformUtcDateToISOWithTZ(formDataInitial.scheduled_at),
      }
    : DEFAULT_VALUES_DATA.bulkMessaging
  const initialStatus = transformedInitialData.status
  const { inProgress, error } = useMappedState(selectSidebarMetaData)
  const allFilesUploaded = useMappedState(selectFilesUploaded)
  const initialFiles = transformedInitialData.files
  const files = allFilesUploaded?.bulkMessage || []

  const isReadOnly = transformedInitialData?.id && initialStatus !== 'draft'
  const location = useLocation()

  const tags = useMappedState(selectMainData('tags'))
  useEffect(() => {
    dispatch(getTableData({ type: 'tags', isSystemData: true }))
  }, [])

  useEffect(() => {
    dispatch(inputFilesClear())
    dispatch(inputFilesAdd(initialFiles, 'bulkMessage', 'filesUploaded'))
  }, [initialFiles])

  const [isSendingNow, setIsSendingNow] = useState(false)
  const [isDraft, setIsDraft] = useState(false)

  const onCancel = () => {
    dispatch(
      upsertSidebarItem({
        id: transformedInitialData.id,
        type: 'bulkMessaging',
        requestBody: { status: 'draft' },
        location,
      })
    )
  }

  const onDelete = () => {
    dispatch(deleteEntity({ id: transformedInitialData.id, type: 'bulkMessaging', navigate, location }))
  }

  const onFormSubmit = (valuesChanged) => {
    const newValues = transformDataToBeSent(valuesChanged)
    const dataToSend = merge(transformedInitialData, newValues, { arrayMerge: overwriteMerge })
    const status = isSendingNow
      ? 'in_progress'
      : isDraft
      ? 'draft'
      : dataToSend.is_scheduled
      ? 'scheduled'
      : 'draft'

    if (!isObjectEmpty(dataToSend)) {
      if (dataToSend.tags === null) {
        dataToSend.tags = []
      }
      if (files?.length) {
        dataToSend.files = files
      }
      if (dataToSend.is_scheduled) {
        dataToSend.scheduled_at = transformDateToUTC(dataToSend.scheduled_at)
      }

      if (isSendingNow) {
        dataToSend.scheduled_at = transformDateToUTC(new Date())
      }

      dispatch(inputFilesClear())
      dispatch(
        upsertSidebarItem({
          id: transformedInitialData.id,
          type: 'bulkMessaging',
          requestBody: { ...dataToSend, status },
          location,
          navigate,
        })
      )
    }
    setIsDraft(false)
    setIsSendingNow(false)
  }

  const onClientFiltersUpdate = (name, values, formValues) => {
    if (name === 'tags' || name === 'channels') {
      const tags = (name === 'tags' ? values : formValues.tags)?.map((el) => el.id || el)
      const channels = (name === 'channels' ? values : formValues.channels)?.map((el) => el.id || el)

      let queryParams = []
      if (tags?.length) {
        queryParams.push({ key: 'tags', value: tags })
      }
      if (channels?.length) {
        queryParams.push({ key: 'channels', value: channels })
      }

      if (queryParams.length) {
        dispatch(
          getTableData({
            type: 'bulkContacts',
            isSystemData: true,
            queryParams,
          })
        )
      }
    }
  }

  const getFormButtons = (isDraft, formValues) => {
    const { t } = useTranslation('all')

    if (['complete', 'complete_with_error', 'in_progress'].includes(formValues?.status)) {
      return null
    }

    return (
      <FlexRow gap="10px" flexWrap="wrap" margin="0 0 30px" justifyContent="space-between">
        {formValues?.status === 'scheduled' ? (
          <Button
            fullWidth
            onClick={onCancel}
            className="cancelButton"
            disabled={inProgress}
            iconLeftProps={{
              size: 16,
              name: 'cross',
              fill: theme.color.general.white,
              wrapperColor: 'none',
            }}
          >
            {t('cancel')}
          </Button>
        ) : (
          !isReadOnly && (
            <>
              <FlexRow gap="10px" style={{ width: '100%' }} justifyContent="space-between">
                <Button
                  type="submit"
                  variant="general"
                  style={{ width: '30%' }}
                  onClick={() => setIsDraft(true)}
                  disabled={inProgress}
                >
                  {t('save')}
                </Button>
                {formValues?.is_scheduled ? (
                  <Button
                    type="submit"
                    iconLeftProps={{ size: 16, name: 'mailSend' }}
                    style={{ width: 'calc(70% - 20px)' }}
                    text={t('schedule')}
                    disabled={inProgress}
                  />
                ) : (
                  <Button
                    type="submit"
                    iconLeftProps={{ size: 16, name: 'mailSend' }}
                    style={{ width: '70%' }}
                    text={t('sendMessagesNow')}
                    disabled={inProgress}
                    onClick={() => setIsSendingNow(true)}
                  />
                )}
              </FlexRow>
              {formValues?.id && formValues?.status !== 'complete' && (
                <Button
                  fullWidth
                  variant="general"
                  confirmButtonProps={{
                    onDeleteConfirm: onDelete,
                    iconLeftProps: {
                      fill: theme.color.status.error,
                      name: 'inputError',
                    },
                  }}
                  className="cancelButton"
                  backgroundColor={theme.color.status.error}
                  borderColor={theme.color.status.error}
                  color={theme.color.general.white}
                  iconLeftProps={{ size: 16, name: 'trashCan' }}
                  text={t('delete')}
                  disabled={inProgress}
                />
              )}
            </>
          )
        )}
      </FlexRow>
    )
  }

  return (
    <Form
      customOnChange={onClientFiltersUpdate}
      FormBlockWrapper={FormBlockWrapper}
      optionsData={{ tags }}
      initialValues={transformedInitialData}
      formConfig={FORM_CONFIGS.bulkMessaging}
      validationRules={FORM_CONFIGS.bulkMessaging.validationRules}
      onSubmit={onFormSubmit}
      isSubmitButtonAlwaysShown
      getFormButtons={getFormButtons}
      isReadOnly={isReadOnly}
      isButtonShown
      inProgress={inProgress}
      serverError={error !== false}
    />
  )
}

export default withTheme(BulkMessagingEdit)
