import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'

import { FlexRow, Loader, Switch, Tag, Typography } from '../../atomic-design-components'
import { useMappedState } from '../../hooks/useReactRedux'
import { useWidth } from '../../hooks/useWidth'
import { getBillingPlans, getCurrentSubscription } from '../../redux-saga/actions'
import {
  selectBillingPlans,
  selectCompanyMetaData,
  selectCurrentSubscription,
  selectUserDetails,
} from '../../redux-saga/selectors'
import SubscriptionCurrentPlan from './components/SubscriptionCurrentPlan'
import SubscriptionPlanCard from './components/SubscriptionPlanCard'
import { StyledSubscription } from './styled'

const MyCompanySubscription = ({ theme }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('all')

  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.lg

  const { phone, email, member_full_name, customer_id: companyId } = useMappedState(selectUserDetails)

  const billingPlans = useMappedState(selectBillingPlans)

  const { inProgress } = useMappedState(selectCompanyMetaData)
  const planData = useMappedState(selectCurrentSubscription)

  const [billedAnnually, setBilledAnnually] = useState(true)

  useEffect(() => {
    dispatch(getBillingPlans())
    dispatch(getCurrentSubscription())
    console.log('getCurrentSubscription and plans')
  }, [])

  if (inProgress) {
    return <Loader size="60px" />
  }

  return (
    <StyledSubscription>
      <Typography variant={isMobile ? 'h4' : 'h3'} text={t('yourCurrentPlan')} />

      <SubscriptionCurrentPlan
        t={t}
        theme={theme}
        subscriptionData={planData}
        isMobile={isMobile}
        lng={i18n.language}
      />

      <FlexRow justifyContent="space-between" margin="20px 0 0" flexWrap="wrap" gap="10px">
        <Typography variant={isMobile ? 'h4' : 'h3'} text={t('availablePlans')} />
        <FlexRow gap="5px">
          <Switch
            leftLabel={t('billedMonthly')}
            label={t('billedAnnually')}
            textVariant={isMobile ? 'caption2' : 'button1'}
            id="billed"
            gap="5px"
            checked={billedAnnually}
            onChange={(e) => setBilledAnnually(e)}
          />
          <Tag
            backgroundColor={theme.color.primary.main}
            color={theme.color.general.white}
            text={t('saveUp')}
          />
        </FlexRow>
      </FlexRow>

      <FlexRow flexDirection="column">
        {billingPlans?.map((plan) => (
          <SubscriptionPlanCard
            key={plan.id}
            t={t}
            theme={theme}
            plan={plan}
            isActive={planData.plan_id === plan.id}
            isMobile={isMobile}
            billedAnnually={billedAnnually}
            customerEmail={email}
            customerPhone={phone}
            customerFullName={member_full_name}
            trial_available={planData.trial_available}
            companyId={companyId}
          />
        ))}
      </FlexRow>
    </StyledSubscription>
  )
}

export default withTheme(MyCompanySubscription)
