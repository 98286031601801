import React from 'react'

export const checkboxChecked = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
    <rect width={width || 16} height={height || 16} rx="3" fill={fill || color.status.new} />
    <path
      d="M7.16504 8.83333L5.16504 6.83333C4.79685 6.46514 4.1999 6.46514 3.83171 6.83333C3.46352 7.20152 3.46352 7.79848 3.83171 8.16667L6.45793 10.7929C6.84846 11.1834 7.48162 11.1834 7.87215 10.7929L12.165 6.5C12.5332 6.13181 12.5332 5.53486 12.165 5.16667C11.7968 4.79848 11.1999 4.79848 10.8317 5.16667L7.16504 8.83333Z"
      fill={color.general.white}
    />
  </svg>
)
