import React from 'react'

export const floppy = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M15.4284 14.2856C15.4284 14.5887 15.308 14.8794 15.0937 15.0937C14.8794 15.308 14.5887 15.4284 14.2856 15.4284H1.71415C1.41104 15.4284 1.12035 15.308 0.906024 15.0937C0.691697 14.8794 0.571289 14.5887 0.571289 14.2856V3.99986L3.99986 0.571289H14.2856C14.5887 0.571289 14.8794 0.691697 15.0937 0.906024C15.308 1.12035 15.4284 1.41104 15.4284 1.71415V14.2856Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.71387H4V15.4282H12V9.71387Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9997 0.571289H5.14258V5.14272H11.9997V0.571289Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
