import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import ReactHelmet from '../../wrappers/ReactHelmet'

import { Icon, Typography } from '../../atomic-design-components'
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import backOrange from '../../assets/images/backOrange.jpg'

const PasswordRecoverySent = ({ theme }) => {
  const { t } = useTranslation('auth')

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper
        type="passwordRecoverySent"
        theme={theme}
        imageUrl={backOrange}
        text={t('checkYourMailbox')}
      >
        <Icon name="checkMailbox" margin="30px 0 0" />
        <div className="inscriptionWrapper">
          <Typography
            variant="body2"
            color={theme.color.general.black}
            margin="20px 0 0 0"
            className="inline"
            text={t('passRecoverSentText')}
          />
        </div>
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(PasswordRecoverySent)
