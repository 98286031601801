import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useMappedState } from '../hooks/useReactRedux'
import { initializeApp } from '../redux-saga/actions'
import { selectWholeUserState } from '../redux-saga/selectors'
import { ROUTES_FORBIDDEN } from '../constants'

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation('all')

  const user = useMappedState(selectWholeUserState)
  const role = user?.details?.role

  useEffect(() => {
    if (!user.isAppInitialized) {
      dispatch(initializeApp(navigate, location, i18n.changeLanguage))
    }
  }, [user])

  if (!user.isAppInitialized || user.inProgress || !user.details.email) {
    return null
  }

  const isForbidden = ROUTES_FORBIDDEN[role]?.some((path) => location.pathname.includes(path))

  if (isForbidden) {
    return <Navigate replace to="/404" />
  }

  return user.isAuthenticated ? children : <Navigate replace to="/sign-in" />
}

export default PrivateRoute
