import clsx from 'clsx'
import { PropTypes as T } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeGrid as Grid } from 'react-window'

import CardTableItem from '../../../components/CardTableItem'
import { HEADER_HEIGHT } from '../../../constants'
import { useLoadOnScroll } from '../../../hooks/useLoadOnScroll'
import { StyledVirtualizedList } from './styled'
import { RIGHT_PANEL_CUSTOM_ROUTES } from '../../../config'

const VirtualizedList = ({
  activeRowId,
  before,
  cellProps,
  className,
  columns,
  columnCount = 1,
  columnWidth,
  CustomListItem,
  customListItemProps = {},
  estimatedColumnWidth,
  estimatedRowHeight,
  gridRef,
  hasMore,
  initialPageNumber,
  inProgress,
  itemSize,
  items,
  itemsTotalCount,
  limit,
  loadNextPage,
  margin = 20,
  offset,
  // maxHeight,
  // minimumBatchSize = 10, // Minimum number of rows to be loaded at a time. This property can be used to batch requests to reduce HTTP requests.
  onItemClick,
  onItemSelect,
  onResize: onCustomResize,
  onRowHeightsCounted,
  onSetColumnCount,
  pageProp,
  prevRowId,
  rowCount,
  rowHeight,
  searchValue,
  selectable,
  selectedRowKeys,
  sortState,
  t,
  TableCardContent,
  threshold, // A threshold of 15 means that data will start loading when a user scrolls within 15 rows.
  type,
  variant,
  ...rest
}) => {
  const hasMountedRef = useRef(false)
  const rowHeights = useRef({})

  const onResize = ({ width }) => {
    if (onSetColumnCount) {
      onSetColumnCount(width)
    }

    if (onCustomResize) {
      onCustomResize(width)
    }
  }

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = items.length //hasNextPage ? items.length + 1 :

  const [topReachedByFirstListElement, setTopReachedByFirstListElement] = useState(false)
  const startLineRef = useRef(null)

  useEffect(() => {
    if (!loadNextPage) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setTopReachedByFirstListElement(!entry.isIntersecting)
      },
      { rootMargin: `-${HEADER_HEIGHT}px` }
    )
    observer.observe(startLineRef.current)

    return () => observer.disconnect()
  }, [])

  const { lastElementRef } = useLoadOnScroll({
    hasMore,
    inProgress,
    initialPageNumber,
    loadNextPage,
    pageProp,
  })

  const getRowHeight = (index) => {
    return rowHeights.current?.[index] || 80
  }

  const setItemHeight = (index, size) => {
    gridRef.current.resetAfterRowIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }

    if (!hasMountedRef.current && index === items.length - 1 && onRowHeightsCounted) {
      onRowHeightsCounted()
      hasMountedRef.current = true
    }
  }

  // Render an item or a loading indicator.
  const Item = (props) => {
    const { index, style, columnIndex, rowIndex } = props

    const itemIndex = index === undefined ? rowIndex * columnCount + columnIndex : index
    const itemRef = useRef({})

    let content
    content = items[itemIndex] || {}

    useEffect(() => {
      if (itemRef.current && !rowHeight) {
        setItemHeight(itemIndex, itemRef.current.clientHeight)
      }
    }, [itemRef])

    // const isLoaded = isItemLoaded(itemIndex)
    const TableItem = CustomListItem || CardTableItem

    // if (!isLoaded) {
    //   content = 'Loading...'
    // } else {
    // }

    const onClick = (event) => {
      if (onItemClick) {
        onItemClick({ event, rowKey: content.id || itemIndex })
      }
    }

    if (!items[itemIndex]) {
      return null
    }

    const isHighlightedRow =
      activeRowId && !RIGHT_PANEL_CUSTOM_ROUTES.includes(activeRowId)
        ? activeRowId === items[itemIndex]?.id?.toString()
        : prevRowId && items[itemIndex]?.id?.toString() === prevRowId

    if (content.id === items[(items?.length || 0) - 1]?.id) {
      return (
        <div
          ref={lastElementRef}
          style={{ ...style, width: '100%' }}
          onClick={typeof CustomListItem === 'object' ? undefined : onClick}
          key={content.id || itemIndex}
          className={`${type}Card`}
        >
          <TableItem
            CardContent={TableCardContent}
            cellProps={cellProps}
            isClicked={isHighlightedRow}
            index={itemIndex}
            currentItemsCount={itemCount}
            onClick={onClick}
            style={style}
            data={content}
            columns={columns}
            margin={margin}
            ref={rowHeight ? undefined : itemRef}
            rowHeight={rowHeight}
            t={t}
            type={type}
            {...customListItemProps}
          />
        </div>
      )
    }

    return (
      <div
        style={{ ...style, width: '100%' }}
        onClick={typeof CustomListItem === 'object' ? undefined : onClick}
        key={content.id || itemIndex}
        className={`${type}Card`}
      >
        <TableItem
          CardContent={TableCardContent}
          cellProps={cellProps}
          isClicked={isHighlightedRow}
          index={itemIndex}
          currentItemsCount={itemCount}
          onClick={onClick}
          style={style}
          data={content}
          columns={columns}
          margin={margin}
          ref={rowHeight ? undefined : itemRef}
          rowHeight={rowHeight}
          t={t}
          type={type}
          {...customListItemProps}
        />
      </div>
    )
  }

  return (
    <>
      {!!loadNextPage && <div className="startLine" ref={startLineRef} />}
      <AutoSizer onResize={onResize}>
        {({ height, width }) => {
          return (
            <StyledVirtualizedList
              as={Grid}
              height={height}
              itemCount={items.length}
              itemSize={itemSize}
              ref={gridRef}
              width={width}
              className={clsx(
                className,
                'listVirtualized',
                loadNextPage && 'withLoadOnScroll',
                topReachedByFirstListElement && 'scrolledIntoViewMax'
              )}
              columnWidth={
                () => columnWidth || width / columnCount // - (screenUp === 'xs' || screenUp === 'xxs' ? 10 : 3)
              }
              columnCount={columnCount}
              rowHeight={(index) => (rowHeight ? rowHeight + margin : getRowHeight(index))} // tableMode === 'cards'
              estimatedColumnWidth={estimatedColumnWidth}
              estimatedRowHeight={estimatedRowHeight || 80}
              rowCount={rowCount || Math.ceil(itemCount / columnCount)}
              {...rest}
              // outerElementType={outerElementType}
              // innerElementType={innerElementType}
            >
              {Item}
            </StyledVirtualizedList>
          )
        }}
      </AutoSizer>
    </>
  )
}

export default VirtualizedList

VirtualizedList.propTypes = {
  loadOnScrollDirection: T.oneOf(['up', 'down', 'both']),
}

VirtualizedList.defaultProps = {
  loadOnScrollDirection: 'down',
}
