import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useMappedState } from '../../hooks/useReactRedux'
import { StyledGeneral } from './styled'
import Form from '../../components/Form'
import { selectUserCompanyId, selectCompanyData, selectCompanyMetaData } from '../../redux-saga/selectors'
import { Typography } from '../../atomic-design-components'
import { FORM_CONFIGS } from '../../config/forms'
import { transformDataToBeSent } from '../../utils/transformDataToBeSent'
import { isObjectEmpty } from '../../utils/isObjectEmpty'
import { getCompanyDataById, updateCompanyDataById } from '../../redux-saga/actions'

const FormBlockWrapper = withTheme(({ children, blockKey, theme }) => {
  const { t } = useTranslation('all')

  if (blockKey === 'companyInformation') {
    return (
      <>
        <Typography
          text={t('companyInfoTip')}
          variant="body2"
          color={theme.color.general.dark}
          className="explanation"
        />
        {children}
      </>
    )
  }

  return children
})

const MyCompanyGeneral = () => {
  const dispatch = useDispatch()

  const companyData = useMappedState(selectCompanyData)
  const { inProgress, error } = useMappedState(selectCompanyMetaData)

  const currentUserCompanyId = useMappedState(selectUserCompanyId)

  useEffect(() => {
    dispatch(getCompanyDataById(currentUserCompanyId))
  }, [])

  const formDataInitial = companyData
  const initialValues = formDataInitial?.phone?.phone
    ? formDataInitial
    : {
        ...formDataInitial,
        phone: { phone: formDataInitial.phone },
      }

  const onFormSubmit = (valuesChanged) => {
    const newValues = {
      ...transformDataToBeSent(valuesChanged),
    }

    if (!isObjectEmpty(valuesChanged)) {
      dispatch(updateCompanyDataById(companyData.id, newValues))
    }
  }

  return (
    <StyledGeneral>
      <Form
        isSubmitOnBlur
        inProgress={inProgress}
        serverError={error}
        initialValues={initialValues}
        validationRules={FORM_CONFIGS.companyGeneral.validationRules}
        formConfig={FORM_CONFIGS.companyGeneral}
        onSubmit={onFormSubmit}
        expansionPanelProps={{
          disabled: true,
          withChevronInHeader: false,
        }}
        FormBlockWrapper={FormBlockWrapper}
      />
    </StyledGeneral>
  )
}

export default MyCompanyGeneral
