import React, { useCallback, useContext } from 'react'
import { NAV_MENU_WIDTH } from '../../constants'
import { useMappedState } from '../../hooks/useReactRedux'
import { ScreenContext } from '../../hooks/useScreenContext'
import { selectIsNavMenuExtended } from '../../redux-saga/selectors'
import ErrorBoundary from '../../wrappers/ErrorBoundary'
import CentralPanel from './CentralPanel'
import { StyledGrid, StyledLeftStaticDrawer, StyledRightDrawer } from './styled'

const GridLayoutDesktop = ({
  children,
  className,
  isRightPanelOpened,
  setIsRightPanelOpened,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  LeftPanelComponent,
  RightPanelComponent,
  RightPanelMinWidth,
  onCloseRightPanel,
  t,
  theme,
  type,
  rightPanelTitle,
}) => {
  const { currentBreakpoint, width, lg } = useContext(ScreenContext)
  const colsNumber = theme.grid[currentBreakpoint]?.colsCount

  const isNavMenuExtended = useMappedState(selectIsNavMenuExtended)

  const getWidth = useCallback(
    (colsCount, gap = '20px') => {
      const navMenuWidth = isNavMenuExtended
        ? NAV_MENU_WIDTH.desktop.extended
        : NAV_MENU_WIDTH.desktop.condensed

      return `calc(((100vw - ${navMenuWidth}) / ${colsNumber}) * ${colsCount} - ${gap})`
    },
    [isNavMenuExtended, colsNumber]
  )
  return (
    <StyledGrid className={isNavMenuExtended && 'extended'}>
      {/*/!*chats, settings, templates - not closable*!/*/}
      {LeftPanelComponent && (
        <StyledLeftStaticDrawer
          width={getWidth(theme.grid[currentBreakpoint]?.leftPanelCols)}
          openedValue={isLeftPanelOpened}
          toggleDrawer={setIsLeftPanelOpened}
          side="left"
          isStatic
          withCloseIcon={false}
          title={t(type)}
          className={type}
        >
          <ErrorBoundary>{LeftPanelComponent}</ErrorBoundary>
        </StyledLeftStaticDrawer>
      )}

      <CentralPanel className={className}>{children}</CentralPanel>

      {/*/!*chats (templates, user info, forward), members (invite, edit), customers (invite, edit), contacts, settings.invoices, settings.payments*!/*/}
      {RightPanelComponent && (
        <StyledRightDrawer
          openedValue={isRightPanelOpened}
          toggleDrawer={setIsRightPanelOpened}
          width={getWidth(theme.grid[currentBreakpoint]?.rightPanelCols)}
          minWidth={RightPanelMinWidth}
          onClose={onCloseRightPanel}
          title={rightPanelTitle || t('all:details')}
        >
          <ErrorBoundary>{RightPanelComponent}</ErrorBoundary>
        </StyledRightDrawer>
      )}
    </StyledGrid>
  )
}

export default GridLayoutDesktop
