import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
const dayOfYear = require('dayjs/plugin/dayOfYear')

dayjs.extend(dayOfYear)
dayjs.extend(utc)

export const rangeOption = [
  { id: 'today', period: 'today' },
  { id: 'yesterday', period: 'yesterday' },
  { id: 'thisWeek', period: 'thisWeek' },
  { id: 'lastWeek', period: 'lastWeek' },
  { id: 'thisMonth', period: 'thisMonth' },
  { id: 'lastMonth', period: 'lastMonth' },
  { id: 'last30days', period: 'last30days' },
  { id: 'last90days', period: 'last90days' },
  { id: 'thisYear', period: 'thisYear' },
  { id: 'lastYear', period: 'lastYear' },
]

const getStartDateTime = (date) =>
  dayjs(date).set('hour', 0).set('minute', 0).set('second', 0).millisecond(0).$d
const getEndDateTime = (date) =>
  dayjs(date).set('hour', 23).set('minute', 59).set('second', 59).millisecond(0).$d

export const getDays = (day, range) => {
  const rangeDay = dayjs().add(-day, 'day')
  if (range) {
    return [getStartDateTime(rangeDay), getEndDateTime(dayjs())]
  } else {
    return [getStartDateTime(rangeDay), getEndDateTime(rangeDay)]
  }
}

export const getWeeks = (range) => {
  if (range === 'thisWeek') {
    return [getStartDateTime(dayjs().day(1)), getEndDateTime(dayjs().day(7))]
  }
  if (range === 'lastWeek') {
    return [getStartDateTime(dayjs().day(-6)), getEndDateTime(dayjs().day(0))]
  }
}

export const getMonths = (range) => {
  let firstDay
  let lastDay
  let currMonth = dayjs().month()

  if (range === 'thisMonth') {
    lastDay = dayjs().daysInMonth()
    return [getStartDateTime(dayjs().date(1)), getEndDateTime(dayjs().date(lastDay))]
  }
  if (range === 'lastMonth') {
    firstDay = dayjs()
      .month(currMonth - 1)
      .date(1)
    lastDay = dayjs()
      .month(currMonth - 1)
      .daysInMonth()
    return [
      getStartDateTime(firstDay),
      getEndDateTime(
        dayjs()
          .month(currMonth - 1)
          .date(lastDay)
      ),
    ]
  }
}

export const getYears = (range) => {
  let firstDay
  let lastDay
  let currYear = dayjs().year()
  if (range === 'thisYear') {
    lastDay = dayjs().month(11).daysInMonth()
    return [getStartDateTime(dayjs().dayOfYear(1)), getEndDateTime(dayjs().month(11).date(lastDay))]
  }
  if (range === 'lastYear') {
    firstDay = dayjs().year(currYear - 1)
    lastDay = dayjs().month(-1).daysInMonth()
    return [getStartDateTime(firstDay.dayOfYear(1)), getEndDateTime(dayjs().month(-1).date(lastDay))]
  }
  return [firstDay, lastDay]
}

export const rangeDate = {
  today: getDays(0),
  yesterday: getDays(1),
  thisWeek: getWeeks('thisWeek'),
  lastWeek: getWeeks('lastWeek'),
  thisMonth: getMonths('thisMonth'),
  lastMonth: getMonths('lastMonth'),
  last30days: getDays(30, true),
  last90days: getDays(90, true),
  thisYear: getYears('thisYear'),
  lastYear: getYears('lastYear'),
}
