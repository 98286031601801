import styled from 'styled-components'
import { VariableSizeList as List } from 'react-window'

export const StyledVirtualizedList = styled(List)`
  overflow-x: hidden !important;

  &.withLoadOnScroll {
    overflow: hidden !important;

    &.scrolledIntoViewMax {
      overflow: auto !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .widgetsCard .panel.bordered {
    padding: 4px 14px 8px 14px;
  }

  & > div > div:last-child {
    margin-bottom: 20px;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      margin-bottom: 10px;
    }
  }
`
