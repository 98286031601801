import React, { useContext } from 'react'

import { StyledFilesList } from './styled'
import FileItem from './FileItem'
import { ScreenContext } from '../../../hooks/useScreenContext'

const FilesList = ({ files, type }) => {
  const { width, sm } = useContext(ScreenContext)
  const isMobile = width < sm

  return (
    <StyledFilesList className={`${type}Container`} imgSize="100px">
      {files.map((file) => {
        if (!file?.file_code) {
          return null
        }
        return <FileItem key={file.file_code} type={type} file={file} isMobile={isMobile} />
      })}
    </StyledFilesList>
  )
}

export default FilesList
