import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledNotifications } from './styled'
import { Typography, Switch, FlexRow } from '../../atomic-design-components'
import theme from '../../theme'

const SettingsNotifications = () => {
  const { t } = useTranslation('all')

  const hasLocalStorageState = (type) =>
    type.includes('Sound')
      ? soundNotifications?.[type] !== undefined
      : pushNotifications?.[type] !== undefined

  const soundNotifications = JSON.parse(localStorage.getItem('soundNotifications')) || {
    chatSound: true,
    messageSound: true,
  }

  const pushNotifications = JSON.parse(localStorage.getItem('pushNotifications')) || {
    chatPush: true,
    messagePush: true,
  }

  useEffect(() => {
    if (!localStorage.getItem('soundNotifications')) {
      window.localStorage.setItem('soundNotifications', JSON.stringify(soundNotifications))
    }

    if (!localStorage.getItem('pushNotifications')) {
      window.localStorage.setItem('pushNotifications', JSON.stringify(pushNotifications))
    }
  }, [])

  const [chatSound, setChatSound] = useState(
    hasLocalStorageState('chatSound') ? soundNotifications.chatSound : true
  )
  const [messageSound, setMessageSound] = useState(
    hasLocalStorageState('messageSound') ? soundNotifications.messageSound : true
  )

  const [chatPush, setChatPush] = useState(
    hasLocalStorageState('chatPush') ? pushNotifications.chatPush : true
  )
  const [messagePush, setMessagePush] = useState(
    hasLocalStorageState('messagePush') ? pushNotifications.messagePush : true
  )

  const handleSoundSwitchChange = (checked, id) => {
    const isChatSound = id === 'chatSound'
    if (isChatSound) {
      setChatSound(checked)
    } else {
      setMessageSound(checked)
    }

    const newSoundNotifications = {
      [isChatSound ? 'messageSound' : 'chatSound']: isChatSound ? messageSound : chatSound,
      [id]: checked,
    }

    window.localStorage.setItem('soundNotifications', JSON.stringify(newSoundNotifications))
  }

  const handlePushSwitchChange = (checked, id) => {
    const isChatPush = id === 'chatPush'
    if (isChatPush) {
      setChatPush(checked)
    } else {
      setMessagePush(checked)
    }

    const newPushNotifications = {
      [isChatPush ? 'messagePush' : 'chatPush']: isChatPush ? messagePush : chatPush,
      [id]: checked,
    }

    window.localStorage.setItem('pushNotifications', JSON.stringify(newPushNotifications))
  }
  const renderNotificationItem = (id, title, explain) => (
    <div className="notificationItem">
      <FlexRow justifyContent="space-between" className="notificationSwitch">
        <Typography variant="h4" text={t(title)} color={theme.color.general.darker} />
        <Switch
          id={id}
          checked={id.includes('Sound') ? soundNotifications[id] : pushNotifications[id]}
          onChange={(checked) =>
            id.includes('Sound') ? handleSoundSwitchChange(checked, id) : handlePushSwitchChange(checked, id)
          }
        />
      </FlexRow>
      <Typography variant="body2" text={t(explain)} color={theme.color.general.dark} />
    </div>
  )

  return (
    <StyledNotifications>
      <Typography className="headerWrapper" variant="h3" text={t('notificationsTitle')} />
      {renderNotificationItem('chatSound', 'notificationTitle1', 'notificationExplain1')}
      {renderNotificationItem('messageSound', 'notificationTitle2', 'notificationExplain2')}
      <Typography className="headerWrapper" variant="h3" text={t('notificationsTitle3')} />
      {renderNotificationItem('chatPush', 'notificationTitle1', 'notificationExplain1')}
      {renderNotificationItem('messagePush', 'notificationTitle2', 'notificationExplain2')}
    </StyledNotifications>
  )
}

export default SettingsNotifications
