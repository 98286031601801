import React from 'react'

export const requestSent = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
    <circle cx="100.004" cy="100" r="100" fill={color.primary.lightest} />
    <path
      d="M50 81.4999L98.15 112.144C98.7 112.55 99.35 112.75 100 112.75C100.65 112.75 101.3 112.55 101.85 112.144L150 81.4999L101.875 44.6249C100.763 43.7937 99.2375 43.7937 98.125 44.6249L50 81.4999Z"
      fill={color.primary.lighter}
    />
    <path
      d="M64.5 44C63.3954 44 62.5 44.8954 62.5 46V116.733C62.5 117.838 63.3954 118.733 64.5 118.733H135.5C136.605 118.733 137.5 117.838 137.5 116.733V46C137.5 44.8954 136.605 44 135.5 44H64.5Z"
      fill={color.general.white}
    />
    <path
      d="M100 89C107.732 89 114 82.732 114 75C114 67.268 107.732 61 100 61C92.268 61 86 67.268 86 75C86 82.732 92.268 89 100 89Z"
      fill={color.status.success}
    />
    <path
      d="M106.613 70.1271C107.382 70.9059 107.382 72.1581 106.613 72.9369L99.585 80.0578C98.8019 80.8512 97.5212 80.8512 96.7381 80.0578L93.3867 76.6622C92.6179 75.8834 92.6179 74.6313 93.3867 73.8524L93.5692 73.6675C94.3523 72.8741 95.6329 72.8741 96.416 73.6675L98.1616 75.436L100 73.5733L103.584 69.9422C104.367 69.1488 105.648 69.1488 106.431 69.9422L106.613 70.1271Z"
      fill={color.general.white}
    />
    <path
      d="M101.85 112.144C101.3 112.55 100.65 112.75 100 112.75C99.35 112.75 98.7 112.55 98.15 112.144L50 81.5V137.75C50 141.2 52.8 144 56.25 144H143.75C147.2 144 150 141.2 150 137.75V81.5L101.85 112.144Z"
      fill={color.primary.light}
    />
    <path
      d="M53 144C51.3431 144 50 142.657 50 141V138H50.0047C50.0016 137.917 50 137.834 50 137.75C50 136.756 50.475 135.819 51.275 135.231L98.15 103.981C98.7 103.575 99.35 103.375 100 103.375C100.65 103.375 101.3 103.575 101.85 103.981L148.725 135.231C149.525 135.819 150 136.756 150 137.75C150 137.834 149.998 137.917 149.995 138H150V141C150 142.657 148.657 144 147 144H53Z"
      fill={color.status.warning}
    />
  </svg>
)
