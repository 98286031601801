import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects'

import {
  GET_CHAT_DETAILS,
  GET_CHAT_DETAILS_ERROR,
  GET_CHAT_DETAILS_SUCCESS,
  GET_CHATS_LIST,
  GET_CHATS_LIST_NOT_CLOSED,
  GET_CHATS_LIST_NOT_CLOSED_SUCCESS,
  GET_CHATS_LIST_ERROR,
  GET_CHATS_LIST_SUCCESS,
  SET_EVENT_TO_CHAT_HISTORY,
  SET_EVENT_TO_CHAT_HISTORY_SUCCESS,
  SEARCH_CHAT_MESSAGE,
  SET_FOUND_MESSAGES_IDS,
  GET_CHAT_DETAILS_ON_SCROLL,
  GET_CHAT_DETAILS_ON_SCROLL_SUCCESS,
  SET_EVENT_TO_CHAT_HISTORY_ERROR,
  FIND_ALL_MESSAGES,
  GET_ONLINE_CONTACTS_SUCCESS,
  SEARCH_CHAT_MESSAGE_SUCCESS,
  SEARCH_CHAT_MESSAGE_ERROR,
  SET_FOUND_MESSAGES_IDS_ERROR,
} from '../constants'
import { buildRequest, fetchData, showError } from '../../api'
import {
  selectChatsList,
  selectContactsOnline,
  selectCurrentChatId,
  selectFirstMessageId,
  selectLastHistoryMessageId,
  selectLastMessageIdCurrent,
  selectUserMemberId,
} from '../selectors'
import { doGetTableData } from './data'
import { MAX_BACKEND_LIMIT, SEARCH_VALUE_MIN_LENGTH } from '../../constants'
import { setContactOnlineStatus } from '../actions'
import { doGetFileUrl } from './fileUpload'

const CLOSED_CHATS_LIMIT = 15
export const CHAT_DETAILS_LIMIT = 35

function* doGetChatsList({ payload: { queryParams = [], status, isInfiniteScroll, searchValue } }) {
  if (status) {
    queryParams.push({ key: 'status', value: status })
  }

  if (status === 'closed' && !queryParams.some(({ key }) => key === 'limit')) {
    queryParams.push({ key: 'limit', value: CLOSED_CHATS_LIMIT })
  }

  if (searchValue?.length >= SEARCH_VALUE_MIN_LENGTH) {
    queryParams.push({ key: 'searchValue', value: searchValue }, { key: 'searchFields', value: 'data' })
  }

  // if (status && ['closed', 'open'].includes(status)) {
  //   const currentUserId = yield select(selectUserMemberId)
  //
  //   queryParams.push({ key: 'owner_id', value: +currentUserId })
  // }

  queryParams.push({
    key: 'sort',
    value: `${status === 'closed' ? 'status_changed_at' : 'last_message_at'}=desc`,
  })

  try {
    const request = yield buildRequest({ apiMethod: 'GET', type: 'chats', queryParams })
    const response = yield fetchData(request)

    const closedCount = status === 'closed' ? response.total : ''

    yield showError(response)

    if (status) {
      yield put({
        type: GET_CHATS_LIST_SUCCESS,
        payload: { ...response, status, closedCount, isInfiniteScroll },
      })
    } else {
      return response
    }
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CHATS_LIST_ERROR })
  }
}

function* doGetCurrentUserDepartments() {
  try {
    const userMemberId = yield select(selectUserMemberId)
    const items = yield doGetTableData({
      payload: {
        type: 'members',
        status: 'active',
        queryParams: [{ key: 'id', value: userMemberId }],
        noReduxSet: true,
      },
    })

    return items?.[0]?.departments
  } catch (error) {
    console.error(error)
  }
}

function transformForwardedChatInfo(chat, currentUserId, currentUserDepartments) {
  const departments = currentUserDepartments?.map((department) => department.id).filter((dep) => !!dep)
  const isChatFromCurrentUser = +chat.chat_data.from_operator_id === +currentUserId
  const isChatToCurrentUser = +chat.chat_data.to_operator_id === +currentUserId
  const isChatToCurrentUserDepartments = departments && departments.includes(+chat.chat_data.to_department_id)

  if (isChatFromCurrentUser) {
    return {
      ...chat,
      status: 'open',
      isChatFromCurrentUser,
      isForwarded: true,
    }
  }

  if (isChatToCurrentUser || isChatToCurrentUserDepartments) {
    return {
      ...chat,
      status: 'new',
      isChatToCurrentUser,
      isChatToCurrentUserDepartments,
      isForwarded: true,
    }
  }

  return chat
}

function* doGetChatById({ chatId }) {
  try {
    const newChatResponse = yield doGetChatsList({
      payload: { queryParams: [{ key: 'id', value: chatId }] },
    })

    const currentUserDepartments = yield doGetCurrentUserDepartments()

    let newChat = newChatResponse?.items?.[0]
    let chatResult

    if (newChat?.status === 'forwarded') {
      const currentUserId = yield select(selectUserMemberId)

      chatResult = transformForwardedChatInfo(newChatResponse.items[0], currentUserId, currentUserDepartments)
    } else {
      chatResult = newChat
    }

    return chatResult
  } catch (error) {
    console.error(error)
  }
}

function* doGetOnlineContactsIds() {
  try {
    const request = yield buildRequest({ apiMethod: 'GET', type: 'contactsOnline' })
    const response = yield fetchData(request)

    yield put({ type: GET_ONLINE_CONTACTS_SUCCESS, payload: response })
  } catch (error) {
    console.error(error)
  }
}

function* doGetChatsListNotClosed({ searchValue }) {
  const queryParams = [
    { key: 'status__ne', value: 'closed' },
    { key: 'sort', value: 'last_message_at=desc' },
    { key: 'limit', value: MAX_BACKEND_LIMIT },
  ]

  if (searchValue?.length >= SEARCH_VALUE_MIN_LENGTH) {
    queryParams.push({ key: 'searchValue', value: searchValue }, { key: 'searchFields', value: 'data' })
  }

  try {
    const currentUserId = yield select(selectUserMemberId)
    const currentUserDepartments = yield doGetCurrentUserDepartments()

    const response = yield doGetChatsList({ payload: { queryParams } })

    const contactsOnline = yield select(selectContactsOnline)
    if (!contactsOnline) {
      yield doGetOnlineContactsIds()
    }

    const chatsByStatuses = response.items.reduce((allChats, chat) => {
      const { status } = chat

      const isForwardedChat = status === 'forwarded'

      const getChatsToSet = (status, chatToSet) => {
        return allChats[status] ? [...allChats[status], chatToSet || chat] : [chatToSet || chat]
      }

      const allChatsToSet = {
        ...allChats,
        [status]: getChatsToSet(status),
      }

      if (isForwardedChat) {
        const transformedChat = transformForwardedChatInfo(chat, currentUserId, currentUserDepartments)

        if (transformedChat.isChatFromCurrentUser) {
          allChatsToSet.open = getChatsToSet('open', transformedChat)
        }

        if (transformedChat.isChatToCurrentUser || transformedChat.isChatToCurrentUserDepartments) {
          allChatsToSet.new = getChatsToSet('new', transformedChat)
        }
      }

      return allChatsToSet
    }, {})

    if (!chatsByStatuses.new) {
      chatsByStatuses.new = []
    }

    if (!chatsByStatuses.open) {
      chatsByStatuses.open = []
    }

    const payload = {
      ...chatsByStatuses,
      // chatInfo: newChatInfo || currentChatInfo,
    }

    yield put({
      type: GET_CHATS_LIST_NOT_CLOSED_SUCCESS,
      payload,
    })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CHATS_LIST_ERROR })
  }
}

function* doMapEventWithFiles(event, isHistoryFirstLoadWithPictures) {
  try {
    let updatedEvent = { ...event }
    if (updatedEvent.operator_pic && !updatedEvent.operator_pic.startsWith('http')) {
      const operatorAvatar = yield call(doGetFileUrl, { file_code: updatedEvent.operator_pic })
      updatedEvent = { ...updatedEvent, operator_pic: operatorAvatar.url }
    }
    if (updatedEvent.data?.files?.length) {
      const filesWithUrls = yield all(updatedEvent.data.files.map((file) => call(doGetFileUrl, file)))
      if (isHistoryFirstLoadWithPictures) {
        isHistoryFirstLoadWithPictures.value = true
      }

      updatedEvent = { ...updatedEvent, data: { ...updatedEvent.data, files: filesWithUrls } }
    }
    return updatedEvent
  } catch (error) {
    console.error(error)
  }
}

function* doGetDataWithFileUrls(data, isHistoryFirstLoadWithPictures) {
  try {
    return yield all(data.map((el) => call(doMapEventWithFiles, el, isHistoryFirstLoadWithPictures)))
  } catch (error) {
    console.error(error)
  }
}

function* doGetChatDetails({ payload: { chatId } }) {
  // getting chat details on selecting new chat
  try {
    const queryParams = [
      { key: 'chat_id', value: chatId },
      { key: 'limit', value: CHAT_DETAILS_LIMIT },
    ]

    queryParams.push({ key: 'sort', value: 'id=desc' })

    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'chatDetails',
      queryParams,
    })
    const response = yield fetchData(request)

    yield showError(response)

    let chatData
    // get and set the current chat info when clicked on the chat
    chatData = yield doGetChatById({ chatId })

    const isHistoryFirstLoadWithPictures = { value: false }

    const items = yield doGetDataWithFileUrls(response.items, isHistoryFirstLoadWithPictures)

    yield put({
      type: GET_CHAT_DETAILS_SUCCESS,
      payload: {
        chatData,
        history: items.reverse(), // reverse for the very first history load
        total: response.total,
        allMessagesLoadedUp: response.total === items.length || CHAT_DETAILS_LIMIT > items.length,
        allMessagesLoadedDown: true,
        limit: CHAT_DETAILS_LIMIT,
        isHistoryFirstLoadWithPictures: isHistoryFirstLoadWithPictures.value,
        lastMessageId: items[items.length - 1]?.id,
      },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CHAT_DETAILS_ERROR })
  }
}

function* doGetChatDetailsOnScroll({ payload: { isScrollUp, before = CHAT_DETAILS_LIMIT } }) {
  try {
    const limit = isScrollUp ? before + 1 : before * 2 + 1
    const thresholdMessageId = isScrollUp
      ? yield select(selectFirstMessageId)
      : yield select(selectLastMessageIdCurrent)

    const queryParams = [
      { key: 'limit', value: limit },
      { key: 'before', value: before },
    ]

    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'chatFoundNear',
      queryParams,
      id: thresholdMessageId,
    })
    const response = yield fetchData(request)

    yield showError(response)

    const targetItemIndex = response.items.findIndex((el) => el.id === thresholdMessageId)
    const allMessagesLoadedInScrollDirection = isScrollUp
      ? targetItemIndex !== before
      : response.items.length < limit

    let finalResult

    if (isScrollUp) {
      if (allMessagesLoadedInScrollDirection) {
        finalResult = response.items.slice(0, targetItemIndex) // remove messages that are already loaded
      } else {
        finalResult = [...response.items]
        finalResult.pop() // remove last item (it's the same as thresholdMessageId)
      }
    } else {
      if (allMessagesLoadedInScrollDirection) {
        finalResult = response.items.slice(targetItemIndex + 1) // remove messages that are already loaded
      } else {
        finalResult = response.items.slice(before + 1) // start from the next item after the threshold
      }
    }

    const items = yield doGetDataWithFileUrls(finalResult)
    yield put({
      type: GET_CHAT_DETAILS_ON_SCROLL_SUCCESS,
      payload: {
        history: items,
        isScrollUp,
        allMessagesLoadedInScrollDirection,
      },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CHAT_DETAILS_ERROR })
  }
}

function* doFindAllMessages({ payload: { chatId, searchValue } }) {
  const queryParams = [
    { key: 'chat_id', value: chatId },
    { key: 'limit', value: CHAT_DETAILS_LIMIT },
    { key: 'sort', value: 'id=asc' },
  ]

  queryParams.push({ key: 'searchValue', value: searchValue }, { key: 'searchFields', value: 'data' })

  try {
    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'chatDetails',
      queryParams,
    })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({
      type: SET_FOUND_MESSAGES_IDS,
      payload: response.items.map((el) => el.id),
    })
  } catch (error) {
    console.error(error)
    yield put({ type: SET_FOUND_MESSAGES_IDS_ERROR })
  }
}

function* doSearchChatMessage({ payload: { chatId, messageId } }) {
  try {
    const queryParams = [
      // { key: 'before', value: 50 },
      { key: 'before', value: CHAT_DETAILS_LIMIT },
      { key: 'sort', value: 'id=asc' },
      { key: 'limit', value: CHAT_DETAILS_LIMIT * 2 + 1 },
      // { key: 'limit', value: 100 },
    ]

    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'chatFoundNear',
      queryParams,
      id: messageId,
    })
    const response = yield fetchData(request)

    yield showError(response)

    const targetItemIndex = response.items.findIndex((el) => el.id === messageId)
    const allMessagesLoadedUp = targetItemIndex < CHAT_DETAILS_LIMIT
    const allMessagesLoadedDown = targetItemIndex > CHAT_DETAILS_LIMIT

    const items = yield doGetDataWithFileUrls(response.items)

    yield put({
      type: SEARCH_CHAT_MESSAGE_SUCCESS,
      payload: {
        history: items,
        allMessagesLoadedUp,
        allMessagesLoadedDown,
      },
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: SEARCH_CHAT_MESSAGE_ERROR,
    })
  }
}

function* doSetEventToChatHistory({ payload: { data, type, isReceivedEvent } }) {
  try {
    const currentChatId = yield select(selectCurrentChatId)
    const lastMessageIdFromServer = yield select(selectLastHistoryMessageId)
    const lastMessageIdReduxState = yield select(selectLastMessageIdCurrent)

    let newChatData
    if (data.chat_id) {
      newChatData = yield doGetChatById({ chatId: data.chat_id })
    }

    // isChatFromCurrentUser,
    //     isChatToCurrentUser,
    //     isChatToCurrentUserDepartments,

    const payload = {}

    if (type === 'chat_started') {
      payload.status = 'new'
      payload.chatToSet = { data: newChatData }
      payload.chatsToDelete = [{ status: 'open', id: data.chat_id }] // why from opened?
    }

    if (type === 'chat_closed') {
      // delete from opened, add to closed top
      payload.status = 'closed'
      payload.chatToSet = { data: { ...newChatData, status: 'closed' } }
      payload.chatsToDelete = [{ status: 'open', id: data.chat_id }]

      if (isReceivedEvent) {
        payload.chatsToDelete = [{ status: 'open', id: data.chat_id }]
      }
    }

    if (type === 'operator_joined') {
      if (!isReceivedEvent) {
        return
      }
      const currentOperatorId = yield select(selectUserMemberId)
      const isCurrentOperatorAcceptedChat = data?.operator_id === currentOperatorId

      if (isCurrentOperatorAcceptedChat) {
        // if accept button is clicked by current operator, add new chat to opened chats and delete chat from new chats
        payload.status = 'open'
        payload.chatToSet = { data: newChatData }
        payload.chatsToDelete = [{ status: 'new', id: data.chat_id }]
      }

      // if another operator joined chat, delete chat from open chats if chat was forwarded
      if (!isCurrentOperatorAcceptedChat) {
        payload.chatsToDelete = [
          { status: 'open', id: data.chat_id },
          { status: 'new', id: data.chat_id },
        ]
      }
    }

    if (type === 'forwarded_operator' || type === 'forwarded_department') {
      if (newChatData?.isChatFromCurrentUser) {
        payload.status = 'open'
        payload.chatToSet = { data: newChatData } // add transformed chat data to top of opened chats
        payload.chatsToDelete = [{ status: 'open', id: newChatData.id }] // delete old chat data from opened chats
      } else if (newChatData?.isChatToCurrentUserDepartments || newChatData?.isChatToCurrentUser) {
        // if forward event is received
        payload.status = 'new'
        payload.chatToSet = { data: newChatData } // add transformed chat data to top of new chats
      } else {
        // if chat that was previously forwarded to you was forwarded to another operator
        payload.chatsToDelete = [{ status: 'new', id: data.chat_id }]
      }
    }

    if (['contact_message', 'operator_message', 'system_message'].includes(type)) {
      if (!newChatData) {
        return
      }
      // replace chat data in new or opened chats
      payload.status = newChatData.status
      payload.chatToSet = { data: newChatData, id: newChatData.id }

      if (type === 'contact_message') {
        // set online status for this guest
        yield put(setContactOnlineStatus(true, data.contact_id))
      }

      if (data.status === 'new') {
        if (isReceivedEvent) {
          // if closed chat becomes new, delete chat from closed
          const closedChats = yield select(selectChatsList('closed'))
          if (closedChats.some((el) => el.id === data.chat_id)) {
            payload.chatsToDelete = [{ status: 'closed', id: data.chat_id }]
          }
        }
      }
    }

    // only chats list last message is updated (when search is active), not chat details
    if (lastMessageIdFromServer === lastMessageIdReduxState && +data.chat_id === +currentChatId) {
      const dataWithFileUrls = yield call(doGetDataWithFileUrls, [data])

      payload.event = dataWithFileUrls[0]
    }

    payload.eventType = data.type
    // if (type === 'operator_rated') {
    // }

    yield put({
      type: SET_EVENT_TO_CHAT_HISTORY_SUCCESS,
      payload,
    })
  } catch (error) {
    console.error(error)
    yield put({ type: SET_EVENT_TO_CHAT_HISTORY_ERROR })
  }
}

export default function* () {
  return [
    yield takeLatest(GET_CHATS_LIST, doGetChatsList),
    yield takeLatest(GET_CHATS_LIST_NOT_CLOSED, doGetChatsListNotClosed),
    yield takeEvery(SET_EVENT_TO_CHAT_HISTORY, doSetEventToChatHistory), // TODO: after backend fix, change this to takeEvery cause chat_started after chat was closed is not set
    yield takeLatest(GET_CHAT_DETAILS, doGetChatDetails),
    yield takeLatest(GET_CHAT_DETAILS_ON_SCROLL, doGetChatDetailsOnScroll),
    yield takeLatest(FIND_ALL_MESSAGES, doFindAllMessages),
    yield takeLatest(SEARCH_CHAT_MESSAGE, doSearchChatMessage),
  ]
}
