import React from 'react'

export const zoomArrows = ({ width, height, fill, theme }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_12767_105749)">
      <path
        d="M6.28655 6.28582L0.572266 0.571533"
        stroke={fill || theme.color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14369 0.571533H0.572266V5.14296"
        stroke={fill || theme.color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.71484 9.71436L15.4291 15.4286"
        stroke={fill || theme.color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8574 15.4286H15.4289V10.8572"
        stroke={fill || theme.color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12767_105749">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
