import React from 'react'

export const checkMailbox = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
    <g clipPath="url(#clip0_9115_101693)">
      <circle cx="100" cy="100" r="100" fill={color.primary.lightest} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150 88C137.85 88 128 78.1503 128 66C128 65.8748 128.001 65.7498 128.003 65.625H56.25C52.8 65.625 50 68.425 50 71.875V128.125C50 131.575 52.8 134.375 56.25 134.375H143.75C147.2 134.375 150 131.575 150 128.125V88Z"
        fill={color.primary.light}
      />
      <path
        d="M143.75 134.375H56.25C52.7438 134.375 50 131.631 50 128.125C50 127.131 50.475 126.194 51.275 125.606L98.15 94.3563C98.7 93.95 99.35 93.75 100 93.75C100.65 93.75 101.3 93.95 101.85 94.3563L148.725 125.606C149.525 126.194 150 127.131 150 128.125C150 131.631 147.256 134.375 143.75 134.375Z"
        fill={color.status.warning}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.003 65.625C128.001 65.7498 128 65.8748 128 66C128 72.9991 131.268 79.2349 136.362 83.2642L101.819 108.062C100.731 108.844 99.2625 108.844 98.175 108.062L51.275 74.3938C50.475 73.8063 50 72.8688 50 71.875C50 68.3688 52.7438 65.625 56.25 65.625H128.003Z"
        fill={color.primary.lighter}
      />
      <path
        d="M150 84C159.941 84 168 75.9411 168 66C168 56.0589 159.941 48 150 48C140.059 48 132 56.0589 132 66C132 75.9411 140.059 84 150 84Z"
        fill={color.status.success}
      />
      <path
        d="M152.5 58V74H148.71V62.4319H148.605L144.5 64.979V60.7809L148.938 58H152.5Z"
        fill={color.general.white}
      />
    </g>
    <defs>
      <clipPath id="clip0_9115_101693">
        <rect width="200" height="200" fill={color.general.white} />
      </clipPath>
    </defs>
  </svg>
)
