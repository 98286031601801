import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ChatsListSearch from './ChatsListSearch'
import ChatsList from '../panels/ChatsList'
import { useChatsCommonWebsocket } from '../useChatsWebsockets'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectAllChatsLists, selectCurrentChatInfo } from '../../../redux-saga/selectors'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'

const ChatsLeftPanel = () => {
  const { t } = useTranslation('all')
  const navigate = useNavigate()

  const { chatId } = useParams()
  const chatInfo = useMappedState(selectCurrentChatInfo)
  const chatsLists = useMappedState(selectAllChatsLists)

  const chats = [{ new: chatsLists.new }, { open: chatsLists.open }, { closed: chatsLists.closed }]

  useEffect(() => {
    if (isObjectEmpty(chatInfo) && chatId) {
      navigate('/chats')
    }
  }, [chatInfo])

  useChatsCommonWebsocket(chatId)

  return (
    <>
      <ChatsListSearch />
      {chats.map((chat) => {
        const chatType = Object.keys(chat)[0]

        return <ChatsList key={chatType} type={chatType} t={t} chatsData={chat[chatType]} />
      })}
    </>
  )
}

export default ChatsLeftPanel
