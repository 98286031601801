import React from 'react'
import ReactTexty from 'react-texty'

import { Typography } from '../../../index'

const TableCell = ({ className, cellData, column }) => {
  // console.log(column)
  // if (column.cellRenderer) {
  //   return (
  //     <ReactTexty key={column.key} className={className}>
  //       {column.cellRenderer()}
  //     </ReactTexty>
  //   )
  // }
  //
  // if (column.isLink) {
  //   return (
  //     <Link href={cellData} key={column.key} {...column.linkProps}>
  //       <ReactTexty className={className}>{cellData}</ReactTexty>
  //     </Link>
  //   )
  // }

  return (
    <ReactTexty key={column.key} className={className}>
      <Typography variant="body2">{cellData === 0 ? '0' : cellData}</Typography>
    </ReactTexty>
  )
}

export default TableCell
