import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'

import { FlexRow, Typography, Switch, Input, Tooltip, Link } from '../../../atomic-design-components'
import NameWithLogo from '../../../components/NameWithLogo'
import { StyledCardContent, StyledLine } from '../styled'
import { upsertSidebarItem } from '../../../redux-saga/actions'
import { BOTS_URLS_START } from '../../../constants'

const SwitchableCard = ({ cardProps }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    initialData,
    bot,
    disableTooltip,
    inputValue,
    isActive,
    isWidgetActive,
    isConnected,
    currBot,
    onChannelActivate,
    onTokenAdd,
    onTokenRemove,
    getConnectedText,
    theme,
    t,
  } = cardProps
  const [tokenValue, setTokenValue] = useState('')

  const channel = initialData?.channels[bot]

  useEffect(() => {
    setTokenValue(inputValue)
  }, [inputValue])

  const isInputDisabled = isWidgetActive ? !!isConnected : true

  const transformInputValue = (value) => {
    if (isConnected) {
      if (value === '') {
        return value
      } else {
        return `... ${value.substr(-4)}`
      }
    }
    return value
  }

  const onMetaPageConnect = () => {
    window.FB.getLoginStatus(function (response) {
      console.log(response, 'status')
    })

    let scope = 'pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement'
    if (bot === 'instagram') {
      scope = 'instagram_basic,instagram_manage_messages'
    }

    window.FB.login(
      function (response) {
        let dataToSend
        if (response?.authResponse?.accessToken) {
          dataToSend = {
            ...initialData,
            channels: {
              ...initialData.channels,
              [bot]: {
                accessToken: response.authResponse.accessToken,
                userID: response.authResponse.userID,
                status: 'active',
              },
            },
          }

          dispatch(
            upsertSidebarItem({
              id: initialData?.id,
              type: 'widgets',
              requestBody: dataToSend,
              navigate,
            })
          )
        }

        console.log(response, 'login')
      },
      {
        scope,
      }
    )
  }

  const isLiveChatConnected = bot === 'live_chat' && isConnected
  const isChatBotWithToken = bot === 'telegram' || bot === 'viber'

  return (
    <StyledCardContent
      key={currBot.name}
      className={clsx(isActive && 'active', !isWidgetActive && 'disabled')}
    >
      <FlexRow className="switchableCardItemRow" justifyContent="space-between">
        <FlexRow style={{ overflow: 'hidden' }}>
          <NameWithLogo iconName={`${currBot.iconName}Icon`} name={t(currBot.name)} />
        </FlexRow>

        <Tooltip
          text={t(currBot.hint)}
          color={theme.color.general.darkest}
          arrowPosition="right"
          left="-100px"
          whiteSpace="pre-wrap"
          disableTooltip={disableTooltip}
        >
          <Switch
            checked={isActive}
            disabled={!isConnected || !isWidgetActive}
            onChange={onChannelActivate}
            label={isWidgetActive && isConnected ? (isActive ? 'On' : 'Off') : ''}
            textVariant="button1"
            id={bot}
          />
        </Tooltip>
      </FlexRow>

      <StyledLine />
      {!isLiveChatConnected && currBot.middleRow && (
        <FlexRow className="switchableCardItemRow">
          {currBot.middleRow.text && !isConnected && (
            <Typography text={t(currBot.middleRow.text)} variant="body2" />
          )}

          {isChatBotWithToken && (
            <Input
              label={currBot.middleRow.name}
              placeholder={t(currBot.middleRow.placeholder)}
              placeholderColor={theme.color.general.dark}
              fullWidth
              value={transformInputValue(tokenValue)}
              onChange={(event) => setTokenValue(event.target.value)}
              disabled={isInputDisabled}
              onSubmit={onTokenAdd}
              onBlur={onTokenAdd}
              iconRightProps={
                inputValue && isWidgetActive
                  ? {
                      name: 'trashCan',
                      width: 16,
                      height: 16,
                      fill: theme.color.general.black,
                      onClick: onTokenRemove,
                    }
                  : {}
              }
            />
          )}
        </FlexRow>
      )}

      <FlexRow
        className={clsx(
          bot,
          'switchableCardItemRow',
          isWidgetActive && !isConnected && !isChatBotWithToken && 'connectButton'
        )}
        justifyContent="center"
        onClick={isWidgetActive && !isConnected && !isChatBotWithToken ? onMetaPageConnect : undefined}
      >
        {/*{isWidgetActive && !isConnected && !isChatBotWithToken && (*/}
        {/*  <fb:login-button config_id="697688265419332" onLogin={checkLoginState}></fb:login-button>*/}
        {/*)}*/}
        {isConnected ? (
          <>
            <Link
              isOuterLink
              href={
                channel.bot_url ||
                `${BOTS_URLS_START[bot]}${
                  bot === 'instagram' ? channel.page_name : channel.page_id || channel.bot_name
                }`
              }
              noStyles
            >
              <Typography
                text={getConnectedText(bot, initialData, isActive, t).bot}
                variant="button1"
                className={clsx(isActive && 'active')}
                color={
                  initialData?.channels[bot]?.status === 'active'
                    ? theme.color.secondary.main
                    : theme.color.general.light
                }
                margin="0 5px 0 0"
              />
            </Link>

            <Typography
              text={getConnectedText(bot, initialData, isActive, t).text}
              variant="button1"
              className={clsx(isActive && 'active')}
              color={
                initialData?.channels[bot]?.status === 'active'
                  ? theme.color.general.darker
                  : theme.color.general.light
              }
            />
          </>
        ) : (
          <Typography
            text={t(isChatBotWithToken || bot === 'live_chat' ? 'notConnected' : 'connect')}
            variant="button1"
            color={
              isChatBotWithToken || bot === 'live_chat'
                ? theme.color.general.darker
                : theme.color.secondary.main
            }
            margin="0 auto"
          />
        )}
      </FlexRow>
    </StyledCardContent>
  )
}

export default SwitchableCard
