import merge from 'deepmerge'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ExpansionPanel, Loader, Typography } from '../../../atomic-design-components'
import SwitchableCardWithFields from '../../../components/SwitchableCardWithFields/SwitchableCardWithFields'
import { useMappedState } from '../../../hooks/useReactRedux'
import { upsertSidebarItem } from '../../../redux-saga/actions'
import { selectSidebarDetails } from '../../../redux-saga/selectors'
import { StyledAutoMessagesContainer } from '../styled'

const DELAY = {
  key: 'delay',
  label: 'delay',
  iconLeftProps: { name: 'clock' },
  type: 'number',
  isOneNumberInput: false,
  required: true,
  min: 0,
  placeholder: 0,
}

const TEXT = {
  key: 'text',
  label: 'messageText',
  placeholder: 'writeYourMessageHere',
  multiline: true,
  required: true,
}

const formConfig = {
  live_chat_delayed: [
    {
      key: 'first_message',
      tip: 'firstMessageTip',
      fields: [DELAY, TEXT],
    },
    {
      key: 'second_message',
      tip: 'secondMessageTip',
      fields: [DELAY, TEXT],
      getIsCardDisabled: (data) => data.first_message.status === 'inactive',
    },
    {
      key: 'idle_operator_new_chat',
      tip: 'messageTipIdleOperator',
      fields: [DELAY, TEXT],
    },
    {
      key: 'idle_operator_open_chat',
      tip: 'messageTipIdleOperator',
      fields: [DELAY, TEXT],
    },

    {
      key: 'idle_guest',
      tip: 'messageTipIdleGuest',
      fields: [DELAY, TEXT],
    },
  ],
  all_channels_instant: [
    {
      key: 'first_message',
      tip: 'firstMessageTipLivechat',
      fields: [TEXT],
    },
    {
      key: 'second_message',
      tip: 'secondMessageTipLivechat',
      fields: [TEXT],
      getIsCardDisabled: (data) => data.first_message.status === 'inactive',
    },
    {
      key: 'non_working_hours',
      tip: 'firstMessageTipLivechat',
      fields: [TEXT],
    },
  ],
}

const ChatWidgetsAutoMessages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['all', 'validation'])

  const initialData = useMappedState(selectSidebarDetails)

  const formDataInitial = initialData?.auto_reply_settings

  const saveAutoMessagesSettings = ({ valuesChanged, blockKey, cardKey }) => {
    let values = { [blockKey]: { [cardKey]: { ...valuesChanged } } }

    if (cardKey === 'first_message' && valuesChanged.status === 'inactive') {
      values = {
        ...values,
        [blockKey]: {
          ...values[blockKey],
          second_message: { ...values[blockKey].second_message, status: 'inactive' },
        },
      }
    }

    const updatedData = {
      ...initialData,
      auto_reply_settings: merge(formDataInitial, values),
    }

    dispatch(
      upsertSidebarItem({
        id: initialData?.id,
        type: 'widgets',
        requestBody: updatedData,
        navigate,
      })
    )
  }

  if (!initialData) {
    return <Loader size="60px" />
  }

  return (
    <StyledAutoMessagesContainer>
      {Object.keys(formConfig).map((blockKey) => {
        return (
          <ExpansionPanel
            header={<Typography text={t(blockKey)} variant="h4" />}
            panelName={blockKey}
            initialOpened={true}
            key={blockKey}
            marginBottomWhenOpened="20px"
          >
            {formConfig[blockKey].map((card) => {
              const isCardDisabled =
                card.getIsCardDisabled && card.getIsCardDisabled(formDataInitial[blockKey])

              return (
                <SwitchableCardWithFields
                  fields={card.fields}
                  cardKey={card.key}
                  key={card.key}
                  tipText={card.tip}
                  onSubmitData={saveAutoMessagesSettings}
                  blockKey={blockKey}
                  t={t}
                  values={formDataInitial[blockKey][card.key]}
                  isCardDisabled={isCardDisabled}
                />
              )
            })}
          </ExpansionPanel>
        )
      })}
    </StyledAutoMessagesContainer>
  )
}

export default ChatWidgetsAutoMessages
