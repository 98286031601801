import styled from 'styled-components'
import { Image } from '../../index'

export const StyledUserpic = styled(Image)`
  border-radius: 50%;
  width: fit-content;
  position: relative;

  .onlineIcon {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  img {
    border-radius: 50%;
    min-width: ${({ width }) => `${width}px` || '36px'};
  }
`
