import React from 'react'

export const monitor = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_13367_24890)">
      <path
        d="M13.1427 2.28711H14.857C15.0086 2.28711 15.1539 2.34731 15.2611 2.45448C15.3682 2.56164 15.4284 2.70699 15.4284 2.85854V12.0014C15.4284 12.1529 15.3682 12.2983 15.2611 12.4055C15.1539 12.5126 15.0086 12.5728 14.857 12.5728H1.14272C0.991165 12.5728 0.84582 12.5126 0.738657 12.4055C0.631493 12.2983 0.571289 12.1529 0.571289 12.0014V2.85854C0.571289 2.70699 0.631493 2.56164 0.738657 2.45448C0.84582 2.34731 0.991165 2.28711 1.14272 2.28711H2.857"
        stroke={fill || color.general.dark}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.85672 12.5723L5.71387 15.4294"
        stroke={fill || color.general.dark}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14258 12.5723L10.2854 15.4294"
        stroke={fill || color.general.dark}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57129 15.4297H11.4284"
        stroke={fill || color.general.dark}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.37133 2.69782C7.42053 2.5711 7.50688 2.46222 7.61905 2.38544C7.73122 2.30866 7.86398 2.26758 7.99991 2.26758C8.13583 2.26758 8.26859 2.30866 8.38076 2.38544C8.49293 2.46222 8.57928 2.5711 8.62848 2.69782L9.3599 4.5721H11.3142C11.4531 4.57415 11.5883 4.61767 11.7024 4.69709C11.8164 4.77651 11.9041 4.8882 11.9542 5.01782C11.9928 5.13941 11.9961 5.26947 11.9637 5.39287C11.9314 5.51627 11.8646 5.62796 11.7713 5.71496L10.0456 7.24639L10.777 8.70925C10.8433 8.83767 10.8657 8.98421 10.8409 9.12657C10.8162 9.26892 10.7456 9.39929 10.6399 9.49782C10.5368 9.60073 10.4031 9.66748 10.2589 9.68808C10.1147 9.70868 9.9677 9.68203 9.83991 9.6121L7.99991 8.60639L6.1599 9.6121C6.03211 9.68203 5.8851 9.70868 5.74089 9.68808C5.59667 9.66748 5.46301 9.60073 5.35991 9.49782C5.25422 9.39929 5.18363 9.26892 5.15887 9.12657C5.13412 8.98421 5.15655 8.83767 5.22276 8.70925L5.95419 7.24639L4.22848 5.71496C4.12633 5.62424 4.05415 5.50463 4.0215 5.37197C3.98884 5.23932 3.99726 5.09987 4.04562 4.9721C4.10265 4.85103 4.19332 4.74894 4.30681 4.67801C4.4203 4.60708 4.5518 4.57031 4.68562 4.5721H6.6399L7.37133 2.69782Z"
        stroke={fill || color.general.dark}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13367_24890">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
