import styled from 'styled-components'

import { FlexRow } from '../../../atomic-design-components'

export const StyledFlexRow = styled(FlexRow)`
  max-width: inherit;

  .pendingMemberDelete {
    position: absolute;
    left: 20px;
    right: 20px;
    height: 44px;

    &.general:hover:not(.disabled) {
      opacity: 1;
      background-color: ${({ theme }) => theme.color.status.error};
    }
  }

  button:nth-child(2) {
    max-width: calc(100% - 95px);
    flex-grow: 1;
  }
`

export const StyledNameRow = styled(FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
  .name {
    white-space: break-spaces;
  }
`
