import React from 'react'

import { FlexRow } from '../../../atomic-design-components'
import { StyledCardContent } from './styled'
// you should use name of column keys from config to get data here (not dataKeys): data.key

const BulkMessagingTableCard = ({ data, t }) => {
  return (
    <StyledCardContent>
      <FlexRow className="cardTableItemRow">
        <FlexRow>
          {data.status}
          {data.name}
        </FlexRow>
        {t('at')} {data.date}
      </FlexRow>
      <FlexRow className="cardTableItemRow" style={{ height: '40px' }}>
        {data.messengers}
        {data.tags}
      </FlexRow>
    </StyledCardContent>
  )
}

export default BulkMessagingTableCard
