import React, { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import MyCompany from '../MyCompany'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectCurrentSubscription, selectMainData } from '../../redux-saga/selectors'
import TableBlock from '../../components/TableBlock'
import ChatWidgetsTableCard from './components/ChatWidgetsTableCard'
import { useWidth } from '../../hooks/useWidth'

const ChatWidgets = ({ theme }) => {
  const { t } = useTranslation('all')
  const params = useParams()
  const navigate = useNavigate()
  const urlId = params?.rightPanelId

  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.md

  const data = useMappedState(selectMainData('widgets')) || []

  const planData = useMappedState(selectCurrentSubscription)
  const { limits, counts } = planData || {}
  const widgetsTotal = counts?.widgets

  const isAddingNewAllowedByBilling = widgetsTotal < limits?.widgets

  const [isNavMenuExtended, setIsNavMenuExtended] = useState(false)

  const onIconClick = () => {
    setIsNavMenuExtended((prev) => !prev)
    navigate('../my-company')
  }

  // RightPanelComponent={urlId && <Outlet />}
  return (
    <MyCompany
      RightPanelComponent={
        <Outlet
          context={{ isAddingNewAllowedByBilling: planData ? isAddingNewAllowedByBilling : undefined }}
        />
      }
      className="withBackIcon"
    >
      <TableBlock
        tableData={data}
        navbarProps={{
          buttonProps: {
            tooltipProps: isAddingNewAllowedByBilling
              ? undefined
              : {
                  tipPosition: 'bottom',
                  arrowPosition: 'left',
                  left: '0',
                  text: t('upgradeYourPlanToAdd'),
                },
            iconLeftProps: { name: 'plusCircle' },
            text: t('addChatWidget'),
            variant: 'primary',
            hoverType: 'opacity',
            className: isAddingNewAllowedByBilling ? '' : 'disabled',
            onClick: () => {
              if (isAddingNewAllowedByBilling) {
                navigate('/my-company/chat-widgets/new')
              }
            },
          },
          withSearch: false,
        }}
        activeRowId={urlId}
        type="widgets"
        iconEmpty="emptyChatWidgets"
        TableCardContent={ChatWidgetsTableCard}
        tableCardHeight={100}
        withMenuIcon={isMobile}
        menuIconProps={{
          opened: isNavMenuExtended,
          onClick: onIconClick,
          fill: theme.color.general.light,
          iconName: 'paginationArrow',
        }}
        // rightPanelIsOpened={rightPanelIsOpened}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
        // user={user}
      />
    </MyCompany>
  )
}

export default withTheme(ChatWidgets)
