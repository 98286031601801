import styled from 'styled-components'

export const StyledLoader = styled.div`
  position: absolute;
  top: 40vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 2;

  &.withOverlay {
    background-color: ${({ theme }) => `${theme.color.general.lighter}7d`};
    top: 0;
    bottom: 0;
    align-items: center;
  }

  ${({ top }) => top && `top: ${top};`}
  ${({ left }) => left && `left: ${left};`}

  .icon {
    width: ${({ size }) => size || '30px'};
    height: ${({ size }) => size || '30px'};
  }

  .rotate-center {
    -webkit-animation: rotate-center 1s cubic-bezier(0.86, 0, 0.07, 1) infinite both;
    animation: rotate-center 1s cubic-bezier(0.86, 0, 0.07, 1) infinite both;
    transform-origin: center;
  }

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
