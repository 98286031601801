import { createContext, useCallback, useEffect, useState } from 'react'
import { useWidth } from './useWidth'

export const ScreenContext = createContext(undefined)

export const useScreenContext = (theme) => {
  const isClient = typeof window !== 'undefined'

  const width = useWidth()
  const {
    breakpoints,
    breakpoints: { xl, lg, md, sm, xs },
  } = theme

  const getHeight = () => (isClient ? document.documentElement.clientHeight : undefined)
  const height = getHeight()

  const getCurrentBreakpoint = useCallback(
    (setCurrentBreakpoint) => {
      for (const [key, value] of Object.entries(breakpoints)) {
        if (width && width >= value) {
          if (setCurrentBreakpoint) {
            setCurrentBreakpoint(key)
          } else {
            return key
          }
          break
        } else if (width && width < sm) {
          if (setCurrentBreakpoint) {
            setCurrentBreakpoint('xs')
          } else {
            return 'xs'
          }
        }
      }
    },
    [theme, width]
  )

  const [currentBreakpoint, setCurrentBreakpoint] = useState(getCurrentBreakpoint)

  useEffect(() => {
    getCurrentBreakpoint(setCurrentBreakpoint)
  }, [getCurrentBreakpoint])

  return { width, currentBreakpoint, xl, lg, md, sm, xs, height }
}
