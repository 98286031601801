import styled from 'styled-components'

export const StyledHours = styled.div`
  max-width: 660px;
  margin: 10px auto;
  padding: 20px;

  .headerWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};

    .body2 {
      margin: 10px 0;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  .timezoneInput {
    margin-bottom: 10px;
    font-size: 15px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin: 20px 30px;
  }
`
export const StyledDay = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  &:not(last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
  }

  .inputContainer {
    margin-bottom: 0;
  }
  .caption1 {
    width: 150px;
  }
  label {
    width: 120px;
  }
  .timeContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .button1 {
    margin: 0 14px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 0;
    .timeContainer {
      margin-left: 0;
    }
  }
`
