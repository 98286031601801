import { pick } from 'dot-object'

import { LANGUAGES_FULL_NAME } from '../locales'

export const transformStringSelectValue = (value, labelKey, lng, valueKey) => {
  if (typeof value === 'object') {
    return value
  }

  return {
    [valueKey || 'id']: value,
    [labelKey || 'label']: value[lng] || value,
  }
}

export const getSelectLanguageValue = (lngKey) => {
  const name = LANGUAGES_FULL_NAME[lngKey]

  return {
    id: lngKey,
    label: name,
  }
}

export const getSelectOptions = (field, optionsData, lng) => {
  if (!field.options && !field.optionsKeys && !field.getOptions) {
    return
  }
  const valueKey = field.valueKey || 'id'
  const labelKey = field.labelKey || 'label'

  console.log('HERE: ' + field.label)

  const getOptionsArr = field.getOptions && field.getOptions(optionsData)

  const optionsArr =
    getOptionsArr ||
    field.options ||
    field.optionsKeys?.reduce((acc, curr) => {
      const rawOptions = optionsData[curr] || {}
      const options = Object.values(rawOptions)

      return [...acc, ...options]
    }, [])

  return optionsArr[0]?.[valueKey] && optionsArr[0]?.[labelKey]
    ? optionsArr
    : optionsArr.map((option) =>
        field.key.toLowerCase().includes('language')
          ? getSelectLanguageValue(option)
          : transformStringSelectValue(option, field.labelKey, lng)
      )
}

export const getSelectValue = (value, options, field) => {
  // TODO: @AG, why do we have this one here?
  if (!value) {
    return ''
  }

  if (!options) {
    return value
  }
  const labelKey = field.labelKey || 'label'
  const valueKey = field.valueKey || 'id'

  if (Array.isArray(value)) {
    return value
      .map((oneValue) => options.find((option) => option[valueKey] === (oneValue[valueKey] || oneValue)))
      ?.filter((val) => val)
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return options.find((option) => option[valueKey] == value) //TODO: check inquiry value in orders
  }
  if (!pick(labelKey, value)) {
    return options.find((option) => option[valueKey] === (value[valueKey] || value))
  }

  return value
}
