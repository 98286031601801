import styled from 'styled-components'

export const StyledColor = styled.div`
  border-radius: 50%;
  border: 1px solid
    ${({ theme, color, withBorder }) =>
      withBorder ||
      color === 'white' ||
      (color && (color.toLowerCase() === '#ffffff' || color.toLowerCase() === '#fff'))
        ? theme.color.general.light
        : 'transparent'};
  background-color: ${({ color }) => color};
  width: ${({ size }) => size || 16}px;
  height: ${({ size }) => size || 16}px;
  display: inline-block;
  flex-shrink: 0;

  &.selected:not(.readOnly) {
    border-color: ${({ theme }) => theme.color.general.black};
    box-shadow: 0 0 0 2px #fff inset, 0 0 0 2px #000 inset;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
