import useWebSocket from 'react-use-websocket'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { WEBSOCKET_URL_START } from '../../api'
import { isValidJSON } from '../../utils/json'
import { EVENT_TYPE_TO_CHAT_STATUS, FILE_TYPES } from '../../constants'
import {
  getCompanyDataById,
  getCurrentSubscription,
  resetNewChatHistoryNotifications,
  setContactOnlineStatus,
  setEventToChatHistory,
  setNewChatHistoryNotification,
  setNewChatHistoryNotificationsInitial,
  toggleIsUserOnline,
  verifyToken,
} from '../../redux-saga/actions'
import { useMappedState, usePrevious } from '../../hooks/useReactRedux'
import {
  selectChatsNotClosedInProgress,
  selectIsUserOnline,
  selectNewChatNotifications,
  selectUserCompanyId,
  selectUserMemberId,
  selectUserToken,
} from '../../redux-saga/selectors'
import { getObjectFromLocalStorage } from '../../utils/getObjectFromLS'
import { isObjectEmpty } from '../../utils/isObjectEmpty'

const getMessageData = (lastJsonMessage) => {
  let messageData
  if (isValidJSON(lastJsonMessage)) {
    messageData = JSON.parse(lastJsonMessage)
  } else {
    messageData = lastJsonMessage
  }

  return messageData
}

// receives new chat events all over the app and sets "messages" to local storage to show notification in nav menu; receives online statuses of contacts
export const useGeneralWebsocket = (isUserOnline) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation('popupAlerts')

  const userToken = useMappedState(selectUserToken)
  const userId = useMappedState(selectUserMemberId)
  const newChatHistoryNotifications = useMappedState(selectNewChatNotifications)
  const companyId = useMappedState(selectUserCompanyId)
  const inProgressNotClosed = useMappedState(selectChatsNotClosedInProgress)

  const isPrevEventChatStarted = useRef(false)

  const [messageOpenSound] = useState(() => {
    return new Audio('https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_message_open.mp3')
  })
  const [messageNewSound] = useState(() => {
    return new Audio('https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_message_new.mp3')
  })
  const [chatsSound] = useState(() => {
    return new Audio('https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_chats.mp3')
  })

  const [skipFirstNewChatNotification, setSkipFirstNewChatNotification] = useState(false) // we need this cause push notification of first message comes too quickly after "chat_started" event and replaces it in browser

  const { lastMessage, lastJsonMessage, readyState } = useWebSocket(
    `${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
    {
      share: true,
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => {
        dispatch(verifyToken())

        return isUserOnline
      },
      reconnectInterval: 5000,
      reconnectAttempts: 50,
      // retryOnError: true,
    },
    isUserOnline
  )

  const playSound = (messageData) => {
    const soundNotificationsSettings = JSON.parse(localStorage.getItem('soundNotifications'))
    const { type, status, chat_data = {} } = messageData
    const { from_operator_id, to_operator_id } = chat_data

    if (
      (typeof soundNotificationsSettings?.chatSound !== 'boolean' || soundNotificationsSettings?.chatSound) &&
      (type === 'chat_started' || type === 'forwarded_operator' || type === 'forwarded_department')
    ) {
      chatsSound.play()
    } else if (
      (typeof soundNotificationsSettings?.messageSound !== 'boolean' ||
        soundNotificationsSettings?.messageSound) &&
      type === 'contact_message'
    ) {
      if (
        (status === 'new' && !isPrevEventChatStarted.current) ||
        (status === 'forwarded' && to_operator_id === userId)
      ) {
        messageNewSound.play()
      } else if (status === 'open' || (status === 'forwarded' && from_operator_id === userId)) {
        messageOpenSound.play()
      }
    }
  }

  const showPushNotification = (messageData) => {
    if (messageData?.type !== 'chat_started' && messageData?.type !== 'contact_message') {
      return
    }

    const pushNotificationsSettings = JSON.parse(localStorage.getItem('pushNotifications'))

    if (
      typeof pushNotificationsSettings?.chatPush === 'boolean' &&
      !pushNotificationsSettings?.chatPush &&
      (messageData?.type === 'chat_started' || messageData.status === 'new')
    ) {
      return
    }

    if (
      typeof pushNotificationsSettings?.messagePush === 'boolean' &&
      !pushNotificationsSettings?.messagePush &&
      messageData.status === 'open'
    ) {
      return
    }

    if (messageData?.type === 'chat_started') {
      setSkipFirstNewChatNotification(true)
    }

    if (skipFirstNewChatNotification) {
      setSkipFirstNewChatNotification(false)
      return
    }

    const notificationTitle =
      messageData?.type === 'chat_started'
        ? t('newChat')
        : messageData.status === 'new'
        ? `${t('newMessage')} (${t('newChat').toLowerCase()})`
        : t('newMessage')

    const lonelyFileInMessage = !messageData?.data?.text && messageData?.data?.files?.[0]
    const notificationBody =
      messageData?.type === 'contact_message' &&
      (messageData?.data?.text ||
        (lonelyFileInMessage &&
          (FILE_TYPES.images[lonelyFileInMessage.type] ? '📷' + t('image') : '📎' + t('file'))))

    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      console.log('This browser does not support desktop notification')
    } else if (Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification(notificationTitle, { body: notificationBody || '' })
      // …
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const notification = new Notification(notificationTitle, { body: notificationBody || '' }) // …
        }
      })
    }
  }

  useEffect(() => {
    const messageData = getMessageData(lastJsonMessage)

    if (messageData?.type === 'contact_message') {
      dispatch(setNewChatHistoryNotification(messageData))
    }

    if (['plan_prolonged', 'plan_updated'].includes(messageData?.type)) {
      dispatch(getCurrentSubscription())

      if (location.pathname.includes('/customers')) {
        dispatch(getCompanyDataById(companyId))
      }
    }

    if (messageData?.status === 'contact_offline' || messageData?.status === 'contact_online') {
      dispatch(setContactOnlineStatus(messageData.status === 'contact_online', messageData.contact_id))
    }

    if (
      document.activeElement?.id !== `chatTextarea-${messageData?.chat_id}` &&
      messageData?.type &&
      Object.keys(EVENT_TYPE_TO_CHAT_STATUS).includes(messageData.type)
    ) {
      playSound(messageData)
      showPushNotification(messageData)

      if (messageData.type === 'chat_started') {
        isPrevEventChatStarted.current = true
      } else if (isPrevEventChatStarted.current) {
        isPrevEventChatStarted.current = false
      }
    }
  }, [lastMessage, chatsSound, messageNewSound, messageOpenSound])

  useEffect(() => {
    const notificationsFromLS = getObjectFromLocalStorage('newChatHistoryNotifications')?.[userId]
    if (!newChatHistoryNotifications && notificationsFromLS && inProgressNotClosed === false) {
      dispatch(setNewChatHistoryNotificationsInitial(notificationsFromLS))
    }
  }, [inProgressNotClosed])

  useEffect(() => {
    if (newChatHistoryNotifications) {
      const allNotificationsFromLS = getObjectFromLocalStorage('newChatHistoryNotifications')

      window.localStorage.setItem(
        'newChatHistoryNotifications',
        JSON.stringify({ ...allNotificationsFromLS, [userId]: newChatHistoryNotifications })
      )
    }
  }, [newChatHistoryNotifications])
}

// receives new chat events for current chat and sets them to redux store (chat history)
export const useChatsCommonWebsocket = (chatId) => {
  const dispatch = useDispatch()

  const userToken = useMappedState(selectUserToken)
  const isUserOnline = useMappedState(selectIsUserOnline)
  const newChatHistoryNotifications = useMappedState(selectNewChatNotifications)

  const prevChatId = usePrevious(chatId)

  const { lastJsonMessage, lastMessage, readyState } = useWebSocket(
    `${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
    {
      share: true,
      shouldReconnect: (closeEvent) => {
        dispatch(verifyToken())

        return isUserOnline
      },
      reconnectInterval: 5000,
      reconnectAttempts: 50,
    },
    isUserOnline
  )

  useEffect(() => {
    const messageData = getMessageData(lastJsonMessage)
    // console.log(lastJsonMessage, messageData, lastMessageIdFromServer, lastMessageIdReduxState)
    if (
      messageData?.type &&
      messageData?.status !== 'ok' &&
      Object.keys(EVENT_TYPE_TO_CHAT_STATUS).includes(messageData.type)
    ) {
      // receives contact's message and writes it to current chat's chat history
      dispatch(setEventToChatHistory(messageData.type, messageData, true))

      if (messageData?.type === 'chat_closed') {
        dispatch(resetNewChatHistoryNotifications(messageData.chat_id))
      }
    }
  }, [lastMessage])

  useEffect(() => {
    if (chatId && newChatHistoryNotifications?.[chatId]) {
      dispatch(resetNewChatHistoryNotifications(chatId))
    }
  }, [chatId, newChatHistoryNotifications, prevChatId])
}

export const useSendEventWebsocket = (onSend, onSuccess, setEventJustReceived) => {
  const dispatch = useDispatch()
  const userToken = useMappedState(selectUserToken)
  const isUserOnline = useMappedState(selectIsUserOnline)

  const [eventInProgress, setEventInProgress] = useState('')
  const [eventSentStatus, setEventSentStatus] = useState('') // loading, ok, error

  const { sendMessage, sendJsonMessage, lastJsonMessage, lastMessage, readyState } = useWebSocket(
    `${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
    {
      share: true,
      shouldReconnect: (closeEvent) => {
        dispatch(verifyToken())

        return isUserOnline
      },
      reconnectInterval: 5000,
      reconnectAttempts: 50,
    },
    isUserOnline
  )

  const onSendEvent = (eventType) => () => {
    if (readyState !== 1) {
      dispatch(toggleIsUserOnline(true))

      setTimeout(() => {
        onSend(() => {
          setEventInProgress(eventType)
          setEventSentStatus('inProgress')
        })
      }, 1000)
    } else if (!eventInProgress) {
      onSend(() => {
        setEventInProgress(eventType)
        setEventSentStatus('inProgress')
      })
    }
  }

  useEffect(() => {
    const messageData = getMessageData(lastJsonMessage)

    if (
      messageData?.type &&
      Object.keys(EVENT_TYPE_TO_CHAT_STATUS).includes(messageData.type) &&
      setEventJustReceived
    ) {
      setEventJustReceived(messageData.type)
    }

    if (messageData?.status) {
      //operator_connected
      if (['ok', 'error', 'operator_connected'].includes(messageData.status)) {
        setEventSentStatus(messageData.status)
      }

      if (messageData.status === 'error') {
        setEventInProgress('')
      }

      if ((messageData.status === 'ok' || messageData.status === 'contact_offline') && onSuccess) {
        onSuccess(eventInProgress, () => {
          setEventSentStatus('')
          setEventInProgress('')
        })
      }

      // if (messageData.status === 'error') {
      //   setEventSentStatus('error')
      //   setEventInProgress('')
      //   dispatch(
      //     popupAlertShow({
      //       type: 'error',
      //       content: messageData.detail,
      //       timeout: 10000,
      //       withCloseButton: true,
      //       iconName: 'statusDeleted',
      //       stacked: true,
      //     })
      //   )
      // }
    }
  }, [eventInProgress, lastMessage])

  return {
    sendEventToServer: sendJsonMessage,
    sendMessage,
    onSendEvent,
    eventSentStatus,
    eventInProgress,
  }
}
