import styled from 'styled-components'
import { FlexRow, Icon } from '../../atomic-design-components'

export const StyledCardContent = styled.div`
  border: 1px solid ${({ theme: { color } }) => color.general.lighter};
  border-radius: 3px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.active:not(.disabled) {
    border: 1px solid ${({ theme }) => theme.color.status.success};
    outline: 2px solid ${({ theme }) => `${theme.color.status.success}40`};
    label .typography {
      color: ${({ theme }) => theme.color.status.success} !important;
    }
  }
  &.disabled {
    opacity: 0.5;
  }

  .switchableCardItemRow {
    padding: 15px;

    &:not(:last-child, :first-child) {
      padding-bottom: 0;
    }

    &.live_chat .active {
      color: ${({ theme: { color } }) => color.status.success};
    }

    &.connectButton {
      cursor: pointer;
    }

    &.live_chat .active {
      color: ${({ theme: { color } }) => color.status.success};
    }
  }
  .tooltip {
    right: 0;
  }

  .switch {
    order: 1;
  }
  label {
    gap: 10px;
  }
  .inputContainer {
    flex-grow: 1;
  }
`
export const StyledLine = styled.hr`
  background-color: ${({ theme }) => theme.color.general.lighter};
  color: ${({ theme }) => theme.color.general.lighter};
  border-width: 0;
  height: 1px;
  margin: 0;
`

export const StyledWidgetCustomize = styled(FlexRow)`
  background-image: ${({ url }) => `url(${url})`};
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 900px) {
    margin-bottom: 90px !important;
    background-image: none;
  }
`

export const StyledWidgetCustomizedContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.general.white};
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
  max-width: 370px;
  min-width: 320px;
  margin: 0 auto;
  flex-grow: 1;
  box-shadow: 0 14px 15px rgba(0, 0, 0, 0.15);
`

export const StyledPreviewIcon = styled(Icon)`
  position: absolute;
  bottom: -86px;
  right: 0;
  animation: pulse 1.5s infinite;

  &:hover {
    animation: none;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.75);
    }
    70% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.75);
    }
  }
`

export const StyledAutoMessagesContainer = styled.div`
  padding: 20px;
`
