import React from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'
import { withTheme } from 'styled-components'

import { IconWrapper } from './styled'
import Icons from '../../../assets/icons'
import Color from '../Color'

const Icon = ({
  alignItems,
  borderRadius,
  className,
  color,
  dataTooltip,
  disabled,
  fill,
  height,
  margin,
  justifyContent,
  name,
  onClick,
  padding,
  size,
  stroke,
  style,
  title,
  theme,
  width,
  withoutWrapper,
  wrapperColor,
  wrapperHeight,
  wrapperWidth,
  ...otherProps
}) => {
  const iconName = typeof name === 'string' ? name : name?.id || name?.slug
  const IconComponent = Icons[iconName]

  if (!IconComponent && !color) {
    return null
  }

  if (color) {
    return <Color color={color} size={size} className={clsx(className, 'icon')} />
  }

  if (withoutWrapper) {
    return (
      <IconComponent
        width={width}
        height={height}
        fill={fill}
        stroke={stroke}
        wrapperColor={wrapperColor}
        theme={theme}
        {...otherProps}
      />
    )
  }

  return (
    <IconWrapper
      data-tooltip={dataTooltip}
      title={title}
      width={wrapperWidth}
      height={wrapperHeight}
      margin={margin}
      padding={padding}
      size={size}
      className={clsx(className, 'icon', disabled && 'disabled')}
      onClick={disabled ? undefined : onClick}
      wrapperColor={wrapperColor}
      borderRadius={borderRadius}
      style={style}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      <IconComponent
        width={width || size}
        height={height || size}
        fill={fill}
        stroke={stroke}
        wrapperColor={wrapperColor}
        theme={theme}
        {...otherProps}
      />
    </IconWrapper>
  )
}

export default withTheme(Icon)

Icon.propTypes = {
  alignItems: T.oneOf(['start', 'end', 'center', 'stretch']),
  borderRadius: T.string,
  className: T.string,
  color: T.string,
  dataTooltip: T.string,
  disabled: T.bool,
  fill: T.string,
  height: T.number,
  margin: T.string,
  justifyContent: T.oneOf([
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
  ]),
  name: T.oneOfType([T.object, T.string]).isRequired,
  onClick: T.func,
  size: T.number,
  stroke: T.string,
  style: T.object,
  title: T.string,
  theme: T.object,
  width: T.number,
  withoutWrapper: T.bool,
  wrapperColor: T.string,
  wrapperHeight: T.oneOfType([T.number, T.string]),
  wrapperWidth: T.oneOfType([T.number, T.string]),
}

Icon.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  fill: '',
  name: '',
}
