import React from 'react'

export const typeSkibbleWidget = ({ width, height, fill, theme: { color } }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    width={width || 56}
    height={height || 56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <defs>
      <style>
        {`.cls-1 {
            fill: ${fill || color.primary.main};
          }

          .cls-2 {
            fill: ${color.general.white};
            fill-rule: evenodd;
          }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className="cls-1"
        d="m1.06,11.77C1.8,6.19,6.19,1.8,11.77,1.06,22.54-.35,33.46-.35,44.23,1.06c5.58.73,9.98,5.13,10.71,10.71,1.42,10.77,1.42,21.68,0,32.45-.73,5.58-5.13,9.98-10.71,10.71-10.77,1.42-21.68,1.42-32.45,0-5.58-.73-9.98-5.13-10.71-10.71C-.35,33.46-.35,22.54,1.06,11.77Z"
      />
      <g>
        <path
          className="cls-2"
          d="m28,9.91c-9.97,0-18.09,8.11-18.09,18.09s8.11,18.09,18.09,18.09,18.09-8.11,18.09-18.09-8.11-18.09-18.09-18.09Zm0,34.17c-8.87,0-16.09-7.22-16.09-16.09s7.22-16.09,16.09-16.09,16.09,7.22,16.09,16.09-7.22,16.09-16.09,16.09Z"
        />
        <path
          className="cls-2"
          d="m35.48,21.49h0c-3.96-1.1-6.32,1.86-8.06,4.62l-.5.83c-1.39,2.22-2.83,4.51-5.57,3.74-1.49-.42-2.21-1.51-2.39-2.85h-3.23c.06,3.12,1.53,5.63,4.78,6.53,4.78,1.33,7.36-2.67,8.79-4.88l.07-.11.47-.73c.06-.1.12-.19.18-.29,1.15-1.83,2.38-3.8,4.61-3.19,1.27.35,2.11,1.34,2.39,2.66h3.25c-.18-3.05-1.82-5.52-4.79-6.34Z"
        />
      </g>
    </g>
  </svg>
)
