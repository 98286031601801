import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'

import Form from '../../components/Form'
import { FORM_CONFIGS } from '../../config/forms'
import { Button } from '../../atomic-design-components'
import { userSignUp } from '../../redux-saga/actions'
import { StyledLine } from '../../components/IntroScreens/styled'
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist'

const FormInvite = ({ code, initialValues }) => {
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(undefined)
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const { t } = useTranslation('auth')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { type, iconRightProps } = useIsPasswordShown()

  const onFormSubmit = (credentials) => {
    dispatch(userSignUp({ ...credentials, code }, navigate))
  }

  return (
    <>
      <Form
        className="form formInvite"
        initialValues={initialValues}
        fields={[
          { ...FORM_CONFIGS.signUp.fields[2], isDisabled: true },
          { ...FORM_CONFIGS.signUp.fields[1], key: 'company_name', isDisabled: true },
        ]}
      />
      <StyledLine />
      <Form
        className="form"
        type="signUp"
        initialValues={initialValues}
        // serverError={error !== false}
        onSubmit={onFormSubmit}
        validationRules={{
          full_name: ['required'],
          password: ['required', { type: 'password', isPasswordValid }],
        }}
        fields={[
          FORM_CONFIGS.signUp.fields[0],
          {
            ...FORM_CONFIGS.signUp.fields[4],
            type,
            iconRightProps,
            onInputValueChange: (value) => setPassword(value),
          },
        ]}
      >
        <CustomPasswordChecklist
          password={password}
          setIsPasswordValid={setIsPasswordValid}
          className={isSubmitPressed && !isPasswordValid ? 'passError' : ''}
        />
        <Button
          fullWidth
          variant="primary"
          type="submit"
          iconName="peopleSign"
          onClick={() => setIsSubmitPressed(true)}
        >
          {t('signUp')}
        </Button>
      </Form>
    </>
  )
}

export default withTheme(FormInvite)
