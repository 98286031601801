import React from 'react'
import { PropTypes as T } from 'prop-types'
import { useWidth } from '../../hooks/useWidth'

import { Icon, Link } from '../../atomic-design-components'
import { StyledLogoWrapper } from './styled'

const Logo = ({ theme, variant, isExtended, isTextPage, hasMiniLogo, logoColor, to, className }) => {
  const screenWidth = useWidth()
  const isMobile = screenWidth && screenWidth < theme.breakpoints.sm
  const isTablet = screenWidth && screenWidth < theme.breakpoints.lg

  const size = {
    small: {
      icon: { width: 36, height: 36 },
      text: { width: 134, height: 16 },
    },
    medium: {
      icon: { width: 44, height: 44 },
      text: { width: 162, height: 20 },
    },
    large: {
      text: { width: 184, height: 22 },
    },
  }

  return (
    <StyledLogoWrapper className={className}>
      <Link noStyles to={to}>
        {hasMiniLogo ? (
          <>
            <Icon name="logo" {...size[variant].icon} className="logoIcon" />
            {isExtended && (
              <Icon
                name="skibbleTextLogo"
                className="skibbleTextLogo"
                fill={logoColor || theme.color.general.white}
                {...size[variant].text}
              />
            )}
          </>
        ) : (
          <>
            {(isMobile || isTextPage) && <Icon name="logo" {...size[variant].icon} />}
            <Icon
              name="skibbleTextLogo"
              className="skibbleTextLogo"
              fill={((isTablet || isTextPage) && theme.color.general.black) || theme.color.general.white}
              {...size[variant].text}
            />
          </>
        )}
      </Link>
    </StyledLogoWrapper>
  )
}

export default Logo

Logo.propTypes = {
  theme: T.object,
  variant: T.oneOf(['small', 'medium', 'large']),
  isExtended: T.bool,
  isTextPage: T.bool,
  hasMiniLogo: T.bool,
}

Logo.defaultProps = {
  variant: 'medium',
}
