import styled from 'styled-components'

export const StyledSettingsProfile = styled.div`
  max-width: 660px;
  margin: 30px auto;

  .attachButton {
    position: relative;
  }
  .profileDrop {
    width: 100%;
    height: 100%;
  }
  .expansionPanel {
    border: none;
    padding: 7px 20px;
  }

  .inputContainer {
    margin-bottom: 0;
  }

  .languages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 20px;

    .panelHeader {
      grid-area: 1 / 1 / 2 / 3;
    }
    .explanation {
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  .personalInformation,
  .passwordSecurity {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 20px;

    .panelHeader {
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  .passwordSecurity {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;

    .panelHeader {
      width: 100%;
    }

    .currPass {
      width: 100%;
      margin-bottom: 20px;
    }

    .password {
      flex-grow: 1;
    }

    .repeatPass {
      margin-left: 10px;
    }
    .newPass {
      margin-right: 10px;
    }
  }

  .submitFormButton {
    margin: 0 20px;
  }

  .languages,
  .profilePicture {
    .panelHeader {
      margin-bottom: 10px;
    }
    .explanation {
      margin-bottom: 10px;
    }
  }

  .rowsForm {
    form {
      flex-direction: revert;
      align-items: flex-end;
      justify-content: space-between;
    }
    .changeEmail {
      width: 100%;
    }
    button {
      margin: 0 20px 7px 0;
    }
    .submitFormButtons {
      display: none;
    }
  }
  .passForm {
    form {
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      grid-template-rows: repeat(2, auto);
      align-items: flex-start;
    }
    .passwordSecurity {
      grid-area: 1 / 1 / 2 / 3;
      .inputContainer {
        margin-bottom: 0;
      }
      .newPass {
        margin-right: 0;
      }
    }
    button {
      grid-area: 1 / 3 / 2 / 4;
      margin: 49px 20px 0 0;
    }
    .passwordCheckList {
      grid-area: 2 / 2 / 3 / 3;
      padding: 0 10px 20px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: 20px;

    .profilePicture .column > div {
      flex-wrap: wrap;
      justify-content: center;
    }

    .personalInformation {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      .panelHeader {
        grid-area: 1;
      }
    }
    .languages {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 1fr;
      .panelHeader {
        grid-area: 1/1/2/2;
      }
      .explanation {
        grid-area: 2/1/2/2;
      }
    }
    .rowsForm:not(.passForm) form {
      flex-wrap: wrap;
      justify-content: center;
      button {
        margin: 0 20px;
      }
    }
    .passForm form {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      .submitFormButton {
        grid-area: 2 / 1 / 3 / 2;
        margin: 20px auto;
        width: fit-content;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      .passwordCheckList {
        grid-area: 3 / 1 / 4 / 2;
        padding: 0 20px;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      .passwordSecurity {
        grid-area: 1/1/2/2;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        .panelHeader {
          grid-area: auto;
        }
      }
    }
  }
`

export const StyledProfilePicture = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 10px;
  border: 1px solid ${({ theme: { color } }) => color.general.lighter};
  border-radius: 3px;
  figure {
    flex-shrink: 0;
  }
`
