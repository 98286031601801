import React, { useEffect } from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { StyledButton } from './styled'
import Icon from '../../atoms/Icon'
import Tooltip from '../../atoms/Tooltip'
import theme from '../../../theme'
import { useTimeout } from '../../../hooks/useTimeout'
import { useTranslation } from 'react-i18next'

const typographyVariant = {
  small: 'button3',
  medium: 'button1',
}

const Button = (props) => {
  const {
    backgroundColor,
    borderColor,
    borderRadius,
    children,
    className,
    confirmButtonProps,
    color,
    disabled,
    fullWidth,
    hoverType,
    iconName,
    iconSize,
    iconRightProps,
    iconLeftProps,
    margin,
    maxWidth,
    onClick,
    padding,
    size,
    text,
    tooltipProps,
    type,
    uppercase,
    variant,
    withoutBorder,
    ...otherProps
  } = props
  const { t } = useTranslation('all')
  const [isConfirmDeleteActive, setIsConfirmDeleteActive] = React.useState(false)
  const deleteConfirmRef = React.useRef(null)

  const onDeleteClick = (e) => {
    e.preventDefault()
    setIsConfirmDeleteActive(true)
  }

  useTimeout(
    () => {
      setIsConfirmDeleteActive(false)
    },
    3000,
    [isConfirmDeleteActive]
  )

  const onDeleteClickConfirm = (e) => {
    e.preventDefault()
    if (confirmButtonProps.onDeleteConfirm) {
      confirmButtonProps.onDeleteConfirm(e)
    }

    if (onClick) {
      onClick(e)
    }

    deleteConfirmRef.current = setTimeout(() => {
      setIsConfirmDeleteActive(false)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(deleteConfirmRef.current)
    }
  }, [])

  const confirmButtonPropsExtended =
    confirmButtonProps && isConfirmDeleteActive
      ? {
        backgroundColor: confirmButtonProps.backgroundColor || theme.color.general.white,
        color: confirmButtonProps.color || theme.color.status.error,
        variant: confirmButtonProps.variant || 'general',
        onClick: onDeleteClickConfirm,
      }
      : {}

  const confirmIconProps = confirmButtonProps && isConfirmDeleteActive && confirmButtonProps.iconLeftProps
  const confirmText = confirmButtonProps && isConfirmDeleteActive && t('clickToConfirm')
  const hasChildren = !!children || !!confirmText || !!text

  return (
    <Tooltip {...tooltipProps} disableTooltip={tooltipProps ? tooltipProps.disableTooltip : true}>
      <StyledButton
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        borderColor={borderColor}
        color={color}
        hoverType={hoverType}
        disabled={disabled}
        margin={margin}
        maxWidth={maxWidth}
        onClick={confirmButtonProps ? onDeleteClick : onClick}
        padding={padding}
        type={type}
        typographyVariant={typographyVariant[size]}
        variant={variant}
        className={clsx(
          className,
          variant,
          disabled && 'disabled',
          fullWidth && 'fullWidth',
          uppercase && 'uppercase',
          withoutBorder && 'withoutBorder',
          confirmButtonProps && isConfirmDeleteActive && 'confirmState',
          confirmButtonProps && isConfirmDeleteActive && confirmButtonProps?.classNameConfirm
        )}
        {...otherProps}
        {...confirmButtonPropsExtended}
      >
        {(iconName || iconLeftProps) && (
          <Icon
            name={iconName}
            fill={color || theme.color.general.white}
            margin={hasChildren ? '0 6px 0 0' : '0'}
            size={iconSize || 16}
            {...iconLeftProps}
            {...confirmIconProps}
          />
        )}
        {children || confirmText || text}
        {iconRightProps && (
          <Icon fill={color || theme.color.general.white} margin="0 0 0 6px" {...iconRightProps} />
        )}
      </StyledButton>
    </Tooltip>
  )
}

export default Button

Button.propTypes = {
  backgroundColor: T.string,
  borderColor: T.string,
  borderRadius: T.string,
  children: T.node,
  color: T.string,
  className: T.string,
  confirmButtonProps: T.object,
  disabled: T.bool,
  fullWidth: T.bool,
  hoverType: T.oneOf(['light', 'dark', 'opacity']),
  iconName: T.string,
  iconRightProps: T.object,
  iconLeftProps: T.object,
  margin: T.string,
  maxWidth: T.string,
  onClick: T.func,
  padding: T.string,
  size: T.oneOf(['small', 'medium']),
  text: T.string,
  tooltipProps: T.object,
  uppercase: T.bool,
  variant: T.oneOf(['primary', 'secondary', 'general']).isRequired,
  withIcon: T.bool,
  withoutBorder: T.bool,
}

Button.defaultProps = {
  hoverType: 'light',
  size: 'medium',
  type: 'button',
  variant: 'primary',
}
