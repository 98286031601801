import { object } from 'dot-object'
import { isObject } from '.'
import { CURRENCY } from '../constants'

const PAYMENT_TYPE_TO_PRICE_KEY = {
  daily: 'price_monthly', // dev env only
  monthly: 'price_monthly',
  annually: 'price_annually',
}

export const convertArrayToObject = (array = [], key = 'id') =>
  array.reduce((acc, curr) => {
    if (key === 'id' && !curr[key] && curr._id) {
      acc[curr._id] = curr
    } else {
      acc[curr[key]] = curr
    }

    return acc
  }, {})

export const getPlanPrice = (planPrices, paymentType, currency = 'UAH', plan_id) => {
  const priceKey = PAYMENT_TYPE_TO_PRICE_KEY[paymentType]

  // Free plan
  if (plan_id === 1) {
    return `${CURRENCY[currency] || ''} 0`
  }

  if (!planPrices || paymentType === 'manual') {
    return null
  }

  return `${CURRENCY[currency] || ''} ${planPrices?.[priceKey]?.[currency] || 0}`
}

export const getPercent = (quantity, data) => {
  if (data.length > 0) {
    let allQuantity = 0
    for (let i = 0; i < data.length; i++) {
      allQuantity += data[i].quantity
    }
    return ((quantity / allQuantity) * 100).toFixed(2)
  } else {
    return 0
  }
}

export const mapSelectObjectValuesToIds = (currValues, initialValues) => {
  return Object.keys(object(currValues)).reduce((values, currKey) => {
    const value = currValues[currKey]

    return {
      ...values,
      [currKey]: Array.isArray(value)
        ? value.map((el) => el.id)
        : isObject(value)
        ? value.id
          ? value.id
          : { ...initialValues[currKey], ...value }
        : value,
    }
  }, {})
}
