import styled from 'styled-components'

export const StyledCardContent = styled.div`
  .statusIcon {
    margin-left: 12px;
    padding: 2px 5px;
  }
  .planRow span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .actionRow .bottom.tooltip {
    right: auto;
    left: 0;
    .tooltip-arrow {
      right: auto !important;
      left: 9% !important;
    }
  }
  .statusRow {
    > * {
      width: 50%;
    }
    .bottom.tooltip {
      right: 0;
      left: auto;
      .tooltip-arrow {
        right: 9% !important;
        left: auto !important;
      }
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .statusIcon {
      padding: 0;
    }
  }
`
