export default {
  all: {
    acceptChat: 'Accept chat',
    accountStatus: 'Account status',
    action: 'Actions',
    activate: 'Activate',
    activated: 'Activated',
    active: 'Active',
    addBulkMessaging: 'Add bulk messaging',
    addFirstItem: 'Add {{itemType}}',
    addChatWidget: 'Add chat widget',
    addCustomer: 'Add customer',
    addingChatWidget: 'Adding chat widget',
    addDepartment: 'Add department',
    addMembers: 'Add members',
    addTag: 'Add tag:',
    addTemplates: 'Add a new template',
    addingDepartment: 'Adding department',
    administrators: 'Notify administrators',
    all_channels_instant: 'Rules for messengers and LiveChat',
    allCustomers: 'All customers',
    allMembers: 'All members',
    amount: 'Amount',
    annually: 'Annually',
    approved: 'Approved',
    attachment: 'Attachment',
    'auto-messages': 'Auto Messages',
    availablePlans: 'Available plans',
    chat_duration: 'Average Duration',
    averageRating: 'Average Rating',
    averageReaction: 'Average Reaction',
    response_time: 'Average Response',
    bankAccountDetails: 'Bank account details',
    bankName: 'Bank name',
    bankCountry: 'Bank country',
    billedAnnually: 'Billed Annually',
    billedMonthly: 'Billed Monthly',
    botPlaceholder: 'Please input your bot token',
    botHint: 'Enter token first',
    botActivated: 'is connected',
    botPaused: 'is paused',
    is_branding: 'No Skibble Branding',
    is_custom: 'Custom plan',
    browser: 'Browser',
    browserLanguage: 'Browser language',
    bulkErrors: 'Guests who failed to receive their messages ({{messagesCount}}):',
    bulkMessage: 'Bulk message',
    bulkMessagePlaceholder: 'Type the message which needs to be sent',
    bulkSuccess: 'Messages successfully sent: {{messagesCount}}.',
    plan_business: 'Business',
    cameFrom: 'Came from',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    cancelSubscription: 'Cancel subscription',
    channel: 'Channel',
    channelConfig: 'Channels configuration',
    channels: 'Channels',
    'chat-customization': 'Chat widget customization',
    chats: 'Chats',
    chat_owner: 'Notify chat owner',
    changeEmail: 'Change email',
    cherry: 'Cherry',
    classic: 'Classic',
    clickToConfirm: 'Click to confirm',
    clientFilters: 'Client filters',
    clientCountTip: 'Message recipients: {{count}}',
    close: 'Close',
    closeChat: 'Close chat',
    closed: 'Closed',
    closedBy: 'Closed by',
    closedByGuest: 'Closed by guest',
    comment: 'Comment',
    companyEmail: 'Company email',
    companyInformation: 'Company information',
    companyInfoTip: 'Please fill in your basic contact information and industry of your business.',
    companyInfoDescription: 'What does your company do?',
    companyName: 'Company name',
    companyPhone: 'Company phone',
    companyWebsite: 'Company website',
    complete: 'Complete',
    complete_with_error: 'Complete with error',
    connect: 'Connect',
    contactDetails: 'Contact details',
    contactInfo: 'Contact information',
    copyCode: 'Copy code',
    coral: 'Coral',
    corporate: 'Corporate',
    count: 'Count',
    create: 'Create',
    created: 'Created',
    createdAt: 'Created at',
    currentPassword: 'Current password',
    currentPlan: 'Current plan',
    customer_administrator: 'Administrator',
    customer_owner: 'Owner',
    customers: 'Customers',
    customerInfo: 'Customer information',
    customization: 'Customization',
    is_customization: 'Customization',
    date: 'Date',
    deactivate: 'Deactivate',
    deactivateAccount: 'Delete your account',
    declined: 'Declined',
    delay: 'Delay (seconds)',
    delayMS: 'Delay (mm:ss)',
    delete: 'Delete',
    deleteDepartment: 'Delete department',
    deleteMemberPermanently: 'Delete member permanently',
    departmentName: 'Department name',
    departments: 'Departments',
    departmentsField: 'Departments ({{choosedDep}} of {{allDeps}})',
    description: 'Description',
    desktop: 'Desktop',
    details: 'Details',
    device: 'Device',
    disabledCardTip: 'Enable First message trigger first',
    draft: 'Draft',
    EDRPOU: 'EDRPOU',
    editTemplate: 'Edit your template',
    email: 'Email',
    emailNotifications: 'Email notifications',
    enterChatWidgetName: 'Enter a chat widget name',
    enterYourMessage: 'Enter your message',
    enterYourPassword: 'Enter your password',
    exp: 'exp',
    expired: 'Expired',
    expires: 'Expires',
    facebookMessenger: 'facebook messenger',
    field: 'Field',
    fieldsTooltip: 'Fill all the fields in the card',
    firstLastName: 'First and Last name',
    first_message: 'First message',
    firstMessageTip: 'Message fires after a delay once widget is loaded on the page.',
    messageTipIdleOperator:
      "Message fires if an operator hasn't responded to the guest message within a specified time.",
    firstMessageTipLivechat: 'Message fires immediately after client starts a chat.',
    firstSeen: 'First seen',
    for: 'for',
    forms: 'Forms',
    forward: 'Forward',
    forwardTo: 'Forward to',
    forwardedTo: '{{operatorName}} forwarded to {{departmentOrOperatorName}}',
    free: 'Free',
    plan_free: 'Free plan',
    planTitle: 'Plan title',
    friday: 'Friday',
    fullName: 'Full name',
    general: 'General',
    generateInvoice: 'Generate invoice',
    gold: 'Gold',
    grass: 'Grass',
    guest: 'Guest',
    guestInfo: 'Guest information',
    guestOfflinePlaceholder:
      'The guest is not on the website right now, he will see your messages when returns',
    guestOfflineText: 'Guest has left the website',
    hideBranding: 'Hide branding',
    h: 'h',
    hoursOfWorkTitle: "Set your team's working hours",
    hoursOfWorkTip: 'Working hours affect Guests access to communication channels on the chat widget',
    iconType: 'Icon type',
    inactive: 'Inactive',
    inactiveWidgetTip: 'Chat widget is not displayed on the website and you don’t receive messages',
    industry: 'Industry',
    idle_guest: 'Idle guest',
    idle_operator_new_chat: 'Idle operator in new chat',
    idle_operator_open_chat: 'Idle operator in opened chat',
    information: 'Information',
    in_progress: 'In progress',
    inProcessing: 'In processing',
    installed: 'Installed',
    installedNot: 'Not installed',
    interfaceLanguage: 'Interface language',
    invitationsSent: 'Invitation sent',
    invite: 'Invite',
    inviteMember: 'Inviting a member',
    joinedChat: 'joined the chat',
    languages: 'Languages',
    languagesExplain:
      'System language is used for the dashboard interface. And operational languages are the ones you can communicate in.',
    lastMessage: 'Last message',
    lastMonth: 'Last month',
    lastSeen: 'Last seen',
    lastWeek: 'Last week',
    lastYear: 'Last year',
    last30days: 'Last 30 days',
    last90days: 'Last 90 days',
    link: 'link',
    liveChatExplain: 'Live chat will be available after you implement the chat widget code on your web site.',
    liveChatHint: 'Implement code on your website first',
    liveChatActivated: 'Live chat is activated',
    live_chat_delayed: 'Rules for LiveChat (with delay)',
    liveChatPaused: 'Live chat is paused',
    loginAsOwner: 'Login as owner',
    memberInfo: 'Member information',
    memberMessengers: 'Member messengers',
    members: 'Members',
    message: 'Message',
    messageText: 'Message text',
    messagingChannels: 'Messaging Channels',
    messengers: 'Messengers',
    metaHint: 'You need to connect first',
    metaActivated: 'is connected',
    metaPaused: 'is paused',
    min: 'm',
    mobile: 'Mobile',
    monday: 'Monday',
    monthly: 'Monthly',
    // mostPopulartags: 'Most popular tags',
    // mostPopularbrowser: 'Most popular browsers',
    // mostPopularbrowser_lang: 'Most popular browser languages',
    // mostPopulardevice: 'Most popular devices',
    // mostPopulardevice_os
    // mostPopulartimezone: 'Most popular timezones',
    mostPopulartags: 'Tags',
    mostPopularbrowser: 'Browsers',
    mostPopularbrowser_lang: 'Browser languages',
    mostPopulardevice: 'Devices',
    mostPopulardevice_os: 'Operating systems',
    mostPopulartimezone: 'Timezones',
    name: 'Name',
    new: 'New',
    newBulkMessage: 'New bulk message',
    new_chat_working_hours: 'New chat (working hours)',
    new_chat_working_hours_tip: 'Indicate a delay after which notification email or emails will be sent',
    new_chat_non_working_hours: 'New chat (non-working hours)',
    new_chat_non_working_hours_tip:
      'Indicate who needs to be notified of a client message during non-working hours',
    newPassword: 'new password',
    nextPayment: 'Next payment',
    non_working_hours: 'Non working hours',
    no_response: 'No response in active chat',
    no_response_tip: 'Indicate a delay after which notification email or emails will be sent',
    notConnected: 'Not connected',
    nothingFound: 'Nothing found',
    notificationsTitle: 'Sound notifications',
    notificationTitle1: 'Notify me when I have a new chat',
    notificationExplain1: 'You will receive a notification about each new or forwarded chat',
    notificationTitle2: 'Notify me when I have new message',
    notificationExplain2: 'You will receive a notification about each new message in Opened chats',
    notificationsTitle3: 'Push notifications',
    of: 'of',
    open: 'Opened',
    operationalLanguages: 'Operational languages',
    operator: 'Operator',
    operators: 'Notify all operators',
    options: 'Options',
    orange: 'Orange',
    orderReference: 'Order reference',
    owner: 'Owner',
    password: 'password',
    passwordSecurity: 'Change password',
    paymentMethod: 'Payment method',
    paymentsRequisites: 'Payments requisites',
    paymentType: 'Payment type',
    pending: 'Pending',
    personal: 'Personal',
    personalInformation: 'Personal information',
    personalTemplate: 'Personal template',
    phone: 'Phone',
    plan: 'Plan',
    planEnds: 'Plan ends',
    privacyPolicy: 'Privacy Policy',
    preChat: 'Contact form',
    pricing: 'Price',
    privacyPolicyAgreeText:
      'I agree that my personal data, provided via chat, will be processed for the purpose of receiving online',
    privacyPolicyLinkText: 'support and consultation.',
    profile: 'My Profile',
    profileImageTip: 'Upload your profile photo, recommended image size is 512x512px',
    profilePicture: 'Profile picture',
    profilePictureTip: 'Upload a real image, it increases the level of trust of your customers.',
    rated: 'Rated',
    rating: 'Rating',
    ratingWrite: 'Please rate your experience',
    repeatPassword: 'Repeat password',
    resendInvitation: 'Resend invitation',
    region: 'Region',
    reset: 'Reset',
    resetPassword: 'Reset password',
    restoreSubscription: 'Restore subscription',
    results: 'Results',
    retypePassword: 'retype password',
    required: 'Required',
    role: 'Role',
    saturday: 'Saturday',
    save: 'Save',
    saveUp: 'Save up to 20%',
    saveSchedule: 'Save schedule',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    sendMessagesNow: 'Send now',
    search: 'Search',
    sec: 's',
    selectDate: 'Filter by date',
    send: 'Send',
    sendInvitation: 'Send invitation',
    settings: 'Settings',
    setup: 'Setup',
    setupCodeText:
      'Add the code snippet below to every page of your website where you’d like to see a widget. Place the code snippet right before the </body> tag in your html code.',
    plan_small_team: 'Small team',
    planCharacteristics: 'Plan characteristics',
    prices: 'Prices',
    priceAnnually: 'Price annually',
    priceMonthly: 'Price monthly',
    second_message: 'Second message',
    secondMessageTip: 'Message fires after a delay following the first message.',
    messageTipIdleGuest:
      "Message fires if an guest hasn't responded to the operator message within a specified time.",
    secondMessageTipLivechat: 'Second message will be send immediately after first one.',
    specified_emails: 'Notify specific emails',
    status: 'Status',
    sum: 'Sum',
    sunday: 'Sunday',
    is_support: 'Priority Live-Support',
    switchToPlan: 'Switch to plan',
    system: 'System',
    system_administrator: 'Platform Administrator',
    systemLanguage: 'System language',
    tablet: 'Tablet',
    tags: 'Tags',
    templateCheckboxLabel: 'General template (allows all members to use it)',
    templateName: 'Template name',
    template: 'Template',
    templates: 'Templates',
    templatesC: 'Templates',
    thisMonth: 'This month',
    thisWeek: ' This week',
    thisYear: 'This year',
    thursday: 'Thursday',
    time: 'Time',
    TIN: 'TIN',
    title: 'Title',
    timeZone: 'Time zone',
    to: 'to',
    toAddNewLine: 'to add a new line',
    today: 'Today',
    total: 'Total',
    translations: 'Translations',
    trial: 'Trial',
    trialPeriod: '14 Days Trial',
    tuesday: 'Tuesday',
    turquoise: 'Turquoise',
    type: 'Type',
    unlimitTemplates: 'Unlimited Templates',
    updatePlan: 'Update plan',
    upgradeYourPlanToAdd: 'Upgrade your plan to add more',
    upgradeYourPlanToUse: 'Upgrade your plan to use this feature',
    upload: 'Upload',
    uploadAndImage: 'Upload a file',
    user: 'User',
    users: 'Users',
    usersIncluded: 'Users included',
    violet: 'Violet',
    visible: 'Visible',
    website: 'Website',
    wednesday: 'Wednesday',
    widget: 'Widget',
    widgets: 'Chat Widgets',
    widgetAppearance: 'Widget appearance',
    widgetButton: 'Widget button',
    widgetColor: 'Widget color',
    withSchedule: 'Send message on specific date and time',
    writeYourMessageHere: 'Write your message here',
    yourCompanyName: 'Your company name',
    yourCurrentPlan: 'Your current plan',
    '@username': '@username',
    ecommerce: 'E-commerce',
    logistics: 'Logistics',
    finance: 'Finance',
    education: 'Education',
    travel: 'Travel',
    real_estate: 'Real Estate',
    construction: 'Construction',
    it: 'Information Technology',
    medical: 'Medical',
    poweredBy: 'Powered by',
    quickWayToContact: 'Here’s a quick way to contact us!',
    sendMessageToStart: 'Send a message to start a conversation',
    sendUsMessage: 'Send us a message',
    welcome: 'Welcome',
    writeIn: 'Write in',
    yesterday: 'Yesterday',
  },
  auth: {
    alreadyHaveAccount: 'Already have an account?',
    backTo: 'Back to ',
    cancel: 'Cancel',
    changePassword: 'Change password',
    checkYourMailbox: 'Check your mailbox',
    clientInformation: 'Client information',
    confirmYourEmail: 'Confirm your email',
    continueGoogle: 'Continue with Google',
    createYourAccount: 'Create your account',
    dontHaveAccount: 'Don’t have an account?',
    firstPageText: 'Everything you need for effective business communication',
    firstPageText1:
      'Business owners know that lost messages and missing chat histories can cause financial damage and lead to lost customers.\nSkibble is designed to solve these issues.',
    footerText: 'All Rights Reserved',
    forgotPassword: 'forgot your password?',
    language: 'Language',
    legalPagesInscription: 'By clicking "Sign up" you agree that you have read',
    legalPagesInscription1: ' and have reviewed and agree to',
    newPassword: 'New password',
    or: 'or',
    passRecoverSentText:
      'We have sent you instructions on how to recover your password. Also check your spam folder if you can’t find our email!',
    passRecoverText: 'Enter your email below to receive password reset instructions.',
    passwordRecovery: 'Password recovery',
    privacyPolicy: 'Privacy Policy',
    proceed: 'Proceed',
    recoverPassword: 'Recover password',
    rememberPassword: 'Remember my password',
    requestText: 'To use Skibble, please confirm your email address first. We have sent a link to',
    requestText1: '. Check the spam folder if the email does not arrive.',
    signIn: 'Sign in',
    signUp: 'Sign up',
    termsAndConditions: 'Terms and Conditions',
    toSignIn: 'Sign in',
    welcome: 'Welcome!',
    withTermsAndConditions: 'Terms and Conditions',
  },
  emptyPages: {
    bulkMessagingHeader: 'There are no Bulk Messaging yet',
    bulkMessagingText: 'Create you first broadcast message.',
    contactsHeader: 'There are no Contacts yet',
    contactsText: 'Contact will appear here after Guest contacts you.',
    chatsHeader: 'There are no Chats yet',
    chatsText: 'Chat appears after Guest contacts you.',
    chatsSelectedHeader: 'There is no Chat selected',
    chatsSelectedText: 'Select Chat on the left sidebar.',
    customersHeader: 'There are no Customers yet',
    customersText: 'Customers will appear here after Owner registers company.',
    deleteAccountHeader: 'Delete account',
    deleteAccountText:
      'If you no longer need your account, you can delete it but be advised, account deletion is final. There will be no way to restore your account.',
    departmentsHeader: 'There are no Departments yet',
    departmentsText:
      'Add Department to split Operators into groups. It allows to forward Chats to exact group of specialists.',
    widgetsHeader: 'There are no Chat Widgets yet',
    widgetsText: 'Add Widget to allow your Guests send and receive messages.',
    paymentsHeader: 'There are no Payments yet',
    paymentsText: 'Payment will appear here after Guest pays for the plan.',
    templatesHeader: 'There are no templates added yet',
    templatesText: 'Compose a message template for a quick responses.',
    templateSelectedHeader: 'There is no template selected',
    templateSelectedText: 'Select template on the left sidebar.',
    invoicesHeader: 'There are no Invoices yet',
    invoicesText: '',
  },
  menu: {
    accountSettings: 'Account Settings',
    bulkMessaging: 'Bulk Messaging',
    chats: 'Chats',
    contacts: 'Contacts',
    customers: 'Customers',
    dashboard: 'Dashboard',
    deleteAccount: 'Delete account',
    deleteCompany: 'Delete company',
    departments: 'Departments',
    general: 'General',
    helpCenter: 'Help Center',
    hoursOfWork: 'Hours of work',
    invoices: 'Invoices',
    members: 'Members',
    myCompany: 'My company',
    notifications: 'Notifications',
    payments: 'Payments',
    profile: 'My Profile',
    settings: 'Settings',
    signOut: 'Sign out',
    subscription: 'Subscription',
    subscriptionPlans: 'Subscription Plans',
    teamChat: 'Team Chat',
    templates: 'Templates',
    widgets: 'Chat Widgets',
  },
  meta: {
    appDescription:
      'A business solution that automates and improves the process of communicating with customers. Chatbots, messengers, live chat on the site, and much more.',
    appTitle: 'Messengers and online chats for business | Skibble',
    bulkMessagingTitle: 'Skibble | Bulk Messaging',
    chatsTitle: 'Skibble | Chats',
    companyTitle: 'Skibble | My company',
    contactsTitle: 'Skibble | Contacts',
    customersTitle: 'Skibble | Customers',
    dashboardTitle: 'Skibble | Dashboard',
    membersTitle: 'Skibble | Members',
    settingsTitle: 'Skibble | Account settings',
    signTitle: 'Skibble connects!',
    subscriptionPlansTitle: 'Skibble | Subscription Plans',
    templatesTitle: 'Skibble | Templates',
  },
  popupAlerts: {
    'Department already exists': 'Department already exists',
    'Department is in use': 'Department is in use',
    'Wrong email or password.': 'Wrong email or password',
    codeCopied: 'Code copied',
    dataSuccessfullySaved: 'Data successfully saved',
    emailConfirmed: 'Email confirmed',
    emailCopied: 'Email copied!',
    file: 'File',
    'file-too-large': '{{fileName}}\nSize limit is 20MB a file',
    image: 'Image',
    invitationLinkCopied: 'Invitation link copied!',
    invitationSent: 'Invitation sent',
    newMessage: 'New message',
    newChat: 'New chat',
    'Member already exists': 'Member already exists',
    "Member is owner of company and can't be deleted": "Member is owner of company and can't be deleted",
    'Member not found': 'The user does not exist',
    noActiveChannelsText: 'You have no active channels and can’t receive new chats. ',
    noActiveChannelsLink: 'Configure',
    only10Files: 'You can upload 10 files in a message',
    passRecoverySentCustomers: 'Password recovery instructions sent to {{email}}',
    somethingWentWrong: 'Something went wrong',
    'The user does not exist.': 'The user does not exist',
    wrongPass: 'Wrong password',
  },
  validation: {
    capital: 'Uppercase',
    checkboxError: 'Choose at least one option',
    email: 'Invalid email address',
    enterAllChars: 'Enter all characters',
    lowercase: 'Lowercase',
    emailsError: 'Choose at least one email',
    minLength: '8 Characters',
    number: 'Digit',
    onlyDigits: 'Only digits',
    passEqualTo: 'Passwords do not match',
    password: 'Your password does not meet the requirements',
    phone: 'Format is invalid, please enter number starting “+380”',
    price: 'Price format 0.00',
    privacy_policy_required_custom: 'This field is required',
    privacy_policy_website_custom: 'Check your link format',
    required: 'This field is required',
    requiredIfFieldsEmpty: 'Fill in one of these fields: {{fields}}',
    requiredIfFieldsNotEmpty: 'This field is required',
    specialChar: 'Special character',
  },
  termsAndConditions: {
    // text: '',
    // link: '',
    // text1: '',
    // header1: '',
    // text2: '',
    // header2: '',
    // text3: '',
  },
  privacyPolicy: {
    // text: '',
    // header1: '',
    // text1: '',
    // header2: '',
    // text2: '',
    // header3: '',
    // text3: '',
    // header4: '',
    // text4: '',
    // link: '',
    // text5: '',
  },
}
