import React from 'react'
import { components } from 'react-select'

import { StyledOptionRow } from '../styled'
import Icon from '../../../atoms/Icon/Icon'
import clsx from 'clsx'

const Option = (props) => {
  const { getOptionBeforeTextComponent, onOptionClick, optionIconColor, optionIconKey, initialValue } =
    props.selectProps
  // const optionValue = valueKey && props.data ? pick(valueKey, props.data) : props.value
  const optionIcon = props.data.iconName || (optionIconKey && props.data[optionIconKey])
  const iconClassName = props.data.className

  return (
    <StyledOptionRow
      onClick={onOptionClick && onOptionClick(props)}
      className={clsx(optionIcon ? 'withLeftIcon' : '', props.isSelected && 'selected')}
    >
      {(optionIcon || props.data.color) && (
        <Icon
          name={optionIcon}
          color={props.data.color}
          height={16}
          width={16}
          fill={optionIconColor}
          className={clsx(iconClassName, props.isDisabled && 'isOptionDisabled', 'optionLeftIcon')}
        />
      )}
      {getOptionBeforeTextComponent && getOptionBeforeTextComponent(props)}
      <components.Option {...props} />
      {/*{value && valueKey && optionValue === pick(valueKey, value) && (*/}
      {/*  <Icon className="rightIcon" name="check" />*/}
      {/*)}*/}
    </StyledOptionRow>
  )
}

export default Option
