import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useContext } from 'react'
import { useMappedState } from '../../hooks/useReactRedux'
import { ScreenContext } from '../../hooks/useScreenContext'
import { selectCurrentChatStatus } from '../../redux-saga/selectors'
import theme from '../../theme'
import AcceptButton from './components/AcceptButton'
import ChatAreaWithSearch from './components/ChatAreaWithSearch'
import Textarea from './components/Textarea'
import { StyledChatsContainer } from './components/styled'

const ChatContainer = () => {
  const { t } = useTranslation('all')

  const { width, md } = useContext(ScreenContext)
  const isMobile = width <= md

  const currentChatStatus = useMappedState(selectCurrentChatStatus)

  const searchButtonClicked = useRef(false)
  const [eventJustReceived, setEventJustReceived] = useState('')

  return (
    <>
      <ChatAreaWithSearch
        eventJustReceived={eventJustReceived}
        setEventJustReceived={setEventJustReceived}
        searchButtonClicked={searchButtonClicked}
        theme={theme}
        currentChatStatus={currentChatStatus}
        t={t}
      />
      <StyledChatsContainer className="textarea">
        {!isMobile && currentChatStatus === 'new' && <AcceptButton t={t} theme={theme} />}
        <Textarea setEventJustReceived={setEventJustReceived} theme={theme} t={t} />
      </StyledChatsContainer>
    </>
  )
}

export default ChatContainer
