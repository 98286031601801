import { put, select } from 'redux-saga/effects'

import { popupAlertShow, userLogout } from './redux-saga/actions'
import { verifyToken } from './redux-saga/sagas/auth'
import { selectUserSystemParams } from './redux-saga/selectors'

export const API_URL_START = process.env.REACT_APP_API_HOST || 'http://localhost:8000'

export const WEBSOCKET_URL_START = process.env.REACT_APP_WS_HOST || 'ws://localhost:8000'

export const GET_API_URLS = (role = 'manager', apiUrlParam) => ({
  billingPlans: `/${role}/plans`,
  bulkMessaging: `/${role}/bulks`,
  bulkContacts: `/${role}/bulks/contacts`,
  subscriptionPlans: `/${role}/admin/plans`,
  subscriptionPlansByCustomer: `/${role}/admin/plans/customer-plans`,
  cancelSubscription: `/${role}/billing/payments/cancel`,
  chats: `/${role}/chats`,
  chatSendMessage: `/${role}/chats/${apiUrlParam}/message`,
  chatSendAction: `/${role}/chats/${apiUrlParam}/action`,
  chatDetails: `/${role}/chats/events`,
  chatFoundNear: `/${role}/chats/events/near`,
  contacts: `/${role}/contacts`,
  contactsOnline: '/chat/contacts_online',
  countries: '/api/common/country_codes',
  currentCompany: `/${role}/current_company`,
  currentUser: `/${role}/current_user`,
  customers: `/${role}/customers${apiUrlParam ? `/${apiUrlParam}` : ''}`,
  customersInvoice: `/${role}/customers/generate-invoice`,
  deleteCompany: `/${role}/customers/delete_company`,
  departments: `/${role}/my_company/departments`,
  payments: `/${role}/billing/payments`,
  templates: `/${role}/my_company/templates`,
  widgets: `/${role}/my_company/widgets`,
  emailConfirm: '/api/auth/confirm_email',
  invite: `/${role}/members/invite`,
  inviteLink: `/${role}/members/${apiUrlParam}/invite`,
  inviteResend: `/${role}/members/${apiUrlParam}/resend_invite`,
  inviteDetails: '/api/auth/confirm_invite_details',
  inviteSignup: '/api/auth/confirm_invite',
  members: `/${role}/members`,
  refreshToken: '/api/auth/refresh-token',
  requestResetPassword: '/api/auth/request_reset_password',
  resetPassword: '/api/auth/reset_password',
  updatePassword: '/api/auth/update_password',
  signIn: '/api/auth/signin',
  signUp: '/api/auth/signup',
  tags: `/${role}/contacts/tags`,
  tokenRefresh: '/api/auth/refresh',
  fileUrl: `/chat/file_url${apiUrlParam}`,
  filesUpload: '/chat/operator/upload',
  statistics: `/${role}/stats/metrics/${apiUrlParam}`,
  statisticMetric: `/${role}/stats/top/${apiUrlParam}`,
  updateCurrentPlan: `/${role}/plans/set-plan-subscription`, // manual plan change
  wayforpayServiceUrl: '/billing/payment-confirm',
})

export function buildApiUrl({ apiUrlParam, id, queryParams = [], type }) {
  let url = `${API_URL_START}${GET_API_URLS(undefined, apiUrlParam)[type]}`

  if (id) {
    url += url.endsWith('/') ? id : `/${id}`
  }

  // _C_ - comma, _E_ - equals (backend uses this encoding to parse the url

  if (queryParams.length) {
    const filters =
      queryParams
        .filter(
          (param) =>
            ![
              'sort',
              'limit',
              'offset',
              'date_from',
              'date_to',
              'columns',
              'before',
              'searchValue',
              'searchFields',
              'customer_id',
              'tags',
              'channels',
            ].includes(param.key)
        )
        .map((val) => `${val.key}_E_${val.value}`) || []

    const getParamValue = (key) => queryParams.find((param) => param.key === key)?.value

    const searchFields = getParamValue('searchFields')
    const searchValueParsedToFilters = searchFields
      ? [`${searchFields}__like_E_${encodeURIComponent(getParamValue('searchValue'))}`]
      : []

    const filtersAll = filters.concat(searchValueParsedToFilters).join('_C_')

    const urlParams = {
      filters: filtersAll,
      sort: getParamValue('sort')
        ?.replace(/,/g, '_C_')
        .replace(/-asc/g, '_E_asc')
        .replace(/-desc/g, '_E_desc')
        .replace(/=asc/g, '_E_asc')
        .replace(/=desc/g, '_E_desc'),
      limit: getParamValue('limit'),
      offset: getParamValue('offset'),
      date_from: getParamValue('date_from'),
      date_to: getParamValue('date_to'),
      before: getParamValue('before'),
      tags: getParamValue('tags'),
      channels: getParamValue('channels'),
      customer_id: getParamValue('customer_id'),
    }

    const urlParamsFiltered = Object.keys(urlParams).filter(
      (key) => !!urlParams[key] || urlParams[key] === 0 || !!urlParams[key]?.length
    )

    if (urlParamsFiltered.length) {
      url += `?${urlParamsFiltered.map((urlParam) => `${urlParam}=${urlParams[urlParam]}`).join('&')}`
    }
  }

  return url
}

export function* buildRequest({
  apiMethod,
  apiUrlParam,
  id,
  queryParams,
  requestBody,
  tokenArg,
  tokenExpArg,
  type,
  isOpenData,
}) {
  let token
  if (type !== 'refreshToken' && type !== 'signIn' && !isOpenData) {
    token = yield verifyToken(tokenArg, tokenExpArg)
  }

  const isMultipartFormData = type === 'filesUpload'

  const headers = {
    Origin: window.location.origin,
  }

  if (token && !isOpenData) {
    headers.Authorization = `Bearer ${token}`
  }

  if (!isMultipartFormData) {
    headers['Content-Type'] = 'application/json'
  }

  const options = {
    headers: new Headers(headers),
    method: apiMethod,
  }

  if (requestBody) {
    options.body = isMultipartFormData ? requestBody : JSON.stringify(requestBody)
  }
  return new Request(buildApiUrl({ apiUrlParam, id, queryParams, type }), options)
}

export function* fetchData(request) {
  const response = yield fetch(request)

  return yield response.json()
}

export function* showError(response) {
  if (!response || (response?.detail && Object.keys(response).length === 1)) {
    if (response?.detail) {
      const regEx = new RegExp(/^(Auth0:\d\d\d\s+[\s\S]*)$/)
      const errorText = typeof response.detail === 'string' ? response.detail : response.detail?.[0]?.msg

      let currentError = errorText
      if (regEx.test(errorText)) {
        const authText = errorText.split(' ', 1)[0]
        currentError = errorText.replace(`${authText} `, '')
      }

      const { isAuthenticated } = yield select(selectUserSystemParams)

      const authTokenError = ['Missing bearer token', 'Unauthorized'].includes(errorText)

      if (isAuthenticated && authTokenError) {
        yield put(userLogout())
      }

      yield put(
        popupAlertShow({
          contentKey: currentError || 'somethingWentWrong',
          type: 'error',
          timeout: 10000,
          withCloseButton: true,
          iconName: 'statusDeleted',
          stacked: !!authTokenError,
        })
      )
    }
    throw new Error(response?.detail || 'Something went wrong')
  }
}
