import { DEFAULT_TABLE_SORTING } from './defaultValues'

export const RIGHT_PANEL_CUSTOM_ROUTES = ['invite', 'new']

export const COLLECTIONS_WITH_TABLE = [
  'members',
  'contacts',
  'customers',
  'departments',
  'widgets',
  'payments',
  'billingPlans',
  'bulkMessaging',
  'subscriptionPlans',
]

export const COLLAPSED_FORM_BLOCKS = []

export const PLANS = ['Free', 'Basic', 'Pro', 'Custom']

export const ENTITIES = {
  common: [
    {
      key: 'dashboard',
      route: '/',
      iconName: 'dashboard',
    },
    {
      key: 'chats',
      route: '/chats',
      iconName: 'chats',
    },
    {
      key: 'templates',
      route: '/templates',
      iconName: 'templates',
    },
    // { key: 'teamChat', route: '/team-chat', iconName: 'teamChat' },
  ],
  roleSensitive: [
    {
      key: 'contacts',
      route: '/contacts',
      iconName: 'contacts',
      sortObject: DEFAULT_TABLE_SORTING.contacts,
      rolesWithAccess: ['system_administrator', 'customer_administrator', 'customer_owner'],
    },
    {
      key: 'bulkMessaging',
      route: '/bulk-messaging',
      iconName: 'bulkMessaging',
      sortObject: DEFAULT_TABLE_SORTING.bulkMessaging,
      rolesWithAccess: ['system_administrator', 'customer_administrator', 'customer_owner'],
    },
    {
      key: 'members',
      route: '/members',
      iconName: 'members',
      sortObject: DEFAULT_TABLE_SORTING.members,
      rolesWithAccess: ['system_administrator', 'customer_administrator', 'customer_owner'],
    },
  ],
  systemAdminOnly: [
    {
      key: 'customers',
      route: '/customers',
      iconName: 'customers',
      sortObject: DEFAULT_TABLE_SORTING.customers,
      rolesWithAccess: ['system_administrator'],
    },
    {
      key: 'subscriptionPlans',
      route: '/subscription-plans',
      iconName: 'plans',
      sortObject: DEFAULT_TABLE_SORTING.subscriptionPlans,
      rolesWithAccess: ['system_administrator'],
    },
    {
      key: 'payments',
      route: '/payments',
      iconName: 'invoices',
      text: 'payments',
      sortObject: DEFAULT_TABLE_SORTING.payments,
      rolesWithAccess: ['system_administrator'],
    },
  ],
}

export const MY_COMPANY = [
  { key: 'general', route: '/my-company/general', iconName: 'equalizer', text: 'general' },
  {
    key: 'widgets',
    route: '/my-company/chat-widgets',
    iconName: 'widgets',
    text: 'widgets',
    sortObject: DEFAULT_TABLE_SORTING.widgets,
  },
  {
    key: 'departments',
    route: '/my-company/departments',
    iconName: 'multiUsers',
    text: 'departments',
    sortObject: DEFAULT_TABLE_SORTING.departments,
  },
  { key: 'hoursOfWork', route: '/my-company/hours-of-work', iconName: 'clock', text: 'hoursOfWork' },
  { key: 'notifications', route: '/my-company/notifications', iconName: 'bell', text: 'notifications' },
  {
    key: 'subscription',
    route: '/my-company/subscription',
    iconName: 'subscription',
    text: 'subscription',
    rolesWithAccess: ['system_administrator', 'customer_owner'],
  },
  {
    key: 'payments',
    route: '/my-company/payments',
    iconName: 'invoices',
    text: 'payments',
    sortObject: DEFAULT_TABLE_SORTING.payments,
    rolesWithAccess: ['customer_owner'],
  },
  // {
  //   key: 'invoices',
  //   route: '/my-company/invoices',
  //   iconName: 'invoices',
  //   text: 'invoices',
  //   sortObject: DEFAULT_TABLE_SORTING.invoices,
  // },
]

export const SETTINGS = {
  website: [
    { key: 'subscription', route: '/settings/subscription', iconName: '' },
    { key: 'payments', route: '/settings/payments', iconName: '' },
    { key: 'invoices', route: '/settings/invoices', iconName: '' },
    { key: 'widgets', route: '/settings/widgets', iconName: '' },
  ],
  notifications: [{ key: 'notifications', route: '/settings/notifications', iconName: '' }],
}
