import React, { forwardRef } from 'react'
import ReactTexty from 'react-texty'

import { Icon, Link, Typography } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectCurrentChatInfo } from '../../../redux-saga/selectors'
import { transformDateWithDay } from '../../../utils/dates'
import { StyledAction, StyledActionWrapper, StyledLine } from './styled'

const ChatActionDivider = forwardRef(
  ({ action: { at, type, operator_name, data = {} }, areaWidth, t, theme }, ref) => {
    const {
      closed_by,
      department_name,
      from_operator_name,
      to_operator_name,
      to_department_name,
      operator_name: operatorName,
      origin,
      rating,
    } = data

    const regex = /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g
    const origin_text = origin?.replace(regex, '')

    const { channel, widget_name, contact_name, contact_id } = useMappedState(selectCurrentChatInfo)

    const borderColor = {
      forwarded_operator: theme.color.tags.light.orange,
      forwarded_department: theme.color.tags.light.orange,
      operator_rated: theme.color.secondary.lighter,
    }

    const isChatForwarded = type === 'forwarded_operator' || type === 'forwarded_department'

    const timestamp = transformDateWithDay(at)

    const maxActionTextWidth =
      areaWidth < 350 ? '100px' : areaWidth < 450 ? '150px' : areaWidth < 500 ? '200px' : '250px'

    const getContent = () => {
      if (type === 'chat_started') {
        return (
          <div className="actionDividerContainer chat_started">
            <Typography variant="caption3" text={`${widget_name} → `} />
            <Icon name={`${channel}Icon`} width={14} height={14} />
            {origin ? (
              <Link
                isOuterLink
                href={origin}
                text={origin_text.endsWith('/') ? origin_text.slice(0, -1) : origin_text}
                noStyles
              />
            ) : (
              <Typography textTransform="capitalize" variant="caption3" text={channel.replace('_', ' ')} />
            )}
            {department_name && <Typography variant="caption3" text={` ${t('for')} ${department_name}`} />}
            <Typography variant="caption3" text={` • ${timestamp}`} />
          </div>
        )
      }

      if (type === 'operator_joined') {
        return (
          <div className="actionDividerContainer">
            <Typography
              variant="caption3"
              text={operatorName || operator_name}
              as={ReactTexty}
              className="longActionText"
            />
            <Icon name="skibbleIcon" width={14} height={14} />
            <Typography
              variant="caption3"
              text={` ${t('joinedChat')} • ${timestamp}`}
              as={ReactTexty}
              className="longActionText"
            />
          </div>
        )
      }

      if (isChatForwarded) {
        return (
          <div className="actionDividerContainer">
            <Icon name="forwarded" fill={theme.color.primary.main} />
            <Typography
              variant="caption3"
              color={theme.color.primary.main}
              text={`${t('forwardedTo', {
                departmentOrOperatorName: to_department_name || to_operator_name,
                operatorName: from_operator_name,
              })}`}
              as={ReactTexty}
              className="longerText"
            />
            <Typography variant="caption3" color="#FF5F00" text={`• ${timestamp}`} />
          </div>
        )
      }

      if (type === 'chat_closed') {
        return (
          <div className="actionDividerContainer">
            <Icon name="statusActive" width={14} height={14} stroke={theme.color.general.dark} fill="none" />
            <Typography
              variant="caption3"
              color={theme.color.general.dark}
              text={
                closed_by === 'operator'
                  ? `${t('closedBy')} ${operatorName || operator_name}`
                  : `${t('closedByGuest')} ${contact_name || `#${contact_id}`}`
              }
              as={ReactTexty}
              className="longActionText"
            />
            <Typography
              variant="caption3"
              color={theme.color.general.dark}
              text={` • ${timestamp}`}
              as={ReactTexty}
              className="longText"
            />
          </div>
        )
      }

      if (type === 'operator_rated') {
        return (
          <div className="actionDividerContainer">
            <Icon name="starSign" width={14} height={14} fill={theme.color.primary.lightest} />
            <Typography
              variant="caption3"
              color={theme.color.secondary.dark}
              text={`${t('rated')} ${rating} → ${operatorName || operator_name} • ${timestamp}`}
              as={ReactTexty}
              className="longText"
            />
          </div>
        )
      }
    }

    return (
      <StyledActionWrapper ref={ref}>
        <StyledLine />
        <StyledAction
          borderColor={borderColor[type] || theme.color.general.lighter}
          width={maxActionTextWidth}
        >
          {getContent()}
        </StyledAction>
        <StyledLine />
      </StyledActionWrapper>
    )
  }
)

export default ChatActionDivider
