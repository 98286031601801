import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { useMappedState, usePrevious } from '../../hooks/useReactRedux'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import Form from '../../components/Form'
import { selectResetPassMetaData, selectUserDetails } from '../../redux-saga/selectors'
import { EMAIL, FULL_NAME, LANGUAGE, LANGUAGES, PHONE } from '../../config/forms'
import { StyledSettingsProfile } from './styled'
import { Button, Typography } from '../../atomic-design-components'
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist'
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture'
import theme from '../../theme'
import { transformDataToBeSent } from '../../utils/transformDataToBeSent'
import { isObjectEmpty } from '../../utils/isObjectEmpty'
import { upsertSidebarItem, userResetPassword } from '../../redux-saga/actions'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'

const passwordsInitialValues = {
  password: '',
  new_password: '',
}

const FormBlockWrapper = withTheme(({ children, blockKey, id, formValues, theme }) => {
  const { t } = useTranslation('all')

  if (blockKey === 'languages') {
    return (
      <>
        <Typography
          text={t('languagesExplain')}
          variant="body2"
          color={theme.color.general.dark}
          className="explanation"
        />
        {children}
      </>
    )
  }

  if (blockKey === 'profilePicture') {
    return (
      <>
        <Typography
          text={t('profilePictureTip')}
          variant="body2"
          color={theme.color.general.dark}
          className="explanation"
        />
        {children}
      </>
    )
  }
  // if (blockKey === 'passwordSecurity') {
  //   return <>{children}</>
  // }

  return children
})

const SettingsProfile = () => {
  const dispatch = useDispatch()
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation('all')

  const navigate = useNavigate()

  const { inProgress, error } = useMappedState(selectResetPassMetaData)
  const prevInProgress = usePrevious(inProgress)

  const formDataInitial = useMappedState(selectUserDetails)
  const initialValues = formDataInitial?.phone?.phone
    ? formDataInitial
    : {
        ...formDataInitial,
        phone: { phone: formDataInitial.phone },
      }

  const [password, setPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(undefined)
  const { type, iconRightProps } = useIsPasswordShown()

  useEffect(() => {
    if (prevInProgress && !inProgress && !error) {
      setNewPassword('')
      setPassword('')
    }
  }, [inProgress, prevInProgress, error])

  const onFormSubmit = (valuesChanged) => {
    const newValues = {
      ...formDataInitial,
      ...transformDataToBeSent(valuesChanged),
      full_name: valuesChanged.member_full_name,
    }

    if (!isObjectEmpty(valuesChanged)) {
      if (!isObjectEmpty(newValues.phone)) {
        newValues.phone = newValues['phone.phone']
        delete newValues['phone.phone']
      }

      dispatch(
        upsertSidebarItem({
          id: formDataInitial.member_id,
          type: 'members',
          requestBody: newValues,
          navigate,
          location,
        })
      )
    }
  }

  const onEmailChange = () => {}
  const isPassButtonDisabled = !(password && new_password && password !== new_password && isPasswordValid)
  const onPasswordChange = (credentials) => {
    dispatch(userResetPassword(true, { ...credentials, email: initialValues.email }, navigate))
  }

  return (
    <>
      <StyledSettingsProfile>
        <Form
          initialValues={initialValues}
          validationRules={{
            member_full_name: ['required'],
            'phone.phone': ['phone'],
          }}
          formConfig={{
            fields: {
              profilePicture: [
                {
                  key: 'member_profile_picture',
                  component: (props) => (
                    <ProfilePicture props={props} theme={theme} memberID={initialValues.member_id} />
                  ),
                },
              ],
              personalInformation: [
                { ...FULL_NAME, key: 'member_full_name' },
                { ...PHONE, placeholder: '+380' },
              ],
              languages: [
                {
                  ...LANGUAGE,
                  key: 'lang',
                  label: 'interfaceLanguage',
                  onSelectValueChange: (value) => {
                    changeLanguage(Object.values(value))
                    const cookies = new Cookies()
                    cookies.set('lng', value.id, { path: '/' })
                  },
                },
                { ...LANGUAGES, key: 'languages', label: 'operationalLanguages' },
              ],
            },
          }}
          onSubmit={onFormSubmit}
          FormBlockWrapper={FormBlockWrapper}
          expansionPanelProps={{
            disabled: true,
            withChevronInHeader: false,
          }}
          isSubmitOnBlur
        ></Form>
        {/*<Form*/}
        {/*  className="rowsForm"*/}
        {/*  initialValues={initialValues}*/}
        {/*  validationRules={{*/}
        {/*    email: ['email'],*/}
        {/*  }}*/}
        {/*  formConfig={{*/}
        {/*    fields: {*/}
        {/*      changeEmail: [EMAIL],*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  onSubmit={onEmailChange}*/}
        {/*  expansionPanelProps={{*/}
        {/*    disabled: true,*/}
        {/*    withChevronInHeader: false,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Button*/}
        {/*    variant="primary"*/}
        {/*    type="submit"*/}
        {/*    iconName="floppy"*/}
        {/*    className="submitFormButton"*/}
        {/*    onClick={() => setIsSubmitPressed(true)}*/}
        {/*  >*/}
        {/*    {t('save')}*/}
        {/*  </Button>*/}
        {/*</Form>*/}
        <Form
          serverError={error}
          inProgress={inProgress}
          className="rowsForm passForm"
          initialValues={passwordsInitialValues}
          validationRules={{
            password: [
              {
                type: 'requiredIfFieldsNotEmpty',
                fields: ['new_password', 'repeatPassword'],
                values: { new_password, repeatPassword },
              },
            ],
            newPassword: [
              {
                type: 'requiredIfFieldsNotEmpty',
                fields: ['password', 'repeatPassword'],
                values: { password, repeatPassword },
              },
              { type: 'password', isPasswordValid: !new_password || isPasswordValid },
            ],
          }}
          formConfig={{
            fields: {
              passwordSecurity: [
                {
                  key: 'password',
                  label: 'currentPassword',
                  variant: 'secondary',
                  type: 'password',
                  placeholder: 'password',
                  labelType: 'border',
                  iconLeftProps: { name: 'passwordLocked' },
                  className: 'password currPass',
                  onInputValueChange: (value) => setPassword(value),
                },
                {
                  key: 'new_password',
                  label: 'newPassword',
                  variant: 'secondary',
                  type,
                  placeholder: 'password',
                  labelType: 'border',
                  iconLeftProps: { name: 'passwordLocked' },
                  iconRightProps,
                  className: 'password newPass',
                  onInputValueChange: (value) => setNewPassword(value),
                },
              ],
            },
          }}
          onSubmit={onPasswordChange}
          FormBlockWrapper={FormBlockWrapper}
          expansionPanelProps={{
            disabled: true,
            withChevronInHeader: false,
          }}
        >
          <Button
            variant="primary"
            type="submit"
            iconName="floppy"
            className="submitFormButton"
            onClick={() => setIsSubmitPressed(true)}
            disabled={isPassButtonDisabled}
          >
            {t('save')}
          </Button>
          {new_password && (
            <CustomPasswordChecklist
              password={new_password}
              setIsPasswordValid={setIsPasswordValid}
              className={isSubmitPressed && new_password && !isPasswordValid ? 'passError' : ''}
            />
          )}
        </Form>
      </StyledSettingsProfile>
    </>
  )
}

export default SettingsProfile
