import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { withTheme } from 'styled-components'
import { Checkbox, DatePicker, Label, Switch } from '../../atomic-design-components'
import MessageTextarea from '../../atomic-design-components/molecules/MessageTextarea/MessageTextarea'
import FormInput from './FormInput'
import FormSelect from './FormSelect'

const FormField = ({
  field,
  formError,
  formErrors,
  formFlexDirection,
  formSubmit,
  formValue,
  initialValue,
  isDisabled,
  isHidden,
  isSubmitOnBlur,
  isTabs,
  labelKey,
  labelType,
  name,
  optionsData,
  optionsFromFormValues,
  sidebarFormId,
  submitByEnterPressed,
  updateCheckbox,
  updateInput,
  updateSelect,
  validateField,
  validationRules,
  theme,
}) => {
  const { t, i18n } = useTranslation(['all', 'validation'])
  const lng = i18n.language

  const isSelect = field.component === 'dropdown'

  const disabled = field.disabled || field.isDisabled || isDisabled

  const valueNormalized = typeof formValue === 'number' ? formValue.toString(10) : formValue
  const value =
    (field.transformValue ? field.transformValue(valueNormalized, lng) : valueNormalized) ||
    field.defaultValue ||
    ''

  const labelTranslation =
    field.label && (i18n.exists(field.label, { ns: 'all' }) ? t(field.label) : t(`table:${field.label}`))

  useEffect(() => {
    // TODO: fix for units in item content and stock items warehouse_id
    if (
      field.defaultValue &&
      (formValue?.id !== field.defaultValue?.id || formValue !== field.defaultValue)
    ) {
      // console.log(defaultValue, formValue)
      if (isSelect) {
        updateSelect(field.key)(field.defaultValue)
      } else {
        updateInput({ target: { name: field.key, value: field.defaultValue } })
      }
    }
  }, [])

  const commonSelectAndInputProps = {
    className: clsx('formInput', isHidden && 'isHidden', field.className),
    disabled,
    error:
      formError && typeof formError === 'string' && formError !== 'success'
        ? t(
            `validation:${formError}`,
            field.validationTranslationKeys
              ? {
                  fields: field.validationTranslationKeys.reduce(
                    (acc, curr, i, arr) => `${acc}${t(curr)}${i < arr.length - 1 ? ', ' : ''}`,
                    ''
                  ),
                }
              : {}
          )
        : undefined,
    fullWidth: true,
    // onBlur: onValidateField(field.key),
    field,
    formSubmit,
    initialValue: initialValue || field.initialValue || '',
    isDisabled: disabled,
    isHidden,
    isSubmitOnBlur,
    label: labelTranslation,
    labelType: field.labelType || labelType,
    labelWidth: '100px',
    name,
    placeholder:
      field.placeholder &&
      (i18n.exists(field.placeholder, { ns: 'all' })
        ? t(field.placeholder)
        : t(`table:${field.placeholder}`)),
    primaryFocusColor: true,
    required: isTabs
      ? field.required && validationRules[name]?.includes('required')
      : field.required || false,
    success: formError === 'success',
    t,
    type: field.type,
    withBorder: true,
    value,
    variant: field.variant,
    validateField,
  }

  if (field.component === 'checkbox') {
    return (
      <Checkbox
        {...field}
        disabled={disabled}
        name={name}
        value={field.value}
        checked={formValue}
        variant={field.variant || 'primary'}
        label={labelTranslation}
        handleChange={field.onChange || updateCheckbox}
        labelType={field.labelType || 'body2'}
        className="formCheckbox"
      />
    )
  }

  if (field.component === 'switch') {
    return (
      <Switch
        {...field}
        disabled={disabled}
        name={name}
        checked={formValue}
        label={labelTranslation}
        labelColor={theme.color.general.dark}
        onChange={field.onChange || updateCheckbox}
      />
    )
  }

  if (field.component === 'date') {
    return (
      <DatePicker
        onChange={(date) => {
          updateInput({ target: { name, value: date } }, field.onInputValueChange)
        }}
        minDate={field.minDate}
        withIcon
        {...commonSelectAndInputProps}
      />
    )
  }

  if (field.component === 'messageTextarea') {
    return (
      <MessageTextarea
        {...field}
        {...commonSelectAndInputProps}
        onChange={(e) => updateInput(e)}
        name={field.key}
      />
    )
  }

  if (typeof field.component === 'function') {
    return (
      <div className={clsx(formFlexDirection, field.className, isHidden && 'isHidden')}>
        {!field.labelTopHidden && labelTranslation && <Label text={labelTranslation} />}
        {field.component({
          ...field,
          ...commonSelectAndInputProps,
          // isDisabled: disabled,
          // disabled,
          // isHidden,
          // label: labelTranslation,
          // labelType: field.labelType || labelType,
          // t,
          // name,
          // value,
          errors: formErrors,
          formSubmit,
          isSubmitOnBlur,
          lng,
          optionsData,
          sidebarFormId,
          labelKey,
          optionsFromFormValues,
          updateSelect,
          updateInput,
          updateCheckbox,
        })}
      </div>
    )
  }

  return (
    // <StyledInputWrapper
    //   width={field.width}
    //   className={clsx(formFlexDirection, field.className, isHidden && 'isHidden')}
    // >
    field.component === 'dropdown' ? (
      <FormSelect
        {...commonSelectAndInputProps}
        labelKey={labelKey}
        lng={lng}
        optionsData={optionsData}
        optionsFromFormValues={optionsFromFormValues}
        updateSelect={updateSelect}
      />
    ) : (
      <FormInput
        submitByEnterPressed={submitByEnterPressed}
        updateInput={updateInput}
        {...commonSelectAndInputProps}
      />
    )
    // </StyledInputWrapper>
  )
}

export default withTheme(FormField)
