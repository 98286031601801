import React from 'react'

import { Badge, ExpansionPanel, Typography } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectClosedCount } from '../../../redux-saga/selectors'
import { StyledContainer } from '../components/styled'
import ChatsListClosed from './ChatsListClosed'
import ChatsListItem from './ChatsListItem'
import { StyledHeader } from './styled'

const ChatsList = ({ type, chatsData, t }) => {
  const closedCount = useMappedState(selectClosedCount)
  const isClosedChat = type === 'closed'
  const total_items = isClosedChat ? closedCount : chatsData?.length || '0'

  const panelHeader = (
    <StyledHeader>
      <Typography text={t(type)} variant="button1" />
      <Badge returnBadgeOnly badgeContent={total_items} className="itemsCountBadge" />
    </StyledHeader>
  )

  const getChatsList = () => {
    if (isClosedChat) {
      return <ChatsListClosed chatsData={chatsData} closedCount={closedCount} t={t} />
    }

    return chatsData?.map((chat) => {
      return <ChatsListItem data={chat} key={chat.contact_id} type={type} t={t} />
    })
  }

  return (
    <StyledContainer className={type}>
      <ExpansionPanel
        header={panelHeader}
        withChevronInHeader={!!chatsData}
        initialOpened
        panelName={`${type}ChatsLeftPanel`}
      >
        {getChatsList()}
      </ExpansionPanel>
    </StyledContainer>
  )
}

export default ChatsList
