import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import { PropTypes as T } from 'prop-types'

import { StyledPopupAlert } from './styled'
import Icon from '../../atoms/Icon'
import Typography from '../../atoms/Typography'
import clsx from 'clsx'
import { useTimeout } from '../../../hooks/useTimeout'

const MAP_TYPE_TO_COLOR = {
  default: 'general.black',
  new: 'status.new',
  success: 'status.success',
  warning: 'status.warning',
  error: 'status.error',
}

const PopupAlert = ({
  children,
  className,
  content,
  iconName,
  id,
  onClose,
  position,
  theme: { color },
  timeout,
  type,
  withCloseButton,
  index,
}) => {
  const backgroundColor = MAP_TYPE_TO_COLOR[type]
  const splitThemeColor = backgroundColor.split('.')

  const [hide, setHide] = useState(false)
  const isNotClosable = timeout === 0

  useEffect(() => {
    if (hide) {
      const closeId = setTimeout(() => {
        onClose(id)
      }, 300)

      return () => {
        clearTimeout(closeId)
      }
    }
  }, [hide])

  const onPopupClose = () => {
    setHide(true)
  }

  useEffect(() => {
    if (timeout && withCloseButton && index > 3) {
      onPopupClose()
    }
  }, [timeout, withCloseButton, index, onPopupClose])

  useTimeout(() => {
    if (!isNotClosable) {
      onPopupClose()
    }
  }, timeout)

  return (
    <StyledPopupAlert splitThemeColor={splitThemeColor} className={clsx(className, position, type, hide && 'hide')}>
      {iconName && (
        <Icon name={iconName} className="leftIcon" fill={color.general.white} stroke={color.general.white} />
      )}

      <Typography variant="body2">{children || content}</Typography>
      {withCloseButton && (
        <Icon
          name="cross"
          className="rightIcon"
          wrapperColor="transparent"
          fill={color.general.white}
          height={18}
          width={18}
          onClick={onPopupClose}
        />
      )}
    </StyledPopupAlert>
  )
}

export default withTheme(PopupAlert)

PopupAlert.propTypes = {
  content: T.oneOfType([T.object, T.string]),
  position: T.oneOf(['top', 'bottom']),
  type: T.oneOf(['default', 'new', 'success', 'warning', 'error']),
  children: T.node,
  iconName: T.string,
  id: T.string,
  index: T.number,
  onClose: T.func,
  theme: T.object,
  timeout: T.number,
  withCloseButton: T.bool,
}

PopupAlert.defaultProps = {
  position: 'top',
  type: 'default',
}
