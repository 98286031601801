import React from 'react'

export const offline = ({ fill, height, theme: { color }, width }) => (
  <svg width={width || 18} height={height || 16} viewBox="0 0 18 16">
    <g clipPath="url(#clip0_12870_277488)">
      <path
        d="M10.326 1.3256C10.326 0.593492 9.73251 0 9.00041 0C8.2683 0 7.6748 0.593492 7.6748 1.3256V11.0555C7.6748 11.7876 8.2683 12.3811 9.00041 12.3811C9.73251 12.3811 10.326 11.7876 10.326 11.0555V1.3256Z"
        fill={fill || color.general.dark}
      />
      <path
        d="M9.00041 16C9.73251 16 10.326 15.4065 10.326 14.6744C10.326 13.9422 9.73251 13.3488 9.00041 13.3488C8.2683 13.3488 7.6748 13.9422 7.6748 14.6744C7.6748 15.4065 8.2683 16 9.00041 16Z"
        fill={fill || color.general.dark}
      />
      <path
        d="M17.3118 4.28174C15.6666 2.96555 13.7076 2.0191 11.5586 1.60718C11.2593 1.54982 10.9887 1.78436 10.9887 2.0891V3.77475C10.9887 4.01501 11.16 4.22007 11.3941 4.27389C12.9863 4.63983 14.4466 5.36044 15.6813 6.36293C16.1983 6.77387 16.9539 6.72084 17.4179 6.25688C17.683 5.99176 17.8155 5.64711 17.8155 5.3157C17.8155 4.93128 17.6432 4.54686 17.3118 4.28174ZM0.582735 6.25688C0.834599 6.50874 1.17926 6.64131 1.51066 6.64131C1.80229 6.64131 2.08066 6.54851 2.31927 6.36293C3.55404 5.36044 5.01431 4.63983 6.60649 4.27389C6.84065 4.22007 7.0119 4.01502 7.0119 3.77475V2.0891C7.0119 1.78436 6.74131 1.54982 6.44202 1.60718C4.29296 2.0191 2.33402 2.96555 0.688783 4.28174C0.0657505 4.77221 0.0259825 5.70013 0.582735 6.25688Z"
        fill={fill || color.general.dark}
      />
      <path
        d="M13.4542 8.07286C12.886 7.68725 12.2633 7.37155 11.6042 7.14361C11.296 7.03707 10.9886 7.27605 10.9886 7.60206V9.40978C10.9886 9.61163 11.111 9.79178 11.292 9.88109C11.4967 9.98212 11.6934 10.0952 11.89 10.2203C12.4335 10.565 13.1361 10.5385 13.6 10.0745C13.8652 9.80939 13.9977 9.46474 13.9977 9.10682C13.9977 8.70914 13.8121 8.31146 13.4542 8.07286ZM4.40038 10.0745H4.41363C4.86434 10.5385 5.56691 10.565 6.1104 10.2203C6.29422 10.0978 6.4896 9.9868 6.69272 9.89119C6.88129 9.80243 7.01181 9.61829 7.01181 9.40987V7.60206C7.01181 7.27605 6.70438 7.03707 6.39627 7.14361C5.73709 7.37155 5.11445 7.68725 4.54619 8.07286C3.87014 8.52356 3.83037 9.5045 4.40038 10.0745Z"
        fill={fill || color.general.dark}
      />
    </g>
    <defs>
      <clipPath id="clip0_12870_277488">
        <rect width={17.6305} height={16} fill={color.general.white} transform="translate(0.185059)" />
      </clipPath>
    </defs>
  </svg>
)
