import React from 'react'
import { withTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWidth } from '../../hooks/useWidth'

import { parse } from 'query-string'
import { Button, Icon, Typography } from '../../atomic-design-components'
import { SEARCH_VALUE_MIN_LENGTH } from '../../constants'
import { StyledEmptyScreen } from './styled'

const EmptyScreen = ({ type, icon, hasButton, onButtonClick, buttonProps, theme }) => {
  const { search } = useLocation()
  const searchParsed = parse(search)

  const { t } = useTranslation('emptyPages')

  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.lg

  const navigate = useNavigate()

  const iconLeftProps = { name: type === 'deleteAccount' ? 'trashCan' : 'plusCircle', size: 16 }

  const typeData = {
    departments: { buttonText: 'all:addDepartment', navigate: () => navigate('/my-company/departments/new') },
    bulkMessaging: {
      buttonText: 'all:addBulkMessaging',
      navigate: () => navigate('/bulk-messaging/new'),
    },
    templates: { buttonText: 'all:addTemplates' },
    widgets: { buttonText: 'all:addChatWidget', navigate: () => navigate('/my-company/chat-widgets/new') },
    deleteAccount: { buttonText: 'all:deactivateAccount' },
    deleteCompany: {
      buttonText: 'menu:deleteCompany',
      header: 'menu:deleteCompany',
      text: 'emptyPages:deleteAccountText',
    },
  }

  const typeAfterCheck =
    type === 'chats' && searchParsed?.searchValue?.length >= SEARCH_VALUE_MIN_LENGTH ? 'chatsSelected' : type

  const hasActionButton =
    typeData[typeAfterCheck] &&
    (hasButton || type === 'departments' || type === 'widgets' || type === 'bulkMessaging')

  return (
    <>
      {/* <TableTitleBlock type={type} /> */}
      <StyledEmptyScreen className={typeAfterCheck}>
        <Icon name={icon || 'emptyFolder'} />
        <Typography variant={isMobile ? 'h4' : 'h3'} margin="20px 0 10px">
          {t(typeData[typeAfterCheck]?.header || `${typeAfterCheck}Header`)}
        </Typography>
        <Typography
          variant="body2"
          text={t(typeData[typeAfterCheck]?.text || `${typeAfterCheck}Text`)}
          className="inline"
        />
        {hasActionButton && (
          <Button
            text={t(typeData[typeAfterCheck]?.buttonText)}
            iconLeftProps={iconLeftProps}
            onClick={onButtonClick ? onButtonClick : typeData[typeAfterCheck]?.navigate}
            backgroundColor={
              typeAfterCheck.includes('delete') ? theme.color.status.error : theme.color.primary.main
            }
            {...buttonProps}
          />
        )}
      </StyledEmptyScreen>
    </>
  )
}

export default withTheme(EmptyScreen)
