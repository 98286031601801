import styled from 'styled-components'

export const StyledSubscription = styled.div`
  max-width: 760px;
  margin: 30px auto;
  padding: 0 30px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin: 20px 30px;
    padding: 0;
  }
`
