import {
  GET_OPEN_DATA_SUCCESS,
  GET_TABLE_DATA,
  GET_TABLE_DATA_ERROR,
  GET_TABLE_DATA_SUCCESS,
  SET_DATA_TYPE,
  SIGN_OUT,
} from '../constants'

const defaultState = {
  inProgress: null,
  isDataInitialized: false,
  type: '',
  error: false,
  total_items: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_DATA_TYPE:
      return {
        ...state,
        isDataInitialized: false,
        type: action.payload.type,
      }
    case GET_TABLE_DATA:
      return {
        ...state,
        inProgress: action.payload.isSystemData ? state.inProgress : true,
        total_items:
          action.payload.isSystemData || action.payload.search?.includes('searchValue')
            ? state.total_items
            : null,
      }
    case GET_TABLE_DATA_SUCCESS: {
      const { type, data, total, isSystemData, apiUrlParam } = action.payload

      return {
        ...state,
        inProgress: isSystemData ? state.inProgress : false,
        isDataInitialized: isSystemData ? state.isDataInitialized : true,
        [type]:
          type === 'statisticMetric' || type === 'statistics'
            ? { ...state[type], [apiUrlParam]: data }
            : data,
        total_items: total || (total === 0 ? 0 : state.total_items),
      }
    }
    case GET_TABLE_DATA_ERROR:
      return {
        ...state,
        inProgress: false,
        error: true,
      }
    case GET_OPEN_DATA_SUCCESS:
      return {
        ...state,
        [action.payload.type]: action.payload.data,
      }
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
