import React from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { StyledBadge, StyledBadgeWrapper } from './styled'
import capitalize from '../../../utils/capitalize'

const Badge = ({
  anchorOrigin,
  backgroundColor,
  badgeContent,
  borderColor,
  children,
  className,
  color,
  invisible,
  variant,
  isMini,
  returnBadgeOnly,
  ...otherProps
}) => {
  const { vertical, horizontal } = anchorOrigin

  if (returnBadgeOnly && !invisible) {
    return (
      <StyledBadge
        className={clsx(invisible && 'invisible', 'badgeOnly', className)}
        variant={variant}
        color={color}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      >
        {badgeContent}
      </StyledBadge>
    )
  }

  return (
    <StyledBadgeWrapper className={clsx(className, isMini && 'isMini', invisible && 'invisible')} {...otherProps}>
      {children}
      <StyledBadge
        className={clsx(`anchor${capitalize(vertical)}${capitalize(horizontal)}`, invisible && 'invisible')}
        variant={variant}
        color={color}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      >
        {badgeContent}
      </StyledBadge>
    </StyledBadgeWrapper>
  )
}

export default Badge

Badge.propTypes = {
  anchorOrigin: T.shape({ vertical: T.oneOf(['top', 'bottom']), horizontal: T.oneOf(['left', 'right']) }),
  backgroundColor: T.string,
  badgeContent: T.any,
  borderColor: T.string,
  children: T.node,
  className: T.string,
  color: T.string,
  invisible: T.bool,
  isMini: T.bool,
  returnBadgeOnly: T.bool,
  variant: T.oneOf(['primary', 'general']),
}

Badge.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  variant: 'primary',
}
