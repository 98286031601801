import React from 'react'

export const google = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M15.8158 6.8516C15.89 7.26142 15.9417 7.65487 15.9417 8.18723C15.9417 10.6927 14.9998 12.8014 13.3867 14.1941L11.258 12.0654C12.2013 11.3061 12.6347 10.2759 12.7417 9.6032H8.56348V6.85156H15.8158V6.8516Z"
      fill="#3B7DED"
    />
    <path
      d="M13.5643 2.09345L11.3868 4.18727C9.01008 1.89382 4.92859 2.95164 3.64241 5.97709L1.41626 3.75091C2.82971 1.496 5.33662 0 8.1999 0C10.3544 0 12.1675 0.790545 13.5643 2.09345Z"
      fill="#E43E2B"
    />
    <path
      d="M11.2581 12.0654L13.3868 14.1941C12.0614 15.3388 10.2821 15.9999 8.19993 15.9999C5.43084 15.9999 2.99521 14.6006 1.55957 12.4694L3.76721 10.2617C4.58357 11.9163 6.26615 13.0515 8.19993 13.0515C9.55739 13.0515 10.5508 12.6344 11.2581 12.0654Z"
      fill="#2BA24C"
    />
    <path
      d="M3.76719 10.2609L1.55955 12.4685C0.700679 11.1933 0.199951 9.65582 0.199951 7.99909C0.199951 6.43655 0.645406 4.98018 1.41631 3.75L3.6425 5.97618C3.38686 6.57655 3.24177 7.25436 3.24177 7.99909C3.24177 8.81218 3.43122 9.58055 3.76719 10.2609Z"
      fill="#F0B501"
    />
  </svg>
)
