export default {
  all: {
    acceptChat: 'Принять чат',
    accountStatus: 'Статус',
    action: 'Действия',
    activate: 'Активировать',
    activated: 'Активирован',
    active: 'Активный',
    addBulkMessaging: 'Добавить рассылку',
    addFirstItem: 'Добавить {{itemType}}',
    addChatWidget: 'Добавить Чат Виджет',
    addCustomer: 'Добавить клиента',
    addingChatWidget: 'Создание Чат Виджета',
    addDepartment: 'Добавить Отдел',
    addMembers: 'Добавить сотрудников',
    addTag: 'Добавить тег:',
    addTemplates: 'Добавить новый шаблон',
    addingDepartment: 'Создание отдела',
    administrators: 'Уведомить администраторов',
    all_channels_instant: 'Правила для мессенджеров и Live Chat',
    allCustomers: 'Все клиенты',
    allMembers: 'Все сотрудники',
    amount: 'Количество',
    annually: 'Ежегодно',
    approved: 'Одобренный',
    attachment: 'Вложение',
    'auto-messages': 'Автосообщения',
    availablePlans: 'Доступные планы',
    chat_duration: 'Средняя продолжительность',
    averageRating: 'Средний рейтинг',
    averageReaction: 'Средняя реакция',
    response_time: 'Средний ответ',
    bankAccountDetails: 'Банковские реквизиты',
    bankName: 'Название банка',
    bankCountry: 'Страна банка',
    billedAnnually: 'Оплата ежегодно',
    billedMonthly: 'Оплата ежемесячно ',
    botPlaceholder: 'Введите токен вашего бота',
    botHint: 'Сначала введите токен',
    botActivated: 'подключен',
    botPaused: 'приостановлен',
    bulkMessage: 'Рассылка',
    is_branding: 'Без брендинга Skibble',
    is_custom: 'Кастомный план',
    browser: 'Браузер',
    browserLanguage: 'Язык браузера',
    bulkErrors: 'Гости, не получившие сообщения ({{messagesCount}}):',
    bulkMessagePlaceholder: 'Введите сообщение, которое нужно отправить',
    bulkSuccess: 'Успешно отправлено: {{messagesCount}}.',
    cameFrom: 'Пришёл из',
    plan_business: 'Бизнес',
    cancel: 'Отменить',
    cancelled: 'Отмененный',
    cancelSubscription: 'Отменить подписку',
    channel: 'Канал',
    channelConfig: 'Конфигурация каналов',
    channels: 'Каналы',
    'chat-customization': 'Настройка виджета чата',
    chats: 'Чаты',
    chat_owner: 'Уведомить владельца чата',
    changeEmail: 'Изменить email',
    cherry: 'Вишневый',
    classic: 'Классический',
    clickToConfirm: 'Нажмите для подтверждения',
    clientFilters: 'Фильтры клиентов',
    clientCountTip: 'Получателей сообщения: {{count}}',
    close: 'Закрыть',
    closeChat: 'Закрыть чат',
    closed: 'Закрытые',
    closedBy: 'Закрыто',
    closedByGuest: 'Закрыто гостем',
    comment: 'Коментарий',
    companyEmail: 'Email компании',
    companyInformation: 'Информация о компании',
    companyInfoTip: 'Пожалуйста, заполните основную контактную информацию и отрасль вашего бизнеса.',
    companyInfoDescription: 'Чем занимается ваша компания?',
    companyName: 'Имя компании',
    companyPhone: 'Телефон компании',
    companyWebsite: 'Вебсайт компании',
    complete: 'Завершено',
    complete_with_error: 'Завершено с ошибкой',
    connect: 'Подключить',
    contactDetails: 'Детали контакта',
    contactInfo: 'Контактная информация',
    copyCode: 'Копировать код',
    coral: 'Коралловый',
    corporate: 'Корпоративный',
    count: 'Кол-во',
    create: 'Создать',
    created: 'Создано',
    createdAt: 'Создано ',
    currentPassword: 'Текущий пароль',
    currentPlan: 'Текущий план',
    customer_administrator: 'Администратор',
    customer_owner: 'Владелец',
    customers: 'Клиенты',
    customerInfo: 'Информация клиента',
    customization: 'Кастомизация',
    is_customization: 'Кастомизация',
    date: 'Дата',
    deactivate: 'Деактивировать',
    deactivateAccount: 'Удалить ваш аккаунт',
    declined: 'Отклоненный',
    delay: 'Задержка (секунды)',
    delayMS: 'Задержка (мм:сс)',
    delete: 'Удалить',
    deleteDepartment: 'Удалить отдел',
    deleteMemberPermanently: 'Удалить участника навсегда',
    departmentName: 'Название отдела',
    departments: 'Отделы',
    departmentsField: 'Отделы ({{choosedDep}} из {{allDeps}})',
    description: 'Описание',
    desktop: 'Десктоп',
    details: 'Детали',
    device: 'Устройство',
    EDRPOU: 'ЕДРПОУ',
    disabledCardTip: 'Сначала включите триггер для первого сообщения',
    draft: 'Черновик',
    editTemplate: 'Отредактируйте свой шаблон',
    email: 'Email',
    emailNotifications: 'Уведомления по электронной почте',
    enterChatWidgetName: 'Введите название чат-виджета',
    enterYourMessage: 'Введите ваше сообщение',
    enterYourPassword: 'Введите свой пароль',
    exp: 'до',
    expired: 'Истекший',
    expires: 'Истекает',
    facebookMessenger: 'facebook messenger',
    field: 'Поле',
    fieldsTooltip: 'Заполните все поля в карточке',
    firstLastName: 'Имя и фамилия',
    first_message: 'Перше повідомлення',
    firstMessageTip: 'Сообщение запускается с задержкой после загрузки виджета на страницу.',
    messageTipIdleOperator:
      'Сообщение запускается, если оператор не ответил на гостевое сообщение в течение указанного времени.',
    firstMessageTipLivechat: 'Уведомление запускается сразу после того, как клиент начинает чат.',
    firstSeen: 'Первое посещение',
    for: 'для',
    forms: 'Формы',
    forward: 'Направить ',
    forwardTo: 'Направить на',
    forwardedTo: 'Передано {{departmentOrOperatorName}} от {{operatorName}}',
    free: 'Бесплатно ',
    plan_free: 'Бесплатный план',
    planTitle: 'Название плана',
    friday: 'Пятница',
    fullName: 'Полное имя',
    general: 'Общие',
    generateInvoice: 'Сгенерировать счёт',
    gold: 'Золотой',
    grass: 'Травяной',
    guest: 'Гость',
    guestInfo: 'Информация гостя',
    guestOfflinePlaceholder: 'Гость сейчас не на сайте, он увидит ваши сообщения, когда вернется',
    guestOfflineText: 'Гость покинул вебсайт',
    hideBranding: 'Скрыть брендинг',
    h: 'ч',
    hoursOfWorkTitle: 'Установите часы работы вашей команды',
    hoursOfWorkTip: 'Время работы влияет на доступ Гостей к каналам связи в виджете чата',
    iconType: 'Тип значка',
    inactive: 'Неактивный',
    inactiveWidgetTip: 'Виджет чата не отображается на сайте и вы не получаете сообщения',
    industry: 'Отрасль',
    idle_guest: 'Ожидание гостя',
    idle_operator_new_chat: 'Ожидание оператора в новом чате',
    idle_operator_open_chat: 'Ожидание оператора в открытом чате',
    information: 'Информация',
    in_progress: 'Выполняется',
    inProcessing: 'В обработке ',
    installed: 'Установлено',
    installedNot: 'Не установлен',
    interfaceLanguage: 'Язык интерфейса',
    invitationsSent: 'Приглашение отправлено ',
    invite: 'Пригласить',
    inviteMember: 'Приглашение сотрудника ',
    joinedChat: 'присоединился к чату',
    languages: 'Языки',
    languagesExplain:
      'Язык системы используется на интерфейсе Дашборда. Операционные языки – те которыми вы можете коммуницировать',
    lastMessage: 'Последнее сообщение',
    lastMonth: 'Предыдущий месяц',
    lastSeen: 'Последнее посещение',
    lastWeek: 'Предыдущая неделя',
    lastYear: 'Прошлый год',
    last30days: 'Последние 30 дней',
    last90days: 'Последние 90 дней',
    link: 'Ссылка',
    liveChatExplain: 'Live chat станет доступным после имплементации кода чат-виджета на вашем вебсайте',
    liveChatHint: 'Сначала имплементируйте код на вашем вебсайте',
    liveChatActivated: 'Live chat активировано',
    live_chat_delayed: 'Правила для LiveChat (с задержкой)',
    liveChatPaused: 'Live chat приостановлено',
    loginAsOwner: 'Зайти как владелец',
    memberInfo: 'Информация о сотруднике',
    memberMessengers: 'Мессенджеры сотрудника',
    members: 'Сотрудники',
    messagingChannels: 'Каналы Связи',
    message: 'Сообщение',
    messageText: 'Текст сообщения',
    messengers: 'Мессенджеры',
    metaHint: 'Сначала нужно подключить',
    metaActivated: 'подключено',
    metaPaused: 'приостановлено ',
    min: 'м',
    mobile: 'Телефон',
    monday: 'Понедельник',
    monthly: 'Ежемесячно',
    // mostPopulartags: 'Самые популярные теги',
    // mostPopularbrowser: 'Самые популярные браузеры',
    // mostPopularbrowser_lang: 'Самые популярные языки браузера',
    // mostPopulardevice: 'Самые популярные девайсы',
    // mostPopulardevice_os: 'Самые популярные операционные системы',
    // mostPopulartimezone: 'Самые популярные часовые пояса',
    mostPopulartags: 'Теги',
    mostPopularbrowser: 'Браузеры',
    mostPopularbrowser_lang: 'Языки браузера',
    mostPopulardevice: 'Девайсы',
    mostPopulardevice_os: 'Операционные системы',
    mostPopulartimezone: 'Часовые пояса',
    name: 'Имя',
    new: 'Новые',
    newBulkMessage: 'Новая рассылка',
    new_chat_working_hours: 'Новый чат (рабочие часы)',
    new_chat_working_hours_tip: 'Укажите задержку, после которой будет отправлено сообщение с уведомлением',
    new_chat_non_working_hours: 'Новый чат (нерабочие часы)',
    new_chat_non_working_hours_tip: 'Укажите, кого нужно сообщить о сообщении клиента в нерабочее время',
    newPassword: 'новый пароль',
    nextPayment: 'Следующий платёж',
    non_working_hours: 'Нерабочие часы',
    no_response: 'Нет ответа в активном чате',
    no_response_tip: 'Укажите задержку, после которой будет отправлено сообщение с уведомлением',
    notConnected: 'Не подключено',
    nothingFound: 'Ничего не найдено',
    notificationsTitle: 'Звуковые оповещения',
    notificationTitle1: 'Сообщать мне, когда у меня появится новый чат',
    notificationExplain1: 'Вы будете получать уведомления о каждом новом или передаваемом чате',
    notificationTitle2: 'Уведомлять меня о новых сообщениях',
    notificationExplain2: 'Вы будете получать уведомления о каждом новом сообщении в открытых чатах',
    notificationsTitle3: 'Push-уведомления',
    of: 'из',
    open: 'Открытые',
    operationalLanguages: 'Рабочие языки',
    operator: 'Оператор',
    operators: 'Уведомить всех операторов',
    options: 'Опции',
    orange: 'Оранжевый',
    orderReference: 'Номер заказа',
    owner: 'Владелец',
    password: 'пароль',
    passwordSecurity: 'Изменение пароля',
    paymentMethod: 'Способ оплаты',
    paymentsRequisites: 'Платёжные реквизиты',
    paymentType: 'Тип платежа',
    pending: 'В ожидании',
    personal: 'Личные',
    personalInformation: 'Личная информация',
    personalTemplate: 'Личный шаблон',
    phone: 'Телефон',
    plan: 'План',
    planEnds: 'Дата окончания',
    preChat: 'Форма контактов',
    pricing: 'Цена',
    privacyPolicy: 'Политика конфиденциальности',
    privacyPolicyAgreeText:
      'Я согласен с тем, что мои персональные данные, предоставленные через чат, будут обрабатываться с целью получения онлайн',
    privacyPolicyLinkText: 'поддержка и консультации.',
    profile: 'Мой профиль',
    profileImageTip: 'Загрузите фото профиля, рекомендуемый размер изображения 512x512px',
    profilePicture: 'Изображение профиля',
    profilePictureTip: 'Загружайте реальное изображение, это повышает уровень доверия ваших клиентов.',
    rated: 'Оценено на',
    rating: 'Рейтинг',
    ratingWrite: 'Как вы оцениваете нашу работу?',
    repeatPassword: 'Повторите пароль',
    resendInvitation: 'Отправить повторно',
    region: 'Регион',
    reset: 'Сброс',
    resetPassword: 'Сброс пароля',
    restoreSubscription: 'Восстановить подписку',
    results: 'Результаты',
    retypePassword: 'введите пароль повторно',
    required: 'Необходимое',
    role: 'Роль',
    saturday: 'Суббота',
    save: 'Сохранить',
    saveUp: 'Сэкономьте до 20%',
    saveSchedule: 'Сохранить расписание',
    schedule: 'Запланировать',
    scheduled: 'Запланировано',
    sendMessagesNow: 'Отправить сейчас',
    search: 'Поиск',
    sec: 'с',
    selectDate: 'Фильтр по дате',
    send: 'Отправить',
    sendInvitation: 'Отправить приглашение',
    settings: 'Настройки',
    setup: 'Установка',
    setupCodeText:
      'Добавьте код на каждую страницу вашего сайта, на которой вы хотите показывать виджет. Код нужно разместить перед тегом </body> вашего кода html.',
    plan_small_team: 'Небольшая команда',
    planCharacteristics: 'Характеристики планов',
    prices: 'Цены',
    priceAnnually: 'Цена за год',
    priceMonthly: 'Цена за месяц',
    second_message: 'Второе сообщение',
    secondMessageTip: 'Сообщение запускается после задержки после первого сообщения.',
    messageTipIdleGuest:
      'Сообщение запускается, если гость не ответил на сообщение оператора в течение указанного времени.',
    secondMessageTipLivechat: 'Второе сообщение будет отправлено сразу после первого сообщения.',
    specified_emails: 'Уведомить конкретные электронные адреса',
    status: 'Статус',
    sum: 'Сумма',
    sunday: 'Воскресенье',
    is_support: 'Приоритетная Онлайн поддержка',
    switchToPlan: 'Переключиться на план',
    system: 'Система',
    system_administrator: 'Администратор Платформы',
    systemLanguage: 'Язык системы',
    tablet: 'Планшет',
    tags: 'Теги ',
    templateCheckboxLabel: 'Общий шаблон (позволяет использовать его всем сотрудникам)',
    templateName: 'Название шаблона',
    template: 'Шаблон',
    templates: 'Шаблоны',
    templatesC: 'Шаблонов',
    thisMonth: 'Этот месяц',
    thisWeek: ' Эта неделя',
    thisYear: 'Этот год',
    thursday: 'Четверг',
    time: 'Время',
    TIN: 'ИНН',
    title: 'Название',
    timeZone: 'Часовой пояс',
    to: 'до',
    toAddNewLine: 'чтобы добавить новую строку',
    today: 'Сегодня',
    total: 'Всего',
    translations: 'Переводы',
    trial: 'Пробный',
    trialPeriod: '14 дней пробный период',
    tuesday: 'Вторник',
    turquoise: 'Бирюзовый',
    type: 'Тип',
    unlimitTemplates: 'Неограниченные шаблоны',
    updatePlan: 'Обновить план',
    upgradeYourPlanToAdd: 'Обновите свой план, чтобы добавить больше',
    upgradeYourPlanToUse: 'Обновите свой план чтобы использовать эту функцию',
    upload: 'Загрузить',
    uploadAndImage: 'Загрузить файл',
    user: 'Пользователь',
    users: 'Пользователей',
    usersIncluded: 'Пользователей включено',
    violet: 'Лиловый',
    visible: 'Видимый',
    website: 'Сайт',
    wednesday: 'Среда',
    widget: 'Виджет',
    widgets: 'Чат Виджетов',
    widgetAppearance: 'Внешний вид виджета',
    widgetButton: 'Кнопка виджета',
    widgetColor: 'Цвет виджета',
    withSchedule: 'Отправить сообщение в данные дату и время',
    writeYourMessageHere: 'Напишите свое сообщение здесь',
    yourCompanyName: 'Название вашей компании',
    yourCurrentPlan: 'Ваш текущий план',
    '@username': '@username',
    ecommerce: 'Електронная коммерция',
    logistics: 'Логистика',
    finance: 'Финанси',
    education: 'Образование',
    travel: 'Путешествия',
    real_estate: 'Недвижимость',
    construction: 'Строительство',
    it: 'Информационные Технологии',
    medical: 'Медицина',
    poweredBy: 'Powered by',
    quickWayToContact: 'Вот быстрый способ связаться с нами!',
    sendMessageToStart: 'Отправьте сообщение, чтобы начать разговор',
    sendUsMessage: 'Отправьте нам сообщение',
    welcome: 'Добро пожаловать',
    writeIn: 'Написать в',
    yesterday: 'Вчера',
  },
  auth: {
    alreadyHaveAccount: 'Уже есть аккаунт?',
    backTo: 'Вернуться к',
    cancel: 'Отменить',
    changePassword: 'Изменить пароль',
    checkYourMailbox: 'Проверьте свой почтовый ящик',
    clientInformation: 'Информация клиента',
    confirmYourEmail: 'Подтвердите ваш адрес электронной почты',
    continueGoogle: 'Продолжить с Google',
    createYourAccount: 'Создать учетную запись',
    dontHaveAccount: 'Нет аккаунта?',
    firstPageText: 'Всё, что нужно для эффективного делового общения',
    firstPageText1:
      'Владельцы бизнеса знают, что потерянные сообщения и исчезнувшие истории переписок могут нанести существенный ущерб и привести к потере клиентов.\nSkibble разработан, чтобы решать эти вопросы.',
    footerText: 'Все права защищены',
    forgotPassword: 'забыли свой пароль?',
    language: 'Язык',
    legalPagesInscription: 'Нажимая «Зарегистрироваться», вы соглашаетесь с тем, что ознакомились с',
    legalPagesInscription1: ', ознакомились и согласны с',
    newPassword: 'Новый пароль',
    or: 'или',
    passRecoverSentText:
      'Мы отправили вам инструкции по восстановлению пароля. Также проверьте папку со спамом, если вы не можете найти наше письмо!',
    passRecoverText: 'Введите свой адрес электронной почты ниже, чтобы получить инструкции по сбросу пароля.',
    passwordRecovery: 'Востановление пароля',
    privacyPolicy: 'Политика конфиденциальности',
    proceed: 'Продолжить',
    recoverPassword: 'Восстановить пароль',
    rememberPassword: 'Запомнить пароль',
    requestText:
      'Чтобы использовать Skibble, сначала подтвердите свой адрес электронной почты. Мы отправили ссылку на адрес',
    requestText1: '. Проверьте папку со спамом, если письмо не приходит.',
    signIn: 'Логин',
    signUp: 'Зарегистрироваться',
    termsAndConditions: 'Правила и условия предоставления услуг',
    toSignIn: 'Авторизация',
    welcome: 'Добро пожаловать!',
    withTermsAndConditions: 'Правилами и условиями предоставления услуг',
  },
  emptyPages: {
    bulkMessagingHeader: 'Массовых рассылок пока нет',
    bulkMessagingText: 'Создайте вашу первую рассылку.',
    contactsHeader: 'Контактов пока нет',
    contactsText: 'Контакт появится здесь после того, как Гость свяжется с вами.',
    chatsHeader: 'Чатов пока нет',
    chatsText: 'Чат появится после того, как Гость свяжется с вами.',
    chatsSelectedHeader: 'Чат не выбран',
    chatsSelectedText: 'Выберите Чат на левой боковой панели.',
    customersHeader: 'Клиентов пока нет',
    customersText: 'Клиент появятся здесь после того, как Владелец зарегистрирует компанию.',
    deleteAccountHeader: 'Удалить аккаунт',
    deleteAccountText:
      'Если ваш аккаунт вам больше не нужен, вы можете удалить его, но учтите, что удаление аккаунта является окончательным. Невозможно восстановить аккаунт.',
    departmentsHeader: 'Отделов пока нет',
    departmentsText: 'Добавьте отдел, чтобы разделить операторов на группы.',
    widgetsHeader: 'Чат-виджетов пока нет',
    widgetsText: 'Добавьте виджет, чтобы ваши Гости могли отправлять и получать сообщения.',
    paymentsHeader: 'Оплат пока нет',
    paymentsText: 'Оплата появится здесь после того, как Гость оплатит тарифный план.',
    templatesHeader: 'Шаблонов еще нет',
    templatesText: 'Создайте шаблон сообщения для быстрого ответа.',
    templateSelectedHeader: 'Шаблон не выбран.',
    templateSelectedText: 'Выберите шаблон в левой боковой панели.',
    invoicesHeader: 'Инвойсов пока нет',
    invoicesText: '',
  },

  menu: {
    accountSettings: 'Настройка аккаунта',
    bulkMessaging: 'Массовая рассылка',
    chats: 'Чаты',
    contacts: 'Контакты',
    customers: 'Клиенты',
    dashboard: 'Информационная панель',
    deleteAccount: 'Удалить аккаунт',
    deleteCompany: 'Удалить компанию',
    departments: 'Отделы',
    general: 'Общие',
    helpCenter: 'Центр помощи',
    hoursOfWork: 'Рабочие часы',
    invoices: 'Инвойсы',
    members: 'Участники',
    myCompany: 'Моя компания',
    notifications: 'Уведомления',
    payments: 'Платежи',
    profile: 'Мой профиль',
    settings: 'Настройки',
    signOut: 'Выход',
    subscription: 'Подписка',
    subscriptionPlans: 'Планы подписок',
    teamChat: 'Командный чат',
    templates: 'Шаблоны',
    widgets: 'Чат Виджеты',
  },
  meta: {
    appDescription:
      'Бизнес-решение, автоматизирующее и улучшающее процесс общения с клиентами. Чат-боты, мессенджеры, живой чат на сайте и многое другое.',
    appTitle: 'Мессенджеры и онлайн-чаты для бизнеса | Skibble',
    bulkMessagingTitle: 'Skibble | Массовая рассылка',
    chatsTitle: 'Skibble | Чаты',
    companyTitle: 'Skibble | Моя компания',
    contactsTitle: 'Skibble | Контакты',
    customersTitle: 'Skibble | Клиенты',
    dashboardTitle: 'Skibble | Дашборд',
    membersTitle: 'Skibble | Сотрудники',
    settingsTitle: 'Skibble | Настройки аккаунта',
    signTitle: 'Skibble объединяет!',
    subscriptionPlansTitle: 'Skibble | Планы подписок',
    templatesTitle: 'Skibble | Темплейты',
  },
  popupAlerts: {
    'Department already exists': 'Отдел уже существует',
    'Department is in use': 'Отдел используется',
    'Wrong email or password.': 'Неверная почта или пароль',
    codeCopied: 'Код скопирован',
    dataSuccessfullySaved: 'Данные успешно сохранены',
    emailConfirmed: 'Почта подтверждена',
    emailCopied: 'Почта скопирована!',
    file: 'Файл',
    'file-too-large': '{{fileName}}\nРазмер превышает 20 МБ',
    image: 'Изображение',
    invitationLinkCopied: 'Ссылка на приглашение скопирована!',
    invitationSent: 'Приглашение отправлено',
    newMessage: 'Новое сообщение',
    newChat: 'Новый чат',
    'Member already exists': 'Сотрудник уже существует',
    "Member is owner of company and can't be deleted":
      'Сотрудник является владельцем компании и не может быть удален',
    'Member not found': 'Такого пользователя не существует',
    noActiveChannelsText: 'У вас нет активных каналов и вы не можете получать новые чаты. ',
    noActiveChannelsLink: 'НАСТРОИТЬ',
    only10Files: 'Не более 10 файлов в сообщении',
    passRecoverySentCustomers: 'Инструкция по изменению пароля отправлена на почту {{email}}',
    somethingWentWrong: 'Что-то пошло не так',
    'The user does not exist.': 'Такого пользователя не существует',
    wrongPass: 'Неверный пароль',
  },
  validation: {
    capital: 'Заглавная буква',
    checkboxError: 'Выберите хотя бы один вариант',
    email: 'Введите действительный адрес электронной почты',
    enterAllChars: 'Введите все символы',
    lowercase: 'Cтрочная буква',
    emailsError: 'Выберите хотя бы один email',
    minLength: '8 символов',
    number: 'Цифра',
    onlyDigits: 'Только цифры',
    passEqualTo: 'Пароли не совпадают',
    password: 'Ваш пароль не соответствует требованиям',
    phone: 'Формат недействителен, пожалуйста, введите номер, начинающийся с «+380»',
    price: 'Формат цены 0.00',
    privacy_policy_required_custom: 'Это обязательное поле',
    privacy_policy_website_custom: 'Проверьте формат ссылки',
    required: 'Это обязательное поле',
    requiredIfFieldsEmpty: 'Заполните одно из этих полей: {{fields}}',
    requiredIfFieldsNotEmpty: 'Это обязательное поле',
    specialChar: 'Специальный символ',
  },
  termsAndConditions: {
    // text: '',
    // link: '',
    // text1: '',
    // header1: '',
    // text2: '',
    // header2: '',
    // text3: '',
  },
  privacyPolicy: {
    // text: '',
    // header1: '',
    // text1: '',
    // header2: '',
    // text2: '',
    // header3: '',
    // text3: '',
    // header4: '',
    // text4: '',
    // link: '',
    // text5: '',
  },
}
