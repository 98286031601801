import React from 'react'
import { withTheme } from 'styled-components'

import { Switch, Tooltip } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectCurrentSubscription } from '../../../redux-saga/selectors'

const IsHideBrandingSwitch = (props) => {
  const planData = useMappedState(selectCurrentSubscription)
  const { limits } = planData || {}

  const isHideBrandingAllowedByBilling = limits?.is_branding // subscription_status === 'active' &&

  const onSwitchChecked = (checked, e) => {
    props.updateCheckbox(checked, e)
  }

  return (
    <Tooltip
      right="-15px"
      text={props.t('upgradeYourPlanToUse')}
      arrowPosition="right"
      whiteSpace="pre-wrap"
      disableTooltip={isHideBrandingAllowedByBilling}
    >
      <Switch
        {...props}
        labelColor={props.theme.color.general.dark}
        onChange={isHideBrandingAllowedByBilling ? onSwitchChecked : undefined}
        checked={props.value}
        disabled={!isHideBrandingAllowedByBilling}
      />
    </Tooltip>
  )
}

export default withTheme(IsHideBrandingSwitch)
