import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import EmptyScreen from '../../components/EmptyScreen'
import { deleteEntity } from '../../redux-saga/actions'
import { selectUserMemberId } from '../../redux-saga/selectors'
import { useMappedState } from '../../hooks/useReactRedux'

const SettingsDeleteAccount = ({ theme }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const id = useMappedState(selectUserMemberId)

  const onDeleteConfirm = () => {
    dispatch(deleteEntity({ id, type: 'members', navigate, location, navigateTo: '/sign-in' }))
  }

  const buttonProps = {
    iconName: 'trashCan',
    confirmButtonProps: {
      fullWidth: true,
      iconLeftProps: {
        size: 16,
        margin: '0 5px 0 0',
        fill: theme.color.general.white,
        name: 'inputError',
      },
      backgroundColor: theme.color.status.error,
      color: theme.color.general.white,
      onDeleteConfirm: onDeleteConfirm,
    },
  }

  return <EmptyScreen type="deleteAccount" icon="deleteAccount" hasButton buttonProps={buttonProps} />
}

export default withTheme(SettingsDeleteAccount)
