import clsx from 'clsx'
import merge from 'deepmerge'
import dot from 'dot-object'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../atomic-design-components'
import { DEFAULT_VALUES_DATA } from '../../config/defaultValues'
import { useMappedState, usePrevious } from '../../hooks/useReactRedux'
import { selectSidebarInitial, selectSidebarMetaData } from '../../redux-saga/selectors'
import validate from '../../utils/formValidation'
import { isObjectEmpty } from '../../utils/isObjectEmpty'
import FormBlock from './FormBlock'
import { StyledForm, StyledFormButtons } from './styled'
import useForm from './useForm'

const Form = ({
  id,
  buttonsAreSticky,
  cancelButtonText,
  children,
  className,
  customOnChange,
  expansionPanelProps,
  fields: customFields,
  formConfig,
  formErrors: customFormErrors,
  formValues: customFormValues,
  FormBlockWrapper,
  getBeforeFormChildren,
  getFormButtons,
  initialValues: initialValuesBeforeCheck,
  initialValuesChanged: initialValuesChangedBeforeCheck,
  inProgress: customInProgress,
  isDraft: customIsDraft,
  isReadOnly,
  isButtonShown,
  isSubmitButtonAlwaysShown,
  isSubmitOnBlur,
  labelKey,
  labelType,
  optionsData,
  onSubmit,
  resetForm,
  saveBtnIconProps,
  saveBtnTextKey,
  serverError,
  setFormErrors: customSetFormErrors,
  setIsDraft: customSetIsDraft,
  submitByEnterPressed,
  type,
  updateInputValue: customUpdateInputValue,
  updateSelectValue: customUpdateSelectValue,
  validationRules: validationRulesInitial = {},
  valuesChanged: customValuesChanged,
  setValuesChanged: customSetValuesChanged,
  // withBlocks,
  withActions,
  withCancelButton = false,
  withCustomValidationRule,
  withSaveInRedux,
}) => {
  const { t } = useTranslation(['all', 'validation'])
  const [touchedFields, setTouchedFields] = useState([])
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const formReduxDataInitial = useMappedState(selectSidebarInitial)
  const { inProgress: sidebarInProgress } = useMappedState(selectSidebarMetaData)

  const inProgress = customInProgress === undefined ? sidebarInProgress : customInProgress

  // const formConfig = customFormConfig || FORM_CONFIGS[type]
  const withTabs = formConfig?.withTabs || []
  const prevId = usePrevious(id)

  const initialValuesAll = merge.all([
    DEFAULT_VALUES_DATA[type] || {},
    initialValuesBeforeCheck || {},
    initialValuesChangedBeforeCheck ? dot.object(initialValuesChangedBeforeCheck) : {},
  ])

  const getFields = () => {
    if (customFields) {
      return customFields
    }
    return formConfig?.fields
  }

  const fields = getFields()

  const validationRules = Object.keys(validationRulesInitial).reduce((acc, curr) => {
    let customRules
    if (
      Object.values(fields)
        .flat()
        .some((field) => {
          const isFieldPresent =
            (field.key === curr || (withTabs.includes(field.key) && curr.startsWith(field.key))) &&
            (!field.getIsHidden || !field.getIsHidden(formValues, optionsData))

          if (isFieldPresent && field.validationRules) {
            customRules = { ...field.validationRules }
          }
          return isFieldPresent
        })
    ) {
      return { ...acc, [curr]: validationRulesInitial[curr], ...(customRules || {}) }
    }
    return acc
  }, {})

  const {
    formValues,
    formErrors: errors,
    setFormErrors: setErrors,
    isDraft: formIsDraft,
    setIsDraft: setFormIsDraft,
    updateInputValue,
    updateSelectValue,
    updateCheckboxValue,
    valuesChanged,
    setValuesChanged,
    validateField,
  } = useForm({
    customFormValues,
    customOnChange,
    initialValuesAll,
    initialValuesChanged: initialValuesChangedBeforeCheck,
    isSubmitPressed,
    validationRules: validationRulesInitial,
    withTabs,
    optionsData,
    setTouchedFields,
    withCustomValidationRule,
    withSaveInRedux,
  })

  // console.log(formValues, fields)

  const formValuesChanged = customValuesChanged || valuesChanged
  const setFormValuesChanged = customSetValuesChanged || setValuesChanged
  const formErrors = customFormErrors || errors
  const setFormErrors = customSetFormErrors || setErrors
  const isDraft = customIsDraft || formIsDraft
  const setIsDraft = customSetIsDraft || setFormIsDraft
  const updateInput = customUpdateInputValue || updateInputValue
  const updateSelect = customUpdateSelectValue || updateSelectValue

  const formReset = useCallback(
    (event) => {
      if (event !== undefined) {
        event.preventDefault()
      }
      setFormValuesChanged(initialValuesChangedBeforeCheck ? dot.dot(initialValuesChangedBeforeCheck) : {})
      setIsDraft(false)
      setFormErrors({})
      if (resetForm) {
        resetForm()
      }
    },
    [setFormErrors, setIsDraft, setFormValuesChanged]
  )

  useEffect(() => {
    if ((inProgress === false && !serverError) || (prevId !== id && typeof prevId !== 'undefined')) {
      formReset()
    }
  }, [inProgress, serverError, formReset, id, prevId])

  const formSubmit = (e, customValuesForOnBlur) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }

    //(isSubmitOnBlur && isObjectEmpty(customValues||formValuesChanged))
    if (isReadOnly || (isSubmitOnBlur && isObjectEmpty(customValuesForOnBlur || formValuesChanged))) {
      return
    }

    if (!isSubmitPressed) {
      setIsSubmitPressed(true)
    }

    // const addedValues = addLastValuesToForm(type, formValuesChanged, formValues, optionsData)
    // const allValuesChanged = { ...formValuesChanged, ...addedValues }
    // const formValuesWithAdded = merge(formValues, dot.object(addedValues))

    const submitErrors = validate(validationRules)(
      customValuesForOnBlur ? { ...initialValuesAll, ...customValuesForOnBlur } : formValues,
      {
        values: customValuesForOnBlur ? { ...initialValuesAll, ...customValuesForOnBlur } : formValues,
      }
    )
    setFormErrors(submitErrors)
    console.log(submitErrors)

    if (isObjectEmpty(submitErrors)) {
      if (onSubmit) {
        onSubmit(customValuesForOnBlur || formValuesChanged, e)
      }
    }
  }

  const getFormBlock = (fields, blockKey, isSecondaryBlock) => {
    return (
      <FormBlock
        blockKey={blockKey}
        expansionPanelProps={expansionPanelProps}
        fields={customFields || fields || []}
        formErrors={formErrors}
        formSubmit={isSubmitOnBlur && formSubmit}
        formValues={formValues}
        formValuesChanged={formValuesChanged}
        FormBlockWrapper={FormBlockWrapper}
        initialValues={initialValuesAll}
        isSubmitOnBlur={isSubmitOnBlur}
        isReadOnly={isReadOnly}
        isSecondaryBlock={isSecondaryBlock}
        key={blockKey}
        labelKey={labelKey}
        labelType={labelType}
        optionsData={optionsData}
        setTouchedFields={setTouchedFields}
        submitByEnterPressed={submitByEnterPressed}
        touchedFields={touchedFields}
        type={type}
        updateInput={updateInput}
        updateSelect={updateSelect}
        updateCheckbox={updateCheckboxValue}
        validateField={validateField}
        validationRules={validationRules}
      />
    )
  }

  // VR: form renders multiple times
  return (
    <StyledForm className={clsx(className, withActions && isDraft && 'buttonsAreShown')}>
      <form onSubmit={formSubmit}>
        {getBeforeFormChildren && getBeforeFormChildren(formValues)}
        {fields &&
          (Array.isArray(fields)
            ? getFormBlock(fields)
            : Object.keys(fields).map((blockKey) => getFormBlock(fields[blockKey], blockKey)))}
        {children}
        {((!isReadOnly && !isSubmitOnBlur) || isButtonShown) && (
          <StyledFormButtons className={buttonsAreSticky ? 'sticky submitFormButtons' : 'submitFormButtons'}>
            {withActions && (isSubmitButtonAlwaysShown || isDraft) && (
              <div className={buttonsAreSticky ? 'stickyButtonsWrapper' : ''}>
                {withCancelButton && (
                  <Button fullWidth variant="general" onClick={formReset} className="cancelButton">
                    {t(cancelButtonText || 'Cancel')}
                  </Button>
                )}

                <Button
                  fullWidth
                  type="submit"
                  disabled={isSubmitButtonAlwaysShown && (!isDraft || inProgress)}
                  iconLeftProps={{ size: 16, ...saveBtnIconProps }}
                >
                  {t(`${saveBtnTextKey || t('save')}`)}
                </Button>
              </div>
            )}
            {!withActions && getFormButtons && getFormButtons(isDraft, formValues)}
          </StyledFormButtons>
        )}
      </form>
    </StyledForm>
  )
}

export default Form
