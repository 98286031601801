import React from 'react'

export const print = ({ width, height, fill, stroke, opacity, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M11.9989 12.5731H14.2846C14.5877 12.5731 14.8784 12.4527 15.0927 12.2384C15.307 12.024 15.4275 11.7334 15.4275 11.4302V6.28739C15.4275 5.98428 15.307 5.69359 15.0927 5.47927C14.8784 5.26494 14.5877 5.14453 14.2846 5.14453H1.71317C1.41007 5.14453 1.11937 5.26494 0.905048 5.47927C0.69072 5.69359 0.570313 5.98428 0.570312 6.28739V11.4302C0.570313 11.7334 0.69072 12.024 0.905048 12.2384C1.11937 12.4527 1.41007 12.5731 1.71317 12.5731H3.99888"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10.8574V14.8574C4 15.009 4.0602 15.1543 4.16737 15.2615C4.27453 15.3686 4.41988 15.4289 4.57143 15.4289H11.4286C11.5801 15.4289 11.7255 15.3686 11.8326 15.2615C11.9398 15.1543 12 15.009 12 14.8574V10.8574H4Z"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5.14369V1.14369C12 0.992142 11.9398 0.846797 11.8326 0.739633C11.7255 0.63247 11.5801 0.572266 11.4286 0.572266H4.57143C4.41988 0.572266 4.27453 0.63247 4.16737 0.739633C4.0602 0.846797 4 0.992142 4 1.14369V5.14369"
      stroke={fill || color.general.white}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5686 8H11.4258"
      stroke={fill || color.general.white}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
