import React from 'react'

import clsx from 'clsx'
import ReactTexty from 'react-texty'
import { FlexRow, Icon, Tooltip, Typography } from '../../../atomic-design-components'
import { StyledCardContent, StyledOptionContainer } from './styled'
// you should use "key" keys from columns config to get data here (not dataKeys): data[key]
const SubscriptionPlansTableCard = ({ isMobile, t, theme, className, data, initialData }) => {
  return (
    <StyledCardContent className={className}>
      <FlexRow className="cardTableItemRow" justifyContent="space-between">
        <Typography variant={isMobile ? 'h4' : 'h3'} text={data.planTitle} as={ReactTexty} />
        <Typography variant={isMobile ? 'h4' : 'h3'} text={data.pricing} as={ReactTexty} />
      </FlexRow>

      <StyledOptionContainer>
        <FlexRow
          alignItems="start"
          flexWrap="wrap"
          flexDirection="column"
          gap="10px"
          className="optionColumn"
        >
          {Object.keys(initialData.features).map((item, i) => {
            const isOptionActive = initialData.features[item]
            const getText = () => {
              if (typeof initialData.features[item] === 'number') {
                if (item === 'templates') {
                  if (initialData.features[item] === -1) {
                    return t('unlimitTemplates')
                  } else {
                    return `${t('templatesC')}: ${initialData.features[item]}`
                  }
                } else {
                  return `${t(item)}: ${initialData.features[item]}`
                }
              } else {
                return t(item)
              }
            }
            return (
              <FlexRow gap="10px" key={i} className={clsx(item, 'flexRow')}>
                <Icon
                  name={isOptionActive ? 'statusActive' : 'plusCircle'}
                  size={18}
                  stroke={isOptionActive ? theme.color.general.white : ''}
                  fill={isOptionActive ? theme.color.status.success : theme.color.general.white}
                  opacity={isOptionActive ? '1' : ''}
                  fillColor={!isOptionActive ? theme.color.status.error : ''}
                  className={!isOptionActive ? 'disabledIcon' : ''}
                />
                <Typography variant="body3" text={getText()} />
              </FlexRow>
            )
          })}
          <Tooltip text={t('customers')} arrowPosition="left" className="tooltipWrapper">
            <FlexRow gap="10px" key="customers" className={clsx('customers')}>
              <Icon name="customers" size={18} fill={theme.color.general.black} />
              <Typography variant="body3" text={data.customers} as={ReactTexty} />
            </FlexRow>
          </Tooltip>
        </FlexRow>
      </StyledOptionContainer>

      <FlexRow className="cardTableItemRow" justifyContent="space-between"></FlexRow>
    </StyledCardContent>
  )
}

export default SubscriptionPlansTableCard
