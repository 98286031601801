import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ReactHelmet from '../../wrappers/ReactHelmet'

import GridLayout from '../Layout/GridLayout'
import SettingsLeftPanel from './panels/SettingsLeftPanel'
import { useWidth } from '../../hooks/useWidth'
import theme from '../../theme'
import { Button } from '../../atomic-design-components'

const Settings = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.lg
  const md = theme.breakpoints.md

  useEffect(() => {
    if (width > md && pathname === '/settings' || pathname === '/settings/') {
      navigate('/settings/my-profile', { replace: true })
    }
  }, [pathname])

  const onArrowClick = () => {
    navigate({ pathname: '../settings' })
  }

  return (
    <>
      <ReactHelmet title="settingsTitle" />
      <GridLayout
        LeftPanelComponent={<SettingsLeftPanel isExtended={true} isMobile={isMobile} theme={theme} />}
        type="accountSettings"
        isLeftPanelShown={true}
        className="settingsPage"
      >
        {width < md && (
          <Button
            variant="general"
            iconLeftProps={{
              name: 'paginationArrow',
              fill: theme.color.general.black,
              width: 20,
              height: 20,
              className: 'withoutHover',
            }}
            className="navbarButton leftMenuButton"
            onClick={onArrowClick}
            margin="20px 30px 0"
          />
        )}
        {children || <Outlet />}
      </GridLayout>
    </>
  )
}

export default Settings
