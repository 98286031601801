import React from 'react'

export const user = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <path
      d="M7.99972 9.143C9.57768 9.143 10.8569 7.86381 10.8569 6.28585C10.8569 4.7079 9.57768 3.42871 7.99972 3.42871C6.42176 3.42871 5.14258 4.7079 5.14258 6.28585C5.14258 7.86381 6.42176 9.143 7.99972 9.143Z"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7825 12.2859C10.7382 11.3635 9.39295 10.8545 7.99965 10.8545C6.60636 10.8545 5.26107 11.3635 4.2168 12.2859"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4286 12V14.2857C15.4286 14.5888 15.3082 14.8795 15.0938 15.0938C14.8795 15.3082 14.5888 15.4286 14.2857 15.4286H12"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 0.571289H14.2857C14.5888 0.571289 14.8795 0.691697 15.0938 0.906024C15.3082 1.12035 15.4286 1.41104 15.4286 1.71415V3.99986"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.571289 3.99986V1.71415C0.571289 1.41104 0.691697 1.12035 0.906024 0.906024C1.12035 0.691697 1.41104 0.571289 1.71415 0.571289H3.99986"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.99986 15.4286H1.71415C1.41104 15.4286 1.12035 15.3082 0.906024 15.0938C0.691697 14.8795 0.571289 14.5888 0.571289 14.2857V12"
      stroke={fill || color.general.black}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
