import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import {
  setEventToChatHistory,
  inputFilesClear,
  setChatMessage,
  setEventJustSent,
} from '../../../redux-saga/actions'
import {
  selectChatMessage,
  selectCurrentChatChannel,
  selectCurrentChatId,
  selectCurrentChatStatus,
  selectFilesUploaded,
  selectIsContactOnline,
  selectEventJustSent,
  selectSetEventToHistoryInProgress,
  selectUserNameWithPic,
} from '../../../redux-saga/selectors'
import UploadImages from '../../../components/UploadImages'
import { useMappedState } from '../../../hooks/useReactRedux'
import useAutosizeTextArea from '../../../hooks/useAutosizeTextArea'
import UploadedItemsList from '../../../components/UploadImages/UploadedItemsList'
import { StyledInput } from './styled'
import { useSendEventWebsocket } from '../useChatsWebsockets'
import TextareaActionButtons from './TextareaActionButtons'

const Textarea = ({ isReadOnly, setEventJustReceived, t, theme }) => {
  const dispatch = useDispatch()
  const chatId = useMappedState(selectCurrentChatId)

  const message = useMappedState(selectChatMessage)
  const isSetMessageToHistoryInProgress = useMappedState(selectSetEventToHistoryInProgress)

  const eventJustSent = useMappedState(selectEventJustSent)

  const allChatsFiles = useMappedState(selectFilesUploaded)
  const files = allChatsFiles?.[chatId] || []

  const [openedEmoji, setOpenedEmoji] = useState(false)
  const [extraPadding, setExtraPadding] = useState(0)
  const [uploadClicked, setUploadClicked] = useState(false)

  const currentChatStatus = useMappedState(selectCurrentChatStatus)
  const active = currentChatStatus === 'open' || isReadOnly

  const isContactOnline = useMappedState(selectIsContactOnline())
  const currentChatChannel = useMappedState(selectCurrentChatChannel)

  const { name, pic } = useMappedState(selectUserNameWithPic)

  const textAreaRef = useRef(null)
  useAutosizeTextArea(textAreaRef.current, message, 126)

  useEffect(() => {
    dispatch(setChatMessage(''))
    dispatch(inputFilesClear())
  }, [chatId])

  const constructMessageData = () => {
    const mainData = { chat_id: +chatId, type: 'operator_message', data: {} }

    if (message) {
      mainData.data.text = message.trim()
    }

    if (files.length) {
      mainData.data.files = files
    }

    return mainData
  }

  const onMessageSentSuccess = (eventType, callback) => {
    if (eventType === 'operator_message' && (message || files.length)) {
      dispatch(setChatMessage(''))
      dispatch(inputFilesClear())

      dispatch(
        setEventToChatHistory('operator_message', {
          ...constructMessageData(),
          operator_name: name,
          operator_pic: pic,
        })
      )
      callback()
      dispatch(setEventJustSent('operator_message'))
    }
  }

  const onSend = (callback) => {
    if (message || files.length) {
      const data = constructMessageData()
      // TODO: fix for sending messages after error occurred (same is ok in widget)
      if (message === 'error') {
        sendMessage(data)
      } else {
        sendEventToServer(data)
      }

      callback()
    }
  }

  const { sendEventToServer, sendMessage, onSendEvent, eventSentStatus } = useSendEventWebsocket(
    onSend,
    onMessageSentSuccess,
    setEventJustReceived
  )

  const inProgress =
    eventSentStatus === 'inProgress' || isSetMessageToHistoryInProgress || eventSentStatus === 'error'

  useEffect(() => {
    if (textAreaRef.current && !isReadOnly) {
      textAreaRef.current.focus()
    }
  }, [chatId, active, eventJustSent, isReadOnly])

  const handleChange = (e) => {
    // if (eventSentStatus === 'error') {
    //   setEventSentStatus('')
    // }
    dispatch(setChatMessage(e.currentTarget.value))
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      // console.log('test send')
      onSendEvent('operator_message')()
    }
  }

  const filesHeight = useRef(null)

  useEffect(() => {
    if (filesHeight.current) {
      setExtraPadding(filesHeight.current.clientHeight)
    }
  }, [filesHeight, extraPadding, uploadClicked, onSendEvent])

  if (!chatId && !isReadOnly) {
    return null
  }

  const onEmojiClick = () => setOpenedEmoji((prev) => !prev)
  const onUploadClick = () => setUploadClicked((prev) => !prev)

  const onEmojiSelect = (emoji) => {
    const cursorPlace = textAreaRef.current.selectionStart
    const messageStart = message.slice(0, cursorPlace)
    const messageEnd = message.slice(cursorPlace)
    const text = messageStart + emoji.native + messageEnd
    dispatch(setChatMessage(text))

    setOpenedEmoji((prev) => !prev)
    textAreaRef.current.focus()
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <StyledInput
        id={`chatTextarea-${chatId}`}
        // type="richText"
        error={eventSentStatus === 'error' && !!(message || files.length)}
        rows={1}
        multiline={active}
        fullWidth
        placeholder={
          currentChatChannel !== 'live_chat' ||
          currentChatStatus === 'closed' ||
          isContactOnline ||
          isReadOnly
            ? t('enterYourMessage')
            : t('guestOfflinePlaceholder')
        }
        disabled={!active || inProgress}
        insideButtons={
          active && (
            <TextareaActionButtons
              isReadOnly={isReadOnly}
              theme={theme}
              t={t}
              inProgress={inProgress}
              chatId={chatId}
              onEmojiClick={onEmojiClick}
              onUploadClick={onUploadClick}
              onSend={onSendEvent('operator_message')}
            />
          )
        }
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={message}
        inputRef={textAreaRef}
        extraPadding={extraPadding}
        active={active}
      >
        <UploadImages
          chatID={chatId}
          maxFiles={10}
          maxSize={20971520}
          onHide={onUploadClick}
          noClick={true}
          hiddenZone
          dropHeight={extraPadding}
          active={active}
        />
      </StyledInput>
      {active && (
        <UploadedItemsList chatID={chatId} isSimpleButton uploadRef={filesHeight} onHide={onUploadClick} />
      )}

      {openedEmoji && (
        <div className="emojiPicker">
          <Picker data={data} onEmojiSelect={onEmojiSelect} onClickOutside={onEmojiClick} />
        </div>
      )}
    </div>
  )
}

export default Textarea
