import React from 'react'
import ReactTexty from 'react-texty'

import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { useMappedState } from '../../../hooks/useReactRedux'
import { selectSidebarDetails } from '../../../redux-saga/selectors'
import { Icon, FlexRow } from '../../../atomic-design-components'
import StripedRowsBlock from '../../../components/StripedRowsBlock'
import { StyledDetails } from '../styled'
import { transformDate } from '../../../utils/dates'
import { CURRENCY, PAYMENTS_STATUSES_ICONS } from '../../../constants'

const PaymentsDetails = ({ theme }) => {
  const { t } = useTranslation('all')
  const formDataInitial = useMappedState(selectSidebarDetails)

  const currData = {
    status: (
      <FlexRow gap="5px">
        <Icon name={PAYMENTS_STATUSES_ICONS[formDataInitial?.status]}></Icon>
        {t(formDataInitial?.status)}
      </FlexRow>
    ),
    createdAt: transformDate(formDataInitial?.created_at, 'DD/MM/YYYY HH:mm'),
    orderReference: <ReactTexty>{formDataInitial?.order_reference}</ReactTexty>,
    amount: `${formDataInitial?.amount} ${CURRENCY[formDataInitial?.currency]}`,
    type: t(formDataInitial?.type),
    bankName: formDataInitial?.details?.issuerBankName,
    bankCountry: formDataInitial?.details?.issuerBankCountry,
  }

  return (
    <StyledDetails>
      <StripedRowsBlock data={currData} />
    </StyledDetails>
  )
}

export default withTheme(PaymentsDetails)
