import React from 'react'

export const passwordReset = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_13412_12723)">
      <path
        d="M0.571533 8.00011C0.571533 9.46934 1.00721 10.9056 1.82347 12.1272C2.63974 13.3488 3.79992 14.301 5.15731 14.8632C6.51471 15.4255 8.00835 15.5726 9.44935 15.2859C10.8903 14.9993 12.214 14.2918 13.2529 13.2529C14.2918 12.214 14.9993 10.8904 15.2859 9.44935C15.5726 8.00835 15.4255 6.51471 14.8632 5.15732C14.301 3.79992 13.3488 2.63974 12.1272 1.82348C10.9056 1.00722 9.46934 0.571538 8.0001 0.571538C5.87207 0.569183 3.82518 1.38794 2.28582 2.85725"
        stroke={fill || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.85732 0.571533L2.28589 2.85725L4.5716 3.42868"
        stroke={fill || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2857 6.94287H5.71425C5.39866 6.94287 5.14282 7.19871 5.14282 7.5143V10.9429C5.14282 11.2585 5.39866 11.5143 5.71425 11.5143H10.2857C10.6013 11.5143 10.8571 11.2585 10.8571 10.9429V7.5143C10.8571 7.19871 10.6013 6.94287 10.2857 6.94287Z"
        stroke={fill || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.94308V6.08594C10 5.5555 9.78929 5.0468 9.41421 4.67172C9.03914 4.29665 8.53043 4.08594 8 4.08594C7.46957 4.08594 6.96086 4.29665 6.58579 4.67172C6.21071 5.0468 6 5.5555 6 6.08594V6.94308"
        stroke={fill || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13412_12723">
        <rect width={width || 16} height={height || 16} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
