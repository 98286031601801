import styled from 'styled-components'
import { Userpic, Icon } from '../../atomic-design-components'

export const StyledPic = styled(Userpic)`
  position: absolute;
  left: ${({ i }) => i * 19}px;
`

export const StyledIcon = styled(Icon)`
  border-radius: 50%;
  position: absolute;
  left: ${({ i }) => i * 19}px;
`

export const StyledUserpicsMore = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.secondary.lightest};
  color: ${({ theme }) => theme.color.secondary.darker};
  position: absolute;
  left: ${({ i }) => i * 19}px;
  text-align: center;
  padding: 6px;

  .activeRow & {
    background-color: ${({ theme }) => theme.color.secondary.main};
    color: ${({ theme }) => theme.color.general.white};
  }
`
