import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'
import ReactHelmet from '../../wrappers/ReactHelmet'

import GridLayout from '../Layout/GridLayout'
import TableBlock from '../../components/TableBlock'
import ContactsTableCard from '../Contacts/components/ContactsTableCard'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectMainData } from '../../redux-saga/selectors'

const Contacts = () => {
  const { t } = useTranslation('all')
  const params = useParams()
  const urlId = params?.rightPanelId

  const data = useMappedState(selectMainData('contacts')) || []

  return (
    <>
      <ReactHelmet title="contactsTitle" />

      <GridLayout RightPanelComponent={urlId && <Outlet />}>
        <TableBlock
          tableData={data}
          navbarProps={{}}
          TableCardContent={ContactsTableCard}
          activeRowId={urlId}
          type="contacts"
          // rightPanelIsOpened={rightPanelIsOpened}
          // leftPanelIsOpened={leftPanelIsOpened}
          // setLeftPanelOpened={setLeftPanelOpened}
          // user={user}
        />
      </GridLayout>
    </>
  )
}

export default Contacts
