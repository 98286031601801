import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../atomic-design-components'
import { COLLAPSED_FORM_BLOCKS } from '../../config'
import { FORM_CONFIGS } from '../../config/forms'
import FormBlockTabs from './FormBlockTabs'
import FormFields from './FormFields'
import { StyledExpansionPanel } from './styled'

const FormBlock = ({
  blockKey,
  expansionPanelProps,
  fields,
  formErrors,
  formSubmit,
  formValues,
  formValuesChanged,
  FormBlockWrapper,
  initialValues,
  isReadOnly,
  isSecondaryBlock,
  isSubmitOnBlur,
  labelKey,
  labelType,
  optionsData,
  touchedFields,
  type,
  setTouchedFields,
  submitByEnterPressed,
  updateCheckbox,
  updateInput,
  updateSelect,
  validateField,
  validationRules,
}) => {
  const { t, i18n } = useTranslation(['all', 'menu', 'validation'])

  // const InfoPanelConfig = INFO_PANEL_CONFIG[type]?.[blockKey]
  const withInfo = false //!!InfoPanelConfig?.props

  const withBlockTabs = ['translations', 'prices'].includes(blockKey) && FORM_CONFIGS[type]?.withTabs?.length
  const [infoOpened, setInfoOpened] = useState(false) //InfoPanelConfig?.initiallyOpened

  const onToggleInfo = (e) => {
    e.stopPropagation()
    setInfoOpened((prev) => !prev)
  }

  const isFormWithOneBlockOnly = !blockKey

  const getPanelHeader = (blockKey, withInfo) => {
    if (blockKey === 'noTitle') {
      return null
    }

    const blockNameTranslation =
      blockKey && (i18n.exists(blockKey, { ns: 'all' }) ? t(blockKey) : t(`menu:${blockKey}`))

    return (
      // <div>
      <Typography variant="h4">{blockNameTranslation}</Typography>
      //   {withInfo && (
      //     <Icon
      //       name="info"
      //       className="infoIcon"
      //       wrapperWidth={16}
      //       wrapperHeight={16}
      //       borderRadius="50%"
      //       onClick={onToggleInfo}
      //     />
      //   )}
      // </div>
    )
  }

  const formFieldsProps = {
    formErrors,
    formSubmit,
    formValues,
    initialValues,
    isReadOnly,
    isSubmitOnBlur,
    labelKey,
    labelType,
    optionsData,
    submitByEnterPressed,
    type,
    updateCheckbox,
    updateInput,
    updateSelect,
    validateField,
    validationRules,
  }

  const compoundTypeKey = `${type}.${blockKey}`

  const getIsBlockShown = () => {
    let isShown = true

    if (isShown) {
      if (
        fields &&
        Array.isArray(fields) &&
        fields.some((field) => !!field.getIsHidden) &&
        !fields.filter((field) => !field.getIsHidden || !field.getIsHidden(formValues, optionsData)).length
      ) {
        isShown = false
      }
    }

    return isShown
  }

  const isBlockShown = getIsBlockShown()

  const panelProps = {
    isHidden: isFormWithOneBlockOnly || blockKey === 'noTitle',
    key: blockKey,
    header: getPanelHeader(blockKey, withInfo),
    initialOpened: !COLLAPSED_FORM_BLOCKS.includes(blockKey),
    id: blockKey,
    className: clsx(
      'formBlock',
      blockKey,
      !isBlockShown && 'hidden',
      isFormWithOneBlockOnly && 'oneBlockForm'
    ),
    ...expansionPanelProps,
  }

  const getFields = () => {
    if (!isBlockShown) {
      return null
    }

    return (
      <>
        {/*{withInfo && infoOpened && <InfoPanel {...InfoPanelConfig?.props} />}*/}
        {Array.isArray(fields) ? (
          <FormFields {...formFieldsProps} fields={fields} />
        ) : (
          Object.values(fields).map((values, i) => {
            const key = Object.keys(fields)[i]

            return Array.isArray(values) ? (
              <div key={i}>
                <Typography type="h4" text={t(key)} margin="0 0 10px 0" />
                <FormFields {...formFieldsProps} fields={values} />
              </div>
            ) : null
          })
        )}
      </>
    )
  }

  return FormBlockWrapper ? (
    <StyledExpansionPanel {...panelProps}>
      <FormBlockWrapper
        isBlockShown={isBlockShown}
        isReadOnly={isReadOnly}
        blockKey={blockKey}
        compoundTypeKey={compoundTypeKey}
        id={formValues.id}
        fields={fields}
        formValues={formValues}
        formValuesChanged={formValuesChanged}
        getPanelHeader={getPanelHeader}
        optionsData={optionsData}
        setTouchedFields={setTouchedFields}
        touchedFields={touchedFields}
        withInfo={!isSecondaryBlock}
        updateCheckbox={updateCheckbox}
        updateInput={updateInput}
        updateSelect={updateSelect}
        formErrors={formErrors}
      >
        {withBlockTabs ? (
          <FormBlockTabs formFieldsProps={formFieldsProps} fields={fields} type={type} blockKey={blockKey} />
        ) : (
          getFields()
        )}
      </FormBlockWrapper>
    </StyledExpansionPanel>
  ) : (
    <StyledExpansionPanel {...panelProps}>{getFields()}</StyledExpansionPanel>
  )
}

export default FormBlock
