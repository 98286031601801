import styled from 'styled-components'

import { TextPanel } from '../../atomic-design-components'

export const StyledTableCard = styled(TextPanel)`
  height: ${({ rowHeight }) => `${rowHeight}px` || '100%'};
  padding: 12px 14px;
  margin: ${({ margin }) => `${margin / 2}px 0` || '5px'};
  display: block;

  &:first-child {
    margin-top: 0;
  }

  &.clicked {
    border: 2px solid ${({ theme: { color } }) => color.secondary.lighter};
  }

  .inactive {
    color: ${({ theme: { color } }) => color.general.light};

    .icon:not(~ .statusIcon) {
      path {
        fill: ${({ theme: { color } }) => color.general.lightest};
        stroke: ${({ theme: { color } }) => color.general.lighter};
      }
      circle {
        fill: ${({ theme: { color } }) => color.status.inactive};
      }
    }
    .userpic {
      opacity: 0.2;
    }
    .typography {
      color: ${({ theme: { color } }) => color.general.light};
    }
    .tag {
      background-color: ${({ theme: { color } }) => color.general.lighter};
      color: ${({ theme: { color } }) => color.general.darker};
    }
  }

  .cardTableItemRow {
    padding: 10px 0;
    justify-content: space-between;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme: { color } }) => color.general.lighter};
    }
  }
`
