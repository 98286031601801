import React from 'react'

export const helpCenter = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M10.0002 18.9145C14.9234 18.9145 18.9145 14.9234 18.9145 10.0002C18.9145 5.077 14.9234 1.08594 10.0002 1.08594C5.077 1.08594 1.08594 5.077 1.08594 10.0002C1.08594 14.9234 5.077 18.9145 10.0002 18.9145Z"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.94336 7.94386C7.94336 7.537 8.06401 7.13927 8.29005 6.80098C8.51609 6.46268 8.83737 6.19901 9.21327 6.04331C9.58916 5.88761 10.0028 5.84687 10.4018 5.92625C10.8009 6.00562 11.1674 6.20155 11.4551 6.48924C11.7428 6.77694 11.9387 7.14349 12.0181 7.54253C12.0975 7.94158 12.0568 8.3552 11.9011 8.7311C11.7454 9.10699 11.4817 9.42827 11.1434 9.65431C10.8051 9.88036 10.4074 10.001 10.0005 10.001V11.3724"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0003 13.4297C9.79682 13.4297 9.59796 13.49 9.42881 13.603C9.25966 13.7161 9.12783 13.8767 9.04998 14.0646C8.97213 14.2526 8.95176 14.4594 8.99144 14.6589C9.03113 14.8584 9.12909 15.0417 9.27294 15.1856C9.41679 15.3294 9.60006 15.4274 9.79959 15.4671C9.99911 15.5068 10.2059 15.4864 10.3939 15.4085C10.5818 15.3307 10.7425 15.1989 10.8555 15.0297C10.9685 14.8606 11.0288 14.6617 11.0288 14.4583C11.0253 14.1866 10.9158 13.927 10.7236 13.7349C10.5315 13.5427 10.2719 13.4332 10.0003 13.4297Z"
      fill={fill || color.general.white}
    />
  </svg>
)
