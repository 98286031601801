import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectSidebarDetails } from '../../../redux-saga/selectors'
import { useMappedState } from '../../../hooks/useReactRedux'
import { upsertSidebarItem } from '../../../redux-saga/actions'
import SwitchableCard from './SwitchableCard'

const BOTS_CONFIG = {
  live_chat: {
    name: 'live chat',
    iconName: 'live_chat',
    middleRow: {
      text: 'liveChatExplain',
    },
    hint: 'liveChatHint',
    activeText: 'liveChatActivated',
    pausedText: 'liveChatPaused',
  },
  telegram: {
    name: 'telegram',
    iconName: 'telegram',
    middleRow: {
      name: 'bot token',
      placeholder: 'botPlaceholder',
    },
    hint: 'botHint',
    activeText: 'botActivated',
    pausedText: 'botPaused',
  },
  viber: {
    name: 'viber',
    iconName: 'viber',
    middleRow: {
      name: 'bot token',
      placeholder: 'botPlaceholder',
    },
    hint: 'botHint',
    activeText: 'botActivated',
    pausedText: 'botPaused',
  },
  facebook: {
    name: 'facebookMessenger',
    iconName: 'facebook',
    hint: 'metaHint',
    activeText: 'metaActivated',
    pausedText: 'metaPaused',
  },
  instagram: {
    name: 'instagram',
    iconName: 'instagram',
    hint: 'metaHint',
    activeText: 'metaActivated',
    pausedText: 'metaPaused',
  },
}

const getConnectedText = (channel, item, isActive, t) => {
  const text = isActive ? BOTS_CONFIG[channel].activeText : BOTS_CONFIG[channel].pausedText

  if (item?.channels[channel]?.page_name) {
    return { bot: item?.channels[channel]?.page_name, text: t(text) }
  }
  if (item?.channels[channel]?.bot_name) {
    return { bot: `@${item?.channels[channel]?.bot_name}`, text: t(text) }
  }
  if (channel === 'live_chat') {
    return { text: t(text) }
  }
  return ''
}

const SwitchableCards = ({ formProps, theme }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('all')

  const initialData = useMappedState(selectSidebarDetails)

  return Object.keys(BOTS_CONFIG).map((bot) => {
    const currBot = BOTS_CONFIG[bot]
    const isWidgetActive = initialData?.status === 'active'
    const isConnected =
      (bot === 'live_chat' && initialData?.license_id && initialData?.install_status === 'installed') ||
      !!initialData?.channels?.[bot]?.token ||
      !!initialData?.channels?.[bot]?.page_name ||
      false
    const isActive = isWidgetActive && initialData?.channels[bot]?.status === 'active'

    const inputValue = initialData?.channels[bot]?.token || ''

    const disableTooltip = isActive || (bot === 'live_chat' && initialData?.install_status === 'installed')

    const onChannelActivate = (isChecked) => {
      const status = isChecked ? 'active' : 'inactive'
      const updatedData = {
        ...initialData,
        channels: { ...initialData.channels, [bot]: { ...initialData.channels[bot], status: status } },
      }

      dispatch(
        upsertSidebarItem({
          id: initialData?.id,
          type: 'widgets',
          requestBody: updatedData,
          navigate,
        })
      )
    }

    const onTokenAdd = useCallback(
      (e) => {
        const value = typeof e === 'string' ? e : e.target.value
        if (value) {
          const updatedData = {
            ...initialData,
            channels: {
              ...initialData.channels,
              [bot]: { ...initialData.channels[bot], token: value, status: 'active' },
            },
          }

          dispatch(
            upsertSidebarItem({
              id: initialData?.id,
              type: 'widgets',
              requestBody: updatedData,
              navigate,
            })
          )
        }
      },
      [initialData]
    )

    const onTokenRemove = () => {
      const updatedData = {
        ...initialData,
        channels: {
          ...initialData.channels,
          [bot]: {
            ...initialData.channels[bot],
            token: null,
            bot_name: '@',
            status: 'inactive',
          },
        },
      }

      dispatch(
        upsertSidebarItem({
          id: initialData?.id,
          type: 'widgets',
          requestBody: updatedData,
          navigate,
        })
      )
    }

    const cardProps = {
      initialData,
      bot,
      disableTooltip,
      inputValue,
      isActive,
      isWidgetActive,
      isConnected,
      currBot,
      onChannelActivate,
      onTokenAdd,
      onTokenRemove,
      getConnectedText,
      theme,
      t,
    }

    return <SwitchableCard key={currBot.name} cardProps={cardProps} />
  })
}

export default SwitchableCards
