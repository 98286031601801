import React from 'react'
import { useNavigate } from 'react-router-dom'

import Form from '../../../components/Form'
import { FORM_CONFIGS } from '../../../config/forms'
import { useDispatch } from 'react-redux'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectMainData, selectSidebarDetails } from '../../../redux-saga/selectors'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'
import { upsertSidebarItem, deleteEntity } from '../../../redux-saga/actions'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { Button } from '../../../atomic-design-components'
import theme from '../../../theme'
import { useTranslation } from 'react-i18next'

const DepartmentUpsert = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('all')
  const formDataInitial = useMappedState(selectSidebarDetails)
  const members = useMappedState(selectMainData('members')) || []

  const depID = formDataInitial?.id

  const onDeleteConfirm = () => {
    dispatch(deleteEntity({ id: depID, type: 'departments', navigate, location }))
  }

  const onFormSubmit = (valuesChanged) => {
    if (!isObjectEmpty(valuesChanged)) {
      const newValues = transformDataToBeSent(valuesChanged)

      if (typeof valuesChanged.members !== 'undefined') {
        newValues.member_ids = newValues.members || []
      }

      if (!formDataInitial?.id) {
        newValues.status = 'active'
        if (!newValues.member_ids) {
          newValues.member_ids = []
        }
      }

      dispatch(
        upsertSidebarItem({
          id: formDataInitial?.id,
          type: 'departments',
          requestBody: newValues,
          navigate,
        })
      )
    }
  }
  const iconProps = { name: 'plusCircle', margin: '0 6px 0' }
  const deleteIconProps = { name: 'trashCan', margin: '0 6px 0', size: 16 }

  return (
    <>
      <Form
        initialValues={formDataInitial}
        formConfig={FORM_CONFIGS.departments}
        validationRules={FORM_CONFIGS.departments.validationRules}
        onSubmit={onFormSubmit}
        buttonsAreSticky
        withActions
        saveBtnIconProps={iconProps}
        saveBtnTextKey="create"
        isSubmitOnBlur={formDataInitial?.id}
        optionsData={{ members }}
        isSubmitButtonAlwaysShown={!formDataInitial?.id}
      />
      {formDataInitial?.id && (
        <Button
          style={{ width: 'calc(100% - 40px)' }}
          margin="0 20px"
          padding="11px"
          variant="primary"
          text={t('deleteDepartment')}
          fullWidth
          iconLeftProps={deleteIconProps}
          backgroundColor={theme.color.status.error}
          confirmButtonProps={{
            fullWidth: true,
            iconLeftProps: {
              name: 'inputError',
              size: 16,
              margin: '0 5px 0 0',
              fill: theme.color.status.error,
            },
            color: theme.color.status.error,
            backgroundColor: theme.color.general.white,
            onDeleteConfirm: onDeleteConfirm,
            variant: 'primary',
          }}
        />
      )}
    </>
  )
}

export default DepartmentUpsert
