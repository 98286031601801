import React from 'react'

export const deleteCompany = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 200} height={height || 200} viewBox="0 0 200 200" fill="none">
    <g clipPath="url(#clip0_11080_219893)">
      <circle cx="100" cy="100" r="100" fill="#F9CEC2" />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.339 60.1602C123.119 61.4074 123.004 62.6906 123.004 64.0001C123.004 76.1314 132.873 86.0001 145.004 86.0001C146.723 86.0001 148.397 85.8019 150.004 85.4271V90.1602H50.0039V63.1956C50.0039 61.5184 51.3972 60.1602 53.1178 60.1602H123.339Z"
        fill="#E83C0C"
      />
      <g filter="url(#filter0_d_11080_219893)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.625 69.2012C125.967 78.8305 134.664 86.0001 145.004 86.0001C146.723 86.0001 148.397 85.8019 150.004 85.4271V137.043C150.004 138.764 148.611 140.157 146.89 140.157H53.1178C51.3972 140.157 50.0039 138.764 50.0039 137.043V78.3844C50.0039 76.6638 51.3972 75.2705 53.1178 75.2671H113.561C115.157 75.2671 116.608 74.3326 117.269 72.8785L117.856 71.5898C118.517 70.1357 119.964 69.2012 121.564 69.2012H123.625Z"
          fill="#E83C0C"
        />
      </g>
      <g filter="url(#filter1_d_11080_219893)">
        <path
          d="M145.004 46C135.078 46 127.004 54.0744 127.004 64C127.004 73.9256 135.078 82 145.004 82C154.93 82 163.004 73.9256 163.004 64C163.004 54.0744 154.93 46 145.004 46Z"
          fill="#E83C0C"
        />
      </g>
      <path
        d="M151.634 68.5075C152.221 69.0941 152.221 70.042 151.634 70.6286C151.342 70.9212 150.958 71.0681 150.574 71.0681C150.19 71.0681 149.806 70.9212 149.513 70.6286L145.006 66.1209L140.498 70.6286C140.206 70.9212 139.822 71.0681 139.438 71.0681C139.054 71.0681 138.67 70.9212 138.377 70.6286C137.791 70.042 137.791 69.0941 138.377 68.5075L142.885 64L138.377 59.4926C137.791 58.906 137.791 57.9581 138.377 57.3714C138.964 56.785 139.912 56.785 140.498 57.3714L145.006 61.8791L149.513 57.3714C150.1 56.785 151.048 56.785 151.634 57.3714C152.221 57.9581 152.221 58.906 151.634 59.4926L147.127 64L151.634 68.5075Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_11080_219893"
        x="35.0039"
        y="68.2012"
        width="130"
        height="100.955"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.909804 0 0 0 0 0.235294 0 0 0 0 0.0470588 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11080_219893" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11080_219893" result="shape" />
      </filter>
      <filter
        id="filter1_d_11080_219893"
        x="112.004"
        y="45"
        width="66"
        height="66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.909804 0 0 0 0 0.235294 0 0 0 0 0.0470588 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11080_219893" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11080_219893" result="shape" />
      </filter>
      <clipPath id="clip0_11080_219893">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
