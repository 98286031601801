import React, { useState, useRef, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Typography, Input, Checkbox, Button, FlexRow } from '../../../atomic-design-components'
import { StyledArea } from './styled'
import EmptyScreen from '../../../components/EmptyScreen'
import { deleteEntity, upsertSidebarItem } from '../../../redux-saga/actions'
import { useMappedState, usePrevious } from '../../../hooks/useReactRedux'
import { selectUserRole } from '../../../redux-saga/selectors'
import { ScreenContext } from '../../../hooks/useScreenContext'

const TemplateArea = ({
  templatesList,
  operatorID,
  t,
  theme,
  dispatch,
  navigate,
  isNewTemplate,
  setIsNewTemplate,
}) => {
  const { templateId } = useParams()
  const prevTemplateId = usePrevious(templateId)

  const role = useMappedState(selectUserRole)

  const { width, sm, lg } = useContext(ScreenContext)
  const currTemplate = templatesList?.find((el) => el.id === +templateId)
  const templateNameCurr = currTemplate
    ? currTemplate.name === 'Personal template'
      ? t('personalTemplate')
      : currTemplate.name
    : t('personalTemplate')

  const isNotAdmin = !currTemplate?.operator_id && role === 'operator'

  const nameAreaRef = useRef(null)

  const [checkboxChecked, setCheckboxChecked] = useState(!currTemplate?.operator_id)
  const [templateName, setTemplateName] = useState(templateNameCurr)
  const [templateValue, setTemplateValue] = useState(currTemplate?.body)
  const [nameError, setNameError] = useState('')

  useEffect(() => {
    if (templateName) {
      setNameError('')
    }
  }, [templateName])

  const onNameChange = (e) => {
    setTemplateName(e.target.value)
  }

  const onValueChanged = (e) => {
    setTemplateValue(e.target.value)
    // setTemplateValue(content.html)
  }

  useEffect(() => {
    if (prevTemplateId !== templateId) {
      if (nameAreaRef.current) {
        nameAreaRef.current.focus()
        if (isNewTemplate) {
          nameAreaRef.current.select()
          setIsNewTemplate(false)
        }
      }

      setTemplateName(templateNameCurr)
      setTemplateValue(currTemplate?.body)
      setCheckboxChecked(!currTemplate?.operator_id)
    }
  }, [templateId, prevTemplateId])

  const iconProps = { name: 'floppy', size: 16 }
  const deleteIconProps = { name: 'trashCan', size: 16, fill: theme.color.general.darker }

  const onDeleteConfirm = () =>
    dispatch(deleteEntity({ id: templateId, type: 'templates', navigate, location, navigateTo: '.' }))

  const onUpdate = () => {
    if (!templateName) {
      setNameError(t('validation:required'))
      return
    }

    const requestBody = {
      name: templateName,
      body: templateValue,
      operator_id: checkboxChecked ? null : operatorID,
    }

    dispatch(
      upsertSidebarItem({
        id: templateId,
        type: 'templates',
        requestBody,
        location,
      })
    )
  }

  if (!templateId) {
    return <EmptyScreen type="templateSelected" icon="templatesEmpty" />
  }

  return (
    <StyledArea>
      <Typography text={t('editTemplate')} variant={width <= lg ? 'h3' : 'h1'} />
      <Input
        type="text"
        label={t('templateName')}
        value={templateName}
        inputRef={nameAreaRef}
        onChange={(e) => onNameChange(e)}
        error={nameError}
        disabled={isNotAdmin}
      />

      <Input value={templateValue} onChange={onValueChanged} multiline disabled={isNotAdmin} />
      {/*<RichTextEditor value={templateValue} onChange={onValueChanged} readOnly={isNotAdmin} />*/}

      <Checkbox
        checked={checkboxChecked}
        handleChange={setCheckboxChecked}
        label={t('templateCheckboxLabel')}
        disabled={role === 'operator'}
      />

      <FlexRow justifyContent="space-between" gap="20px" flexWrap={width <= sm ? 'wrap' : 'nowrap'}>
        <Button
          disabled={isNotAdmin}
          fullWidth
          text={t('delete')}
          iconLeftProps={deleteIconProps}
          variant="general"
          confirmButtonProps={{
            iconLeftProps: {
              name: 'inputError',
              size: 16,
              margin: '0 5px 0 0',
              fill: theme.color.status.error,
            },
            color: theme.color.status.error,
            backgroundColor: theme.color.general.white,
            borderColor: theme.color.status.error,
            onDeleteConfirm: onDeleteConfirm,
            fullWidth: true,
          }}
        />

        <Button
          disabled={isNotAdmin}
          variant="primary"
          text={t('save')}
          iconLeftProps={iconProps}
          fullWidth
          onClick={onUpdate}
        />
      </FlexRow>
    </StyledArea>
  )
}

export default TemplateArea
