import { useState } from 'react'

export const useIsPasswordShown = () => {
  const [isPasswordShown, setPasswordShown] = useState(false)

  const handlePasswordShown = () => {
    setPasswordShown(!isPasswordShown)
  }

  const type = isPasswordShown ? 'text' : 'password'
  const iconRightProps = {
    name: isPasswordShown ? 'eyeSignCross' : 'eyeSign',
    onClick: (e) => {
      e.stopPropagation()
      handlePasswordShown()
    },
  }
  return { type, iconRightProps }
}
