import styled from 'styled-components'

import { Drawer } from '../../atomic-design-components'
import { HEADER_HEIGHT } from '../../constants'

export const StyledMainWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .titleRow {
    height: ${HEADER_HEIGHT}px;
    padding: 10px 20px;
    border-bottom: 1px solid ${({ theme: { color } }) => color.general.lighter};

    .closeIcon {
      border: 1px solid ${({ theme: { color } }) => color.general.lighter};
      border-radius: 3px;
    }
  }

  .popupLinkText {
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    .oneLinePopup {
      max-width: 100%;
      white-space: nowrap;
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
`

export const StyledGrid = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    // &.extended {
    //   margin-top: ${HEADER_HEIGHT}px;
    // }
  }
`

export const StyledMain = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  &.settingsPage {
    overflow-y: auto;
  }

  .titleRow {
    .itemsCountBadge {
      margin-left: 10px;
    }
    .menuIcon {
      margin-left: auto;
    }
  }

  &.withBackIcon {
    .titleRow .menuIcon {
      padding: 10px;
      border: 1px solid ${({ theme: { color } }) => color.general.light};
      border-radius: 3px;
    }
  }
`

export const StyledLeftStaticDrawer = styled(Drawer)`
  border-right: 1px solid ${({ theme: { color } }) => color.general.lighter};
  min-width: 200px;
  overflow-x: hidden;

  &.chats {
    min-width: 260px;
  }

  &.templates {
    min-width: 300px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${HEADER_HEIGHT}px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    z-index: 5;
    border: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    height: calc(100vh - ${HEADER_HEIGHT}px);
  }
`

export const StyledRightDrawer = styled(Drawer)`
  border-left: 1px solid ${({ theme: { color } }) => color.general.lighter};
  min-width: ${({ minWidth }) => minWidth || '280px'};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    z-index: 5;
    border: none;
    margin-top: ${HEADER_HEIGHT};
  }
`
