import dayjs from 'dayjs'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { buildRequest, fetchData } from '../../api'
import { upsertSidebarItem } from '../actions'
import {
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_URL,
  POPUP_ALERT_SHOW,
  SET_FILE_URL_LOADED,
  SET_USER_AVATAR,
} from '../constants'
import { selectFileUrlsLoaded } from '../selectors'

export function* getUploadedImagesData({ newFiles, chatID, memberID, location }) {
  try {
    yield all(
      newFiles
        .map((file) => {
          return call(uploadFile, {
            file,
            chatID,
            memberID,
            location,
          })
        })
        .flat()
    )
  } catch (error) {
    console.error(error)
  }
}

/**
 * Core function which holds all the logic of file upload/Library update
 * @param {file} file - File to be uploaded
 * @param {object} params - File's metadata
 */
export function* uploadFile({ file, chatID, memberID, location }) {
  try {
    const fd = new FormData()
    fd.append('file_obj', file)

    const request = yield buildRequest({
      requestBody: fd,
      type: 'filesUpload',
      apiMethod: 'POST',
    })

    const result = yield fetchData(request)
    if (result.file_code) {
      if (chatID) {
        yield put({
          type: FILE_UPLOAD_SUCCESS,
          file: {
            file_code: result.file_code,
            file_name: file.name,
            file_size: file.size,
            type: file.type,
            preview: file.preview,
          },
          chatID,
        })
        return { chatID, fileCode: result.file_code }
      }
      if (memberID) {
        const file = yield doGetFileUrl(result)
        yield put(
          upsertSidebarItem({
            id: memberID,
            type: 'members',
            requestBody: { profile_picture: file.file_code },
            location,
          })
        )

        yield put({
          type: SET_USER_AVATAR,
          avatar: file.url,
        })
        return { fileCode: result.file_code }
      }
    } else {
      throw new Error('No "result.file_code" in response')
    }
  } catch (error) {
    console.log(error)
    // yield put({ type: FILE_UPLOAD_ERROR, filePreviewId, chatID })
    yield put({ type: POPUP_ALERT_SHOW, data: { contentKey: 'errorInSavingData', type: 'error' } })
  }

  // if (!errors?.length && params.onSuccess) {
  //   params.onSuccess(result.photos)
  // }
}

export function* doGetFileUrl(file, keyName, fileCode) {
  const urlsObjectToCheck = yield select(selectFileUrlsLoaded)

  const code = fileCode || file.file_code

  if (code?.startsWith('http')) {
    return file
  }

  if (urlsObjectToCheck[code]?.url && urlsObjectToCheck[code].date.add(11, 'hour').isAfter(dayjs())) {
    return { ...file, [keyName || 'url']: urlsObjectToCheck[code].url, file_code: code }
  } else {
    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'fileUrl',
      apiUrlParam: `?file_code=${code}`,
    })
    const response = yield fetchData(request)

    if (urlsObjectToCheck) {
      yield put({ type: SET_FILE_URL_LOADED, code: code, url: response.url })
    }

    return { ...file, [keyName || 'url']: response.url, file_code: code }
  }
}

export function* doGetDepartmentMembersAvatars({ departments }) {
  try {
    const allMembers = departments.map((department) => {
      return department.members
    })

    let urls
    for (const member of allMembers.flat()) {
      urls = yield call(doGetFileUrl, member, 'profile_picture', member.profile_picture)
    }

    const avatarsUrls = yield select(selectFileUrlsLoaded)

    return departments.map((department) => ({
      ...department,
      members: department.members
        .filter((member) => member.id)
        .map((member) => ({
          ...member,
          profile_picture: member.profile_picture.startsWith('http')
            ? member.profile_picture
            : avatarsUrls[member.profile_picture]?.url,
        })),
    }))
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  return [yield takeLatest(FILE_UPLOAD, getUploadedImagesData), yield takeLatest(FILE_URL, doGetFileUrl)]
}
