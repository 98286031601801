import React from 'react'
import { withTheme } from 'styled-components'

import { StyledTimeInput } from './styled'

const TimeInput = ({ className, disabled, id, step, value, width, onChange }) => {
  return (
    <StyledTimeInput
      className={className}
      disabled={disabled}
      id={id}
      step={step}
      type="time"
      value={value}
      width={width}
      onChange={onChange}
    ></StyledTimeInput>
  )
}

export default withTheme(TimeInput)
