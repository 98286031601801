import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes as T } from 'prop-types'
import Cookies from 'universal-cookie'

import { LANGUAGES_FULL_NAME } from '../../locales'
import { useWidth } from '../../hooks/useWidth'
import { Select } from '../../atomic-design-components'
import { StyledSelect } from './styled'

const LanguageSelect = ({ theme, width, iconLeftProps, isFullSize, isIntroScreensSelect }) => {
  const screenWidth = useWidth()
  const isMobile = screenWidth && screenWidth < theme.breakpoints.sm
  const isNearMobile = screenWidth && screenWidth < '640'

  const {
    i18n: {
      language: currentLng,
      changeLanguage,
      options: { languages },
      t,
    },
  } = useTranslation()

  useEffect(() => {
    const cookies = new Cookies()
    const lngFromCookies = cookies.get('lng', currentLng, { path: '/' })
    if (!lngFromCookies) {
      cookies.set('lng', currentLng, { path: '/' })
    }
  }, [])

  const onChange = (lang) => {
    changeLanguage(Object.values(lang))
    const cookies = new Cookies()
    cookies.set('lng', lang.id, { path: '/' })
  }

  const getLanguagesList = () => {
    return languages.map((lang) => ({
      id: lang,
      label: LANGUAGES_FULL_NAME[lang],
    }))
  }

  const value = {
    id: currentLng,
    label:
      isNearMobile || !isFullSize
        ? LANGUAGES_FULL_NAME[currentLng].slice(0, 3)
        : LANGUAGES_FULL_NAME[currentLng],
  }

  if (isIntroScreensSelect) {
    return (
      <StyledSelect>
        <Select
          iconLeftProps={!isMobile && isFullSize && { name: 'planet' }}
          onChange={onChange}
          options={getLanguagesList()}
          value={value}
          variant="primary"
          withoutValidation
          minWidth={width}
          className="languageSelect"
          iconText={!isMobile && isFullSize && `${t('auth:language')}:`}
        />
      </StyledSelect>
    )
  }

  return (
    <Select
      iconLeftProps={iconLeftProps}
      onChange={onChange}
      options={getLanguagesList()}
      value={value}
      variant="primary"
      withoutValidation
      fullWidth
    />
  )
}

export default LanguageSelect

LanguageSelect.propTypes = {
  theme: T.object,
  width: T.string,
}
