import styled from 'styled-components'

export const StyledEmptyTable = styled.div`
  padding: 10px 20px;
  color: ${({ theme }) => theme.color.general.dark};
  background-color: ${({ theme }) => theme.color.general.lightest};
  font-size: 14px;
  line-height: 23px;
`

export const StyledNavbar = styled.nav`
  display: flex;
  padding: 20px 20px 0;
  align-items: center;
  width: 100%;

  &.transparent {
    opacity: 0;
  }

  .inputContainer {
    flex-grow: 1;
    margin-bottom: 0;
    border-color: ${({ theme }) => theme.color.general.lighter};

    //
    //.icon.leftIcon {
    //  top: 0;
    //}
  }

  > *:not(:last-child) {
    margin-right: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-wrap: wrap;
    justify-content: center;

    button:not(.previousPageButton):not(.nextPageButton) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: 400px) {
    .searchInput.inputContainer {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 415px) {
    .searchInput.inputContainer {
      margin-bottom: 15px;
    }
  }
`
