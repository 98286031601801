import styled from 'styled-components'

export const StyledCardContent = styled.div`
  .statusIcon {
    margin-left: 12px;
    padding: 2px 5px;
  }
  .planRow span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .actionRow .bottom.tooltip {
    right: auto;
    left: 0;
    .tooltip-arrow {
      right: auto !important;
      left: 9% !important;
    }
  }
  .statusRow .bottom.tooltip {
    right: 0;
    left: auto;
    .tooltip-arrow {
      right: 9% !important;
      left: auto !important;
    }
  }
  &.Free {
    order: 1;
  }
  &.Business {
    order: 3;
  }
  &.SmallTeam {
    order: 2;
  }

  .users {
    order: 1;
  }
  .widgets {
    order: 2;
  }
  .templates {
    order: 3;
  }
  .is_customization {
    order: 4;
  }
  .is_branding {
    order: 5;
  }
  .is_support {
    order: 6;
    display: none;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .statusIcon {
      padding: 0;
    }
  }
`
export const StyledOptionContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.general.lightest};
  border-radius: 3px;
  margin-top: 10px;

  .disabledIcon svg {
    transform: rotate(45deg);
  }
  .tooltipWrapper {
    max-width: 45%;
  }
  .optionColumn {
    width: 100%;
    max-height: 100px;
  }
`
