import React from 'react'

export const typeBubbleWidget = ({ width, height, fill, theme: { color } }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    width={width || 56}
    height={height || 56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <defs>
      <style>
        {`.cls-1 {
             fill: ${color.general.white};
          }

          .cls-2 {
             fill: ${fill || color.primary.main};
          }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className="cls-2"
        d="m1.06,11.77C1.8,6.19,6.19,1.8,11.77,1.06,22.54-.35,33.46-.35,44.23,1.06c5.58.73,9.98,5.13,10.71,10.71,1.42,10.77,1.42,21.68,0,32.45-.73,5.58-5.13,9.98-10.71,10.71-10.77,1.42-21.68,1.42-32.45,0-5.58-.73-9.98-5.13-10.71-10.71C-.35,33.46-.35,22.54,1.06,11.77Z"
      />
      <g>
        <path
          className="cls-1"
          d="m20.71,25.71c-1.18,0-2.14.96-2.14,2.14s.96,2.14,2.14,2.14,2.14-.96,2.14-2.14-.96-2.14-2.14-2.14Z"
        />
        <path
          className="cls-1"
          d="m28.14,25.71c-1.18,0-2.14.96-2.14,2.14s.96,2.14,2.14,2.14,2.14-.96,2.14-2.14-.96-2.14-2.14-2.14Z"
        />
        <path
          className="cls-1"
          d="m35.57,25.71c-1.18,0-2.14.96-2.14,2.14s.96,2.14,2.14,2.14,2.14-.96,2.14-2.14-.96-2.14-2.14-2.14Z"
        />
        <path
          className="cls-1"
          d="m41.89,14.11c-.62-.62-1.45-.96-2.32-.96h-22.86c-.88,0-1.7.34-2.32.96s-.96,1.45-.96,2.32v20.41l-2.23,6.7c-.12.35-.03.73.21,1,.19.21.46.32.73.32.08,0,.16,0,.24-.03l9.02-2.26h18.16c.88,0,1.7-.34,2.32-.96.62-.62.96-1.45.96-2.32v-22.86c0-.88-.34-1.7-.96-2.32Zm-1.04,25.18c0,.34-.13.67-.38.91-.24.24-.57.38-.91.38h-18.29c-.08,0-.16.01-.24.03l-7.38,1.84,1.71-5.13c.03-.1.05-.21.05-.32v-20.57c0-.34.13-.67.38-.91s.57-.38.91-.38h22.86c.34,0,.67.14.91.38.24.24.38.57.38.91v22.86Z"
        />
      </g>
    </g>
  </svg>
)
