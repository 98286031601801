import React from 'react'
import { PropTypes as T } from 'prop-types'

import clsx from 'clsx'
import { StyledUserpic } from './styled'
import theme from '../../../theme'
import emptyUserpic from '../../../assets/images/emptyUserpic.png'
import { Icon } from '../../index'

const Userpic = ({
  height,
  src,
  onlineState,
  iconName,
  width,
  className,
  iconWidth,
  iconHeight,
  ...otherProps
}) => {
  const stateColor = {
    online: theme.color.status.success,
    offline: theme.color.status.warning,
    idle: theme.color.status.inactive,
  }

  return (
    <StyledUserpic
      src={src || emptyUserpic}
      height={height}
      width={width}
      className={clsx(className, 'userpic')}
      {...otherProps}
    >
      {(onlineState || iconName) && (
        <Icon
          name={iconName || 'state'}
          className="onlineIcon"
          fill={stateColor[onlineState]}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </StyledUserpic>
  )
}

Userpic.propTypes = {
  className: T.string,
  height: T.oneOfType([T.number, T.string]),
  src: T.string.isRequired,
  state: T.string,
  iconName: T.string,
  width: T.oneOfType([T.number, T.string]),
  onlineState: T.string,
}

Userpic.defaultProps = {
  src: '',
}

export default Userpic
