import React from 'react'

import { FlexRow } from '../../../atomic-design-components'
import { StyledCardContent } from './styled'
// you should use name of column keys from config to get data here (not dataKeys): data.key

const ContactsTableCard = ({ cellProps, editMode, index, onClick, data, columns, t, theme }) => {
  return (
    <StyledCardContent>
      <FlexRow className="cardTableItemRow">
        <FlexRow>
          {data.channel}
          {data.fullName}
        </FlexRow>
        {data.chats}
      </FlexRow>
      <FlexRow className="cardTableItemRow">
        {data.email}
        {data.lastMessage}
      </FlexRow>
      <FlexRow className="cardTableItemRow">{data.tags}</FlexRow>
    </StyledCardContent>
  )
}

export default ContactsTableCard
