import { put, takeLatest } from 'redux-saga/effects'

import { buildRequest, fetchData, showError } from '../../api'
import { popupAlertShow, userLogout } from '../actions'
import {
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  DELETE_COMPANY,
  GET_BILLING_PLANS,
  GET_BILLING_PLANS_ERROR,
  GET_BILLING_PLANS_SUCCESS,
  GET_COMPANY_DATA,
  GET_COMPANY_DATA_ERROR,
  GET_COMPANY_DATA_SUCCESS,
  UPDATE_COMPANY_DATA,
  UPDATE_COMPANY_DATA_ERROR,
  UPDATE_COMPANY_DATA_SUCCESS,
} from '../constants'

/**
 * Returns company data by id
 * @param {*} id
 */

function* doGetMyCompany({ id }) {
  try {
    const request = yield buildRequest({ apiMethod: 'GET', type: 'customers', id })

    const response = yield fetchData(request)

    yield showError(response)
    yield put({
      type: GET_COMPANY_DATA_SUCCESS,
      item: response,
    })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_COMPANY_DATA_ERROR })
  }
}

function* doUpdateMyCompanyData({ id, values }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'PATCH',
      id,
      requestBody: values,
      type: 'customers',
    })

    const response = yield fetchData(request)

    yield showError(response)
    yield put({
      type: UPDATE_COMPANY_DATA_SUCCESS,
      item: response,
    })

    yield put(
      popupAlertShow({
        contentKey: 'dataSuccessfullySaved',
        type: 'success',
        withCloseButton: true,
        iconName: 'statusActive',
      })
    )
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_COMPANY_DATA_ERROR })
  }
}

function* doGetBillingPlans() {
  try {
    const request = yield buildRequest({
      apiMethod: 'GET',
      type: 'billingPlans',
      queryParams: [{ key: 'sort', value: 'id=asc' }],
    })

    const response = yield fetchData(request)

    yield showError(response)

    yield put({ type: GET_BILLING_PLANS_SUCCESS, plans: response?.items })
  } catch (error) {
    console.error(error)
    yield put({ type: GET_BILLING_PLANS_ERROR })
  }
}

function* doCancelSubscription() {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'cancelSubscription',
    })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({ type: CANCEL_SUBSCRIPTION_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: CANCEL_SUBSCRIPTION_ERROR })
  }
}

function* doDeleteCompany({ customerId, navigate }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'deleteCompany',
      queryParams: [{ key: 'customer_id', value: customerId }],
    })

    const response = yield fetchData(request)

    yield showError(response)

    if (response.status === 'ok') {
      yield navigate('/sign-in')
      yield put(userLogout())
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  return [
    yield takeLatest(GET_COMPANY_DATA, doGetMyCompany),
    yield takeLatest(UPDATE_COMPANY_DATA, doUpdateMyCompanyData),
    yield takeLatest(GET_BILLING_PLANS, doGetBillingPlans),
    yield takeLatest(CANCEL_SUBSCRIPTION, doCancelSubscription),
    yield takeLatest(DELETE_COMPANY, doDeleteCompany),
  ]
}
