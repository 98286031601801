import React from 'react'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { Tag, Typography } from '../../atomic-design-components'

const NameWithTag = ({ rowData, cellData, name, theme: { color }, typographyVariant, className, t }) => {
  const TAG_CONFIG = {
    installed: {
      light: `${color.status.success}40`,
      dark: color.status.success,
      text: 'installed',
    },
    not_installed: {
      light: `${color.status.error}40`,
      dark: color.status.error,
      text: 'installedNot',
    },
  }

  const currentStatus = rowData?.install_status

  return (
    <>
      <Typography
        component="div"
        variant={typographyVariant || 'body2'}
        className="name"
        margin="0 5px 0 0"
        as={ReactTexty}
      >
        {rowData.name || 'chatWidget #1'}
      </Typography>
      <Tag
        backgroundColor={TAG_CONFIG[currentStatus].light}
        color={TAG_CONFIG[currentStatus].dark}
        text={t(TAG_CONFIG[currentStatus].text)}
        className="withoutInactive"
      />
    </>
  )
}

export default withTheme(NameWithTag)
