import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'
import dayjs from 'dayjs'

import MyCompany from '../MyCompany'
import { useTranslation } from 'react-i18next'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectMainData, selectUserRole } from '../../redux-saga/selectors'
import TableBlock from '../../components/TableBlock'
import { useDispatch } from 'react-redux'
import PaymentsTableCard from './components/PaymentsTableCard'
import { useWidth } from '../../hooks/useWidth'
import { DatePicker, Select } from '../../atomic-design-components'
import { getTableData } from '../../redux-saga/actions'
import { StyledPayments } from './styled'

const Payments = ({ theme }) => {
  const dispatch = useDispatch()
  const {
    i18n: { language: currentLng },
  } = useTranslation()
  const { t } = useTranslation('all')
  const params = useParams()
  const navigate = useNavigate()
  const urlId = params?.rightPanelId

  const width = useWidth()
  const isMobile = width && width < theme.breakpoints.md

  const role = useMappedState(selectUserRole)

  const data = useMappedState(selectMainData('payments')) || []
  const customers = role === 'system_administrator' ? useMappedState(selectMainData('customers')) || [] : []

  const [isNavMenuExtended, setIsNavMenuExtended] = useState(false)

  const onIconClick = () => {
    setIsNavMenuExtended((prev) => !prev)
    navigate('../my-company')
  }

  useEffect(() => {
    if (role === 'system_administrator') {
      dispatch(getTableData({ type: 'customers', isSystemData: true }))
    }
  }, [])

  const [selectCustomer, setSelectCustomer] = useState('')

  const [dateRange, setDateRange] = useState([])
  const [startDate, endDate] = dateRange

  const onSelectDate = (update) => {
    setDateRange(update)
  }

  const [queryParams, setQueryParams] = useState([])
  useEffect(() => {
    setQueryParams([
      { key: 'date_from', value: endDate ? dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS') : null },
      { key: 'date_to', value: endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS') : null },
      { key: 'customer_id', value: selectCustomer?.id },
    ])
  }, [endDate, selectCustomer])

  if (!queryParams.length) {
    return null
  }

  const getFilters = (className) => (
    <StyledPayments className={className}>
      {role === 'system_administrator' && (
        <Select
          options={customers}
          value={selectCustomer}
          onChange={(customer) => setSelectCustomer(customer)}
          isMulti={false}
          isCreatable={false}
          isClearable={true}
          valueKey="id"
          labelKey="alias"
          variant="primary"
          withoutValidation
          width="200px"
          iconLeftProps={{ name: 'customers', fill: theme.color.general.dark }}
          placeholder={t('allCustomers')}
        />
      )}
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        onChange={(update) => onSelectDate(update)}
        currLang={currentLng}
        hasSelect
        theme={theme}
        t={t}
        // startPeriod="thisMonth"
        dateRange={dateRange}
        selectOptions={[
          { id: 'thisMonth', period: 'thisMonth' },
          { id: 'lastMonth', period: 'lastMonth' },
          { id: 'thisYear', period: 'thisYear' },
          { id: 'lastYear', period: 'lastYear' },
        ]}
        placeholder={t('selectDate')}
        isClearable
      />
    </StyledPayments>
  )

  return (
    <>
      <MyCompany RightPanelComponent={urlId && <Outlet />} className="withBackIcon paymentsContainer">
        {role !== 'system_administrator' && getFilters('datePickerContainer')}
        <TableBlock
          tableData={data}
          activeRowId={urlId}
          type="payments"
          iconEmpty="emptyPayments"
          TableCardContent={PaymentsTableCard}
          tableCardHeight={80}
          tableTitleChildren={getFilters('filterActions')}
          withMenuIcon={role !== 'system_administrator' && isMobile}
          role={role}
          menuIconProps={
            role === 'system_administrator'
              ? undefined
              : {
                  opened: isNavMenuExtended,
                  onClick: onIconClick,
                  fill: theme.color.general.light,
                  iconName: 'paginationArrow',
                }
          }
          queryParams={queryParams}
          navbarProps={{ withSearch: false }}
          // rightPanelIsOpened={rightPanelIsOpened}
          // leftPanelIsOpened={leftPanelIsOpened}
          // setLeftPanelOpened={setLeftPanelOpened}
          // user={user}
        />
      </MyCompany>
    </>
  )
}

export default withTheme(Payments)
