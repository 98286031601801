import styled from 'styled-components'
import { Input, Link } from '../../../atomic-design-components'
import { HEADER_HEIGHT } from '../../../constants'

export const StyledChatArea = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${HEADER_HEIGHT}px);
  position: relative;

  .listVirtualized {
    overflow-x: hidden !important;
  }

  & + .offlineText + .textarea {
    padding-top: 0;
  }
`

export const StyledChatsNotFoundRow = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};

  .icon {
    margin-right: 7px;
  }
`

export const StyledContainer = styled.div`
  padding: 20px;
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-top: 1px solid transparent;
  }
  .expansionPanel {
    width: 100%;
  }
  .inputWrapper.searchInput {
    flex-grow: 1;
  }

  .withInsideButtons {
    padding-bottom: 0;

    input {
      padding-left: 10px;
    }
  }

  .inputContainer {
    flex-grow: 1;
  }

  &.searchContainer {
    position: sticky;
    top: -1px;
    background-color: ${({ theme }) => theme.color.general.white};
    z-index: 3;
    padding: 9px 20px;

    .withoutBorder:focus-within {
      border: 1px solid transparent !important;
      outline: none !important;
    }

    .leftIcon {
      top: 12px;
      left: 0;
    }

    .hasLeftIcon {
      padding-left: 24px;
    }

    .rightIcon {
      padding-right: 0;
    }

    .buttonsRow {
      top: 0;
      right: 0;
      left: auto;
      bottom: auto;
    }
    .infoButtonTip {
      margin-right: 10px;
    }
    .navbarButton {
      width: 44px;
      height: 44px;
      background-color: ${({ theme }) => theme.color.general.white};

      &.forwardButton {
        margin-right: 10px;
      }

      &:not(.closeChatButton) .icon {
        margin: 0;
      }
      &.disabled {
        border: 1px solid ${({ theme }) => theme.color.general.lightest};
      }
      &.infoButton {
        transform: rotate(180deg);
      }
      &.closeChatButton {
        background-color: ${({ theme }) => theme.color.status.error};
        border-color: ${({ theme }) => theme.color.status.error};
        width: fit-content;
        &:hover:not(.disabled) {
          background-color: ${({ theme }) => theme.color.primary.main} !important;
        }
        &.disabled {
          background-color: ${({ theme }) => theme.color.general.lightest};
          border-color: ${({ theme }) => theme.color.general.lightest};
        }
        &.confirmState {
          background-color: ${({ theme }) => theme.color.general.white};
          border-color: ${({ theme }) => theme.color.general.lighter};
          &:hover:not(.disabled) {
            background-color: ${({ theme }) => theme.color.general.white} !important;
          }
        }
      }
    }

    .dropSearch {
      position: absolute;
      top: ${HEADER_HEIGHT}px;
      width: 100%;
      left: 0;
      border-top-color: ${({ theme }) => theme.color.general.lighter};
      border-radius: 0;
      padding: 0 14px;
      .inputWrapper {
        background-color: ${({ theme }) => theme.color.general.white};
      }
      .hasLeftIcon {
        padding-left: 39px;
      }

      .icon.leftIcon {
        left: 15px;
      }

      input {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 20px 14px;

    &.searchContainer {
      padding: 9px 14px;
    }
    &.searchContainer.withDrop {
      padding-bottom: 54px;
    }
    &.searchContainer:not(.searchMessageContainer) {
      top: ${HEADER_HEIGHT}px;

      .hasLeftIcon {
        padding-left: 39px;
      }
      .icon.leftIcon {
        left: 15px;
      }
    }

    &.searchContainer.searchMessageContainer {
      display: flex;
      .navbarButton:not(:last-of-type) {
        margin-right: 10px;
        &.searchButton {
          margin-right: auto;
        }
      }

      .closeChatButton.confirmState .icon {
        margin-right: 5px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .searchManipulation {
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    .closeChatButton.confirmState {
      position: absolute;
      right: 20px;
      top: 9px;
      z-index: 3;
    }
  }
`

export const StyledChatsWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .scrollButton {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    bottom: 0;
    z-index: 5;

    &.displayed {
      opacity: 1;
      animation: toShowButton 0.5s ease-in;
    }

    &.hide {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    .icon {
      transform: rotate(-90deg);
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    height: calc(100vh - ${HEADER_HEIGHT}px);
  }

  @keyframes toShowButton {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const StyledChatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;

  &.textarea {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    z-index: 3;
    background-color: ${({ theme }) => theme.color.general.white};

    .inputContainer {
      margin-bottom: 0;
    }

    .inputWrapper:focus-within:not(.hasError) {
      border-color: ${({ theme }) => theme.color.general.light};
      outline: 2px solid transparent;
    }
    .prompt {
      position: absolute;
      right: 20px;
      bottom: -30px;
      letter-spacing: -0.3px;
    }
    .buttonsRow {
      width: 100%;
      .sendButton,
      .loader {
        position: absolute;
        right: 30px;
        margin-right: 0;
      }
    }
  }

  .textareaActionButton .icon {
    margin: 0;
  }
  .attachButton {
    position: relative;
  }
  .sendButton {
    background-color: ${({ theme }) => theme.color.secondary.main};
    border-color: ${({ theme }) => theme.color.secondary.main};
    &:hover:not(.disabled) {
      background-color: ${({ theme }) => theme.color.secondary.light} !important;
    }
  }

  .emojiPicker {
    position: absolute;
    bottom: 82px;
    left: 35px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 14px;
  }
`

export const StyledInput = styled(Input)`
  .inputWrapper {
    padding-bottom: ${({ active, extraPadding }) => active && `calc(60px + ${extraPadding}px) !important`};

    .multiline {
      min-height: 56px;
    }

    &.hasError {
      border-color: ${({ theme }) => theme.color.status.error};
    }

    &:focus-within:not(.searchInput):not(.hasError) {
      outline: none;
      border: 1px solid ${({ theme }) => theme.color.general.light};
    }

    .ql-editor {
      max-height: 95px;
    }
  }
`

export const StyledActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  .longText {
    max-width: 100px;
  }
  .longerText {
    max-width: 280px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .longText {
      max-width: 200px;
    }
    .longerText {
      max-width: 500px;
    }
  }
`

export const StyledAction = styled.div`
  .actionDividerContainer {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 3px;
    width: max-content;

    a {
      font-size: 10px;
      line-height: 13px;
    }

    & > * {
      margin-right: 4px;
    }
    .longActionText {
      max-width: ${({ width }) => width};
    }
  }
`
export const StyledLine = styled.hr`
  background-color: ${({ theme }) => theme.color.general.lighter};
  color: ${({ theme }) => theme.color.general.lighter};
  border-width: 0;
  height: 1px;
  width: 100%;
`

export const StyledChatMessage = styled.div`
  padding: 10px 14px;
  width: 85%;

  .chatMessage {
    .title {
      display: flex;
      margin-bottom: 5px;
      flex-wrap: wrap;
    }

    .content {
      padding: 8px 15px;
      background-color: ${({ theme }) => theme.color.general.lightest};
      color: ${({ theme }) => theme.color.general.dark};
      border-radius: 3px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    .highlightedText {
      background-color: ${({ theme }) => theme.color.status.warning}D9;
      color: ${({ theme }) => theme.color.general.white};
      padding: 1.5px 3px;
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        background-color: ${({ theme }) => theme.color.status.warning}D9;
        position: absolute;
        width: 6px;
        top: 0;
        left: 100%;
        height: 100%;
        border-radius: 3px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      &::before {
        content: '';
        background-color: ${({ theme }) => theme.color.status.warning}D9;
        position: absolute;
        width: 6px;
        top: 0;
        right: 100%;
        height: 100%;
        border-radius: 3px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &.operatorMessage,
  &.systemMessage,
  &.bulkMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
    margin-left: auto;

    .titleAndContent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      white-space: nowrap;

      .title {
        align-self: flex-start;
      }
    }

    .content {
      background-color: ${({ theme }) => `${theme.color.secondary.main}1A`};
      color: ${({ theme }) => theme.color.secondary.darker};
    }

    .userpic {
      margin-right: 10px;
    }

    .highlightedText {
      background-color: ${({ theme }) => theme.color.status.new}D9;

      &::after {
        background-color: ${({ theme }) => theme.color.status.new}D9;
      }

      &::before {
        background-color: ${({ theme }) => theme.color.status.new}D9;
      }
    }

    .imagesContainer {
      justify-content: flex-end;
    }
    .filesContainer {
      align-items: flex-end;
    }
  }
  &.systemMessage {
    .content {
      background-color: #ffe9c2;
      color: ${({ theme }) => theme.color.primary.main};
    }
  }
  &.bulkMessage {
    .content {
      background-color: #d2ecc8;
      color: ${({ theme }) => theme.color.tertiary.main};
    }
  }
  .longText {
    max-width: 100px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 60%;
    padding: 10px 20px;

    &.operatorMessage {
      width: 70%;
      margin-left: auto;
    }
    .longText {
      max-width: 200px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    &.operatorMessage {
      width: 60%;
    }
  }
`

export const StyledFileMessage = styled(Link)`
  justify-content: flex-end;
  position: relative;

  a {
    position: relative;
    display: flex;
    gap: 10px;
    .typography {
      max-width: 300px;
    }
    .nameContainer {
      display: flex;
      max-width: 300px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    & {
      padding: 5px;
    }
    a .typography {
      max-width: 200px;
    }
    .icon {
      margin: 0;
    }
  }
`
export const StyledFilesList = styled.div`
  margin-top: 20px;
  display: flex;

  .messageImageItem {
    position: relative;
    display: flex;
    justify-content: flex-end;
    border: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-radius: 3px;
    padding: 2px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  &.imagesContainer {
    margin-top: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;

    .messageImage {
      width: ${({ imgSize }) => imgSize};
      height: ${({ imgSize }) => imgSize};
      object-fit: cover;
    }
    .modalviewElement {
      position: absolute;
      right: calc(50% - 13px);
      bottom: calc(50% - 13px);
      pointer-events: none;
    }
  }

  &.filesContainer {
    flex-direction: column;
    gap: 10px;
    max-width: 350px;
    align-items: flex-start;
    margin-top: 10px;

    span.link {
      border: 1px solid ${({ theme }) => theme.color.general.lighter};
      border-radius: 3px;
      padding: 5px 10px;
    }
  }
`

export const StyledSearchInput = styled(Input)`
  .label {
    align-items: center;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid ${({ theme }) => theme.color.general.lighter};
  }
`
