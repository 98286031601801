import React from 'react'
import styled from 'styled-components'

const StyledDots = styled.div`
  width: ${({ width }) => width || 20}px;
  font-weight: 700;
  font-size: 22px;
  display: flex;
  align-items: center;
  &:after {
    content: '·';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`

const floatingDots = ({ fill, width }) => {
  return <StyledDots width={width} fill={fill}></StyledDots>
}

export default floatingDots
