import dayjs from 'dayjs'

import {
  FILE_UPLOAD,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_SUCCESS,
  INPUT_FILES_ADD,
  INPUT_FILES_CLEAR,
  INPUT_FILE_REMOVE,
  SET_FILE_URL_LOADED,
  SIGN_OUT,
} from '../constants'

const defaultState = {
  errors: {},
  errorLatest: null,
  filesUploaded: {},
  filesChosen: {},
  inProgress: false,
  filesInProgress: {},
  fileUrlsLoadedByCode: {},
}

export default (state = defaultState, action) => {
  const chatID = action.chatID
  const stateKey = action.stateKey

  switch (action.type) {
    case INPUT_FILES_ADD:
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          [action.chatID]: [...(state[stateKey][action.chatID] || []), ...action.files],
        },
      }
    case INPUT_FILE_REMOVE:
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          [chatID]: state[stateKey][chatID]?.filter((file) => file.preview !== action.filePreviewId),
        },
        filesUploaded: {
          ...state.filesUploaded,
          [chatID]: state.filesUploaded[chatID]?.filter((file) => file.preview !== action.filePreviewId),
        },
        filesInProgress: {
          ...state.filesInProgress,
          [chatID]: state.filesInProgress[chatID]?.filter((fileId) => action.filePreviewId !== fileId),
        },
        errors: {
          ...state.errors,
          [chatID]: state.errors[chatID]?.filter((errorFileId) => action.filePreviewId !== errorFileId),
        },
      }
    case FILE_UPLOAD:
      return {
        ...state,
        inProgress: true,
        filesInProgress: {
          ...state.filesInProgress,
          [action.chatID]: [...(state.filesInProgress[action.chatID] || []), action.newFiles[0]],
        },
      }
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        filesUploaded: {
          ...state.filesUploaded,
          [action.chatID]: [...(state.filesUploaded[action.chatID] || []), action.file],
        },
        filesInProgress: {
          ...state.filesInProgress,
          [action.chatID]: state.filesInProgress[action.chatID].filter(
            (file) => action.file.name !== file.name
          ),
        },
        errors: {
          ...state.errors,
          // [action.fileType]: state.errors[action.fileType]?.filter(
          //   (errorFileId) => action.filePreviewId !== errorFileId
          // ),
        },
      }
    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        inProgress: false,
        errors: {
          ...state.errors,
          // [action.fileType]: [...(state.errors[action.fileType] || []), action.filePreviewId],
        },
        filesInProgress: {
          ...state.filesInProgress,
          // [action.fileType]: state.filesInProgress[action.fileType]?.filter(
          //   (fileId) => action.filePreviewId !== fileId
          // ),
        },
      }
    case INPUT_FILES_CLEAR:
      return {
        ...state,
        filesInProgress: {},
        filesChosen: {},
        filesUploaded: {},
      }
    case SET_FILE_URL_LOADED:
      return {
        ...state,
        fileUrlsLoadedByCode: {
          ...state.fileUrlsLoadedByCode,
          [action.code]: { url: action.url || '', date: dayjs() },
        },
      }
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
