import React, { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import merge from 'deepmerge'
import dot from 'dot-object'

import Form from '../../../components/Form'
import { useMappedState } from '../../../hooks/useReactRedux'
import { FORM_CONFIGS } from '../../../config/forms'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { getTableData, setFormValues, upsertSidebarItem } from '../../../redux-saga/actions'
import { selectMainData, selectFormValues } from '../../../redux-saga/selectors'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'
import { PreviewContainer } from '../widgetPreview/PreviewContainer'
import { ScreenContext } from '../../../hooks/useScreenContext'
import { DEFAULT_WIDGET_SETTINGS } from '../../../constants'

const overwriteMerge = (destinationArray, sourceArray) => sourceArray

const ChatWidgetsCustomisationPanel = () => {
  const params = useParams()
  const { rightPanelId } = params

  const { width } = useContext(ScreenContext)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formValues = useMappedState(selectFormValues)

  const widgets = useMappedState(selectMainData('widgets')) || []

  const currWidget = widgets.find((widget) => widget.id === +rightPanelId)

  const formDataInitial = currWidget?.settings || DEFAULT_WIDGET_SETTINGS

  useEffect(() => {
    if (!isObjectEmpty(formDataInitial)) {
      dispatch(setFormValues(formDataInitial))
    }
  }, [formDataInitial])

  useEffect(() => {
    dispatch(
      getTableData({
        type: 'departments',
        status: 'active',
      })
    )
  }, [])

  const FormBlockWrapper = ({ children, blockKey }) => {
    if (blockKey === 'widgetAppearance') {
      return (
        <>
          {width <= 900 && <PreviewContainer formValues={formValues} />}
          {children}
        </>
      )
    }
    return children
  }

  const onFormSubmit = (valuesChanged) => {
    const newValues = {
      ...currWidget,
      settings: merge.all(
        [
          formValues,
          dot.object(transformDataToBeSent(valuesChanged)),
          {
            departments: {
              is_selected: false,
              values: [],
            },
          },
        ],
        {
          arrayMerge: overwriteMerge,
        }
      ),
    }

    if (!isObjectEmpty(valuesChanged)) {
      dispatch(
        upsertSidebarItem({
          id: rightPanelId,
          type: 'widgets',
          requestBody: newValues,
          navigate,
        })
      )
    }
  }

  return (
    <Form
      initialValues={formDataInitial}
      formConfig={FORM_CONFIGS.customization}
      validationRules={FORM_CONFIGS.customization.validationRules}
      onSubmit={onFormSubmit}
      withCancelButton
      cancelButtonText="reset"
      saveBtnIconProps={{ name: 'floppy' }}
      withActions
      buttonsAreSticky
      isSubmitButtonAlwaysShown
      withSaveInRedux
      FormBlockWrapper={FormBlockWrapper}
    />
  )
}

export default ChatWidgetsCustomisationPanel
