import { components } from 'react-select'
import React from 'react'

import Icon from '../../../atoms/Icon/Icon'

const ClearIndicator = ({ children, ...props }) => {
  const { isDisabled, error, theme } = props.selectProps

  return (
    <components.ClearIndicator {...props}>
      <Icon
        width={12}
        height={12}
        name="cross"
        className="crossIcon"
        fill={
          isDisabled
            ? theme.color.general.light
            : error
              ? theme.color.status.error
              : theme.color.general.black
        }
      />
    </components.ClearIndicator>
  )
}

export default ClearIndicator
