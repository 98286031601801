import styled from 'styled-components'
import { FlexRow } from '../../../atomic-design-components'

export const StyledCardContent = styled.div`
  padding: 20px;
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.color.general.lighter}`};
  margin-top: 20px;
  width: 100%;

  &.active {
    border-color: ${({ theme }) => theme.color.primary.main};
    box-shadow: ${({ theme }) => `0px 0px 3px ${theme.color.primary.main}`};
  }

  .tag {
    margin: 0 auto 0 10px;
  }
  .optionColumn {
    max-height: 74px;
    width: 100%;
  }
  .linedText {
    text-decoration: line-through;
  }

  .users {
    order: 1;
  }
  .widgets {
    order: 2;
  }
  .templates {
    order: 3;
  }
  .is_customization {
    order: 4;
  }
  .is_branding {
    order: 5;
  }
  .is_support {
    order: 6;
    display: none;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .optionColumn {
      max-height: none;
      .flexRow {
        width: 100%;
      }
    }
    .tag {
      margin: 0 10px;
    }
    .cardTableItemRow {
      flex-wrap: wrap;
      gap: 10px 0;
    }
  }
`

export const StyledOptionContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.general.lightest};
  border-radius: 3px;
  margin-top: 10px;

  .disabledIcon svg {
    transform: rotate(45deg);
  }
  .flexRow {
    width: 45%;
  }
`

export const StyledCurrPlanCard = styled.div`
  margin-top: 10px;

  .planRow {
    padding: 5px 10px;
  }
  .planRow:nth-child(odd) {
    background-color: ${({ theme }) => theme.color.general.lightest};
  }
  .optionName {
    width: 150px;
  }
  .disabledIcon svg {
    transform: rotate(45deg);
  }
`

export const StyledFlexRow = styled(FlexRow)`
  order: ${({ order }) => order};
`
