import styled from 'styled-components'

const getBorder = (props) => {
  const { color } = props.theme
  // if (props.isDragAccept) {
  // return `1px solid ${color.status.success}`
  // }
  // if (props.isDragReject) {
  // return `1px solid ${color.status.error}`
  // }
  // if (props.isDragActive) {
  // return `1px solid ${color.status.new}`
  // }
  return `1px dashed ${color.general.light}`
}

export const StyledDropZone = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  border-radius: 3px;
  border: ${(props) => getBorder(props)};
  outline: none;
  transition: border-color 0.24s ease-in-out;
`

export const StyledDropButton = styled.div`
  position: absolute;
  width: 32px;
  height: 26px;
  top: 0;
  left: 0;
`
