import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '../../../atomic-design-components'
import Form from '../../../components/Form'
import NameWithAvatar from '../../../components/NameWithAvatar'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import { getTableData, updateCurrentPlan, upsertSidebarItem } from '../../../redux-saga/actions'
import { selectMainData, selectSidebarDetails, selectSidebarMetaData } from '../../../redux-saga/selectors'
import { transformDate, transformDateToUtcDayWithZeroedTime } from '../../../utils/dates'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'
import { StyledNameRow } from '../../Members/panels/styled'
import CustomerOwner from '../components/CustomerOwner'

const StyledCustomersEdit = styled.div`
  .formBlock {
    .opened {
      margin-bottom: 15px;
    }
  }

  .owner .typography.name {
    line-height: 15px;
  }

  .information {
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
    margin-top: 20px;
  }
`

const CustomerEdit = ({ theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')
  const navigate = useNavigate()
  const location = useLocation()
  const { rightPanelId } = useParams()

  const plans = useMappedState(selectMainData('subscriptionPlansByCustomer'))
  const formDataInitial = useMappedState(selectSidebarDetails)
  const { inProgress, error } = useMappedState(selectSidebarMetaData)
  const isStatusInactive = formDataInitial?.status === 'inactive'

  const planDataInitial = formDataInitial &&
    plans && {
      subscription_expire_at: formDataInitial.subscription_expire_at
        ? new Date(formDataInitial.subscription_expire_at)
        : null,
      plan: plans?.find((pl) => pl.id === formDataInitial.plan_id),
    }

  useEffect(() => {
    dispatch(getTableData({ type: 'subscriptionPlansByCustomer', isSystemData: true, id: rightPanelId }))
  }, [rightPanelId])

  const onPlanUpdate = (valuesChanged) => {
    dispatch(
      updateCurrentPlan({
        customer_id: formDataInitial.customer_id || formDataInitial.id,
        plan_id: valuesChanged.plan?.id || formDataInitial.plan_id,
        start_at: transformDateToUtcDayWithZeroedTime(),
        expires_at:
          (valuesChanged.plan?.id || formDataInitial.plan_id) !== 1 &&
          (valuesChanged.subscription_expire_at || formDataInitial.subscription_expire_at)
            ? transformDateToUtcDayWithZeroedTime(
                valuesChanged.subscription_expire_at || formDataInitial.subscription_expire_at
              )
            : null,
        payment_type: 'manual',
        status: 'active',
        navigate,
        location,
      })
    )
  }

  const onStatusChange = () => {
    dispatch(
      upsertSidebarItem({
        type: 'customers',
        apiUrlParam: 'update_status',
        requestBody: { customer_id: formDataInitial.id, is_active: isStatusInactive },
      })
    )
  }

  const onInfoUpdate = (valuesChanged) => {
    if (!isObjectEmpty(valuesChanged)) {
      const newValues = { ...transformDataToBeSent(valuesChanged) }

      dispatch(
        upsertSidebarItem({
          id: formDataInitial.id,
          type: 'customers',
          requestBody: newValues,
        })
      )
    }
  }

  const iconProps = { name: 'floppy', margin: '0 6px 0' }

  if (planDataInitial === null) {
    return null
  }

  return (
    <StyledCustomersEdit>
      <StyledNameRow padding="20px">
        <NameWithAvatar
          avatarSrc={formDataInitial?.company_logo}
          avatarSize={44}
          name={formDataInitial?.alias || formDataInitial?.company_name}
          underText={transformDate(formDataInitial?.created_at)}
          typographyVariant="h3"
        />
      </StyledNameRow>
      <Form
        initialValues={formDataInitial}
        formConfig={FORM_CONFIGS.customers.accountStatus}
        getFormButtons={() => (
          <Button
            type="submit"
            className="changeStatusButton"
            borderColor={isStatusInactive ? theme.color.status.success : theme.color.status.error}
            backgroundColor={isStatusInactive ? theme.color.status.success : theme.color.status.error}
            fullWidth
            disabled={formDataInitial.status === 'pending'}
            text={t(isStatusInactive ? 'activate' : 'deactivate')}
            iconLeftProps={{
              name: isStatusInactive ? 'statusActive' : 'statusDeleted',
              fill: theme.color.general.white,
              stroke: theme.color.general.white,
            }}
          />
        )}
        onSubmit={onStatusChange}
        expansionPanelProps={{
          disabled: true,
          withChevronInHeader: false,
        }}
      />
      <CustomerOwner t={t} />
      <Form
        initialValues={planDataInitial}
        formConfig={FORM_CONFIGS.customers.currentPlan}
        optionsData={{ plans }}
        onSubmit={onPlanUpdate}
        inProgress={inProgress}
        serverError={error}
        getFormButtons={(isDraft, formValues) => {
          return (
            <Button
              type="submit"
              disabled={
                inProgress || !isDraft || (formValues.plan?.id !== 1 && !formValues.subscription_expire_at)
              }
              backgroundColor={theme.color.primary.main}
              fullWidth
              text={t('updatePlan')}
              iconLeftProps={{
                name: 'arrowSync',
                fill: theme.color.general.white,
              }}
            />
          )
        }}
        expansionPanelProps={{
          disabled: true,
          withChevronInHeader: false,
        }}
      />
      <Form
        validationRules={FORM_CONFIGS.customers.information.validationRules}
        isSubmitOnBlur
        initialValues={formDataInitial}
        formConfig={FORM_CONFIGS.customers.information}
        onSubmit={onInfoUpdate}
        withActions
        buttonsAreSticky
        expansionPanelProps={{
          disabled: true,
          withChevronInHeader: false,
        }}
      />
    </StyledCustomersEdit>
  )
}

export default withTheme(CustomerEdit)
