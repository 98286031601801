import styled from 'styled-components'

export const StyledTextPanel = styled.div`
  position: relative;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding || '10px 15px'};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};

  .titleAndContent {
    width: 100%;
  }

  &.bordered {
    border: ${({ borderColor, color }) => `1px solid ${borderColor || color}`};
  }

  &.fullWidth {
    width: 100%;
  }
`
