import { css } from 'styled-components'

export const getCssFromTheme = (theme, component, type = '') => {
  const mainObject = theme.components[component] || theme.font.size[component]
  const cssObject = type ? mainObject?.[type] : mainObject

  if (cssObject) {
    return css`
      ${Object.keys(cssObject).reduce((accString, cssKey) => {
        return accString + `${cssKey}: ${cssObject[cssKey]};`
      }, '')};
    `
  }
}
