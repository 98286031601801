import React from 'react'

import { StyledMain } from './styled'
import ErrorBoundary from '../../wrappers/ErrorBoundary'
import PopupAlertsComponent from '../../wrappers/PopupAlertsComponent'

const CentralPanel = ({ children, className }) => {
  return (
    <StyledMain className={className}>
      <PopupAlertsComponent />
      <ErrorBoundary>{children}</ErrorBoundary>
      {/*<Button onClick={() => setIsRightPanelOpened(true)} />*/}
    </StyledMain>
  )
}

export default CentralPanel
