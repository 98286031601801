import clsx from 'clsx'
import { PropTypes as T } from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useDispatch } from 'react-redux'
import { toggleDrawerExtended } from '../../../redux-saga/actions'
import Icon from '../../atoms/Icon'
import { FlexRow, Typography } from '../../index'
import { StyledDrawer } from './styled'

const Drawer = ({
  absolutePositioned,
  children,
  className,
  closeIconPadding,
  destroyOnClose,
  isStatic,
  onClose,
  openedValue,
  side,
  title,
  toggleDrawer,
  width,
  withCloseIcon,
  withTitleSection,
}) => {
  const dispatch = useDispatch()

  const [displayed, setDisplayed] = useState(isStatic || !destroyOnClose || false)
  const [opened, setOpened] = useState(isStatic || false)
  const targetRef = useRef(null)

  const currDrawerState = JSON.parse(localStorage.getItem('rightDrawerState'))
  const hasLocalStorageState = currDrawerState?.isExtended !== undefined

  useEffect(() => {
    if (displayed && hasLocalStorageState) {
      dispatch(toggleDrawerExtended(currDrawerState.isExtended))
    } else {
      dispatch(toggleDrawerExtended())
    }
  }, [])

  const closeDrawer = useCallback(() => {
    if (destroyOnClose) {
      setOpened(false)
      window.closeId = setTimeout(() => {
        setDisplayed(false)
        if (onClose) {
          onClose()
        }
      }, 350)
    } else {
      setOpened(false)
      if (onClose) {
        onClose()
      }
    }
    if (toggleDrawer) {
      toggleDrawer(false)
    }
    dispatch(toggleDrawerExtended())
  }, [destroyOnClose, onClose, toggleDrawer])

  const openDrawer = useCallback(() => {
    if (destroyOnClose) {
      setDisplayed(true)
      window.openId = setTimeout(() => {
        setOpened(true)
        if (toggleDrawer) {
          toggleDrawer(true)
        }
      }, 100)
    } else {
      setOpened(true)
      if (toggleDrawer) {
        toggleDrawer(true)
      }
    }
    dispatch(toggleDrawerExtended(true))
  }, [destroyOnClose, toggleDrawer])

  useEffect(() => {
    if (openedValue) {
      openDrawer()
    } else if (openedValue === false) {
      closeDrawer()
    }

    return () => {
      window.clearTimeout(window.closeId)
      window.clearTimeout(window.openId)
    }
  }, [openedValue, closeDrawer, openDrawer])

  useEffect(() => {
    return () => {
      window.clearTimeout(window.openId)
    }
  }, [])

  return (
    displayed && (
      <StyledDrawer
        closeIconPadding={closeIconPadding}
        className={clsx(
          className,
          'drawer',
          opened && 'opened',
          displayed && 'displayed',
          side,
          isStatic && 'static'
        )}
        ref={targetRef}
        absolutePositioned={absolutePositioned}
        width={width}
      >
        {withTitleSection && (
          <FlexRow justifyContent="space-between" className="titleRow">
            {typeof title === 'string' ? <Typography variant="h3" text={title} /> : title}
            {withCloseIcon && (
              <Icon
                name="cross"
                onClick={closeDrawer}
                className="closeIcon"
                wrapperWidth={44}
                wrapperHeight={44}
                size={23}
              />
            )}
          </FlexRow>
        )}
        {children}
      </StyledDrawer>
    )
  )
}

export default Drawer

Drawer.propTypes = {
  absolutePositioned: T.bool,
  closeIconPadding: T.string,
  destroyOnClose: T.bool,
  isStatic: T.bool,
  side: T.oneOf(['left', 'right']),
  width: T.string,
  withCloseIcon: T.bool,
  withTitleSection: T.bool,
}

Drawer.defaultProps = {
  absolutePositioned: false,
  closeIconPadding: '20px',
  isStatic: false,
  side: 'right',
  destroyOnClose: true,
  withCloseIcon: true,
  withTitleSection: true,
}
