import React from 'react'

import { StyledAnalyticsLine } from './styled'

const AnalyticsLine = ({ color, value, width }) => {
  return (
    <StyledAnalyticsLine color={color} value={value} width={width}>
      <div className="valueRow" />
    </StyledAnalyticsLine>
  )
}

export default AnalyticsLine
