import { useCallback, useEffect, useRef, useState } from 'react'
import { HEADER_HEIGHT } from '../constants'

export const useLoadOnScroll = ({ hasMore, initialPageNumber, inProgress, loadNextPage, pageProp }) => {
  const [loadNumber, setLoadNumber] = useState(initialPageNumber || 1)
  const observer = useRef()
  // const pagesCount = itemsTotalCount && Math.ceil(itemsTotalCount / limit)
  const withPageProp = pageProp !== undefined
  const page = withPageProp ? pageProp : loadNumber

  useEffect(() => {
    if (loadNextPage && page > 1 && hasMore) {
      loadNextPage(page)
    }
  }, [page, hasMore])

  const lastElementRef = useCallback(
    (node) => {
      // console.log('last', loadNumber, hasMoreDown)

      if (inProgress) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        // const loadMore = hasMore || loadNumber < pagesCount

        if (entries[0].isIntersecting && hasMore) {
          // console.log('last', loadNumber)
          if (!withPageProp) {
            setLoadNumber((prevPageNumber) => prevPageNumber + 1)
          }
        }
      })

      if (node) observer.current.observe(node)
    },
    [inProgress, hasMore]
  )

  return { lastElementRef, loadNumber }
}
