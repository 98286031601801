import React, { useEffect } from 'react'
import { useNavigate, useLocation, useParams, useOutletContext, Navigate } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Button } from '../../../atomic-design-components'
import Form from '../../../components/Form'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectMainData, selectSidebarDetails } from '../../../redux-saga/selectors'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'
import { upsertSidebarItem, deleteEntity } from '../../../redux-saga/actions'
import ChatWidgetsNew from './ChatWidgetsNew'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { DEFAULT_WIDGET_SETTINGS } from '../../../constants'

const StyledButton = styled(Button)`
  justify-content: flex-start;
  cursor: auto;
  white-space: pre-wrap;
  text-align: left;
  margin-bottom: 20px;
  align-items: flex-start;
  .icon {
    margin-top: 2px;
  }
`

const FormBlockWrapper = withTheme(({ children, blockKey, id, formValues, theme }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('all')

  const data = useMappedState(selectMainData('widgets')) || []

  const hasInactiveWidget = (data) => {
    return data.find((widget) => widget.id === id)?.status === 'inactive'
  }

  const getTipsComponent = () => {
    return (
      <StyledButton
        variant="general"
        iconLeftProps={{ name: 'inputError', size: 16, fill: theme.color.general.darker }}
        backgroundColor={theme.color.general.lightest}
        borderColor={theme.color.general.lightest}
        fullWidth
        text={t('inactiveWidgetTip')}
      />
    )
  }

  const onDeleteConfirm = () => {
    dispatch(deleteEntity({ id, type: 'widgets', navigate, location }))
  }

  if (blockKey === 'general') {
    return (
      <>
        {hasInactiveWidget(data) && getTipsComponent()}
        {children}
        <Button
          iconLeftProps={{ name: 'trashCan', size: 15 }}
          text={t('delete')}
          fullWidth
          backgroundColor={theme.color.status.error}
          color={theme.color.general.white}
          confirmButtonProps={{
            onDeleteConfirm,
            iconLeftProps: {
              fill: theme.color.status.error,
              name: 'inputError',
            },
          }}
        />
      </>
    )
  }
  return children
})

const ChatWidgetsUpsert = () => {
  const params = useParams()
  const { rightPanelId } = params

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formDataInitial = useMappedState(selectSidebarDetails)

  const { isAddingNewAllowedByBilling } = useOutletContext()

  const onFormSubmit = (valuesChanged) => {
    if (!isObjectEmpty(valuesChanged)) {
      let newValues

      if (valuesChanged.status?.id === 'inactive') {
        const channelsDeactivated = Object.entries(formDataInitial.channels).reduce(
          (acc, [channelKey, channelObj]) => {
            acc[channelKey] = { ...channelObj, status: 'inactive' }
            return acc
          },
          {}
        )
        newValues = {
          ...formDataInitial,
          channels: channelsDeactivated,
          status: 'inactive',
          settings: formDataInitial.settings || DEFAULT_WIDGET_SETTINGS,
        }
      } else {
        newValues = {
          ...formDataInitial,
          ...transformDataToBeSent(valuesChanged),
          settings: formDataInitial.settings || DEFAULT_WIDGET_SETTINGS,
        }
      }

      dispatch(
        upsertSidebarItem({
          id: formDataInitial?.id,
          type: 'widgets',
          requestBody: newValues,
          navigate,
        })
      )
    }
  }

  if (typeof isAddingNewAllowedByBilling === 'undefined') {
    return null
  }

  if (rightPanelId === 'new' && !isAddingNewAllowedByBilling) {
    return <Navigate replace to="/404" />
  }

  if (rightPanelId === 'new') {
    return <ChatWidgetsNew />
  }

  return (
    <Form
      initialValues={formDataInitial}
      formConfig={FORM_CONFIGS.widgets}
      validationRules={FORM_CONFIGS.widgets.validationRules}
      FormBlockWrapper={FormBlockWrapper}
      onSubmit={onFormSubmit}
      buttonsAreSticky
      withActions
      isSubmitOnBlur={formDataInitial?.id}
    />
  )
}

export default ChatWidgetsUpsert
