import styled from 'styled-components'

export const StyledEmptyScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding-top: 10%;
`

export const StyledEmptyScreen = styled.div`
  height: calc(100% - 12vh);
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  padding: 0 15px;
  text-align: center;

  .inline {
    display: inline;
  }

  &.departments,
  &.widgets {
    margin-top: 8vh;
  }

  &.customers,
  &.contacts {
    margin-top: 5vh;
  }

  &.payments {
    margin-top: 20px;
  }

  button {
    margin-top: 20px;
    &.confirmState {
      width: auto;
    }
  }

  .typography {
    max-width: 430px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .body2,
    .body3 {
      color: ${({ theme }) => theme.color.general.dark};
    }
    .link a {
      color: ${({ theme }) => theme.color.secondary.dark};
    }
  }
`

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dadada;

  .headerBadge {
    margin-left: 10px;
    span {
      position: relative;
      transform: none;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    & {
      justify-content: space-between;
    }
    .headerBadge {
      margin-left: 10px;
      span {
        line-height: 21px;
        font-size: 16px;
        height: 24px;
      }
    }
  }
`
