import React from 'react'
import { useLocation } from 'react-router-dom'

import MenuGroup from '../../../components/NavMenu/MenuGroup'
import MenuItem from '../../../components/NavMenu/MenuItem'
import { StyledLine } from '../../../components/NavMenu/styled'
import { MY_COMPANY } from '../../../config'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectUserRole } from '../../../redux-saga/selectors'

const MyCompanyLeftPanel = ({ isExtended, isMobile, theme }) => {
  const { pathname } = useLocation()

  const role = useMappedState(selectUserRole)

  const isActiveKey = (key) =>
    pathname && pathname !== '/my-company'
      ? pathname.replace(/[^a-zA-Z ]/g, '').includes(key.toLowerCase())
      : key === ''

  const getIconFill = (key) => (isActiveKey(key) ? theme.color.general.white : theme.color.general.darker)

  return (
    <>
      <MenuGroup textColor={theme.color.general.darker} padding="0 10px">
        {MY_COMPANY.map((elem) => {
          if (
            (role === 'system_administrator' && elem.key === 'payments') ||
            (elem.rolesWithAccess && !elem.rolesWithAccess.includes(role))
          ) {
            return null
          }
          return (
            <MenuItem
              key={elem.key}
              iconName={elem.iconName}
              iconFill={getIconFill(elem.key)}
              text={elem.text}
              to={elem.route}
              isActive={isActiveKey(elem.key)}
              isExtended={isExtended}
              isMobile={isMobile}
              sortObject={elem.sortObject}
            />
          )
        })}
      </MenuGroup>
      {['system_administrator', 'customer_owner'].includes(role) && (
        <>
          <StyledLine lineColor={theme.color.general.lighter} />
          <MenuGroup textColor={theme.color.status.error} padding="0 10px">
            <MenuItem
              activeBGColor={theme.color.status.error}
              iconName="trashCan"
              iconFill={isActiveKey('deletecompany') ? theme.color.general.white : theme.color.status.error}
              text="deleteCompany"
              to="../my-company/delete-company"
              isActive={isActiveKey('deletecompany')}
              isExtended={isExtended}
              isMobile={isMobile}
            />
          </MenuGroup>
        </>
      )}
    </>
  )
}

export default MyCompanyLeftPanel
