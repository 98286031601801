import React from 'react'
import { Navigate, useOutletContext, useParams } from 'react-router-dom'

import MembersInvite from './MembersInvite'
import MemberEdit from './MemberEdit'

const MembersRightPanel = () => {
  const params = useParams()
  const urlId = params?.rightPanelId

  const { isAddingNewAllowedByBilling } = useOutletContext()

  if (typeof isAddingNewAllowedByBilling === 'undefined') {
    return null
  }

  if ((urlId === 'invite' && !isAddingNewAllowedByBilling) || urlId === 'new') {
    return <Navigate replace to="/404" />
  }

  if (urlId === 'invite') {
    return <MembersInvite />
  }

  return <MemberEdit />
}

export default MembersRightPanel
