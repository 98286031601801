import clsx from 'clsx'
import React, { useContext, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button, FlexRow, Tooltip } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { ScreenContext } from '../../../hooks/useScreenContext'
import { useTimeout } from '../../../hooks/useTimeout'
import { setEventToChatHistory } from '../../../redux-saga/actions'
import {
  selectCurrentChatInfo,
  selectIsDrawerExtended,
  selectIsNavMenuExtended,
  selectUserName,
} from '../../../redux-saga/selectors'
import { useSendEventWebsocket } from '../useChatsWebsockets'
import AcceptButton from './AcceptButton'
import SearchInChatMessage from './SearchInChatMessage'
import { StyledContainer } from './styled'

const ChatNavbar = ({
  currentChatStatus,
  searchButtonClicked,
  foundMessagesIds,
  searchValueCount,
  setSearchValueCount,
  setSearchValue,
  searchValue,
  t,
  theme,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const ref = useRef(null)

  const { search } = useLocation()

  const { width, md } = useContext(ScreenContext)
  const isMobile = width <= md

  const iconLeftProps = { name: 'searchSign', fill: theme.color.general.dark }

  const [searchClicked, setSearchClicked] = useState(false)
  const [areaWidth, setAreaWidth] = useState(0)

  const currentChat = useMappedState(selectCurrentChatInfo)
  const isDisabled = currentChat.status !== 'open'

  const currentUserName = useMappedState(selectUserName)

  const isMenuOpened = useMappedState(selectIsNavMenuExtended)
  const isPanelOpened = useMappedState(selectIsDrawerExtended)

  useTimeout(
    () => {
      setAreaWidth(ref.current.offsetWidth)
    },
    300,
    [isMenuOpened, isPanelOpened],
    true
  )

  const onChatCloseSuccess = (eventType, callback) => {
    if (eventType === 'chat_closed') {
      dispatch(
        setEventToChatHistory('chat_closed', {
          chat_id: +params.chatId,
          type: 'chat_closed',
          operator_name: currentUserName,
          data: { closed_by: 'operator' },
        })
      )
      callback()
    }
  }

  const onChatCloseConfirm = (callback) => {
    sendEventToServer({ chat_id: +params.chatId, type: 'chat_closed' })
    callback()
  }

  const { sendEventToServer, onSendEvent } = useSendEventWebsocket(onChatCloseConfirm, onChatCloseSuccess)

  const onArrowClick = () => {
    navigate({ pathname: '.', search })
  }

  const onSearchClick = () => {
    setSearchClicked(!searchClicked)
  }

  const onInfoClick = () => {
    navigate({ pathname: `${params.chatId}/contact/${currentChat.contact_id}`, search })
  }

  const onForwardClick = () => {
    navigate({ pathname: `${params.chatId}/forward`, search })
  }

  const getActionButtons = () => (
    <>
      {isMobile && (
        <>
          <Button
            variant="general"
            iconLeftProps={{
              name: 'paginationArrow',
              fill: theme.color.general.black,
              width: 20,
              height: 20,
              className: 'withoutHover',
            }}
            className="navbarButton leftMenuButton"
            onClick={onArrowClick}
          />
          <Button
            variant="general"
            iconLeftProps={{
              name: 'searchSign',
              fill: theme.color.general.black,
              width: 20,
              height: 20,
              className: 'withoutHover',
            }}
            className="navbarButton searchButton"
            onClick={onSearchClick}
            // disabled={isDisabled}
          />
        </>
      )}
      {areaWidth < 450 && !isMobile && (
        <Tooltip
          text={t('search')}
          tipPosition="bottom"
          arrowPosition="center"
          left="-28px"
          className="infoButtonTip"
        >
          <Button
            variant="general"
            iconLeftProps={{
              name: 'searchSign',
              fill: theme.color.general.black,
              width: 20,
              height: 20,
              className: 'withoutHover',
            }}
            className="navbarButton searchButton"
            onClick={onSearchClick}
            // disabled={isDisabled}
          />
        </Tooltip>
      )}
      <Tooltip
        text={t('contactDetails')}
        tipPosition="bottom"
        arrowPosition="center"
        left="-32px"
        className="infoButtonTip"
      >
        <Button
          variant="general"
          iconLeftProps={{
            name: 'inputError',
            fill: theme.color.general.black,
            width: 20,
            height: 20,
            className: 'withoutHover',
          }}
          className="navbarButton infoButton"
          onClick={onInfoClick}
        />
      </Tooltip>
      <Tooltip
        text={t('forward')}
        tipPosition="bottom"
        arrowPosition="center"
        left="-28px"
        disableTooltip={isDisabled}
      >
        <Button
          variant="general"
          iconLeftProps={{
            name: 'forwarded',
            fill: theme.color.general.black,
            width: 20,
            height: 20,
            className: 'withoutHover',
          }}
          className="navbarButton forwardButton"
          onClick={onForwardClick}
          disabled={isDisabled}
        />
      </Tooltip>
      {currentChatStatus === 'new' && isMobile ? (
        <AcceptButton t={t} theme={theme} />
      ) : (
        <Tooltip
          text={t('closeChat')}
          tipPosition="bottom"
          arrowPosition="center"
          left="-28px"
          disableTooltip={areaWidth > 450 && !searchValue}
        >
          <Button
            text={areaWidth > 450 && !searchValue ? t('closeChat') : ''}
            iconLeftProps={{
              size: 15,
              name: 'crossBubble',
              className: 'withoutHover',
            }}
            backgroundColor={theme.color.status.error}
            color={theme.color.general.white}
            disabled={isDisabled}
            className="navbarButton closeChatButton"
            confirmButtonProps={{
              onDeleteConfirm: onSendEvent('chat_closed'),
              iconLeftProps: {
                fill: theme.color.status.error,
                name: 'inputError',
              },
            }}
          />
        </Tooltip>
      )}
    </>
  )

  return (
    <StyledContainer
      className={clsx(
        !isMobile && 'withButtons',
        searchClicked && 'withDrop',
        'searchContainer searchMessageContainer'
      )}
      ref={ref}
    >
      {areaWidth > 450 ? (
        <FlexRow style={{ width: '100%' }}>
          <SearchInChatMessage
            chatId={params.chatId}
            iconLeftProps={iconLeftProps}
            placeholder={t('search')}
            placeholderColor={theme.color.general.light}
            className="searchInput"
            searchButtonClicked={searchButtonClicked}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            foundMessagesIds={foundMessagesIds}
            searchValueCount={searchValueCount}
            setSearchValueCount={setSearchValueCount}
            withoutBorder
            withoutValidation
          />
          {getActionButtons()}
        </FlexRow>
      ) : (
        <>
          {getActionButtons()}
          {searchClicked && (
            <SearchInChatMessage
              chatId={params.chatId}
              iconLeftProps={iconLeftProps}
              searchButtonClicked={searchButtonClicked}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              foundMessagesIds={foundMessagesIds}
              searchValueCount={searchValueCount}
              setSearchValueCount={setSearchValueCount}
              placeholder={t('search')}
              placeholderColor={theme.color.general.light}
              className="searchInput dropSearch"
              withoutValidation
              withoutBorder
            />
          )}
        </>
      )}
    </StyledContainer>
  )
}

export default ChatNavbar
