import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

import App from './App'
import ReactHelmet from './wrappers/ReactHelmet'
import GlobalStyle from './globalStyles'
import configureStore from './configureStore'
import theme from './theme'
import './i18n'
import 'react-datepicker/dist/react-datepicker.css'
import { unregister } from './registerServiceWorker'

unregister() // TODO: do we need this to host loader.js file?

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ReactHelmet title="appTitle" content="appDescription" />
      <GlobalStyle />
      <App theme={theme} />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
