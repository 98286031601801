import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Link } from '../../../atomic-design-components'
import Form from '../../../components/Form'
import StripedRowsBlock from '../../../components/StripedRowsBlock'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import { getOpenData, getTableData, upsertSidebarItem } from '../../../redux-saga/actions'
import { selectMainData, selectOpenData, selectSidebarDetails } from '../../../redux-saga/selectors'
import { transformDateToClientTimezone } from '../../../utils/dates'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { transformDataToBeSent } from '../../../utils/transformDataToBeSent'

const FormBlockWrapper = ({ children, blockKey, id, formValues, theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')

  useEffect(() => {
    if (formValues?.country) {
      dispatch(getOpenData({ type: 'countries', queryParams: [{ key: 'code', value: formValues?.country }] }))
    }
  }, [formValues?.country])

  const countries = useMappedState(selectOpenData('countries'))

  if (blockKey === 'cameFrom') {
    const getCameFromBlockData = () => {
      const timezoneToShow = formValues?.timezone === 'Europe/Kiev' ? 'Europe/Kyiv' : formValues.timezone

      return {
        timeZone: timezoneToShow,
        firstSeen: transformDateToClientTimezone(formValues.first_message_at, formValues.timezone),
        lastSeen: transformDateToClientTimezone(formValues.last_message_at, formValues.timezone),
        cameFrom:
          formValues.origin && formValues.channel === 'live_chat' ? (
            <Link
              isOuterLink
              href={formValues.origin}
              text={formValues.origin.endsWith('/') ? formValues.origin.slice(0, -1) : formValues.origin}
              noStyles
            />
          ) : (
            formValues.channel
          ),
        browserLanguage: formValues.browser_lang,
        browser: formValues.browser,
        OS: formValues.device_os,
        device: t(formValues.device),
        country: countries?.[formValues.country],
      }
    }

    return <StripedRowsBlock data={getCameFromBlockData()} />
  }

  return children
}

const ContactEdit = ({ page }) => {
  const dispatch = useDispatch()
  const formDataInitial = useMappedState(selectSidebarDetails)

  const location = useLocation()

  const tags = useMappedState(selectMainData('tags'))
  useEffect(() => {
    dispatch(getTableData({ type: 'tags', isSystemData: true }))
  }, [])

  // const { action, rightPanelId } = useParams()

  // if ((page === 'chats' && (!rightPanelId || action !== 'contact')) || (page === 'contacts' && !action)) {
  //   return null
  // }

  const onFormSubmit = (valuesChanged) => {
    const newValues = transformDataToBeSent(valuesChanged)

    if (!isObjectEmpty(newValues)) {
      if (newValues.tags === null) {
        newValues.tags = []
      }

      dispatch(
        upsertSidebarItem({
          id: formDataInitial.id,
          type: 'contacts',
          requestBody: newValues,
          location,
        })
      )
    }
  }

  return (
    <Form
      FormBlockWrapper={FormBlockWrapper}
      optionsData={{ tags }}
      initialValues={formDataInitial}
      formConfig={FORM_CONFIGS.contacts}
      validationRules={FORM_CONFIGS.contacts.validationRules}
      onSubmit={onFormSubmit}
      isSubmitOnBlur
    />
  )
}

export default ContactEdit
