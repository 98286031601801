import React from 'react'
import ReactTexty from 'react-texty'

import { Tag } from '../../../atomic-design-components'
import theme from '../../../theme'
import { isTableCellArrayEmpty } from '../../../utils/table'

const TagsCell = ({ cellData, rowData }) => {
  if (!cellData?.length) {
    return null
  }

  return (
    <ReactTexty>
      {cellData.map((tag) => {
        if (!tag?.name && typeof tag !== 'string') {
          return null
        }

        return (
          <Tag
            backgroundColor={theme.color.primary.lightest}
            color={theme.color.primary.main}
            text={tag?.name || (typeof tag === 'string' && tag)}
            key={tag?.name || (typeof tag === 'string' && tag)}
            fontSize={10}
            lineHeight={13}
            fontWeight={500}
          />
        )
      })}
    </ReactTexty>
  )
}

export default TagsCell
