import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ChatForward from './ChatForward'
import ChatTemplates from './ChatTemplates'

const ChatActionPanel = () => {
  const { action } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!['forward', 'templates'].includes(action)) {
      navigate('..')
    }
  }, [action])

  if (action === 'forward') {
    return <ChatForward />
  }

  if (action === 'templates') {
    return <ChatTemplates />
  }

  return null
}

export default ChatActionPanel
