import styled from 'styled-components'

import { Link } from '../../atomic-design-components'
import ExpansionPanel from '../../atomic-design-components/organisms/ExpansionPanel'

export const StyledForm = styled.div`
  form {
    display: flex;
    flex-direction: column;

    .firstInOrder {
      order: -2;
    }
    .secondInOrder {
      order: -1;
    }
  }

  .wrapper {
    margin-bottom: 25px;
  }

  .checkbox.formCheckbox {
    margin-bottom: 5px !important;
  }

  .isHidden {
    display: none;
  }

  .formBlock {
    .infoIcon {
      display: inline-flex;
      margin-left: 10px;
      border: 1px solid ${({ theme }) => theme.color.general.dark};
      path {
        fill: ${({ theme }) => theme.color.general.dark};
      }
    }
    &.clientFilters .selectWrapper {
      .react-select__value-container .input {
        display: none;
      }
      &.focused {
        .react-select__option,
        .react-select__value-container {
          .icon path {
            stroke: none;
          }
        }
      }
    }
  }
`

export const StyledInputWrapper = styled.div`
  position: relative;

  &.password ~ ul .inputWrapper:not(.hasError) {
    margin-bottom: 10px;
  }

  &.row {
    width: ${({ width }) => width};
    flex-grow: 1;
    margin: 0 5px 0 5px;
    &:first-child {
      margin-left: 0;
    }

    .formInput {
      margin: 0;
      padding: 0;
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

export const StyledFormButtons = styled.div`
  background-color: ${({ theme }) => theme.color.general.white};
  margin: 0 20px;

  button {
    .icon {
      margin-right: 6px;
    }
  }

  &.sticky {
    left: 20px;
    right: 20px;
    position: sticky;
    bottom: 0;
    z-index: 8;
    padding-bottom: 20px;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
      left: 0;
      right: 0;
    }
  }

  .stickyButtonsWrapper {
    border-radius: 5px;
    display: flex;

    & button:not(:first-child) {
      margin-left: 10px;
    }

    .cancelButton {
      flex-shrink: 2;
    }
  }
`

export const StyledAdditionToInputLink = styled(Link)`
  position: absolute;
  top: -8px;
  right: 9px;
  z-index: 2;
  background-color: #ffffff;
  padding: 0 7px;
  border-radius: 7.5px;
  a {
    font-size: 12px;
    line-height: 15px;
  }
`

export const StyledExpansionPanel = styled(ExpansionPanel)`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.color.general.lighter};

  &:first-child {
    border: none;
  }

  padding: 20px;

  &.hidden {
    display: none;
  }
`
