import { useState } from 'react'

const BUTTON_EXTENDED = {
  widgets: { maxWidth: 1030 },
  customers: { uk: { maxWidth: 1300 }, en: { maxWidth: 1050 } },
}

export const useTableModeAndSize = (topHeight, gap, gridRef, gridColCount, type, hasCardMode, currLng) => {
  const [tableMode, setTableMode] = useState('table')
  const [isActionButtonsExtended, setIsActionButtonsExtended] = useState(true)

  const getMaxTableHeight = (rowHeight, tableHeaderHeight) => {
    if (typeof window !== 'undefined' && topHeight) {
      if (tableMode === 'table') {
        return window.innerHeight - topHeight - tableHeaderHeight - gap
      } else if (tableMode === 'cards') {
        return window.innerHeight - topHeight - gap
      }
    }

    return null
  }

  const onListResize = (width) => {
    if (gridRef?.current) {
      if (gridRef.current.resetAfterColumnIndex) {
        gridRef.current.resetAfterColumnIndex(0)
      }
      if (gridRef.current.resetAfterRowIndex) {
        gridRef.current.resetAfterRowIndex(0)
      }
    }

    if (width > 650) {
      setTableMode('table')
    }
  }

  const onTableResize = ({ width }) => {
    if (hasCardMode && width < 650) {
      setTableMode('cards')
    }
    const maxWidth = BUTTON_EXTENDED[type]?.maxWidth
      ? BUTTON_EXTENDED[type]?.maxWidth
      : BUTTON_EXTENDED[type]?.[currLng]?.maxWidth

    if (width < maxWidth) {
      setIsActionButtonsExtended(false)
    } else {
      setIsActionButtonsExtended(true)
    }
  }

  return { tableMode, onTableResize, onListResize, getMaxTableHeight, isActionButtonsExtended }
}
