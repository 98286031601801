import React from 'react'
import { PropTypes as T } from 'prop-types'
import styled from 'styled-components'

const StyledRow = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  padding: ${({ padding }) => padding};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: ${({ gap }) => gap};
  margin: ${({ margin }) => margin};

  &.squezzyBlock {
    position: relative;
  }
`

const FlexRow = ({
  alignItems,
  children,
  className,
  style,
  justifyContent,
  flexDirection,
  padding,
  flexWrap,
  gap,
  margin,
  onClick,
}) => {
  return (
    <StyledRow
      className={className}
      justifyContent={justifyContent}
      alignItems={alignItems}
      padding={padding}
      flexWrap={flexWrap}
      flexDirection={flexDirection}
      gap={gap}
      style={style}
      margin={margin}
      onClick={onClick}
    >
      {children}
    </StyledRow>
  )
}

export default FlexRow

FlexRow.propTypes = {
  alignItems: T.oneOf(['start', 'end', 'center', 'stretch']),
  children: T.node,
  justifyContent: T.oneOf([
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
  ]),
  flexWrap: T.oneOf(['wrap', 'nowrap']),
  padding: T.string,
  gap: T.string,
  flexDirection: T.oneOf(['column', 'row']),
}

FlexRow.defaultProps = {
  alignItems: 'center',
  justifyContent: 'start',
  flexDirection: 'row',
}
