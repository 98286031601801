import React from 'react'

export const smile = ({ width, height, fill, stroke, theme: { color } }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_11190_75437)">
      <path
        d="M8.00084 15.4287C12.1036 15.4287 15.4294 12.1028 15.4294 8.0001C15.4294 3.89742 12.1036 0.571533 8.00084 0.571533C3.89815 0.571533 0.572266 3.89742 0.572266 8.0001C0.572266 12.1028 3.89815 15.4287 8.00084 15.4287Z"
        stroke={stroke || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.22852 9.14282C4.79994 11.2 7.08566 12.4571 9.1428 11.8857C10.3999 11.4285 11.4285 10.4 11.7714 9.14282"
        stroke={stroke || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.48493 6.22866C5.32714 6.22866 5.19922 6.10074 5.19922 5.94294C5.19922 5.78515 5.32714 5.65723 5.48493 5.65723"
        stroke={stroke || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.48633 6.22866C5.64412 6.22866 5.77204 6.10074 5.77204 5.94294C5.77204 5.78515 5.64412 5.65723 5.48633 5.65723"
        stroke={stroke || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5142 6.22866C10.3564 6.22866 10.2285 6.10074 10.2285 5.94294C10.2285 5.78515 10.3564 5.65723 10.5142 5.65723"
        stroke={stroke || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5137 6.22866C10.6715 6.22866 10.7994 6.10074 10.7994 5.94294C10.7994 5.78515 10.6715 5.65723 10.5137 5.65723"
        stroke={stroke || color.general.black}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11190_75437">
        <rect width="16" height="16" fill={fill || color.general.white} />
      </clipPath>
    </defs>
  </svg>
)
