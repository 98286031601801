import React from 'react'

export const teamChat = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M17.5431 1.08594H2.45737C2.09364 1.08594 1.74481 1.23043 1.48762 1.48762C1.23043 1.74481 1.08594 2.09364 1.08594 2.45737V14.1145C1.08594 14.4782 1.23043 14.8271 1.48762 15.0843C1.74481 15.3414 2.09364 15.4859 2.45737 15.4859H5.20022V18.9145L9.47908 15.4859H17.5431C17.9068 15.4859 18.2556 15.3414 18.5128 15.0843C18.77 14.8271 18.9145 14.4782 18.9145 14.1145V2.45737C18.9145 2.09364 18.77 1.74481 18.5128 1.48762C18.2556 1.23043 17.9068 1.08594 17.5431 1.08594Z"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="5" y1="5.40039" x2="7.8" y2="5.40039" stroke={fill || color.general.white} strokeWidth="1" strokeLinecap="round" />
    <line x1="11" y1="5.40039" x2="15" y2="5.40039" stroke={fill || color.general.white} strokeWidth="1" strokeLinecap="round" />
    <line x1="5" y1="8.40039" x2="10.2" y2="8.40039" stroke={fill || color.general.white} strokeWidth="1" strokeLinecap="round" />
    <line
      x1="13.4004"
      y1="8.40039"
      x2="15.0004"
      y2="8.40039"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
    />
    <line x1="5" y1="11.4004" x2="5.4" y2="11.4004" stroke={fill || color.general.white} strokeWidth="1" strokeLinecap="round" />
    <line
      x1="8.59961"
      y1="11.4004"
      x2="10.1996"
      y2="11.4004"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
    />
    <line
      x1="13.4004"
      y1="11.4004"
      x2="15.0004"
      y2="11.4004"
      stroke={fill || color.general.white}
      strokeWidth="1"
      strokeLinecap="round"
    />
  </svg>
)
