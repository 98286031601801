import React from 'react'
import { useTranslation } from 'react-i18next'

import TemplatesListSearch from '../components/TemplatesListSearch'
import TemplatesList from '../components/TemplatesList'
import { Button } from '../../../atomic-design-components'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectCurrentSubscription } from '../../../redux-saga/selectors'

const TemplatesLeftPanel = ({ templates, variant = 'templates', onAddTemplate, className }) => {
  const { t } = useTranslation('all')

  const planData = useMappedState(selectCurrentSubscription)
  const { limits, counts } = planData || {}
  const templatesTotal = counts?.templates

  const isAddingNewAllowedByBilling = !templatesTotal || templatesTotal < limits?.templates

  return (
    <>
      {variant === 'templates' && (
        <>
          <TemplatesListSearch variant={variant} />
          <div style={{ padding: '20px' }}>
            <Button
              variant="primary"
              text={t('addTemplates')}
              iconLeftProps={{ name: 'plusCircle' }}
              fullWidth
              onClick={isAddingNewAllowedByBilling ? onAddTemplate : undefined}
              className={isAddingNewAllowedByBilling ? '' : 'disabled'}
              tooltipProps={
                isAddingNewAllowedByBilling
                  ? undefined
                  : {
                      tipPosition: 'bottom',
                      arrowPosition: 'center',
                      left: '0',
                      text: t('upgradeYourPlanToAdd'),
                    }
              }
            />
          </div>
        </>
      )}
      {templates.map((template) => {
        const templateType = Object.keys(template)[0]

        return (
          <TemplatesList
            variant={variant}
            key={templateType}
            type={templateType}
            t={t}
            templateData={template[templateType]}
            className={className}
          />
        )
      })}
    </>
  )
}

export default TemplatesLeftPanel
