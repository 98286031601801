import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { withTheme } from 'styled-components'

import MyCompany from '../MyCompany/MyCompany'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectCurrentSubscription, selectFormValues } from '../../redux-saga/selectors'
import { PreviewContainer } from './widgetPreview/PreviewContainer'
import { ScreenContext } from '../../hooks/useScreenContext'
import { isObjectEmpty } from '../../utils/isObjectEmpty'

const ChatWidgetCustomisation = () => {
  const { width } = useContext(ScreenContext)

  const formValues = useMappedState(selectFormValues)

  const planData = useMappedState(selectCurrentSubscription)
  const { limits } = planData || {}
  const isCustomizationAllowedByBilling = limits?.is_customization // subscription_status === 'active' &&

  if (!planData) {
    return null
  }

  if (!isObjectEmpty(planData) && !isCustomizationAllowedByBilling) {
    return <Navigate replace to="/404" />
  }

  // const widgetSettings = useMappedState(selectSidebarDetails)

  // privacy_policy?.document_url
  // privacy_policy?.is_required
  // departments?.is_selected
  // departments?.values

  return (
    <MyCompany RightPanelComponent={<Outlet />} className="withBackIcon">
      {width > 900 ? <PreviewContainer formValues={formValues} /> : <div></div>}
    </MyCompany>
  )
}

export default withTheme(ChatWidgetCustomisation)
