import React from 'react'

export const typePDF = ({ width, height, fill, theme: { color } }) => {
  return (
    <svg width={width || 30} height={height || 36} viewBox="0 0 30 36" fill="none">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H18L30 12V33C30 34.6569 28.6569 36 27 36H3C1.34315 36 0 34.6569 0 33V3Z"
        fill="#E83C0C"
      />
      <path d="M30 12H21C19.3431 12 18 10.6569 18 9V0L30 12Z" fill="#D30E01" />
      <path
        d="M6.74907 29V22.4545H9.33145C9.8279 22.4545 10.2508 22.5494 10.6003 22.739C10.9497 22.9265 11.216 23.1875 11.3993 23.522C11.5847 23.8544 11.6773 24.2379 11.6773 24.6726C11.6773 25.1072 11.5836 25.4908 11.3961 25.8232C11.2086 26.1555 10.9369 26.4144 10.5811 26.5998C10.2274 26.7852 9.79914 26.8778 9.2963 26.8778H7.65035V25.7688H9.07258C9.33891 25.7688 9.55837 25.723 9.73096 25.6314C9.90567 25.5376 10.0356 25.4087 10.1209 25.2447C10.2082 25.0785 10.2519 24.8878 10.2519 24.6726C10.2519 24.4553 10.2082 24.2656 10.1209 24.1037C10.0356 23.9396 9.90567 23.8129 9.73096 23.7234C9.55624 23.6317 9.33465 23.5859 9.06618 23.5859H8.13295V29H6.74907ZM14.8965 29H12.5762V22.4545H14.9157C15.5741 22.4545 16.1408 22.5856 16.616 22.8477C17.0911 23.1076 17.4565 23.4815 17.7122 23.9695C17.97 24.4574 18.0989 25.0412 18.0989 25.7209C18.0989 26.4027 17.97 26.9886 17.7122 27.4787C17.4565 27.9687 17.089 28.3448 16.6096 28.6069C16.1323 28.869 15.5613 29 14.8965 29ZM13.9601 27.8143H14.839C15.2481 27.8143 15.5922 27.7418 15.8713 27.5969C16.1526 27.4499 16.3635 27.223 16.5041 26.9162C16.6469 26.6072 16.7183 26.2088 16.7183 25.7209C16.7183 25.2372 16.6469 24.842 16.5041 24.5352C16.3635 24.2283 16.1536 24.0025 15.8745 23.8576C15.5954 23.7127 15.2513 23.6403 14.8422 23.6403H13.9601V27.8143ZM19.1241 29V22.4545H23.4579V23.5955H20.5079V25.1552H23.1702V26.2962H20.5079V29H19.1241Z"
        fill="white"
      />
    </svg>
  )
}
