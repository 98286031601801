import { RESET_FORM_VALUES, SET_FORM_VALUES, SIGN_OUT } from '../constants'

const defaultState = {
  formValues: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FORM_VALUES:
      return {
        ...state,
        [action.payload.name || 'formValues']: { ...action.payload.values },
      }
    case RESET_FORM_VALUES:
      return {
        ...state,
        [action.payload.name || 'formValues']: {},
      }
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
