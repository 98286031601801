import React from 'react'

import { FlexRow, Typography } from '../../atomic-design-components'
import { StyledIcon, StyledPic, StyledUserpicsMore } from './styled'

const getImageConfig = (item, isNotActive) => {
  const IMAGES_CONFIG = {
    members: {
      component: (props) => <StyledPic {...props} />,
      props: {
        iconWidth: 14,
        iconHeight: 14,
        onlineState: item.onlineState,
        src: item.profile_picture,
      },
    },
    channels: {
      component: (props) => <StyledIcon {...props} />,
      props: { name: `${item}Icon`, fill: isNotActive ? '#9D9D9D' : '' },
    },
    messengers: {
      component: (props) => <StyledIcon {...props} />,
      props: { name: `${item}Icon` },
    },
  }
  return IMAGES_CONFIG
}

const SqueezeImageBlock = ({ cellData, rowData, type }) => {
  const dataArray = (Array.isArray(cellData) ? cellData : Object.keys(cellData)).filter(
    (item) => item !== 'whatsapp'
  )

  //do not show whatsapp now (waiting to implement)
  if (dataArray.includes('whatsapp')) dataArray.splice(dataArray.indexOf('whatsapp'), 1)

  return (
    <FlexRow className="squezzyBlock">
      {dataArray.map((item, i) => {
        const isNotActive =
          type === 'channels'
            ? rowData.status === 'active'
              ? cellData[item].status === 'inactive'
              : true
            : true

        if (i < 6) {
          return getImageConfig(item)[type].component({
            i,
            key: i,
            height: 28,
            width: 28,
            className: 'squeezeIcon',
            ...getImageConfig(item, isNotActive)[type].props,
          })
        } else if (i === 6) {
          return (
            <StyledUserpicsMore i={i} key={i} className="squeezeIcon">
              <Typography text={`+${cellData.length - 6}`} variant="caption3" />
            </StyledUserpicsMore>
          )
        }
      })}
    </FlexRow>
  )
}

export default SqueezeImageBlock
