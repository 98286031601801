import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import merge from 'deepmerge'

import { Button } from '../../../atomic-design-components'
import Form from '../../../components/Form'
import { DEFAULT_VALUES_DATA } from '../../../config/defaultValues'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks/useReactRedux'
import { upsertSidebarItem } from '../../../redux-saga/actions'
import { selectMainData, selectSidebarDetails, selectSidebarMetaData } from '../../../redux-saga/selectors'
import { mapSelectObjectValuesToIds } from '../../../utils/dataTransformations'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { useNavigate } from 'react-router-dom'

const StyledSubscriptionPlansEdit = styled.div`
  .formBlock {
    .opened {
      margin-bottom: 15px;
    }
  }
`

const FormBlockWrapper = withTheme(({ children }) => {
  return children
})

const SubscriptionPlansEdit = ({ theme }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('all')

  const initialValues = DEFAULT_VALUES_DATA.subscriptionPlans
  const formDataInitial = useMappedState(selectSidebarDetails) || DEFAULT_VALUES_DATA.subscriptionPlans
  const customers = useMappedState(selectMainData('customers')) || []

  const { inProgress, error } = useMappedState(selectSidebarMetaData)
  const isFormDisabled = formDataInitial?.id && !formDataInitial?.customers?.[0]?.id

  const onFormSubmit = (valuesChanged) => {
    let newValues = mapSelectObjectValuesToIds(
      valuesChanged,
      formDataInitial?.id ? formDataInitial : initialValues
    )
    if ('customers' in valuesChanged) {
      newValues.customer_ids = newValues.customers || []
      delete newValues.customers
    }

    if ('prices' in valuesChanged) {
      newValues.prices = { ...merge(formDataInitial.prices, newValues.prices) }
    }

    if ('titles' in valuesChanged) {
      newValues.titles = { ...merge(formDataInitial.titles, newValues.titles) }
    }

    if (!formDataInitial?.id && !valuesChanged.status) {
      newValues.status = 'active'
    }

    if (!isObjectEmpty(newValues)) {
      dispatch(
        upsertSidebarItem({
          id: formDataInitial?.id,
          type: 'subscriptionPlans',
          requestBody: newValues,
          navigate,
        })
      )
    }
  }

  return (
    <StyledSubscriptionPlansEdit>
      <Form
        initialValues={formDataInitial || initialValues}
        formConfig={FORM_CONFIGS.subscriptionPlans}
        validationRules={FORM_CONFIGS.subscriptionPlans.validationRules}
        initialValuesChanged={!inProgress && !formDataInitial.id && { status: 'active' }}
        getFormButtons={() => (
          <Button
            type="submit"
            fullWidth
            disabled={inProgress}
            text={t('save')}
            iconLeftProps={{
              name: 'floppy',
              fill: theme.color.general.white,
              stroke: theme.color.general.white,
            }}
          />
        )}
        onSubmit={onFormSubmit}
        expansionPanelProps={{
          disabled: true,
          withChevronInHeader: false,
        }}
        type="subscriptionPlans"
        isSubmitOnBlur={formDataInitial?.id}
        FormBlockWrapper={FormBlockWrapper}
        isReadOnly={isFormDisabled}
        optionsData={{ customers }}
      />
    </StyledSubscriptionPlansEdit>
  )
}

export default withTheme(SubscriptionPlansEdit)
