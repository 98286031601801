import styled from 'styled-components'

export const StyledTooltip = styled.div`
  position: relative;
  .tooltip {
    position: absolute;
    background: ${({ color, theme }) => color || theme.color.general.black};
    color: ${({ textColor, theme }) => textColor || theme.color.general.white};
    visibility: hidden;
    padding: 10px 12px;
    border-radius: 5px;
    z-index: 10;
    min-width: 100px;
    text-align: center;
    white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};
    left: ${({ left }) => left};
    right: ${({ right }) => right};
    width: ${({ width }) => width};

    &.bottom {
      top: 120%;
      .tooltip-arrow {
        bottom: 100%;
      }
    }
    &.top {
      bottom: 120%;
      .tooltip-arrow {
        top: 100%;
        border-color: ${({ color, theme }) => color || theme.color.general.black} transparent transparent;
      }
    }
  }

  .tooltip-arrow {
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ color, theme }) => color || theme.color.general.black};

    &.left {
      left: 10%;
    }
    &.center {
      left: 45%;
    }
    &.right {
      right: 10%;
    }
  }
`
