import React from 'react'

import { FlexRow, Typography } from '../../../atomic-design-components'
import { StyledCardContent } from './styled'

// you should use name of column keys from config to get data here (not dataKeys): data.key

const DepartmentsTableCard = ({ cellProps, editMode, index, onClick, data, columns, t, theme }) => {
  const isInactive = data?.status?.props?.dataTooltip === 'Inactive'

  return (
    <StyledCardContent className={isInactive ? 'inactive' : ''}>
      <FlexRow className="cardTableItemRow">
        <FlexRow>
          {data.status}
          <Typography variant="button1" text={data.name} />
        </FlexRow>
        {data.members}
      </FlexRow>
    </StyledCardContent>
  )
}

export default DepartmentsTableCard
