import React from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledWidgetCustomize, StyledWidgetCustomizedContainer, StyledPreviewIcon } from '../../styled'
import introPurple from '../../../../assets/images/introPurple.jpg'
import { isObjectEmpty } from '../../../../utils/isObjectEmpty'
import { Loader } from '../../../../atomic-design-components'
import { PreChatForm } from '../PreChatForm'
import { ChooseChannels } from '../ChooseChannels'

const PreviewContainer = ({ formValues }) => {
  const { t } = useTranslation('all')

  const {
    color,
    is_hide_branding,
    button_style,
    is_form_displayed,
    form_fields,
    departments,
    privacy_policy,
  } = formValues

  const inProgress = isObjectEmpty(formValues)

  return (
    <StyledWidgetCustomize url={introPurple}>
      {inProgress ? (
        <Loader size="60" />
      ) : (
        <StyledWidgetCustomizedContainer>
          {is_form_displayed ? (
            <PreChatForm
              color={color?.color || color}
              is_hide_branding={is_hide_branding}
              getT={t}
              className="preChatForm"
              form_fields={form_fields}
              departments={departments}
              privacy_policy={privacy_policy}
            />
          ) : (
            <ChooseChannels
              color={color?.color || color}
              channels={{
                telegram: { status: 'active' },
                viber: { status: 'active' },
                facebook: { status: 'active' },
                instagram: { status: 'active' },
              }}
              getT={t}
              is_hide_branding={is_hide_branding}
            />
          )}

          <StyledPreviewIcon name={`${button_style}Widget`} fill={color?.color || color} />
        </StyledWidgetCustomizedContainer>
      )}
    </StyledWidgetCustomize>
  )
}

export default withTheme(PreviewContainer)
