import React from 'react'
import { withTheme } from 'styled-components'

import { useDispatch } from 'react-redux'
import { FlexRow, HeaderMenuIcon } from '../../atomic-design-components'
import { NAV_MENU_COLORS } from '../../constants'
import { useMappedState } from '../../hooks/useReactRedux'
import { toggleNavMenuExtended } from '../../redux-saga/actions'
import { selectIsNavMenuExtended } from '../../redux-saga/selectors'
import ErrorBoundary from '../../wrappers/ErrorBoundary'
import Logo from '../Logo'
import NavMenu from '../NavMenu'
import MenuItem from '../NavMenu/MenuItem'
import { StyledHeaderMobile } from './styled'

const HeaderMobile = ({ colorScheme, isTablet, isRevert, setIsNavMenuExtended, theme }) => {
  const dispatch = useDispatch()
  const isNavMenuExtended = useMappedState(selectIsNavMenuExtended)
  const onIconClick = () => {
    dispatch(toggleNavMenuExtended())
  }

  return (
    <>
      <StyledHeaderMobile colorScheme={colorScheme} className={isNavMenuExtended && 'extended'}>
        <FlexRow justifyContent="space-between">
          <Logo
            theme={theme}
            variant="small"
            isExtended
            hasMiniLogo
            logoColor={theme.color.general.black}
            to="../chats"
            className="headerMobileLogo"
          />
          <MenuItem iconName="chats" to="/chats" />
          <HeaderMenuIcon
            className="menuIcon"
            opened={isNavMenuExtended}
            onClick={onIconClick}
            fill={NAV_MENU_COLORS[colorScheme].dark || theme.color.general.white}
          />
        </FlexRow>
      </StyledHeaderMobile>
      <ErrorBoundary>
        <NavMenu
          isMobile
          isExtendedProp={isNavMenuExtended}
          setIsExtendedProp={setIsNavMenuExtended}
          colorScheme={colorScheme}
          isTablet={isTablet}
          isRevert={isRevert}
          onClick={onIconClick}
        />
      </ErrorBoundary>
    </>
  )
}

export default withTheme(HeaderMobile)
