import React from 'react'
import { PropTypes as T } from 'prop-types'

import { StyledRating } from './styled'
import { Icon, Typography } from '../../index'
import theme from '../../../theme'

const RatingView = (props) => {
  const { value, fill, rowData, cellData } = props

  return (
    <StyledRating>
      <Icon
        name="starSign"
        fill={(value || cellData) === 0 ? theme.color.general.lightest : fill}
        stroke={(value || cellData) === 0 ? theme.color.general.lighter : theme.color.primary.lighter}
      />
      <Typography variant="button2" text={value || cellData} margin="0 0 0 8px" />
    </StyledRating>
  )
}

export default RatingView

RatingView.propTypes = {
  fill: T.string,
  value: T.oneOfType([T.string, T.number]),
  rowData: T.object,
  cellData: T.oneOfType([T.string, T.number]),
}
