import styled from 'styled-components'

export const StyledNotifications = styled.div`
  max-width: 660px;
  margin: 30px auto;
  padding: 0 40px;

  .headerWrapper {
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    padding-bottom: 15px;
    width: 100%;
    
    &:nth-of-type(2) {
      margin-top: 30px;
    }
  }
  .notificationItem {
    margin: 30px 0 10px;
  }
  .notificationSwitch {
    gap: 10px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin: 20px;
  }
`
