import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import TableSearch from '../../../atomic-design-components/organisms/Table/components/TableSearch'
import theme from '../../../theme'
import { StyledChatsNotFoundRow, StyledContainer } from '../components/styled'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { getChatsList, getChatsListNotClosed } from '../../../redux-saga/actions'
import { useDispatch } from 'react-redux'
import { useMappedState } from '../../../hooks/useReactRedux'
import { Icon, Typography } from '../../../atomic-design-components'
import { selectIsNoChatsFound } from '../../../redux-saga/selectors'
import { SEARCH_VALUE_MIN_LENGTH } from '../../../constants'

const ChatsListSearch = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')

  const iconLeftProps = { name: 'searchSign', fill: theme.color.general.dark }
  const iconRightProps = { name: 'cross', size: 16 }
  const noChatsFound = useMappedState(selectIsNoChatsFound)

  const { search } = useLocation()
  const searchParsed = parse(search)
  const searchValue = searchParsed.searchValue ?? ''
  // const prevSearchValue = usePrevious(searchValue)

  useEffect(() => {
    if (searchValue.length >= SEARCH_VALUE_MIN_LENGTH) {
      dispatch(getChatsListNotClosed(searchValue))
      dispatch(getChatsList('closed', [], false, searchValue))
    }

    if (!searchValue || searchValue.length < SEARCH_VALUE_MIN_LENGTH) {
      dispatch(getChatsListNotClosed())
      dispatch(getChatsList('closed'))
    }
  }, [searchValue])

  return (
    <>
      <StyledContainer className="searchContainer">
        <TableSearch
          iconLeftProps={iconLeftProps}
          iconRightProps={iconRightProps}
          placeholder={t('search')}
          placeholderColor={theme.color.general.light}
          className="searchInput"
          withoutBorder
          withoutValidation
        />
      </StyledContainer>
      {searchValue && noChatsFound && (
        <StyledChatsNotFoundRow>
          <Icon name="inputError" fill={theme.color.general.dark} />
          <Typography variant="body3" color={theme.color.general.dark}>
            {t('nothingFound')}
          </Typography>
        </StyledChatsNotFoundRow>
      )}
    </>
  )
}

export default ChatsListSearch
