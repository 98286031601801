import React from 'react'

export const creditCard = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_11636_42183)">
      <path
        d="M17.5431 3.48633H2.45737C1.69995 3.48633 1.08594 4.10034 1.08594 4.85776V15.1435C1.08594 15.9009 1.69995 16.5149 2.45737 16.5149H17.5431C18.3005 16.5149 18.9145 15.9009 18.9145 15.1435V4.85776C18.9145 4.10034 18.3005 3.48633 17.5431 3.48633Z"
        stroke={fill || color.general.darker}
        strokeWidth="1.14"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08594 8.28711H18.9145"
        stroke={fill || color.general.darker}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4287 13.0859H15.4859"
        stroke={fill || color.general.darker}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11636_42183">
        <rect width="19.2" height="19.2" fill="white" transform="translate(0.400391 0.400391)" />
      </clipPath>
    </defs>
  </svg>
)
