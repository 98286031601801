import React from 'react'
import ReactTexty from 'react-texty'

import { Icon, RatingView, Tooltip, Typography } from '../atomic-design-components'
import NameWithAvatar from '../components/NameWithAvatar'
import NameWithTag from '../components/NameWithTag'
import SqueezeImageBlock from '../components/SqueezeImageBlock'
import EmailWithCopyOnClick from '../components/TableBlock/components/EmailWithCopyOnClick'
import PlanPeriodEndDate from '../components/TableBlock/components/PlanPeriodEndDate'
import TagsCell from '../components/TableBlock/components/TagsCell'
import {
  BULK_MESSAGE_STATUSES_ICONS,
  CURRENCY,
  CUSTOMERS_ADDITIONAL_STATUS_ICON,
  PAYMENTS_STATUSES_ICONS,
  STATUSES_ICONS,
} from '../constants'
import ChatsTableCell from '../containers/Contacts/components/ChatsTableCell'
import ChatWidgetActions from '../containers/MyCompanyChatWidgets/components/ChatWidgetActions'
import theme from '../theme'
import { getPlanPrice } from '../utils/dataTransformations'
import { transformDate, transformDateToClientTimezone, transformUtcDateToISOWithTZ } from '../utils/dates'
import { getAvailableTranslation } from '../utils/getAvailableTranslation'
import { isTableCellArrayEmpty } from '../utils/table'

const getDummyCol = (t) => ({
  key: '',
  dataKey: '',
  width: 0,
  flexGrow: 1,
  sortable: true,
  headerRenderer: headerRenderer(t),
  cellRenderer: ({ cellData, columns, column, columnIndex, rowData, rowIndex, container, isScrolling }) =>
    null,
})

const headerRenderer =
  (t) =>
  // eslint-disable-next-line react/display-name
  ({ columns, column, columnIndex, headerIndex, container }) =>
    (
      <Typography as={ReactTexty} variant="body2">
        {t(column.key) || t(column.title)}
      </Typography>
    )

const getStatusColumnProps = (t) => ({
  key: 'status',
  dataKey: 'status',
  width: 80,
  flexShrink: 0,
  resizable: false,
  sortable: true,
  className: 'statusIcons',
  headerRenderer: headerRenderer(t),
  cellRenderer: ({ cellData, column }) => {
    const allStatusesIcons = {
      ...STATUSES_ICONS,
      ...CUSTOMERS_ADDITIONAL_STATUS_ICON,
      ...PAYMENTS_STATUSES_ICONS,
    }
    return allStatusesIcons[cellData] ? (
      <Tooltip left="8px" text={t(`${cellData}`)} arrowPosition="left">
        <Icon
          key={column.key}
          className="statusIcon"
          // wrapperHeight={tableMode === 'list' ? '100%' : 'auto'}
          // wrapperWidth={tableMode === 'table' ? '100%' : 'auto'}
          onClick={() => {}}
          name={allStatusesIcons[cellData]}
          width={16}
          margin="0 auto 0 5px"
          padding="10px"
        />
      </Tooltip>
    ) : (
      ''
    )
  },
})
const getBulkStatusColumnProps = (t) => ({
  key: 'status',
  dataKey: 'status',
  width: 80,
  flexShrink: 0,
  resizable: false,
  sortable: true,
  className: 'statusIcons',
  headerRenderer: headerRenderer(t),
  cellRenderer: ({ cellData, column }) => {
    return BULK_MESSAGE_STATUSES_ICONS[cellData] ? (
      <Tooltip left="8px" text={t(cellData)} arrowPosition="left">
        <Icon
          key={column.key}
          className="statusIcon"
          name={BULK_MESSAGE_STATUSES_ICONS[cellData]}
          width={cellData === 'scheduled' ? 19 : 16}
          height={cellData === 'scheduled' ? 20 : 20}
          margin="0 auto 0 5px"
          padding="10px"
        />
      </Tooltip>
    ) : (
      ''
    )
  },
})

const getFullName = (t, withoutAvatar, isContact) => ({
  key: 'fullName',
  dataKey: 'full_name',
  width: 0,
  flexGrow: 1,
  sortable: true,
  cellRenderer: ({ cellData, rowData }) => (
    <NameWithAvatar
      withoutAvatar={withoutAvatar}
      name={cellData || (isContact && `${t('guest')} #${rowData.id}`)}
      avatarSrc={rowData.profile_picture}
    />
  ),
  headerRenderer: headerRenderer(t),
})

const getEmail = (t) => ({
  key: 'email',
  dataKey: 'email',
  width: 0,
  flexGrow: 1.2,
  sortable: true,
  cellRenderer: (props) => <EmailWithCopyOnClick {...props} />,
  headerRenderer: headerRenderer(t),
})

const getPlanPrices = ({ cellData, rowData }) => {
  return (
    getPlanPrice(cellData, rowData.payment_type, 'UAH', rowData.plan_id || rowData.id) ??
    (rowData.payment_type === 'manual' && '-')
  )
}

const getTableColumns = (type, t, lng) => {
  const COLUMNS_CONFIG = {
    contacts: [
      {
        key: 'channel',
        dataKey: 'channel',
        width: 85,
        // flexGrow: 0.3,
        sortable: true,
        cellRenderer: ({ cellData, column }) => {
          const channel = cellData.replace('://', '').replace(RegExp('\\d', 'g'), '')
          return <Icon width={30} height={30} margin="0 0 0 10px" name={`${channel}Icon`} />
        },
        headerRenderer: headerRenderer(t),
      },
      getFullName(t, true, true),
      getEmail(t),
      {
        key: 'tags',
        dataKey: 'tags',
        width: 0,
        flexGrow: 1.5,
        cellRenderer: (props) => <TagsCell {...props} />,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'widget',
        dataKey: 'widget_name',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'lastMessage',
        dataKey: 'last_message_at',
        width: 130,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: ({ cellData, rowData }) =>
          transformDateToClientTimezone(cellData, rowData.timezone, 'DD/MM/YYYY', true), // TODO: check timezone
      },
      {
        key: 'chats',
        dataKey: 'total_messages',
        sortable: true,
        width: 80,
        headerRenderer: headerRenderer(t),
        cellRenderer: ({ rowData, cellData }) => (
          <ChatsTableCell chatId={rowData.chat_id} messagesCount={cellData} />
        ),
      },
    ],
    bulkMessaging: [
      getBulkStatusColumnProps(t, lng),
      {
        key: 'name',
        dataKey: 'name',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'date',
        dataKey: 'scheduled_at',
        width: 0,
        flexGrow: 1.5,
        sortable: true,
        cellRenderer: (props) => transformUtcDateToISOWithTZ(props.cellData, 'DD/MM/YYYY HH:mm'),
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'messengers',
        dataKey: 'channels',
        width: 0,
        flexGrow: 1,
        cellRenderer: ({ cellData, rowData, column }) => {
          if (!cellData) {
            return null
          }
          return <SqueezeImageBlock cellData={cellData} rowData={rowData} type={column.key} />
        },
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'tags',
        dataKey: 'tags',
        width: 0,
        flexGrow: 1.5,
        cellRenderer: (props) => <TagsCell {...props} />,
        headerRenderer: headerRenderer(t),
      },
    ],
    members: [
      getStatusColumnProps(t, lng),
      getFullName(t),
      getEmail(t),
      {
        key: 'rating',
        dataKey: 'rating',
        width: 80,
        flexShrink: 0,
        sortable: true,
        cellRenderer: (props) => <RatingView fill={theme.color.primary.lightest} {...props} />,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'role',
        dataKey: 'role',
        width: 0,
        flexGrow: 1,
        sortable: true,
        cellRenderer: (props) => t(props.rowData.role),
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'departments',
        dataKey: 'departments',
        width: 0,
        flexGrow: 1,
        cellRenderer: (props) => <TagsCell {...props} />,
        headerRenderer: headerRenderer(t),
      },
    ],
    customers: [
      getStatusColumnProps(t, lng),
      {
        key: 'companyName',
        dataKey: 'alias',
        width: 0,
        flexGrow: 1,
        sortable: true,
        cellRenderer: ({ cellData, rowData }) => (
          <NameWithAvatar
            withoutAvatar={!rowData.company_logo}
            name={cellData}
            avatarSrc={rowData.company_logo}
          />
        ),
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'created',
        dataKey: 'created_at',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => transformDate(props.cellData),
      },
      {
        key: 'plan',
        dataKey: 'plan_titles',
        width: 0,
        flexGrow: 1,
        // sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => getAvailableTranslation(props.cellData, lng),
      },
      {
        key: 'planEnds',
        dataKey: 'subscription_expire_at',
        width: 0,
        flexGrow: 1,
        sortable: true,
        cellRenderer: (props) => <PlanPeriodEndDate {...props} />,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'pricing',
        dataKey: 'plan_prices',
        width: 0,
        flexGrow: 1,
        // sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: ({ cellData, rowData }) => {
          return (
            getPlanPrice(cellData, rowData.payment_type, 'UAH', rowData.plan_id || rowData.id) ??
            (rowData.payment_type === 'manual' && '-')
          )
        },
      },
      {
        key: 'usersIncluded',
        dataKey: 'total_members_count',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: ({ cellData, rowData }) => {
          const plan_total_members =
            (rowData.limits?.users || cellData) &&
            `${cellData || ''} ${rowData.limits?.users ? t('of') : ''} ${rowData.limits?.users || ''}`

          return plan_total_members
        },
        //check transformCustomersGrid
      },
      // {
      //   key: 'action',
      //   dataKey: 'id',
      //   width: 0,
      //   flexGrow: 2.5,
      //   className: 'actionButtons',
      //   cellRenderer: (props) => <CustomerActions t={t} {...props} />,
      //   headerRenderer: headerRenderer(t),
      // },
    ],
    departments: [
      getStatusColumnProps(t, lng),
      {
        key: 'name',
        dataKey: 'name',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'members',
        dataKey: 'members',
        width: 0,
        flexGrow: 2,
        cellRenderer: ({ cellData, column }) => {
          if (!cellData || isTableCellArrayEmpty(cellData)) {
            return null
          }

          return <SqueezeImageBlock cellData={cellData} type={column.key} />
        },
        headerRenderer: headerRenderer(t),
      },
    ],
    widgets: [
      getStatusColumnProps(t, lng),
      {
        key: 'name',
        dataKey: 'name',
        width: 0,
        flexGrow: 1,
        sortable: true,
        cellRenderer: ({ rowData, cellData }) => {
          return <NameWithTag rowData={rowData} data={cellData} t={t} />
        },
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'channels',
        dataKey: 'channels',
        width: 0,
        flexGrow: 1,
        cellRenderer: ({ cellData, rowData, column }) => {
          if (!cellData) {
            return null
          }
          return <SqueezeImageBlock cellData={cellData} rowData={rowData} type={column.key} />
        },
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'action',
        dataKey: 'action',
        width: 0,
        flexGrow: 1,
        className: 'actionButtons',
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => <ChatWidgetActions t={t} {...props} />,
      },
    ],
    payments: [
      getStatusColumnProps(t, lng),
      {
        key: 'companyName',
        dataKey: 'customer_name',
        width: 0,
        flexGrow: 1,
        sortable: true,
        getIsHidden: ({ role }) => {
          return role !== 'system_administrator'
        },
        cellRenderer: ({ cellData, rowData }) => (
          <NameWithAvatar
            withoutAvatar={!rowData.company_logo}
            name={cellData}
            avatarSrc={rowData.company_logo}
          />
        ),
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'created',
        dataKey: 'created_at',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => transformDate(props.cellData),
      },
      {
        key: 'plan',
        dataKey: 'titles',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => getAvailableTranslation(props.cellData, lng),
      },
      {
        key: 'paymentType',
        dataKey: 'type',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => t(props.cellData),
      },
      {
        key: 'paymentMethod',
        dataKey: 'details',
        width: 0,
        flexGrow: 2,
        cellRenderer: (props) => {
          return props.cellData?.cardPan + (props.cellData?.cardType ? ` (${props.cellData?.cardType})` : '')
        },
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'pricing',
        dataKey: 'amount',
        width: 0,
        flexGrow: 1,
        // sortable: true,
        cellRenderer: (props) => `${props.rowData.props || CURRENCY.UAH} ${props.cellData}`,
        headerRenderer: headerRenderer(t),
      },
    ],
    subscriptionPlans: [
      getStatusColumnProps(t, lng),
      {
        key: 'planTitle',
        dataKey: 'titles',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => getAvailableTranslation(props.cellData, lng),
      },
      {
        key: 'details',
        dataKey: 'features',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: ({ cellData }) => (
          <Typography as={ReactTexty} variant="body3" fontSize="13px">
            {`${t('widgets')} ${cellData.widgets}, ${t('users')} ${cellData.users}, ${t('templates')} ${
              cellData.templates
            }`}
          </Typography>
        ),
      },
      {
        key: 'customers',
        dataKey: 'customers',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t),
        cellRenderer: ({ cellData }) => (
          <Typography as={ReactTexty} variant="body3" fontSize="13px">
            {cellData.map((customer) => customer.alias).join(', ') || '-'}
          </Typography>
        ),
      },
      {
        key: 'pricing',
        dataKey: 'prices',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) =>
          `${CURRENCY.UAH} ${props.cellData.price_monthly.UAH || 0} / ${
            props.cellData.price_annually.UAH || 0
          }`,
      },
      {
        key: 'created',
        dataKey: 'created_at',
        width: 0,
        flexGrow: 1,
        sortable: true,
        headerRenderer: headerRenderer(t),
        cellRenderer: (props) => transformDate(props.cellData),
      },
    ],
  }

  return COLUMNS_CONFIG[type]
}

export default getTableColumns
