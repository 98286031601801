import React from 'react'

export const loginArrow = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 17} height={height || 17} fill="none" viewBox="0 0 17 17">
    <path
      d="M9.57983 9H0.579834"
      stroke={fill || color.general.black}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6.71429L10.2857 9.00001L8 11.2857"
      stroke={fill || color.general.black}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66066 14.1314C3.6869 15.2011 5.00968 15.9393 6.45887 16.251C7.90806 16.5628 9.41738 16.4338 10.7927 15.8808C12.168 15.3277 13.3464 14.3758 14.1763 13.1476C15.0062 11.9194 15.4497 10.4709 15.4497 8.98857C15.4497 7.50623 15.0062 6.05779 14.1763 4.82955C13.3464 3.6013 12.168 2.64944 10.7927 2.09639C9.41738 1.54334 7.90806 1.41439 6.45887 1.72613C5.00968 2.03788 3.6869 2.77605 2.66066 3.84571"
      stroke={fill || color.general.black}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
