import styled from 'styled-components'
import BaseTable from 'react-base-table'

export const StyledTableWrapper = styled.div`
  .BaseTable--empty {
    .BaseTable__header {
      display: none;
    }
    .BaseTable__empty-layer {
      top: 15px !important;
      background-color: transparent;
      overflow: visible;
    }
  }
`

export const StyledBaseTable = styled(BaseTable)`
  box-shadow: none;

  .BaseTable__table-main {
    outline: none;
    border: none;

    .BaseTable__body {
      overflow: visible !important;
    }

    .BaseTable__header {
      margin-bottom: 10px;
    }

    .BaseTable__row:nth-child(odd) {
      background-color: ${({ theme: { color } }) => color.general.lightest};
      .statusIcon rect {
        stroke: ${({ theme: { color } }) => color.general.lightest};
      }

      .squeezeIcon {
        border: 2px solid ${({ theme }) => theme.color.general.lightest};
      }
    }

    .BaseTable__row {
      border: 1px solid transparent;
      margin-bottom: 4px;

      .squeezeIcon {
        border: 2px solid ${({ theme }) => theme.color.general.white};
      }
      .BaseTable__row-cell {
        overflow: initial !important;
      }
      .BaseTable__row-cell .typography {
        display: inline;
      }

      &.activeRow {
        background-color: ${({ theme: { color } }) => color.secondary.lightest};
        border: 1px solid ${({ theme: { color } }) => color.secondary.lighter};
        border-radius: 3px;

        .statusIcon rect {
          stroke: ${({ theme: { color } }) => color.secondary.lightest};
        }

        .squeezeIcon {
          border: 2px solid ${({ theme }) => theme.color.secondary.lightest};
        }
      }

      &.inactive {
        & > div:not(.actionButtons, .statusIcons) {
          opacity: 0.5;
        }
        .icon:not(~ .statusIcon) {
          path {
            fill: ${({ theme: { color } }) => color.general.lightest};
            stroke: ${({ theme: { color } }) => color.general.lighter};
          }
          circle {
            fill: ${({ theme: { color } }) => color.status.inactive};
          }
        }

        .tag:not(.withoutInactive) {
          background-color: ${({ theme: { color } }) => color.general.lighter};
          color: ${({ theme: { color } }) => color.general.darker};
        }
        &:not(> button) .typography {
          color: ${({ theme: { color } }) => color.general.light};
        }

        .BaseTable__row-cell-text {
          //line-height: 1rem;
        }
      }
    }
    .BaseTable__row:hover {
      background-color: ${({ theme: { color } }) => color.general.lighter};
      .squeezeIcon {
        border: 2px solid ${({ theme }) => theme.color.general.lighter};
      }
    }

    .BaseTable__header-cell .icon {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
    .BaseTable__header-cell:first-child,
    .BaseTable__row-cell:first-child {
      padding-left: 0;
      margin-left: 15px;
    }
  }

  .BaseTable__header-row {
    background-color: ${({ theme: { color } }) => color.general.white};
    border: 1px solid ${({ theme: { color } }) => color.general.lighter};
    border-radius: 3px;
    color: ${({ theme: { color } }) => color.general.dark};

    .BaseTable__header-cell .BaseTable__sort-indicator {
      display: block;
      svg path {
        fill: ${({ theme: { color } }) => color.general.light};
      }
    }

    .BaseTable__header-cell--sortable.BaseTable__header-cell--sorting {
      .BaseTable__sort-indicator {
        svg path {
          fill: ${({ theme: { color } }) => color.general.dark};
        }
      }
    }

    .BaseTable__header-cell--sortable:hover {
      background-color: ${({ theme: { color } }) => color.general.white};
    }
  }

  .BaseTable--empty .BaseTable__table-main .BaseTable__header {
    display: none;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    .BaseTable__table-main {
      padding: 10px;
    }
  }
`
