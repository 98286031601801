import styled from 'styled-components'

export const StyledDashboard = styled.div`
  .h3 {
    flex-grow: 1;
  }
  .inputContainer {
    margin-bottom: 0 !important;
    margin-right: 10px;
  }
  .react-datepicker__close-icon::after {
    background-color: ${({ theme }) => theme.color.general.black};
  }
  .react-datepicker__input-container input {
    border: 1px solid ${({ theme }) => theme.color.general.light};
    border-radius: 3px;
    width: 242px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .inputContainer {
      margin-right: 0;
    }
    .headerBlock {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    .headerBlock,
    .averageBlock,
    .averageBlock .flexRow {
      justify-content: center;
    }
  }
`

export const StyledStatisticBlock = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  > div:not(.flexRow) {
    height: fit-content;
    padding: 14px 20px;
    border: 1px solid ${({ color }) => color.general.lighter};
    border-radius: 5px;
    min-width: 320px;
    height: 290px;
    display: flex;
    flex-direction: column;
  }
  .flexRow {
    height: fit-content;
    gap: 20px;
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .flexRow {
      gap: 10px;
    }
  }
`
