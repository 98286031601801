import { parse } from 'query-string'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Loader, Table, VirtualizedList } from '../../atomic-design-components'
import EmptyTable from '../../atomic-design-components/organisms/Table/components/Empty'
import TableNavbar from '../../atomic-design-components/organisms/Table/components/Navbar'
import { COLLECTIONS_WITH_TABLE, RIGHT_PANEL_CUSTOM_ROUTES } from '../../config'
import getTableColumns from '../../config/columns'
import { HEADER_HEIGHT } from '../../constants'
import { useMappedState, usePrevious } from '../../hooks/useReactRedux'
import { useWidth } from '../../hooks/useWidth'
import { getTableData } from '../../redux-saga/actions'
import {
  selectMainDataInitialized,
  selectMainDataLoading,
  selectTableTotalItems,
  selectUserRole,
} from '../../redux-saga/selectors'
import EmptyScreen from '../EmptyScreen/EmptyScreen'
import TableTitleBlock from './components/TableTitleBlock'
import { sortObjectToUrlString, useSorting } from './hooks/useSorting'
import { useTableModeAndSize } from './hooks/useTableModeAndSize'
import { StyledContent } from './styled'

const TableBlock = ({
  activeRowId,
  className,
  iconEmpty,
  menuIconProps,
  navbarProps,
  queryParams,
  rowKey,
  TableCardContent,
  tableCardHeight = 146,
  tableData,
  tableTitleChildren,
  tableModeInitial,
  theme,
  type,
  withMenuIcon,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('all')
  const location = useLocation()
  const navigate = useNavigate()
  const screenWidth = useWidth()
  const searchParsed = parse(location.search)
  const prevSearchValue = usePrevious(searchParsed.searchValue)

  const prevRowId = useRef(activeRowId)

  const gridRef = useRef(null)
  const contentRef = useRef(null)

  const inProgress = useMappedState(selectMainDataLoading)
  const isDataInitialized = useMappedState(selectMainDataInitialized)
  const itemsTotalCount = useMappedState(selectTableTotalItems)
  const role = useMappedState(selectUserRole)

  const isTableWithTitle =
    ['contacts', 'members', 'customers', 'bulkMessaging'].includes(type) ||
    (role === 'system_administrator' && ['payments', 'subscriptionPlans', 'billingPlans'].includes(type))

  const isEmptyTable =
    isDataInitialized && !tableData?.length && !searchParsed?.searchValue && inProgress === false

  const gap = 20
  const gridColCount = 1
  const tableRowHeight = 44
  const tableHeaderHeight = 44
  const tableRowGap = 4
  const navbarHeight = type === 'payments' ? 100 : 66

  const isMobile = screenWidth && screenWidth < theme.breakpoints.lg
  const isPageWithHeader = screenWidth && screenWidth < theme.breakpoints.xl
  const topHeight =
    (isPageWithHeader ? HEADER_HEIGHT : 0) + (isTableWithTitle ? HEADER_HEIGHT : 0) + navbarHeight

  const columnsFromConfig = getTableColumns(type, t, i18n.language) || []
  const columns = columnsFromConfig.filter(
    (column) => !column.getIsHidden || !column.getIsHidden({ type, role })
  )

  const { tableMode, onTableResize, onListResize, getMaxTableHeight, isActionButtonsExtended } =
    useTableModeAndSize(topHeight, gap, gridRef, gridColCount, type, !!TableCardContent, i18n.language)

  const maxTableHeight = getMaxTableHeight(
    tableMode === 'table' ? tableRowHeight : tableCardHeight,
    tableHeaderHeight
  )

  const maxTableRowsCount = Math.floor(maxTableHeight / (tableRowHeight + tableRowGap))
  const maxListRowsCount = useRef()

  const { sortState, onColumnSort } = useSorting(type)

  useEffect(() => {
    if (maxTableHeight && COLLECTIONS_WITH_TABLE.includes(type)) {
      maxListRowsCount.current = maxTableRowsCount

      dispatch(
        getTableData({
          type,
          search: location.search,
          sortString: sortObjectToUrlString(sortState),
          pageLimit: maxTableRowsCount,
          pageOffset:
            !prevSearchValue && searchParsed.searchValue
              ? undefined
              : searchParsed.page && (searchParsed.page - 1) * maxTableRowsCount,
          tableMode,
          queryParams: [...(queryParams || [])],
        })
      )
    }
  }, [type, location.search, maxTableRowsCount, maxTableHeight, queryParams])

  useEffect(() => {
    if (searchParsed.page && !prevSearchValue && searchParsed.searchValue) {
      delete searchParsed.page
      navigate({
        search: createSearchParams(searchParsed).toString(),
      })
    }
  }, [prevSearchValue, searchParsed])

  useEffect(() => {
    if (activeRowId && !RIGHT_PANEL_CUSTOM_ROUTES.includes(activeRowId)) {
      prevRowId.current = activeRowId
    }
  }, [activeRowId])

  const onRowClick = useCallback(
    (data) => {
      // dispatch(inputFilesClear())
      prevRowId.current = data.rowKey?.toString()

      navigate({
        pathname: data.rowKey ? `${data.rowKey}` : type,
        search: createSearchParams(searchParsed).toString().replace(/%2C/g, ','),
      })
    },
    [dispatch, searchParsed]
  )

  const isDataInProgress =
    ((!searchParsed.searchValue && !tableData?.length) || !isDataInitialized) && inProgress

  const getTableComponent = () => {
    if (isDataInProgress) {
      return <Loader size="60px" />
    }

    if (isEmptyTable) {
      return <EmptyScreen type={type} icon={iconEmpty} />
    }

    switch (tableMode) {
      case 'table':
        return (
          <Table
            columns={columns}
            data={tableData}
            emptyText={t('nothingFound')}
            maxHeight={maxTableHeight}
            onColumnSort={onColumnSort}
            onResize={onTableResize}
            onRowClick={onRowClick}
            // paddingsAndBorders={20}
            rowClassName={({ rowData }) => {
              const isInactive = rowData.status === 'inactive' ? 'inactive' : ''
              const isHighlightedRow =
                activeRowId && !RIGHT_PANEL_CUSTOM_ROUTES.includes(activeRowId)
                  ? activeRowId === rowData.id?.toString()
                  : prevRowId?.current && rowData.id?.toString() === prevRowId.current

              const activeRow = isHighlightedRow ? 'activeRow' : ''

              return `${isInactive} ${activeRow}`
            }}
            rowHeight={tableRowHeight}
            headerHeight={tableHeaderHeight}
            rowKey={rowKey || 'id'}
            sortState={sortState}
            isActionButtonsExtended={isActionButtonsExtended}
            {...rest}
          />
        )
      case 'cards': {
        return tableData?.length ? (
          <VirtualizedList
            activeRowId={activeRowId}
            columns={columns}
            columnCount={gridColCount}
            gridRef={gridRef}
            initialPageNumber={1}
            items={tableData}
            itemsTotalCount={itemsTotalCount}
            onItemClick={onRowClick}
            onResize={onListResize}
            prevRowId={prevRowId?.current}
            rowHeight={tableCardHeight}
            sortState={sortState}
            TableCardContent={TableCardContent}
            t={t}
            type={type}
            // searchValue={searchParsed?.searchValue}
            // cellProps={{ container: { props: { cellProps } } }}
            // hasNextPage={itemsTotalCount > tableData.length}
            // isNextPageLoading={inProgress}
            // onSetColumnCount={onSetGridColCount}
            {...rest}
          />
        ) : (
          <EmptyTable className="emptyList" text={t(inProgress ? t('loading') : 'nothingFound')} />
        )
      }
    }
  }

  return (
    <>
      {isTableWithTitle && (
        <TableTitleBlock type={type} withMenuIcon={withMenuIcon} menuIconProps={menuIconProps}>
          {!isMobile && tableTitleChildren}
        </TableTitleBlock>
      )}
      {navbarProps && !navbarProps.isHidden && !isDataInProgress && !isEmptyTable && (
        <TableNavbar
          // className={clsx((isDataInProgress || isEmptyTable) && 'transparent')}
          tableMode={tableMode}
          rowsCount={maxTableRowsCount} //tableMode === 'table' ? maxTableRowsCount : 50
          itemsTotalCount={itemsTotalCount}
          {...navbarProps}
        />
      )}
      {isTableWithTitle && isMobile && tableTitleChildren}
      <StyledContent ref={contentRef} topHeight={topHeight} maxHeight={maxTableHeight} className={className}>
        {getTableComponent()}
      </StyledContent>
    </>
  )
}

export default withTheme(TableBlock)
