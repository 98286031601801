import styled from 'styled-components'

import { getCssFromTheme } from '../../../utils/css'

export const StyledTypography = styled.span`
  ${({ theme, variant }) => getCssFromTheme(theme, 'typography', variant)};
  color: ${({ color }) => color};
  display: ${({ as }) => (as === 'div' ? 'block' : 'inline-block')};
  text-align: ${({ $textAlign }) => $textAlign};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  text-transform: ${({ $textTransform }) => $textTransform};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ $lineHeight }) => $lineHeight};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  // @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
  //   color: ${({ color }) => color};
  //   font-weight: ${({ fontWeight }) => fontWeight};
  //   font-family: ${({ fontFamily }) => fontFamily};
  //   font-size: ${({ fontSize }) => fontSize};
  //   line-height: ${({ $lineHeight }) => $lineHeight};
  // }
  //
  // @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
  //   color: ${({ color }) => color};
  //   font-weight: ${({ fontWeight }) => fontWeight};
  //   font-family: ${({ fontFamily }) => fontFamily};
  //   font-size: ${({ fontSize }) => fontSize};
  //   line-height: ${({ $lineHeight }) => $lineHeight};
  // }
`
