import React from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StyledFieldsCustomisation } from './styled'
import { Icon, Switch, Tooltip, Typography } from '../../../atomic-design-components'

const FieldsCustomisation = (props) => {
  const { t } = useTranslation('all')

  const fieldsSettings = props.value || {}
  const fields = Object.keys(fieldsSettings)
  const is_form_displayed = props.optionsFromFormValues.is_form_displayed

  const onSwitchChecked = (field, type, value) => {
    const updatedValues = {
      ...fieldsSettings,
      [field]: {
        ...fieldsSettings[field],
        [type]: value,
      },
    }

    props.updateSelect('form_fields')(updatedValues)
  }

  return (
    <StyledFieldsCustomisation>
      <div className="row header">
        <Typography variant="caption1" text={t('field')} className="col1" />
        <Tooltip text={t('visible')} className="col2" tipPosition="bottom" arrowPosition="right" right="3px">
          <Icon name="eyeSign" fill={props.theme.color.general.black} size={16} padding="10px" />
        </Tooltip>
        <Tooltip text={t('required')} className="col3" tipPosition="bottom" arrowPosition="right" right="3px">
          <Icon name="starRequiredSign" fill={props.theme.color.primary.main} size={16} padding="10px" />
        </Tooltip>
      </div>

      {fields.map((field) => (
        <div className="row field" key={field}>
          <Typography
            variant="button1"
            text={t(field)}
            className="col1"
            color={props.theme.color.general.dark}
          />
          <Switch
            id={`${field.field_name}Visible`}
            className="col2"
            checked={fieldsSettings[field].is_visible}
            onChange={(value) => onSwitchChecked(field, 'is_visible', value)}
            disabled={!is_form_displayed}
          />
          <Switch
            id={`${field}Required`}
            className="col3"
            checked={fieldsSettings[field].is_required}
            onChange={(value) => onSwitchChecked(field, 'is_required', value)}
            disabled={!is_form_displayed}
          />
        </div>
      ))}
    </StyledFieldsCustomisation>
  )
}

export default withTheme(FieldsCustomisation)
