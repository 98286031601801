import React, { useEffect, useRef, useState } from 'react'
import { withTheme } from 'styled-components'
import { FlexRow, Input, Switch, TextPanel, Tooltip, Typography } from '../../atomic-design-components'
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea'
import { StyledSwitchableCardWithFields } from './styled'
const SwitchableCardWithFields = ({
  blockKey,
  cardKey,
  fields,
  tipText,
  onSubmitData,
  values,
  isCardDisabled,
  theme,
  t,
}) => {
  const [inputValues, setInputValues] = useState(values)
  const [errors, setErrors] = useState({})
  useEffect(() => {
    setInputValues(values)
  }, [values])

  const isSwitchActive = inputValues.status === 'active'
  const hasEmptyValue = Object.values(inputValues).some((el) => !el && el !== 0)

  const onSwitchClick = (isSwitchedOn) => {
    if (isSwitchedOn && hasEmptyValue) {
      return
    }

    onSubmitData({
      valuesChanged: { ...inputValues, status: isSwitchedOn ? 'active' : 'inactive' },
      cardKey,
      blockKey,
    })
  }

  const onInputBlur = (e) => {
    const { name, value } = e.target

    if (!value && isSwitchActive) {
      setErrors((prev) => ({ ...prev, [name]: 'required' }))
      return
    }

    setErrors((prev) => ({ ...prev, [name]: '' }))

    const hasChanged = name === 'delay' ? +value !== values[name] : value !== values[name]
    if (hasChanged) {
      onSubmitData({
        valuesChanged: { ...inputValues, delay: Number(inputValues.delay) },
        cardKey,
        blockKey,
      })
    }
  }

  const onInputChange = (e) => {
    const { name, value } = e.target

    if (value) {
      setErrors((prev) => ({ ...prev, [name]: '' }))
    }

    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <StyledSwitchableCardWithFields>
      <FlexRow justifyContent="space-between">
        <Typography variant="button1" text={t(cardKey)} />
        <Tooltip
          text={isCardDisabled ? t('disabledCardTip') : t('fieldsTooltip')}
          color={theme.color.general.darkest}
          arrowPosition="right"
          left="-100px"
          whiteSpace="pre-wrap"
          disableTooltip={!isCardDisabled && !hasEmptyValue}
        >
          <Switch
            checked={isSwitchActive}
            disabled={isCardDisabled || hasEmptyValue}
            onChange={onSwitchClick}
            leftLabel={isSwitchActive ? 'On' : 'Off'}
            textVariant="button1"
            id={cardKey}
            gap="10px"
          />
        </Tooltip>
      </FlexRow>
      <TextPanel
        backgroundColor={theme.color.general.lightest}
        content={isCardDisabled ? t('disabledCardTip') : t(tipText)}
        iconLeftProps={{ name: 'infoCircle' }}
        gap="6px"
      />
      {fields.map((field, i) => {
        const inputRef = useRef(null)
        useAutosizeTextArea(field.multiline && inputRef.current, inputValues[field.key], 400)
        return (
          <Input
            key={i}
            {...field}
            value={inputValues[field.key]}
            name={field.key}
            onChange={onInputChange}
            onBlur={onInputBlur}
            error={errors[field.key] && t(`validation:${errors[field.key]}`)}
            label={t(field.label)}
            placeholder={t(field.placeholder)}
            disabled={isCardDisabled}
            inputRef={inputRef}
          />
        )
      })}
    </StyledSwitchableCardWithFields>
  )
}

export default withTheme(SwitchableCardWithFields)
