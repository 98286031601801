import React, { useRef } from 'react'

import { Input } from '../../atomic-design-components'
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea'

// import ReadOnlyInfo from '../RightPanel/ReadOnlyInfo'

const FormInput = (props) => {
  const {
    field,
    isHidden,
    disabled,
    formSubmit,
    label,
    isSubmitOnBlur,
    name,
    required,
    submitByEnterPressed,
    t,
    updateInput,
    initialValue,
    value,
  } = props

  const {
    iconLeftProps,
    iconRightProps,
    type,
    onChange,
    multiline,
    addElementToInput,
    additionToInput,
    min,
    max,
    rows,
    step,
    // key,
  } = field
  // if (field.readOnly) {
  //   return <ReadOnlyInfo label={label} value={value} />
  // }
  const inputRef = useRef(null)
  useAutosizeTextArea(multiline && inputRef.current, value, 400)

  const onChangeInput = (field) => (e) => {
    updateInput(e.target ? e : { target: { name, value: e } }, field.onInputValueChange)
  }

  return (
    <Input
      {...props}
      iconLeftProps={iconLeftProps}
      iconRightProps={iconRightProps}
      type={type}
      onBlur={isSubmitOnBlur && formSubmit}
      onEnter={isSubmitOnBlur && !multiline && formSubmit}
      submitByEnterPressed={submitByEnterPressed}
      onChange={onChange || onChangeInput(field)}
      multiline={multiline || false}
      min={min}
      max={max}
      rows={rows}
      step={step}
      value={value || ''}
      primaryFocusColor
      required={required}
      inputRef={inputRef}
    >
      {additionToInput}
      {!isHidden && addElementToInput && addElementToInput(updateInput, t, disabled)}
    </Input>
  )
}

export default FormInput
