import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'
import { TimezoneSelect } from '../../timezoneSelect/index'

import { Button, Switch, TimeInput, Typography } from '../../atomic-design-components'
import { DEFAULT_WORK_HOURS } from '../../constants'
import { useMappedState } from '../../hooks/useReactRedux'
import { getCompanyDataById, updateCompanyDataById } from '../../redux-saga/actions'
import { selectCompanyData, selectUserCompanyId } from '../../redux-saga/selectors'
import { StyledDay, StyledHours } from './styled'

const MyCompanyHours = ({ theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')

  const currentUserCompanyId = useMappedState(selectUserCompanyId)

  useEffect(() => {
    dispatch(getCompanyDataById(currentUserCompanyId))
  }, [])

  const companyData = useMappedState(selectCompanyData)

  const initialValues = companyData.work_hours
    ? {
        timezone_offset: companyData.timezone_offset || DEFAULT_WORK_HOURS.timezone_offset,
        timezone_region: companyData.timezone_region || DEFAULT_WORK_HOURS.timezone_region,
        work_hours: companyData.work_hours,
      }
    : DEFAULT_WORK_HOURS

  const [updatedValues, setUpdatedValues] = useState({ ...initialValues })

  useEffect(() => {
    setUpdatedValues({ ...initialValues })
  }, [companyData])

  const getDaySchedule = ({ day, params }) => {
    const { is_open, start, close } = params
    return (
      <StyledDay key={day}>
        <Typography variant="caption1" text={t(day)} />
        <Switch
          id={`${day}Open`}
          textVariant="button1"
          label={is_open ? t('open') : t('closed')}
          checked={is_open}
          onChange={(checked) => onValuesChange({ value: checked, day })}
        />
        <div className="timeContainer">
          <TimeInput
            id={`${day}Start`}
            width="120px"
            step="1800"
            value={start}
            disabled={!is_open}
            onChange={(e) => onValuesChange({ value: e, day, name: 'start' })}
          />
          <Typography variant="button1" text={t('to')} style={{ textTransform: 'capitalize' }} />
          <TimeInput
            id={`${day}Close`}
            width="120px"
            step="1800"
            value={close}
            disabled={!is_open}
            onChange={(e) => onValuesChange({ value: e, day, name: 'close' })}
          />
        </div>
      </StyledDay>
    )
  }

  const onValuesChange = ({ value, day, name }) => {
    const isTime = !!name
    const changedValue = isTime ? value.target.value : value

    const key = isTime ? name : 'is_open'

    setUpdatedValues({
      ...updatedValues,
      work_hours: {
        ...updatedValues.work_hours,
        [day]: {
          ...updatedValues.work_hours[day],
          [key]: changedValue,
        },
      },
    })
  }

  const onSetTimezone = (timezone) => {
    setUpdatedValues({ ...updatedValues, timezone_region: timezone.value, timezone_offset: timezone.offset })
  }

  const onCancel = () => {
    setUpdatedValues({ ...initialValues })
  }

  const onSave = () => {
    dispatch(updateCompanyDataById(companyData.id, updatedValues))
  }

  return (
    <StyledHours>
      <div className="headerWrapper">
        <Typography variant="h3" text={t('hoursOfWorkTitle')} />
        <Typography variant="body2" text={t('hoursOfWorkTip')} color={theme.color.general.dark} />
      </div>

      <Typography variant="h4" text={t('timeZone')} color={theme.color.general.darker} margin="20px 0 10px" />
      <TimezoneSelect
        value={updatedValues.timezone_region}
        onChange={onSetTimezone}
        className="timezoneInput"
      />

      {Object.keys(updatedValues.work_hours).map((day) =>
        getDaySchedule({ day, params: updatedValues.work_hours[day] })
      )}

      <div className="buttonWrapper">
        <Button
          variant="general"
          text={t('cancel')}
          confirmButtonProps={{
            onDeleteConfirm: onCancel,
          }}
        />
        <Button
          variant="primary"
          text={t('saveSchedule')}
          iconLeftProps={{ name: 'floppy' }}
          onClick={onSave}
        />
      </div>
    </StyledHours>
  )
}

export default withTheme(MyCompanyHours)
