import styled from 'styled-components'

import { HEADER_HEIGHT, NAV_MENU_WIDTH, NAV_MENU_COLORS } from '../../constants'

export const StyledNavMenu = styled.nav`
  position: relative;
  padding: 14px;
  background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};
  transition: width 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  z-index: 5;
  display: flex;
  flex-direction: column;

  .onlineIcon rect {
    stroke: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};
  }

  &.extended {
    width: ${NAV_MENU_WIDTH.desktop.extended};
    &.tabletView {
      position: absolute;
      top: ${HEADER_HEIGHT}px;
      left: 0;
      width: 300px;
      border-radius: 0px 0px 5px 0px;
      box-shadow: 0px 15px 25px ${({ theme }) => `${theme.color.general.white}14`};
    }

    .skibbleTextLogo {
      opacity: 1;
      transition: opacity 1.5s;
    }
  }

  &.condensed {
    width: ${NAV_MENU_WIDTH.desktop.condensed};

    .toggleIcon {
      transform: rotate(180deg);
    }

    .skibbleTextLogo {
      width: 0;
      margin: 0;
      opacity: 0;
      transition: opacity 0s;
    }
  }

  .toggleIcon {
    position: absolute;
    right: -10px;
    top: 20px;
    transition: transform 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9);

    rect[x='2'] {
      fill: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    }
  }

  .menuItem a,
  .typography {
    color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  .menuItemIcon path,
  line {
    stroke: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  .menuItemIcon.logoSymbol path[fill-rule] {
    fill: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  .label {
    color: ${({ theme }) => theme.color.general.dark};
  }

  .tag {
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    color: ${({ theme }) => theme.color.general.white};
    width: fit-content;
    margin-top: 3px;
  }

  .logoutLink {
    margin-top: 20px;
    display: block;
  }

  .menuItem .active {
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    border: 1px solid ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    border-radius: 3px;

    .typography {
      color: ${({ theme }) => theme.color.general.white};
    }

    .menuItemIcon path,
    line {
      stroke: ${({ theme }) => theme.color.general.white};
    }

    .menuItemIcon.logoSymbol path[fill-rule] {
      fill: ${({ theme }) => theme.color.general.white};
    }
  }

  .menuItemBadge.isMini {
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};
  }

  hr {
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].lighter};
  }

  .topWrapper {
    flex-grow: 1;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    &.extended {
      margin-top: 12px;
      height: 100vh;
    }
  }
  @media only screen and (max-height: ${({ theme }) => theme.breakpoints.lg}px) {
    &.extended.revertView {
      height: calc(100vh - ${HEADER_HEIGHT}px);
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: 0;

    &.extended {
      width: ${NAV_MENU_WIDTH.mobile.extended};
      transform: translateY(0%);
      transition: transform 0.4s ease-in-out;
      border-top: 1px solid ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].lighter};
      overflow-y: auto;
      padding: 14px;
      position: absolute;
      z-index: 10;
      top: 52px;
    }

    &.condensed {
      width: ${NAV_MENU_WIDTH.mobile.condensed};
      position: absolute;
      bottom: 100vh;
      left: 0;
      transform: translateY(-100%);
      transition: transform 0.6s ease-in-out;
    }
  }
`

export const StyledMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: ${({ padding }) => padding};

  a {
    width: 100%;
    color: ${({ textColor }) => textColor};
  }

  .menuItem.link {
    &:hover:not(.disabled) {
      a {
        opacity: 1;
      }

      .extendedItem.typography {
        opacity: 0.7;
      }
    }
  }
`

export const StyledMenuItem = styled.div`
  padding: 7px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  border: 1px solid transparent;
  border-radius: 3px;

  &.userState {
    z-index: -1;
    padding: 12px 0;

    .userInfo {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      flex-grow: 1;
      width: 135px;

      .caption1 {
        margin: 0;
      }
    }
  }

  .typography {
    margin-left: 8px;
  }

  .condensedItem {
    opacity: 0;
    transition: opacity 0s;
    transform: translateX(-100%);
    transition: transform 0.6s ease-in-out;
    &.typography {
      margin-left: 0;
    }
  }

  .extendedItem {
    transform: translateX(0%);
    transition: transform 0.4s ease-in-out;
    opacity: 1;
    transition: opacity 1.5s;
  }

  .icon {
    margin: 0;
  }
  &.active {
    background-color: ${({ activeBGColor }) => activeBGColor || '#2d2d2d'};
    color: white;
  }
`

export const StyledLine = styled.hr`
  color: ${({ theme }) => theme.color.general.white};
  background-color: ${({ lineColor }) => lineColor};
  border-width: 0;
  height: 1px;
  margin: 0;
`
