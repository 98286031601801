import React from 'react'
import { components } from 'react-select'

import { Icon } from '../../../index'

const DropdownIndicator = ({ children, ...props }) => {
  const {
    error,
    isDisabled,
    theme: { color },
  } = props.selectProps

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="chevronDown"
        fill={isDisabled ? color.general.light : error ? color.status.error : color.general.black}
        className="chevronDown"
      />
    </components.DropdownIndicator>
  )
}
export default DropdownIndicator
