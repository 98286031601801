import React, { useEffect } from 'react'
import { withTheme } from 'styled-components'

import { StyledFieldsCustomisation } from './styled'
import { Switch, Typography, Input, Select } from '../../../atomic-design-components'
import { useTranslation } from 'react-i18next'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectMainData } from '../../../redux-saga/selectors'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import { getSelectValue } from '../../../utils/select'

const InputWithSwitch = (props) => {
  const { t } = useTranslation(['all', 'validation'])

  const departments = useMappedState(selectMainData('departments')) || []
  const fieldsSettings = props?.value
  const is_form_displayed = props.optionsFromFormValues?.is_form_displayed

  const choosedDep = fieldsSettings?.values?.length || 0
  const allDeps = departments.length

  const onSwitchChecked = (checked, name) => {
    const newFieldsValue = {
      ...fieldsSettings,
      [name]: checked,
    }

    const currName = props.name
    props.updateSelect(currName)(newFieldsValue)
  }

  const onDepartmentChoose = (option) => {
    option.map((opt) => {
      if (isObjectEmpty(opt)) {
        opt = departments.find((dep) => dep.id === opt)
      }
    })

    props.updateSelect('departments.values')(option)
  }

  const onURLChange = (e) => {
    const value = e?.target?.value
    props.updateInput({ target: { name: 'privacy_policy.document_url', value } })
  }

  useEffect(() => {
    if (props.name === 'privacy_policy') {
      document.getElementById('privacy_policy')?.focus()

      if (props.value.is_required && props.value.document_url === '') {
        props.updateInput({ target: { name: 'privacy_policy.document_url', value: 'https://' } })
      }
    }
  }, [props.name, props.value])

  return (
    <StyledFieldsCustomisation>
      {props.name === 'departments' && (
        <>
          <div className="row field">
            <Typography
              variant="button1"
              text={t('departmentsField', { choosedDep, allDeps })}
              color={props.theme.color.general.black}
            />
            <Switch
              id="departmentsVisible"
              className="col2"
              checked={fieldsSettings?.is_selected}
              onChange={(checked) => onSwitchChecked(checked, 'is_selected')}
            />
          </div>
          {fieldsSettings.is_selected && (
            <Select
              label="departments"
              isMulti
              options={departments}
              labelKey="name"
              valueKey="id"
              iconLeftProps={{ name: 'multiUsers', fill: props.theme.color.general.dark }}
              fullWidth
              isClearable
              value={getSelectValue(fieldsSettings?.values, departments, {
                valueKey: 'id',
                labelKey: 'name',
              })}
              onChange={onDepartmentChoose}
            />
          )}
        </>
      )}
      {props?.name === 'privacy_policy' && (
        <div className="rows">
          <Typography variant="button1" text={t('privacyPolicy')} color={props.theme.color.general.black} />
          <Switch
            id="policyRequired"
            checked={fieldsSettings?.is_required}
            onChange={(checked) => onSwitchChecked(checked, 'is_required')}
            disabled={!is_form_displayed}
          />
          {fieldsSettings.is_required && (
            <Input
              type="text"
              iconLeftProps={{ name: 'linkSign', size: 16, fill: props.theme.color.general.dark }}
              label={t('link')}
              placeholder={t('privacyPolicy')}
              fullWidth
              value={fieldsSettings?.document_url}
              onChange={onURLChange}
              error={props.error}
              id="privacy_policy"
              required={fieldsSettings.is_required}
              disabled={!is_form_displayed}
            />
          )}
        </div>
      )}
    </StyledFieldsCustomisation>
  )
}

export default withTheme(InputWithSwitch)
