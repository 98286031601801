import React from 'react'
import ReactTexty from 'react-texty'
import ModalImage from 'react-modal-image'

import { Icon, Typography } from '../../../atomic-design-components'
import { StyledFileMessage } from './styled'
import { formatSizeUnits } from '../../../utils/formatMeasureUnits'
import theme from '../../../theme'

const FileItem = ({ file, type, isMobile }) => {
  const transformedSize = formatSizeUnits(file.file_size)
  const fileName = file.file_name?.split('.')[0]
  const fileType = file.file_name?.split('.')[1]
  const iconName = 'type' + fileType?.toUpperCase()

  if (type === 'images') {
    return (
      <div className="messageImageItem">
        <ModalImage small={file.url} large={file.url} className="messageImage" />

        <Icon
          name="zoomArrows"
          size={16}
          fill={theme.color.general.dark}
          wrapperColor={`${theme.color.general.lighter}b3`}
          wrapperWidth={26}
          wrapperHeight={26}
          borderRadius="10px"
          className="modalviewElement"
        />
      </div>
    )
  }

  return (
    <StyledFileMessage href={file.url} target="_self" isOuterLink themeColor="general.dark">
      <Icon name={iconName} width={isMobile ? 20 : 30} height={isMobile ? 24 : 36} />
      <div>
        <div className="nameContainer">
          <Typography variant={isMobile ? 'caption2' : "caption1"} text={fileName} as={ReactTexty} />
          <Typography variant={isMobile ? 'caption2' : "caption1"} text={`.${fileType}`} />
        </div>
        <Typography variant="caption3" text={`Click to download   •   ${transformedSize}`} />
      </div>
    </StyledFileMessage>
  )
}

export default FileItem
