import React from 'react'

export const linkSign = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 17} height={height || 18} viewBox="0 0 17 18" fill="none">
    <path
      d="M5.57108 8.41699L1.90251 12.0513C1.79539 12.1575 1.71037 12.2839 1.65235 12.4232C1.59433 12.5625 1.56445 12.7118 1.56445 12.8627C1.56445 13.0136 1.59433 13.163 1.65235 13.3022C1.71037 13.4415 1.79539 13.5679 1.90251 13.6741L4.32537 16.097C4.43161 16.2041 4.55801 16.2891 4.69728 16.3472C4.83655 16.4052 4.98592 16.435 5.13679 16.435C5.28766 16.435 5.43704 16.4052 5.57631 16.3472C5.71558 16.2891 5.84198 16.2041 5.94822 16.097L9.58251 12.4284"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4284 9.58251L16.0627 5.94822C16.1698 5.84198 16.2548 5.71558 16.3129 5.57631C16.3709 5.43704 16.4008 5.28766 16.4008 5.13679C16.4008 4.98592 16.3709 4.83655 16.3129 4.69728C16.2548 4.55801 16.1698 4.43161 16.0627 4.32537L13.6741 1.90251C13.5679 1.79539 13.4415 1.71037 13.3022 1.65235C13.163 1.59433 13.0136 1.56445 12.8627 1.56445C12.7118 1.56445 12.5625 1.59433 12.4232 1.65235C12.2839 1.71037 12.1575 1.79539 12.0513 1.90251L8.41699 5.57108"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2853 6.71387L6.71387 11.2853"
      stroke={fill || color.general.light}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
