import React from 'react'
import { useLocation } from 'react-router-dom'

import MenuGroup from '../../../components/NavMenu/MenuGroup'
import MenuItem from '../../../components/NavMenu/MenuItem'
import { StyledLine } from '../../../components/NavMenu/styled'

const SettingsLeftPanel = ({ isExtended, isMobile, onClick, theme }) => {
  const { pathname } = useLocation()

  const isActiveKey = (key) =>
    pathname && pathname !== '/settings'
      ? pathname.replace(/[^a-zA-Z ]/g, '').includes(key.toLowerCase())
      : key === ''

  const getIconFill = (key) => (isActiveKey(key) ? theme.color.general.white : theme.color.general.darker)

  return (
    <>
      <MenuGroup onClick={onClick} textColor={theme.color.general.darker} padding="0 10px">
        <MenuItem
          key="profile"
          iconName="equalizer"
          iconFill={getIconFill('profile')}
          text="profile"
          to="../settings/my-profile"
          isActive={isActiveKey('profile')}
          isExtended={isExtended}
          isMobile={isMobile}
        />
      </MenuGroup>
      <StyledLine lineColor={theme.color.general.lighter} />
      <MenuGroup onClick={onClick} textColor={theme.color.general.darker} padding="0 10px">
        <MenuItem
          key="notifications"
          iconName="bell"
          iconFill={getIconFill('notifications')}
          text="notifications"
          to="../settings/notifications"
          isActive={isActiveKey('notifications')}
          isExtended={isExtended}
          isMobile={isMobile}
        />
      </MenuGroup>
      <StyledLine lineColor={theme.color.general.lighter} />
      <MenuGroup onClick={onClick} textColor={theme.color.status.error} padding="0 10px">
        <MenuItem
          key="deleteAccount"
          iconName="trashCan"
          iconFill={isActiveKey('deleteAccount') ? theme.color.general.white : theme.color.status.error}
          text="deleteAccount"
          to="../settings/delete-account"
          isActive={isActiveKey('deleteAccount')}
          isExtended={isExtended}
          isMobile={isMobile}
          activeBGColor={theme.color.status.error}
        />
      </MenuGroup>
    </>
  )
}

export default SettingsLeftPanel
