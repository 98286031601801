export const transformDataToBeSent = (object) => {
  const keys = Object.keys(object)

  const newValues = Object.values(object).reduce((result, value, i) => {
    let transformedValue

    if (value) {
      if (keys[i] === 'website_url' || keys[i] === 'privacy_policy.document_url') {
        const values = value.match(/(http(s)?:\/\/)?(.*)/)

        if (values[1] === undefined) {
          transformedValue = `https://${values[3]}`
        } else {
          transformedValue = value
        }
      } else if (value.id) {
        transformedValue = value.id
      } else if (value[0]?.id) {
        transformedValue = value.map((v) => v.id)
      } else {
        transformedValue = value
      }
    } else if (value === false) {
      transformedValue = value
    } else if (keys[i] === 'privacy_policy.document_url') {
      transformedValue = ''
    } else {
      transformedValue = null
    }

    return { ...result, [keys[i]]: transformedValue }
  }, {})

  if ('phone.phone' in object) {
    const phoneValue = object['phone.phone']
    const filteredPhoneNumber = phoneValue && phoneValue.replace(/\D/g, '')
    newValues.phone = `+${filteredPhoneNumber}` || null
    delete newValues['phone.phone']
  }

  return newValues
}
