import clsx from 'clsx'
import React from 'react'
import { withTheme } from 'styled-components'

import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import Icon from '../../atoms/Icon/Icon'
import Typography from '../../atoms/Typography/Typography'
import { StyledErrorLabel, StyledInputWrapper, StyledLabel, StyledWrapper } from '../Input/styled'
import { StyledInput } from './styled'

const TimeInputWithMask = (props) => {
  const {
    mask,
    onChange,
    value,
    className,
    withoutValidation,
    required,
    label,
    labelType = 'border',
    ref,
    fullWidth,
    type,
    disabled,
    error,
    field,
    onBlur,
    name,
    placeholder,
    iconLeftProps,
    formatChars,
    theme: { color },
  } = props
  const hasLeftIcon = !isObjectEmpty(iconLeftProps)

  // let beforeMaskedValueChange = (newState, oldState, userInput) => {
  //   let { value } = newState

  //   // Conditional mask for the 2nd digit base on the first digit
  //   if (value.startsWith('2')) formatChars['2'] = '[0-3]' // To block 24, 25, etc.
  //   else formatChars['2'] = '[0-9]' // To allow 05, 12, etc.
  //   return { value, selection: newState.selection }
  // }
  return (
    <StyledInputWrapper
      className={clsx(className, withoutValidation && 'withoutValidation', 'inputContainer')}
    >
      {label && labelType === 'top' && (
        <StyledLabel
          as={Typography}
          variant="caption1"
          className={clsx(required && 'required', 'label', 'top')}
        >
          {label}
        </StyledLabel>
      )}
      <StyledWrapper
        ref={ref}
        fullWidth={fullWidth}
        type={type}
        className={clsx('inputWrapper', disabled && 'disabled', !!error && 'hasError')}
      >
        {label && labelType === 'border' && (
          <StyledLabel
            className={clsx(required && 'required', 'label', 'border')}
            isUppercase={field?.isUppercase}
          >
            {label?.toLowerCase()}
          </StyledLabel>
        )}
        {!!iconLeftProps && (
          <Icon
            width={16}
            height={16}
            className="leftIcon"
            fill={disabled ? color.general.lighter : error ? color.status.error : color.general.light}
            {...iconLeftProps}
          />
        )}
        <StyledInput
          mask={mask}
          formatChars={formatChars}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          className={clsx(!!error && 'hasError', hasLeftIcon && 'hasLeftIcon')}
          name={name}
          onBlur={onBlur}
        />
      </StyledWrapper>
      {!!error && typeof error === 'string' && (
        <StyledErrorLabel icon={<Icon name="inputError" size={13} />} className="error">
          {error}
        </StyledErrorLabel>
      )}
    </StyledInputWrapper>
  )
}

export default withTheme(TimeInputWithMask)
