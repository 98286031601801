import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useDispatch } from 'react-redux'
import ReactHelmet from '../../wrappers/ReactHelmet'

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import { Typography, Link } from '../../atomic-design-components'
// import SocialButtons from '../../components/IntroScreens/components/SocialButtons'
import backGreen from '../../assets/images/backGreen.jpg'
import backBlue from '../../assets/images/backBlue.jpg'
import FormCreate from './FormCreate'
import FormInvite from './FormInvite'
import { getInviteDetails } from '../../redux-saga/actions'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectUserInviteDetails } from '../../redux-saga/selectors'

const SignUp = ({ theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('auth')
  const { search } = useLocation()
  const code = search && parse(search)?.code
  const isInvite = !!code

  const inviteDetails = useMappedState(selectUserInviteDetails)

  useEffect(() => {
    if (code) {
      dispatch(getInviteDetails(code))
    }
  }, [code])

  const initialValues = isInvite
    ? { ...inviteDetails, full_name: '', password: '' }
    : { email: '', password: '' }

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper
        text={t('createYourAccount')}
        type="signUp"
        theme={theme}
        imageUrl={isInvite ? backGreen : backBlue}
      >
        {isInvite ? (
          <FormInvite initialValues={initialValues} code={code} />
        ) : (
          <FormCreate initialValues={initialValues} />
        )}
        {!isInvite && (
          <div className="inscriptionWrapper">
            <Typography
              variant="body2"
              color={theme.color.general.black}
              margin="20px 0 0 0"
              className="inline"
            >
              {t('alreadyHaveAccount')}
            </Typography>
            <Typography variant="body2" fontWeight={500} color={theme.color.primary.main} className="inline">
              <Link to="/sign-in" className="link inline" themeColor="primary.main">
                &nbsp;{t('signIn')}
              </Link>
            </Typography>
          </div>
        )}
        <div className="inscriptionWrapper">
          <Typography
            variant="body2"
            color={theme.color.general.black}
            margin="20px 0 0 0"
            className="inline"
          >
            {t('legalPagesInscription')}
          </Typography>
          <Typography variant="body2" fontWeight={500} color={theme.color.primary.main} className="inline">
            <Link to="/privacy-policy" className="link inline" themeColor="primary.main" target="_blank">
              &nbsp;{t('privacyPolicy')}
            </Link>
          </Typography>
          <Typography
            variant="body2"
            color={theme.color.general.black}
            margin="20px 0 0 0"
            className="inline"
          >
            {t('legalPagesInscription1')}
          </Typography>
          <Typography variant="body2" fontWeight={500} color={theme.color.primary.main} className="inline">
            <Link
              to="/terms-and-conditions"
              className="link inline"
              themeColor="primary.main"
              target="_blank"
            >
              &nbsp;{`${t('withTermsAndConditions')}.`}
            </Link>
          </Typography>
        </div>
        {/* <SocialButtons /> */}
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(SignUp)
