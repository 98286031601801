import React from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes as T } from 'prop-types'

import { useWidth } from '../../hooks/useWidth'
import HeaderIntroScreen from './components/HeaderIntroScreen'

import { StyledFooterText, StyledWindow, StyledWrapper, StyledIntroWrapper } from './styled'
import { Typography } from '../../atomic-design-components'
import PopupAlertsComponent from '../../wrappers/PopupAlertsComponent'
import LanguageSelect from '../LanguageSelect/LanguageSelect'

const IntroScreenWrapper = ({ children, text, type, theme, imageUrl }) => {
  const { t } = useTranslation('auth')

  const screenWidth = useWidth()
  const isMobile = screenWidth && screenWidth < theme.breakpoints.sm
  const isTablet = screenWidth && screenWidth < theme.breakpoints.lg
  const isNearTablet = screenWidth && screenWidth < '1140'
  const isNearMobile = screenWidth && screenWidth < '900'

  return (
    <StyledWrapper type={type} className={`${type}Wrapper`}>
      {!isTablet && (
        <StyledIntroWrapper className="introWrapper" url={imageUrl} color={theme.color.general.white}>
          <HeaderIntroScreen theme={theme} />
          {!isNearMobile && (
            <div className="footerText">
              <Typography
                variant="h2"
                $textAlign="center"
                margin="15px 0"
                padding="0 30px"
                text={t('firstPageText')}
              />
              <Typography variant="body1" $textAlign="center" padding="0 80px" text={t('firstPageText1')} />
            </div>
          )}
        </StyledIntroWrapper>
      )}
      <div className="mainWrapper">
        <PopupAlertsComponent />
        {isTablet && <HeaderIntroScreen theme={theme} />}
        <StyledWindow className={type} type={type}>
          {isNearTablet && !isTablet && (
            <LanguageSelect isIntroScreensSelect theme={theme} width="204px" isFullSize />
          )}
          <div className="contentWrapper">
            <Typography
              variant={(isMobile && 'h2') || 'h1'}
              className="text"
              textAlign="left"
              text={t(text || type)}
              margin="0"
            />
            {children}
          </div>
        </StyledWindow>
        <StyledFooterText>
          <Typography variant="body2" className="footerText">
            Skibble {new Date().getFullYear()} © {t('footerText')}
          </Typography>
        </StyledFooterText>
      </div>
    </StyledWrapper>
  )
}

export default IntroScreenWrapper

IntroScreenWrapper.propTypes = {
  children: T.node,
  text: T.string,
  type: T.string,
  theme: T.object,
  imageUrl: T.string,
}
