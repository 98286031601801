import React from 'react'

export const instagramIcon = ({ width, height, fill }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="10" r="10" fill={fill || 'url(#paint0_linear_11104_77406)'} />
    <path
      d="M12.4666 3.75H7.68967C5.55882 3.75 3.82812 5.48069 3.82812 7.61155V12.3885C3.82812 14.5193 5.55882 16.25 7.68967 16.25H12.4666C14.5974 16.25 16.3281 14.5193 16.3281 12.3885V7.61155C16.3281 5.48069 14.5974 3.75 12.4666 3.75ZM14.9326 12.3935C14.9326 13.754 13.8271 14.8645 12.4616 14.8645H7.68467C6.32412 14.8645 5.21368 13.759 5.21368 12.3935V7.61655C5.21368 6.256 6.31912 5.14556 7.68467 5.14556H12.4616C13.8221 5.14556 14.9326 6.251 14.9326 7.61655V12.3935Z"
      fill="white"
    />
    <path
      d="M10.0756 6.80622C8.31492 6.80622 6.87935 8.2418 6.87935 10.0025C6.87935 11.7632 8.31492 13.1988 10.0756 13.1988C11.8363 13.1988 13.2719 11.7632 13.2719 10.0025C13.2719 8.2418 11.8363 6.80622 10.0756 6.80622ZM10.0756 11.9433C9.0052 11.9433 8.13485 11.0729 8.13485 10.0025C8.13485 8.93207 9.0052 8.06172 10.0756 8.06172C11.1461 8.06172 12.0164 8.93207 12.0164 10.0025C12.0164 11.0729 11.1461 11.9433 10.0756 11.9433Z"
      fill="white"
    />
    <path
      d="M13.5153 7.15663C13.8098 7.10889 14.0098 6.83148 13.9621 6.537C13.9144 6.24253 13.6369 6.04251 13.3425 6.09024C13.048 6.13798 12.848 6.4154 12.8957 6.70987C12.9434 7.00435 13.2209 7.20437 13.5153 7.15663Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11104_77406"
        x1="2.38573"
        y1="17.6143"
        x2="16.5968"
        y2="3.40316"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEE411" />
        <stop offset="0.0518459" stopColor="#FEDB16" />
        <stop offset="0.1381" stopColor="#FEC125" />
        <stop offset="0.2481" stopColor="#FE983D" />
        <stop offset="0.3762" stopColor="#FE5F5E" />
        <stop offset="0.5" stopColor="#FE2181" />
        <stop offset="1" stopColor="#9000DC" />
      </linearGradient>
    </defs>
  </svg>
)
