import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'

import { useMappedState } from '../../hooks/useReactRedux'
import { inputFileRemove } from '../../redux-saga/actions'
import { selectFilesUpload } from '../../redux-saga/selectors'
import UploadedItem from './UploadedItem'
import { StyledUploadedList } from './styled'

const UploadedItemsList = ({ chatID, uploadRef, onHide, className, stateKey }) => {
  const dispatch = useDispatch()

  const { filesChosen, filesUploaded, filesInProgress, errors } = useMappedState(selectFilesUpload)
  const files = stateKey === 'filesUploaded' ? filesUploaded[chatID] : filesChosen[chatID]

  const fnDelete = (id) => {
    dispatch(inputFileRemove(id, chatID, stateKey))
    onHide()
  }

  return (
    <StyledUploadedList ref={uploadRef} className={clsx(className, 'uploadItemsListContainer')}>
      <aside className="uploadItemsList">
        {files &&
          files.map((file) => {
            return (
              <UploadedItem
                error={errors[chatID]?.includes(file.preview)}
                fnDelete={fnDelete}
                key={file.preview}
                loading={filesInProgress[chatID]?.includes(file.preview)}
                src={file.preview}
                fileName={file.name || file.file_name}
                fileSize={file.size || file.file_size}
              />
            )
          })}
      </aside>
    </StyledUploadedList>
  )
}

export default UploadedItemsList
