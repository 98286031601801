import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'
import ReactHelmet from '../../wrappers/ReactHelmet'
// import SocialButtons from '../../components/IntroScreens/components/SocialButtons'
import backOrange from '../../assets/images/backOrange.jpg'
import { Button, Link, Typography } from '../../atomic-design-components'
import { StyledLabel } from '../../atomic-design-components/atoms/Label/styled'
import Form from '../../components/Form'
import { StyledAdditionToInputLink } from '../../components/Form/styled'
import { FORM_CONFIGS } from '../../config/forms'
import { toggleNavMenuExtended, userConfirm, userSignIn } from '../../redux-saga/actions'

const initialValues = { email: '', password: '' }
const queryString = require('query-string')

const SignIn = ({ theme, socialDisabled, screenContext }) => {
  const { t } = useTranslation('auth')

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { type, iconRightProps } = useIsPasswordShown()

  const { search } = useLocation()
  const userCode = search && queryString.parse(search)?.code

  const { width, xl } = screenContext
  const isDesktop = width > xl

  useEffect(() => {
    if (userCode) {
      dispatch(userConfirm(userCode))
    }
  }, [])

  const onFormSubmit = (credentials) => {
    dispatch(userSignIn(credentials, navigate))

    if (isDesktop) {
      dispatch(toggleNavMenuExtended(true))
    }
  }

  const getForgotPassLink = () => (
    <StyledAdditionToInputLink to="/password-recovery" themeColor="general.dark">
      <StyledLabel size="big" className={clsx('label', 'border')}>
        {t('forgotPassword')}
      </StyledLabel>
    </StyledAdditionToInputLink>
  )

  const getText = () => (
    <div>
      <Typography variant="body2" color={theme.color.general.black} margin="20px 0 0 0">
        {t('dontHaveAccount')}
      </Typography>
      <Typography variant="body2" fontWeight={500} color={theme.color.primary.main}>
        <Link to="/sign-up" className="link" themeColor="primary.main">
          &nbsp;{t('signUp')}
        </Link>
      </Typography>
    </div>
  )

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper type="signIn" theme={theme} imageUrl={backOrange} text={t('welcome')}>
        <Form
          className="form"
          type="signIn"
          initialValues={initialValues}
          // serverError={error !== false}
          onSubmit={onFormSubmit}
          validationRules={{
            email: ['required', 'email'],
            password: ['required'],
          }}
          fields={[
            FORM_CONFIGS.signIn.fields[0],
            {
              ...FORM_CONFIGS.signIn.fields[1],
              additionToInput: getForgotPassLink(),
              type,
              iconRightProps,
            },
          ]}
        >
          {/* <Checkbox label={t('rememberPassword')} /> */}
          <Button fullWidth variant="primary" type="submit" iconName="loginArrow">
            {t('signIn')}
          </Button>
          {getText()}
        </Form>
        {/* <SocialButtons socialDisabled={socialDisabled} /> */}
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(SignIn)
