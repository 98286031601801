import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import ReactHelmet from '../../wrappers/ReactHelmet'
import GridLayout from '../Layout/GridLayout'
import TableBlock from '../../components/TableBlock'
import MembersTableCard from './components/MembersTableCard'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectCurrentSubscription, selectMainData } from '../../redux-saga/selectors'
import { getTableData } from '../../redux-saga/actions'

const Members = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('all')
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = useParams()
  const urlId = params?.rightPanelId

  const data = useMappedState(selectMainData('members')) || []

  const planData = useMappedState(selectCurrentSubscription)
  const { limits, counts } = planData || {}
  const membersTotal = counts?.users

  const isAddingNewAllowedByBilling = membersTotal < limits?.users

  useEffect(() => {
    dispatch(getTableData({ type: 'departments', status: 'active', isSystemData: true }))
  }, [])

  return (
    <>
      <ReactHelmet title="membersTitle" />

      <GridLayout
        RightPanelComponent={
          urlId && (
            <Outlet
              context={{ isAddingNewAllowedByBilling: planData ? isAddingNewAllowedByBilling : undefined }}
            />
          )
        }
      >
        <TableBlock
          tableData={data}
          navbarProps={{
            buttonProps: {
              onClick: () => {
                if (isAddingNewAllowedByBilling) {
                  navigate({ pathname: 'invite', search })
                }
              },
              className: isAddingNewAllowedByBilling ? '' : 'disabled',
              text: t('invite'),
              iconName: 'invitePeople',
              padding: '13px 15px',
              tooltipProps: isAddingNewAllowedByBilling
                ? undefined
                : {
                    tipPosition: 'bottom',
                    arrowPosition: 'left',
                    left: '0',
                    text: t('upgradeYourPlanToAdd'),
                  },
            },
          }}
          TableCardContent={MembersTableCard}
          activeRowId={urlId}
          type="members"
          // rightPanelIsOpened={rightPanelIsOpened}
          // leftPanelIsOpened={leftPanelIsOpened}
          // setLeftPanelOpened={setLeftPanelOpened}
          // user={user}
        />
      </GridLayout>
    </>
  )
}

export default Members
