import styled from 'styled-components'

import { getCssFromTheme } from '../../../utils/css'
import { StyledInput } from '../Input/styled'

export const StyledQuantityInput = styled(StyledInput)`
  &.withQuantityButtons {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1px;
    text-align: center;
    //padding: 7px 8px 9px 9px;
  }

  &.isOneNumberInput {
    flex-grow: 0;
    width: 56px;
    height: 56px;
    text-align: center;
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h3')};

    &.hasLeftIcon {
      width: 72px;
    }
    &.hasRightIcon {
      width: 72px;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    border: 1px solid ${({ theme }) => theme.color.general.light};
    color: ${({ theme }) => theme.color.general.black};
    border-radius: 3px;
    padding: 2px 0;
    width: 20px;
    flex-basis: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:first-child {
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      border-top-right-radius: 0;
      margin-top: 1px;
    }
  }
`
