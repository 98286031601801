import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from '../hooks/useInterval'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const updateLocale = require('dayjs/plugin/updateLocale')

require('dayjs/locale/uk')
require('dayjs/locale/en')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)

export const transformDate = (date, format) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format(format || 'DD/MM/YYYY')
}

export const transformDateToUTC = (date) => {
  return dayjs(date).utc().format()
}

export const transformDateToUtcDayWithZeroedTime = (date) => {
  return date
    ? dayjs(date).format().substring(0, 10) + 'T00:00:00Z'
    : dayjs().format().substring(0, 10) + 'T00:00:00Z'
}

export const transformDateWithDay = (date) => {
  const {
    i18n: { language },
  } = useTranslation()

  const timezone = dayjs.tz.guess()

  dayjs.updateLocale('uk', {
    weekdaysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  })

  if (language === 'en') {
    return dayjs.utc(date).tz(timezone).locale(language).format('ddd, DD MMM HH:mm')
  } else {
    return dayjs.utc(date).tz(timezone).locale(language).format('dd, DD MMMM HH:mm')
  }
}

export const useTransformDateFromNow = (date) => {
  const relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  const [currentTime, setCurrentTime] = useState(dayjs())
  useInterval(() => setCurrentTime(dayjs()), 1000, [])

  const {
    i18n: { language },
  } = useTranslation()

  const messageDate = dayjs(date)
  const diffHours = currentTime.diff(messageDate, 'hours')
  const diffWeek = currentTime.diff(messageDate, 'week')

  if (diffHours > 24) {
    return dayjs.utc(date).locale(language).format('DD MMM YY')
  }
  if (diffWeek === 0 && diffHours >= 24) {
    return dayjs.utc(date).locale(language).format('dddd')
  }
  if (diffHours < 24) {
    return dayjs.utc(date).locale(language).fromNow()
  }
}

export const transformDateToClientTimezone = (date, timezone, format, hideTz) => {
  if (!date) {
    return ''
  }

  if (timezone) {
    const timezoneToShow = timezone === 'Europe/Kiev' ? 'Europe/Kyiv' : timezone
    const dateTransformed = dayjs
      .utc(date)
      .tz(timezone)
      .format(format || 'DD/MM/YYYY, HH:mm')

    return hideTz ? dateTransformed : `${dateTransformed} (${timezoneToShow})`
  }

  const operatorTimezone = dayjs.tz.guess() === 'Europe/Kiev' ? 'Europe/Kyiv' : dayjs.tz.guess()

  const dateTransformed = dayjs
    .utc(date)
    .tz(operatorTimezone)
    .format(format || 'DD/MM/YYYY, HH:mm')

  return hideTz ? dateTransformed : `${dateTransformed} (${operatorTimezone})`
}

export const transformUtcDateToISOWithTZ = (date, format) => {
  const tzoffset = new Date(date).getTimezoneOffset() * 60000
  const localISOTime = new Date(new Date(date).valueOf() - tzoffset).toISOString()
  return format ? dayjs(localISOTime).format(format) : localISOTime
}
