import React from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '../../../atomic-design-components'
import theme from '../../../theme'
import { popupAlertShow } from '../../../redux-saga/actions'
import clsx from 'clsx'
import ReactTexty from 'react-texty'

const EmailWithCopyOnClick = ({
  cellData,
  columns,
  column,
  columnIndex,
  rowData,
  rowIndex,
  container,
  isScrolling,
}) => {
  const dispatch = useDispatch()

  const handleClick = (event) => {
    event.stopPropagation()
    setTimeout(
      async () =>
        await navigator.clipboard.writeText(cellData).then(
          function () {
            console.log('Copy to clipboard successfully.')
          },
          function () {
            console.log('Copy to clipboard unsuccessfully.')
          }
        )
    )

    dispatch(
      popupAlertShow({
        contentKey: 'emailCopied',
        type: 'success',
        withCloseButton: true,
        iconName: 'copy',
      })
    )
  }

  return (
    <Typography
      variant="body2"
      color={theme.color.secondary.main}
      className={'emailForCopy'}
      onClick={handleClick}
      as={ReactTexty}
    >
      {cellData}
    </Typography>
  )
}

export default EmailWithCopyOnClick
