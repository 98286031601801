import {
  GENERATE_INVOICE,
  GENERATE_INVOICE_ERROR,
  GENERATE_INVOICE_SUCCESS,
  GET_CHATS_LIST_ERROR,
  GET_INVITATION_LINK,
  GET_INVITATION_LINK_SUCCESS,
  GET_TABLE_ITEM_DETAILS,
  GET_TABLE_ITEM_DETAILS_ERROR,
  GET_TABLE_ITEM_DETAILS_SUCCESS,
  INVITATIONS_RESEND,
  INVITATIONS_RESEND_ERROR,
  INVITATIONS_RESEND_SUCCESS,
  INVITATIONS_SEND,
  INVITATIONS_SEND_ERROR,
  INVITATIONS_SEND_SUCCESS,
  SIDEBAR_ITEM_RESET,
  SIDEBAR_ITEM_SET,
  SIDEBAR_UPSERT,
  SIDEBAR_UPSERT_ERROR,
  SIDEBAR_UPSERT_SUCCESS,
  SIGN_OUT,
  UPDATE_CURRENT_PLAN,
  UPDATE_CURRENT_PLAN_ERROR,
  UPDATE_CURRENT_PLAN_SUCCESS,
} from '../constants'

const defaultState = {
  details: null,
  initial: null,
  sidebarInProgress: false,
  error: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_TABLE_ITEM_DETAILS:
      return {
        ...state,
        sidebarInProgress: true,
      }
    case GET_TABLE_ITEM_DETAILS_ERROR:
    case SIDEBAR_UPSERT_ERROR:
    case GENERATE_INVOICE_ERROR:
    case INVITATIONS_SEND_ERROR:
    case INVITATIONS_RESEND_ERROR:
    case GET_CHATS_LIST_ERROR:
      return {
        ...state,
        sidebarInProgress: false,
        error: action.error,
      }
    case GET_TABLE_ITEM_DETAILS_SUCCESS:
      // case GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        error: false,
        sidebarInProgress: false,
        details: action.item,
      }
    case SIDEBAR_UPSERT:
    case GENERATE_INVOICE:
    case INVITATIONS_SEND:
    case INVITATIONS_RESEND:
    case GET_INVITATION_LINK:
    case UPDATE_CURRENT_PLAN:
      return {
        ...state,
        sidebarInProgress: true,
      }
    case INVITATIONS_SEND_SUCCESS:
    case INVITATIONS_RESEND_SUCCESS:
    case GENERATE_INVOICE_SUCCESS:
    case GET_INVITATION_LINK_SUCCESS:
    case UPDATE_CURRENT_PLAN_SUCCESS:
    case UPDATE_CURRENT_PLAN_ERROR:
      return {
        ...state,
        sidebarInProgress: false,
      }
    case SIDEBAR_UPSERT_SUCCESS:
      return {
        ...state,
        details: action.item || null,
        error: false,
        sidebarInProgress: false,
      }
    case SIDEBAR_ITEM_SET:
      return {
        ...state,
        [action.key || 'details']: action.item,
      }
    case SIDEBAR_ITEM_RESET:
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
