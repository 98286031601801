import React from 'react'

export const emptyChatWidgets = ({ width, height, fill, theme: { color } }) => (
  <svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
    <g clipPath="url(#clip0_11494_230800)">
      <circle cx="100.004" cy="100" r="100" fill={color.general.lightest} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.013 61.3555C106.004 61.5695 106 61.7846 106 62.0008C106 70.8373 113.163 78.0008 122 78.0008H135V116.417C135 120.203 131.903 123.299 128.118 123.299H91.9841L70.6475 143.671V123.298H56.8825C53.0966 123.298 50 120.201 50 116.415V68.238C50 64.4521 53.0966 61.3555 56.8825 61.3555H106.013Z"
        fill={color.general.light}
      />
      <path
        d="M110 62C110 55.3726 115.373 50 122 50H138C144.627 50 150 55.3726 150 62C150 68.6274 144.627 74 138 74H122C115.373 74 110 68.6274 110 62Z"
        fill="#DADADA"
      />
      <circle cx="122" cy="62" r="9" fill={color.general.dark} />
      <path
        d="M144.575 84.0684H88.425C85.4409 84.0684 83 86.5092 83 89.4934V127.47C83 130.454 85.4409 132.895 88.425 132.895H116.907L133.725 148.953V132.894H144.575C147.559 132.894 150 130.453 150 127.469V89.4934C150 86.5092 147.559 84.0684 144.575 84.0684Z"
        fill={color.general.lighter}
      />
    </g>
    <defs>
      <clipPath id="clip0_11494_230800">
        <rect width="200" height="200" fill={color.general.white} />
      </clipPath>
    </defs>
  </svg>
)
