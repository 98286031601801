import React from 'react'

import TemplatesLeftPanel from '../../Templates/panels/TemplatesLeftPanel'
import { useMappedState } from '../../../hooks/useReactRedux'
import { selectMainData, selectUserMemberId } from '../../../redux-saga/selectors'
import TemplatesInChatsSearch from './TemplatesInChatsSearch'

const ChatTemplates = () => {
  const templates = useMappedState(selectMainData('templates')) || []
  const operatorID = useMappedState(selectUserMemberId)

  const getTemplatesList = () => {
    const general = templates?.length > 0 ? templates.filter((item) => item.operator_id === null) : []
    const personal = templates?.length > 0 ? templates.filter((item) => item.operator_id === operatorID) : []

    return [{ general }, { personal }]
  }

  return (
    <>
      <TemplatesInChatsSearch />
      <TemplatesLeftPanel templates={getTemplatesList()} variant="chats" className='chatTemplatesList' />
    </>
  )
}

export default ChatTemplates
