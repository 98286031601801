import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const ReactHelmet = ({ title, content, children }) => {
  const { t } = useTranslation('meta')

  return (
    <Helmet>
      <meta name="description" content={t(content)} />
      <title>{t(title)}</title>
      {children}
    </Helmet>
  )
}

export default ReactHelmet
