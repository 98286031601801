import React from 'react'

export const bell = ({ width, height, fill, theme }) => (
  <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <path
      d="M8.62891 18.5715H11.3718"
      stroke={fill || theme.color.general.darker}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4856 8.2857C15.4856 6.8308 14.9076 5.43549 13.8789 4.40672C12.8501 3.37795 11.4548 2.79999 9.99989 2.79999C8.54499 2.79999 7.14967 3.37795 6.1209 4.40672C5.09213 5.43549 4.51417 6.8308 4.51417 8.2857V13.0857C4.51417 13.6313 4.29744 14.1545 3.91165 14.5403C3.52586 14.9261 3.00262 15.1428 2.45703 15.1428H17.5427C16.9972 15.1428 16.4739 14.9261 16.0881 14.5403C15.7023 14.1545 15.4856 13.6313 15.4856 13.0857V8.2857Z"
      stroke={fill || theme.color.general.darker}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.08594 8.10745C1.08668 6.80204 1.39798 5.51554 1.99415 4.35422C2.59031 3.1929 3.45422 2.19007 4.51451 1.42859"
      stroke={fill || theme.color.general.darker}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9129 8.10745C18.9122 6.80204 18.6009 5.51554 18.0047 4.35422C17.4086 3.1929 16.5447 2.19007 15.4844 1.42859"
      stroke={fill || theme.color.general.darker}
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
