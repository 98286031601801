import React from 'react'
import { useTranslation } from 'react-i18next'

import TableSearch from '../../../atomic-design-components/organisms/Table/components/TableSearch'
import theme from '../../../theme'
import { StyledContainer } from './styled'

const TemplatesListSearch = ({ variant }) => {
  const { t } = useTranslation('all')

  const iconLeftProps = { name: 'searchSign', fill: theme.color.general.dark }
  const iconRightProps = { name: 'cross', size: 16 }

  return (
    <StyledContainer className="searchContainer">
      <TableSearch
        iconLeftProps={iconLeftProps}
        iconRightProps={iconRightProps}
        placeholder={t('search')}
        placeholderColor={theme.color.general.light}
        className="searchInput"
        withoutBorder
        withoutValidation
      />
    </StyledContainer>
  )
}

export default TemplatesListSearch
