import React, { useState } from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'

import { StyledExpansionPanel, StyledHeaderWrapper } from './styled'
import Icon from '../../atoms/Icon'
import { useEffect } from 'react'

const ExpansionPanel = (props) => {
  const {
    className,
    children,
    disabled,
    align,
    header,
    // headerBackgroundColor,
    headerIconRightProps,
    // headerIconLeftProps,
    initialOpened,
    isHidden,
    marginBottomWhenOpened,
    panelName,
    withChevronInHeader,
    ...otherProps
  } = props
  const currentPanelStates = JSON.parse(localStorage.getItem('explPanelStates'))
  const hasLocalStorageState = panelName && currentPanelStates?.[panelName] !== undefined

  const [opened, setOpened] = useState(hasLocalStorageState ? currentPanelStates?.[panelName] : initialOpened)

  useEffect(() => {
    const explPanelStates = panelName ? { ...currentPanelStates, [panelName]: opened } : currentPanelStates
    window.localStorage.setItem('explPanelStates', JSON.stringify(explPanelStates))
  }, [opened])

  const toggleOpened = () => {
    setOpened((prevValue) => !prevValue)
  }

  if (isHidden) {
    return (
      <StyledExpansionPanel align={align} className={clsx(className, 'expansionPanel')} {...otherProps}>
        {children}
      </StyledExpansionPanel>
    )
  }

  return (
    <StyledExpansionPanel
      marginBottomWhenOpened={marginBottomWhenOpened}
      align={align}
      className={clsx(
        className,
        opened && 'opened',
        'expansionPanel',
        !children || (Array.isArray(children) && !children.length && 'empty')
      )}
      {...otherProps}
    >
      <StyledHeaderWrapper
        className={clsx(opened && 'opened', disabled && 'disabled', 'panelHeader')}
        onClick={disabled ? undefined : toggleOpened}
      >
        {/*{headerIconLeftProps && <Icon {...headerIconLeftProps} />}*/}
        {header}
        {headerIconRightProps && <Icon {...headerIconRightProps} />}
        {withChevronInHeader && <Icon name="chevronDown" className="headerChevronIcon" />}
      </StyledHeaderWrapper>
      {opened && children}
      {/*<StyledDetailsWrapper>{children}</StyledDetailsWrapper>*/}
    </StyledExpansionPanel>
  )
}

export default ExpansionPanel

ExpansionPanel.propTypes = {
  align: T.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  children: T.node,
  className: T.string,
  disabled: T.bool,
  header: T.oneOfType([T.string, T.object]),
  isHidden: T.bool,
  // headerBackgroundColor: T.string,
  // detailsBackgroundColor: T.string,
  marginBottomWhenOpened: T.string,
  withChevronInHeader: T.bool,
}

ExpansionPanel.defaultProps = {
  withChevronInHeader: true,
}
