import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { useDispatch } from 'react-redux'
import { RIGHT_PANEL_CUSTOM_ROUTES } from '../../config'
import { DEFAULT_RIGHT_PANEL_DATA } from '../../config/defaultValues'
import { useMappedState } from '../../hooks/useReactRedux'
import { ScreenContext } from '../../hooks/useScreenContext'
import {
  getTableItemDetails,
  sidebarItemReset,
  sidebarItemSet,
  toggleNavMenuExtended,
} from '../../redux-saga/actions'
import { selectDataType } from '../../redux-saga/selectors'
import GridLayoutDesktop from './GridLayoutDesktop'
import GridLayoutMobile from './GridLayoutMobile'

const RIGHT_PANEL_TITLE_KEYS = {
  members: {
    invite: 'inviteMember',
  },
  customers: {
    'generate-invoice': 'bankAccountDetails',
  },
  chats: { forward: 'forwardTo', templates: 'templates' },
  departments: { new: 'addingDepartment' },
  widgets: { new: 'addingChatWidget' },
}

const GridLayout = ({ children, theme, isLeftPanelShown, ...otherProps }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { width, md, lg } = useContext(ScreenContext)
  const { t } = useTranslation(['menu', 'all'])

  const params = useParams()
  const { rightPanelId, action, chatId, templateId } = params

  const type = useMappedState(selectDataType)

  const [isRightPanelOpened, setIsRightPanelOpened] = React.useState(false)
  const [isLeftPanelOpened, setIsLeftPanelOpened] = React.useState(isLeftPanelShown)

  const panelsProps = {
    isRightPanelOpened,
    setIsRightPanelOpened,
    isLeftPanelOpened,
    setIsLeftPanelOpened,
  }

  useEffect(() => {
    setIsRightPanelOpened(!!rightPanelId || !!action)
  }, [rightPanelId, action])

  useEffect(() => {
    if (width <= lg) {
      if (isRightPanelOpened) {
        dispatch(toggleNavMenuExtended(false))
      }
    }
  }, [isRightPanelOpened, width])

  const onCloseRightPanel = () => {
    if ((rightPanelId || action) && chatId) {
      navigate({ pathname: chatId, search })
      return
    }
    if (rightPanelId || action) {
      navigate({ pathname: '.', search })
    }
    if (pathname.includes('chat-customization') || pathname.includes('auto-messages')) {
      navigate({ pathname: '../my-company/chat-widgets', search })
    }
  }

  useEffect(() => {
    if (width <= md) {
      if (pathname.includes('templates')) {
        setIsLeftPanelOpened(!templateId)
      }
      if (pathname.includes('chats')) {
        setIsLeftPanelOpened(!chatId)
      }
      if (pathname === '/my-company') {
        setIsLeftPanelOpened(true)
      }
      if (pathname.includes('/my-company/')) {
        setIsLeftPanelOpened(false)
      }
      if (pathname === '/settings') {
        setIsLeftPanelOpened(true)
      }
      if (pathname.includes('/settings/')) {
        setIsLeftPanelOpened(false)
      }
    }
  }, [chatId, templateId, width, pathname])

  const getRightPanelTitle = () => {
    const title = pathname.includes('chat-customization')
      ? 'chat-customization'
      : pathname.includes('auto-messages')
      ? 'auto-messages'
      : RIGHT_PANEL_TITLE_KEYS[type]?.[action || (rightPanelId && 'details')]
    return title && t(`all:${title}`)
  }

  useEffect(() => {
    if (type) {
      if (!rightPanelId) {
        dispatch(sidebarItemReset())
      } else if (rightPanelId) {
        if (RIGHT_PANEL_CUSTOM_ROUTES.includes(rightPanelId)) {
          dispatch(sidebarItemSet(DEFAULT_RIGHT_PANEL_DATA[pathname]))
        } else {
          dispatch(getTableItemDetails(rightPanelId, type === 'chats' ? 'contacts' : type))
        }
      }
    }
  }, [rightPanelId, type])

  if (width <= md) {
    return (
      <GridLayoutMobile
        onCloseRightPanel={onCloseRightPanel}
        t={t}
        type={type}
        rightPanelTitle={getRightPanelTitle()}
        {...otherProps}
        {...panelsProps}
      >
        {children}
      </GridLayoutMobile>
    )
  }

  return (
    <GridLayoutDesktop
      onCloseRightPanel={onCloseRightPanel}
      t={t}
      theme={theme}
      type={type}
      rightPanelTitle={getRightPanelTitle()}
      {...otherProps}
      {...panelsProps}
    >
      {children}
    </GridLayoutDesktop>
  )
}

export default withTheme(GridLayout)
