import React from 'react'
import { Outlet } from 'react-router-dom'
import ReactHelmet from '../../wrappers/ReactHelmet'

import GridLayout from '../Layout/GridLayout'
import EmptyScreen from '../../components/EmptyScreen/EmptyScreen'
import ChatsLeftPanel from './panels/ChatsLeftPanel'
import { StyledChatsWrapper } from './components/styled'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectIsNoChatsFound } from '../../redux-saga/selectors'
import ChatContainer from './ChatContainer'

const Chats = () => {
  const hasChats = !useMappedState(selectIsNoChatsFound)

  return (
    <>
      <ReactHelmet title="chatsTitle" />

      <GridLayout
        LeftPanelComponent={<ChatsLeftPanel />}
        RightPanelComponent={<Outlet />} // (urlId || action) && <Outlet />
        isLeftPanelShown={true}
      >
        {hasChats ? (
          <StyledChatsWrapper>
            <ChatContainer />
          </StyledChatsWrapper>
        ) : (
          <EmptyScreen type="chats" icon="noChats" />
        )}
      </GridLayout>
    </>
  )
}

export default Chats
