import React, { useState, useEffect } from 'react'

import { StyledAnalyticsCircle } from './styled'
import { FlexRow, Typography, Icon, Color, Tag } from '../../../atomic-design-components'
import { getPercent } from '../../../utils/dataTransformations'
import ReactTexty from 'react-texty'

const AnalyticsCircle = ({ color, t, data, header, iconName, isTagsAnalytics, className }) => {
  const COLORS = [
    ...Object.values(color.primary),
    ...Object.values(color.secondary),
    ...Object.values(color.tertiary),
  ]

  const [usedColors, setUsedColors] = useState(COLORS)
  useEffect(() => {
    setUsedColors(COLORS.sort(() => 0.5 - Math.random()))
  }, [data.length])

  let prevPercent = 0

  return (
    <StyledAnalyticsCircle color={color} className={className}>
      <FlexRow gap="6px">
        <Icon name={iconName} size={18} fill={color.primary.main} />
        <Typography variant="caption2" text={t(header)} textTransform="capitalize" />
      </FlexRow>

      {!isTagsAnalytics && (
        <div className="circleContainer">
          <svg viewBox="0 0 36 36" className="circular-chart">
            {data.map((part, i) => {
              const percent = Math.round(getPercent(part.quantity, data))
              const strokeDasharray =
                i === 0 ? `${percent}, ${100 - percent}` : `0, ${prevPercent}, ${percent}, ${100 - percent} `

              prevPercent += percent

              if (part.value)
                return (
                  <path
                    key={part.value}
                    className="circle"
                    strokeDasharray={strokeDasharray}
                    stroke={isTagsAnalytics ? `${usedColors[i]}80` : usedColors[i]}
                    strokeWidth="2.5"
                    fill="none"
                    d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                )
            })}
          </svg>
        </div>
      )}

      <div className="legend">
        {data.map((part, i) => {
          const percent = getPercent(part.quantity, data)

          if (part.value === 'Europe/Kiev') part.value = 'Europe/Kyiv'

          if (part.value)
            return (
              <FlexRow gap="6px" padding="4px 0" key={part.value}>
                {!isTagsAnalytics && (
                  <Color color={isTagsAnalytics ? `${usedColors[i]}80` : usedColors[i]} size={10} />
                )}
                {isTagsAnalytics && (
                  <Tag
                    text={part.value}
                    className="nameText"
                    backgroundColor={usedColors[i]}
                    withBackgroundOpacity={true}
                    hexOpacity="80"
                  />
                )}
                {!isTagsAnalytics && (
                  <Typography
                    variant="button2"
                    text={part.value}
                    className="nameText"
                    textTransform="capitalize"
                    as={ReactTexty}
                  />
                )}
                <FlexRow gap="5px" justifyContent="space-between" style={{ width: '90px' }}>
                  <Typography variant="button2" text={part.quantity} />
                  {!isTagsAnalytics && (
                    <Typography variant="button2" text={`${percent}%`} style={{ width: '50%' }} />
                  )}
                </FlexRow>
              </FlexRow>
            )
        })}
      </div>
    </StyledAnalyticsCircle>
  )
}

export default AnalyticsCircle
