import React from 'react'
import { AutoResizer } from 'react-base-table'
import { withTheme } from 'styled-components'

import 'react-base-table/styles.css'
import 'react-texty/styles.css'

import TableCell from './components/Cell'
import EmptyTable from './components/Empty'
import SortIndicator from './components/SortIndicator'
import { StyledBaseTable, StyledTableWrapper } from './styled'

const Table = ({
  children,
  className,
  columns,
  components,
  data,
  emptyText,
  headerHeight,
  isReadOnly,
  maxHeight,
  onColumnSort,
  onResize,
  onRowClick,
  paddingsAndBorders = 0,
  rowHeight,
  rowClassName,
  rowKey,
  sortBy,
  theme,
  ...rest
}) => {
  return (
    <AutoResizer onResize={onResize}>
      {({ width, height }) => {
        return (
          <StyledTableWrapper>
            <StyledBaseTable
              emptyRenderer={<EmptyTable text={emptyText} />}
              columns={columns}
              data={data}
              width={width - paddingsAndBorders}
              height={height}
              maxHeight={maxHeight}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              rowClassName={rowClassName}
              className={className}
              rowKey={rowKey || 'id'}
              components={{ TableCell, SortIndicator, ...components }}
              sortBy={sortBy}
              onColumnSort={onColumnSort}
              rowEventHandlers={{ onClick: onRowClick }}
              {...rest}
            >
              {children}
            </StyledBaseTable>
          </StyledTableWrapper>
        )
      }}
    </AutoResizer>
  )
}

export default withTheme(Table)
