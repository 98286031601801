import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import EmptyScreen from '../../components/EmptyScreen'
import { deleteCompany } from '../../redux-saga/actions'
import { selectCompanyId } from '../../redux-saga/selectors'
import { useMappedState } from '../../hooks/useReactRedux'

const MyCompanyDelete = ({ theme }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const customerId = useMappedState(selectCompanyId)

  const onDeleteConfirm = () => {
    dispatch(deleteCompany(customerId, navigate))
  }

  const buttonProps = {
    iconName: 'trashCan',
    confirmButtonProps: {
      fullWidth: true,
      iconLeftProps: {
        size: 16,
        margin: '0 5px 0 0',
        fill: theme.color.general.white,
        name: 'inputError',
      },
      backgroundColor: theme.color.status.error,
      color: theme.color.general.white,
      onDeleteConfirm: onDeleteConfirm,
    },
  }

  return <EmptyScreen type="deleteCompany" icon="deleteCompany" hasButton buttonProps={buttonProps} />
}

export default withTheme(MyCompanyDelete)
