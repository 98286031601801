import styled from 'styled-components'

import InputMask from 'react-input-mask'
import { getCssFromTheme } from '../../../utils/css'

export const StyledInput = styled(InputMask)`
  ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
  flex-grow: 1;
  padding: 8px 15px;
  color: ${({ theme }) => theme.color.general.black};
  border-radius: 3px;
  min-height: 42px;
  resize: none;
  border: none;

  &.multiline {
    line-height: 22px;
  }

  &.hasLeftIcon {
    padding-left: 39px; // icon 16px + padding 15px + 8px
  }
  &.hasRightIcon {
    padding-right: 47px; // icon 15px + 2*padding 16px
  }
  &.hasError {
    padding-right: 39px;
  }

  .disabled & {
    background-color: ${({ theme }) => theme.color.general.lightest};
    color: ${({ theme }) => theme.color.general.light};
    cursor: not-allowed;
  }

  ::placeholder {
    opacity: 1;
    color: ${({ theme, placeholderColor }) => placeholderColor || theme.color.general.dark};
  }

  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    /* Internet Explorer 10-11 */ /* Microsoft Edge */
    color: ${({ theme, placeholderColor }) => placeholderColor || theme.color.general.dark};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
`
