import clsx from 'clsx'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { FILE_TYPES } from '../../constants'
import { useMappedState } from '../../hooks/useReactRedux'

import { getUploadedData, inputFilesAdd } from '../../redux-saga/actions'
import { selectFilesDraft } from '../../redux-saga/selectors'
import { isObjectEmpty } from '../../utils/isObjectEmpty'
import DropZone from '../DropZone'
import { StyledUploadImages } from './styled'

const UploadImages = ({
  chatID,
  maxFiles,
  maxSize,
  isSimpleButton,
  onHide,
  noClick,
  hiddenZone,
  dropHeight,
}) => {
  const dispatch = useDispatch()
  const draftFiles = useMappedState(selectFilesDraft)

  const [isDropZoneActive, setIsDropZoneActive] = useState(false)
  const fnSet = (newFiles) => {
    if (!isObjectEmpty(newFiles)) {
      dispatch(inputFilesAdd(newFiles, chatID))
      dispatch(getUploadedData(newFiles, chatID))
      onHide()
      if (chatID === 'bulkMessage') {
        document.querySelector('textarea[name="message"]')?.focus()
      } else {
        document.getElementById(`chatTextarea-${chatID}`).focus()
      }
    }
  }

  return (
    <>
      <StyledUploadImages
        className={clsx(hiddenZone && 'hidden', isDropZoneActive && 'active')}
        dropHeight={dropHeight}
      >
        <DropZone
          fileType={{ ...FILE_TYPES.images, ...FILE_TYPES.files }} //parse file_types
          setFiles={fnSet}
          type={chatID}
          currentFiles={draftFiles[chatID]}
          isSimpleButton={isSimpleButton}
          maxFiles={maxFiles}
          maxSize={maxSize}
          noClick={noClick}
          setIsDropZoneActive={setIsDropZoneActive}
        />
      </StyledUploadImages>
    </>
  )
}

export default UploadImages
