import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import ReactHelmet from '../../wrappers/ReactHelmet'

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist'
import Form from '../../components/Form'
import { FORM_CONFIGS } from '../../config/forms'
import Button from '../../atomic-design-components/molecules/Button'
import backOrange from '../../assets/images/backOrange.jpg'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'
import { userResetPassword } from '../../redux-saga/actions'
import { useNavigate, useLocation } from 'react-router-dom'

const initialValues = { email: '', password: '' }

const NewPassword = ({ theme }) => {
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(undefined)
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const { type, iconRightProps } = useIsPasswordShown()
  const navigate = useNavigate()

  const { t } = useTranslation('auth')
  const dispatch = useDispatch()

  const { search } = useLocation()
  const userCode = search && parse(search)?.code

  const onFormSubmit = (credentials) => {
    dispatch(userResetPassword(false, { ...credentials, code: userCode }, navigate))
  }

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper type="newPassword" theme={theme} imageUrl={backOrange}>
        <Form
          className="form"
          type="newPassword"
          withCustomValidationRule
          initialValues={initialValues}
          // serverError={error !== false}
          onSubmit={onFormSubmit}
          validationRules={{
            password: ['required', { type: 'password', isPasswordValid }],
          }}
          fields={[
            {
              ...FORM_CONFIGS.newPassword.fields[0],
              type,
              iconRightProps,
              onInputValueChange: (value) => setPassword(value),
            },
          ]}
        >
          <CustomPasswordChecklist
            password={password}
            setIsPasswordValid={setIsPasswordValid}
            className={isSubmitPressed && !isPasswordValid ? 'passError' : ''}
          />
          <Button
            fullWidth
            variant="primary"
            type="submit"
            iconName="passwordLocked"
            onClick={() => setIsSubmitPressed(true)}
          >
            {t('changePassword')}
          </Button>
        </Form>
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(NewPassword)
