import React from 'react'

export const logoSymbolFilled = ({ width, height, fill }) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16">
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill={fill || 'black'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2515 5.16954C12.5407 5.52785 13.2557 6.60117 13.3334 7.92675H11.9217C11.8032 7.34992 11.4365 6.92252 10.8848 6.76907C9.91666 6.49994 9.37856 7.35876 8.8805 8.1541C8.85407 8.19668 8.82845 8.23846 8.80202 8.28023L8.59703 8.59596L8.5658 8.64417C7.94523 9.6034 6.81938 11.3427 4.74306 10.7651C3.33376 10.373 2.69237 9.28125 2.66675 7.92675H4.07044C4.14811 8.50759 4.45961 8.9848 5.10901 9.16556C6.30131 9.49736 6.92669 8.50438 7.53045 7.53791L7.74664 7.17639C8.50254 5.97694 9.52749 4.68992 11.2507 5.16954H11.2515Z"
      fill="white"
    />
  </svg>
)
