import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../../constants'

export const StyledContainer = styled.div`
  padding: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-top: 1px solid transparent;
  }

  .inputWrapper.searchInput {
    flex-grow: 1;
  }

  .withInsideButtons {
    padding-bottom: 0;

    input {
      padding-left: 10px;
    }
  }

  .inputContainer {
    flex-grow: 1;
  }

  &.searchContainer {
    padding: 9px 20px;

    .withoutBorder:focus-within {
      border: 1px solid transparent !important;
      outline: none !important;
    }

    .leftIcon {
      top: 12px;
      left: 0;
    }

    .hasLeftIcon {
      padding-left: 24px;
    }

    .rightIcon {
      padding-right: 0;
    }
  }

  &.chatTemplatesList {
    .template {
      user-select: none;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 20px 14px;

    &.searchContainer {
      padding: 10px 14px;
    }
    &.searchContainer:not(.searchMessageContainer) {
      top: ${HEADER_HEIGHT}px;

      .hasLeftIcon {
        padding-left: 39px;
      }
      .icon.leftIcon {
        left: 15px;
      }
    }
  }
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  .itemsCountBadge {
    margin-left: 5px;
  }
`

export const StyledArea = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 630px;
  margin: ${HEADER_HEIGHT}px auto 30px;
  gap: 20px;
  padding: 0 30px;

  .inputContainer {
    margin-bottom: 0;
  }
  .textareaActionButton {
    padding: 12px;

    .icon {
      margin: 0;
    }
  }

  .ql-container {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .ql-toolbar {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .ql-editor,
  textarea {
    min-height: 220px;
    max-height: 400px;
  }

  .ql-disabled {
    background-color: ${({ theme }) => theme.color.general.lightest};
    color: ${({ theme }) => theme.color.general.dark};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 20px auto;
  }
`

export const StyledItemContent = styled.div`
  padding: 10px 15px;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &.template {
    background-color: ${({ theme }) => theme.color.general.lightest};
  }

  &.active {
    background-color: ${({ theme }) => theme.color.status.new};
    .typography {
      color: ${({ theme }) => theme.color.general.white};
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .caption1 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
