import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'

import ReactHelmet from '../../wrappers/ReactHelmet'
import GridLayout from '../Layout/GridLayout'
import TableBlock from '../../components/TableBlock'
import CustomersTableCard from '../Customers/components/CustomersTableCard'
import { useMappedState } from '../../hooks/useReactRedux'
import { selectMainData } from '../../redux-saga/selectors'

const Customers = () => {
  const { t } = useTranslation('all')
  const params = useParams()
  const urlId = params?.rightPanelId

  const data = useMappedState(selectMainData('customers')) || []

  return (
    <>
      <ReactHelmet title="customersTitle" />

      <GridLayout RightPanelComponent={urlId && <Outlet />}>
        <TableBlock
          tableData={data}
          TableCardContent={CustomersTableCard}
          // tableCardHeight={190}
          activeRowId={urlId}
          type="customers"
          navbarProps={{}}
          // rightPanelIsOpened={rightPanelIsOpened}
          // leftPanelIsOpened={leftPanelIsOpened}
          // setLeftPanelOpened={setLeftPanelOpened}
          // user={user}
        />
      </GridLayout>
    </>
  )
}

export default Customers
