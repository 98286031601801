import styled from 'styled-components'

export const StyledCardContent = styled.div`
  .caption1 {
    margin-left: 10px;
  }
  .statusIcon {
    margin: 0 10px 0 0;
  }
  .squezzyBlock {
    position: relative;
    min-width: 145px;
  }
  .cardTableItemRow {
    height: 26px;
  }
`
