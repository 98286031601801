import React, { forwardRef, useEffect } from 'react'
import { PropTypes as T } from 'prop-types'
import clsx from 'clsx'
import { withTheme } from 'styled-components'

import Icon from '../../atoms/Icon'
import {
  StyledButtonsRow,
  StyledErrorLabel,
  StyledInput,
  StyledLabel,
  StyledWrapper,
  StyledInputWrapper,
} from './styled'
import QuantityInput from '../QuantityInput'
import { useDebounce } from '../../../hooks/useDebounce'
import Typography from '../../atoms/Typography'
import { isObjectEmpty } from '../../../utils/isObjectEmpty'
import RichTextEditor from '../RichTextEditor'

const Input = forwardRef((props, ref) => {
  const {
    children,
    className,
    disabled,
    error,
    field,
    fullWidth,
    iconLeftProps,
    iconRightProps,
    initialValue,
    inputRef,
    insideButtons,
    isOneNumberInput,
    label,
    labelType,
    labelWidth,
    maxLength,
    multiline,
    name,
    onBlur,
    onChange,
    onEnter,
    onSubmit,
    placeholder,
    placeholderColor,
    required,
    rows,
    submitByEnterPressed = true,
    success,
    theme: { color },
    type,
    value,
    withDebounce,
    withQuantityButtons,
    withoutBorder,
    withoutValidation,
    ...otherProps
  } = props
  const debouncedValue = useDebounce(value, 300)

  const onChangeState = (e) => {
    if (onChange && !withDebounce) {
      onChange(e)
    }
  }

  useEffect(() => {
    if (onChange && withDebounce) {
      onChange(debouncedValue)
    }
  }, [withDebounce, debouncedValue, onChange])

  const handleKeyDown = (e) => {
    // it triggers by pressing the enter key
    if (!disabled && submitByEnterPressed && (onEnter || onSubmit) && e.key === 'Enter') {
      if (onSubmit) {
        onSubmit(e.target.value)
      }
      if (onEnter) {
        onEnter(e.target.value)
      }
    }
  }

  const hasLeftIcon = !isObjectEmpty(iconLeftProps)
  const hasRightIcon = !isObjectEmpty(iconRightProps)

  const getInputField = () => {
    const classes = clsx(
      !!error && 'hasError',
      success && 'success',
      hasLeftIcon && 'hasLeftIcon',
      hasRightIcon && 'hasRightIcon',
      multiline && 'multiline',
      withQuantityButtons && 'withQuantityButtons',
      isOneNumberInput && 'isOneNumberInput'
      // primaryFocusColor && 'primaryFocusColor'
    )

    if (type === 'number') {
      return (
        <QuantityInput
          inputClassName={classes}
          isOneNumberInput={isOneNumberInput}
          withQuantityButtons={withQuantityButtons}
          maxLength={maxLength}
          {...props}
        />
      )
    } else if (type === 'richText' && typeof window !== 'undefined') {
      return <RichTextEditor inputClassName={classes} {...props} />
    } else {
      return (
        <StyledInput
          onKeyDown={handleKeyDown}
          as={multiline && 'textarea'}
          name={name}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={onChangeState}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          type={type}
          className={classes}
          ref={inputRef}
          rows={rows}
          maxLength={maxLength}
          {...otherProps}
        />
      )
    }
  }

  return (
    <StyledInputWrapper
      className={clsx(className, withoutValidation && 'withoutValidation', 'inputContainer')}
    >
      {label && labelType === 'top' && (
        <StyledLabel
          as={Typography}
          variant="caption1"
          className={clsx(required && 'required', 'label', 'top')}
        >
          {label}
        </StyledLabel>
      )}
      <StyledWrapper
        ref={ref}
        fullWidth={fullWidth}
        type={type}
        className={clsx(
          'inputWrapper',
          disabled && 'disabled',
          !!error && 'hasError',
          success && 'success',
          insideButtons && 'withInsideButtons',
          withoutBorder && 'withoutBorder'
        )}
      >
        {label && labelType === 'border' && (
          <StyledLabel
            className={clsx(required && 'required', 'label', 'border')}
            isUppercase={field?.isUppercase}
          >
            {label?.toLowerCase()}
          </StyledLabel>
        )}
        {!!iconLeftProps && (
          <Icon
            width={16}
            height={16}
            className="leftIcon"
            fill={disabled ? color.general.lighter : error ? color.status.error : color.general.light}
            {...iconLeftProps}
          />
        )}

        {getInputField()}

        {insideButtons && <StyledButtonsRow className="buttonsRow">{insideButtons}</StyledButtonsRow>}

        {!!iconRightProps && (
          <Icon
            size={15}
            className="rightIcon"
            fill={disabled ? color.general.lighter : error ? color.status.error : color.general.black}
            {...iconRightProps}
          />
        )}

        {success && (
          <Icon
            name="checkRounded"
            borderRadius="50%"
            wrapperHeight={14}
            wrapperWidth={14}
            width={8}
            className="rightIcon success"
          />
        )}

        {children}
      </StyledWrapper>
      {!!error && typeof error === 'string' && (
        <StyledErrorLabel icon={<Icon name="inputError" size={13} />} className="error">
          {error}
        </StyledErrorLabel>
      )}
    </StyledInputWrapper>
  )
})

Input.propTypes = {
  disabled: T.bool,
  error: T.oneOfType([T.bool, T.string]),
  fullWidth: T.bool,
  isOneNumberInput: T.bool,
  label: T.string,
  labelType: T.oneOf(['top', 'border']),
  maxLength: T.number,
  multiline: T.bool,
  name: T.string,
  onChange: T.func,
  placeholder: T.string,
  primaryFocusColor: T.bool,
  type: T.oneOf(['text', 'password', 'number', 'richText', 'time']),
  withDebounce: T.bool,
  withoutValidation: T.bool,
  className: T.string,
  iconLeftProps: T.object,
  iconRightProps: T.object,
  // insideButtons
  labelWidth: T.string,
  onSubmit: T.func,
  placeholderColor: T.string,
  submitByEnterPressed: T.bool,
  success: T.bool,
  theme: T.object,
  value: T.oneOfType([T.object, T.string]),
  withQuantityButtons: T.bool,
  withoutBorder: T.bool,
}

Input.defaultProps = {
  error: '',
  isOneNumberInput: true,
  labelType: 'border',
  type: 'text',
  withQuantityButtons: false,
}

export default withTheme(Input)
