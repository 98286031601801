import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { Link, Typography } from '../../atomic-design-components'
import HeaderMobile from '../../components/HeaderMobile/HeaderMobile'
import NavMenu from '../../components/NavMenu/NavMenu'
import { ENTITIES, MY_COMPANY, SETTINGS } from '../../config'
import { NO_ACTIVE_CHANNELS_WARNING_ID } from '../../constants'
import { useMappedState } from '../../hooks/useReactRedux'
import { ScreenContext } from '../../hooks/useScreenContext'
import {
  getTableData,
  popupAlertHide,
  popupAlertHideAll,
  popupAlertShow,
  setDataType,
} from '../../redux-saga/actions'
import { selectMainData, selectPopupAlerts, selectUserRole } from '../../redux-saga/selectors'
import theme from '../../theme'
import ErrorBoundary from '../../wrappers/ErrorBoundary'
import { StyledMainWrapper } from './styled'

const getEntityByRoute = (pathname) => {
  const getEntity = (configObject) => {
    const array = Array.isArray(configObject) ? configObject : Object.values(configObject).flat()
    return array.find((entity) => entity.route !== '/' && pathname.includes(entity.route))
  }

  return pathname === '/'
    ? { key: 'dashboard' }
    : getEntity(ENTITIES) || getEntity(MY_COMPANY) || getEntity(SETTINGS)
}

const getHasActiveChannel = (widgets) => {
  if (widgets.length > 0) {
    const hasActiveChannel = widgets.some((widget) => {
      if (widget.status === 'active') {
        return Object.values(widget.channels).some((channel) => {
          return channel.status === 'active'
        })
      }
    })
    return hasActiveChannel
  }
  return false
}

const MainWrapper = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('popupAlerts')

  const { width, md, xl, height } = useContext(ScreenContext)
  const isTablet = width <= xl && width > md
  const isRevertView = width > height

  const { pathname } = useLocation()
  const typeData = getEntityByRoute(pathname)

  const [isWarningShown, setIsWarningShown] = useState(false)
  const widgets = useMappedState(selectMainData('widgets'))

  const currentUserRole = useMappedState(selectUserRole)
  const popupAlerts = useMappedState(selectPopupAlerts)

  const linkToWidgets = () => {
    return (
      <>
        <Typography text={t('noActiveChannelsText')} variant="body2" className="popupText" />
        <Link to="/my-company/chat-widgets" noStyles themeColor="general.white">
          <Typography
            text={t('noActiveChannelsLink')}
            variant="caption1"
            className="popupLinkText"
            color={theme.color.status.error}
          />
        </Link>
      </>
    )
  }

  useEffect(() => {
    if (typeData?.key !== 'widgets') {
      dispatch(
        getTableData({
          type: 'widgets',
          isSystemData: true,
        })
      )
    }
  }, [typeData?.key])

  useEffect(() => {
    const isShown = widgets && !getHasActiveChannel(widgets) // && currentUserRole === 'administrator'
    setIsWarningShown(isShown)
  }, [widgets])

  useEffect(() => {
    if (isWarningShown) {
      const hasPopup = popupAlerts.find((popup) => popup.id === NO_ACTIVE_CHANNELS_WARNING_ID)
      if (!hasPopup)
        dispatch(
          popupAlertShow({
            id: NO_ACTIVE_CHANNELS_WARNING_ID,
            content: linkToWidgets(),
            type: 'warning',
            timeout: 0,
            iconName: 'warningSign',
            withCloseButton: width <= xl ? true : false,
            className: 'oneLinePopup',
          })
        )
    } else {
      dispatch(popupAlertHide(NO_ACTIVE_CHANNELS_WARNING_ID))
    }
  }, [isWarningShown, pathname])

  useEffect(() => {
    if (typeData) {
      dispatch(popupAlertHideAll())
      dispatch(setDataType(typeData.key))
    }
  }, [typeData])

  if (width <= xl) {
    return (
      <StyledMainWrapper>
        <HeaderMobile colorScheme={currentUserRole} isTablet={isTablet} isRevert={isRevertView} />

        <Outlet />
      </StyledMainWrapper>
    )
  }

  return (
    <StyledMainWrapper>
      <ErrorBoundary>
        <NavMenu colorScheme={currentUserRole} />
      </ErrorBoundary>

      <Outlet />
    </StyledMainWrapper>
  )
}

export default MainWrapper
