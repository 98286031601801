import React from 'react'

import { StyledLeftStaticDrawer, StyledRightDrawer } from './styled'
import ErrorBoundary from '../../wrappers/ErrorBoundary'
import CentralPanel from './CentralPanel'

const GridLayoutMobile = ({
  children,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  isRightPanelOpened,
  setIsRightPanelOpened,
  LeftPanelComponent,
  RightPanelComponent,
  onCloseRightPanel,
  rightPanelTitle,
  t,
  type,
  className,
}) => {
  return (
    <>
      {LeftPanelComponent && (
        <StyledLeftStaticDrawer
          width="100vw"
          side="left"
          openedValue={isLeftPanelOpened}
          toggleDrawer={setIsLeftPanelOpened}
          absolutePositioned
          title={t(type)}
          withCloseIcon={false}
          destroyOnClose={false}
        >
          <ErrorBoundary>{LeftPanelComponent}</ErrorBoundary>
        </StyledLeftStaticDrawer>
      )}

      <CentralPanel className={className}>{children}</CentralPanel>

      {RightPanelComponent && (
        <StyledRightDrawer
          openedValue={isRightPanelOpened}
          toggleDrawer={setIsRightPanelOpened}
          width="100vw"
          absolutePositioned
          onClose={onCloseRightPanel}
          title={rightPanelTitle || t('all:details')}
        >
          <ErrorBoundary>{RightPanelComponent}</ErrorBoundary>
        </StyledRightDrawer>
      )}
    </>
  )
}

export default GridLayoutMobile
